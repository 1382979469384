import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonProps,
    Checkbox,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Spinner,
    SpinnerProps,
    Stack,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import { allNetworks, ChainId, NetworkConfig, NETWORKS } from '../../data-lib/networks';
import { useSelectedNetworks } from '../../hooks/useSelectedNetworks';
import { useDataReadiness } from '../../hooks/useUserData';
import { useWeb3 } from '../../hooks/useWeb3';
import { useGnosisSafe } from '../../state/gnosis-state';
import { ChainSymbol } from '../chain-symbol';
import { filterButtonProps } from './views/filters/claim-filter';
import { filterMenuItemFontProps, filterMenuProps } from './views/filters/common';

type NetworkRowProps = {
    networkConfig: NetworkConfig;
    isDisabled: boolean;
    isChecked: boolean;
    toggle: VoidFunction;
    spinnerVisibility?: SpinnerProps['visibility'];
};

const NetworkRow = ({ networkConfig, isChecked, isDisabled, toggle, spinnerVisibility }: NetworkRowProps) => {
    const chainId = networkConfig.chainId;

    return (
        <HStack px="3" spacing={3} key={chainId}>
            <Checkbox isDisabled={isDisabled} isChecked={isChecked} onChange={toggle} size="lg" />
            <Box w="6">
                <ChainSymbol chainId={chainId} />
            </Box>
            <Text>{networkConfig.label}</Text>
            <Spinner visibility={spinnerVisibility} />
        </HStack>
    );
};

export const ViewNetworkSelector = (props: Partial<ButtonProps>) => {
    const { selectedNetworks, toggleNetwork } = useSelectedNetworks();
    const { connectedNetwork } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const { isChainInitialized } = useDataReadiness();

    return (
        <Popover matchWidth>
            {({ isOpen }) => (
                <>
                    <PopoverTrigger>
                        <Button bg="scheme.accent_light" color={'black'} {...props}>
                            <span>
                                {`Viewing ${selectedNetworks.length} network${selectedNetworks.length > 1 ? 's' : ''}`}
                                {isOpen ? <ChevronUpIcon ml="2" /> : <ChevronDownIcon ml="2" />}
                            </span>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent w="fit-content" py="2">
                        <Stack spacing={'3'}>
                            {allNetworks.map(networkConfig => {
                                const { chainId } = networkConfig;
                                const isConnectedNetwork = connectedNetwork == chainId;

                                return (
                                    <NetworkRow
                                        isChecked={isConnectedNetwork || selectedNetworks.includes(chainId)}
                                        isDisabled={!!connectedSafeAddress || isConnectedNetwork}
                                        networkConfig={networkConfig}
                                        key={chainId}
                                        spinnerVisibility={isChainInitialized(chainId) ? 'hidden' : undefined}
                                        toggle={() => toggleNetwork(chainId)}
                                    />
                                );
                            })}
                        </Stack>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};

type NetworkMulitselectProps = {
    selectedNetworks: Set<ChainId>;
    toggleNetwork: (chainId: ChainId) => void;
    selectableNetworks?: Set<ChainId>;
};
export const NetworkMultiselect = ({ selectedNetworks, toggleNetwork, selectableNetworks }: NetworkMulitselectProps) => {
    const label = selectedNetworks.size == 0 ? 'Networks' : `${selectedNetworks.size} network${selectedNetworks.size == 1 ? '' : 's'}`;

    return (
        <Menu closeOnSelect={false} isLazy>
            <MenuButton rightIcon={<ChevronDownIcon />} as={Button} {...filterButtonProps}>
                {label}
            </MenuButton>
            <MenuList {...filterMenuProps}>
                {(selectableNetworks ? [...selectableNetworks].map(x => NETWORKS[x]) : allNetworks).map((networkConfig, i) => {
                    return (
                        <MenuItem
                            key={i}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            {...filterMenuItemFontProps}
                        >
                            <NetworkRow
                                isChecked={selectedNetworks.has(networkConfig.chainId)}
                                isDisabled={false}
                                networkConfig={networkConfig}
                                spinnerVisibility={'hidden'}
                                toggle={() => toggleNetwork(networkConfig.chainId)}
                            />
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
};

export const NetworkLoadingSpinner = () => {
    const { isChainInitialized } = useDataReadiness();
    const { selectedNetworks } = useSelectedNetworks();

    const someNetworksLoading = selectedNetworks.some(x => !isChainInitialized(x));
    return someNetworksLoading ? <Spinner mr="2" /> : <></>;
};
