import { Log, TransactionReceipt } from '@ethersproject/abstract-provider';
import { BigNumber, Event, utils } from 'ethers';
import { BytesLike, Interface, keccak256, LogDescription, toUtf8Bytes } from 'ethers/lib/utils';
import _ from 'lodash';
import { HumaFactorEvent } from '../domain/common-domain';
import {
    InvoiceApprovedEvent,
    InvoiceFundedEvent,
    InvoiceKickbackAmountSentEvent,
    InvoiceUnfactoredEvent,
} from '../domain/factoring-domain';
import { EthAddress } from '../ethereum';
import { intToDate } from '../helpers';
import {
    IBullaBanker,
    IBullaFactoring,
    IBullaFinance,
    IBullaInstantPay,
    IBullaModule,
    IERC721,
    IFrendLend,
    IGnosisSafe_1_3,
    IReceivableFactoringPool,
    I_IBullaClaim,
} from './contract-interfaces';
import {
    mapToBullaBankerCreatedEvent,
    mapToBullaBankerModuleDeployEvent,
    mapToBullaTagUpdatedEvent,
    mapToClaimCreatedEvent,
    mapToClaimPaymentEvent,
    mapToClaimRejectedEvent,
    mapToClaimRescindedEvent,
    mapToFeePaidEvent,
    mapToFinancingAcceptedEvent,
    mapToFinancingOfferedEvent,
    mapToInstantPaymentEvent,
    mapToInstantPaymentTagUpdatedEvent,
    mapToLoanOfferAcceptedEvent,
    mapToLoanOfferedEvent,
    mapToLoanOfferRejectedEvent,
    mapToModuleDisabledEvent,
    mapToModuleEnabledEvent,
    mapToTransferEvent,
} from './event-mappers';
import { MappedEventType } from './mapped-event-types';

import { parseTxLogs } from './parser';

export type EventParser = ({ args, name }: Partial<utils.LogDescription>, log: Log) => MappedEventType;

export const sigs = {
    bullaTagUpdated: 'BullaTagUpdated(address,uint256,address,bytes32,uint256)',
    bullaBankerCreated: 'BullaBankerCreated(address,address,address,uint256)',
    instantPayment: 'InstantPayment(address,address,uint256,address,string,string,string,uint256)',
    instantPaymentTagUpdated: 'InstantPaymentTagUpdated(bytes32,address,string,uint256)',
    ERC721Transfer: 'Transfer(address,address,uint256)',
    bullaBankerModuleDeploy: 'BullaBankerModuleDeploy(string,address,address,address)',
    enabledModule: 'EnabledModule(address)',
    disabledModule: 'DisabledModule(address)',
    ERC20Transfer: 'Transfer(address,address,uint256)',
    claimCreated:
        'ClaimCreated(address,uint256,address,address,address,address,string,(uint256,uint256,uint8,uint256,address,address,(bytes32,uint8,uint8)),uint256)',
    claimPayment: 'ClaimPayment(address,uint256,address,address,address,uint256,uint256)',
    claimRejected: 'ClaimRejected(address,uint256,uint256)',
    claimRescinded: 'ClaimRescinded(address,uint256,uint256)',
    feePaid: 'FeePaid(address,uint256,address,uint256,uint256,uint256)',
    financingOffered: 'FinancingOffered(uint256,(uint24,uint24,uint40),uint256)',
    financingAccepted: 'FinancingAccepted(uint256,uint256,uint256)',
    loanOffered: 'LoanOffered(uint256,address,(uint24,uint40,uint128,address,address,string,address,(bytes32,uint8,uint8)),uint256)',
    loanOfferAccepted: 'LoanOfferAccepted(uint256,uint256,uint256)',
    loanOfferRejected: 'LoanOfferRejected(uint256,address,uint256)',
    drawDownMadeWithReceivable: 'DrawdownMadeWithReceivable(address,uint256,uint256,address,uint256)',
    extraFundsDispersed: 'ExtraFundsDispersed(address,uint256)',
    paymentMade: 'PaymentMade(address,uint256,uint256,uint256,address)',
    invoiceApproved: 'InvoiceApproved(uint256,uint16,uint16,uint256,uint16)',
    invoiceFunded: 'InvoiceFunded(uint256,uint256,address)',
    invoiceKickbackAmountSent: 'InvoiceKickbackAmountSent(uint256,uint256,address)',
    invoiceUnfactored: 'InvoiceUnfactored(uint256,address,uint256,uint256)',
};

export const mapToInvoiceUnfactoredEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InvoiceUnfactoredEvent => {
    return {
        __typename: 'InvoiceUnfactoredEvent',
        txHash: transactionHash,
        totalRefundAmount: args?.totalRefundAmount,
        interestToCharge: args?.interestToCharge,
        originalCreditor: args?.originalCreditor,
        tokenId: args?.invoiceId.toString(),
        logIndex: logIndex,
        blocktime: new Date(),
        blockNumber: blockNumber,
        eventType: 'InvoiceUnfactoredEvent',
        poolAddress: args?.poolAddress,
        priceAfterTransaction: args?.priceAfterTransaction,
    };
};

export const mapToInvoiceKickbackAmountSentEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InvoiceKickbackAmountSentEvent => {
    return {
        __typename: 'InvoiceKickbackAmountSentEvent',
        txHash: transactionHash,
        kickbackAmount: args?.kickbackAmount,
        originalCreditor: args?.originalCreditor,
        tokenId: args?.invoiceId.toString(),
        logIndex: logIndex,
        blocktime: new Date(),
        blockNumber: blockNumber,
        eventType: 'InvoiceKickbackAmountSentEvent',
        poolAddress: args?.poolAddress,
        priceAfterTransaction: args?.priceAfterTransaction,
    };
};

export const mapToInvoiceFundedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InvoiceFundedEvent => {
    return {
        __typename: 'InvoiceFundedEvent',
        txHash: transactionHash,
        fundedAmount: args?.fundedAmount,
        originalCreditor: args?.originalCreditor,
        tokenId: args?.invoiceId.toString(),
        logIndex: logIndex,
        blocktime: new Date(),
        blockNumber: blockNumber,
        eventType: 'InvoiceFundedEvent',
        poolAddress: args?.poolAddress,
        priceAfterTransaction: args?.priceAfterTransaction,
    };
};

export const mapToInvoiceApprovedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InvoiceApprovedEvent => {
    return {
        __typename: 'InvoiceApprovedEvent',
        txHash: transactionHash,
        interestApr: args?.interestApr,
        upfrontBps: args?.upfrontBps,
        tokenId: args?.invoiceId.toString(),
        validUntil: intToDate(Number(args?.validUntil)),
        logIndex: logIndex,
        blocktime: new Date(),
        blockNumber: blockNumber,
        eventType: 'InvoiceApprovedEvent',
    };
};

export const mapToPaymentMadeEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): HumaFactorEvent => ({
    __typename: 'PaymentMadeEvent',
    blocktime: args?.blocktime ? intToDate(args.blocktime) : new Date(),
    txHash: transactionHash,
    amount: args!.amount.toString(),
    netAmountToBorrower: '',
    owner: args!.borrower,
    logIndex: logIndex,
    tokenId: '',
    blockNumber: blockNumber,
    eventType: 'PaymentMadeEvent',
});

export const mapToDrawDownMadeWithReceivableEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): HumaFactorEvent => ({
    __typename: 'DrawdownOnReceivableEvent',
    blocktime: args?.blocktime ? intToDate(args.blocktime) : new Date(),
    txHash: transactionHash,
    amount: args!.borrowAmount,
    netAmountToBorrower: args!.netAmountToBorrower.toString(),
    owner: args!.borrower,
    logIndex: logIndex,
    tokenId: args!.receivableParam.toString(),
    blockNumber: blockNumber,
    eventType: 'DrawdownOnReceivableEvent',
});

export const mapToExtraFundsDispersedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): HumaFactorEvent => ({
    __typename: 'ExtraFundsDispersedEvent',
    blocktime: args?.blocktime ? intToDate(args.blocktime) : new Date(),
    txHash: transactionHash,
    amount: args!.amount.toString(),
    netAmountToBorrower: args!.amount.toString(),
    owner: args!.receiver,
    logIndex: logIndex,
    tokenId: '',
    blockNumber: blockNumber,
    eventType: 'ExtraFundsDispersedEvent',
});

export const eventMappingArray: Record<string, EventParser> = {
    [sigs.bullaBankerCreated]: mapToBullaBankerCreatedEvent,
    [sigs.bullaBankerModuleDeploy]: mapToBullaBankerModuleDeployEvent,
    [sigs.bullaTagUpdated]: mapToBullaTagUpdatedEvent,
    [sigs.claimCreated]: mapToClaimCreatedEvent,
    [sigs.claimPayment]: mapToClaimPaymentEvent,
    [sigs.claimRejected]: mapToClaimRejectedEvent,
    [sigs.claimRescinded]: mapToClaimRescindedEvent,
    [sigs.feePaid]: mapToFeePaidEvent,
    [sigs.ERC721Transfer]: mapToTransferEvent,
    [sigs.ERC20Transfer]: mapToTransferEvent,
    [sigs.instantPayment]: mapToInstantPaymentEvent,
    [sigs.instantPaymentTagUpdated]: mapToInstantPaymentTagUpdatedEvent,
    [sigs.enabledModule]: mapToModuleEnabledEvent,
    [sigs.disabledModule]: mapToModuleDisabledEvent,
    [sigs.financingAccepted]: mapToFinancingAcceptedEvent,
    [sigs.financingOffered]: mapToFinancingOfferedEvent,
    [sigs.loanOffered]: mapToLoanOfferedEvent,
    [sigs.loanOfferAccepted]: mapToLoanOfferAcceptedEvent,
    [sigs.loanOfferRejected]: mapToLoanOfferRejectedEvent,
    [sigs.drawDownMadeWithReceivable]: mapToDrawDownMadeWithReceivableEvent,
    [sigs.extraFundsDispersed]: mapToExtraFundsDispersedEvent,
    [sigs.paymentMade]: mapToPaymentMadeEvent,
    [sigs.invoiceApproved]: mapToInvoiceApprovedEvent,
};

export type TransactionResult = TransactionReceipt & { events: MappedEventType[] };
export const findEventLogs = (events: MappedEventType[], eventName: MappedEventType['__typename']) =>
    events.filter(event => event.__typename === eventName);

export const getEventsFromReceipt = ({ logs }: TransactionReceipt) =>
    parseTxLogs(logs)
        .map((logDescription, i) => logDescription && eventMappingArray[logDescription.signature]?.(logDescription, logs[i]))
        .filter((ev): ev is MappedEventType => !!ev);

export type InstantPaymentRequests = 'inboundInstantPayments' | 'outboundInstantPayments' | 'instantPaymentTagUpdates';
export type ClaimCreationRequests = 'creditorClaimCreated' | 'transferredClaims' | 'debtorClaimCreated';
export type ClaimUpdateRequests =
    | 'bullaTagUpdated'
    | 'batchBullaTagUpdated'
    | 'claimPayment'
    | 'claimRejected'
    | 'claimRescinded'
    | 'feePaid'
    | 'inboundTransfers'
    | 'outboundTransfers'
    | 'transfers'
    | 'drawDownMadeWithReceivable'
    | 'extraFundsDispersed'
    | 'paymentMade'
    | 'invoiceApproved';

export type Requests =
    | InstantPaymentRequests
    | ClaimCreationRequests
    | ClaimUpdateRequests
    | 'bullaBankerCreated'
    | 'gnosisModuleDeploy'
    | 'gnosisModuleEnabled'
    | 'gnosisModuleDisabled';

export type EventData = {
    eventSignature: string;
    abiInterface: Interface;
    topicSets: (...any: any) => any[];
    mapLog: (logDescription: utils.LogDescription, rawLog: Log) => MappedEventType;
};

export type TopicStrings = Array<string | null | string[]>;
export type EventLog = Event | LogDescription;
export type EventLogs = {
    [request in Requests]?: MappedEventType[];
};

/** a domain specific way we create ids for events by hashing their txHash and logIndex for a unique 32byte hash */
export const getUniqueEventId = ({ txHash, logIndex }: { txHash: string; logIndex: number }): string =>
    keccak256(toUtf8Bytes(txHash + logIndex));
export const hashEventSignature = (eventSignature: string) => utils.id(eventSignature);
export const toTopicString = (value: string | number | string[] | number[] | BigNumber | BigNumber[]) => {
    const hashValue = (value: string | number | BigNumber) => utils.hexZeroPad(value as BytesLike, 32);
    return Array.isArray(value) ? value.map(hashValue) : hashValue(value);
};
export const formatEventLogs = (logs: Log[] | Log, { mapLog, abiInterface }: EventData) => {
    return (_.isArray(logs) ? logs : [logs]).map(log => mapLog(abiInterface.parseLog(log), log));
};

const bullaTagUpdateRequest = {
    eventSignature: sigs.bullaTagUpdated,
    abiInterface: IBullaBanker,
    mapLog: mapToBullaTagUpdatedEvent,
    topicSets: (bullaManager?: EthAddress, tokenIds?: string[], updatedBy?: EthAddress) => [
        hashEventSignature(sigs.bullaTagUpdated),
        bullaManager ? toTopicString(bullaManager) : null,
        tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
        updatedBy ? toTopicString(updatedBy) : null,
    ],
};

export const eventLogRequests = {
    bullaBankerCreated: {
        eventSignature: sigs.bullaBankerCreated,
        abiInterface: IBullaBanker,
        mapLog: mapToBullaBankerCreatedEvent,
        topicSets: (bullaManager: EthAddress) => [hashEventSignature(sigs.bullaBankerCreated), toTopicString(bullaManager)],
    },
    inboundInstantPayments: {
        eventSignature: sigs.instantPayment,
        abiInterface: IBullaInstantPay,
        mapLog: mapToInstantPaymentEvent,
        topicSets: (userAddress: EthAddress) => [hashEventSignature(sigs.instantPayment), null, toTopicString(userAddress)],
    },
    outboundInstantPayments: {
        eventSignature: sigs.instantPayment,
        abiInterface: IBullaInstantPay,
        mapLog: mapToInstantPaymentEvent,
        topicSets: (userAddress: EthAddress) => [hashEventSignature(sigs.instantPayment), toTopicString(userAddress)],
    },
    instantPaymentTagUpdates: {
        eventSignature: sigs.instantPaymentTagUpdated,
        abiInterface: IBullaInstantPay,
        mapLog: mapToInstantPaymentTagUpdatedEvent,
        topicSets: (updatedBy: EthAddress, txAndLogIndexHash?: string) => [
            hashEventSignature(sigs.instantPaymentTagUpdated),
            txAndLogIndexHash ? toTopicString(txAndLogIndexHash) : null,
            toTopicString(updatedBy),
        ],
    },
    inboundTransfers: {
        eventSignature: sigs.ERC721Transfer,
        abiInterface: IERC721,
        mapLog: mapToTransferEvent,
        topicSets: (userAddress: EthAddress) => [
            hashEventSignature(sigs.ERC721Transfer),
            null,
            userAddress ? toTopicString(userAddress) : null,
        ],
    },
    outboundTransfers: {
        eventSignature: sigs.ERC721Transfer,
        abiInterface: IERC721,
        mapLog: mapToTransferEvent,
        topicSets: (userAddress: EthAddress) => [hashEventSignature(sigs.ERC721Transfer), userAddress ? toTopicString(userAddress) : null],
    },
    gnosisModuleDeploy: {
        eventSignature: sigs.bullaBankerModuleDeploy,
        abiInterface: IBullaModule,
        mapLog: mapToBullaBankerModuleDeployEvent,
        topicSets: (safeAddress: EthAddress) => [
            hashEventSignature(sigs.bullaBankerModuleDeploy),
            safeAddress ? toTopicString(safeAddress) : null,
        ],
    },
    gnosisModuleEnabled: {
        eventSignature: sigs.enabledModule,
        abiInterface: IGnosisSafe_1_3,
        mapLog: mapToModuleEnabledEvent,
        topicSets: () => [hashEventSignature(sigs.enabledModule)],
    },
    gnosisModuleDisabled: {
        eventSignature: sigs.disabledModule,
        abiInterface: IGnosisSafe_1_3,
        mapLog: mapToModuleDisabledEvent,
        topicSets: () => [hashEventSignature(sigs.disabledModule)],
    },
    transfers: {
        eventSignature: sigs.ERC721Transfer,
        abiInterface: IERC721,
        mapLog: mapToTransferEvent,
        topicSets: (tokenIds: string[]) => [
            hashEventSignature(sigs.ERC721Transfer),
            null,
            null,
            toTopicString(tokenIds.map(BigNumber.from)),
        ],
    },
    bullaTagUpdated: bullaTagUpdateRequest,
    batchBullaTagUpdated: bullaTagUpdateRequest,
    creditorClaimCreated: {
        eventSignature: sigs.claimCreated,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimCreatedEvent,
        topicSets: (userAddress: EthAddress) => [hashEventSignature(sigs.claimCreated), null, toTopicString(userAddress)],
    },
    transferredClaims: {
        eventSignature: sigs.claimCreated,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimCreatedEvent,
        topicSets: (tokenIds: string[]) => [
            hashEventSignature(sigs.claimCreated),
            toTopicString(toTopicString(tokenIds.map(BigNumber.from))),
        ],
    },
    debtorClaimCreated: {
        eventSignature: sigs.claimCreated,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimCreatedEvent,
        topicSets: (userAddress: EthAddress) => [
            hashEventSignature(sigs.claimCreated), //edited to erc20
            null,
            null,
            toTopicString(userAddress),
        ],
    },
    claimPayment: {
        eventSignature: sigs.claimPayment,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimPaymentEvent,
        topicSets: (bullaManager: EthAddress, tokenIds?: string[], debtor?: EthAddress) => [
            hashEventSignature(sigs.claimPayment),
            toTopicString(bullaManager),
            tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
            debtor ?? null,
        ],
    },
    claimRejected: {
        eventSignature: sigs.claimRejected,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimRejectedEvent,
        topicSets: (bullaManager: EthAddress, tokenIds?: string[]) => [
            hashEventSignature(sigs.claimRejected),
            toTopicString(bullaManager),
            tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
        ],
    },
    claimRescinded: {
        eventSignature: sigs.claimRescinded,
        abiInterface: I_IBullaClaim,
        mapLog: mapToClaimRescindedEvent,
        topicSets: (bullaManager: EthAddress, tokenIds?: string[]) => [
            hashEventSignature(sigs.claimRescinded),
            toTopicString(bullaManager),
            tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
        ],
    },
    feePaid: {
        eventSignature: sigs.feePaid,
        abiInterface: I_IBullaClaim,
        mapLog: mapToFeePaidEvent,
        topicSets: (bullaManager: EthAddress, tokenIds?: string[], collectionAddress?: EthAddress) => [
            hashEventSignature(sigs.feePaid),
            toTopicString(bullaManager),
            tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
            collectionAddress ? toTopicString(collectionAddress) : null,
        ],
    },
    financeOfferedEvent: {
        eventSignature: sigs.financingOffered,
        abiInterface: IBullaFinance,
        mapLog: mapToFinancingOfferedEvent,
        topicSets: () => [hashEventSignature(sigs.financingOffered)],
    },
    financeAcceptedEvent: {
        eventSignature: sigs.financingAccepted,
        abiInterface: IBullaFinance,
        mapLog: mapToFinancingAcceptedEvent,
        topicSets: (tokenIds?: string[]) => [
            hashEventSignature(sigs.financingAccepted),
            tokenIds?.length ? toTopicString(tokenIds.map(BigNumber.from)) : null,
        ],
    },
    loanOfferedEvent: {
        eventSignature: sigs.loanOffered,
        abiInterface: IFrendLend,
        mapLog: mapToLoanOfferedEvent,
        topicSets: () => [hashEventSignature(sigs.loanOffered)],
    },
    loanOfferAcceptedEvent: {
        eventSignature: sigs.loanOfferAccepted,
        abiInterface: IFrendLend,
        mapLog: mapToLoanOfferAcceptedEvent,
        topicSets: (loanIds?: BigNumber[]) => [
            hashEventSignature(sigs.loanOfferAccepted),
            loanIds?.length ? toTopicString(loanIds.map(BigNumber.from)) : null,
        ],
    },
    loanOfferRejectedEvent: {
        eventSignature: sigs.loanOfferRejected,
        abiInterface: IFrendLend,
        mapLog: mapToLoanOfferRejectedEvent,
        topicSets: (loanIds?: BigNumber[]) => [
            hashEventSignature(sigs.loanOfferRejected),
            loanIds?.length ? toTopicString(loanIds.map(BigNumber.from)) : null,
        ],
    },
    drawDownMadeWithReceivable: {
        eventSignature: sigs.drawDownMadeWithReceivable,
        abiInterface: IReceivableFactoringPool,
        mapLog: mapToDrawDownMadeWithReceivableEvent,
        topicSets: () => [hashEventSignature(sigs.drawDownMadeWithReceivable)],
    },
    extraFundsDispersed: {
        eventSignature: sigs.extraFundsDispersed,
        abiInterface: IReceivableFactoringPool,
        mapLog: mapToExtraFundsDispersedEvent,
        topicSets: () => [hashEventSignature(sigs.extraFundsDispersed)],
    },
    paymentMade: {
        eventSignature: sigs.paymentMade,
        abiInterface: IReceivableFactoringPool,
        mapLog: mapToPaymentMadeEvent,
        topicSets: () => [hashEventSignature(sigs.paymentMade)],
    },
    invoiceApproved: {
        eventSignature: sigs.invoiceApproved,
        abiInterface: IBullaFactoring,
        mapLog: mapToInvoiceApprovedEvent,
        topicSets: () => [hashEventSignature(sigs.invoiceApproved)],
    },
    invoiceFunded: {
        eventSignature: sigs.invoiceFunded,
        abiInterface: IBullaFactoring,
        mapLog: mapToInvoiceFundedEvent,
        topicSets: () => [hashEventSignature(sigs.invoiceFunded)],
    },
    invoiceKickbackAmountSent: {
        eventSignature: sigs.invoiceKickbackAmountSent,
        abiInterface: IBullaFactoring,
        mapLog: mapToInvoiceKickbackAmountSentEvent,
        topicSets: () => [hashEventSignature(sigs.invoiceKickbackAmountSent)],
    },
    invoiceUnfactored: {
        eventSignature: sigs.invoiceUnfactored,
        abiInterface: IBullaFactoring,
        mapLog: mapToInvoiceUnfactoredEvent,
        topicSets: () => [hashEventSignature(sigs.invoiceUnfactored)],
    },
};
