import React from 'react';

type PageSize = 5 | 10 | 25 | 50 | 100;

export type PageSelectorProps = {
    pageSize: number;
    setPageSize: (pageSize: PageSize) => void;
    dontNeedPages: boolean;
    previousPageButtonDisabled: boolean;
    nextPageButtonDisabled: boolean;
    goToPage: (pageNumber: number) => () => void;
    goToPreviousPage: () => void;
    goToNextPage: () => void;
    goToFirstPage: () => void;
    goToLastPage: () => void;
    currentPage: number;
    maxNumberOfPages: number;
};

export const usePagination = <T>(allItems: T[], initialPageSize: PageSize = 10): PageSelectorProps & { shownItems: T[] } => {
    const allItemsLength = allItems.length;
    const [pageSize, _setPageSize] = React.useState<PageSize>(initialPageSize);
    const [currentPage, _setCurrentPage] = React.useState(1);

    const maxNumberOfPages = React.useMemo(() => Math.max(Math.ceil(allItemsLength / pageSize), 1), [pageSize, allItemsLength]);

    const mostOfContext = React.useMemo(() => {
        const setPageSize = (pageSize: PageSize) => {
            _setPageSize(pageSize);
            _setCurrentPage(1);
        };
        const dontNeedPages = maxNumberOfPages == 1;
        const previousPageButtonDisabled = currentPage == 1;
        const nextPageButtonDisabled = currentPage == maxNumberOfPages;

        const goToPage = (pageNumber: number) => () =>
            _setCurrentPage(prevPageNumber => {
                if (pageNumber == prevPageNumber) return pageNumber;
                if (pageNumber < 1) return 1;
                if (pageNumber > maxNumberOfPages) return maxNumberOfPages;
                return pageNumber;
            });

        const goToPreviousPage = () => _setCurrentPage(prev => (previousPageButtonDisabled ? prev : prev - 1));
        const goToNextPage = () => _setCurrentPage(prev => (nextPageButtonDisabled ? prev : prev + 1));
        const goToFirstPage = () => _setCurrentPage(prev => (previousPageButtonDisabled ? prev : 1));
        const goToLastPage = () => _setCurrentPage(prev => (nextPageButtonDisabled ? prev : maxNumberOfPages));

        return {
            pageSize,
            setPageSize,
            dontNeedPages,
            previousPageButtonDisabled,
            nextPageButtonDisabled,
            goToPage,
            goToPreviousPage,
            goToNextPage,
            goToFirstPage,
            goToLastPage,
            currentPage,
        };
    }, [maxNumberOfPages, currentPage]);

    const shownItems = React.useMemo(
        () => allItems.slice((currentPage - 1) * pageSize, currentPage * pageSize),
        [JSON.stringify(allItems), pageSize, currentPage],
    );

    React.useEffect(() => {
        currentPage > maxNumberOfPages && mostOfContext.goToLastPage();
    }, [maxNumberOfPages]);

    return { ...mostOfContext, shownItems, maxNumberOfPages };
};
