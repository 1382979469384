{
    "defaultAddress": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
    "released": true,
    "contractName": "CreateCall",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "3": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "10": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "11": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "12": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "28": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "42": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "5": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "56": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "69": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "100": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "122": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "123": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "137": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "246": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "288": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "588": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1088": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "1284": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1285": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1287": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "4002": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "42161": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "42220": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "43114": "0xB19D6FFc2182150F8Eb585b79D4ABcd7C5640A9d",
        "73799": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "80001": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "333999": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1313161554": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4",
        "1313161555": "0x7cbB62EaA69F79e6873cD1ecB2392971036cFAa4"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "name": "ContractCreation",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                }
            ],
            "name": "performCreate",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "value",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "deploymentData",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes32",
                    "name": "salt",
                    "type": "bytes32"
                }
            ],
            "name": "performCreate2",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "newContract",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}
