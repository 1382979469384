import React, { ReactElement } from 'react';

const AddContactsIcon = (): ReactElement => (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.95974 12C11.9427 12 13.5557 10.43 13.5557 8.5C13.5557 6.57 11.9427 5 9.95974 5C7.97683 5 6.36378 6.57 6.36378 8.5C6.36378 10.43 7.97683 12 9.95974 12ZM9.95974 7C10.8125 7 11.5009 7.67 11.5009 8.5C11.5009 9.33 10.8125 10 9.95974 10C9.10699 10 8.41862 9.33 8.41862 8.5C8.41862 7.67 9.10699 7 9.95974 7ZM5.61377 17H10.0111C9.98029 17.16 9.95974 17.33 9.95974 17.5V19H2.76782V17.5C2.76782 15.17 7.55559 14 9.95974 14C10.5351 14 11.244 14.07 11.994 14.2C11.2646 14.68 10.6584 15.28 10.3091 16.01C10.2526 16.01 10.1935 16.0075 10.1344 16.005C10.0753 16.0025 10.0163 16 9.95974 16C8.3878 16 6.63091 16.5 5.61377 17ZM17.6654 14.5C15.7749 14.5 12.0146 15.51 12.0146 17.5V19H23.3162V17.5C23.3162 15.51 19.5558 14.5 17.6654 14.5ZM20.2339 10.5C20.2339 11.44 19.6894 12.25 18.9086 12.68C18.5387 12.88 18.1174 13 17.6654 13C17.2133 13 16.7921 12.88 16.4222 12.68C15.6414 12.25 15.0968 11.44 15.0968 10.5C15.0968 9.12 16.2475 8 17.6654 8C19.0832 8 20.2339 9.12 20.2339 10.5Z"
            fill="black"
        />
    </svg>
);

export default AddContactsIcon;
