import { Container, HStack, Box, Spacer, Flex, Heading, Text } from '@chakra-ui/react';
import { ArrowFatLinesRight, CalendarBlank } from 'phosphor-react';
import React from 'react';
import { ContinueButton, EditButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import {
    BatchWizardState,
    ClaimConfigurationState,
    DefaultBatchClaimValues,
    DefaultBatchValuesState,
    initialDefaultValues,
    PaymentTime,
    ReviewState,
    SelectPaymentDateState,
    SuccessState,
} from './batch-state';
import { BatchFooter, BatchStepCard } from './shared-components';

const getTitleForPaymentTime = (paymentTime: PaymentTime) => {
    const icon = paymentTime === 'now' ? <ArrowFatLinesRight size={18} /> : <CalendarBlank size={18} />;
    const title = paymentTime === 'now' ? 'PAY NOW' : 'SCHEDULE A PAYMENT';

    return (
        <HStack pt="0" spacing={2}>
            {icon}
            <Text fontSize={12} fontWeight="700">
                {title}
            </Text>
        </HStack>
    );
};

type PayNowOrLaterCardProps = {
    description: string;
    setState: (state: SelectPaymentDateState) => void;
    state: SelectPaymentDateState;
    paymentTime: PaymentTime;
};

const PayNowOrLaterCard = ({ state, setState, paymentTime, description }: PayNowOrLaterCardProps) => (
    <ShadowBox
        color="black"
        flex={1}
        p="6"
        border={state.paymentTime == paymentTime ? '1px solid orange' : 'inherit'}
        _hover={{ cursor: 'pointer' }}
        onClick={() =>
            setState({
                ...state,
                defaultValues: state.defaultValues
                    ? {
                          ...state.defaultValues,
                          defaultDueDate:
                              paymentTime === 'now'
                                  ? undefined
                                  : !state.defaultValues.defaultDueDate
                                  ? initialDefaultValues.defaultDueDate
                                  : state.defaultValues.defaultDueDate,
                      }
                    : undefined,
                paymentTime: paymentTime,
            })
        }
    >
        {getTitleForPaymentTime(paymentTime)}
        <Text fontSize={14} textStyle="faint" fontWeight={'400'} lineHeight="21px" mt="4">
            {description}
        </Text>
    </ShadowBox>
);

type OpenedSelectedPaymentCardProps = {
    state: SelectPaymentDateState;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
    initialDefaultValuesWithToken: DefaultBatchClaimValues;
};

export const OpenedSelectedPaymentCard = ({ state, setWizardState, initialDefaultValuesWithToken }: OpenedSelectedPaymentCardProps) => (
    <Container maxW="container.xl" p="0">
        <BatchStepCard title="Pay now or later" subtitle="Do you want to make your payments now or schedule them for the future?">
            <HStack p="0" pt="8">
                <PayNowOrLaterCard
                    description={'Payments will be instantly transferred to designated wallets.'}
                    setState={setWizardState}
                    state={state}
                    paymentTime={'now'}
                />
                <Box w="8" />
                <PayNowOrLaterCard
                    description={
                        'Payments will be scheduled for a future date and require another action on your behalf to complete the transaction.'
                    }
                    setState={setWizardState}
                    state={state}
                    paymentTime={'later'}
                />
            </HStack>
        </BatchStepCard>

        <BatchFooter>
            <Spacer />
            <ContinueButton
                isDisabled={state.paymentTime === undefined}
                onClick={() => {
                    const values = state.defaultValues ?? initialDefaultValuesWithToken;
                    setWizardState({
                        ...state,
                        kind: 'DefaultValueSelection',
                        defaultValues: {
                            ...values,
                            defaultDueDate:
                                state.paymentTime === 'now'
                                    ? undefined
                                    : !values.defaultDueDate
                                    ? initialDefaultValues.defaultDueDate
                                    : values.defaultDueDate,
                        },
                    });
                }}
            />
        </BatchFooter>
    </Container>
);

type ClosedPaymentTimeCardProps = {
    paymentTime: 'now' | 'later';
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
};

export const ClosedPaymentTimeCard = React.memo(({ paymentTime, setWizardState }: ClosedPaymentTimeCardProps) => (
    <ShadowBox p="4" mb="4">
        <Flex color="#9F9F9F">
            <HStack pt="0" spacing={8}>
                <Heading fontSize={18}>Pay Now or Pay Later</Heading>
                <HStack spacing={2}>{getTitleForPaymentTime(paymentTime)}</HStack>
            </HStack>
            <Spacer />
            <EditButton
                isDisabled={false}
                onClick={() =>
                    setWizardState(previousState => ({
                        ...(previousState as DefaultBatchValuesState | ClaimConfigurationState | ReviewState | SuccessState),
                        kind: 'SelectPaymentType',
                        paymentTime,
                    }))
                }
            />
        </Flex>
    </ShadowBox>
));
