import { Box, Container, Spinner, VStack } from '@chakra-ui/react';
import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EthAddress } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS } from '../../../data-lib/networks';
import { useAuthApi } from '../../../hooks/useAuthApi';
import { LedgerExportResponse, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { useMembership } from '../../../hooks/useMembership';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { PageLayoutProvider } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { AddWalletsChainsCard } from './add-wallets-chains-card';
import { LedgerExportSuccessCard } from './ledger-export-success-card';
import { LedgerWizardStep, ProgressSteps } from './progress-steps';
import { ReportNameCard } from './report-name-card';

export type LedgerReportMetadata = {
    reportId: string;
    createdOn: string;
};

export type SelectNameState = {
    step: LedgerWizardStep.SelectName;
    reportName: string;
} & LedgerReportMetadata;

export type WalletChainSelection = {
    addresses: Set<EthAddress>;
    chainIds: ChainId[];
};

export type SelectWalletState = {
    step: LedgerWizardStep.SelectWallet;
    walletChainSelection: WalletChainSelection;
} & LedgerReportMetadata;

export type ReportFetchingState = {
    step: LedgerWizardStep.ReportFetching;
    walletChainSelection: WalletChainSelection;
} & LedgerReportMetadata;

export type SuccessState = {
    step: LedgerWizardStep.ExportSuccess;
    walletChainSelection: WalletChainSelection;
    ledgerExportResponse: LedgerExportResponse;
} & LedgerReportMetadata;

export type LedgerWizardState = SelectNameState | SelectWalletState | ReportFetchingState | SuccessState;

const FetchingCard: React.FC<{ state: ReportFetchingState; setState: React.Dispatch<React.SetStateAction<LedgerWizardState>> }> = ({
    state,
    setState,
}) => {
    const { buildLedgerExport } = useExternalTransactionsApi();
    const { loadTokenPrices } = useHistoricalPrices();
    const { resolveTokenInfo } = useTokenRepo();

    useEffect(() => {
        buildLedgerExport(state.walletChainSelection)
            .then(async response => {
                const priceFetchParams = [
                    ...response.claimEntries.map(x => ({
                        chainId: x.chainId as ChainId,
                        timestamp: intToDate(x.eventTimestamp),
                        tokenAddress: x.token.address,
                    })),
                    ...response.paymentEntries.map(x => ({
                        chainId: x.chainId as ChainId,
                        timestamp: intToDate(x.timestamp),
                        tokenAddress: x.token.address,
                    })),
                ];
                const uniqueTokens = Array.from(
                    new Map(priceFetchParams.map(item => [`${item.chainId}-${item.tokenAddress}`, item])).values(),
                );

                await Promise.all([
                    loadTokenPrices(priceFetchParams),
                    ...uniqueTokens.map(x => resolveTokenInfo(x.chainId, x.tokenAddress)),
                ]);
                return response;
            })
            .then(result =>
                setState(oldState => ({
                    ...(oldState as ReportFetchingState),
                    ledgerExportResponse: result,
                    step: LedgerWizardStep.ExportSuccess,
                })),
            );
    }, []);
    return <Spinner />;
};

export const LedgerWizard = () => {
    const navigate = useNavigate();
    const onClose = () => navigate('/reporting');
    const actingWallet = useActingWalletAddress();
    const initialReportId = crypto.randomUUID();
    const [reportName, setReportName] = useState('');
    const { connectedNetwork } = useWeb3();
    const { getFullMembershipInfo } = useAuthApi();
    const membership = useMembership();

    const [selectableWallets, setSelectableWallets] = useState<Set<string>>(new Set());

    const membershipIds = membership?.membershipIds ?? [];

    useEffect(() => {
        if (membership == null) return;
        if (membership.isFreeTrial || membershipIds.length == 0) {
            setSelectableWallets(new Set([actingWallet.toLowerCase()]));
            return;
        }

        Promise.all(membershipIds.map(getFullMembershipInfo)).then(x => {
            const selectableWallets = new Set(
                x.flatMap(dto => (dto ? [dto.mainAddress, ...dto.additionalAddresses] : [])).map(x => x.toLowerCase()),
            );
            return setSelectableWallets(selectableWallets);
        });
    }, [membershipIds, membership]);

    const initialReportCreationState: SelectNameState = {
        step: LedgerWizardStep.SelectName,
        reportName: '',
        reportId: initialReportId,
        createdOn: new Date().toLocaleDateString(),
    };

    const [wizardState, setWizardState] = useState<LedgerWizardState>(initialReportCreationState);

    const addWalletCard = wizardState.step === LedgerWizardStep.SelectWallet && (
        <AddWalletsChainsCard
            setWizardState={setWizardState}
            wizardState={wizardState}
            selectableWallets={selectableWallets}
            onSelectedWallets={walletChainSelection =>
                setWizardState({
                    ...(wizardState as LedgerReportMetadata),
                    step: LedgerWizardStep.ReportFetching,
                    walletChainSelection,
                } as ReportFetchingState)
            }
        />
    );

    const loadingCard = wizardState.step == LedgerWizardStep.ReportFetching && (
        <FetchingCard state={wizardState} setState={setWizardState} />
    );

    const startCard = wizardState.step === LedgerWizardStep.SelectName && (
        <ReportNameCard
            reportName={reportName}
            setReportName={setReportName}
            onStartExport={() =>
                setWizardState({
                    ...(wizardState as LedgerReportMetadata),
                    step: LedgerWizardStep.SelectWallet,
                    reportName: reportName,
                    walletChainSelection: {
                        addresses: new Set([actingWallet.toLowerCase()]),
                        chainIds: [NETWORKS[connectedNetwork].chainId],
                    },
                } as SelectWalletState)
            }
        />
    );

    const ledgerExportCard = wizardState.step === LedgerWizardStep.ExportSuccess && <LedgerExportSuccessCard wizardState={wizardState} />;

    return (
        <PageLayoutProvider>
            {wizardState.step === LedgerWizardStep.ExportSuccess ? (
                ledgerExportCard
            ) : (
                <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" p="12">
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <BullaCloseButton onClose={onClose} />
                    </Box>
                    <Container maxW="xl" p="0">
                        <VStack spacing={8} align="center">
                            {startCard}
                            {addWalletCard}
                            {loadingCard}
                            <ProgressSteps currentStep={wizardState.step} />
                        </VStack>
                    </Container>
                </Box>
            )}
        </PageLayoutProvider>
    );
};
