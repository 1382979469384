import React from 'react';
import { useLocation } from 'react-router-dom';

type ParamMap = { [name: string]: string | undefined };

export const useQuery = <T extends string | string[]>(name: T) => {
    const { search } = useLocation();
    const getSearch = (param: string) => new URLSearchParams(search).get(param) ?? undefined;

    return React.useMemo<T extends string[] ? ParamMap : string | undefined>(
        () =>
            (Array.isArray(name)
                ? name.reduce<ParamMap>((acc, name) => ({ ...acc, [name]: getSearch(name) }), {})
                : getSearch(name)) as T extends string[] ? ParamMap : string | undefined,
        [search],
    );
};

export const useQueryList = (name: string) => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search).getAll(name) ?? [], [search]);
};
