import { useEffect, useState } from 'react';
import { isUserReady, useAuth } from '../state/auth-state';
import { useCompanyDetailsRepo } from './useCompanyDetailsRepo';
import { useActingWalletAddress } from './useWalletAddress';

export type AttachmentGenerationState = 'unauthenticated' | 'fetching' | 'no-details' | 'ready';

export const useAttachmentGenerationState = () => {
    const actingAddress = useActingWalletAddress();
    const { user } = useAuth();
    const { getCompanyDetails } = useCompanyDetailsRepo();
    const [attachmentGenerationState, setAttachmentGenerationState] = useState<AttachmentGenerationState>('unauthenticated');

    useEffect(() => {
        (async () => {
            if (attachmentGenerationState === 'ready') return 'ready';
            if (!isUserReady(user)) return 'unauthenticated';

            setAttachmentGenerationState('fetching');
            const companyDetails = await getCompanyDetails();
            return !!companyDetails ? 'ready' : 'no-details';
        })().then(setAttachmentGenerationState);
    }, [user, actingAddress]);

    return { attachmentGenerationState, setAttachmentGenerationState, getCompanyDetails } as const;
};
