import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export const AlertInfo = ({ message, hideIcon, ...overrides }: { message: string | React.ReactNode; hideIcon?: boolean } & BoxProps) => (
    <Box
        w="100%"
        bg="gray.200"
        p="4"
        boxShadow={'inset 0px 1px 4px rgba(0, 0, 0, 0.04)'}
        borderRadius="4px"
        border="1px solid #E2E8F0"
        {...overrides}
    >
        <HStack>
            {!hideIcon && <InfoOutlineIcon />}
            {typeof message == 'string' ? <Text>{message}</Text> : message}
        </HStack>
    </Box>
);
