import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Collapse, HStack, Image, keyframes, Stack, Tag, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { ImportTransactionState, SelectTransactionState } from '.';
import { NoteSvg } from '../../../../assets/svgs';
import { WarningIconWithTooltip } from '../../../../assets/warning';
import { ExternalTransactionDTO, TransferDTO } from '../../../../data-lib/dto/external-transactions-dto';
import { addressEquality, EthAddress } from '../../../../data-lib/ethereum';
import { ChainId, EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS, NETWORKS } from '../../../../data-lib/networks';
import { toDateWithTime } from '../../../../tools/common';
import { TXHashLabel } from '../../../base/address-label';
import { BullaBlueTextButton } from '../../../inputs/buttons';
import {
    CumulativeAmountTd,
    getCumulativeAmounts,
    getDetectedTxTypeBadge,
    InboundOutboundTd,
    Td,
    ToolbarState,
    TransferTd,
} from './common';

const SELECT_HEADERS = [
    { label: 'chain', isNumeric: false },
    { label: 'from / to', isNumeric: false, isSortable: true },
    { label: 'date', isNumeric: false, isSortable: true },
    { label: 'transaction type', isNumeric: false },
    { label: 'tokens', isNumeric: true },
    { label: 'tx hash', isNumeric: false },
] as const;

export const blinkAnimation = keyframes`
  from {opacity: 1;}
  to {opacity: .5;}
`;

export const CollapsableTransferTr = React.memo(
    ({
        transfer,
        userAddress,
        isChecked,
        onSelect,
        isReverse,
    }: {
        transfer: TransferDTO;
        userAddress: EthAddress;
        isChecked: boolean;
        onSelect: (selected: boolean) => void;
        isReverse?: boolean;
    }) => (
        <motion.tr
            animate={'open'}
            variants={{
                open: { opacity: 1, height: 'auto', display: 'table-row' },
                collapsed: { opacity: 0, height: 0, display: 'none' },
            }}
            transition={{ duration: 0.5 }}
            style={{ background: '#F7FAFC' }}
        >
            <Td>
                <Checkbox isChecked={isChecked} onChange={e => onSelect(e.target.checked)} />
            </Td>

            <Td />

            <InboundOutboundTd {...{ userAddress, transfer, isReverse }} />

            <Td />

            <Td pl="12">
                <Tag>Transfer</Tag>
            </Td>

            <TransferTd transfer={transfer} />

            <Td />
            <Td />
        </motion.tr>
    ),
);

export const ExternalTxTr = React.memo(
    ({
        tx,
        userAddress,
        setOmittedIds,
        omittedIds,
    }: {
        userAddress: EthAddress;
        tx: ExternalTransactionDTO;
        setOmittedIds: (prevStateCallback: (ids: string[]) => string[]) => void;
        omittedIds: string[];
    }) => {
        const hasSubTx = tx.allTransfers.length > 1;
        const dateTimeString = toDateWithTime(tx.timestamp);
        const chainId = tx.chainId;
        const { blockExplorer, logoFileName, label } = NETWORKS[chainId];

        const [topTransfer] = tx.allTransfers;

        const txInitiatorIsUser = !!tx.nativeTransfer && addressEquality(tx.nativeTransfer.from, userAddress);
        const cumulativeAmounts = useMemo(() => getCumulativeAmounts(tx.allTransfers, userAddress), [tx.id, userAddress]);

        const childIds = useMemo(() => tx.allTransfers.map(t => t.id), [tx.id]);
        const allSelected = useMemo(() => childIds.every(id => !omittedIds.includes(id)), [omittedIds, childIds]);
        const anySelected = useMemo(() => childIds.some(id => !omittedIds.includes(id)), [omittedIds, childIds]);

        const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: !allSelected && anySelected });

        const handleParentCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
            const includeAll = e.target.checked;
            if (includeAll) {
                setOmittedIds(prev => prev.filter(id => !childIds.includes(id)));
            } else {
                setOmittedIds(prev => [...new Set([...prev, ...childIds])]);
            }
        };

        const handleChildCheck = (select: boolean, id: string) => {
            if (select) {
                setOmittedIds(prev => prev.filter(_id => id !== _id));
            } else {
                setOmittedIds(prev => [...new Set([...prev, id])]);
            }
        };

        return (
            <>
                <Tr>
                    <Td>
                        <Checkbox
                            isChecked={allSelected || anySelected}
                            onChange={handleParentCheck}
                            isIndeterminate={!allSelected && anySelected}
                        />
                    </Td>

                    <Td>
                        <HStack>
                            {hasSubTx ? (
                                <HStack onClick={onToggle} _hover={{ textDecor: 'underline', cursor: 'pointer' }} spacing="-1">
                                    <TriangleDownIcon w="9px" h="9px" transform={!isOpen ? 'rotate(-90deg)' : 'rotate(0deg)'} />
                                    <BullaBlueTextButton textDecor={'none'}>+{tx.allTransfers.length}</BullaBlueTextButton>
                                </HStack>
                            ) : null}
                            <Image src={logoFileName} maxH="14px" />
                            <Text>{label}</Text>
                        </HStack>
                    </Td>

                    {!!topTransfer ? <InboundOutboundTd userAddress={userAddress} transfer={topTransfer} /> : <Td />}

                    <Td>{dateTimeString}</Td>

                    <Td>{tx.detectedType && getDetectedTxTypeBadge(tx.detectedType, tx.functionName)}</Td>

                    <CumulativeAmountTd toggleOpen={onToggle} isOpen={isOpen} cumulativeAmounts={cumulativeAmounts} />

                    <Td>
                        <Box>
                            <TXHashLabel fontWeight={500} blockExplorerURL={blockExplorer} txHash={tx.txHash} />
                        </Box>
                    </Td>
                </Tr>

                <AnimatePresence>
                    {isOpen &&
                        tx.allTransfers.map((transfer, key) => (
                            <CollapsableTransferTr
                                {...{
                                    key,
                                    transfer,
                                    userAddress,
                                    onSelect: (select: boolean) => handleChildCheck(select, transfer.id),
                                    isChecked: !omittedIds.includes(transfer.id),
                                    isReverse: txInitiatorIsUser && addressEquality(transfer.to, userAddress),
                                }}
                            />
                        ))}
                </AnimatePresence>
            </>
        );
    },
);

const TransactionFound = ({
    allLoaded,
    txTotal,
    fetchingStateArr,
}: {
    allLoaded: boolean;
    txTotal: number;
    fetchingStateArr: [
        string,
        {
            loading: boolean | 'init';
            error: string | undefined;
        },
    ][];
}) => {
    return (
        <HStack pos="relative" height={'32px'} w="100%" alignItems={'center'}>
            <Collapse in={allLoaded} style={{ position: 'absolute', height: '32px', margin: '0' }}>
                <HStack>
                    <NoteSvg />
                    <Text>{txTotal} Transactions Found</Text>
                    {fetchingStateArr.some(([, state]) => !!state.error) && (
                        <WarningIconWithTooltip
                            placement="top-start"
                            label={
                                <Stack>
                                    <Text>
                                        Error loading{' '}
                                        {fetchingStateArr
                                            .reduce<string[]>(
                                                (acc, [network, state]) => (!!state.error ? [...acc, NETWORKS[+network]?.label] : acc),
                                                [],
                                            )
                                            .join(', ')}
                                    </Text>
                                </Stack>
                            }
                            warningOverrides={{ color: 'danger', height: '32px' }}
                        />
                    )}
                </HStack>
            </Collapse>
        </HStack>
    );
};

export type LoadingState = {
    [network in typeof EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS[number]]: { loading: boolean | 'init'; error: string | undefined };
};

export const getInitLoadingState = (networks: ChainId[]) =>
    networks.reduce<LoadingState>(
        (acc, network) => ({
            ...acc,
            [network]: {
                loading: 'init',
                error: undefined,
            },
        }),
        {} as LoadingState,
    );

export const getFinishedLoadingState = (networks: ChainId[]) =>
    networks.reduce<LoadingState>(
        (acc, network) => ({
            ...acc,
            [network]: {
                loading: false,
                error: undefined,
            },
        }),
        {} as LoadingState,
    );

type SelectedTransactionsProps = {
    walletAddress: string;
    externalTxState: SelectTransactionState;
    setExternalTxState: React.Dispatch<React.SetStateAction<ImportTransactionState>>;
    filteredTxs: ImportTransactionState['queried'];
    page: number;
    networksToFetch: ChainId[];
    toolbarState: ToolbarState;
};
