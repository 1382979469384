import { solidityKeccak256 } from 'ethers/lib/utils';
import { getDetectedType } from '../../components/detected-type';
import { TokenInfoByChainIdAndAddress } from '../../hooks/useTokenRepo';
import { TxHashBitmap } from '../../tools/common';
import { Replace } from '../../tools/types';
import { addressEquality, EthAddress } from '../ethereum';
import { intToDate } from '../helpers';
import { allNetworks, ChainId, chainIds, NETWORKS, TokenInfo } from '../networks';
import { buildExternalTxToken } from '../tokens';

// server response types //

export type Metadata = {
    tags: string[];
    description: string;
    notes: string;
    chainId?: ChainId; // ChainId needs to be specified for Bulla Items, and omitted for external transactions
};

export type Transfer = {
    id: string;
    metadata: Metadata | null;
    from: EthAddress;
    to: EthAddress;
    value: string;
};

export type InternalTransaction = {
    id: string;
    gas: string;
    transfer: Transfer;
};

export type TokenData = {
    address: EthAddress;
    decimals: number;
    name: string;
    symbol: string;
};

export type ERC20TransferEvent = {
    transfer: Transfer;
    token: TokenData;
};

export type ERC721TransferEvent = {
    transfer: Omit<Transfer, 'value' | 'metadata'>;
    token: { contractAddress: string; tokenId: string; tokenName: string; tokenSymbol: string };
};

export type ExternalTransaction = {
    id: string;
    chainId: ChainId;
    functionName: string;
    blockNumber: number;
    timestamp: number;
    txHash: string;
    nativeTransfer: Transfer | null;
    internalTranfers: InternalTransaction[];
    erc20TransferEvents: ERC20TransferEvent[];
    erc721TransferEvents: ERC721TransferEvent[];
};

export type ExternalTxServiceResponse = {
    transactions: ExternalTransaction[];
    bullaItemMetadata: Record<string, TransferMetadata>;
};

// end server response types //

export type DetectedExternalTxType = ReturnType<typeof getDetectedType>;

export type TransferMetadata = Partial<Metadata> & {
    id: string;
};

export type NonFungibleTransferType = {
    kind: 'non-fungible';
    contractAddress: string;
    tokenId: string;
    tokenSymbol: string;
    tokenName: string;
};

export type TransferDTO = Transfer & {
    transferType: { kind: 'fungible'; tokenInfo: TokenInfo } | NonFungibleTransferType;
    parentTxId: string;
    action: 'Sent' | 'Received';
    chainId: ChainId;
};

export type ExternalTransactionDTO = Replace<ExternalTransaction, 'timestamp', Date> & {
    id: string;
    allTransfers: TransferDTO[];
    detectedType?: DetectedExternalTxType;
};

const getAction = (from: string, userAddress: EthAddress): 'Sent' | 'Received' =>
    addressEquality(from, userAddress) ? 'Sent' : 'Received';

export const erc20TxToTransferDTO = (
    erc20Tx: ERC20TransferEvent,
    userAddress: EthAddress,
    chainId: ChainId,
    parentTxId: string,
    tokenInfoByChainIdAndAddress: TokenInfoByChainIdAndAddress,
): TransferDTO => ({
    ...erc20Tx.transfer,
    parentTxId,
    chainId,
    transferType: {
        kind: 'fungible',
        tokenInfo:
            tokenInfoByChainIdAndAddress(chainId)(erc20Tx.token.address) ??
            buildExternalTxToken(chainId, erc20Tx.token.address, erc20Tx.token.decimals, erc20Tx.token.symbol, erc20Tx.token.name),
    },
    action: getAction(erc20Tx.transfer.from, userAddress),
});

export const internalTxToTransferDTO = (
    internalTx: InternalTransaction,
    userAddress: EthAddress,
    chainId: ChainId,
    parentTxId: string,
): TransferDTO => ({
    ...internalTx.transfer,
    parentTxId,
    chainId,
    transferType: { kind: 'fungible', tokenInfo: NETWORKS[chainId].nativeCurrency.tokenInfo },
    action: getAction(internalTx.transfer.from, userAddress),
});

export const nativeTxToTransferDTO = (nativeTx: Transfer, userAddress: EthAddress, chainId: ChainId, parentTxId: string): TransferDTO => ({
    ...nativeTx,
    parentTxId,
    chainId,
    transferType: { kind: 'fungible', tokenInfo: NETWORKS[chainId].nativeCurrency.tokenInfo },
    action: getAction(nativeTx.from, userAddress),
});

const transferToLowerCase = (transfer: Transfer) => ({
    ...transfer,
    from: transfer.from.toLowerCase(),
    to: transfer.to.toLowerCase(),
});

const bullaAddressBitmap = allNetworks.reduce<Record<number, TxHashBitmap>>(
    (acc, network) => ({
        ...acc,
        [network.chainId as number]: {
            [network.batchCreate.address.toLowerCase()]: true,
            [network.bullaBankerLatest.toLowerCase()]: true,
            [network.bullaClaimAddress.toLowerCase()]: true,
            [network.bullaFinanceAddress?.toLowerCase() ?? 'undeployedFinanceAddr']: !!network.bullaFinanceAddress,
            [network.bullaInstantPaymentAddress.toLowerCase()]: true,
            [network.bullaManager.toLowerCase()]: true,
            [network.frendlendAddress?.toLowerCase() ?? 'undeployedFrendlendAddr']: !!network.frendlendAddress,
        },
    }),
    {},
);

const possibleSpamTokenDomains: Record<string, boolean> = {
    xyz: true,
    com: true,
    org: true,
    net: true,
    io: true,
    co: true,
    us: true,
    uk: true,
    ca: true,
    de: true,
    cc: true,
    cash: true,
    ru: true,
    fun: true,
    me: true,
    exchange: true,
    casino: true,
    app: true,
};

const commonScamTokenAddresses: Record<EthAddress, boolean> = {
    '0x0b91b07beb67333225a5ba0259d55aee10e3a578': true, // Minereum
    '0xf9d922c055a3f1759299467dafafdf43be844f7a': true, // Minereum
};

const isBullaRelated = (chainId: ChainId, [from, to]: [EthAddress, EthAddress]) =>
    bullaAddressBitmap[chainId][from.toLowerCase()] || bullaAddressBitmap[chainId][to.toLowerCase()];

const tokenNameContainsDomain = (token: TokenData) => {
    const [, domain] = token.name?.split('.') ?? token.symbol?.split('.') ?? [];
    return !!domain && possibleSpamTokenDomains[domain.toLowerCase()];
};

const isKnownScamToken = (token: TokenData) => {
    return commonScamTokenAddresses[token.address.toLowerCase()];
};

export const filterExternalTransactions = (externalTransactions: ExternalTransaction[], txHashBitmap: TxHashBitmap) => {
    return externalTransactions.reduce<{
        scams: ExternalTransaction[];
        bullaRelated: ExternalTransaction[];
        externalTransactions: ExternalTransaction[];
        errors: ExternalTransaction[];
    }>(
        (acc, tx) => {
            try {
                // check if it is a transaction already in bulla
                if (txHashBitmap[tx.txHash.toLowerCase()]) return { ...acc, bullaRelated: [...acc.bullaRelated, tx] };

                if (tx.nativeTransfer) {
                    const { from, to } = transferToLowerCase(tx.nativeTransfer);

                    if (isBullaRelated(tx.chainId, [from, to])) return { ...acc, bullaRelated: [...acc.bullaRelated, tx] };
                }

                // ERC20 filters
                for (const { transfer, token } of tx.erc20TransferEvents) {
                    const { from, to } = transferToLowerCase(transfer);

                    if (isBullaRelated(tx.chainId, [from, to])) return { ...acc, bullaRelated: [...acc.bullaRelated, tx] };
                    if (tokenNameContainsDomain(token) || isKnownScamToken(token)) return { ...acc, scams: [...acc.scams, tx] };
                }

                for (const { transfer } of tx.internalTranfers) {
                    const { from, to } = transferToLowerCase(transfer);

                    if (isBullaRelated(tx.chainId, [from, to])) return { ...acc, bullaRelated: [...acc.bullaRelated, tx] };
                }

                return { ...acc, externalTransactions: [...acc.externalTransactions, tx] };
            } catch (e) {
                console.error('Error filtering external transaction', e, tx);
                return { ...acc, errors: [...acc.errors, tx] };
            }
        },
        { scams: [], bullaRelated: [], externalTransactions: [], errors: [] },
    );
};

export const copyMetadataObjectWithoutEmptyValues = <T extends TransferMetadata | Metadata>(metadata: T): T => {
    const copy = { ...metadata };
    if (copy.description === '') delete copy.description;
    if (copy.notes === '') delete copy.notes;
    if (copy.tags?.length === 0) delete copy.tags;
    return copy;
};

export const externalTxsToExternalTransactionDTO = (
    filteredTxs: ExternalTransaction[],
    userAddress: EthAddress,
    tokenInfoByChainIdAndAddress: TokenInfoByChainIdAndAddress,
): ExternalTransactionDTO[] => {
    const txs = filteredTxs.map<ExternalTransactionDTO>(tx => {
        const id = solidityKeccak256(['string'], ['parentTx' + tx.txHash]);

        const allTransfers: TransferDTO[] = [
            ...(tx.nativeTransfer && tx.nativeTransfer.value != '0'
                ? [nativeTxToTransferDTO(tx.nativeTransfer, userAddress, tx.chainId, id)]
                : []),
            ...tx.internalTranfers.map(it => internalTxToTransferDTO(it, userAddress, tx.chainId, id)),
            ...tx.erc20TransferEvents.map(erc20tx =>
                erc20TxToTransferDTO(erc20tx, userAddress, tx.chainId, id, tokenInfoByChainIdAndAddress),
            ),
            ...tx.erc721TransferEvents.map(erc721tx => ({
                transferType: { kind: 'non-fungible', ...erc721tx.token } as const,
                ...erc721tx.transfer,
                parentTxId: tx.txHash,
                metadata: null,
                value: '1',
                chainId: tx.chainId,
                action: getAction(erc721tx.transfer.from, userAddress),
            })),
        ];

        return {
            ...tx,
            id,
            detectedType: getDetectedType(tx, userAddress),
            timestamp: intToDate(tx.timestamp),
            allTransfers,
        };
    });
    return txs;
};

export const deferUndefinedChildMetadataToParent = ({
    parentMetadata,
    childMetadata,
}: {
    parentMetadata: TransferMetadata | undefined;
    childMetadata: TransferMetadata | undefined;
}) => {
    return {
        ...(parentMetadata ? copyMetadataObjectWithoutEmptyValues(parentMetadata) : {}),
        ...(childMetadata ? copyMetadataObjectWithoutEmptyValues(childMetadata) : {}),
    };
};

export const initTransferMetadataMappingFromAllTransfers = (transfers: TransferDTO[]): Record<string, TransferMetadata> => {
    const metaData = transfers.reduce<Record<string, TransferMetadata>>((acc, { id, metadata }) => {
        const hasMetadata = !!metadata && (metadata.description !== '' || metadata.notes !== '' || metadata.tags.length > 0);

        return { ...acc, [id]: { id, ...(hasMetadata ? copyMetadataObjectWithoutEmptyValues(metadata) : {}) } };
    }, {});
    return metaData;
};
