import axios from 'axios';
import { PricesServiceRequest, PricesServiceRequestBodyObject, PricesServiceResponse } from '../data-lib/dto/external-prices-dto';
import { endpoints } from '../tools/common';

export const usePricesApi = () => {
    const fetchExternalPrices = async (
        request: PricesServiceRequest,
        currentPricing?: 'get-current-prices',
    ): Promise<PricesServiceResponse> => {
        try {
            const data = request.body;
            const result = await axios({
                url: `${endpoints.pricesApi}/${request.currency}${currentPricing == 'get-current-prices' ? '?current=true' : ''}`,
                method: 'post',
                data,
            });
            if (result.status !== 200) throw new Error('error fetching prices');
            else if (result.data.length !== request.body.length) throw new Error('error fetching prices');
            else
                return request.body.map((item: PricesServiceRequestBodyObject, index: number) => {
                    return {
                        ...item,
                        price: result.data[index] as string,
                    };
                });
        } catch (error) {
            console.error('error', error);
            return request.body.map((item: PricesServiceRequestBodyObject, index: number) => {
                return {
                    ...item,
                    price: 'not-found',
                };
            });
        }
    };

    return { fetchExternalPrices };
};
