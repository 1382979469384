import { CheckIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    chakra,
    CloseButton,
    Container,
    Divider,
    Fade,
    Flex,
    HStack,
    Link,
    SlideFade,
    Spinner,
    Stack,
    Text,
} from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { DiscordLogo, GithubLogo, LinkedinLogo, TwitterLogo } from 'phosphor-react';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { transactionUri } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import { Alert as AlertType, PendingTxn, useUIState } from '../../state/ui-state';
import { BULLA_NETWORK_DISCORD_INVITE, reloadWindow } from '../../tools/common';
import { ChakraCompose } from '../../tools/types';
import { ChakraMotion } from '../base/animation';
import { TextButton } from '../inputs/buttons';
import { ItemDetailsModal } from '../modals/item-details-modal/item-details-modal';
import { WelcomeModal } from '../modals/welcome-modal';
import { Header } from './header';
import { MultisendMenu } from './multisend-menu';

export const ScrollToTop = () => {
    const { pathname } = useLocation();
    React.useEffect(() => window.scrollTo(0, 0), [pathname]);
    return null;
};

export const SummaryPanel = ({ children, ...overrides }: ChakraCompose) => (
    <MaxWidthWrapper w="100%" {...overrides}>
        {children}
    </MaxWidthWrapper>
);

export const MaxWidthWrapper = ({ children, ...props }: ChakraCompose) => (
    <Container maxW="pageMax" mx="auto" p="0" alignSelf="center" {...props}>
        {children}
    </Container>
);

export const TxnToast = ({ hash, status, error, id }: PendingTxn & { id: string }) => {
    const { connectedNetworkConfig } = useWeb3();
    const { archiveTx } = useUIState();
    const [isOpen, setIsOpen] = React.useState(['complete', 'failed', 'timeout', 'pending'].includes(status));

    const close = () => {
        archiveTx(id);
        setIsOpen(false);
    };

    React.useEffect(() => {
        if (status === 'complete') {
            setTimeout(close, 4000);
        }
    }, [status]);

    const completeToastElement = {
        bg: 'green.500',
        text: <Text data-testid="transactionComplete">Transaction Complete</Text>,
        icon: <CheckIcon />,
    };

    const toastElements = {
        pending: {
            bg: 'blue.500',
            text: <Text data-testid="transactionPending">Transaction Pending</Text>,
            icon: <Spinner />,
        },
        complete: completeToastElement,
        failed: {
            bg: 'red.500',
            text: <Text data-testid="transactionFailed">Transaction Failed</Text>,
            icon: <CloseIcon />,
        },
        timeout: {
            bg: 'yellow.500',
            text: <Text data-testid="transactionFailed">Transaction Timed Out</Text>,
            icon: <CloseIcon />,
        },
        archive: completeToastElement,
    };

    return (
        <>
            <Fade in={isOpen} unmountOnExit>
                {status && (
                    <Box
                        pos="relative"
                        color="white"
                        p={3}
                        bg={toastElements[status].bg}
                        borderRadius="lg"
                        shadow="md"
                        w="72"
                        textAlign={'center'}
                    >
                        <CloseButton size="sm" onClick={close} pos="absolute" right="2" top="1" />
                        <HStack justify="center" p="1">
                            {toastElements[status].icon}
                            {toastElements[status].text}
                        </HStack>
                        {(hash || status === 'failed') && <Divider my="1" />}
                        {status === 'failed' && <Text>{error}</Text>}
                        {status === 'timeout' && (
                            <TextButton color="white" onClick={reloadWindow}>
                                Please Refresh
                            </TextButton>
                        )}
                        {hash && connectedNetworkConfig !== undefined && (
                            <Text
                                as="button"
                                onClick={() => window.open(transactionUri(hash, connectedNetworkConfig))}
                                fontWeight="500"
                                textStyle="underline"
                            >
                                View On Block Explorer
                            </Text>
                        )}
                    </Box>
                )}
            </Fade>
        </>
    );
};

/** TODO: refactor to have pending txns and alerts in one array with different setters */
export const NotificationWindow = ({
    pendingTxns,
    alerts,
}: {
    pendingTxns: Record<string, PendingTxn>;
    alerts: Record<string, AlertType>;
}) => (
    <Stack
        left="50%"
        w="fit-content"
        transform={'translate(-50%)'}
        position="fixed"
        align="center"
        flex-direction="column"
        py="2"
        zIndex={10000}
    >
        <AnimatePresence>
            {Object.values(alerts).map(({ type, message, link, deleteAlert }, i) => (
                <ChakraMotion key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                    <Alert status={type} w="96" pr="8">
                        <AlertIcon />
                        {typeof message === 'string' ? (
                            <Box>
                                <Text>
                                    <chakra.span>
                                        {message}
                                        {link && (
                                            <Link href={link} isExternal ml="1">
                                                <chakra.span color={'scheme.accent_dark'}>View</chakra.span>
                                                <ExternalLinkIcon mt="-4px" color={'scheme.accent_dark'} />
                                            </Link>
                                        )}
                                    </chakra.span>
                                </Text>
                            </Box>
                        ) : (
                            message
                        )}

                        <CloseButton position="absolute" right="1.5" onClick={deleteAlert} />
                    </Alert>
                </ChakraMotion>
            ))}
        </AnimatePresence>
        {Object.entries(pendingTxns).map(([id, txn], i) => (
            <TxnToast key={i} {...txn} id={id} />
        ))}
    </Stack>
);

export const LoadingScreen = ({ isLoading, errorMessage }: { isLoading?: boolean; errorMessage: string | undefined }) => {
    const shouldShow = isLoading || !!errorMessage;
    return (
        <SlideFade in={shouldShow}>
            <Flex
                h="100vh"
                w="100vw"
                position="absolute"
                opacity="1"
                bg="white"
                zIndex={10000}
                direction="column"
                align="center"
                justify="center"
                display={shouldShow ? 'flex' : 'none'}
            >
                {errorMessage ? (
                    <Stack>
                        <Text>An error occured...</Text>
                        <Text>
                            {errorMessage?.includes('NETWORK_ERROR') || errorMessage?.includes('NO_NETWORK')
                                ? 'Issue connecting to the blockchain. Please refresh or contact support.'
                                : errorMessage}
                        </Text>
                        <Text as="button" fontWeight="500" textStyle="underline" onClick={reloadWindow}>
                            Refresh
                        </Text>
                    </Stack>
                ) : (
                    <Text>Loading Bulla network...</Text>
                )}
                {/* {!cache && ' for the first time (this may take a moment)'} */}
            </Flex>
        </SlideFade>
    );
};

export const Footer = () => (
    <Box
        px="12"
        bg={'scheme.accent_light'}
        w="100%"
        color={'headerBg'}
        borderTop="1px solid rgba(191, 199, 201, 0.49)"
        fontSize={'14px'}
        fontWeight={600}
    >
        <MaxWidthWrapper px="8" minH="15" py="8">
            <HStack mt="0" spacing={12}>
                <Link href="https://bulla-network.gitbook.io/bulla-network" isExternal fontSize={'14px'} fontWeight={600}>
                    <Text>Docs</Text>
                </Link>
                <Link href={BULLA_NETWORK_DISCORD_INVITE} isExternal fontSize={'14px'} fontWeight={600}>
                    <Text>Support</Text>
                </Link>
                <HStack pt="1">
                    <Link href="https://discord.gg/UbhMJfCs5j" isExternal fontSize={'14px'} fontWeight={600}>
                        <DiscordLogo size={18} weight="bold" />
                    </Link>
                    <Link href="https://www.linkedin.com/company/bulla-network/" isExternal fontSize={'14px'} fontWeight={600}>
                        <LinkedinLogo size={18} weight="bold" />
                    </Link>
                    <Link href="https://twitter.com/BullaNetwork" isExternal fontSize={'14px'} fontWeight={600}>
                        <TwitterLogo size={18} weight="bold" />
                    </Link>
                    <Link href="https://github.com/bulla-network/bulla-contracts" isExternal fontSize={'14px'} fontWeight={600}>
                        <GithubLogo size={18} weight="bold" />
                    </Link>
                </HStack>
            </HStack>
        </MaxWidthWrapper>
    </Box>
);

type PageLayoutContext = { scrollRef: React.RefObject<HTMLDivElement> | null };
const PageLayoutContext = React.createContext<PageLayoutContext>({ scrollRef: null });

export const PageLayoutProvider = ({ children }: { children: React.ReactNode }) => {
    const { pendingTxns, alerts } = useUIState();
    const scrollRef = React.useRef<HTMLDivElement>(null);

    return (
        <PageLayoutContext.Provider value={{ scrollRef }}>
            <Box p="0" overflowX="hidden" overflowY={'auto'} ref={scrollRef}>
                <Box p="0" h="100vh" maxW="100vw" display={'flex'} flexDirection="column" flex={1}>
                    <NotificationWindow pendingTxns={pendingTxns} alerts={alerts} />
                    <Header />
                    <ScrollToTop />
                    <ItemDetailsModal />
                    <WelcomeModal />
                    <MultisendMenu />
                    {/* ^^ utility components */}
                    {children}
                    <Footer />
                </Box>
            </Box>
        </PageLayoutContext.Provider>
    );
};

export const usePageScrollRef = () => {
    const { scrollRef } = useContext(PageLayoutContext);

    return scrollRef;
};
