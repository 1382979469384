import { ClaimInfo } from '../data-lib/data-model';
import { buildPayMultisendTransaction, buildRejectMultisendTransaction, buildRescindMultisendTransaction } from './useBullaClaim';
import { MultisendTransaction, useGnosisGlobalMultisend } from './useGnosisMultisend';
import { useWeb3 } from './useWeb3';

export function useTransactionBundle() {
    const {
        connectedNetworkConfig: { batchCreate, bullaClaimAddress },
    } = useWeb3();
    const { transactions, addTransaction } = useGnosisGlobalMultisend();

    const actOnClaims =
        (transactionBuilder: (contractAddress: string, claim: ClaimInfo) => MultisendTransaction) => (claims: ClaimInfo[]) => {
            const claimsToPay = claims.filter(x => x.claimStatus == 'Pending');
            const transactionCount = Object.values(transactions).length;
            const maxTransactions = batchCreate.maxClaims * 2;
            const availableTransactionCount = maxTransactions - transactionCount;

            claimsToPay
                .slice(0, availableTransactionCount)
                .map(claim => transactionBuilder(bullaClaimAddress, claim))
                .forEach(addTransaction);
        };

    const addPayClaimTransactionsToBundle = actOnClaims((contractAddress: string, claim: ClaimInfo) =>
        buildPayMultisendTransaction(contractAddress, claim.claimAmount.sub(claim.paidAmount), claim),
    );
    const addRejectClaimTransactionsToBundle = actOnClaims(buildRejectMultisendTransaction);
    const addRescindClaimTransactionsToBundle = actOnClaims(buildRescindMultisendTransaction);

    return { addPayClaimTransactionsToBundle, addRejectClaimTransactionsToBundle, addRescindClaimTransactionsToBundle };
}

export const filterSelectedClaims = (selectedTokenIds: string[], allClaims: ClaimInfo[]) => {
    const selectedTokenIdsSet = new Set(selectedTokenIds);
    return allClaims.filter(x => selectedTokenIdsSet.has(x.id));
};

export const getMultisendTooltipLabel = (isSafeConnected: boolean, isFull: boolean) =>
    !isSafeConnected ? 'Currently only supported for Gnosis Safes.' : isFull ? 'Transaction bundle is full' : undefined;
