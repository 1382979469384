import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Flex, HStack, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { NETWORKS } from '../../../data-lib/networks';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { usePagination } from '../../../hooks/usePagination';
import { NftTransfer, useGlobalUserData } from '../../../hooks/useUserData';
import { toDateDisplay } from '../../../tools/common';
import { handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import ExportMenu from '../../../tools/exportMenu';
import { FromAndToWallet } from '../../base/address-label';
import { ChainSymbol } from '../../chain-symbol';
import { ViewDetailsButton } from '../../inputs/buttons';
import {
    CHAIN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCard,
    ListViewCardProps,
    shadowProps,
} from '../../layout/cards';
import { PageLayoutProvider } from '../../layout/page-layout';
import { MOBILE_HEADERS, PageSelector } from '../claim-table';
import { ResponsiveStack } from '../responsive-stack';
import { EnterpriseViewTemplate } from './enterprise-view-template';
import {
    buildNftFilters,
    getInitialNftTransferFilterValues,
    NftFilterValues,
    NftTransferFilter,
    nftTransferFilterToPills,
} from './filters/claim-filter';
import { ClearFilterPillsStack } from './filters/common';

const NFT_TRANSFERS_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'collection', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'token id', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: '100px' },
];

interface NftScannerLinkProps {
    blockExplorer: string;
    parentTxId: string;
}

const NftScannerLink: React.FC<NftScannerLinkProps> = ({ blockExplorer, parentTxId }) => (
    <HStack onClick={() => window.open(`${blockExplorer}tx/${parentTxId}`, '_blank')} cursor={'pointer'}>
        <ViewDetailsButton onClick={() => null} />
        <ExternalLinkIcon />
    </HStack>
);

const _NftTransfers = () => {
    const isMobile = useIsMobile();
    const { nftTransfers } = useGlobalUserData('include-originating-claims');
    const [filters, setFilters] = React.useState<NftFilterValues>(() => getInitialNftTransferFilterValues());
    React.useEffect(() => setNftFilters(buildNftFilters(filters)), [filters]);

    const [nftFilters, setNftFilters] = React.useState<((item: NftTransfer) => boolean)[]>([]);
    const filterItems = (items: NftTransfer[]) => nftFilters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredItems = filterItems(nftTransfers);
    const filteredItemsWithoutBullaClaims = React.useMemo(
        () => filteredItems.filter(item => item.tokenName !== 'BullaClaim721'),
        [filteredItems],
    );
    const pageSelectorProps = usePagination(filteredItemsWithoutBullaClaims);
    const visibleTransfers = pageSelectorProps.shownItems;

    const handleExportNftTransfers = React.useCallback(
        async (method: 'excel' | 'csv') => {
            const headers = ['From', 'To', 'Chain', 'Date', 'Collection', 'Token ID'];

            // All reports should be sorted earliest to latest
            const filteredItemsEarliestToLatest = [...filteredItems].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

            const dataRows = filteredItemsEarliestToLatest.map(transfer => {
                const tokenIdAndSymbol = `#${transfer.tokenId} ${transfer.tokenSymbol}`;
                const chainLabel = NETWORKS[transfer.chainId].label;
                const formattedDate = toReportDateTimeString(transfer.timestamp);

                return [transfer.from, transfer.to, chainLabel, formattedDate, transfer.tokenName, tokenIdAndSymbol];
            });

            await handleExport(method, 'NFT Transfers', headers, dataRows);
        },
        [filteredItems],
    );

    const displayNftItems = React.useMemo(
        () =>
            visibleTransfers.map(({ to, chainId, from, id, tokenId, tokenName, timestamp, tokenSymbol, parentTxId }: NftTransfer) => {
                const date = toDateDisplay(timestamp);
                const tokenIdAndSymbol = <Text>{`#${tokenId} ${tokenSymbol}`}</Text>;
                const blockExplorer = NETWORKS[chainId].blockExplorer;

                return {
                    columnValues: isMobile
                        ? [date, tokenIdAndSymbol]
                        : [
                              <FromAndToWallet chainId={chainId} from={from} to={to} />,
                              <ChainSymbol chainId={chainId} />,
                              date,
                              <Text>{tokenName}</Text>,
                              tokenIdAndSymbol,
                              <NftScannerLink blockExplorer={blockExplorer} parentTxId={parentTxId} />,
                          ],
                    selectId: id,
                };
            }),
        [visibleTransfers, isMobile],
    );

    const mainSection = React.useMemo(
        () => (
            <>
                <ResponsiveStack align="flex-start" mb="4">
                    <NftTransferFilter filters={filters} setFilters={setFilters} nftTransfers={nftTransfers} />
                    <Spacer />
                </ResponsiveStack>
                <ClearFilterPillsStack
                    pb="4"
                    clearAll={() => setFilters(getInitialNftTransferFilterValues())}
                    filters={filters}
                    filtersToPills={nftTransferFilterToPills(setFilters)}
                />
                <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto">
                    <ListViewCard
                        headers={isMobile ? MOBILE_HEADERS : NFT_TRANSFERS_HEADERS}
                        displayedListItems={displayNftItems}
                        bordered={false}
                        flexHeight={true}
                        totalItemCount={visibleTransfers.length}
                    />
                </Flex>
                <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
            </>
        ),
        [displayNftItems, isMobile, filters],
    );
    return (
        <EnterpriseViewTemplate
            tab={'nft-transfers'}
            topRightWidget={<ExportMenu handleExport={handleExportNftTransfers} />}
            mainSection={mainSection}
        />
    );
};

export const NftTransfers = () => (
    <PageLayoutProvider>
        <_NftTransfers />
    </PageLayoutProvider>
);
