import { constants, providers } from 'ethers';
import React from 'react';
import { getERC20Contract } from '../data-lib/dto/contract-interfaces';
import { addressEquality, EthAddress } from '../data-lib/ethereum';
import { ChainId, NETWORKS, TokenInfo, TokenList } from '../data-lib/networks';
import { buildExternalTxToken, unknownToken } from '../data-lib/tokens';
import { STORAGE_KEYS } from '../tools/storage';
import { useLocalStorage } from './useStorage';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type TokenInfoResolver = (chainId: ChainId, tokenAddress: EthAddress) => Promise<TokenInfo>;
export type TokenInfoByChainIdAndAddress = (chainId: ChainId) => (address: string) => TokenInfo;
type TokenRepoContext = {
    resolveTokenInfo: TokenInfoResolver;
    getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress;
    allTokensLength: number;
    tokensByChainId: Record<number, TokenInfo[]>;
    erc20sByChainId: Record<number, TokenInfo[]>;
};

const TokenRepoContext = React.createContext<TokenRepoContext | undefined>(undefined);

export const TokenRepoProvider = ({ children }: { children: React.ReactNode }) => {
    const { providersByChainId } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const [localStorageCache, setLocalStorageCache] = useLocalStorage<TokenInfo[]>(STORAGE_KEYS.tokenInfoCache, []);
    const [fetchedTokens, setFetchedTokens] = React.useState<TokenInfo[]>(
        localStorageCache.filter(token => token.token.address !== constants.AddressZero && token.icon !== ''),
    );
    const { basicERC20Tokens, nativeTokens, basicERC20TokenHash } = React.useMemo(() => {
        const allNetworkConfigs = Object.values(NETWORKS);

        const basicERC20Tokens = allNetworkConfigs.flatMap(x => Object.values(x.supportedTokens));
        const basicERC20TokenHash = new Set(basicERC20Tokens.map(x => `${x.chainId}${x.token.address.toLowerCase()}`));
        const nativeTokens = Object.values(NETWORKS).map(x => x.nativeCurrency.tokenInfo);
        return { basicERC20Tokens, nativeTokens, basicERC20TokenHash };
    }, []);

    const _fetchUnknownToken = React.useCallback(async (chainId: ChainId, tokenAddress: EthAddress, provider: providers.Provider) => {
        const tokenContract = getERC20Contract(tokenAddress).connect(provider);
        const [symbol, decimals] = await Promise.all([tokenContract.symbol(), tokenContract.decimals()]);
        const tokenInfo = buildExternalTxToken(chainId, tokenAddress, decimals, symbol);

        return tokenInfo;
    }, []);

    const { allTokens, getTokenByChainIdAndAddress, tokensByChainId, erc20sByChainId } = React.useMemo(() => {
        const allERC20Tokens = [
            ...basicERC20Tokens,
            ...fetchedTokens.filter(x => !basicERC20TokenHash.has(`${x.chainId}${x.token.address.toLowerCase()}`)),
        ];
        const allTokens = [...nativeTokens, ...allERC20Tokens];

        const tokensByChainId = allTokens.reduce<{ [chainId: number]: TokenInfo[] }>(
            (acc, tokenInfo) => ({
                ...acc,
                [tokenInfo.chainId]: [...(acc[tokenInfo.chainId] ?? []), tokenInfo],
            }),
            {},
        );

        const erc20sByChainId = allERC20Tokens.reduce<{ [chainId: number]: TokenInfo[] }>(
            (acc, tokenInfo) => ({
                ...acc,
                [tokenInfo.chainId]: [...(acc[tokenInfo.chainId] ?? []), tokenInfo],
            }),
            {},
        );

        const tokensByAddressByChainId = allTokens.reduce<{ [chainId: number]: TokenList }>(
            (acc, tokenInfo) => ({
                ...acc,
                [tokenInfo.chainId]: {
                    ...(acc[tokenInfo.chainId] ?? {}),
                    [tokenInfo.token.address.toLowerCase()]: tokenInfo,
                },
            }),
            {},
        );

        const getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress = (chainId: ChainId) => (address: string) => {
            return tokensByAddressByChainId[chainId][address.toLowerCase()];
        };

        return { allTokens, getTokenByChainIdAndAddress, tokensByChainId, erc20sByChainId };
    }, [fetchedTokens.length, actingWallet]);

    const resolveTokenInfo: TokenInfoResolver = React.useCallback(
        async (chainId: ChainId, tokenAddress: EthAddress): Promise<TokenInfo> => {
            try {
                if (!tokenAddress) return unknownToken(chainId);

                const isNative = tokenAddress === constants.AddressZero;
                if (isNative) return NETWORKS[chainId].nativeCurrency.tokenInfo;

                const currentlyExistingToken = getTokenByChainIdAndAddress(chainId)(tokenAddress);
                if (!!currentlyExistingToken) return currentlyExistingToken;

                const newToken = await _fetchUnknownToken(chainId, tokenAddress, providersByChainId[chainId]);

                setFetchedTokens(prev => {
                    const existedBefore = prev.some(x => x.chainId == chainId && addressEquality(x.token.address, tokenAddress));
                    const updatedTokens = existedBefore ? prev : [...prev, newToken];
                    if (!existedBefore) {
                        setLocalStorageCache([...localStorageCache, newToken]);
                    }
                    return updatedTokens;
                });

                return newToken;
            } catch (e) {
                console.error(`Unable to resolve token on ${NETWORKS[chainId].label}: ${tokenAddress}`);
                console.error(e);
                return unknownToken(chainId);
            }
        },
        [providersByChainId, fetchedTokens.length],
    );

    const context = {
        getTokenByChainIdAndAddress,
        resolveTokenInfo,
        allTokensLength: allTokens.length,
        tokensByChainId,
        erc20sByChainId,
    };
    return <TokenRepoContext.Provider value={context}>{children}</TokenRepoContext.Provider>;
};

export const useTokenRepo = (): TokenRepoContext => {
    const context = React.useContext(TokenRepoContext);
    if (!context) throw new Error('Initialize provider first');

    return context;
};
