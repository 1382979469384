import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Drawer, DrawerContent, DrawerBody, VStack, Link, Box, Flex, DrawerOverlay } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CreateClaimModal } from '../modals/create-claim-modal/create-claim-modal';
import { useGlobalUserData } from '../../hooks/useUserData';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../tools/common';
import { useGettingStarted } from '../../hooks/useGettingStarted';

interface MobileMenuProps {
    isOpen: boolean;
    onClose: () => void;
}

enum Section {
    TRANSACTIONS = 'Transactions',
    EXPENSES = 'Expenses',
    HELP = 'Help',
}

type LinkType = {
    label: string;
    to?: string;
    action?: () => void;
    element?: () => JSX.Element;
};

interface SecondaryDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    drawerName: string;
    links: LinkType[];
}

const expensesLinks: LinkType[] = [
    {
        label: 'Request reimbursement',
        element: () => (
            <CreateClaimModal
                claimType="Invoice"
                defaults={{ tags: ['Expense'] }}
                triggerElement={onOpen => (
                    <Flex
                        onClick={onOpen}
                        _active={{ bg: 'gray.400' }}
                        h="56px"
                        borderRadius={'100px'}
                        px="20px"
                        align="center"
                        fontSize="md"
                        fontWeight="600"
                        w="100%"
                    >
                        Request reimbursement
                    </Flex>
                )}
            />
        ),
    },
    {
        label: 'Pay an expense',
        element: () => (
            <CreateClaimModal
                claimType="Payment"
                defaults={{ tags: ['Expense'] }}
                triggerElement={onOpen => (
                    <Flex
                        onClick={onOpen}
                        _active={{ bg: 'gray.400' }}
                        h="56px"
                        borderRadius={'100px'}
                        px="20px"
                        align="center"
                        fontSize="md"
                        fontWeight="600"
                        w="100%"
                    >
                        Pay an expense
                    </Flex>
                )}
            />
        ),
    },
    {
        label: 'View expenses',
        to: '/category/Expense',
    },
];

const transactionsLinks: LinkType[] = [
    { to: '/payments', label: 'Explore Transactions' },
    {
        label: '',
        element: () => (
            <Flex px="20px" align="center" fontWeight="500" w="100%" fontSize="13px" color="gray.500" mb="-4">
                Bulla Business
            </Flex>
        ),
    },
    { to: '/enterprise', label: 'Enterprise Dashboard' },
    { to: '/payables', label: 'Payables' },
    { to: '/receivables', label: 'Receivables' },
    { to: '/payments', label: 'Payments' },
];

interface MobileMenuItemProps {
    children: ReactNode;
    [props: string]: any;
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ children, ...props }) => {
    return (
        <Flex
            justify="space-between"
            align="center"
            _active={{ bg: 'gray.400' }}
            _hover={{ bg: 'gray.400', cursor: 'pointer' }}
            h="56px"
            borderRadius={'100px'}
            px="20px"
            fontSize="md"
            fontWeight="600"
            w="100%"
            {...props}
        >
            {children}
        </Flex>
    );
};

const SecondaryDrawer: React.FC<SecondaryDrawerProps> = ({ isOpen, onClose, drawerName, links }) => (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
            <DrawerContent mt="70px">
                <DrawerBody>
                    <VStack p="2" spacing="2" align="start">
                        <MobileMenuItem onClick={onClose}>
                            <Box>
                                <ArrowBackIcon boxSize={6} /> Main Menu
                            </Box>
                        </MobileMenuItem>
                        <Box fontSize="md" fontWeight="600" color="gray.500">
                            {drawerName}
                        </Box>
                        {links.map((link, i) => {
                            if (typeof link.to === 'string') {
                                const url = new URL(link.to, window.location.origin);

                                if (url.origin === window.location.origin) {
                                    return (
                                        <Link as={RouterLink} to={link.to} key={i}>
                                            <MobileMenuItem>{link.label}</MobileMenuItem>
                                        </Link>
                                    );
                                } else {
                                    return (
                                        <a href={link.to} target="_blank" rel="noopener noreferrer" key={i}>
                                            <MobileMenuItem>{link.label}</MobileMenuItem>
                                        </a>
                                    );
                                }
                            } else if (link.action) {
                                return (
                                    <MobileMenuItem key={i} onClick={link.action}>
                                        {link.label}
                                    </MobileMenuItem>
                                );
                            } else if (link.element) {
                                return (
                                    <Box key={i} w="100%" _hover={{ cursor: 'pointer' }}>
                                        {link.element()}
                                    </Box>
                                );
                            }
                        })}
                    </VStack>
                </DrawerBody>
            </DrawerContent>
        </DrawerOverlay>
    </Drawer>
);

export const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
    const { setGettingStartedEnabled } = useGettingStarted();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const goToGettingStarted = () => {
        setGettingStartedEnabled(true);
        if (pathname !== '/') navigate('/');
    };

    const helpLinks: LinkType[] = [
        {
            label: 'Getting Started',
            action: goToGettingStarted,
        },
        {
            label: 'Gitbook',
            to: 'https://bulla-network.gitbook.io/bulla-network/',
        },
        {
            label: 'Discord',
            to: BULLA_NETWORK_DISCORD_INVITE,
        },
    ];
    const [childDrawer, setChildDrawer] = useState({ isOpen: false, drawerName: '' });

    const openChildDrawer = (drawerName: Section) => {
        setChildDrawer({ isOpen: true, drawerName });
    };

    const closeChildDrawer = () => {
        setChildDrawer({ isOpen: false, drawerName: '' });
    };

    return (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay>
                <DrawerContent mt="70px">
                    <DrawerBody>
                        <VStack p="2" spacing="2" align="start">
                            <Link as={RouterLink} to="/" w="100%" _hover={{ textDecoration: 'none' }}>
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Home
                                    </Box>
                                </Flex>
                            </Link>
                            <Box
                                onClick={() => openChildDrawer(Section.TRANSACTIONS)}
                                w="100%"
                                _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Transactions
                                    </Box>
                                    <ArrowForwardIcon boxSize={6} />
                                </Flex>
                            </Box>
                            <Box
                                onClick={() => openChildDrawer(Section.EXPENSES)}
                                w="100%"
                                _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Expenses
                                    </Box>
                                    <ArrowForwardIcon boxSize={6} />
                                </Flex>
                            </Box>
                            <Link as={RouterLink} to="/contacts" w="100%" _hover={{ textDecoration: 'none' }}>
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Contacts
                                    </Box>
                                </Flex>
                            </Link>
                            <Link as={RouterLink} to="/financing" w="100%" _hover={{ textDecoration: 'none' }}>
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Financing
                                    </Box>
                                </Flex>
                            </Link>
                            <Box
                                onClick={() => openChildDrawer(Section.HELP)}
                                w="100%"
                                _hover={{ textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Help
                                    </Box>
                                    <ArrowForwardIcon boxSize={6} />
                                </Flex>
                            </Box>
                            <Link as={RouterLink} to="/settings" w="100%" _hover={{ textDecoration: 'none' }}>
                                <Flex
                                    justify="space-between"
                                    align="center"
                                    w="100%"
                                    h="56px"
                                    borderRadius={'100px'}
                                    px="20px"
                                    _active={{ bg: 'gray.400' }}
                                >
                                    <Box as="span" fontSize="md" fontWeight="600">
                                        Settings
                                    </Box>
                                </Flex>
                            </Link>
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>

            <SecondaryDrawer
                isOpen={childDrawer.drawerName == Section.TRANSACTIONS && childDrawer.isOpen}
                onClose={closeChildDrawer}
                drawerName={childDrawer.drawerName}
                links={transactionsLinks}
            />

            <SecondaryDrawer
                isOpen={childDrawer.drawerName == Section.HELP && childDrawer.isOpen}
                onClose={closeChildDrawer}
                drawerName={childDrawer.drawerName}
                links={helpLinks}
            />
        </Drawer>
    );
};
