import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useStorage';
import { TextButton } from '../inputs/buttons';
import { CloseModalButton } from './common';

export const WelcomeModal = () => {
    const { isOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { connected: inSafeApp } = useSafeAppsSDK();
    const [userRejectedSafeAppMessage, setUserRejectedSafeAppMessage] = useLocalStorage<boolean>('user-rejected-connect-safe-app-modal');
    const rejectSafeAppMessage = () => setUserRejectedSafeAppMessage(true);

    const messages = {
        safeAppPrompt: {
            closeMessage: () => {
                onClose();
                rejectSafeAppMessage();
                setTimeout(() => navigate('/'), 300);
            },
            header: <Text color="scheme.dark">Welcome to Safe App users!</Text>,
            Body: function ({ exitButton }: { exitButton: JSX.Element }) {
                return (
                    <Stack>
                        <Text>
                            We've created a Gnosis Safe module for safe owners to create and manage claims without requiring multi-signature
                            approval for each transaction.
                        </Text>
                        <Text>
                            If you continue within the Safe-UI, all contract interactions <b>will require multi-signature approval</b>.
                        </Text>
                        <Box h="2" />
                        <Center flexDir="column" w="100%" alignItems={'center'}>
                            <Button colorScheme="accent" h="12" onClick={() => window.open('https://banker.bulla.network/', '_blank')}>
                                <Text>View the full app</Text>
                                <ExternalLinkIcon ml="4px" />
                            </Button>
                            <Box h="4" />
                            {exitButton}
                        </Center>
                    </Stack>
                );
            },
        },
    };

    const showGnosisPrompt = false; //inSafeApp && !userRejectedSafeAppMessage;
    const message = messages['safeAppPrompt'];
    const welcomeMessageOpen = showGnosisPrompt || isOpen;
    const { Body, header, closeMessage } = message;

    return (
        <Modal isOpen={welcomeMessageOpen} onClose={closeMessage} isCentered size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{header}</ModalHeader>
                <CloseModalButton onClose={closeMessage} />
                <ModalBody py="8">
                    <Body
                        exitButton={
                            <TextButton onClick={closeMessage} colorScheme="accent" w="40" h="12">
                                Enter Safe App
                            </TextButton>
                        }
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
