import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { useEffect } from 'react';
import { addressEquality } from '../data-lib/ethereum';
import { ChainId, chainIds, NETWORKS } from '../data-lib/networks';
import { enableBftPricingOnTestnet, enableTcsPricingOnPolygon } from '../tools/featureFlags';
import { PropsWithChildren } from '../tools/props-with-children';
import { Replace } from '../tools/types';
import { fetchFactoringTokenHistoricalPrices } from './useBullaFactoring';
import { usePricesApi } from './usePricesApi';
import { useLocalStorage } from './useStorage';

type PriceFetchParams = { tokenAddress: string; chainId: ChainId; timestamp: number };
const getPriceFetchRef = ({ tokenAddress, chainId, timestamp }: PriceFetchParams) =>
    `${chainId}_${tokenAddress.toLowerCase()}_${timestamp}`;
type PendingFetches = { [ref: string]: PriceFetchParams };

type USDMarkDictionary = { [ref: string]: string };

export type PriceFetchResult = 'not-found' | 'fetching' | number;
export type Priced<T> = T & { usdMark: PriceFetchResult };

export type GetHistoricalTokenPrice = (query: Replace<PriceFetchParams, 'timestamp', Date>) => PriceFetchResult;

type HistoricalPricesContext = {
    USDMark: USDMarkDictionary;
    loadTokenPrices: (queries: Replace<PriceFetchParams, 'timestamp', Date>[]) => void;
    getHistoricalTokenPrice: GetHistoricalTokenPrice;
};
const HistoricalPricesContext = React.createContext<HistoricalPricesContext>({
    USDMark: {},
    loadTokenPrices: _ => {},
    getHistoricalTokenPrice: _ => 'fetching',
});

const findClosestPrice = (prices: USDMarkDictionary, priceFetchHash: string, targetTimestamp: number): string | undefined => {
    const [chainId, tokenAddress] = priceFetchHash.split('_');
    const relevantPrices = Object.entries(prices)
        .filter(([key]) => key.startsWith(`${chainId}_${tokenAddress}`))
        .map(([key, value]) => {
            const timestamp = parseInt(key.split('_')[2]);
            return { timestamp, price: value };
        })
        .sort((a, b) => b.timestamp - a.timestamp);

    const closestPrice = relevantPrices.find(price => price.timestamp <= targetTimestamp);
    return closestPrice?.price;
};

export const HistoricalPricesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [USDMark, setUSDMark] = useLocalStorage<USDMarkDictionary>('USDMark', {});
    const [pendingFetches, _setPendingFetches] = React.useState<PendingFetches>({});
    const [fetching, setFetching] = React.useState(false);
    const { fetchExternalPrices } = usePricesApi();

    useEffect(() => {
        const pendingFetchParams = Object.values(pendingFetches);
        const pendingFetchHashes = Object.keys(pendingFetches);
        if (pendingFetchParams.length == 0) return;
        setFetching(true);
        const pendingFetchSet = new Set(pendingFetchHashes);
        const preparedPriceQuery = {
            body: pendingFetchParams.map(({ chainId, tokenAddress, timestamp }) => ({
                chainId: chainId as number,
                contractAddress: tokenAddress,
                amount: '1',
                timestamp,
            })),
            currency: 'usd',
        };
        fetchExternalPrices(preparedPriceQuery)
            .then(results => {
                setUSDMark(prev => results.reduce((acc, item, index) => ({ ...acc, [pendingFetchHashes[index]]: item.price }), prev));
                _setPendingFetches(prev => Object.fromEntries(Object.entries(prev).filter(([key]) => !pendingFetchSet.has(key))));
            })
            .catch(console.warn)
            .finally(() => setFetching(false));
    }, [pendingFetches, fetching]);

    useEffect(() => {
        const fetchFactoringTokenPrices = async () => {
            for (const network of Object.values(NETWORKS)) {
                if (network.factoringConfig && enableBftPricingOnTestnet) {
                    const bullaFactoringToken = network.factoringConfig.bullaFactoringToken;
                    const poolTokenAddress = bullaFactoringToken.token.address;

                    try {
                        const historicalPrices = await fetchFactoringTokenHistoricalPrices(network.chainId, poolTokenAddress);
                        const newPrices: USDMarkDictionary = {};
                        historicalPrices.forEach(({ poolTokenAddress, chainId, timestamp, price }) => {
                            const priceFetchHash = getPriceFetchRef({ tokenAddress: poolTokenAddress, chainId, timestamp });
                            const formattedPrice = formatUnits(BigNumber.from(price), bullaFactoringToken.token.decimals);
                            newPrices[priceFetchHash] = formattedPrice;
                        });
                        setUSDMark(prev => ({ ...prev, ...newPrices }));
                    } catch (error) {
                        console.error(`Failed to fetch factoring token prices for chain ${network.chainId}:`, error);
                    }
                }
            }
        };

        fetchFactoringTokenPrices();
        const intervalId = setInterval(fetchFactoringTokenPrices, 60000);

        return () => clearInterval(intervalId);
    }, []);

    const context = React.useMemo(() => {
        const _getTokenPrice = (pfp: Replace<PriceFetchParams, 'timestamp', Date>) => {
            const timestamp = Math.floor(pfp.timestamp.getTime() / 1000);
            const priceFetchParams = { ...pfp, timestamp };
            const priceFetchHash = getPriceFetchRef(priceFetchParams);
            const cachedUsdMarkResult = USDMark[priceFetchHash];

            const price: PriceFetchResult = NETWORKS[pfp.chainId].isTestnet
                ? 'not-found'
                : cachedUsdMarkResult == 'not-found'
                ? 'not-found'
                : !cachedUsdMarkResult
                ? 'fetching'
                : Number(cachedUsdMarkResult);

            return {
                priceFetchHash,
                price,
                priceFetchParams,
            };
        };

        const loadTokenPrices: HistoricalPricesContext['loadTokenPrices'] = priceFetchParams => {
            const toFetch = priceFetchParams.reduce<PendingFetches>((acc, pfp) => {
                const { priceFetchHash, price, priceFetchParams } = _getTokenPrice(pfp);

                return price !== 'fetching' ? acc : { ...acc, [priceFetchHash]: priceFetchParams };
            }, {});

            if (Object.keys(toFetch).length !== 0) _setPendingFetches(prev => ({ ...prev, ...toFetch }));
        };

        const getTokenPrice = (pfp: Replace<PriceFetchParams, 'timestamp', Date>): PriceFetchResult => {
            const timestamp = Math.floor(pfp.timestamp.getTime() / 1000);
            const priceFetchParams = { ...pfp, timestamp };
            const priceFetchHash = getPriceFetchRef(priceFetchParams);

            const network = NETWORKS[pfp.chainId];
            const isBFT =
                network.factoringConfig && addressEquality(pfp.tokenAddress, network.factoringConfig.bullaFactoringToken.token.address);

            const isTCS =
                network.chainId === chainIds.MATIC &&
                addressEquality(pfp.tokenAddress, NETWORKS[chainIds.MATIC].supportedTokens.TCS.token.address);

            // Special handling for TCS on Polygon
            if (isTCS && enableTcsPricingOnPolygon) {
                return 0.1; // Set TCS price to $0.1
            }

            // Special handling for BFT on factoring supported networks
            if (isBFT && network.factoringConfig) {
                let underlyingPrice: PriceFetchResult;

                if (network.isTestnet && enableBftPricingOnTestnet) {
                    underlyingPrice = 1;
                } else if (network.isTestnet) {
                    underlyingPrice = 'not-found';
                } else {
                    // Fetch the factoring pool underlying asset price
                    const underlyingAsset = network.factoringConfig.poolUnderlyingToken;
                    const underlyingPriceFetchParams = {
                        ...priceFetchParams,
                        tokenAddress: underlyingAsset.token.address,
                        timestamp: new Date(timestamp * 1000),
                    };
                    underlyingPrice = getTokenPrice(underlyingPriceFetchParams);
                }

                // Apply BFT price per share (closest price)
                const closestPrice = findClosestPrice(USDMark, priceFetchHash, timestamp);
                if (closestPrice && underlyingPrice !== 'not-found' && underlyingPrice !== 'fetching') {
                    return Number(closestPrice) * underlyingPrice;
                }
                return underlyingPrice;
            } else {
                const { price } = _getTokenPrice(pfp);
                if (price === 'not-found' || price === 'fetching') return price;
                if (isNaN(price)) return 'not-found';
                return Number(price);
            }
        };

        return { USDMark, loadTokenPrices, getHistoricalTokenPrice: getTokenPrice };
    }, [USDMark]);

    return <HistoricalPricesContext.Provider value={context}>{children}</HistoricalPricesContext.Provider>;
};

export const useHistoricalPrices = () => {
    const context = React.useContext(HistoricalPricesContext);
    if (context == undefined) throw new Error('historical prices not initialized');

    return context;
};
