import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

const GradientChart = ({ value }: { value: string }) => {
    const series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined = [Number(value) / 10];
    let gradientToColors = ['#F44336'];
    let colorStops = [
        {
            offset: 0,
            color: '#F44336',
            opacity: 1,
        },
    ];

    if (Number(value) / 10 > 35 && Number(value) / 10 <= 60) {
        gradientToColors = ['#F44336', '#FDD835'];
        colorStops = [
            {
                offset: 0,
                color: '#F44336',
                opacity: 1,
            },
            {
                offset: 100,
                color: '#FDD835',
                opacity: 1,
            },
        ];
    } else if (Number(value) / 10 > 60) {
        gradientToColors = ['#F44336', '#FDD835', '#4CAF50'];
        colorStops = [
            {
                offset: 0,
                color: '#F44336',
                opacity: 1,
            },
            {
                offset: 50,
                color: '#FDD835',
                opacity: 1,
            },
            {
                offset: 100,
                color: '#4CAF50',
                opacity: 1,
            },
        ];
    }

    const options: ApexOptions = {
        chart: {
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    size: '70%',
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '0',
                    },
                },
                track: {
                    background: 'gray.900',
                    strokeWidth: '70%',
                    margin: 0,
                },
                startAngle: -90,
                endAngle: 90,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                gradientToColors: gradientToColors,
                shadeIntensity: 0.5,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 100],
                colorStops: colorStops,
            },
        },
    };

    return <ReactApexChart options={options} series={series} type="radialBar" height={240} width={170} />;
};

export default GradientChart;
