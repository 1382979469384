import { Log } from '@ethersproject/abstract-provider';
import { BigNumber, utils } from 'ethers';
import { TransactionDescription } from 'ethers/lib/utils';
import { TokenInfoResolver } from '../../hooks/useTokenRepo';
import { PendingInstantPaymentInfo, TAG_SEPARATOR } from '../data-model';
import { BullaBankerCreatedEvent, BullaTagUpdatedEvent } from '../domain/bulla-banker-domain';
import {
    BullaManagerSetEvent,
    ClaimCreatedEvent,
    ClaimPaymentEvent,
    ClaimRejectedEvent,
    ClaimRescindedEvent,
    FeePaidEvent,
} from '../domain/bulla-claim-domain';
import { FinancingAcceptedEvent, FinancingOfferedEvent } from '../domain/bulla-finance-domain';
import { InstantPaymentEvent, InstantPaymentTagUpdatedEvent } from '../domain/bulla-instant-pay-domain';
import {
    BullaBankerModuleDeployEvent,
    DisabledModuleEvent,
    EnabledModuleEvent,
    ERC20TransferEvent,
    ERC721TransferEvent,
} from '../domain/common-domain';
import { LoanOfferAcceptedEvent, LoanOfferedEvent, LoanOfferRejectedEvent } from '../domain/frendlend-domain';
import { defaultDate, handleBytes32, intToDate, ZERO_BIGNUMBER } from '../helpers';
import { getCIDFromMultihashStruct } from '../multihash';
import { ChainId } from '../networks';

export const mapToBullaBankerCreatedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { blockNumber, transactionHash, logIndex }: Log,
): BullaBankerCreatedEvent => ({
    __typename: 'BullaBankerCreatedEvent',
    bullaManager: args?.bullaManager ?? '',
    bullaBanker: args?.bullaBanker ?? '',
    bullaClaimERC721: args?.bullaClaimERC721 ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: intToDate(args?.blocktime),
});

export const mapToBullaTagUpdatedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { blockNumber, transactionHash, logIndex }: Log,
): BullaTagUpdatedEvent => ({
    __typename: 'BullaTagUpdatedEvent',
    managerAddress: args?.bullaManager ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    tag: handleBytes32(args?.tag) ?? '',
    updatedBy: args?.updatedBy ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: intToDate(args?.blocktime),
});

export const mapToLoanOfferedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): LoanOfferedEvent => {
    const event: LoanOfferedEvent = {
        __typename: 'LoanOfferedEvent',
        interestBPS: args?.loanOffer?.interestBPS ?? 0,
        loanAmount: BigNumber.from(args?.loanOffer?.loanAmount ?? 0),
        termLength: BigNumber.from(args?.loanOffer?.termLength ?? 0),
        eventType: name ?? '',
        blockNumber,
        txHash: transactionHash,
        logIndex,
        blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
        creditor: args?.loanOffer?.creditor ?? '',
        debtor: args?.loanOffer?.debtor ?? '',
        ipfsHash: args?.loanOffer?.attachment ? getCIDFromMultihashStruct(args?.loanOffer?.attachment) || '' : '',
        description: args?.loanOffer?.description ?? '',
        token: args?.loanOffer?.claimToken ?? '',
        loanId: args?.loanId instanceof BigNumber ? args?.loanId.toString() : '',
        offeredBy: args?.offeredBy ?? '',
    };

    console.log({ event });
    return event;
};

export const mapToLoanOfferAcceptedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): LoanOfferAcceptedEvent => {
    const event: LoanOfferAcceptedEvent = {
        __typename: 'LoanOfferAcceptedEvent',
        eventType: name ?? '',
        blockNumber,
        txHash: transactionHash,
        logIndex,
        blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
        loanId: args?.loanId instanceof BigNumber ? args?.loanId.toString() : '',
        tokenId: args?.claimId instanceof BigNumber ? args?.claimId.toString() : '',
    };

    console.log({ event });
    return event;
};

export const mapToLoanOfferRejectedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): LoanOfferRejectedEvent => {
    const event: LoanOfferRejectedEvent = {
        __typename: 'LoanOfferRejectedEvent',
        eventType: name ?? '',
        blockNumber,
        txHash: transactionHash,
        logIndex,
        blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
        loanId: args?.loanId instanceof BigNumber ? args?.loanId.toString() : '',
        rejectedBy: args?.rejectedBy ?? '',
    };

    console.log({ event });
    return event;
};

export const mapToClaimCreatedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ClaimCreatedEvent => ({
    __typename: 'ClaimCreatedEvent',
    bullaManager: args?.bullaManager ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    origin: args?.origin ?? '',
    creditor: args?.creditor ?? '',
    debtor: args?.debtor ?? '',
    parent: args?.parent ?? '',
    ipfsHash: args?.claim?.attachment ? getCIDFromMultihashStruct(args.claim.attachment) || '' : '',
    description: args?.description ?? '',
    claimAmount: args?.claim?.claimAmount ?? ZERO_BIGNUMBER,
    dueBy: args?.claim?.dueBy ? intToDate(args.claim.dueBy) : defaultDate,
    token: args?.claim?.claimToken ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToClaimPaymentEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ClaimPaymentEvent => {
    return {
        __typename: 'ClaimPaymentEvent',
        bullaManager: args?.bullaManager ?? '',
        tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
        debtor: args?.debtor ?? '',
        paidBy: args?.paidBy ?? '',
        paymentAmount: args?.paymentAmount ?? ZERO_BIGNUMBER,
        eventType: name ?? '',
        blockNumber,
        txHash: transactionHash,
        logIndex,
        blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
        creditor: undefined,
    };
};

export const mapToClaimRejectedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ClaimRejectedEvent => ({
    __typename: 'ClaimRejectedEvent',
    managerAddress: args?.managerAddress ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToClaimRescindedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ClaimRescindedEvent => ({
    __typename: 'ClaimRescindedEvent',
    bullaManager: args?.bullaManager ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToFeePaidEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, logIndex, blockNumber }: Log,
): FeePaidEvent => ({
    __typename: 'FeePaidEvent',
    bullaManager: args?.bullaManager ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    collectionAddress: args?.collectionAddress ?? '',
    paymentAmount: args?.paymentAmount ?? '',
    transactionFee: args?.transactionFee ?? ZERO_BIGNUMBER,
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToBullaManagerSetEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): BullaManagerSetEvent => ({
    __typename: 'BullaManagerSetEvent',
    newBullaManager: args?.newBullaManager ?? '',
    prevBullaManager: args?.prevBullaManager ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToFinancingOfferedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): FinancingOfferedEvent => {
    return {
        __typename: 'FinancingOfferedEvent',
        tokenId: args?.originatingClaimId instanceof BigNumber ? args?.originatingClaimId.toString() : '',
        minDownPaymentBPS: args?.terms[0] ?? 0,
        interestBPS: args?.terms[1] ?? 0,
        termLength: BigNumber.from(args?.terms[2] ?? 0),
        eventType: name ?? '',
        blockNumber,
        txHash: transactionHash,
        logIndex,
        blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
    };
};

export const mapToFinancingAcceptedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): FinancingAcceptedEvent => ({
    __typename: 'FinancingAcceptedEvent',
    tokenId: args?.financedClaimId instanceof BigNumber ? args?.financedClaimId.toString() : '',
    originatingClaimId: args?.originatingClaimId instanceof BigNumber ? args?.originatingClaimId.toString() : '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToBullaBankerModuleDeployEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { blockNumber, transactionHash, logIndex }: Log,
): BullaBankerModuleDeployEvent => ({
    __typename: 'BullaBankerModuleDeployEvent',
    version: args?.version ?? '',
    safe: args?.safe ?? '',
    initiator: args?.initiator ?? '',
    moduleAddress: args?.moduleAddress ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
});

export const mapToModuleEnabledEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { blockNumber, transactionHash, logIndex }: Log,
): EnabledModuleEvent => ({
    __typename: 'EnabledModuleEvent',
    module: args?.module ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
});

export const mapToModuleDisabledEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { blockNumber, transactionHash, logIndex }: Log,
): DisabledModuleEvent => ({
    __typename: 'DisabledModuleEvent',
    module: args?.module ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
});

export const mapToInstantPaymentEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InstantPaymentEvent => ({
    __typename: 'InstantPaymentEvent',
    from: args?.from ?? '',
    to: args?.to ?? '',
    amount: args?.amount ?? '',
    tokenAddress: args?.tokenAddress ?? '',
    description: args?.description ?? '',
    tag: args?.tag ?? '',
    ipfsHash: args?.ipfsHash ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToInstantPaymentTagUpdatedEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): InstantPaymentTagUpdatedEvent => ({
    __typename: 'InstantPaymentTagUpdatedEvent',
    txAndLogIndexHash: args?.txAndLogIndexHash ?? '',
    updatedBy: args?.updatedBy ?? '',
    tag: args?.tag ?? '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
    blocktime: args?.blocktime ? intToDate(args.blocktime) : defaultDate,
});

export const mapToERC20TransferEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ERC20TransferEvent => ({
    __typename: 'ERC20TransferEvent',
    from: args?.from ?? '',
    to: args?.to ?? '',
    amount: args?.[2] instanceof BigNumber ? args?.[2] : ZERO_BIGNUMBER,
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
});

export const mapToERC721TransferEvent = (
    { args, name }: Partial<utils.LogDescription>,
    { transactionHash, blockNumber, logIndex }: Log,
): ERC721TransferEvent => ({
    __typename: 'ERC721TransferEvent',
    from: args?.from ?? '',
    to: args?.to ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    eventType: name ?? '',
    blockNumber,
    txHash: transactionHash,
    logIndex,
});

export const mapToTransferEvent = (logDesc: Partial<utils.LogDescription>, log: Log): ERC721TransferEvent | ERC20TransferEvent => {
    const transferType: 'ERC20' | 'ERC721' | 'UNKNOWN' =
        logDesc.args?.tokenId instanceof BigNumber ? 'ERC721' : logDesc.args?.amount instanceof BigNumber ? 'ERC20' : 'UNKNOWN';

    if (transferType === 'ERC20') return mapToERC20TransferEvent(logDesc, log);
    else if (transferType === 'UNKNOWN') return mapToERC20TransferEvent(logDesc, log);
    // some old ERC20 contracts (like WETH) say "wad" instead of "amount"
    else return mapToERC721TransferEvent(logDesc, log);
};

export const mapToPendingInstantPaymentInfo = async (
    from: string,
    txDescription: Partial<TransactionDescription>,
    index: number,
    chainId: ChainId,
    resolveTokenInfo: TokenInfoResolver,
): Promise<PendingInstantPaymentInfo> => {
    const { args } = txDescription;
    const id = txDescription.sighash + index.toString();

    return {
        __type: 'PendingInstantPayment',
        id,
        paidAmount: args?.paymentAmount ?? args?.amount ?? ZERO_BIGNUMBER,
        chainId,
        creditor: args?.to ?? '',
        debtor: from,
        ipfsHash: args?.ipfsHash ?? '',
        description: args?.description ?? '',
        tags: (args?.tag ?? '').split(TAG_SEPARATOR),
        created: defaultDate,
        tokenInfo: await resolveTokenInfo(chainId, args?.tokenAddress ?? ''),
        logs: [],
        notes: '',
    };
};
