import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React from 'react';
import { BullaItemInfo, ClaimInfo, ClaimType, lastPaymentDate } from '../../../data-lib/data-model';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { getFactoringPaymentDetails, isClaim, isFactored } from '../../../data-lib/helpers';
import { NETWORKS } from '../../../data-lib/networks';
import { useOpenBullaItem } from '../../../hooks/useClaimDetailDisclosure';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { toDateDisplay } from '../../../tools/common';
import { handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import ExportMenu from '../../../tools/exportMenu';
import { getSoftledgerCodesByStatus, getStatusString, parseStatusToSoftledgerCode } from '../../base/status-badge';
import { MenuDropdownButton, OrangeButton, secondaryButtonProps } from '../../inputs/buttons';
import { PageLayoutProvider } from '../../layout/page-layout';
import { CreateClaimModal } from '../../modals/create-claim-modal/create-claim-modal';
import { Categories, mapBullaItemInfoToTableItem, TableItem } from '../claim-table';
import { AccountTagView, emptyFilterValues } from './account-tag-view';
import { EnterpriseViewTemplate } from './enterprise-view-template';
import { buildItemInfoFilters, PaymentFilterValues } from './filters/claim-filter';
import { TableFilter } from './filters/common';

interface ClaimsPageProps {
    claims: BullaItemInfo[];
    claimType: ClaimType;
    tabName: 'payables' | 'receivables';
    dropdownActions: React.ReactNode[];
    actionButtonLabel: string;
    selected: string[];
    setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ClaimsPage: React.FC<ClaimsPageProps> = ({
    claims,
    claimType,
    tabName,
    actionButtonLabel,
    dropdownActions,
    selected,
    setSelected,
}) => {
    const [category, setCategory] = React.useState<Categories>('Pending');
    const [filters, setFilters] = React.useState<PaymentFilterValues>(emptyFilterValues);
    const [claimFilters, setClaimFilters] = React.useState<TableFilter<BullaItemInfo>[] | []>([]);
    const actingWallet = useActingWalletAddress();
    React.useEffect(() => setClaimFilters(buildItemInfoFilters(actingWallet)(filters)), [filters]);
    const { getHistoricalTokenPrice } = useHistoricalPrices();
    const userAddress = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const lineItems = mapBullaItemInfoToTableItem(claims, userAddress, openItem);

    const filterItems = (items: TableItem[]) => [...claimFilters].reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredItems = filterItems(lineItems);

    const handleExportClaims = React.useCallback(
        async (method: 'excel' | 'csv') => {
            const headers = [
                'Debtor',
                'Creditor',
                'Chain ID',
                'Chain',
                'Claim Token Address',
                'Symbol',
                'Claim ID',
                'Status',
                'Code',
                'Created Date',
                'Due Date',
                'Paid Date',
                'Description',
                'Categories',
                'Token Quantity',
                'Received Token Quantity',
                'MARK at Issue',
                'MARK at Payment',
                'Value at Issue',
                'Value at Payment',
                'Debit Receivables',
                'Credit Receivables',
                'Debit Receivables',
                'Credit Receivables',
                'Tx Hash',
            ];

            // All reports should be sorted earliest to latest
            const filteredItemsEarliestToLatest = [...filteredItems].sort((a, b) => a.created.getTime() - b.created.getTime());

            const dataRows = filteredItemsEarliestToLatest.map(x => {
                const { debtor, creditor, description, tokenInfo, direction, chainId, displayDate, created, txHash, id, paidAmount } = x;
                const tags = x.tags.filter(x => x !== '');
                const chainLabel = NETWORKS[chainId].label;
                const status = getStatusString(x.itemStatus, direction);
                const tokenQuantity = `${weiToDisplayAmt({ amountWei: x.displayAmount, token: tokenInfo.token })} ${
                    tokenInfo.token.symbol
                }`;
                const factoringPayments =
                    isClaim(x) &&
                    isFactored(x as ClaimInfo, userAddress) &&
                    getFactoringPaymentDetails(x as ClaimInfo).reduce((acc, curr) => acc.add(curr.payment), BigNumber.from(0));
                const receivedTokenQuantity = factoringPayments
                    ? `${weiToDisplayAmt({ amountWei: factoringPayments, token: tokenInfo.token })}`
                    : `${weiToDisplayAmt({ amountWei: paidAmount, token: tokenInfo.token })}`;
                const formattedCreatedDate = toReportDateTimeString(created);
                const formattedDisplayDate = toReportDateTimeString(displayDate);
                const tokenAddress = tokenInfo.token.address;
                const tokenSymbol = tokenInfo.token.symbol;
                const softLedgerCode = parseStatusToSoftledgerCode(status);
                const paidDate = status === 'Paid ' ? toReportDateTimeString(lastPaymentDate(x)) : '';
                const historicalPrice = getHistoricalTokenPrice({
                    chainId: x.chainId,
                    tokenAddress: x.tokenInfo.token.address,
                    timestamp: new Date(x.created),
                });
                const lastPaymentDate_ = lastPaymentDate(x);
                const priceAtPayment =
                    x.itemStatus === 'Paid'
                        ? getHistoricalTokenPrice({
                              chainId: x.chainId,
                              tokenAddress: x.tokenInfo.token.address,
                              timestamp: new Date(lastPaymentDate_),
                          })
                        : '';
                const valueAtIssue = parseFloat(tokenQuantity) * parseFloat(historicalPrice.toString()) || '';
                const valueAtPayment = parseFloat(receivedTokenQuantity) * parseFloat(priceAtPayment.toString()) || '';

                const [drCode1, crCode1, drCode2, crCode2] = getSoftledgerCodesByStatus(softLedgerCode);

                return [
                    debtor,
                    creditor,
                    chainId,
                    chainLabel,
                    tokenAddress,
                    tokenSymbol,
                    id,
                    status,
                    softLedgerCode,
                    formattedCreatedDate,
                    formattedDisplayDate,
                    paidDate,
                    description,
                    tags.length == 0 ? '' : `"${x.tags.join(',')}"`,
                    tokenQuantity,
                    receivedTokenQuantity,
                    historicalPrice,
                    priceAtPayment,
                    valueAtIssue,
                    valueAtPayment,
                    drCode1,
                    crCode1,
                    drCode2,
                    crCode2,
                    txHash ?? '',
                ];
            });

            await handleExport(method, tabName, headers, dataRows);
        },
        [filteredItems],
    );

    const topRightWidget = <ExportMenu handleExport={handleExportClaims} />;

    const mainSection = (
        <AccountTagView
            items={filteredItems}
            category={category}
            setCategory={setCategory}
            setSelected={setSelected}
            filters={filters}
            setFilters={setFilters}
            claimFilters={claimFilters}
            actionButton={
                <HStack>
                    <CreateClaimModal
                        claimType={claimType}
                        triggerElement={onOpen => (
                            <OrangeButton onClick={onOpen} h="10" px="4">
                                {actionButtonLabel}
                            </OrangeButton>
                        )}
                    />
                    <MenuDropdownButton
                        h="10"
                        py="0"
                        px="4"
                        text={`Actions${selected.length == 0 ? '' : ` (${selected.length})`}`}
                        icon={<ChevronDownIcon w="6" h="6" />}
                        actions={dropdownActions}
                        isDisabled={selected.length == 0}
                        {...secondaryButtonProps}
                    />
                </HStack>
            }
            p="0"
            tab={tabName}
        />
    );

    return (
        <PageLayoutProvider>
            <EnterpriseViewTemplate tab={tabName} mainSection={mainSection} topRightWidget={topRightWidget} />
        </PageLayoutProvider>
    );
};
