import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { BigNumber, errors } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import React, { useEffect, useState } from 'react';
import { chainIds, FactoringConfig, NETWORKS, TokenDto, TokenVariant } from '../../data-lib/networks';
import { useBullaFactoring } from '../../hooks/useBullaFactoring';
import { useGnosisTransaction } from '../../hooks/useGnosisTransaction';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { AlertInfo } from '../base/alert';
import { OrangeButton, SecondaryButton } from '../inputs/buttons';
import { CloseModalButton } from './common';
import { EmailField, FileUploadField, FormLabelWithRequiredness, isNumberInputValid } from './create-claim-modal/create-claim-inputs';
import * as Yup from 'yup';
import { Formik, Field, FieldProps, Form } from 'formik';
import { BullaFileObject, BullaItemAttachment, UploadedFile } from './create-claim-modal/create-claim-modal';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { UploadSection } from './deposit-funds-modal';
import { TOKEN_ROUNDING } from '../../data-lib/tokens';
import { useTokenBalances } from '../../hooks/useChainData';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useCompanyDetailsRepo } from '../../hooks/useCompanyDetailsRepo';
import { isAttachmentReady, pinHash, resolveBullaAttachmentToCID } from '../../tools/ipfs';
import { getMultihashStructFromCID } from '../../data-lib/multihash';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { shortAddress } from '../base/address-label';
import { WithSkeleton } from '../base/skeleton';

const RedeemSummary = ({
    redeemAmount,
    pricePerShare,
    fundTokenDecimals,
    underlyingTokenSymbol,
}: {
    redeemAmount: string;
    pricePerShare: BigNumber | null;
    fundTokenDecimals: number;
    underlyingTokenSymbol: string;
}) => {
    const convert = (amount: string, price: BigNumber | null) => {
        if (!price) return '0';
        const parsedAmount = parseUnits(amount, fundTokenDecimals);
        const scaleFactor = BigNumber.from(10).pow(fundTokenDecimals);
        const convertedAmount = parsedAmount.mul(price).div(scaleFactor);
        return formatUnits(convertedAmount, fundTokenDecimals);
    };

    return (
        <VStack spacing="4" borderRadius={'xl'} p="5" border="1px solid" borderColor={'gray.200'} my="2" alignItems={'left'}>
            <Text fontWeight={'600'}>To receive</Text>
            <HStack>
                <FiPlus color="#3b7a52" width={'50px'} />
                <Text color="#3b7a52" ml="-1">
                    {convert(redeemAmount, pricePerShare)}
                </Text>
                <Text>{underlyingTokenSymbol}</Text>
            </HStack>
            <HStack>
                <FiMinus color="#a53124" width={'50px'} />
                <Text color="#a53124" ml="-1">
                    {parseFloat(redeemAmount[0] === '.' ? '0' + redeemAmount : redeemAmount)}
                </Text>
                <Text>Bulla Fund Token</Text>
            </HStack>
        </VStack>
    );
};

const RedeemValidationSchema = Yup.object().shape({
    redeemAmount: Yup.number().required('Redeem amount is required'),
    emailAddress: Yup.string().email('Invalid email address'),
});

export const RedeemFundsModal: React.FC<{ modalOpen: boolean; closeModal: () => void; factoringConfig: FactoringConfig }> = ({
    modalOpen,
    closeModal,
    factoringConfig,
}) => {
    const { connectedNetwork } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const fundTokenInfo = factoringConfig.bullaFactoringToken;
    const bullaFundTokenAddress = fundTokenInfo.token.address.toLowerCase();
    const fundTokenSymbol = fundTokenInfo.token.symbol;
    const fundTokenDecimals = fundTokenInfo.token.decimals;
    const poolUnderlyingTokenInfo = factoringConfig.poolUnderlyingToken;
    const poolChainId = factoringConfig.bullaFactoringToken.chainId;

    const bullaFundAddress = factoringConfig.bullaFactoringToken.token.address;
    const { safeInfo } = useGnosisSafe();
    const { readyToTransact } = useAppState();
    const [executing, { getPricePerShare, redeemInputToMultisendTxDTO, redeemHandler, maxRedeemAmout, getPoolInfo, previewRedeem }] =
        useBullaFactoring(factoringConfig);
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const { executeTransactions } = useGnosisTransaction();
    const [poolName, setPoolName] = React.useState<string>('');
    const usePreviewRedeem = factoringConfig.bullaFactoringToken.chainId === chainIds.SEPOLIA;

    const isLoading = executing || executingSafeTx;
    const isDisabled = !readyToTransact;
    const blockExplorer = connectedNetwork ? NETWORKS[connectedNetwork].blockExplorer : undefined;

    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const tokenBalances = useTokenBalances({ chainId: poolChainId, poll: true });
    const tokenRouding = TOKEN_ROUNDING[getTokenByChainIdAndAddress(poolChainId)(bullaFundTokenAddress)?.variant ?? TokenVariant.UNKNOWN];
    const _currentTokenBalance = tokenBalances.getBalanceForToken(bullaFundTokenAddress);

    const fetchingBalance = _currentTokenBalance == undefined;

    const currentTokenBalance = _currentTokenBalance?.toFixed(tokenRouding + 2).toString() ?? '0';

    const [pricePerShare, setPricePerShare] = React.useState<BigNumber | null>(null);

    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();

    const getPriceFromPreviewRedeem = async () => {
        if (!usePreviewRedeem) return undefined;
        const shares = parseUnits('1000000', fundTokenDecimals);
        const assets = await previewRedeem(shares);
        if (assets) {
            return parseUnits(assets.toString(), poolUnderlyingTokenInfo.token.decimals).div(shares);
        }
    };

    React.useEffect(() => {
        const fetchPricePerShare = async () => {
            const poolInfo = await getPoolInfo();
            setPricePerShare(poolInfo?.price ?? null);
            setPoolName(poolInfo?.name ?? 'null');
        };

        const setPricePerShareWithPreviewRedeem = async () => {
            const price = await getPriceFromPreviewRedeem();
            if (price) {
                setPricePerShare(price);
            }
        };

        usePreviewRedeem ? setPricePerShareWithPreviewRedeem() : fetchPricePerShare();
    }, [connectedNetwork]);

    const handleRedeem = async (
        token: TokenDto,
        amount: string,
        setFieldError: (field: string, message: string) => void,
        attachment: BullaItemAttachment | undefined,
    ) => {
        if (amount === '') return;

        const numericAmount = parseFloat(amount);
        if (numericAmount <= 0) {
            setFieldError('redeemAmount', 'Redeem amount must be greater than zero.');
            return;
        }

        const priceUpdated = usePreviewRedeem ? await getPriceFromPreviewRedeem() : await getPricePerShare();
        if (priceUpdated && pricePerShare && !priceUpdated.eq(pricePerShare)) {
            setFieldError('redeemAmount', 'Price per share has been updated. Please close window and try again.');
            return;
        }

        const parsedAmount = parseUnits(amount, token.decimals);

        const maxRedeem = await maxRedeemAmout();
        if (maxRedeem && maxRedeem.lt(parsedAmount)) {
            const parsedAmount = formatUnits(maxRedeem, token.decimals);
            setFieldError('redeemAmount', `Amount exceeds redeemable limit at this time. The max allowed is ${parsedAmount} BFT`);
            return;
        }

        const attachmentCID = isAttachmentReady(attachment)
            ? await resolveBullaAttachmentToCID({
                  attachment,
                  actingWallet,
                  amount: amount,
                  description: 'Redeem from Bulla Fund',
                  recipient: actingWallet,
                  tokenSymbol: token.symbol,
                  type: 'Payment',
                  attachmentLinkGenerator: getAttachmentGenerationLink,
              })
            : '';

        const ipfsHash = isAttachmentReady(attachment) ? getMultihashStructFromCID(attachmentCID) : undefined;

        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await redeemInputToMultisendTxDTO(
                    bullaFundAddress,
                    fundTokenInfo,
                    parsedAmount,
                    actingWallet,
                    actingWallet,
                    ipfsHash,
                );
                const { transactionResult, success } = await executeTransactions(safeInfo, [transaction], true);
                if (!!attachmentCID) pinHash(attachmentCID);
                if (success && closeModal) closeModal();
                return transactionResult;
            } finally {
                setExecutingSafeTx(false);
            }
        }

        await redeemHandler(parsedAmount, actingWallet, actingWallet, ipfsHash).then(success => {
            if (!!attachmentCID) pinHash(attachmentCID);
            if (closeModal && success) closeModal();
        });
    };

    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                size={'2xl'}
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={{
                            redeemAmount: '',
                            emailAddress: '',
                            attachment: { file: 'not-uploaded' } as BullaFileObject | UploadedFile,
                        }}
                        validationSchema={RedeemValidationSchema}
                        onSubmit={(values, actions) => {
                            const { redeemAmount } = values;
                            const token = fundTokenInfo.token;

                            handleRedeem(token, redeemAmount, actions.setFieldError, values.attachment).then(() => {
                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {({ errors, touched, handleBlur, setFieldValue, values, setFieldError }) => {
                            const [insufficientFunds, setInsufficientFunds] = React.useState<boolean>(currentTokenBalance == '0');

                            const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
                                const { value } = e.target;
                                if (isNumberInputValid(value) && !value.match(/^\.$/)) {
                                    const decimalIndex = value.indexOf('.');
                                    if (decimalIndex !== -1 && value.length - decimalIndex - 1 > fundTokenDecimals) {
                                        const limitedValue = value.slice(0, decimalIndex + fundTokenDecimals + 1);
                                        setFieldValue('redeemAmount', limitedValue);
                                    } else {
                                        setFieldValue('redeemAmount', value);
                                    }
                                }
                            };

                            useEffect(() => {
                                if (tokenBalances && currentTokenBalance != '0' && values.redeemAmount != '') {
                                    const insufficientFundsResult: boolean =
                                        (tokenBalances.getBalanceForToken(bullaFundTokenAddress) ?? 0) < +values.redeemAmount;
                                    setInsufficientFunds(insufficientFundsResult);
                                }
                            }, [values.redeemAmount, tokenBalances.nonce]);

                            const setMax = () => {
                                setFieldValue('redeemAmount', currentTokenBalance);
                            };

                            return (
                                <Form placeholder={''}>
                                    <ModalHeader>
                                        <Text color="gray.700" fontWeight={'700'} fontSize="25px" alignSelf="center" maxW={'95%'} mb="3">
                                            Redeem Funds from: {poolName} {shortAddress(bullaFundAddress)}
                                            <IconButton
                                                as={Link}
                                                href={`${blockExplorer}address/${bullaFundAddress}`}
                                                isExternal
                                                icon={<ExternalLinkIcon />}
                                                aria-label="Open in explorer"
                                                size="md"
                                                variant="ghost"
                                                _hover={{ background: 'transparent' }}
                                                mb="1"
                                            />
                                        </Text>
                                        <Box mx={-6}>
                                            <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                        </Box>
                                    </ModalHeader>
                                    <CloseModalButton onClose={() => closeModal()} />
                                    <ModalBody>
                                        <Stack spacing="3">
                                            <AlertInfo
                                                message={
                                                    <HStack>
                                                        <Text>
                                                            When you redeem Bulla Fund Tokens, you will receive the market rate fo BFT in
                                                            USDC.
                                                        </Text>
                                                    </HStack>
                                                }
                                            />
                                            <Field name="redeemAmount">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isRequired={true} isInvalid={!!errors.redeemAmount && !!touched}>
                                                        <Flex justifyContent={'space-between'} w="100%">
                                                            <FormLabelWithRequiredness
                                                                fieldName={field.name}
                                                                isRequired={true}
                                                                label={'Amount'}
                                                            />
                                                            <Spacer />
                                                            <HStack fontSize="sm" color="gray.400">
                                                                <Text>Current balance:</Text>
                                                                <WithSkeleton isLoading={fetchingBalance} fixedWidth="50px">
                                                                    {currentTokenBalance}
                                                                </WithSkeleton>
                                                            </HStack>
                                                        </Flex>
                                                        <InputGroup size="md">
                                                            <InputLeftAddon px="4" bg="transparent">
                                                                {fundTokenSymbol}
                                                            </InputLeftAddon>
                                                            <Input
                                                                {...field}
                                                                id="redeemAmount"
                                                                placeholder="Enter redeem amount"
                                                                onChange={handleAmount}
                                                                onBlur={handleBlur}
                                                                isInvalid={!!errors.redeemAmount && touched.redeemAmount}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <InputRightElement w="fit-content" px="2">
                                                                <Button size="xs" onClick={setMax} mx="1">
                                                                    max
                                                                </Button>
                                                            </InputRightElement>
                                                        </InputGroup>
                                                        {errors.redeemAmount && touched.redeemAmount && (
                                                            <FormErrorMessage>
                                                                <>{errors.redeemAmount}</>
                                                            </FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            {values.redeemAmount != '' && (
                                                <RedeemSummary
                                                    redeemAmount={
                                                        values.redeemAmount[0] == '.' ? '0' + values.redeemAmount : values.redeemAmount
                                                    }
                                                    pricePerShare={pricePerShare}
                                                    fundTokenDecimals={fundTokenDecimals}
                                                    underlyingTokenSymbol={poolUnderlyingTokenInfo.token.symbol}
                                                />
                                            )}
                                            <Field name="emailAddress">
                                                {({ field }: FieldProps) => (
                                                    <EmailField
                                                        {...{
                                                            field,
                                                            isDisabled: isDisabled,
                                                            error: errors.emailAddress,
                                                            touched: touched.emailAddress,
                                                            label: 'Confirmation Email',
                                                            required: false,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="attachment">
                                                {({ field }: FieldProps) => (
                                                    <FileUploadField
                                                        attachment={values.attachment}
                                                        setAttachment={(file: BullaItemAttachment | undefined) =>
                                                            setFieldValue('attachment', file)
                                                        }
                                                        renderUploadSection={(isDisabled, isLoading) => (
                                                            <UploadSection isDisabled={isDisabled} isLoading={isLoading} />
                                                        )}
                                                        uploadToIpfsOnAccept
                                                        error={errors.attachment}
                                                        label="Attachment"
                                                        field={field}
                                                        isDisabled={isDisabled}
                                                        required={false}
                                                        setErrorOn={() => setFieldError('attachment', 'Error uploading file')}
                                                        setErrorOff={() => setFieldError('attachment', undefined)}
                                                    />
                                                )}
                                            </Field>
                                        </Stack>
                                    </ModalBody>
                                    <ModalFooter>
                                        <>
                                            <OrangeButton
                                                isLoading={isLoading}
                                                isDisabled={isDisabled || isLoading || Object.keys(errors).length > 0 || insufficientFunds}
                                                type="submit"
                                            >
                                                {insufficientFunds ? 'Insufficient funds' : 'Redeem'}
                                            </OrangeButton>
                                        </>
                                    </ModalFooter>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};
