import { gql } from '@apollo/client';
import { EthAddress } from '../ethereum';

export type GnosisModuleConfigQuery = {
    bullaBankerGnosisModuleConfig?: {
        id: EthAddress;
        moduleAddress: EthAddress;
        prevModuleAddress: EthAddress | null;
        version: string;
        installationTimestamp: string;
        __typename: 'User';
    };
};

export const getGnosisModuleConfigEntityId = (safeAddress: EthAddress) => `GnosisSafe:${safeAddress.toLowerCase()}-ModuleConfig`;

export const gnosisModuleConfigQuery = gql`
    query GnosisModuleConfig($queryAddress: String!) {
        bullaBankerGnosisModuleConfig(id: $queryAddress) {
            id
            moduleAddress
            prevModuleAddress
            version
            installationTimestamp
            __typename
        }
    }
`;
