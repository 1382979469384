import { ChakraProvider } from '@chakra-ui/react';
import { css, Global } from '@emotion/react';
import SafeProvider from '@gnosis.pm/safe-apps-react-sdk';
import dotenv from 'dotenv';
import 'focus-visible/dist/focus-visible';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { App } from './app';
import { ConnectedEoaProvider } from './hooks/useConnectedEOA';
import { TokenRepoProvider } from './hooks/useTokenRepo';
import { OnboardRouter, Web3Provider } from './hooks/useWeb3';
import { AuthProvider } from './state/auth-state';
import { UIProvider } from './state/ui-state';
import theme from './theme';

const GlobalStyles = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

dotenv.config();

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <HashRouter>
        <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
            <UIProvider>
                <SafeProvider>
                    <Web3Provider>
                        <TokenRepoProvider>
                            <ConnectedEoaProvider>
                                <AuthProvider>
                                    <OnboardRouter>
                                        <App />
                                    </OnboardRouter>
                                </AuthProvider>
                            </ConnectedEoaProvider>
                        </TokenRepoProvider>
                    </Web3Provider>
                </SafeProvider>
            </UIProvider>
        </ChakraProvider>
    </HashRouter>,
);
