import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        brand: {
            bulla_blue: '#12525B',
            bulla_orange: '#e66c0a',
            bulla_grey: '#A3AED0',
            bulla_yellow: '#FFCF6F',
            50: '#70979c',
            100: '#59858c',
            200: '#41747b',
            300: '#29636b',
            400: '#12525B',
            500: '#104951',
            600: '#0e4148',
            700: '#0c393f',
            800: '#0a3136',
            900: '#09292d',
        },
        accent: {
            100: '#FFFFFF',
            200: '#E9C9B5',
            300: '#D8A17E',
            400: '#c87948',
            500: '#e66c0a',
            600: '#8C5432',
            700: '#643C23',
            800: '#23130e',
        },
        dark: {
            100: '#a7a09e',
            200: '#7b716e',
            300: '#4e423e',
            400: '#23130e',
            500: '#1f110c',
            600: '#180d09',
            700: '#110907',
        },
        orange: {
            100: '#eac5bd',
            200: '#dfa89c',
            300: '#d58b7a',
            400: '#d58b7a',
            500: '#e66c0a',
            600: '#a25848',
            700: '#794236',
        },
        scheme: {
            light: '#FFFFFF',
            dark: '#1A1512',
            accent_dark: '#e66c0a',
            accent_light: '#F3F6F9',
        },
        badgeColors: {
            paid: '#81A778',
            rescinded: '#E0E1E2',
            awaiting: '#8A827A',
            rejected: '#CB6E5A',
        },
        warning: '#FFD98B',
        danger: '#E2806A',
        walletInfoBg: '#2C2725',
        heading: '#353739',
        headerBg: '#0C282E',
        orangeBg: '#e66c0a',
        footerBg: '#DEDCDA',
        highlight: '#CF8452',
        icon_dark: '#4E393B',
        gnosis: '#4A8873',
    },
    textStyles: {
        // xs: {
        //     fontSize: '10px',
        // },
        // sm: {
        //     fontSize: '12px',
        // },
        // md: {
        //     fontSize: '14px',
        // },
        lg: {
            fontSize: '24px',
        },
        dollarDisplay: {
            whiteSpace: 'nowrap',
            fontSize: '32px',
        },
        centsDisplay: {
            whiteSpace: 'nowrap',
            fontSize: '16px',
        },
        labelXl: {
            fontSize: '2em',
            lineHeight: '2em',
            fontWeight: 400,
            whiteSpace: 'nowrap',
        },
        labelLg: {
            fontSize: '24px',
            fontWeight: 800,
        },
        white: {
            color: '#FFFFFF',
        },
        noWrap: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        bullaViewHeader: {
            fontSize: '1.75em',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'clip',
            maxWidth: '96',
            fontWeight: 700,
            color: '#353739',
        },
        listTitle: {
            fontSize: '1.6em',
            color: 'black',
            fontWeight: 700,
        },
        columnName: {
            fontSize: '.9em',
            margin: '2',
            textTransform: 'uppercase',
            fontWeight: 700,
        },
        uppercase: {
            fontWeight: 700,
            textTransform: 'uppercase',
        },
        caption: {
            color: '#CF8452',
            fontStyle: 'italic',
            fontSize: 'xs',
        },
        badge: {
            fontSize: '.8em',
            py: 1,
            px: 2,
            borderRadius: 6,
            whiteSpace: 'nowrap',
        },
        cell: {
            textStyle: 'dark',
        },
        dark: {
            color: '#23130E',
        },
        light: {
            color: '#FFFFFF',
        },
        faint: {
            color: '#8A827A',
        },
        underline: {
            textDecoration: 'underline',
        },
        link: {
            fontWeight: '500',
            textDecoration: 'underline',
        },
        textButton: {
            fontWeight: 500,
            textDecoration: 'underline',
            whiteSpace: 'nowrap',
        },
        hover: {
            textShadow: '-0.01ex 0 black, 0.01ex 0 black',
        },
    },
    sizes: {
        pageMax: '1440px',
    },
    styles: {
        global: {
            html: {
                overflowY: 'hidden',
                fontSize: '14px',
            },
            header: {
                color: '#FFFFFF',
            },
            hr: {
                w: '100%',
                h: '1px',
                bg: '#E3E3E3',
            },
        },
    },
    components: {
        Link: {
            baseStyle: (props: any) => ({
                fontWeight: props?.isselected === 'true' ? 700 : 400,
                fontSize: '.9em',
                lineHeight: '24px',
            }),
        },
        Popover: {
            baseStyle: {
                popper: {
                    width: 'fit-content',
                    maxWidth: 'fit-content',
                },
            },
        },
        Tag: {
            variants: {
                brand: {
                    color: 'white',
                    bg: 'brand.300',
                },
            },
        },
        Tooltip: {
            variants: {
                address: {
                    whiteSpace: 'nowrap',
                    minW: 'fit-content',
                },
                card: {
                    paddingTop: '3.5',
                    paddingLeft: '3.5',
                    paddingRight: '3.5',
                    paddingBottom: '5',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    color: 'gray.900',
                },
            },
        },
        Input: {
            variants: {
                popup: {
                    _disabled: {
                        borderColor: 'gray.300',
                    },
                },
            },
        },
    },
    fonts: {
        heading: '"Inter"',
        body: '"Inter"',
    },
});

export default theme;
