import React from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { PoolDetailsView } from './pool-details-view';
import { useFactoringAndDepositPermissions } from '../../hooks/useFactoringAndDepositPermissions';
import { TitledPage } from '../../components/base/title-route';
import { Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { PageLayoutProvider, MaxWidthWrapper } from '../../components/layout/page-layout';
import { ArrowLeft } from 'phosphor-react';
import { addressEquality } from '../../data-lib/ethereum';
import { WithSkeleton } from '../../components/base/skeleton';

export const PoolDetails = () => {
    const { poolAddress } = useParams();
    const navigate = useNavigate();
    const { poolsWithPermissions, isLoading } = useFactoringAndDepositPermissions();

    const [address, networkId] = poolAddress?.split('-').map(part => part.trim()) ?? [];

    const selectedPool = poolsWithPermissions.find(
        pool =>
            address &&
            addressEquality(pool.factoringConfig.bullaFactoringToken.token.address, address) &&
            pool.factoringConfig.bullaFactoringToken.chainId.toString() === networkId,
    );
    const handleBack = () => {
        navigate('/factoring/pools');
    };

    if (!selectedPool) {
        return (
            <PageLayoutProvider>
                <Flex p={{ sm: '8', md: '12' }} bg={'gray.25'} direction="column" flex="1">
                    <MaxWidthWrapper>
                        <Flex justifyContent="space-between" alignItems="center">
                            {isLoading ? (
                                <WithSkeleton isLoading={true} fixedWidth="550px" height="50px">
                                    <Text>Can't see me</Text>
                                </WithSkeleton>
                            ) : (
                                <Heading color="heading" flex={1}>
                                    Pool not found
                                </Heading>
                            )}

                            <HStack cursor="pointer" onClick={handleBack} color="gray.600">
                                <ArrowLeft size={18} weight="bold" />
                                <Text fontWeight="semibold" fontSize="md">
                                    Back to Pool List
                                </Text>
                            </HStack>
                        </Flex>
                    </MaxWidthWrapper>
                </Flex>
            </PageLayoutProvider>
        );
    }

    return (
        <TitledPage title={`Pool Details - ${selectedPool.factoringConfig.poolName}`}>
            <PoolDetailsView
                factoringConfig={selectedPool.factoringConfig}
                onBack={handleBack}
                hasDepositPermissions={selectedPool.hasDepositPermissions}
            />
        </TitledPage>
    );
};
