import { ApolloClient, InMemoryCache } from '@apollo/client';
import {
    abi as bullaBankerModuleABI,
    bytecode as bullaBankerModuleBytecode,
} from '@bulla-network/contracts/artifacts/contracts/BullaBankerModule.sol/BullaBankerModule.json';
import { BullaBankerModule__factory } from '@bulla-network/contracts/typechain/factories/BullaBankerModule__factory';
import { TransactionDescription } from '@ethersproject/abi';
import { Provider } from '@ethersproject/abstract-provider';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import type SafeAppSDK from '@gnosis.pm/safe-apps-sdk';
import Safe, { ContractNetworksConfig, EthersAdapter } from '@gnosis.pm/safe-core-sdk';
import SafeServiceClient from '@gnosis.pm/safe-service-client';
import { ContractFactory, ethers, providers, Signer } from 'ethers';
import { MetaTransaction } from 'ethers-multisend';
import { GnosisSafe as GnosisSafe_1_1 } from '../../../artifacts/typechain/GnosisSafe_1_1';
import { GnosisSafe as GnosisSafe_1_2 } from '../../../artifacts/typechain/GnosisSafe_1_2';
import { GnosisSafe as GnosisSafe_1_3 } from '../../../artifacts/typechain/GnosisSafe_1_3';
import { MultisendOfferLoanTransaction, MultisendPayTransaction, MultisendTransaction } from '../../hooks/useGnosisMultisend';
import { TokenInfoResolver } from '../../hooks/useTokenRepo';
import { getGnosisModuleDeployRequest } from '../../state/state-helpers';
import { getLocalStorage, setLocalStorage } from '../../tools/localStorage';
import { STORAGE_KEYS } from '../../tools/storage';
import { BullaItemInfo, BullaLineItems, PendingInstantPaymentInfo } from '../data-model';
import { PayClaimTransaction } from '../domain/bulla-claim-domain';
import { BullaBankerModuleDeployEvent } from '../domain/common-domain';
import { mapToPayClaimTransaction } from '../dto/bulla-claim-dto';
import { getGnosisSafe_1_2Contract, getGnosisSafe_1_3Contract } from '../dto/contract-interfaces';
import { getBullaBankerModuleDeployEvents } from '../dto/event-filters';
import { mapToPendingInstantPaymentInfo } from '../dto/event-mappers';
import { parseRaw } from '../dto/parser';
import { addressEquality, EthAddress, toChecksumAddress } from '../ethereum';
import { getGnosisModuleConfigEntityId, gnosisModuleConfigQuery, GnosisModuleConfigQuery } from '../graphql/gnosisModuleConfigQuery';
import { isClaim, sortBlocknumDesc } from '../helpers';
import { getHistoricalLogs } from '../historical-logs';
import { ChainId, chainIds, NetworkConfig, NETWORKS } from '../networks';
import { getDeployAndSetUpModuleTransaction } from './factory';
import { CustomSafeService } from './safeService';
import {
    awaitSafeAppTransaction,
    getApprovalTransactions,
    getDisableModuleTransaction,
    getEnableModuleTransaction,
    getPosterTx,
    proposeSafeTx,
} from './transactions';

/** checksumed safe address: last used date */
export type SafeCache = {
    [safeAddress: string]: {
        chainId: ChainId;
        lastUsed?: number;
        name?: string;
    };
};

export type TransactionInput = {
    to: string;
    value: string;
    data: string;
    operation: number;
};

type PendingMultisendTransaction = Required<MetaTransaction> & { valueDecoded: string | null };

type PendingMultisendTx = {
    type: string;
    name: string;
    value: string;
    valueDecoded: PendingMultisendTransaction[];
};

type DataDecodedResponse = { method: string; parameters: PendingMultisendTx[] };

type SafeModule = {
    moduleEnabled: boolean;
    moduleAddress: EthAddress;
    moduleVersion: string;
};

export type GnosisSafeInfo = {
    safeAddress: EthAddress;
    owners: EthAddress[];
    threshold: number;
    isOwner: boolean;
    module: SafeModule | undefined;
    prevModuleaddress?: EthAddress;
    multisendAddress?: EthAddress;
};

export type GnosisSettings = {
    viewSafeURL: string;
    safeServiceURL: string;
    version: string;
    getSafeContract: (safeAddress: EthAddress) => GnosisSafe_1_1 | GnosisSafe_1_2 | GnosisSafe_1_3;
    moduleFactoryAddress: EthAddress;
    bullaModuleMasterCopyAddress: EthAddress;
    multisendOverrideAddress?: EthAddress;
    posterOverrideAddress?: EthAddress;
};

export const BULLA_BANKER_MODULE_VERSION = '0.0.9';
export const MAX_MUTLI_SEND_TXS = 20;
export const POSTER_ADDRESS = '0x000000000000cd17345801aa8147b8D3950260FF';
export const MUTLISEND_ADDRESS = '0x8D29bE29923b68abfDD21e541b9374737B49cdAD';

export const GNOSIS_CONFIG: Record<number, GnosisSettings> = {
    [chainIds.MAINNET]: {
        viewSafeURL: 'https://app.safe.global/eth:',
        safeServiceURL: 'https://safe-transaction-mainnet.safe.global',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0xCf4254cF35EB7F13B7e02bA774f5502f5f5C7A7d',
        bullaModuleMasterCopyAddress: '0xAA6E5B4E34f3C3BA4D90694909dca7DDdf058079',
    },
    [chainIds.MATIC]: {
        viewSafeURL: 'https://app.safe.global/matic:',
        safeServiceURL: 'https://safe-transaction-polygon.safe.global',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x71E634a30a6FEc0800C1d2740B46EC56fA32aA53',
        bullaModuleMasterCopyAddress: '0x5E279b981E4162a30eeE3F3eC4EbFd66ccA09222',
    },
    [chainIds.GNOSIS]: {
        viewSafeURL: 'https://app.safe.global/gno:',
        safeServiceURL: 'https://safe-transaction-gnosis-chain.safe.global/',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x8ad90CbA0786ed3E89F6f55a86d4B90728223116',
        bullaModuleMasterCopyAddress: '0x9c96fc42B87922f0f22603fF8a31Ee8768cc29F2',
    },
    [chainIds.ARBITRUM]: {
        viewSafeURL: 'https://app.safe.global/arb1:',
        safeServiceURL: 'https://safe-transaction-arbitrum.safe.global',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x26719d2A1073291559A9F5465Fafe73972B31b1f',
        bullaModuleMasterCopyAddress: '0xec6013D62Af8dfB65B8248204Dd1913d2f1F0181',
    },

    // [chainIds.RSK]: {
    //     viewSafeURL: 'https://rsk-gnosis-safe.com/#/safes/',
    //     safeServiceURL: 'https://api.rsk-safe.com/api/v1',
    //     version: '1.1',
    //     getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_1Contract(safeAddress),
    //     moduleFactoryAddress: '0xfbdf5dff946200B038e1455E532D9795F030aB91',
    //     bullaModuleMasterCopyAddress: '0x28BE4AB88bb7EA7b93e8eB155fFDa6010cde3C48',
    //     multisendOverrideAddress: '0xb8e79Ef5765Fa8eeCA73ca85b451D82971a207FC',
    // },
    [chainIds.HARMONY]: {
        viewSafeURL: 'https://multisig.harmony.one/#/safes/',
        safeServiceURL: 'https://multisig.t.hmny.io/api/v1',
        version: '1.2',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_2Contract(safeAddress),
        moduleFactoryAddress: '0x1EdEc726a0DEEad8E92a1a850b7bFD9c7356b9EC',
        bullaModuleMasterCopyAddress: '0x5a632dB79720Ddc32a441F15019e496751E68F72',
        multisendOverrideAddress: '0x0c2aEe6E7C41e5af939065621037d89bC74fA8f7',
        posterOverrideAddress: '0x6EE1900d951ea75d4E55f0aD6103CC36c15161Df',
    },
    [chainIds.AURORA]: {
        viewSafeURL: 'https://app.safe.global/aurora:',
        safeServiceURL: 'https://safe-transaction-aurora.safe.global',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0xd285f3645Af07960C961B6DCBFe230Ee686aA12F',
        bullaModuleMasterCopyAddress: '0x44ad74A14f268551Dd8619B094769C10089239C8',
    },
    [chainIds.AVALANCHE]: {
        viewSafeURL: 'https://app.safe.global/avax:',
        safeServiceURL: 'https://safe-transaction-avalanche.safe.global',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x101452DEF98B40A64d28A964Df1e35170aD5c647',
        bullaModuleMasterCopyAddress: '0xF9C5C979c4d80ADf5Da07e943658617d0110cC92',
    },
    [chainIds.MOONBEAM]: {
        viewSafeURL: 'https://multisig.moonbeam.network/mbeam:',
        safeServiceURL: 'https://transaction.multisig.moonbeam.network',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x68da2c92b60337aA55BAcF0d7e61126eDb535752',
        bullaModuleMasterCopyAddress: '0xAA6E5B4E34f3C3BA4D90694909dca7DDdf058079',
    },
    [chainIds.FUSE]: {
        viewSafeURL: 'https://safe.fuse.io/fuse:',
        safeServiceURL: 'https://transaction-fuse.safe.fuse.io',
        version: '1.3',
        getSafeContract: (safeAddress: EthAddress) => getGnosisSafe_1_3Contract(safeAddress),
        moduleFactoryAddress: '0x8f5952d2122A8DF42a3dcB5286D7576ff640cF5D',
        bullaModuleMasterCopyAddress: '0xf446785EB5e7eBeAFd89E7A0ec1fE4ec37686486',
        posterOverrideAddress: '0x4d5EA6f590a5bEC220995F63b31FA9585Dbed857',
    },
    [chainIds.OPTIMISM]: {
        viewSafeURL: 'https://app.safe.global/oeth:',
        safeServiceURL: 'https://safe-transaction-optimism.safe.global',
        version: '1.3',
        getSafeContract: getGnosisSafe_1_3Contract,
        moduleFactoryAddress: '0x772b675A4180B4F48d9D816619620664f90Cf281',
        bullaModuleMasterCopyAddress: '0x2544E95e643F0d27dD4CA13496D8155D052aC030',
    },
    [chainIds.BASE]: {
        viewSafeURL: 'https://app.safe.global/base:',
        safeServiceURL: 'https://safe-transaction-base.safe.global',
        version: '1.3',
        getSafeContract: getGnosisSafe_1_3Contract,
        moduleFactoryAddress: '0xE14E624b29BcDa2ec409BBBf97037fEDe3803797',
        bullaModuleMasterCopyAddress: '0x1c534661326b41c8b8aab5631ECED6D9755ff192',
    },
    [chainIds.SEPOLIA]: {
        viewSafeURL: 'https://app.safe.global/sep:',
        safeServiceURL: 'https://safe-transaction-sepolia.safe.global',
        version: '1.3',
        getSafeContract: getGnosisSafe_1_3Contract,
        moduleFactoryAddress: '0xd849f582e960610B5704Ed2bb245872A60e56853',
        bullaModuleMasterCopyAddress: '0x085478C955686B38aeD1FF6C2901f503aB5c4984',
    },
};

export const SUPPORTED_GNOSIS_NETWORKS: ChainId[] = Object.keys(GNOSIS_CONFIG).map(num => +num as ChainId);
export const GNOSIS_CUSTOM_SAFE_SERVICE_NETWORKS: number[] = SUPPORTED_GNOSIS_NETWORKS.filter(
    x => GNOSIS_CONFIG[x].multisendOverrideAddress !== undefined,
);

export const getSafeName = (connectedSafeAddress: string, network: ChainId, userAddress: EthAddress) =>
    getSafeCache(network, userAddress)?.[connectedSafeAddress]?.name;

export const useCustomSafeService = (chainId: number) => GNOSIS_CUSTOM_SAFE_SERVICE_NETWORKS.includes(chainId);

export const isModuleOutdated = (moduleVersion: string) => BULLA_BANKER_MODULE_VERSION !== moduleVersion;

export const getGnosisSafeURL = (networkConfig: NetworkConfig, safeAddress: EthAddress, route: 'transactions' | string = 'transactions') =>
    `${GNOSIS_CONFIG[networkConfig.chainId].viewSafeURL}${safeAddress}/${route === 'transactions' ? 'transactions/queue' : route}`;

export const getSafeCache = (chainId: ChainId, _userAddress: EthAddress): SafeCache => {
    const userAddress = toChecksumAddress(_userAddress);
    const cachedSafes = Object.entries(getLocalStorage<Partial<SafeCache>>(`${chainId}:${userAddress}:${STORAGE_KEYS.usedSafes}`) ?? {});

    return cachedSafes.reduce<SafeCache>((acc, [safeAddress, safeInfo]) => ({ ...acc, [safeAddress]: { ...safeInfo, chainId } }), {});
};

export const cacheSafeAddress = (chainId: ChainId, _userAddress: EthAddress, _safeAddress: EthAddress, name?: string) => {
    const userAddress = toChecksumAddress(_userAddress);
    const safeAddress = toChecksumAddress(_safeAddress);
    const safeAddressCache = getLocalStorage<SafeCache>(`${chainId}:${userAddress}:${STORAGE_KEYS.usedSafes}`);
    setLocalStorage(`${chainId}:${userAddress}:${STORAGE_KEYS.usedSafes}`, {
        ...safeAddressCache,
        [safeAddress]: {
            chainId,
            lastUsed: Date.now(),
            name: name ?? safeAddressCache?.[safeAddress]?.name,
        },
    });
};

const isModuleEnabled = async (
    provider: providers.Provider,
    networkConfig: NetworkConfig,
    safeAddress: EthAddress,
    moduleAddress: EthAddress,
) => {
    const { getSafeContract } = GNOSIS_CONFIG[networkConfig.chainId];
    const safeContract = getSafeContract(safeAddress).connect(provider);

    return 'getModules' in safeContract
        ? (async () => {
              const enabledModules = await safeContract.getModules();
              return enabledModules.some(enabledModule => addressEquality(enabledModule, moduleAddress));
          })()
        : await safeContract.isModuleEnabled(moduleAddress);
};

const getModuleInfoViaLogs = async (provider: providers.Provider, networkConfig: NetworkConfig, safeAddress: EthAddress) => {
    const getLogs = getHistoricalLogs(provider, networkConfig.deployedOnBlock);
    const events = await getLogs(getGnosisModuleDeployRequest(safeAddress));

    const bullaBankerModuleDeployEvents = getBullaBankerModuleDeployEvents(events.gnosisModuleDeploy).sort(sortBlocknumDesc);
    const [latestDeployEvent, prevDeployEvent]: (BullaBankerModuleDeployEvent | undefined)[] = bullaBankerModuleDeployEvents;
    const moduleEnabled = latestDeployEvent?.moduleAddress
        ? await isModuleEnabled(provider, networkConfig, safeAddress, latestDeployEvent.moduleAddress)
        : false;
    const moduleOutdated = latestDeployEvent?.version ? latestDeployEvent.version !== BULLA_BANKER_MODULE_VERSION : false;

    return {
        moduleEnabled,
        moduleOutdated,
        moduleVersion: latestDeployEvent?.version,
        moduleAddress: latestDeployEvent?.moduleAddress,
        prevModuleAddress: prevDeployEvent?.moduleAddress,
    };
};

const getModuleInfoViaGraph = async (provider: Provider, networkConfig: NetworkConfig, safeAddress: EthAddress) => {
    const queryAddress = getGnosisModuleConfigEntityId(safeAddress);
    const {
        data: { bullaBankerGnosisModuleConfig },
    } = await new ApolloClient({
        uri: networkConfig.connections.graphEndpoint!,
        cache: new InMemoryCache(),
    }).query<GnosisModuleConfigQuery>({
        query: gnosisModuleConfigQuery,
        variables: { queryAddress },
        fetchPolicy: 'network-only',
    });

    const moduleEnabled = bullaBankerGnosisModuleConfig?.moduleAddress
        ? await isModuleEnabled(provider, networkConfig, safeAddress, bullaBankerGnosisModuleConfig?.moduleAddress)
        : false;

    return {
        moduleEnabled,
        moduleOutdated: bullaBankerGnosisModuleConfig?.version
            ? bullaBankerGnosisModuleConfig.version !== BULLA_BANKER_MODULE_VERSION
            : false,
        moduleVersion: bullaBankerGnosisModuleConfig?.version,
        moduleAddress: bullaBankerGnosisModuleConfig?.moduleAddress,
        prevModuleAddress: bullaBankerGnosisModuleConfig?.prevModuleAddress ?? undefined,
    };
};

export const getGnosisSafeInfo = async (
    signer: Signer,
    provider: providers.Provider,
    networkConfig: NetworkConfig,
    safeAddress: EthAddress,
    resolveTokenInfo: TokenInfoResolver,
) => {
    const moduleInfo = networkConfig.connections.graphEndpoint
        ? await getModuleInfoViaGraph(provider, networkConfig, safeAddress)
        : await getModuleInfoViaLogs(provider, networkConfig, safeAddress);
    const gnosisContract = GNOSIS_CONFIG[networkConfig.chainId].getSafeContract(safeAddress).connect(provider);
    const [owners, threshold] = await Promise.all([gnosisContract.getOwners(), gnosisContract.getThreshold()]);
    const pendingPayments = await getPendingGnosisPayments(signer, networkConfig, safeAddress, resolveTokenInfo);

    return {
        owners,
        threshold,
        moduleEnabled: moduleInfo.moduleEnabled,
        moduleOutdated: moduleInfo.moduleOutdated,
        moduleAddress: moduleInfo?.moduleAddress,
        moduleVersion: moduleInfo.moduleVersion,
        prevModuleAddress: moduleInfo.prevModuleAddress,
        pendingPayments,
    };
};

// create a custom "safe service" for older versions of the safe-service on harmony/rsk
export const getSafeService = (networkConfig: NetworkConfig) =>
    useCustomSafeService(networkConfig.chainId)
        ? new CustomSafeService(networkConfig)
        : new SafeServiceClient(GNOSIS_CONFIG[networkConfig.chainId].safeServiceURL);

export const hasSafeOnNetwork = async (owner: EthAddress, safeAddress: EthAddress, network: ChainId) => {
    const ownerResponse = await getSafeService(NETWORKS[network]).getSafesByOwner(owner);
    return ownerResponse.safes.some(x => addressEquality(x, safeAddress));
};

export const getGnosisTooling = async (signer: Signer, networkConfig: NetworkConfig, _safeAddress: EthAddress) => {
    const safeAddress = toChecksumAddress(_safeAddress);
    const contractNetworks: ContractNetworksConfig = {
        // [chainIds.RSK]: {
        //     multiSendAddress: '0xb8e79Ef5765Fa8eeCA73ca85b451D82971a207FC',
        //     safeMasterCopyAddress: '0xC6cFa90Ff601D6AAC45D8dcF194cf38B91aCa368',
        //     safeProxyFactoryAddress: '0x4b1Af52EA200BAEbF79450DBC996573A7b75f65A',
        // },
        [chainIds.HARMONY]: {
            multiSendAddress: '0x0c2aEe6E7C41e5af939065621037d89bC74fA8f7',
            safeMasterCopyAddress: '0x3736aC8400751bf07c6A2E4db3F4f3D9D422abB2',
            safeProxyFactoryAddress: '0x4f9b1dEf3a0f6747bF8C870a27D3DeCdf029100e',
        },
        [chainIds.BASE]: {
            safeMasterCopyAddress: '0xfb1bffC9d739B8D520DaF37dF666da4C687191EA',
            multiSendAddress: '0x998739BFdAAdde7C933B942a68053933098f9EDa',
            safeProxyFactoryAddress: '0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC',
        },
        [chainIds.SEPOLIA]: {
            safeMasterCopyAddress: '0x085478C955686B38aeD1FF6C2901f503aB5c4984',
            multiSendAddress: '0x998739BFdAAdde7C933B942a68053933098f9EDa',
            safeProxyFactoryAddress: '0xd849f582e960610B5704Ed2bb245872A60e56853',
        },
    };

    const safeSdk = await Safe.create({ ethAdapter: new EthersAdapter({ ethers, signer }), safeAddress, contractNetworks });
    return { safeSdk, safeService: getSafeService(networkConfig) };
};

export type PendingGnosisPayments = {
    payClaimTransactions: PayClaimTransaction[];
    pendingInstantPaymentInfos: PendingInstantPaymentInfo[];
};

//** only works on non-forks */
export const getPendingGnosisPayments = async (
    signer: Signer,
    networkConfig: NetworkConfig,
    _safeAddress: EthAddress,
    resolveTokenInfo: TokenInfoResolver,
): Promise<PendingGnosisPayments> => {
    try {
        const bullaContracts = [
            networkConfig.bullaBankerLatest,
            networkConfig.bullaClaimAddress,
            networkConfig.bullaManager,
            networkConfig.bullaInstantPaymentAddress,
        ];
        const { safeService } = await getGnosisTooling(signer, networkConfig, _safeAddress);
        const results = (await safeService.getPendingTransactions(_safeAddress)).results.filter(
            tx => !!(tx.dataDecoded as unknown as DataDecodedResponse)?.parameters,
        );

        const multisendSubTransactions = results.reduce<(PendingMultisendTransaction & { index: number })[]>(
            (acc, baseTx) => [
                ...acc,
                ...((baseTx.dataDecoded as unknown as DataDecodedResponse)?.parameters
                    ?.map(({ valueDecoded }) => (!!valueDecoded ? valueDecoded.map((x, index) => ({ ...x, index })) : []))
                    ?.flat() ?? []),
            ],
            [],
        );

        const bullaRelatedTransactions = multisendSubTransactions.filter(tx =>
            bullaContracts.some(contract => addressEquality(contract, tx.to)),
        );
        const parsedTransactions = bullaRelatedTransactions
            .map(tx => ({ data: tx.data, index: tx.index }))
            .map(({ data, index }) => ({ txDescription: parseRaw({ __type: 'transaction', data }), index }))
            .filter(
                (txDescriptionAndIndex): txDescriptionAndIndex is { txDescription: TransactionDescription; index: number } =>
                    !!txDescriptionAndIndex.txDescription,
            );

        const payClaimTransactions = parsedTransactions
            .filter(tx => tx.txDescription.name === 'payClaim')
            .map(x => mapToPayClaimTransaction(x.txDescription));

        const pendingInstantPaymentInfos = await Promise.all(
            parsedTransactions
                .filter(tx => tx.txDescription.name === 'instantPayment')
                .map(({ txDescription, index }) =>
                    mapToPendingInstantPaymentInfo(_safeAddress, txDescription, index, networkConfig.chainId, resolveTokenInfo),
                ),
        );

        return { payClaimTransactions, pendingInstantPaymentInfos };
    } catch (e) {
        console.error('unable to fetch pending gnosis payments', e);
        return { payClaimTransactions: [], pendingInstantPaymentInfos: [] };
    }
};

export const getPendingGnosisLineItems = (pendingTransactions: PendingGnosisPayments, payables: BullaItemInfo[]): BullaLineItems[] => {
    // find the id already in payables and filter out any that are not in status pending if claim, or exactly matching if
    const pendingClaims = payables.filter(isClaim).reduce<BullaLineItems[]>((acc, payable) => {
        const claimTx = pendingTransactions.payClaimTransactions.some(tx => tx.tokenId === payable.id);
        if (claimTx) return [...acc, payable];
        return acc;
    }, []);

    const pendingInstantPayments = pendingTransactions.pendingInstantPaymentInfos;
    return [...pendingClaims, ...pendingInstantPayments].sort((a, b) => b.created.getTime() - a.created.getTime());
};

export const deployBullaBankerModule = async (
    signer: Signer,
    networkConfig: NetworkConfig,
    safeAddress: EthAddress,
): Promise<EthAddress> => {
    const module = new ContractFactory(bullaBankerModuleABI, bullaBankerModuleBytecode, signer) as BullaBankerModule__factory;
    const moduleContract = await module.deploy(
        safeAddress,
        networkConfig.bullaBankerLatest,
        networkConfig.bullaClaimAddress,
        networkConfig.batchCreate.address,
    );
    const tx = await moduleContract.deployTransaction.wait();
    return tx.contractAddress;
};

export const deployAndEnableModule = async ({
    signer,
    provider,
    connectedNetworkConfig,
    safeAddress,
    userAddress,
    resolveTokenInfo,
}: {
    signer: Signer;
    provider: Provider;
    connectedNetworkConfig: NetworkConfig;
    safeAddress: EthAddress;
    userAddress: EthAddress;
    resolveTokenInfo: TokenInfoResolver;
}) => {
    const {
        moduleAddress: currentlyDeployedModuleAddress,
        moduleOutdated,
        prevModuleAddress,
        moduleEnabled,
    } = await getGnosisSafeInfo(signer, provider, connectedNetworkConfig, safeAddress, resolveTokenInfo);
    const needsDeployment = moduleOutdated || !currentlyDeployedModuleAddress;

    // user has the ability to refresh, or bail out half way through installation, so here we search for any deployed BullaBankerModules targeted to this safe address
    // this will either use the predeployed module address or deploy a new one

    const outdatedModuleAddress = needsDeployment
        ? currentlyDeployedModuleAddress
        : !!prevModuleAddress && (await isModuleEnabled(provider, connectedNetworkConfig, safeAddress, prevModuleAddress))
        ? prevModuleAddress
        : undefined;
    const { expectedModuleAddress, transaction: deployTransaction } = getDeployAndSetUpModuleTransaction(
        provider,
        connectedNetworkConfig,
        safeAddress,
    );

    // This covers the edge case where the module was deployed, enabled, but now disabled.
    // This tx will re-enable the previously deployed module (as long as it's up to date)
    const moduleToEnable =
        !moduleEnabled && !moduleOutdated && currentlyDeployedModuleAddress ? currentlyDeployedModuleAddress : expectedModuleAddress;
    const enableTx = getEnableModuleTransaction(safeAddress, moduleToEnable);

    // create a multisend tx disabling an outdated module and enabling the new one
    const transaction = [
        !!outdatedModuleAddress && (await getDisableModuleTransaction(signer, connectedNetworkConfig, safeAddress, outdatedModuleAddress)),
        !!needsDeployment && deployTransaction,
        enableTx,
    ].filter((tx): tx is TransactionInput => !!tx);

    return {
        wait: await proposeSafeTx({ signer, connectedNetworkConfig, senderAddress: userAddress, safeAddress, transaction }),
        moduleAddress: expectedModuleAddress,
    };
};

export const gnosis_executePayments = async ({
    signer,
    connectedNetworkConfig,
    userAddress,
    safeInfo: { safeAddress },
    transactionDescription,
    sdk,
    transactions,
}: {
    signer: Signer;
    transactions: MultisendTransaction[];
    connectedNetworkConfig: NetworkConfig;
    userAddress: EthAddress;
    safeInfo: GnosisSafeInfo;
    transactionDescription?: string;
    sdk?: ReturnType<typeof useSafeAppsSDK>['sdk'];
}) => {
    const inSafeApp = !!sdk;
    const { chainId } = connectedNetworkConfig;
    const approvals = getApprovalTransactions(
        transactions
            .filter((tx): tx is MultisendPayTransaction | MultisendOfferLoanTransaction => 'approvalNeeded' in tx)
            .map(({ approvalNeeded, token }) => ({
                spendingContract: approvalNeeded.spendingContract,
                amount: approvalNeeded.amount,
                token,
            }))
            .filter(x => !!x),
    );

    const paymentTransactions = transactions.map(x => x.transactionInput);
    const transaction = transactionDescription
        ? [
              getPosterTx({
                  description: approvals.length > 0 ? 'Approve tokens, and ' + transactionDescription : transactionDescription,
                  safeAddress: safeAddress,
                  address: GNOSIS_CONFIG[chainId].posterOverrideAddress,
              }),
              ...approvals,
              ...paymentTransactions,
          ]
        : [...approvals, ...paymentTransactions];

    const safeTxHash = inSafeApp
        ? (
              await sdk.txs.send({
                  txs: transaction,
              })
          ).safeTxHash
        : '';
    return {
        wait: inSafeApp
            ? async () => awaitSafeAppTransaction(sdk, safeTxHash)
            : await proposeSafeTx({ signer, connectedNetworkConfig, senderAddress: userAddress, safeAddress, transaction }),
    };
};
