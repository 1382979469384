import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, InputGroup, Text, useBoolean } from '@chakra-ui/react';
import { BigNumber, utils } from 'ethers';
import _ from 'lodash';
import moment from 'moment';

import React from 'react';
import * as Yup from 'yup';
import { FinancingTerms, MAX_BPS_BN, TermLength, transformTermLengthInputToLabel } from '../../../data-lib/dto/bulla-finance-dto';
import DatePicker from '../../date-picker';
import { DateCustomInputProps } from '../../display/views/import-external-transactions/common';
import { BaseInputProps, disabledInputProps } from '../create-claim-modal/create-claim-inputs';

import { ItemDesc } from '../item-details-modal/item-details-components';

type FinancingDueByFieldProps = Omit<BaseInputProps, 'isDisabled'> & {
    disabled?: boolean;
    label?: string;
    setFinancingDueBy: (date: Date) => void;
};

const DateCustomInput = React.forwardRef<HTMLInputElement, DateCustomInputProps>(({ value, onClick, isDisabled }, ref) => {
    return (
        <InputGroup>
            <Input value={value} onClick={onClick} placeholder="dd/mm/yy" isDisabled={isDisabled} ref={ref} {...disabledInputProps} />
        </InputGroup>
    );
});

export const FinancingDueByField = ({ field, error, label, setFinancingDueBy, touched }: FinancingDueByFieldProps) => {
    return (
        <FormControl isInvalid={!!touched && !!error}>
            <FormLabel htmlFor={field.name}>{label}</FormLabel>
            <InputGroup maxW="fit-content" isolation={'auto'}>
                <DatePicker
                    selectedDate={field.value}
                    onChange={setFinancingDueBy}
                    minDate={moment().add(1, 'day').toDate()}
                    dateFormat="dd MMM yyyy"
                    customInput={<DateCustomInput isDisabled={false} />}
                />
            </InputGroup>
            {touched && (
                <FormErrorMessage>
                    <>{error}</>
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

export function calculateDueDate(termLength: TermLength): Date {
    const { years, months, days } = termLength;

    const dueDate = moment().add(years, 'years').add(months, 'months').add(days, 'days').toDate();

    return dueDate;
}

export const interestRateSchema = Yup.number()
    .required('Required')
    .test('is-less-than-1BPS', 'Interest rate too small', value => value !== undefined && (value == 0 || value >= 0.01))
    .test('interest-rate-not-massive', 'Interest rate too large', value => value !== undefined && value <= 100000)
    .test('non-negative', value => value !== undefined && value >= 0);

export const financingTermsErrorSchema = () =>
    Yup.object().shape({
        downPayment: Yup.number()
            .required('Required')
            .test('is-less-than-100', 'Down payment must be less than 100%', value => value !== undefined && value <= 100)
            .test('is-less-than-1BPS', 'Down payment too small', value => value !== undefined && value >= 0.01)
            .test('non-negative', value => value !== undefined && value >= 0),
        interestRate: interestRateSchema,
    });

export const NULL_VAL_SYMBOL = '---' as const;

export type FinancingTermsLabels = {
    amountDueLabel: string;
    termLengthLabel: string;
    financeChargeLabel: string;
    interestRatePercentLabel: string;
    loanValueString: string;
    downPaymentLabel: string;
    downPaymentPercentLabel: string;
    principalAmountString: string;
};

export const defaultFinancingTermsLabels: FinancingTermsLabels = {
    amountDueLabel: NULL_VAL_SYMBOL,
    termLengthLabel: NULL_VAL_SYMBOL,
    financeChargeLabel: NULL_VAL_SYMBOL,
    interestRatePercentLabel: NULL_VAL_SYMBOL,
    loanValueString: NULL_VAL_SYMBOL,
    downPaymentLabel: NULL_VAL_SYMBOL,
    downPaymentPercentLabel: NULL_VAL_SYMBOL,
    principalAmountString: NULL_VAL_SYMBOL,
};

export const getFinancingTermLabels = (
    { interestRateBPS, downPaymentBPS, downPayment, loanValue, termLength, totalAmountDue, principalAmount }: FinancingTerms,
    symbol: string,
    decimals: number,
): FinancingTermsLabels => {
    const weiToDisplayAmount = (wei: BigNumber) => utils.formatUnits(wei, decimals);

    const interestRatePercentLabel = `${interestRateBPS / 100}%`;
    const downPaymentLabel = `${weiToDisplayAmount(downPayment)} ${symbol}`;
    const downPaymentPercentLabel = `${downPaymentBPS / 100}%`;

    const loanValueString = `${weiToDisplayAmount(loanValue)} ${symbol}`;
    const principalAmountString = `${weiToDisplayAmount(principalAmount)} ${symbol}`;

    const financeChargeWei = loanValue.mul(interestRateBPS).div(MAX_BPS_BN);
    const financeChargeLabel = `${weiToDisplayAmount(financeChargeWei)} ${symbol}`;

    const termLengthString = transformTermLengthInputToLabel(termLength);
    const termLengthLabel = !!termLengthString.trim() ? termLengthString : NULL_VAL_SYMBOL;

    const amountDueLabel = `${weiToDisplayAmount(totalAmountDue)} ${symbol}`;

    return {
        interestRatePercentLabel,
        downPaymentLabel,
        downPaymentPercentLabel,
        loanValueString,
        financeChargeLabel,
        termLengthLabel,
        amountDueLabel,
        principalAmountString,
    };
};

export const FinanceTermsLabel = ({ children }: { children: React.ReactNode }) => (
    <Text color={'gray.700'} fontWeight={700} fontSize="12px" alignSelf={'center'}>
        {children}
    </Text>
);

type TermGridItemProps = { label: string; value: string; noBorder?: boolean };
export const TermGridItem = ({ label, value, noBorder }: TermGridItemProps) => (
    <GridItem key={label}>
        <ItemDesc title={label} py="2" {...(!noBorder && { borderBottom: '1px rgba(210, 210, 210, 1) solid' })} spacing="0.5">
            {value}
        </ItemDesc>
    </GridItem>
);

export const TermsSummary = ({
    terms,
    feeInfo,
    hideDownPaymentRow,
}: {
    terms: FinancingTermsLabels;
    feeInfo?: string;
    hideDownPaymentRow?: boolean;
}) => {
    const [open, setOpen] = useBoolean();

    return (
        <Box bg="rgba(237, 242, 247, 1)" border="1px rgba(226, 232, 240, 1) solid" borderRadius={'4px'} px="4" py="3">
            <Flex justifyContent={'space-between'} w="100%" cursor={'pointer'} onClick={setOpen.toggle}>
                <Text fontWeight={500} fontSize="19px" lineHeight={'28px'} as="b">
                    Terms
                </Text>
                <ChevronDownIcon w={6} h={6} />
            </Flex>
            {open && (
                <Grid templateColumns={'repeat(3, 1fr)'} mt="2">
                    {!hideDownPaymentRow && (
                        <>
                            <TermGridItem label="Total Amount" value={terms.principalAmountString} />
                            <TermGridItem label="Down Payment" value={terms.downPaymentPercentLabel} />
                            <TermGridItem label="Down Payment" value={terms.downPaymentLabel} />
                        </>
                    )}
                    <TermGridItem label="Loan Amount" value={terms.loanValueString} />
                    <TermGridItem label="Interest Rate" value={terms.interestRatePercentLabel} />
                    <TermGridItem label="Finance Charge" value={terms.financeChargeLabel} />
                    {!!feeInfo ? <TermGridItem label="Origination Fee" value={feeInfo} noBorder /> : <GridItem key="noFee" />}
                    <TermGridItem label="Loan Term" value={terms.termLengthLabel} noBorder />
                    <TermGridItem label="Total Amount Due" value={terms.amountDueLabel} noBorder />
                </Grid>
            )}
        </Box>
    );
};
