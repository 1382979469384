import { Sparkle, Wallet, Clipboard } from 'phosphor-react';
import { Progress, HStack, VStack, Box, Text, Icon, BoxProps } from '@chakra-ui/react';
import React from 'react';

export enum LedgerWizardStep {
    SelectName = 'SelectName',
    SelectWallet = 'SelectWallet',
    ExportSuccess = 'ExportSuccess',
    ReportFetching = 'ReportFetching',
}

interface IconBoxProps extends BoxProps {
    icon: React.ElementType;
}

export const IconBox: React.FC<IconBoxProps> = ({ icon, boxShadow = 'md', ...props }) => (
    <Box
        bg="white"
        p={3}
        borderRadius="xl"
        boxShadow={boxShadow}
        color="gray.700"
        border="1px solid"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...props}
    >
        <Icon as={icon} boxSize={6} />
    </Box>
);

const steps = [
    { icon: Clipboard, label: 'Start Tax Report', step: LedgerWizardStep.SelectName },
    { icon: Wallet, label: 'Add Wallets', step: LedgerWizardStep.SelectWallet },
    { icon: Sparkle, label: 'Build report', step: LedgerWizardStep.ReportFetching },
];

interface ProgressStepsProps {
    currentStep: string;
}

const mapStepToProgress = (step: string): number => {
    switch (step) {
        case LedgerWizardStep.SelectName:
            return 0;
        case LedgerWizardStep.SelectWallet:
            return 50;
        case LedgerWizardStep.ReportFetching:
            return 100;
        default:
            return 0;
    }
};

export const ProgressSteps: React.FC<ProgressStepsProps> = ({ currentStep }) => {
    return (
        <Box width="100%" maxW="600px" position="relative" mt="20">
            <Progress value={mapStepToProgress(currentStep)} height="2px" width="100%" colorScheme="orange" />
            <HStack justify="space-between" position="absolute" width="120%" top="-21px" left="-40px">
                {steps.map((item, index) => (
                    <VStack key={index}>
                        <IconBox icon={item.icon} />
                        <Text
                            fontSize="md"
                            color={currentStep === item.step ? 'gray.700' : 'gray.400'}
                            mt="2"
                            fontWeight={currentStep === item.step ? '600' : '400'}
                        >
                            {item.label}
                        </Text>
                    </VStack>
                ))}
            </HStack>
        </Box>
    );
};
