import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    MenuItem,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { ArrowLeft, CaretDown } from 'phosphor-react';
import React, { ReactElement, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { ClaimLedgerEntryDto, LedgerAccount, PaymentLedgerEntryDto } from '../../../hooks/useExternalTransactionsApi';
import { Priced, PriceFetchResult, useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { usePagination } from '../../../hooks/usePagination';
import { useSelection } from '../../../hooks/useSelection';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { TokenSafetyContext, useTokenSafety } from '../../../hooks/useTokenSafety';
import { ReportingBadge } from '../../../pages/reporting/reporting-explorer';
import { fillToCount, quickHash, toDateDisplay, toUSD } from '../../../tools/common';
import { ContactNameOrAddress, shortAddress } from '../../base/address-label';
import { PageSelector } from '../../display/claim-table';
import { ResponsiveStack } from '../../display/responsive-stack';
import { TokenMultiselect } from '../../display/token-multiselect';
import { NetworkMultiselect } from '../../display/view-network-selector';
import { TabOptions, TabSwitcher } from '../../display/views/account-tag-view';
import { checkDate, filterButtonProps, TableFilters } from '../../display/views/filters/claim-filter';
import {
    ClearFilterPillsStack,
    DateSelect,
    filterMenuProps,
    PillProps,
    SetDate,
    TableFilter,
    WalletFilter,
} from '../../display/views/filters/common';
import { MenuDropdownButton, SecondaryButton } from '../../inputs/buttons';
import { ColumnDefinition, CR_DR_COLUMN_WIDTH, ListItemProps, ListViewCard } from '../../layout/cards';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { LedgerExportModal } from './ledger-export-modal';
import { SuccessState } from './ledger-wizard-modal';
import { LedgerExportType, ledgerExportTypes } from './select-export-type-card';

interface LedgerExportSummaryItemProps {
    badgeNumber: string;
    title: string;
    amount: number;
}

export type DomainPaymentLedgerEntry = Priced<Omit<PaymentLedgerEntryDto, 'token' | 'timestamp'>> & {
    __typename: 'Payment';
    token: TokenInfo;
    timestamp: Date;
    usdMark: PriceFetchResult;
    usdValue: PriceFetchResult;
    displayAmount: number;
    id: string;
};

export type DomainClaimLedgerEntry = Priced<Omit<ClaimLedgerEntryDto, 'token'>> & {
    __typename: 'Claim';
    timestamp: Date;
    token: TokenInfo;
    usdMark: PriceFetchResult;
    usdValue: PriceFetchResult;
    displayAmount: number;
    id: string;
};

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedWallets: Set<string>;
    selectedNetworks: Set<ChainId>;
    selectedTokenSymbols: Set<string>;
};

type SetFilter = (key: keyof ExportFilterValues, value: ExportFilterValues[keyof ExportFilterValues]) => void;

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
    selectableTokenSymbols: Set<string>;
    selectableWallets: Set<string>;
    selectableNetworks: Set<ChainId>;
};

const ExportFilter = ({ setFilters, filters, selectableTokenSymbols, selectableNetworks, selectableWallets }: FilterProps) => {
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <WalletFilter
                    selectableWallets={selectableWallets}
                    selectedWallets={filters.selectedWallets}
                    onClick={address =>
                        setFilter(
                            'selectedWallets',
                            filters.selectedWallets.has(address)
                                ? (() => {
                                      filters.selectedWallets.delete(address);
                                      return filters.selectedWallets;
                                  })()
                                : filters.selectedWallets.add(address),
                        )
                    }
                />
                <NetworkMultiselect
                    selectableNetworks={selectableNetworks}
                    selectedNetworks={filters.selectedNetworks}
                    toggleNetwork={chainId =>
                        setFilter(
                            'selectedNetworks',
                            filters.selectedNetworks.has(chainId)
                                ? (() => {
                                      filters.selectedNetworks.delete(chainId);
                                      return filters.selectedNetworks;
                                  })()
                                : filters.selectedNetworks.add(chainId),
                        )
                    }
                />
                <TokenMultiselect
                    seletectableTokenSymbols={selectableTokenSymbols}
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={symbol =>
                        setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        )
                    }
                />
            </ResponsiveStack>
        </Box>
    );
};

export const LedgerExportSummaryItem: React.FC<LedgerExportSummaryItemProps> = ({ badgeNumber, title, amount }) => {
    return (
        <VStack align="start" spacing="2">
            <ReportingBadge backgroundColor="#EEF4FF" color="#3538CD" borderColor="#C7D7FE" borderRadius="xl" ml="0">
                {badgeNumber}
            </ReportingBadge>
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="2xl" fontWeight="bold">
                $ {amount.toFixed(2)}
            </Text>
        </VStack>
    );
};

interface FilterPopoverProps {
    label: string;
    icon: ReactElement;
}

export const FilterPopover: React.FC<FilterPopoverProps> = ({ label, icon }) => {
    return (
        <Popover placement="bottom-start">
            <PopoverTrigger>
                <Button leftIcon={icon} rightIcon={<CaretDown size={16} />} {...filterButtonProps} width="fit-content" color="gray.700">
                    {label}
                </Button>
            </PopoverTrigger>
            <PopoverContent {...filterMenuProps}>
                <PopoverBody>
                    <Text>Mock Filter</Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const PAYMENT_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'FROM' },
    { label: 'TO' },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD VALUE', relativeColumnWidth: '1fr' },
    { label: 'DR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'CR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
];

const CLAIM_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'CREDITOR' },
    { label: 'DEBTOR' },
    { label: 'CLAIM ID' },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'ACTION', relativeColumnWidth: '1fr' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD VALUE', relativeColumnWidth: '1fr' },
    { label: 'DR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'CR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
];

const toDisplayValue = (account: LedgerAccount | undefined) => {
    switch (account) {
        case 'ap':
            return 'Crypto AP (USD)';
        case 'ar':
            return 'Crypto AR (USD)';
        case 'cash':
            return 'Crypto Asset Cash (USD)';
        case 'sales':
            return 'Crypto Sales (USD)';
        case 'expense':
            return 'Crypto Expense (USD)';
        case undefined:
            return '';
        default:
            return 'NOT NAMED YET';
    }
};

const paymentEntryToRow = (entry: DomainPaymentLedgerEntry, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);
    return {
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            <ContactNameOrAddress chainId={chainId}>{entry.from}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.to}</ContactNameOrAddress>,
            entry.description,
            NETWORKS[chainId].label,
            toUSD(entry.displayAmount, true).replace('$', ''),
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            typeof entry.usdMark !== 'string' ? toUSD(entry.usdMark) : 'N/A',
            typeof entry.usdValue !== 'string' ? toUSD(entry.usdValue) : 'N/A',
            ...fillToCount(entry.adjustments.dr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toDisplayValue(text)}
                </Text>
            )),
            ...fillToCount(entry.adjustments.cr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toDisplayValue(text)}
                </Text>
            )),
        ],
        selectId: entry.id,
        isUnsafe: !isTokenSafe,
    };
};

const claimEntryToRow = (entry: DomainClaimLedgerEntry, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    return {
        selectId: entry.id,
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            <ContactNameOrAddress chainId={chainId}>{entry.creditor}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.debtor}</ContactNameOrAddress>,
            entry.claimId,
            entry.description,
            entry.eventName.split('Event')[0],
            NETWORKS[chainId].label,
            toUSD(weiToDisplayAmt({ amountWei: BigNumber.from(entry.amount), decimals: entry.token.token.decimals }), true).replace(
                '$',
                '',
            ),
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            typeof entry.usdMark !== 'string' ? toUSD(entry.usdMark) : 'N/A',
            typeof entry.usdValue !== 'string' ? toUSD(entry.usdValue) : 'N/A',
            ...fillToCount(entry.adjustments.dr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toDisplayValue(text)}
                </Text>
            )),
            ...fillToCount(entry.adjustments.cr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toDisplayValue(text)}
                </Text>
            )),
        ],
        isUnsafe: !isTokenSafe,
    };
};

export const getLedgerExportTypeDisplayName = (type: LedgerExportType): string => {
    switch (type) {
        case 'AccountsReceivables':
            return 'Accounts Receivables';
        case 'AccountsPayables':
            return 'Accounts Payables';
        case 'CashDisbursements':
            return 'Cash Disbursements';
        default:
            return type;
    }
};

const getPathnameForLedgerExport = (tab: LedgerExportType) => {
    return {
        pathname: '/ledger-wizard',
        search: `?tab=${tab}`,
    };
};

export const buildExportFilters = (filterValues: ExportFilterValues): TableFilters<DomainClaimLedgerEntry | DomainPaymentLedgerEntry> => {
    const {
        date: { startDate, endDate },
        selectedWallets,
        selectedNetworks,
        selectedTokenSymbols,
    } = filterValues;
    const filterByDate =
        !startDate && !endDate
            ? undefined
            : ({ timestamp }: DomainClaimLedgerEntry | DomainPaymentLedgerEntry) => checkDate(timestamp, startDate, endDate);

    const filterByWallets =
        selectedWallets.size !== 0
            ? (item: DomainClaimLedgerEntry | DomainPaymentLedgerEntry) =>
                  (item.__typename == 'Claim' ? [item.creditor, item.debtor] : [item.to, item.from])
                      .map(x => x.toLowerCase())
                      .some(x => selectedWallets.has(x))
            : undefined;

    const filterByNetwork =
        selectedNetworks.size == 0
            ? undefined
            : (payment: DomainClaimLedgerEntry | DomainPaymentLedgerEntry) => selectedNetworks.has(payment.chainId as ChainId);

    const filterByToken =
        selectedTokenSymbols.size == 0
            ? undefined
            : (payment: DomainClaimLedgerEntry | DomainPaymentLedgerEntry) =>
                  selectedTokenSymbols.has(payment.token.token.symbol.toUpperCase());

    return [filterByDate, filterByWallets, filterByNetwork, filterByToken].filter(
        (x): x is TableFilter<DomainClaimLedgerEntry | DomainPaymentLedgerEntry> => x !== undefined,
    );
};

const initialExportFilterValues = (): ExportFilterValues => ({
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
});

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const contactsContext = useExtendedContacts();

    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const walletPills: PillProps[] = [...filters.selectedWallets].map(wallet => {
        const contact = isContactsReady(contactsContext) ? contactsContext.getContact(wallet) : 'not-found';
        return {
            label: contact == 'not-found' ? shortAddress(wallet, 3) : contact.name,
            clear: () =>
                setFilters(filters => {
                    const selectedWallets = new Set([...filters.selectedWallets]);
                    selectedWallets.delete(wallet);
                    return { ...filters, selectedWallets };
                }),
        };
    });

    const networkPills: PillProps[] = [...filters.selectedNetworks].map(network => ({
        label: NETWORKS[network].label,
        clear: () =>
            setFilters(filters => {
                const selectedNetworks = new Set([...filters.selectedNetworks]);
                selectedNetworks.delete(network);
                return { ...filters, selectedNetworks };
            }),
    }));

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));
    return [...dateFilterPills, ...walletPills, ...networkPills, ...tokenPills];
};

export const LedgerExportSuccessCard = ({ wizardState }: { wizardState: SuccessState }) => {
    const ledgerExportTabOptions: TabOptions<LedgerExportType>[] = ledgerExportTypes.map(tab => ({
        label: getLedgerExportTypeDisplayName(tab),
        value: tab,
    }));
    const { getHistoricalTokenPrice, USDMark } = useHistoricalPrices();

    const navigate = useNavigate();
    const setTab = (tab: LedgerExportType) => {
        setActiveTab(tab);
        navigate(getPathnameForLedgerExport(tab), { replace: true });
    };
    const selectedWalletsNumber = wizardState.walletChainSelection.addresses.size;
    const [deletedEntries, setDeletedEntries] = React.useState<Set<String>>(new Set());
    const [activeTab, setActiveTab] = useState<LedgerExportType>(ledgerExportTypes[0]);
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const { isTokenInfoSafe } = useTokenSafety();

    const [filters, setFilters] = React.useState<ExportFilterValues>(initialExportFilterValues);

    const aggregatedFilter = React.useMemo(() => buildExportFilters(filters), [filters]);

    const {
        payables: cashOutEntries,
        receivables: cashInEntries,
        transfers,
    } = React.useMemo(() => {
        return wizardState.ledgerExportResponse.paymentEntries
            .flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];

                const timestamp = intToDate(x.timestamp);
                const usdMark = getHistoricalTokenPrice({
                    chainId: x.chainId as ChainId,
                    timestamp,
                    tokenAddress: x.token.address,
                });

                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });

                const entry: Omit<DomainPaymentLedgerEntry, 'id'> = {
                    ...x,
                    usdMark,
                    __typename: 'Payment',
                    token: token,
                    usdValue: typeof usdMark == 'number' ? usdMark * displayAmount : usdMark,
                    displayAmount,
                    timestamp,
                };

                const id = quickHash(entry);

                return [{ ...entry, id }];
            })
            .reduce<{
                payables: DomainPaymentLedgerEntry[];
                receivables: DomainPaymentLedgerEntry[];
                transfers: DomainPaymentLedgerEntry[];
            }>(
                (acc, item) =>
                    item.direction == 'Payable'
                        ? { ...acc, payables: [...acc.payables, item] }
                        : item.direction == 'Receivable'
                        ? { ...acc, receivables: [...acc.receivables, item] }
                        : item.direction == 'Transfer'
                        ? { ...acc, transfers: [...acc.transfers, item] }
                        : acc,
                { payables: [], receivables: [], transfers: [] },
            );
    }, [USDMark]);

    const { payables, receivables } = React.useMemo(() => {
        return wizardState.ledgerExportResponse.claimEntries
            .flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];
                const timestamp = intToDate(x.eventTimestamp);

                const usdMark = getHistoricalTokenPrice({
                    chainId: x.chainId as ChainId,
                    timestamp,
                    tokenAddress: x.token.address,
                });

                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amount), decimals: token.token.decimals });

                const entry: Omit<DomainClaimLedgerEntry, 'id'> = {
                    ...x,
                    usdMark,
                    __typename: 'Claim',
                    timestamp,
                    usdValue: typeof usdMark == 'number' ? usdMark * displayAmount : usdMark,
                    displayAmount,
                    token: token,
                };

                const id = quickHash(entry);

                return [{ ...entry, id }];
            })
            .reduce<{ payables: DomainClaimLedgerEntry[]; receivables: DomainClaimLedgerEntry[] }>(
                (acc, item) =>
                    item.direction == 'Payable' || item.direction == 'OriginalCreditorOfFactoredClaim'
                        ? { ...acc, payables: [...acc.payables, item] }
                        : item.direction == 'Receivable'
                        ? { ...acc, receivables: [...acc.receivables, item] }
                        : acc,
                { payables: [], receivables: [] },
            );
    }, [USDMark]);

    const entries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry)[] = React.useMemo(() => {
        let _entries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry)[];
        switch (activeTab) {
            case 'AccountsPayables':
                _entries = payables;
                break;
            case 'AccountsReceivables':
                _entries = receivables;
                break;
            case 'CashDisbursements':
                _entries = [...cashOutEntries, ...cashInEntries];
                break;
            case 'Transfers':
                _entries = transfers;
                break;
            default:
                _entries = [];
                break;
        }
        return _entries.filter(x => !deletedEntries.has(x.id));
    }, [USDMark, payables, receivables, cashInEntries, cashOutEntries, activeTab, deletedEntries]);

    const selectOptions = useSelection(entries.map(x => x.id));
    const selected = selectOptions.selected;

    const allEntries = React.useMemo(
        () => [...payables, ...receivables, ...cashInEntries, ...cashOutEntries, ...transfers].filter(x => !deletedEntries.has(x.id)),
        [payables, receivables, cashInEntries, cashOutEntries, USDMark, deletedEntries, transfers],
    );

    const [selectableNetworks, selectableTokenSymbols, selectableWallets] = React.useMemo(() => {
        return [
            new Set(wizardState.walletChainSelection.chainIds),
            new Set(entries.map(x => x.token.token.symbol)),
            new Set(entries.flatMap(x => (x.__typename == 'Claim' ? [x.creditor, x.debtor] : [x.from, x.to])).map(x => x.toLowerCase())),
        ];
    }, [entries]);

    const { totalCash, totalAr, totalAp } = React.useMemo(() => {
        return allEntries
            .filter(x => x.direction !== 'Transfer')
            .reduce<{ totalCash: number; totalAp: number; totalAr: number }>(
                (acc, { adjustments, usdValue }) => ({
                    totalCash:
                        acc.totalCash +
                        (adjustments.cr.find(x => x == 'cash') && typeof usdValue == 'number' ? usdValue : 0) -
                        (adjustments.dr.find(x => x == 'cash') && typeof usdValue == 'number' ? usdValue : 0),
                    totalAp:
                        acc.totalAp +
                        (adjustments.cr.find(x => x == 'ap') && typeof usdValue == 'number' ? usdValue : 0) -
                        (adjustments.dr.find(x => x == 'ap') && typeof usdValue == 'number' ? usdValue : 0),
                    totalAr:
                        acc.totalAr +
                        (adjustments.cr.find(x => x == 'ar') && typeof usdValue == 'number' ? usdValue : 0) -
                        (adjustments.dr.find(x => x == 'ar') && typeof usdValue == 'number' ? usdValue : 0),
                }),
                { totalCash: 0, totalAr: 0, totalAp: 0 },
            );
    }, [allEntries, deletedEntries]);

    const filteredEntries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry)[] = React.useMemo(
        () =>
            aggregatedFilter
                .reduce((entries, filterFunc) => entries.filter(filterFunc), entries)
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()),
        [entries, aggregatedFilter],
    );

    const pageSelectorProps = usePagination(filteredEntries);
    const visibleEntries = pageSelectorProps.shownItems;

    const visibleRows = React.useMemo(
        () =>
            visibleEntries.map(x =>
                x.__typename == 'Payment' ? paymentEntryToRow(x, isTokenInfoSafe) : claimEntryToRow(x, isTokenInfoSafe),
            ),
        [visibleEntries],
    );

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">Ledger Export</Heading>
                    <Spacer />
                    <LedgerExportModal
                        allEntries={allEntries}
                        triggerElement={onClick => (
                            <SecondaryButton onClick={onClick} h="10" px="6">
                                Export
                            </SecondaryButton>
                        )}
                    />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'}>
                    <HStack mt="4" alignItems="flex-end">
                        <TabSwitcher tab={activeTab} setTab={setTab} options={ledgerExportTabOptions} activeColor="brand.bulla_blue" />
                    </HStack>
                </Stack>
                <Flex direction={'column'} flex="1">
                    <Box my="8" px="6" py="8" borderWidth="1px" borderRadius="md" boxShadow="sm">
                        <Heading size="md" mb="4">
                            Accounts Receivable Summary
                        </Heading>
                        <Text mb="4" color="brand.bulla_blue" textDecoration="underline">
                            {selectedWalletsNumber} wallet{selectedWalletsNumber > 1 ? 's' : ''} selected
                        </Text>
                        <Flex justifyContent="flex-start" gap="12">
                            <LedgerExportSummaryItem badgeNumber="185000" title="CRYPTO ASSET CASH" amount={totalCash} />
                            <LedgerExportSummaryItem badgeNumber="110100" title="CRYPTO AR" amount={totalAr} />
                            <LedgerExportSummaryItem badgeNumber="410000" title="CRYPTO AP" amount={totalAp} />
                        </Flex>
                    </Box>
                    <Flex>
                        <ExportFilter
                            filters={filters}
                            setFilters={setFilters}
                            selectableNetworks={selectableNetworks}
                            selectableTokenSymbols={selectableTokenSymbols}
                            selectableWallets={selectableWallets}
                        />
                        <Spacer />
                        <MenuDropdownButton
                            colorScheme={'accent'}
                            h="10"
                            py="0"
                            px="4"
                            text={`Actions${selected.length == 0 ? '' : ` (${selected.length})`}`}
                            icon={<ChevronDownIcon w="6" h="6" />}
                            actions={[
                                <MenuItem
                                    onClick={() =>
                                        setDeletedEntries(prev => {
                                            const newSet = new Set([...prev]);
                                            selected.forEach(x => newSet.add(x));
                                            selectOptions.resetSelection();
                                            return newSet;
                                        })
                                    }
                                >
                                    Remove from report
                                </MenuItem>,
                            ]}
                            isDisabled={selected.length == 0}
                        />
                    </Flex>

                    <ClearFilterPillsStack
                        clearAll={() => setFilters(initialExportFilterValues())}
                        filters={filters}
                        filtersToPills={exportFilterToPills(setFilters)}
                    />
                    <Box my="8">
                        <ListViewCard
                            headers={
                                activeTab == 'AccountsPayables' || activeTab == 'AccountsReceivables' ? CLAIM_HEADERS : PAYMENT_HEADERS
                            }
                            displayedListItems={visibleRows}
                            emptyMessage="No ledger export data available"
                            rowSizeOverride="5em"
                            alternateRowColor="#F4F6F9"
                            showOverflowX
                            selectOptions={selectOptions}
                        />
                        <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
                    </Box>
                </Flex>
            </MaxWidthWrapper>
        </Flex>
    );
};
