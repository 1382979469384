import React, { useEffect, useMemo } from 'react';
import { filterDuplicateArrayVals } from '../data-lib/helpers';
import { allNetworks, ChainId, NETWORKS, SUPPORTED_NETWORKS } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { includeTestnetsInDashboard } from '../tools/featureFlags';
import { usePrevious } from './usePrevious';
import { useQuery } from './useQuery';
import { useLocalStorage } from './useStorage';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

type SelectedNetworks = {
    selectedNetworks: ChainId[];
    addedNetworks: ChainId[];
    removedNetworks: ChainId[];
    addNetwork: (chainId: ChainId | ChainId[]) => void;
    toggleNetwork: (chainId: ChainId) => void;
    overrideSelectedNetworks: (chainIds: ChainId[] | undefined) => void;
};

type SelectedNetworksContext = 'uninitialized' | SelectedNetworks;

const SelectedNetworksContext = React.createContext<SelectedNetworksContext>('uninitialized');

export const SelectedNetworkProvider = ({ children }: { children: React.ReactNode }) => {
    const queriedNetwork = useQuery('network');
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    const { connectedSafeAddress } = useGnosisSafe();
    const [_selectedNetworks, setSelectedNetworks] = useLocalStorage<ChainId[]>(
        `selected-networks:${queryAddress}`,
        queriedNetwork && NETWORKS[+queriedNetwork] ? [connectedNetwork, +queriedNetwork] : [connectedNetwork],
    );
    const [overrideSelectedNetworks, setOverrideSelectedNetworks] = React.useState<ChainId[] | undefined>(undefined);
    const supportedSelectedNetworks = _selectedNetworks.filter(x => SUPPORTED_NETWORKS.includes(x));
    const selectedNetworks =
        !!connectedSafeAddress || supportedSelectedNetworks.length == 0
            ? [connectedNetwork]
            : !!overrideSelectedNetworks
            ? overrideSelectedNetworks
            : allNetworks.map(x => x.chainId); // include the user selected networks and networks opened from a link or a wallet connection
    //   filterDuplicateArrayVals([
    //       ...supportedSelectedNetworks,
    //       connectedNetwork,
    //       ...(queriedNetwork ? [+queriedNetwork as ChainId] : []),
    //   ]);
    const previouslySelectedNetworks = usePrevious(selectedNetworks);
    const previousNetwork = usePrevious(connectedNetwork);

    useEffect(() => {
        if (previousNetwork !== connectedNetwork)
            setSelectedNetworks(prev => (prev.includes(connectedNetwork) ? prev : [...prev, connectedNetwork]));
    }, [connectedNetwork]);

    const context = useMemo(() => {
        const toggleNetwork = (chainId: ChainId) => {
            setSelectedNetworks(prev => (prev.includes(chainId) ? prev.filter(x => x !== chainId) : [...prev, chainId]));
        };

        const addNetwork = (chainId: ChainId | ChainId[]) =>
            setSelectedNetworks(prev => [...new Set([...prev, ...(chainId instanceof Array ? chainId : [chainId])])]);

        const addedNetworks = selectedNetworks.filter(x => !previouslySelectedNetworks.includes(x));
        const removedNetworks = previouslySelectedNetworks.filter(x => !selectedNetworks.includes(x));

        return {
            addedNetworks,
            removedNetworks,
            toggleNetwork,
            addNetwork,
            selectedNetworks,
            overrideSelectedNetworks: (chainIds: ChainId[] | undefined) => setOverrideSelectedNetworks(chainIds),
        };
    }, [selectedNetworks]);

    return <SelectedNetworksContext.Provider value={context}>{children}</SelectedNetworksContext.Provider>;
};

export const useSelectedNetworks = (): SelectedNetworks => {
    const { connectedNetwork } = useWeb3();
    const context = React.useContext(SelectedNetworksContext);

    if (!context) throw new Error('Error: you must call useSelectedNetworks with the SelectedNetworkProvider');
    if (context == 'uninitialized')
        return {
            selectedNetworks: [connectedNetwork],
            addedNetworks: [],
            removedNetworks: [],
            addNetwork: _ => {},
            toggleNetwork: _ => {},
            overrideSelectedNetworks: _ => {},
        };

    return context;
};
