import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Skeleton,
    Spacer,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React, { useMemo } from 'react';
import { BullaItemInfo, ClaimType } from '../../../data-lib/data-model';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { isClaim } from '../../../data-lib/helpers';
import { TokenInfo } from '../../../data-lib/networks';
import { Contact, useExtendedContacts, isContactsReady } from '../../../hooks/useExtendedContacts';
import { useSearchFilter } from '../../../hooks/useSearchFilter';
import { useSelection } from '../../../hooks/useSelection';
import { useCurrentChainUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { toDateDisplay } from '../../../tools/common';
import { Bytes32Label } from '../../base/address-label';
import { emptyMessageForContactsContextState, getDisplayedListItemsForContacts, SearchBar } from '../../display/views/contacts';
import { ListItemProps, ListViewCard } from '../../layout/cards';
import { CloseModalButton } from '../common';
import { emptyPaymentDetails, PaymentDetails } from './batch-state';

type ContactSelectionProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    onAdd: (contacts: Contact[]) => void;
};

export const ContactSelectionModal = ({ onAdd, triggerElement }: ContactSelectionProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const contactsContext = useExtendedContacts();
    const contactsReady = isContactsReady(contactsContext);
    const contacts = contactsReady ? contactsContext.contacts : [];

    const [filteredContacts, queryString, setQueryString] = useSearchFilter(contacts, (item: Contact) =>
        [item.name, item.walletAddress, item.emailAddress].filter((x): x is string => x !== undefined),
    );
    const selectOptions = useSelection(contacts.map(({ walletAddress }) => walletAddress));
    const showSkeleton = contactsContext == 'fetching';
    const selectedContacts = selectOptions.selected;

    const contactsListViewCard = (
        <Skeleton isLoaded={!showSkeleton}>
            <Flex h="50vh" minHeight={'200px'} p="0" direction="column" overflow="auto">
                <ListViewCard
                    headers={[
                        { label: 'Name', relativeColumnWidth: '3fr' },
                        { label: 'Wallet', relativeColumnWidth: '3fr' },
                        { label: 'Email', relativeColumnWidth: '4fr' },
                        { label: 'Groups', relativeColumnWidth: '2fr' },
                    ]}
                    displayedListItems={getDisplayedListItemsForContacts(filteredContacts, false)}
                    selectOptions={selectOptions}
                    totalItemCount={contactsReady ? contactsContext.contacts.length : 0}
                    emptyMessage={emptyMessageForContactsContextState(contactsContext)}
                    bordered={false}
                    flexHeight={true}
                />
            </Flex>
        </Skeleton>
    );

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'4xl'} preserveScrollBarGap={true}>
                <ModalOverlay />
                <ModalContent py="4" px="2" minHeight={'400px'}>
                    <CloseModalButton onClose={onClose} />
                    <ModalBody pb={6} pt={4} h="100%" overflow={'visible'}>
                        <Text color="icon_dark" textStyle="labelLg">
                            Select Contacts
                        </Text>
                        <Box h="4" />
                        <SearchBar queryString={queryString} setQueryString={setQueryString} />
                        <Box h="4" />
                        {contactsListViewCard}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            {'Cancel'}
                        </Button>
                        <Spacer />
                        <Button
                            px="8"
                            py="6"
                            fontWeight="500"
                            colorScheme="dark"
                            type="submit"
                            isDisabled={selectedContacts.length == 0}
                            onClick={() => {
                                const walletSet = new Set(selectedContacts);
                                onAdd(contacts.filter(contact => walletSet.has(contact.walletAddress)));
                                onClose();
                                selectOptions.resetSelection();
                            }}
                        >
                            {`Add${selectedContacts.length == 0 ? '' : ` (${selectedContacts.length})`}`}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

type ImportFromPreviousBatchModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    claimType: ClaimType;
    onAdd: (paymentDetails: PaymentDetails[]) => void;
};

const toPreviousBatchLineItem = (network: number, hash: string, items: BullaItemInfo[]): ListItemProps => ({
    columnValues: [
        <Text key="date">{toDateDisplay(items[0].created)}</Text>,
        <Bytes32Label bytes32={hash} key="txid" />,
        <Text key="description">
            {
                Object.entries(
                    items.reduce<Record<string, number>>(
                        (acc, item) => ({ ...acc, [item.description]: (acc[item.description] ?? 0) + 1 }),
                        {},
                    ),
                ).sort((a, b) => b[1] - a[1])[0][0]
            }
        </Text>,
        <Text key="# of recipient">{items.length}</Text>,
        <Text key="amounts">
            {Object.entries(
                items.reduce<Record<string, [TokenInfo, BigNumber]>>(
                    (acc, item) => ({
                        ...acc,
                        [item.tokenInfo.token.symbol]: [
                            item.tokenInfo,
                            (isClaim(item) ? item.claimAmount : item.paidAmount).add(
                                (acc[item.tokenInfo.token.symbol] ?? [undefined, BigNumber.from(0)])[1],
                            ),
                        ],
                    }),
                    {},
                ),
            )
                .map(([symbol, [tokenInfo, amount]]) => `${weiToDisplayAmt({ amountWei: amount, token: tokenInfo.token })} ${symbol}`)
                .join(', ')}
        </Text>,
    ],
    selectId: hash,
});

export const ImportFromPreviousBatchModal = ({ triggerElement, claimType, onAdd }: ImportFromPreviousBatchModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { userBatchesByTxHash } = useCurrentChainUserData();
    const userAddress = useActingWalletAddress();
    const { connectedNetwork } = useWeb3();
    const contactsContext = useExtendedContacts();
    const contactsReady = isContactsReady(contactsContext);

    const getContactInfo = (walletAddress: string) => {
        if (!contactsReady) return undefined;
        const contact = contactsContext.getContact(walletAddress);
        return contact == 'not-found' ? undefined : { name: contact.name, emailAddress: contact.emailAddress ?? '' };
    };

    const batches = Object.entries(userBatchesByTxHash).filter(
        ([_, items]) => items.length > 1 && items.every(item => addressEquality(item.creditor, userAddress)) === (claimType === 'Invoice'),
    );

    const hashes = batches.map(([hash]) => hash);

    const selectOptions = useSelection(hashes);
    const lineItems = useMemo(
        () => batches.map(([hash, items]) => toPreviousBatchLineItem(connectedNetwork, hash, items)),
        [userBatchesByTxHash],
    );

    const batchesListViewCard = (
        <Flex h="50vh" minHeight={'200px'} p="0" direction="column" overflow={'auto'}>
            <ListViewCard
                headers={[
                    { label: 'Date', relativeColumnWidth: '0.7fr' },
                    { label: 'TxId', relativeColumnWidth: '0.7fr' },
                    { label: 'Description', relativeColumnWidth: '1.2fr' },
                    { label: '# of Recipients', relativeColumnWidth: '0.7fr' },
                    { label: 'Amount', relativeColumnWidth: '0.8fr' },
                ]}
                displayedListItems={lineItems}
                selectOptions={selectOptions}
                totalItemCount={lineItems.length}
                emptyMessage={'No previous batches'}
                bordered={false}
                flexHeight={true}
            />
        </Flex>
    );

    const selectedHashes = selectOptions.selected;

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={'5xl'} preserveScrollBarGap={true}>
                <ModalOverlay />
                <ModalContent py="4" px="2" minHeight={'400px'}>
                    <CloseModalButton onClose={onClose} />
                    <ModalBody pb={6} pt={4} h="100%" overflow={'visible'}>
                        <Text color="icon_dark" textStyle="labelLg">
                            Import from Previous Batch
                        </Text>
                        <Box h="4" />
                        {batchesListViewCard}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            {'Cancel'}
                        </Button>
                        <Spacer />
                        <Button
                            px="8"
                            py="6"
                            fontWeight="500"
                            colorScheme="dark"
                            isDisabled={selectedHashes.length == 0}
                            onClick={() => {
                                const hashesSet = new Set(selectedHashes);
                                const paymentDetailsToAdd = batches
                                    .filter(([hash]) => hashesSet.has(hash))
                                    .map(([_, items]) => items)
                                    .flat()
                                    .map(
                                        (item): PaymentDetails => ({
                                            ...emptyPaymentDetails,
                                            ...getContactInfo(claimType === 'Invoice' ? item.debtor : item.creditor),
                                            walletAddress: claimType === 'Invoice' ? item.debtor : item.creditor,
                                            token: item.tokenInfo.token,
                                            amount: weiToDisplayAmt({
                                                amountWei: isClaim(item) ? item.claimAmount : item.paidAmount,
                                                token: item.tokenInfo.token,
                                            }).toString(),
                                            tags: item.tags,
                                        }),
                                    );

                                onAdd(paymentDetailsToAdd);
                                onClose();
                                selectOptions.resetSelection();
                            }}
                        >
                            {`Add${selectedHashes.length == 0 ? '' : ` (${selectedHashes.length})`}`}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
