import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import { EthAddress } from '../../../data-lib/ethereum';
import { endpoints } from '../../../tools/common';
import { TOrNull } from '../../../tools/types';

export const FIELDS = [
    { name: 'companyName', label: 'Company Name', placeholder: 'Enter your company name' },
    { name: 'addressLine1', label: 'Address Line 1', placeholder: "Enter your company's address", optional: true },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: "Enter your company's office or unit #", optional: true },
    { name: 'city', label: 'City', placeholder: "Enter your company's city", optional: true },
    { name: 'state', label: 'State or Region', placeholder: "Enter your company's state or county", optional: true },
    { name: 'postalCode', label: 'Postal Code', placeholder: "Enter your company's postal code", optional: true },
    { name: 'country', label: 'Country', placeholder: "Enter your company's country", optional: true },
    { name: 'additionalLine1', label: 'Additional line 1', placeholder: "Enter your comapny's EIN or tax code", optional: true },
    { name: 'additionalLine2', label: 'Additional line 2', placeholder: 'Additional Information', optional: true },
    { name: 'additionalLine3', label: 'Additional line 3', placeholder: 'Additional Information', optional: true },
] as const;

export const emptyFields = FIELDS.reduce<Record<typeof FIELDS[number]['name'], ''>>((acc, { name }) => ({ ...acc, [name]: '' }), {} as any);

export type Fields = Record<keyof typeof emptyFields, string>;
export type CompanyDetails = TOrNull<Omit<Fields, 'additionalField1' | 'additionalField2' | 'additionalField3'>> & {
    additionalLines: string[];
};

/** @returns the highest # used additional lines for a given Fields object */
export const countAddedLines = (fields: Fields) =>
    Object.keys(fields).reduce<number>((acc, key) => (key.includes('additionalLine') && +key.slice(-1) > acc ? +key.slice(-1) : acc), 0);

export const fieldsToInvoicePreviewURL = (fields: Fields) =>
    Object.entries(fields).reduce<string>((acc, [key, value]) => {
        if (value != '') {
            return `${acc}&${key}=${value}`;
        } else {
            return acc;
        }
    }, `${endpoints.settingsApi}/invoice-preview?`);

export const mapFieldsToCompanyDetails = (values: Fields) => {
    let details = Object.entries(values).reduce<CompanyDetails>(
        (acc, [key, value]) => {
            if (key.includes('additionalLine')) {
                const index = +key.slice(-1) - 1;
                acc.additionalLines[index] = value;
                return acc;
            } else if (value !== '') {
                return { ...acc, [key]: value };
            } else {
                return acc;
            }
        },
        { additionalLines: _.fill(new Array(3), '') } as CompanyDetails,
    );
    const lastNonEmptyAdditionalLineIndex = details.additionalLines?.reduce<number>(
        (lastIndex, line, index) => (line != '' ? index : lastIndex),
        0,
    );

    if (lastNonEmptyAdditionalLineIndex == 0 && !details.additionalLines[0]) details.additionalLines = [];
    else details.additionalLines = details.additionalLines.slice(0, lastNonEmptyAdditionalLineIndex + 1);

    return details;
};

export const mapCompanyDetailsToFields = (values: CompanyDetails) =>
    Object.entries(values).reduce<Fields>((acc, [key, value]) => {
        if (value instanceof Array) {
            value.forEach((val, i) => (acc[`additionalLine${(i + 1) as 1 | 2 | 3}`] = val));
            return acc;
        } else {
            return { ...acc, [key]: value ?? '' };
        }
    }, {} as Fields);
