{
    "id": "/blockParamSchema",
    "oneOf": [
        {
            "type": "number"
        },
        {
            "enum": ["latest", "earliest", "pending"]
        }
    ]
}
