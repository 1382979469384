import axios, { AxiosError } from 'axios';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useWeb3 } from './useWeb3';

export type UnderwriterData = {
    spectralScore: string;
    spectralScore_timestamp: number;
    rocifiScore: string;
    rocifiScore_timestamp: number;
};

export type FactoringApprovalPathParameters = {
    wallet: string;
    chainId: string;
    poolAddress: string;
    claimId: string;
};

export type FactoringApprovalResponse =
    | {
          txHash: string;
      }
    | { errors: string[] };

export const useUnderwriter = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork } = useWeb3();

    const getUnderwriterScore = async (wallet_address: string): Promise<UnderwriterData> => {
        try {
            const response = await axios.get(`${endpoints.underwriterApi}/score/${wallet_address}`);
            return JSON.parse(response.data);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const getFactoringApprovalTx = async (pathParameters: FactoringApprovalPathParameters): Promise<FactoringApprovalResponse> => {
        try {
            const { wallet, chainId, poolAddress, claimId } = pathParameters;
            const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

            const response = await axios.get(
                `${endpoints.underwriterApi}/underwrite/${wallet}/chain/${chainId}/pool/${poolAddress}/claim/${claimId}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );
            const data: FactoringApprovalResponse = response.data;
            return data;
        } catch (error) {
            console.error(error);
            const axiosError = error as AxiosError;
            if (axiosError && axiosError.response) {
                const errorResponse: FactoringApprovalResponse = axiosError.response.data;
                return errorResponse;
            }
            throw error;
        }
    };

    return {
        getUnderwriterScore,
        getFactoringApprovalTx,
    };
};
