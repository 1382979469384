import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Checkbox,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    TagCloseButton,
    Text,
    useDisclosure,
    VStack,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { Wallet } from 'phosphor-react';
import React, { useState } from 'react';
import { addressEquality } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS } from '../../../data-lib/networks';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { ReportingBadge } from '../../../pages/reporting/reporting-explorer';
import { ContactNameOrAddress } from '../../base/address-label';
import { buttonSizeProps, OrangeButton } from '../../inputs/buttons';
import { LedgerWizardState, SelectNameState, WalletChainSelection } from './ledger-wizard-modal';
import { IconBox, LedgerWizardStep } from './progress-steps';

interface AddWalletsCardProps {
    setWizardState: (state: LedgerWizardState) => void;
    wizardState: LedgerWizardState;
    selectableWallets: Set<string>;
    onSelectedWallets: (selection: WalletChainSelection) => void;
}

export const AddWalletsChainsCard: React.FC<AddWalletsCardProps> = ({
    setWizardState,
    wizardState,
    onSelectedWallets,
    selectableWallets,
}) => {
    const { connectedNetwork } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const wrapRef = React.useRef<HTMLDivElement>(null);

    const [walletChainSelection, setWalletChainSelection] = useState<WalletChainSelection>({
        addresses: new Set([actingWallet.toLowerCase()]),
        chainIds: [NETWORKS[connectedNetwork].chainId],
    });

    const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

    React.useEffect(() => {
        if (wrapRef.current !== null) {
            wrapRef.current.childNodes[0].addEventListener('click', onOpen);
            return () => wrapRef.current?.childNodes[0].removeEventListener('click', onOpen);
        }
    }, [wrapRef.current]);

    const removeChain = (chainId: ChainId) => {
        setWalletChainSelection(prev => ({ ...prev, chainIds: prev.chainIds.filter(id => id !== chainId) }));
    };

    const addChain = (chainId: ChainId) => {
        if (!walletChainSelection.chainIds.includes(chainId)) {
            setWalletChainSelection(prev => ({ ...prev, chainIds: [...prev.chainIds, chainId] }));
        }
    };

    const selectedChainIds = walletChainSelection.chainIds;

    return (
        <Box maxW="450px" mx="auto" mt="8" textAlign="left" shadow="xl" p="5" borderRadius="md">
            <IconBox icon={Wallet} boxShadow="none" w="fit-content" mb="6" />
            <Heading as="h2" size="md" mb="2">
                Add Wallets
            </Heading>
            <Text mb="6">Select the networks you want to add to the report, then select the associated wallets.</Text>

            <Menu isOpen={isOpen} onClose={onClose}>
                <Box position="relative">
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        variant="outline"
                        borderColor="#E2E8F0"
                        w="100%"
                        textAlign="left"
                        onClick={e => {
                            if (e.target === e.currentTarget || (e.target as HTMLElement).closest('.chakra-button__icon')) {
                                onToggle();
                            }
                        }}
                        p={2}
                        minH="40px"
                        h="auto"
                        _hover={{}}
                        _active={{}}
                    >
                        <Box visibility="hidden">
                            <Wrap spacing={2}>
                                {selectedChainIds.map(chainId => (
                                    <WrapItem key={chainId}>
                                        <Tag size="lg">
                                            {NETWORKS[chainId].label}
                                            <TagCloseButton />
                                        </Tag>
                                    </WrapItem>
                                ))}
                            </Wrap>
                        </Box>
                    </MenuButton>
                    <Box position="absolute" top={0} left={0}>
                        <Wrap spacing={2} p={2} ref={wrapRef}>
                            {selectedChainIds.map(chainId => (
                                <WrapItem key={chainId}>
                                    <Tag
                                        size="lg"
                                        variant="outline"
                                        color="gray.800"
                                        borderColor="gray.200"
                                        borderWidth="1px"
                                        colorScheme="none"
                                    >
                                        {NETWORKS[chainId].label}
                                        <TagCloseButton
                                            onClick={e => {
                                                e.stopPropagation();
                                                removeChain(chainId);
                                            }}
                                        />
                                    </Tag>
                                </WrapItem>
                            ))}
                        </Wrap>
                    </Box>
                </Box>
                <MenuList borderRadius="4" overflowY="auto" maxH="400px">
                    {SUPPORTED_NETWORKS.filter(chainId => !selectedChainIds.includes(chainId)).map(chainId => (
                        <MenuItem
                            key={chainId}
                            onClick={() => {
                                addChain(chainId);
                                onClose();
                            }}
                        >
                            {NETWORKS[chainId].label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <VStack align="stretch" spacing={1} mb={6} my="3">
                {[...selectableWallets]
                    .map(x => x.toLowerCase())
                    .map(wallet => (
                        <HStack key={wallet} spacing={2} flex={1}>
                            <Checkbox
                                isChecked={walletChainSelection.addresses.has(wallet)}
                                onChange={() => {
                                    const isChecked = walletChainSelection.addresses.has(wallet);
                                    setWalletChainSelection(prev => {
                                        const prevAddresses = new Set([...prev.addresses]);
                                        isChecked ? prevAddresses.delete(wallet) : prevAddresses.add(wallet);
                                        return { ...prev, addresses: prevAddresses };
                                    });
                                }}
                            ></Checkbox>
                            <ContactNameOrAddress chainId={NETWORKS[connectedNetwork].chainId} excludeYouBadge removeParenthesis>
                                {wallet}
                            </ContactNameOrAddress>
                            {addressEquality(wallet, actingWallet) && (
                                <ReportingBadge backgroundColor="#ECFDF3" color="#067647" borderColor="#ABEFC6">
                                    Connected
                                </ReportingBadge>
                            )}
                        </HStack>
                    ))}
            </VStack>

            <HStack justify="space-between" w="100%">
                <Button
                    variant="outline"
                    flex={1}
                    {...buttonSizeProps}
                    onClick={() =>
                        setWizardState({
                            ...wizardState,
                            step: LedgerWizardStep.SelectName,
                        } as SelectNameState)
                    }
                >
                    Cancel
                </Button>
                <OrangeButton
                    flex={1}
                    isDisabled={walletChainSelection.addresses.size === 0 || selectedChainIds.length === 0}
                    onClick={() => onSelectedWallets(walletChainSelection)}
                >
                    Continue
                </OrangeButton>
            </HStack>
        </Box>
    );
};
