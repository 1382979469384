import React from 'react';
import { Box, Text, Flex, HStack, VStack } from '@chakra-ui/react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, LineChart, TooltipProps } from 'recharts';
import { TokenDto } from '../../data-lib/networks';
import { WithSkeleton } from '../base/skeleton';
import { subDays, eachDayOfInterval } from 'date-fns';
import { getTickFormatter, TimeRange, TimeRangeSelector } from './common';

export interface CashViewTextProps {
    title: string;
    subtitle: string;
    tokenSybol?: string;
    iconColor: string;
}

export const CashViewText: React.FC<CashViewTextProps> = ({ title, subtitle, tokenSybol, iconColor }) => (
    <Flex direction="column">
        <HStack spacing="2" mb="1">
            <Box w="8px" h="8px" borderRadius="full" bg={iconColor} />
            <Text color="gray.600" fontSize={'14px'} fontWeight="500">
                {title}
            </Text>
        </HStack>
        <HStack spacing="2">
            <Text color="gray.800" fontSize="3xl" fontWeight="bold">
                {subtitle}
            </Text>
            {tokenSybol && (
                <Text color="gray.800" fontWeight={'500'} fontSize={'16px'}>
                    {tokenSybol}
                </Text>
            )}
        </HStack>
    </Flex>
);

type CashChartSeriesItem = {
    key: string;
    displayName: string;
    color: string;
    strokeDasharray?: string;
};

const CASH_CHART_SERIES: CashChartSeriesItem[] = [
    { key: 'capitalAccount', displayName: 'Capital Account', color: '#627188' },
    { key: 'fundBalance', displayName: 'Fund Balance', color: '#DB4F05' },
    { key: 'deployedCapital', displayName: 'Deployed Capital', color: '#F7B27A', strokeDasharray: '6 6' },
];

interface FactoringCashChartProps {
    timeRange: TimeRange;
    setTimeRange: (range: TimeRange) => void;
    underlyingToken: TokenDto;
    chartData: CashDataPoint[];
    latestCashIndicators: CashDataPoint | null;
}

export type CashDataPoint = { date: Date; fundBalance: number; capitalAccount: number; deployedCapital: number };

interface CustomTooltipProps extends TooltipProps<number, string> {
    tokenSymbol: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, tokenSymbol }) => {
    if (!active || !payload || payload.length === 0) return null;

    return (
        <Box bg="white" p={3} border="1px solid #E2E8F0" borderRadius="md">
            <Text color="#718096" mb={2}>
                {new Date(label).toLocaleDateString()}
            </Text>
            <VStack align="start" spacing={1}>
                {payload.map((entry, index) => {
                    const series = CASH_CHART_SERIES.find(s => s.key === entry.dataKey);
                    if (!series) return null;

                    return (
                        <Text key={index} color="#2e2e2d">
                            <Box as="span" display="inline-block" w="10px" h="10px" borderRadius="full" bg={series.color} mr={2} />
                            {series.displayName}: {Number(entry.value).toLocaleString()} {tokenSymbol}
                        </Text>
                    );
                })}
            </VStack>
        </Box>
    );
};

const getChartCashDataForTimeRange = (cashData: CashDataPoint[], timeRange: TimeRange) => {
    const today = new Date();
    let startDate: Date;
    let interval: number;

    switch (timeRange) {
        case '1W':
            startDate = subDays(today, 7);
            interval = 1;
            break;
        case '1M':
            startDate = subDays(today, 30);
            interval = 2;
            break;
        case '1Y':
            startDate = subDays(today, 365);
            interval = 7;
            break;
    }

    const dateRange = eachDayOfInterval({ start: startDate, end: today });
    let lastKnownData: CashDataPoint = { date: new Date(0), fundBalance: 0, capitalAccount: 0, deployedCapital: 0 };
    return dateRange
        .reverse()
        .filter((_, index) => index % interval === 0)
        .reverse()
        .map(date => {
            const matchingDataPoint = cashData.find(d => d.date.toDateString() === date.toDateString());
            if (matchingDataPoint) {
                lastKnownData = matchingDataPoint;
            }
            return {
                ...lastKnownData,
                date: date,
            };
        });
};

const getXAxisTicks = (adjustedCashData: CashDataPoint[], timeRange: TimeRange) => {
    const dataLength = adjustedCashData.length;
    const tickCount = timeRange === '1Y' ? 12 : 6;
    const step = Math.floor(dataLength / tickCount);
    return adjustedCashData.filter((_, index) => index % step === 0).map(d => d.date.getTime());
};

export const CashViewChart: React.FC<FactoringCashChartProps> = ({
    chartData,
    latestCashIndicators,
    underlyingToken,
    timeRange,
    setTimeRange,
}) => {
    const chartCashDataFromTimeRange = React.useMemo(() => getChartCashDataForTimeRange(chartData, timeRange), [chartData, timeRange]);

    return (
        <Box p="2">
            <Flex direction="row" justifyContent="space-between" pb="8">
                <Flex direction="row" wrap="wrap" gap={24}>
                    {latestCashIndicators == null ? (
                        <WithSkeleton isLoading={true} fixedWidth="500px" height="50px">
                            <Text>Can't see me</Text>
                        </WithSkeleton>
                    ) : (
                        <>
                            <CashViewText
                                title="Fund Balance"
                                subtitle={`${latestCashIndicators.fundBalance.toLocaleString('en-US', {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                })} ${underlyingToken.symbol}`}
                                iconColor="#DB4F05"
                            />
                            <CashViewText
                                title="Deployed Capital"
                                subtitle={`${latestCashIndicators.deployedCapital.toLocaleString('en-US', {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                })} ${underlyingToken.symbol}`}
                                iconColor="#F7B27A"
                            />
                            <CashViewText
                                title="Capital Account"
                                subtitle={`${latestCashIndicators.capitalAccount.toLocaleString('en-US', {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                })} ${underlyingToken.symbol}`}
                                iconColor="#627188"
                            />
                        </>
                    )}
                </Flex>
                <TimeRangeSelector timeRange={timeRange} setTimeRange={setTimeRange} />
            </Flex>
            <Box h="300px">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={chartCashDataFromTimeRange} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <XAxis
                            dataKey="date"
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={getTickFormatter(timeRange)}
                            ticks={getXAxisTicks(chartCashDataFromTimeRange, timeRange)}
                        />
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={value => `${Number(value).toLocaleString()} ${underlyingToken.symbol}`}
                        />
                        <CartesianGrid vertical={false} stroke="#E2E8F0" />
                        <Tooltip content={<CustomTooltip tokenSymbol={underlyingToken.symbol} />} />
                        {CASH_CHART_SERIES.map(series => (
                            <Line
                                key={series.key}
                                type="monotone"
                                dataKey={series.key}
                                name={series.key}
                                stroke={series.color}
                                dot={false}
                                strokeWidth={3}
                                strokeDasharray={series.strokeDasharray}
                            />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
        </Box>
    );
};
