{
    "id": "/signedOrderSchema",
    "allOf": [
        { "$ref": "/orderSchema" },
        {
            "properties": {
                "signature": { "$ref": "/hexSchema" }
            },
            "required": ["signature"]
        }
    ]
}
