import { ArrowForwardIcon, CheckIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Badge,
    Box,
    Button,
    ButtonGroup,
    Collapse,
    Divider,
    HStack,
    Link,
    List,
    ListIcon,
    ListItem,
    Stack,
    Text,
} from '@chakra-ui/react';
import { DiscordLogo } from 'phosphor-react';
import React from 'react';
import { TextButton } from '../../components/inputs/buttons';
import { BULLA_BANKER_MODULE_VERSION, getGnosisSafeURL, isModuleOutdated } from '../../data-lib/gnosis-tools/gnosis';
import { useWeb3 } from '../../hooks/useWeb3';
import { useGnosisSafe } from '../../state/gnosis-state';
import { Require } from '../../tools/types';
import { ConfirmationButton, SlideProps } from './onboard';

export const GnosisModuleInstall = ({ completeStep, goBack }: Require<SlideProps, 'completeStep' | 'goBack'>) => {
    const { connectedNetworkConfig } = useWeb3();
    const { enableModule, error: moduleInstallError, loading, safeInfo, inSafeApp } = useGnosisSafe();
    const [installationSuccess, setInstallationSuccess] = React.useState(false);

    const installModule = async () => enableModule().then(setInstallationSuccess);

    const moduleOutOfDate = !!(safeInfo?.module?.moduleVersion && isModuleOutdated(safeInfo.module.moduleVersion));
    const moduleReady = safeInfo?.module?.moduleEnabled && !isModuleOutdated(safeInfo.module.moduleVersion) ? true : false;
    const deploymentRequired = safeInfo && (!safeInfo.module?.moduleAddress || isModuleOutdated(safeInfo.module.moduleVersion));

    return (
        <Stack align="center">
            <Box h="2" />
            <Text textStyle="listTitle" whiteSpace={'nowrap'}>
                Install BullaBanker-Gnosis Module (v{BULLA_BANKER_MODULE_VERSION})
            </Text>

            {moduleOutOfDate ? (
                <Alert status="info" mb="4">
                    <AlertIcon />
                    <Text>
                        The BullaBanker Module installed on your Gnosis Safe (v{safeInfo.module?.moduleVersion}) is out of date. Please
                        reinstall.
                    </Text>
                </Alert>
            ) : (
                <Text align="center">
                    In order to use BullaBanker outside of the{' '}
                    <Link
                        href={getGnosisSafeURL(connectedNetworkConfig, safeInfo!.safeAddress, `apps?appUrl=${window.location.href}`)}
                        isExternal
                    >
                        Safe-Apps UI
                        <ExternalLinkIcon ml="1" mt="-4px" />
                    </Link>
                    , you must install and enable our custom module to your safe.
                </Text>
            )}
            <Box h="2" />
            <Stack justify="center" align="center" px="8">
                <Text textStyle="listTitle" alignSelf={'flex-start'}>
                    Features:
                </Text>
                <List spacing={3} pb={6}>
                    <ListItem>
                        <Text fontWeight={600} alignSelf={'center'}>
                            Permissionless functionality
                        </Text>
                        <Divider />
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        <Badge colorScheme={'blue'}>NEW</Badge> Batch create payments
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Create invoices and payments
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Add and remove tags
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Rescind outbound claims.
                    </ListItem>
                    <ListItem>
                        <ListIcon as={CheckIcon} color="green.500" />
                        Reject inbound claims.
                    </ListItem>
                    <ListItem>
                        <ListIcon color="red.500">
                            <CloseIcon />
                        </ListIcon>
                        Pay claims. (Standard Multi-sig functionality)
                    </ListItem>
                </List>
                <ButtonGroup>
                    {!inSafeApp ? (
                        <>
                            <Button onClick={goBack} h="12" variant={'outline'} isDisabled={loading || moduleReady}>
                                Back
                            </Button>
                            <ConfirmationButton
                                onClick={installModule}
                                falseText={'Install'}
                                trueText={'Installed'}
                                validation={moduleReady}
                                isLoading={loading}
                                isDisabled={moduleReady}
                            />
                        </>
                    ) : (
                        <Link href={'https://banker.bulla.network'} isExternal>
                            <Button px="6" py="6">
                                Continue Installation on BullaBanker
                                <ExternalLinkIcon ml="1" mt="-4px" />
                            </Button>
                        </Link>
                    )}
                </ButtonGroup>
            </Stack>
            <Box h="4" />
            {loading ? (
                <>
                    <Text fontSize="xs" color={moduleInstallError ? 'red' : 'gray.600'} textAlign={'center'}>
                        {moduleInstallError
                            ? `Installation failed. See Gnosis-Safe for more info.`
                            : `Deploying BullaBanker module and enabling it on your Gnosis Safe. \n Do not close this window.`}
                    </Text>
                    {moduleInstallError && (
                        <Link href="https://discord.gg/UbhMJfCs5j" isExternal fontSize={'14px'} fontWeight={600}>
                            <HStack>
                                <DiscordLogo size={18} weight="bold" />
                                <Text>
                                    Get Support <ExternalLinkIcon />
                                </Text>
                            </HStack>
                        </Link>
                    )}
                </>
            ) : (
                !installationSuccess &&
                !inSafeApp && (
                    <HStack spacing={1}>
                        <Text>Want to check our</Text>
                        <Link
                            href={`https://github.com/bulla-network/bulla-contracts/blob/master/contracts/BullaBankerModule.sol`}
                            isExternal
                            fontSize={'14px'}
                            textDecoration={'underline'}
                        >
                            module code?
                            <ExternalLinkIcon ml="4px" mt="-4px" />
                        </Link>
                    </HStack>
                )
            )}
            <Box h="2" />
            <Collapse in={moduleReady || installationSuccess}>
                <TextButton rightIcon={<ArrowForwardIcon />} onClick={completeStep}>
                    Enter App
                </TextButton>
            </Collapse>
        </Stack>
    );
};
