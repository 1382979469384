import { BigNumber } from 'ethers';
import React, { useEffect, useMemo, useState } from 'react';
import { LoadingScreen } from '../components/layout/page-layout';
import { ImportedExternalTransactionInfo } from '../data-lib/data-model';
import { BullaItemEvent, getUserData } from '../data-lib/data-transforms';
import {
    ExternalTransaction,
    ExternalTransactionDTO,
    externalTxsToExternalTransactionDTO,
    filterExternalTransactions,
    Transfer,
} from '../data-lib/dto/external-transactions-dto';
import { addressEquality } from '../data-lib/ethereum';
import { allNetworks, ChainId, NetworkConfig, NETWORKS, TokenInfo } from '../data-lib/networks';
import { useExternalTransactionsApi } from '../hooks/useExternalTransactionsApi';
import { useFetchUnassignedOffchainInvoices } from '../hooks/useOffchainInvoices';
import { useSelectedNetworks } from '../hooks/useSelectedNetworks';
import { TokenInfoResolver, useTokenRepo } from '../hooks/useTokenRepo';
import { useActingWalletAddress } from '../hooks/useWalletAddress';
import { useWeb3 } from '../hooks/useWeb3';
import { isValidGuid, TxHashBitmap, userIsViewingPage } from '../tools/common';
import { Replace } from '../tools/types';
import { UserData } from '../tools/userData';
import { useGnosisSafe } from './gnosis-state';
import { setupEventSynchronization } from './listener';
import { getUserEventLogs } from './state-helpers';

export type ProviderState = 'uninitialized' | 'loading' | 'finished' | { errorMessage: string };

export const hasFailed = (state: UserDataState): state is { kind: 'error'; errorMessage: string } => state.kind == 'error';

export const isUserDataReady = (userDataState: UserDataState, disregardFetchedRequirement?: true): userDataState is UserDataReadyState =>
    userDataState.kind == 'fetched' &&
    (!!disregardFetchedRequirement || userDataState.userData.fetched) &&
    (!!disregardFetchedRequirement || userDataState.userData.importFetched);

export const atLeastOneNetworkReady = (userDataByChain: 'uninitialized' | Record<number, UserDataState>) =>
    userDataByChain !== 'uninitialized' && Object.values(userDataByChain).some(x => isUserDataReady(x));

export const allNetworksReady = (
    userDataByChain: 'uninitialized' | Record<number, UserDataState>,
    specificNetworks?: ChainId[],
): userDataByChain is Record<number, UserDataReadyState | UserDataNotSelectedState> => {
    if (userDataByChain === 'uninitialized') return false;

    if (specificNetworks) {
        return specificNetworks.every(chainId => {
            const state = userDataByChain[chainId];
            return isUserDataReady(state);
        });
    }

    return Object.values(userDataByChain).every(state => state.kind === 'not-selected' || isUserDataReady(state));
};

export type UserDataReadyState = { kind: 'fetched'; userData: UserData };
export type UserDataNotSelectedState = {
    kind: 'not-selected';
    userData?: UserData;
};

export type UserDataState = { kind: 'loading' } | UserDataNotSelectedState | UserDataReadyState | { kind: 'error'; errorMessage: string };

export type AppState = {
    userDataByChain: 'uninitialized' | Record<number, UserDataState>;
    readyToTransact: boolean;
    addEventsToAppState: (events: BullaItemEvent[]) => void;
};

export const AppContext = React.createContext<AppState>({
    userDataByChain: 'uninitialized',
    readyToTransact: false,
    addEventsToAppState: _ => {},
});

export const AppProvider = ({ children: app }: { children: React.ReactNode }) => {
    const [bullaItemEventsByChain, setBullaItemEventsByChain] = useState<Record<number, BullaItemEvent[]>>({});
    const { connectedNetworkConfig: currentNetworkConfig, provider, providersByChainId } = useWeb3();
    const [userDataByChain, setUserDataByChain] = useState<'uninitialized' | Record<number, UserDataState>>('uninitialized');
    const { safeInfo, isSafeReady, connectedSafeAddress } = useGnosisSafe();
    const { selectedNetworks, addedNetworks, removedNetworks } = useSelectedNetworks();
    const queryAddress = useActingWalletAddress();
    const [connectedNetworkFetched, setConnectedNetworkFetched] = useState(false);
    const [addEventsToAppState, setAddEventsToAppState] = useState<(events: BullaItemEvent[]) => void>(_ => {});
    const { fetchExternalTransactions } = useExternalTransactionsApi();
    const { resolveTokenInfo, getTokenByChainIdAndAddress } = useTokenRepo();
    const fetchUnassignedInvoices = useFetchUnassignedOffchainInvoices();

    const currentChainEventLogs: BullaItemEvent[] =
        (bullaItemEventsByChain[currentNetworkConfig.chainId] as BullaItemEvent[] | undefined) ?? [];

    const readyToTransact =
        userDataByChain !== 'uninitialized' &&
        userDataByChain[currentNetworkConfig.chainId].kind !== 'loading' &&
        !hasFailed(userDataByChain[currentNetworkConfig.chainId]) &&
        !!queryAddress &&
        (connectedSafeAddress && !isSafeReady ? false : true);

    const fetchItemEventsForChain = async (networkConfig: NetworkConfig) => {
        const eventLogs = await getUserEventLogs(providersByChainId[networkConfig.chainId], networkConfig, queryAddress);
        setBullaItemEventsByChain(eventsByChain => ({ ...eventsByChain, [networkConfig.chainId]: eventLogs }));

        return eventLogs;
    };

    const buildUserData = async (networkConfig: NetworkConfig, eventLogs: BullaItemEvent[]) => {
        const chainId = networkConfig.chainId;
        const userData: Omit<UserData, 'refetch' | 'refetchExternalTransactions' | 'importedExternalTxs' | 'nonImportedExternalTxs'> =
            await getUserData(resolveTokenInfo, chainId, queryAddress, eventLogs, safeInfo?.owners);

        const potentialOffchainInvoicePayments = userData.instantPayments
            .filter(x => addressEquality(x.debtor, queryAddress) && isValidGuid(x.description))
            .map(x => x.description);

        potentialOffchainInvoicePayments.length !== 0 && fetchUnassignedInvoices(potentialOffchainInvoicePayments, chainId);

        setUserDataByChain(previousGlobalUserDataState => {
            if (previousGlobalUserDataState === 'uninitialized') return 'uninitialized';
            const newUserData: UserData = {
                ...userData,
                refetch: async () => {
                    await fetchItemEventsForChain(networkConfig);
                    return;
                },
                refetchExternalTransactions: async (_chainId?: ChainId) => {
                    const targetChainId = _chainId ?? chainId;
                    await fetchExternalTxs([targetChainId], userData.bullaTxHashBitmap);
                    return;
                },
                importedExternalTxs: (() => {
                    const previousUserDataState = previousGlobalUserDataState[networkConfig.chainId];
                    return isUserDataReady(previousUserDataState, true) ? previousUserDataState.userData.importedExternalTxs : [];
                })(),
                nonImportedExternalTxs: (() => {
                    const previousUserDataState = previousGlobalUserDataState[networkConfig.chainId];
                    return isUserDataReady(previousUserDataState, true) ? previousUserDataState.userData.nonImportedExternalTxs : [];
                })(),
                bullaItemMetadataById: (() => {
                    const previousUserDataState = previousGlobalUserDataState[networkConfig.chainId];
                    return isUserDataReady(previousUserDataState, true) ? previousUserDataState.userData.bullaItemMetadataById : {};
                })(),
                fetched: true,
            };
            return {
                ...previousGlobalUserDataState,
                [networkConfig.chainId]: { kind: 'fetched', userData: newUserData },
            };
        });
        await fetchExternalTxs([chainId], userData.bullaTxHashBitmap);
    };

    const fetchExternalTxs = (networks: ChainId[], bullaTxHashBitmap: TxHashBitmap) =>
        Promise.all(
            networks.map(async chainId => {
                const networkConfig = NETWORKS[chainId];
                try {
                    const { transactions: externalTxs, bullaItemMetadata: bullaItemMetadataById } = await fetchExternalTransactions(
                        chainId,
                    );
                    const { externalTransactions: filteredTxs, bullaRelated } = filterExternalTransactions(externalTxs, bullaTxHashBitmap);
                    const txs = await toImportedExternalTransactions(filteredTxs, chainId, resolveTokenInfo);
                    const externalTxsDTO: ExternalTransactionDTO[] = externalTxsToExternalTransactionDTO(
                        [...filteredTxs, ...bullaRelated],
                        queryAddress,
                        getTokenByChainIdAndAddress,
                    );
                    setUserDataByChain(previousUserData =>
                        previousUserData === 'uninitialized'
                            ? 'uninitialized'
                            : {
                                  ...previousUserData,
                                  [networkConfig.chainId]: (() => {
                                      const previousUserDataState = previousUserData[networkConfig.chainId];
                                      return isUserDataReady(previousUserDataState, true)
                                          ? {
                                                ...previousUserDataState,
                                                userData: {
                                                    ...previousUserDataState.userData,
                                                    importedExternalTxs: txs,
                                                    nonImportedExternalTxs: externalTxsDTO,
                                                    bullaItemMetadataById,
                                                    importFetched: true,
                                                },
                                            }
                                          : previousUserDataState;
                                  })(),
                              },
                    );
                } catch (e: any) {
                    console.error(e);
                    setUserDataByChain(previousUserData =>
                        previousUserData === 'uninitialized'
                            ? 'uninitialized'
                            : {
                                  ...previousUserData,
                                  [networkConfig.chainId]: (() => {
                                      const previousUserDataState = previousUserData[networkConfig.chainId];
                                      return isUserDataReady(previousUserDataState, true)
                                          ? {
                                                ...previousUserDataState,
                                                userData: { ...previousUserDataState.userData, importFetched: true },
                                            }
                                          : previousUserDataState;
                                  })(),
                              },
                    );
                }
            }),
        );

    const fetchItemEvents = (networks: ChainId[]) =>
        Promise.all(
            networks.map(chainId => {
                const networkConfig = NETWORKS[chainId];
                return fetchItemEventsForChain(networkConfig)
                    .then(events =>
                        chainId !== currentNetworkConfig.chainId
                            ? buildUserData(NETWORKS[chainId], events)
                            : (async () => setConnectedNetworkFetched(true))(),
                    )
                    .catch(e => {
                        console.error(e);
                        setUserDataByChain(previousUserData =>
                            previousUserData === 'uninitialized'
                                ? 'uninitialized'
                                : {
                                      ...previousUserData,
                                      [networkConfig.chainId]: { kind: 'error', errorMessage: e.message },
                                  },
                        );
                    });
            }),
        );

    useEffect(() => {
        if (connectedNetworkFetched) buildUserData(currentNetworkConfig, currentChainEventLogs);
    }, [currentChainEventLogs.length, connectedNetworkFetched]);

    useEffect(() => {
        setUserDataByChain(
            allNetworks
                .map(x => x.chainId)
                .reduce<Record<number, UserDataState>>(
                    (acc, chainId) => ({ ...acc, [chainId]: { kind: selectedNetworks.includes(chainId) ? 'loading' : 'not-selected' } }),
                    {},
                ),
        );

        fetchItemEvents(selectedNetworks);
    }, [queryAddress]);

    useEffect(() => {
        if (addedNetworks.length === 0) return;

        setUserDataByChain(prev => {
            const [newState, networksToFetch]: [Record<number, UserDataState> | 'uninitialized', ChainId[]] =
                prev === 'uninitialized'
                    ? ['uninitialized', addedNetworks]
                    : (() => {
                          const [newGlobalUserDataState, networksToFetch] = addedNetworks.reduce<
                              [Record<number, UserDataState>, ChainId[]]
                          >(
                              ([acc, networksToFetch], chainId) => {
                                  const previousUserDataState = acc[chainId];

                                  const newUserDataState: UserDataState =
                                      (previousUserDataState.kind == 'not-selected' || previousUserDataState.kind == 'fetched') &&
                                      !!previousUserDataState.userData &&
                                      previousUserDataState.userData.fetched &&
                                      previousUserDataState.userData.importFetched
                                          ? { kind: 'fetched', userData: previousUserDataState.userData }
                                          : { kind: 'loading' };

                                  return [
                                      {
                                          ...acc,
                                          [chainId]: newUserDataState,
                                      },
                                      newUserDataState.kind == 'loading' ? [...networksToFetch, chainId] : networksToFetch,
                                  ];
                              },
                              [prev, []],
                          );
                          return [newGlobalUserDataState, networksToFetch];
                      })();
            networksToFetch.length !== 0 && fetchItemEvents(networksToFetch);
            return newState;
        });
    }, [selectedNetworks.length]);

    useEffect(() => {
        if (removedNetworks.length === 0) return;

        if (removedNetworks.includes(currentNetworkConfig.chainId)) setConnectedNetworkFetched(false);

        setUserDataByChain(prev =>
            prev === 'uninitialized'
                ? 'uninitialized'
                : removedNetworks.reduce<Record<number, UserDataState>>((acc, chainId) => {
                      const userDataState = acc[chainId];
                      return {
                          ...acc,
                          [chainId]: {
                              kind: 'not-selected',
                              userData: userDataState.kind == 'fetched' ? userDataState.userData : undefined,
                          },
                      };
                  }, prev),
        );
    }, [selectedNetworks.length]);

    useEffect(() => {
        // if a polling config is required for this network, skip setting up event listeners

        const { updateEvents } = setupEventSynchronization(
            provider,
            currentNetworkConfig,
            queryAddress,
            currentChainEventLogs,
            previousLogs =>
                setBullaItemEventsByChain(eventsByChain => ({
                    ...eventsByChain,
                    [currentNetworkConfig.chainId]:
                        typeof previousLogs === 'function' ? previousLogs(eventsByChain[currentNetworkConfig.chainId]) : previousLogs,
                })),
            !currentNetworkConfig.connections.pollNetwork,
        );

        setAddEventsToAppState(() => updateEvents);

        const networkPoll =
            !!currentNetworkConfig.connections.pollNetwork &&
            setInterval(() => {
                if (userIsViewingPage())
                    getUserEventLogs(provider, currentNetworkConfig, queryAddress).then(eventLogs =>
                        setBullaItemEventsByChain(eventsByChain => ({ ...eventsByChain, [currentNetworkConfig.chainId]: eventLogs })),
                    );
            }, 4000);

        return () => {
            if (networkPoll) clearInterval(networkPoll);
            provider.removeAllListeners();
        };
    }, [currentNetworkConfig, queryAddress]);

    const context = useMemo(() => {
        return { userDataByChain, readyToTransact, addEventsToAppState };
    }, [userDataByChain, readyToTransact, addEventsToAppState]);

    return (
        <AppContext.Provider value={context}>
            <LoadingScreen errorMessage={undefined} />
            {app}
        </AppContext.Provider>
    );
};

export const useAppState = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) throw new Error('useAppState must me used within the AppState provider');
    return context;
};

async function toImportedExternalTransactions(
    txs: ExternalTransaction[],
    chainId: ChainId,
    resolveTokenInfo: TokenInfoResolver,
): Promise<ImportedExternalTransactionInfo[]> {
    const nativeToken = NETWORKS[chainId].nativeCurrency.tokenInfo;
    const importedNativeTransfers = txs.filter(
        (tx): tx is Replace<ExternalTransaction, 'nativeTransfer', Transfer> =>
            tx.nativeTransfer !== null && tx.nativeTransfer.value != '0',
    );
    const importedErc20Transfers = txs.flatMap(tx => tx.erc20TransferEvents.map(erc20Tx => ({ parent: tx, erc20Tx })));
    const importedInternalTransfers = txs.flatMap(tx => tx.internalTranfers.map(internalTx => ({ parent: tx, internalTx })));

    const tokenInfoByAddress = await [...new Set([...importedErc20Transfers.map(x => x.erc20Tx.token.address)])].reduce<
        Promise<Record<string, TokenInfo>>
    >(async (acc, address) => {
        const tokenInfo = await resolveTokenInfo(chainId, address);
        return { ...(await acc), [address]: tokenInfo };
    }, Promise.resolve({}));

    return [
        ...importedNativeTransfers.map(
            (tx): ImportedExternalTransactionInfo => ({
                __type: 'ImportedExternalTransaction',
                chainId,
                txHash: tx.txHash,
                created: new Date(tx.timestamp * 1000),
                id: tx.nativeTransfer.id,
                creditor: tx.nativeTransfer.to,
                debtor: tx.nativeTransfer.from,
                paidAmount: BigNumber.from(tx.nativeTransfer.value),
                tokenInfo: nativeToken,
                description: tx.nativeTransfer.metadata?.description ?? '',
                tags: tx.nativeTransfer.metadata?.tags ?? [],
                notes: tx.nativeTransfer.metadata?.notes ?? '',
            }),
        ),
        ...importedErc20Transfers.map(
            (tx): ImportedExternalTransactionInfo => ({
                __type: 'ImportedExternalTransaction',
                chainId,
                txHash: tx.parent.txHash,
                created: new Date(tx.parent.timestamp * 1000),
                id: tx.erc20Tx.transfer.id,
                creditor: tx.erc20Tx.transfer.to,
                debtor: tx.erc20Tx.transfer.from,
                paidAmount: BigNumber.from(tx.erc20Tx.transfer.value),
                tokenInfo: tokenInfoByAddress[tx.erc20Tx.token.address],
                description: tx.erc20Tx.transfer.metadata?.description ?? '',
                tags: tx.erc20Tx.transfer.metadata?.tags ?? [],
                notes: tx.erc20Tx.transfer.metadata?.notes ?? '',
            }),
        ),
        ...importedInternalTransfers
            .map(
                (tx): ImportedExternalTransactionInfo => ({
                    __type: 'ImportedExternalTransaction',
                    chainId,
                    txHash: tx.parent.txHash,
                    created: new Date(tx.parent.timestamp * 1000),
                    id: tx.internalTx.transfer.id,
                    creditor: tx.internalTx.transfer.to,
                    debtor: tx.internalTx.transfer.from,
                    paidAmount: BigNumber.from(tx.internalTx.transfer.value),
                    tokenInfo: nativeToken,
                    description: tx.internalTx.transfer.metadata?.description ?? '',
                    tags: tx.internalTx.transfer.metadata?.tags ?? [],
                    notes: tx.internalTx.transfer.metadata?.notes ?? '',
                }),
            )
            .sort((a, b) => b.created.getTime() - a.created.getTime()),
    ];
}
