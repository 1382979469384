{
    "defaultAddress": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
    "released": true,
    "contractName": "MultiSendCallOnly",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "3": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "10": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "11": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "12": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "28": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "42": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "5": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "56": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "69": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "100": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "122": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "123": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "137": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "246": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "288": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "588": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1088": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "1284": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1285": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1287": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "4002": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "42161": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "42220": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "43114": "0xA1dabEF33b3B82c7814B6D82A79e50F4AC44102B",
        "73799": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "80001": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "333999": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1313161554": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D",
        "1313161555": "0x40A2aCCbd92BCA938b02010E17A5b8929b49130D"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "transactions",
                    "type": "bytes"
                }
            ],
            "name": "multiSend",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }
    ]
}
