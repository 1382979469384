import React, { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { FactoringConfig } from '../../data-lib/networks';
import { OrangeButton, SecondaryOrangeButton } from '../inputs/buttons';
import { DepositFundsModal } from '../modals/deposit-funds-modal';
import { RedeemFundsModal } from '../modals/redeem-funds-modal';

interface DepositRedeemButtonsProps {
    factoringConfig: FactoringConfig;
    isLoading?: boolean;
    hasDepositPermissions: boolean;
    holdsNoBFT: boolean;
}

export const DepositRedeemButtons: React.FC<DepositRedeemButtonsProps> = ({
    factoringConfig,
    hasDepositPermissions,
    isLoading,
    holdsNoBFT,
}) => {
    const [depositModalOpen, setDepositModalOpen] = useState(false);
    const [redeemModalOpen, setRedeemModalOpen] = useState(false);

    const closeDepositModal = () => setDepositModalOpen(false);
    const closeRedeemModal = () => setRedeemModalOpen(false);

    return (
        <Flex>
            {redeemModalOpen && (
                <RedeemFundsModal modalOpen={redeemModalOpen} closeModal={closeRedeemModal} factoringConfig={factoringConfig} />
            )}
            <SecondaryOrangeButton
                mr={2}
                px="4"
                onClick={() => setRedeemModalOpen(true)}
                isDisabled={holdsNoBFT || isLoading || !hasDepositPermissions}
            >
                Redeem
            </SecondaryOrangeButton>
            {depositModalOpen && (
                <DepositFundsModal modalOpen={depositModalOpen} closeModal={closeDepositModal} factoringConfig={factoringConfig} />
            )}
            <OrangeButton px="4" onClick={() => setDepositModalOpen(true)} isDisabled={isLoading || !hasDepositPermissions}>
                Deposit
            </OrangeButton>
        </Flex>
    );
};
