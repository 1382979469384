import { BullaClaimERC721 } from '@bulla-network/contracts/typechain/BullaClaimERC721';
import { BullaFinance } from '@bulla-network/contracts/typechain/BullaFinance';
import { BigNumber, BigNumberish, Contract, ethers } from 'ethers';
import { MetaTransaction } from 'ethers-multisend';
import { BytesLike, TransactionDescription } from 'ethers/lib/utils';
import { ClaimInfo, TAG_SEPARATOR } from '../data-model';
import { PayClaimTransaction } from '../domain/bulla-claim-domain';
import { EthAddress, toChecksumAddress } from '../ethereum';
import { dateToInt, daysToSeconds, toBytes32, ZERO_BIGNUMBER } from '../helpers';
import { Multihash } from '../multihash';
import { TokenDto } from '../networks';
import { FinancingTerms, termLengthToDays } from './bulla-finance-dto';
import { IBullaClaimERC721, IBullaFinance } from './contract-interfaces';

export type BullaClaimDto = {
    tags: string[] | undefined;
    claimAmount: BigNumber;
    token: TokenDto;
    creditor: EthAddress;
    debtor: EthAddress;
    description: string;
    dueBy: Date;
    ipfsHash: Multihash;
    tokenURI: string;
};

export const getPayClaimTransaction = (_bullaClaimAddress: EthAddress, _claimId: BigNumberish, _amount: BigNumber) => {
    const bullaClaimAddress = toChecksumAddress(_bullaClaimAddress);
    return {
        to: bullaClaimAddress,
        value: '0',
        operation: 0,
        data: IBullaClaimERC721.encodeFunctionData('payClaim', [_claimId, _amount]),
    };
};

export const getRejectClaimTransaction = (_bullaClaimAddress: EthAddress, _claimId: BigNumberish) => {
    const bullaClaimAddress = toChecksumAddress(_bullaClaimAddress);

    return {
        to: bullaClaimAddress,
        value: '0',
        operation: 0,
        data: IBullaClaimERC721.encodeFunctionData('rejectClaim', [_claimId]),
    };
};

export const getRescindClaimTransaction = (_bullaClaimAddress: EthAddress, _claimId: BigNumberish) => {
    const bullaClaimAddress = toChecksumAddress(_bullaClaimAddress);

    return {
        to: bullaClaimAddress,
        value: '0',
        operation: 0,
        data: IBullaClaimERC721.encodeFunctionData('rescindClaim', [_claimId]),
    };
};

export const mapToPayClaimTransaction = ({ args, name }: Partial<TransactionDescription>): PayClaimTransaction => ({
    __type: 'PayClaimTransaction',
    name: name ?? '',
    tokenId: args?.tokenId instanceof BigNumber ? args?.tokenId.toString() : '',
    paymentAmount: args?.paymentAmount ?? ZERO_BIGNUMBER,
});

type ActionParams = {
    claimInfo: ClaimInfo;
    paymentAmount?: BigNumber;
    contract: Contract;
    amountToPay?: BigNumber;
};

type CreateParams = BullaClaimDto & {
    contract: BullaClaimERC721;
};
export const createClaim = async ({
    contract,
    creditor,
    debtor,
    description,
    claimAmount,
    tokenURI,
    token,
    dueBy,
    ipfsHash,
}: CreateParams) =>
    !!tokenURI
        ? await contract.createClaimWithURI(creditor, debtor, description, claimAmount, dateToInt(dueBy), token.address, ipfsHash, tokenURI)
        : await contract.createClaim(creditor, debtor, description, claimAmount, dateToInt(dueBy), token.address, ipfsHash);

export const payClaim = async ({ contract, claimInfo: { claimAmount, id, paidAmount }, amountToPay }: ActionParams) =>
    await contract.payClaim(BigNumber.from(id), amountToPay ?? claimAmount.sub(paidAmount));

export const rejectClaim = async ({ contract, claimInfo: { id } }: ActionParams) => await contract.rejectClaim(BigNumber.from(id));

export const rescindClaim = async ({ contract, claimInfo: { id } }: ActionParams) => await contract.rescindClaim(BigNumber.from(id));

export const getCreateFinanciableInvoiceTransaction = async ({
    bullaFinanceContract,
    claim,
    tokenURI,
    attachment,
    financingTerms,
}: {
    bullaFinanceContract: BullaFinance;
    claim: BullaClaimDto;
    tokenURI: string;
    attachment: {
        hash: ethers.utils.BytesLike;
        hashFunction: ethers.BigNumberish;
        size: ethers.BigNumberish;
    };
    financingTerms: FinancingTerms;
}): Promise<MetaTransaction> => {
    const claimTokenAddress = toChecksumAddress(claim.token.address);
    const bullaFinanceAddress = toChecksumAddress(bullaFinanceContract.address);
    const tag = toBytes32(claim.tags?.join(TAG_SEPARATOR) ?? '') as BytesLike;
    const fee = await bullaFinanceContract.callStatic.fee();

    return {
        to: bullaFinanceAddress,
        value: fee.toString(),
        data: IBullaFinance.encodeFunctionData('createInvoiceWithFinanceOffer', [
            {
                claimAmount: claim.claimAmount,
                creditor: claim.creditor,
                debtor: claim.debtor,
                description: claim.description.trim(),
                dueBy: dateToInt(claim.dueBy),
                claimToken: claimTokenAddress,
                attachment,
            },
            tokenURI,
            {
                minDownPaymentBPS: financingTerms.downPaymentBPS,
                interestBPS: financingTerms.interestRateBPS,
                termLength: daysToSeconds(termLengthToDays(financingTerms.termLength)),
            },
            tag,
        ]),
    };
};
