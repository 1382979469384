import axios, { AxiosError } from 'axios';
import { ClaimType } from '../data-lib/data-model';
import { CompanyDetails } from '../pages/settings/company-details/common';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type AttachmentLinkGenerator = (
    type: ClaimType,
    recipient: string,
    amount: string,
    tokenSymbol: string,
    description: string,
) => string;

export const useCompanyDetailsRepo = () => {
    const { connectedNetwork, userAddress } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();

    const path = !!connectedSafeAddress ? `${connectedSafeAddress}?account_type=gnosis&chain_id=${connectedNetwork}` : userAddress;
    const pathWithAdditionalQueryParams = !!connectedSafeAddress ? `${path}&` : `${path}?`;

    const getCompanyDetails = async () =>
        await axios
            .get<CompanyDetails>(`${endpoints.settingsApi}/company-details/${path}`, {
                withCredentials: true,
            })
            .then(({ data }) => data)
            .catch((err: AxiosError) => {
                if (err.response?.status == 404) return null;
                else throw err;
            });

    const postCompanyDetails = async (companyDetails: CompanyDetails) =>
        await axios.post(`${endpoints.settingsApi}/company-details/${path}`, companyDetails, {
            withCredentials: true,
        });

    const getAttachmentGenerationLink: AttachmentLinkGenerator = (
        type: ClaimType,
        recipient: string,
        amount: string,
        tokenSymbol: string,
        description: string,
    ) =>
        `${endpoints.settingsApi}/invoice/${pathWithAdditionalQueryParams}kind=${type.toLowerCase()}&recipientAddress=${
            type == 'Invoice' ? actingWallet : recipient
        }&amount=${amount}&symbol=${tokenSymbol}&date=${Math.floor(new Date().getTime() / 1000)}&description=${description}`;

    return { getCompanyDetails, postCompanyDetails, getAttachmentGenerationLink };
};
