import React, { useState, useEffect, useMemo } from 'react';
import { ContactRecord, useContactsApi } from './useContactsApi';
import { useActingWalletAddress } from './useWalletAddress';
import { addressEquality, isValidAddress } from '../data-lib/ethereum';
import * as Yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import { PropsWithChildren } from '../tools/props-with-children';

export type Contact = {
    name: string;
    walletAddress: string;
    emailAddress?: string;
    groups: string[];
};

export type GetContact = (walletAddress: string) => Contact | 'not-found';

export type ContactsRepo = {
    contacts: Contact[];
    addContactAsync: (contact: Contact) => Promise<void>;
    addOrUpdateContactsAsync: (contact: Contact[]) => Promise<void>;
    editContactAsync: (oldContact: Contact, newContact: Contact) => Promise<void>;
    deleteContactsAsync: (toDelete: Contact[]) => Promise<void>;
    getContact: GetContact;
};

export interface ExtendedContactsContextType {
    contacts: Contact[];
    setFetchTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExtendedContactsContext = React.createContext<ContactsRepo | 'fetching' | 'unauthorized'>('fetching');

export function isContactsReady(context: ContactsContext): context is ContactsRepo {
    return context !== 'fetching' && context !== 'unauthorized';
}

export function requireContactsReady(): never {
    throw new Error('Contacts not ready. Should never happen');
}

export function validate(func: () => any): true | string[] {
    try {
        func();
        return true;
    } catch (error: any | { errors: string[] }) {
        const maybeValidationErrors: string[] | undefined = error?.errors;
        return maybeValidationErrors === undefined ? ['Unknown error'] : maybeValidationErrors;
    }
}

export const contactNameSchema = Yup.string().required('Contact name required');
export const emailAddressSchema = Yup.string().email('Invalid email address').optional();

export const isEmailValid = (email: string) => validate(() => emailAddressSchema.validateSync(email));
export const isContactNameValid = (name: string) => validate(() => contactNameSchema.validateSync(name));

export type ContactValidator = {
    schema: any;
    editSchema: (editContact: Contact) => any;
    isContactNameValid: (name: string) => true | string[];
    canAddWalletAddressToContacts: (address: string, specificContactsNotToDuplicate?: Contact[]) => true | string[];
    isEmailValid: (name: string) => true | string[];
};

const contactValidator = (walletAddressSchema: RequiredStringSchema<string | undefined>) =>
    Yup.object().shape({
        name: contactNameSchema,
        walletAddress: walletAddressSchema,
        emailAddress: emailAddressSchema,
    });

function walletAddressSchemaFactory(userAddress: string, contactsNotToDuplicate: Contact[]) {
    return Yup.string()
        .required('Wallet address required')
        .test('is-valid-address', 'Invalid wallet address', value => isValidAddress(value || ''))
        .test('is-address-own', 'Cannot add own wallet address', value => !addressEquality(value || '', userAddress))
        .test('already-exists', 'Cannot add duplicate wallet address', value =>
            contactsNotToDuplicate.every(x => !addressEquality(x.walletAddress, value || '')),
        );
}

export function useContactValidator(): ContactValidator {
    const contactsContext = useExtendedContacts();
    const actingWallet = useActingWalletAddress();

    const contacts = isContactsReady(contactsContext) ? contactsContext.contacts : [];
    const walletAddressSchema = walletAddressSchemaFactory(actingWallet, contacts);
    const schema = contactValidator(walletAddressSchema);
    const editSchema = (editContact: Contact) =>
        contactValidator(
            walletAddressSchemaFactory(
                actingWallet,
                contacts.filter(x => !addressEquality(x.walletAddress, editContact.walletAddress)),
            ),
        );

    const canAddWalletAddressToContacts = (address: string, specificContactsNotToDuplicate?: Contact[]) => {
        const contactsNotToDuplicate = specificContactsNotToDuplicate ?? contacts;
        return validate(() => walletAddressSchemaFactory(actingWallet, contactsNotToDuplicate).validateSync(address));
    };

    return { schema, editSchema, isContactNameValid, canAddWalletAddressToContacts, isEmailValid };
}

const toContactRecord = (contacts: Contact[]) =>
    contacts.reduce<ContactRecord>((acc, item) => ({ ...acc, [item.walletAddress]: { ...item, email: item.emailAddress } }), {});

export type ContactsContext = ContactsRepo | 'fetching' | 'unauthorized';

export const ExtendedContactsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [backendContacts, setBackendContacts] = useState<'init' | Contact[] | 'unauthorized'>('init');
    const { fetchContacts: fetchContactsFromBackend, saveContacts, deleteContacts } = useContactsApi();
    const actingWalletAddress = useActingWalletAddress();

    useEffect(() => {
        setBackendContacts('init');

        fetchContactsFromBackend()
            .then((fetchedContacts: Record<string, ContactRecord>) => {
                const processedContacts: Contact[] = Object.entries(fetchedContacts).map(([walletAddress, contactRecord]) => ({
                    ...contactRecord,
                    walletAddress,
                    name: contactRecord.name?.toString() ?? '',
                    emailAddress: contactRecord.email?.toString() ?? '',
                    groups: Array.isArray(contactRecord.groups) ? contactRecord.groups : [],
                }));

                setBackendContacts(processedContacts);
            })
            .catch(error => {
                console.error(error);
                setBackendContacts('unauthorized');
            });
    }, [actingWalletAddress]);

    const contacts = useMemo(
        () => (backendContacts == 'init' || backendContacts == 'unauthorized' ? [] : backendContacts),
        [JSON.stringify(backendContacts)],
    );

    const saveAndSet = (contacts: Contact[]) => {
        const walletsToSave = contacts.map(x => x.walletAddress.toLowerCase());
        return saveContacts(toContactRecord(contacts)).then(_ =>
            setBackendContacts(old =>
                old == 'unauthorized'
                    ? 'unauthorized'
                    : [...(old == 'init' ? [] : old.filter(x => !walletsToSave.includes(x.walletAddress.toLowerCase()))), ...contacts],
            ),
        );
    };

    const contactsRepo: ContactsRepo = React.useMemo(() => {
        const addOrUpdateContactsAsync = (contacts: Contact[]) => saveAndSet(contacts);
        const addContactAsync = (contact: Contact) => saveAndSet([contact]);
        const editContactAsync = (_: Contact, newContact: Contact) => addContactAsync(newContact);
        const deleteContactsAsync = (contacts: Contact[]) => {
            const addressesToDelete = contacts.map(x => x.walletAddress.toLowerCase());
            return deleteContacts(addressesToDelete).then(_ =>
                setBackendContacts(old =>
                    old == 'unauthorized'
                        ? 'unauthorized'
                        : [...(old == 'init' ? [] : old.filter(x => !addressesToDelete.includes(x.walletAddress.toLowerCase())))],
                ),
            );
        };
        const getContact = (walletAddress: string) => {
            if (backendContacts == 'init' || backendContacts == 'unauthorized') return 'not-found';

            const contact = backendContacts.find(
                contact => addressEquality(contact.walletAddress, walletAddress) || contact.emailAddress == walletAddress,
            );

            return contact ?? 'not-found';
        };

        return { getContact, addOrUpdateContactsAsync, addContactAsync, editContactAsync, deleteContactsAsync, contacts };
    }, [JSON.stringify(contacts)]);

    return (
        <ExtendedContactsContext.Provider
            value={backendContacts == 'init' ? 'fetching' : backendContacts == 'unauthorized' ? 'unauthorized' : contactsRepo}
        >
            {children}
        </ExtendedContactsContext.Provider>
    );
};

export const useExtendedContacts = () => {
    const context = React.useContext(ExtendedContactsContext);
    if (context === undefined) {
        throw new Error('useExtendedContacts must be used within a ExtendedContactsProvider');
    }
    return context;
};
