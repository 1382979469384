import axios from 'axios';
import React, { useEffect } from 'react';
import { addressEquality, EthAddress } from '../data-lib/ethereum';
import { useAuthApi } from '../hooks/useAuthApi';
import { useConnectedEOA } from '../hooks/useConnectedEOA';
import { endpoints, getCookies, getExpectedCookieName } from '../tools/common';
import { ActiveMembershipInfo, BullaJWTSchema } from '../tools/storage';
import { CaughtError } from '../tools/types';

export type AuthenticatedUser = {
    wallet: EthAddress;
    __DONT_USE_DIRECTLY_membership: Membership | null | undefined;
    __DONT_USE_DIRECTLY_memberships: ActiveMembershipInfo[] | undefined;
};

export type AuthState = {
    user: AuthenticatedUser | 'init' | 'authenticating' | CaughtError | 'not-authenticated';
    authenticate: () => Promise<{ success: boolean }>;
};

export type Membership = {
    exp: number;
    isFreeTrial?: boolean; // undefined here means false
    membershipIds: string[];
};

export const isUserReady = (user: AuthState['user']): user is AuthenticatedUser =>
    user !== 'init' && user !== 'authenticating' && user !== 'not-authenticated' && !('errorMessage' in user);

export const hasAuthenticationFailed = (user: AuthState['user']): user is CaughtError => typeof user == 'object' && 'errorMessage' in user;

export const parseAuthJWT = (jwt: string): BullaJWTSchema => {
    const [, payload] = jwt.split('.');
    let payloadJSON: BullaJWTSchema;
    try {
        payloadJSON = JSON.parse(atob(payload));
    } catch (e: any) {
        console.error(e);
        throw new Error('Invalid JWT');
    }
    return payloadJSON;
};

const initAuthState: AuthState = {
    user: 'init',
    authenticate: async () => ({ success: false }),
};

const getInitUserState = (userAddress: EthAddress): AuthState['user'] => {
    const initCookies = getCookies();
    let JWTPayload: BullaJWTSchema;
    try {
        JWTPayload = parseAuthJWT(initCookies?.[getExpectedCookieName(userAddress)]);
        if (!addressEquality(JWTPayload.wallet, userAddress)) throw new Error('current wallet unauthenticated');
        if (new Date(JWTPayload.exp * 1000).getTime() < new Date().getTime()) throw new Error('expired token');
    } catch (e: any) {
        console.debug(e.message);
        return 'not-authenticated';
    }

    return {
        wallet: JWTPayload.wallet,
        __DONT_USE_DIRECTLY_membership: JWTPayload.membership,
        __DONT_USE_DIRECTLY_memberships: JWTPayload.memberships,
    };
};

export const AuthContext = React.createContext<AuthState>(initAuthState);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const eoaSigner = useConnectedEOA();
    const { verifyAddress } = useAuthApi();
    const [user, setUser] = React.useState<AuthState['user']>(() =>
        eoaSigner !== 'not-connected' ? getInitUserState(eoaSigner.address) : 'init',
    );

    useEffect(() => {
        eoaSigner !== 'not-connected' && setUser(getInitUserState(eoaSigner.address));
    }, [eoaSigner]);

    const authenticate = React.useCallback(async () => {
        setUser('authenticating');
        console.log('🔐 authenticating 🔐');
        try {
            if (eoaSigner == 'not-connected') throw new Error('Wallet not connected. Please connect your wallet.');
            const address = eoaSigner.address;
            const { authApi } = endpoints;
            const messageToSign = await axios.get(`${authApi}/message/${address}`);
            const signature = await eoaSigner.signer.signMessage(messageToSign.data.message);

            const message = await verifyAddress(address, signature);

            const parsedJWT = parseAuthJWT(message);
            setUser({
                wallet: parsedJWT.wallet,
                __DONT_USE_DIRECTLY_membership: parsedJWT.membership,
                __DONT_USE_DIRECTLY_memberships: parsedJWT.memberships,
            });
            return { success: true };
        } catch (e: any) {
            console.error('🚨 authentication failed 🚨');
            console.log(e);
            setUser({ errorMessage: e.message });
            return { success: false };
        }
    }, [eoaSigner]);

    const context = React.useMemo(() => ({ user, authenticate }), [user, authenticate]);
    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) throw new Error('useAuth must me used within the Auth provider');
    return context;
};
