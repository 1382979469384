{
    "id": "/orderCancellationRequestsSchema",
    "type": "array",
    "items": {
        "properties": {
            "order": { "$ref": "/orderSchema" },
            "takerTokenCancelAmount": { "$ref": "/wholeNumberSchema" }
        },
        "required": ["order", "takerTokenCancelAmount"],
        "type": "object"
    }
}
