import { Box, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text } from '@chakra-ui/react';
import { Detective, DotsThreeOutline, FolderMinus, IconProps, Money, Note, PaperPlaneTilt } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../assets/warning';
import { FromAndToWallet } from '../../components/base/address-label';
import { ChainSymbol } from '../../components/chain-symbol';
import { TokenAmount } from '../../components/currency/token-display-amount';
import { GettingStartedCard } from '../../components/display/views/getting-started';
import { OneTagPillOrMore } from '../../components/inputs/account-tag-input';
import { LinkButton, ViewDetailsButton } from '../../components/inputs/buttons';
import { CHAIN_COLUMN_WIDTH, DATE_COLUMN_WIDTH, ListViewCard, ShadowBox } from '../../components/layout/cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { CreateClaimModal } from '../../components/modals/create-claim-modal/create-claim-modal';
import { CreateItemLinkHandler } from '../../components/modals/create-claim-modal/create-link-handler';
import { WelcomePremiumModal } from '../../components/modals/welcome-premium-modal';
import { ChainId } from '../../data-lib/networks';
import { useOpenBullaItem } from '../../hooks/useClaimDetailDisclosure';
import { useGettingStarted } from '../../hooks/useGettingStarted';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useLocalStorage } from '../../hooks/useStorage';
import { useTokenSafety } from '../../hooks/useTokenSafety';
import { useGlobalUserData } from '../../hooks/useUserData';
import { useUserSummary } from '../../hooks/useUserSummary';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useAppState } from '../../state/app-state';
import { toDateDisplay } from '../../tools/common';
import { STORAGE_KEYS } from '../../tools/storage';

type HomePageCardProps = { title: string; children: React.ReactNode };
const HomePageCard = ({ title, children }: HomePageCardProps) => (
    <ShadowBox p="6" flex="1">
        <Stack>
            <Text textStyle="listTitle" fontSize={'16px'} fontWeight={600}>
                {title}
            </Text>
            {children}
        </Stack>
    </ShadowBox>
);

const quickActionShadowProps = { style: { filter: 'drop-shadow(0px 18px 40px rgba(112, 144, 176, 0.3))' } };

type QuickActionProps = {
    text: string;
    Icon: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
    menuItems?: { label: string; action: () => void }[];
    onClick?: VoidFunction;
    isDisabled?: boolean;
    blimpCount?: number;
};

const QuickAction = ({ text, Icon, onClick, isDisabled, menuItems, blimpCount }: QuickActionProps) => (
    <Menu>
        <MenuButton
            onClick={!!isDisabled ? undefined : onClick}
            _hover={{ cursor: !!isDisabled ? 'not-allowed' : 'pointer', textDecoration: 'underline' }}
        >
            <Stack w="52px" alignItems={'center'} textAlign={'center'} position="relative">
                {blimpCount && blimpCount > 0 && (
                    <Box p="0.5" bg="red" borderRadius={'50%'} position="absolute" zIndex={10} right="-7px" top={'-7px'} minW="21.5px">
                        <Text color={'white'} fontSize="12px" fontWeight={'700'}>
                            {blimpCount}
                        </Text>
                    </Box>
                )}
                <Box borderRadius={'50%'} bg="white" {...quickActionShadowProps} p="4" color="brand.bulla_orange">
                    <Icon size={'24px'} />
                </Box>
                <Text noOfLines={2}>{text}</Text>
            </Stack>
        </MenuButton>
        {menuItems && (
            <MenuList>
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={item.action}>
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        )}
    </Menu>
);

export const NewHome = () => {
    const { showGettingStarted, setGettingStartedEnabled } = useGettingStarted();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    const actingWallet = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const { readyToTransact } = useAppState();
    const { receivedBullaItemsWithPayments, paidBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const [welcomePremium, _, clearWelcomePremium] = useLocalStorage<boolean>(`${STORAGE_KEYS.welcomePremium}#${actingWallet}`, false);
    const { payableTotals } = useUserSummary();
    const { isTokenInfoSafe } = useTokenSafety();

    const { last5Transactions, totalTransactionCount, mostActiveChain } = React.useMemo(() => {
        const allPayments = [...receivedBullaItemsWithPayments, ...paidBullaItemsWithPayments];
        const totalTransactionCount = receivedBullaItemsWithPayments.length + paidBullaItemsWithPayments.length;
        const paymentCountByChainId = allPayments
            .map(x => x.chainId)
            .reduce<Record<number, number>>((acc, chainId) => ({ ...acc, [chainId]: (acc[chainId] ?? 0) + 1 }), {});

        const sortedByChainActivity = Object.entries(paymentCountByChainId).sort((a, b) => b[1] - a[1]);
        const mostActiveChain = sortedByChainActivity.length == 0 ? undefined : (+sortedByChainActivity[0][0] as ChainId);
        const last5Transactions = allPayments
            .sort((a, b) => b.paymentTimestamp.getTime() - a.paymentTimestamp.getTime())
            .slice(0, 5)
            .map(item => {
                const { paymentTimestamp, payment, tokenInfo, debtor, chainId, creditor, description, tags, __type } = item;
                const date = (
                    <Flex>
                        <Text textStyle="cell" minW="20px">
                            {toDateDisplay(paymentTimestamp)}
                        </Text>
                    </Flex>
                );

                const isTokenSafe = isTokenInfoSafe(tokenInfo);
                const tokenAmountDisplay = (
                    <HStack>
                        {!isTokenSafe && (
                            <WarningIconWithTooltip
                                label="Beware of airdropped scam tokens."
                                warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                            />
                        )}
                        <TokenAmount amount={payment} tokenInfo={tokenInfo} />
                    </HStack>
                );

                return {
                    columnValues: isMobile
                        ? [date, tokenAmountDisplay]
                        : [
                              <FromAndToWallet chainId={chainId} from={debtor} to={creditor} />,
                              <ChainSymbol chainId={chainId} />,
                              date,
                              <Text textOverflow={'ellipsis'} noOfLines={2}>
                                  {description}
                              </Text>,
                              <OneTagPillOrMore tags={tags} />,
                              <>
                                  {tokenAmountDisplay}
                                  <Spacer />
                                  <ViewDetailsButton onClick={() => openItem(item)} />
                              </>,
                          ],
                    isUnsafe: !isTokenSafe,
                };
            });

        return { last5Transactions, totalTransactionCount, mostActiveChain };
    }, [JSON.stringify([...receivedBullaItemsWithPayments, ...paidBullaItemsWithPayments]), isMobile, isTokenInfoSafe]);

    return (
        <PageLayoutProvider>
            <GettingStartedCard isOpen={showGettingStarted} onClose={() => setGettingStartedEnabled(false)} />
            <Box px="12" flex="1" flexDir={'column'}>
                <MaxWidthWrapper pb="12">
                    <Flex pt="12">
                        <Text fontSize={'30px'} fontWeight="600">
                            Overview
                        </Text>
                    </Flex>
                    <Box pt="4">
                        <Stack>
                            <Text fontWeight={600} fontSize="14px">
                                Quick Actions
                            </Text>
                            <Flex direction={'row'} wrap="wrap" gap={'6'}>
                                <CreateClaimModal
                                    claimType="Payment"
                                    triggerElement={onOpen => (
                                        <QuickAction
                                            text="Send Tokens"
                                            Icon={PaperPlaneTilt}
                                            onClick={onOpen}
                                            isDisabled={!readyToTransact}
                                        />
                                    )}
                                />
                                <CreateClaimModal
                                    claimType="Invoice"
                                    triggerElement={onOpen => (
                                        <QuickAction text="Request Tokens" Icon={Note} onClick={onOpen} isDisabled={!readyToTransact} />
                                    )}
                                />
                                <QuickAction text="Explore Transactions" Icon={Detective} onClick={() => navigate('/payments')} />
                                <QuickAction
                                    text="Batch Actions"
                                    Icon={DotsThreeOutline}
                                    menuItems={[
                                        { label: 'Batch Payment', action: () => navigate('/batch-payment') },
                                        { label: 'Batch Invoice', action: () => navigate('/batch-invoice') },
                                    ]}
                                />
                                <QuickAction
                                    text="View Payables"
                                    Icon={Money}
                                    onClick={() => navigate('/payables')}
                                    blimpCount={
                                        payableTotals.invoices.filter(x => x.claimStatus == 'Pending').length +
                                        payableTotals.payments.filter(x => x.claimStatus == 'Pending').length
                                    }
                                />
                                <QuickAction
                                    text="View Expenses"
                                    Icon={FolderMinus}
                                    onClick={() => navigate('/payments?category=Expense')}
                                />
                            </Flex>
                        </Stack>
                    </Box>
                    {/* <ResponsiveStack mt="12" gap={'3'}>
                        <HomePageCard title={'Current Balance'}>
                            <Box fontSize={'36px'} fontWeight={'600'}>
                                {toUSD(usdPortfolioBalance)}
                            </Box>
                        </HomePageCard>
                        <HomePageCard title={'Total Transactions'}>
                            <Box fontSize={'36px'} fontWeight={'600'}>
                                {totalTransactionCount}
                            </Box>
                        </HomePageCard>
                        <HomePageCard title={'Most Active Blockchain'}>
                            <Box fontSize={'36px'} fontWeight={'600'}>
                                {!!mostActiveChain ? (
                                    <HStack gap={1}>
                                        <ChainSymbol chainId={mostActiveChain} maxH="44px" />
                                        <Text>{NETWORKS[mostActiveChain].label}</Text>
                                    </HStack>
                                ) : (
                                    'None'
                                )}
                            </Box>
                        </HomePageCard>
                    </ResponsiveStack> */}
                    <ListViewCard
                        titleElement={
                            <Flex>
                                <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                                    Recent Transactions
                                </Text>
                                <Spacer />
                                <LinkButton to="/payments" color={isMobile ? 'brand.bulla_blue' : 'theme'}>
                                    {isMobile ? 'View All' : 'View All Transactions'}
                                </LinkButton>
                            </Flex>
                        }
                        headers={
                            isMobile
                                ? ['date', 'amount']
                                : [
                                      { label: 'from / to' },
                                      { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
                                      { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
                                      { label: 'description' },
                                      { label: 'categories' },
                                      { label: 'amount' },
                                  ]
                        }
                        displayedListItems={last5Transactions}
                        emptyMessage={<Text textStyle="faint">No transactions to display</Text>}
                        mt="12"
                    />
                </MaxWidthWrapper>
            </Box>
            {welcomePremium && <WelcomePremiumModal modalOpen={welcomePremium} closeModal={clearWelcomePremium}></WelcomePremiumModal>}
            <CreateItemLinkHandler />
        </PageLayoutProvider>
    );
};
