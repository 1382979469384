import { useState } from 'react';
import { ClaimInfo } from '../data-lib/data-model';
import { getOnlyBullaItemRelatedLogs } from '../data-lib/data-transforms';
import { InvoiceApprovedEvent } from '../data-lib/domain/factoring-domain';
import { getEventsFromReceipt } from '../data-lib/dto/events-dto';
import { addressEquality } from '../data-lib/ethereum';
import { useAppState } from '../state/app-state';
import { enableBullaFactoringPool } from '../tools/featureFlags';
import { useUnderwriter, FactoringApprovalPathParameters, FactoringApprovalResponse } from './useUnderwriter';
import { useWaitForItemEventFromTx } from './useWaitForEvent';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type BullaFactoringEligibilityTerms = {
    interestApr: number;
    upfrontBps: number;
    validUntil: Date;
};

export type BullaFactoringEligibilityState =
    | { type: 'init' }
    | { type: 'not-supported' }
    | { type: 'not-eligible'; reason?: string }
    | { type: 'eligible'; terms: BullaFactoringEligibilityTerms };

export const useCheckFactoringEligibility = () => {
    const { connectedNetwork } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const { getFactoringApprovalTx } = useUnderwriter();
    const [loading, setLoading] = useState(false);
    const [searching, eventFound, waitForLogFromTx] = useWaitForItemEventFromTx();
    const { addEventsToAppState } = useAppState();

    const checkEligibility = async (claim: ClaimInfo, bullaFundAddress: string): Promise<BullaFactoringEligibilityState> => {
        const isValidNetworkAndEligibility =
            claim.chainId === connectedNetwork && addressEquality(claim.creditor, actingWallet) && enableBullaFactoringPool;

        if (!isValidNetworkAndEligibility) {
            return { type: 'not-eligible', reason: 'Pool at capacity' };
        }

        const approvalArgs: FactoringApprovalPathParameters = {
            wallet: actingWallet,
            chainId: claim.chainId.toString(),
            poolAddress: bullaFundAddress,
            claimId: claim.id,
        };

        setLoading(true);
        try {
            const tx: FactoringApprovalResponse = await getFactoringApprovalTx(approvalArgs);

            if (typeof tx === 'object' && 'txHash' in tx) {
                const receipt = await waitForLogFromTx(tx.txHash);
                const eventsFromTxReceipt = getEventsFromReceipt(receipt);
                const invoiceApprovedEvent = eventsFromTxReceipt.find(
                    event => event.__typename === 'InvoiceApprovedEvent',
                ) as InvoiceApprovedEvent;

                addEventsToAppState(getOnlyBullaItemRelatedLogs(eventsFromTxReceipt));

                const factoringTerms: BullaFactoringEligibilityTerms = {
                    interestApr: invoiceApprovedEvent.interestApr,
                    upfrontBps: invoiceApprovedEvent.upfrontBps,
                    validUntil: invoiceApprovedEvent.validUntil,
                };
                return { type: 'eligible', terms: factoringTerms };
            } else if ('errors' in tx) {
                switch (tx.errors[0]) {
                    case 'PAST_DUE_DATE':
                        return { type: 'not-eligible', reason: 'Past due date' };
                    case 'DEBTOR_IS_CREDITOR':
                        return { type: 'not-eligible', reason: 'Debtor is creditor' };
                    case 'UNAUTHORIZED_DEBTOR':
                        return { type: 'not-eligible', reason: 'Debtor is unauthorized' };
                    case 'CLAIM_ALREADY_PAID':
                        return { type: 'not-eligible', reason: 'Claim already paid' };
                    case 'BORROWER_NOT_CREDITOR':
                        return { type: 'not-eligible', reason: 'Borrower is not creditor' };
                    case 'TOKEN_NOT_MATCHING':
                        return { type: 'not-eligible', reason: 'Token not matching' };
                    case 'DUE_DATE_PAST_30_DAYS':
                        return { type: 'not-eligible', reason: 'Due date past 30 days' };
                    case 'CLAIM_ALREADY_FACTORED':
                        return { type: 'not-eligible', reason: 'Claim already factored' };
                    default:
                        return { type: 'not-eligible' };
                }
            } else {
                return { type: 'not-eligible' };
            }
        } finally {
            setLoading(false);
        }
    };

    return [loading, { checkEligibility }] as const;
};
