import { Box, Container, Heading, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import ConnectYourWalletImage from 'url:../../assets/connect-your-wallet.svg';
import { NetworkSelector } from '../../components/display/wallet-info';
import { NETWORKS, SUPPORTED_NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useIsMobile } from '../../hooks/useIsMobile';
import { SlideContainer } from './onboard';

const aggregateNetworkLabels = (chainIds: number[]) =>
    chainIds
        .map(x => NETWORKS[x].label)
        .reduce((acc, item, index, initialArray) => (index == initialArray.length - 1 ? `${acc} and ${item}` : `${acc}, ${item}`));

export const ConnectNetworkSlide = () => {
    const canChangeNetwork = useCanChangeNetwork();
    const isMobile = useIsMobile();

    return (
        <SlideContainer>
            <Stack spacing={'4'} alignItems={'center'}>
                <Heading color="black" textAlign={'center'} fontSize={'20px'}>
                    Unsupported Network
                </Heading>
                <Text fontWeight={'400'} fontSize="14px" color={'#696969'} textAlign="center">
                    BullaBanker supports {aggregateNetworkLabels(SUPPORTED_NETWORKS)}.
                </Text>
                <Container py="2" w="fit-content">
                    <Image src={ConnectYourWalletImage} h={['120px', '250px', '320px']} />
                </Container>
                <Box p="0">
                    {canChangeNetwork && !isMobile ? (
                        <NetworkSelector />
                    ) : (
                        <Text fontWeight={'400'} fontSize="1em" color={'#696969'} textAlign="center">
                            Connect to supported network to enter app.
                        </Text>
                    )}
                </Box>
            </Stack>
        </SlideContainer>
    );
};
