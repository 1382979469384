{
    "id": "/orderFillOrKillRequestsSchema",
    "type": "array",
    "items": {
        "properties": {
            "signedOrder": { "$ref": "/signedOrderSchema" },
            "fillTakerAmount": { "$ref": "/wholeNumberSchema" }
        },
        "required": ["signedOrder", "fillTakerAmount"],
        "type": "object"
    }
}
