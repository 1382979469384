import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/datepicker.css';
interface DatePickerProps extends ReactDatePickerProps {
    selectedDate: Date | null | undefined;
    isClearable?: boolean;
    onChange: (date: Date) => any;
    showPopperArrow?: boolean;
    dateFormat?: string;
    placement?: string;
}
export default ({ selectedDate, isClearable, showPopperArrow, onChange, dateFormat, ...overrides }: DatePickerProps) => (
    <DatePicker
        calendarClassName={'chakra-text'}
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        dateFormat={dateFormat}
        {...overrides}
    />
);

interface MultipleDatePickerProps extends Omit<DatePickerProps, 'onChange'> {
    onChange: (date: [Date, Date]) => any;
}
export const MultipleDatePicker = ({
    selectedDate,
    isClearable,
    showPopperArrow,
    onChange,
    dateFormat,
    ...overrides
}: MultipleDatePickerProps) => (
    <DatePicker
        calendarClassName={'chakra-text'}
        selected={selectedDate}
        onChange={onChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        dateFormat={dateFormat}
        selectsRange
        inline
        {...overrides}
    />
);
