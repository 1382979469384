import { ChevronDownIcon, ChevronUpIcon, ExternalLinkIcon, SearchIcon, SettingsIcon } from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Center,
    Flex,
    HStack,
    Image,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Placement,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GnosisSafeIcon, WalletIcon } from '../../assets/svgs';
import { getGnosisSafeURL, GnosisSafeInfo, SUPPORTED_GNOSIS_NETWORKS } from '../../data-lib/gnosis-tools/gnosis';
import { ChainId, NetworkConfig, NETWORKS, SUPPORTED_NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useMembershipUnsafe } from '../../hooks/useMembership';
import { useSessionStorage } from '../../hooks/useStorage';
import { useOnboard, useWeb3 } from '../../hooks/useWeb3';
import { isUserReady, useAuth } from '../../state/auth-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { deleteBullaCookie } from '../../tools/common';
import { STORAGE_KEYS } from '../../tools/storage';
import { AddressLabel } from '../base/address-label';
import { NavButton, navButtonStyles } from '../inputs/buttons';
import { isGnosisConnectedState, useGnosisSafeBanner } from '../layout/header';
import { GnosisOnboardingModal } from '../modals/gnosis-onboarding-modal';
import { Search } from './search/search';
import { filterMenuItemFontProps, filterMenuProps } from './views/filters/common';

export const SettingsButton = () => {
    const navigate = useNavigate();

    return (
        <NavButton onClick={() => navigate('/settings')} borderRadius="full" w="0px">
            <SettingsIcon />
        </NavButton>
    );
};

export const NetworkSelector = () => {
    const { connectedNetworkConfig, connectedNetwork } = useWeb3();
    const { changeNetwork } = useOnboard();
    const { safeInfo } = useGnosisSafe();
    const supportedNetworks = safeInfo ? SUPPORTED_GNOSIS_NETWORKS : SUPPORTED_NETWORKS;
    const chains = Object.values(NETWORKS).filter(({ chainId }) => supportedNetworks.includes(chainId));
    const isUnsupportedNetwork = !connectedNetworkConfig?.label;
    const canChangeNetwork = useCanChangeNetwork();

    return (
        <Menu>
            {({ isOpen, onClose }) => (
                <>
                    <MenuButton
                        as={Button}
                        isActive={isOpen}
                        {...navButtonStyles}
                        {...(!canChangeNetwork && { _hover: { bg: 'inherit' } })}
                        px="4"
                        backgroundColor={!!connectedNetwork ? 'headerBg' : 'brand.bulla_blue'}
                    >
                        <HStack>
                            {isUnsupportedNetwork ? (
                                <Text fontWeight={'600'}>Unsupported Network</Text>
                            ) : (
                                <Center borderRadius={'full'} bg="gray.50" w="25px" h="25px">
                                    <Image src={connectedNetworkConfig.logoFileName} p="2px" />
                                </Center>
                            )}
                            {canChangeNetwork && (!isOpen ? <ChevronDownIcon h="6" w="5" /> : <ChevronUpIcon h="6" w="5" />)}
                        </HStack>
                    </MenuButton>
                    {!!connectedNetworkConfig && canChangeNetwork && (
                        <NetworkDropdown
                            maxH={'100%'}
                            chains={chains}
                            onSelect={chainId => chainId && changeNetwork(chainId).then(onClose)}
                        />
                    )}
                </>
            )}
        </Menu>
    );
};

export const NetworkDropdown = ({
    chains,
    onSelect,
    maxH,
    noSelectionLabel,
}: {
    chains: NetworkConfig[];
    maxH?: BoxProps['maxH'];
    onSelect: (chain: ChainId | undefined) => void;
    noSelectionLabel?: string;
}) => {
    return (
        <MenuList {...filterMenuProps} zIndex={5}>
            {noSelectionLabel !== undefined && (
                <MenuItem
                    color="black"
                    p="3"
                    _hover={{ cursor: 'pointer', bg: 'gray.100' }}
                    onClick={() => onSelect(undefined)}
                    key={'none'}
                    {...filterMenuItemFontProps}
                >
                    <Text as="a">{noSelectionLabel}</Text>
                </MenuItem>
            )}
            {chains.map(chain => (
                <MenuItem
                    color="black"
                    p="3"
                    _hover={{ cursor: 'pointer', bg: 'gray.100' }}
                    onClick={() => onSelect(chain.chainId)}
                    key={chain.chainId}
                    {...filterMenuItemFontProps}
                >
                    <HStack as="a">
                        <Image src={chain.logoFileName} maxH="23px" />
                        <Text>{chain.label}</Text>
                    </HStack>
                </MenuItem>
            ))}
        </MenuList>
    );
};

const ConnectionPopover = ({
    triggerElement,
    headerLabel,
    icon,
    address,
    underAddressLabel,
    disconnect,
    placement,
    externalLink,
}: {
    triggerElement: React.ReactNode;
    headerLabel: string;
    icon: React.ReactNode;
    address: string;
    underAddressLabel: string;
    disconnect?: VoidFunction | (() => Promise<Boolean>);
    placement: Placement;
    externalLink: string;
}) => {
    return (
        <Popover placement={placement}>
            <PopoverTrigger>{triggerElement}</PopoverTrigger>
            <PopoverContent width="22.5em" mt="3">
                <PopoverHeader color={'black'} fontWeight="600">
                    {headerLabel}
                </PopoverHeader>
                <PopoverBody>
                    <Flex py="2" color={'black'}>
                        <HStack>
                            {icon}
                            <Stack spacing={'0.25'}>
                                <HStack mt="0" spacing={1} color={'#469EAA'}>
                                    <AddressLabel fontWeight="500">{address}</AddressLabel>
                                    <Link href={externalLink} isExternal>
                                        <Box>
                                            <ExternalLinkIcon boxSize={4} />
                                        </Box>
                                    </Link>
                                </HStack>
                                <Text>{underAddressLabel}</Text>
                            </Stack>
                        </HStack>
                        {disconnect && (
                            <>
                                <Spacer />
                                <Button minW="fit-content" size="sm" onClick={disconnect} colorScheme={'red'}>
                                    Disconnect
                                </Button>
                            </>
                        )}
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export const WalletInfo = () => {
    const isMobile = useBreakpointValue({ base: true, sm: false }, { ssr: false });
    const { userAddress, connectedNetwork, icon, connectedNetworkConfig, connectedNetworkConfigUnsafe } = useWeb3();
    const { inSafeApp, fetchSafeAppInfo, resetsafeInfo } = useGnosisSafe();
    const [safeInfo] = useSessionStorage<GnosisSafeInfo>(`${connectedNetwork}:${userAddress}:${STORAGE_KEYS.safeInfo}`);
    const { gnosisBannerState } = useGnosisSafeBanner();
    const { walletReset } = useOnboard();
    const { user } = useAuth();
    const premiumMembership = useMembershipUnsafe();

    const navigate = useNavigate();
    const isConnected = !!connectedNetworkConfigUnsafe && typeof connectedNetworkConfigUnsafe === 'object';

    const showConnectToGnosisSafe = !isMobile && gnosisBannerState == 'can-connect-to-safe' && window.location.hash !== '#/onboard';
    const iconSvg = icon?.replace(
        `<?xml version="1.0" encoding="utf-8"?>
        `,
        '',
    );

    const isLoggedIn = isConnected && isUserReady(user);

    React.useEffect(() => {
        if (safeInfo && safeInfo.safeAddress) fetchSafeAppInfo(safeInfo.safeAddress);
    }, []);

    const disconnectEoa = () => {
        deleteBullaCookie(userAddress);
        walletReset();
    };

    return (
        <HStack spacing="1">
            {!isMobile && isLoggedIn && premiumMembership != null && (
                <Search
                    triggerElement={onOpen => (
                        <NavButton onClick={onOpen} w="0" borderRadius={'full'}>
                            <SearchIcon color="scheme.icon_dark" />
                        </NavButton>
                    )}
                />
            )}
            {showConnectToGnosisSafe && (
                <GnosisOnboardingModal
                    triggerElement={connect => (
                        <NavButton onClick={connect.connect}>
                            <HStack>
                                <GnosisSafeIcon />
                                <Text>Safe</Text>
                            </HStack>
                        </NavButton>
                    )}
                />
            )}
            {!showConnectToGnosisSafe && isGnosisConnectedState(gnosisBannerState) && (
                <ConnectionPopover
                    triggerElement={
                        <Box>
                            <NavButton>
                                <HStack pt="0">
                                    <GnosisSafeIcon />
                                    <AddressLabel withIcon={false} charCount={3}>
                                        {gnosisBannerState.connectedSafeAddress}
                                    </AddressLabel>
                                </HStack>
                            </NavButton>
                        </Box>
                    }
                    headerLabel={'Connected To:'}
                    icon={<GnosisSafeIcon width="35" height="35" fill="brand.bulla_blue" />}
                    address={gnosisBannerState.connectedSafeAddress}
                    underAddressLabel={'Gnosis Safe'}
                    disconnect={
                        !inSafeApp
                            ? () => {
                                  resetsafeInfo();
                                  navigate('/');
                              }
                            : undefined
                    }
                    placement="bottom-end"
                    externalLink={getGnosisSafeURL(connectedNetworkConfig, gnosisBannerState.connectedSafeAddress)}
                />
            )}
            {!!userAddress && !inSafeApp && (
                <>
                    <ConnectionPopover
                        triggerElement={
                            <Box>
                                <NavButton>
                                    <HStack>
                                        <WalletIcon />
                                        <AddressLabel withIcon={false} charCount={3}>
                                            {userAddress}
                                        </AddressLabel>
                                    </HStack>
                                </NavButton>
                            </Box>
                        }
                        headerLabel={'Account'}
                        icon={
                            <Image
                                src={iconSvg === undefined ? undefined : `data:image/svg+xml;base64,${btoa(iconSvg)}`}
                                width="35"
                                height="35"
                                alt="Wallet"
                            />
                        }
                        address={userAddress}
                        underAddressLabel={'Wallet'}
                        disconnect={disconnectEoa}
                        placement="bottom-start"
                        externalLink={`${connectedNetworkConfig.blockExplorer}address/${userAddress}`}
                    />

                    {isLoggedIn && <NetworkSelector />}
                </>
            )}
            {isLoggedIn && premiumMembership != null && <SettingsButton />}
        </HStack>
    );
};
