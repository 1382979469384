import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, HStack, Image, Input, Table, TableContainer, Tbody, Text, Textarea, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { useCallback, useMemo, useState } from 'react';
import { DescribeTransactionState, ImportCompleteState, ImportTransactionState } from '.';
import {
    deferUndefinedChildMetadataToParent,
    ExternalTransactionDTO,
    TransferDTO,
    TransferMetadata,
} from '../../../../data-lib/dto/external-transactions-dto';
import { EthAddress } from '../../../../data-lib/ethereum';
import { NETWORKS } from '../../../../data-lib/networks';
import { toDateWithTime } from '../../../../tools/common';
import { BullaBlueTextButton } from '../../../inputs/buttons';
import {
    CumulativeAmountTd,
    CumulativeTransferAmountsByTokenAddress,
    getCumulativeAmounts,
    getFillerRows,
    InboundOutboundTd,
    TableHeader,
    Td,
    Toolbar,
    ToolbarState,
    TransferTd,
    CategoryInput,
} from './common';

const DESCRIBE_HEADERS = [
    { label: 'chain', isNumeric: false },
    { label: 'from / to', isNumeric: false, isSortable: true },
    { label: 'date', isNumeric: false, isSortable: true },
    { label: 'amount', isNumeric: true },
    { label: 'category (optional)', isNumeric: false },
    { label: 'description', isNumeric: false },
    { label: 'notes', isNumeric: false },
] as const;

type ParentTxData = {
    parentTxId: string;
    childTxCount: number;
    cumulativeAmounts: CumulativeTransferAmountsByTokenAddress;
    isParentCheckboxIndeterminate?: boolean;
    toggleOpenRows: () => void;
    rowIsOpen: boolean;
};

export const DescribeTxTr = React.memo(
    ({
        userAddress,
        transfer,
        timestamp,
        suggestedTags,
        isChecked,
        setRowChecked,
        parentTxData,
        metadata,
        setMetadata,
        txHash,
    }: {
        userAddress: EthAddress;
        transfer: TransferDTO;
        timestamp?: Date;
        suggestedTags?: string[];
        setRowChecked: (isChecked: boolean) => void;
        isChecked: boolean;
        parentTxData?: ParentTxData;
        metadata: Partial<TransferMetadata>;
        setMetadata: (metadata: TransferMetadata) => void;
        txHash: string;
    }) => {
        if (parentTxData && parentTxData.childTxCount == 1)
            throw new Error('BAD_IMPL: ParentTxData should not be passed if there is only one child tx');

        const { chainId } = transfer;
        const { logoFileName, label } = NETWORKS[chainId];

        const handleMetadata = (key: keyof TransferMetadata) => (value: any) => {
            if (!isChecked && !!value) setRowChecked(true);
            setMetadata({ id: transfer.id, ...metadata, [key]: value });
        };

        return (
            <Tr h="57px">
                <Td>
                    <HStack>
                        {!!parentTxData && (
                            <HStack
                                onClick={parentTxData?.toggleOpenRows}
                                _hover={{ textDecor: 'underline', cursor: 'pointer' }}
                                spacing="-1"
                            >
                                <TriangleDownIcon w="9px" h="9px" transform={!parentTxData.rowIsOpen ? 'rotate(-90deg)' : 'rotate(0deg)'} />
                                <BullaBlueTextButton textDecor={'none'}>+{parentTxData.childTxCount}</BullaBlueTextButton>
                            </HStack>
                        )}
                        <Image src={logoFileName} maxH="14px" />
                        <Text>{label}</Text>
                    </HStack>
                </Td>

                <InboundOutboundTd pl="12" userAddress={userAddress} transfer={transfer} txHash={txHash} />

                <Td>{timestamp ? toDateWithTime(timestamp) : null}</Td>

                {parentTxData ? (
                    <CumulativeAmountTd
                        cumulativeAmounts={parentTxData.cumulativeAmounts}
                        isOpen={parentTxData.rowIsOpen}
                        toggleOpen={parentTxData.toggleOpenRows}
                    />
                ) : (
                    <TransferTd transfer={transfer} />
                )}

                <Td>
                    {!parentTxData && (
                        <CategoryInput
                            setTags={prevCallback => handleMetadata('tags')(prevCallback(metadata?.tags ?? []))}
                            tags={metadata?.tags ?? []}
                            suggestedTags={suggestedTags ?? []}
                            bg="white"
                        />
                    )}
                </Td>

                <Td>
                    {!parentTxData && (
                        <Input
                            placeholder="Description..."
                            minW="250px"
                            value={metadata?.description ?? ''}
                            onChange={e => handleMetadata('description')(e.target.value)}
                            bg="white"
                        />
                    )}
                </Td>

                <Td>
                    {!parentTxData && (
                        <Textarea
                            rows={1}
                            minW="320px"
                            placeholder="Notes..."
                            value={metadata?.notes ?? ''}
                            onChange={e => handleMetadata('notes')(e.target.value)}
                            bg="white"
                        />
                    )}
                </Td>
            </Tr>
        );
    },
    (prevProps, nextProps) => {
        const hasBeenChecked = prevProps.isChecked !== nextProps.isChecked;
        if (hasBeenChecked) return false;

        const userChanged = prevProps.userAddress !== nextProps.userAddress;
        if (userChanged) return false;

        const hasBeenOpened = prevProps.parentTxData?.rowIsOpen !== nextProps.parentTxData?.rowIsOpen;
        if (hasBeenOpened) return false;

        const metadataChanged = JSON.stringify(prevProps.metadata) !== JSON.stringify(nextProps.metadata);
        if (metadataChanged) return false;

        const transferChanged = JSON.stringify(prevProps.transfer) !== JSON.stringify(nextProps.transfer);
        if (transferChanged) return false;

        return true;
    },
);

export const AddMetadataTxTr = React.memo(
    ({
        tx,
        userAddress,
        setOmittedIds,
        omittedIds,
        transferMetadata,
        setExternalTxState,
    }: {
        userAddress: EthAddress;
        tx: ExternalTransactionDTO;
        setOmittedIds: (callback: (ids: string[]) => string[]) => void;
        omittedIds: string[];
        transferMetadata: Record<string, TransferMetadata>;
        setExternalTxState: React.Dispatch<React.SetStateAction<ImportTransactionState>>;
    }) => {
        const externalTxId = tx.id;
        const txHash = tx.txHash;
        const [parentMetadata, setParentMetadata] = useState<TransferMetadata>(transferMetadata[externalTxId] ?? { id: externalTxId });
        const [topTransfer] = tx.allTransfers;
        const hasChildren = tx.allTransfers.length > 1;

        const childIds = useMemo(() => tx.allTransfers.map(t => t.id), [externalTxId]);
        const allSelected = useMemo(() => childIds.every(id => !omittedIds.includes(id)), [omittedIds, childIds]);
        const anySelected = useMemo(() => childIds.some(id => !omittedIds.includes(id)), [omittedIds, childIds]);
        const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: false });

        const cumulativeAmounts = useMemo(() => getCumulativeAmounts(tx.allTransfers, userAddress), [externalTxId, userAddress]);

        const handleParentCheck = useCallback(
            (includeAll: boolean) => {
                if (includeAll) {
                    setOmittedIds(prev => prev.filter(id => !childIds.includes(id)));
                } else {
                    setOmittedIds(prev => [...new Set([...prev, ...childIds])]);
                }
            },
            [childIds],
        );

        const handleTransferCheck = useCallback((select: boolean, id: string) => {
            if (select) {
                setOmittedIds(prev => prev.filter(_id => id !== _id));
            } else {
                setOmittedIds(prev => [...new Set([...prev, id])]);
            }
        }, []);

        const setMetadata = useCallback((id: string, metadata: TransferMetadata) => {
            setExternalTxState(prev => ({
                ...prev,
                transferMetadata: { ...(prev as DescribeTransactionState).transferMetadata, [id]: metadata },
            }));
        }, []);

        /**
         * @dev if the parent transaction id is found in state.transferMetadata, the children will defer its undefined metadata to the parent
         */
        const handleParentMetadataChange = useCallback((metadata: TransferMetadata) => {
            setParentMetadata(metadata);
            setMetadata(externalTxId, metadata);
        }, []);

        return (
            <>
                <DescribeTxTr
                    {...{
                        key: topTransfer.id,
                        userAddress,
                        transfer: topTransfer,
                        setRowChecked: handleParentCheck,
                        suggestedTags: tx.detectedType ? [tx.detectedType] : undefined,
                        isChecked: allSelected || anySelected,
                        timestamp: tx.timestamp,
                        parentTxData: hasChildren
                            ? {
                                  parentTxId: externalTxId,
                                  childTxCount: tx.allTransfers.length,
                                  cumulativeAmounts,
                                  toggleOpenRows: onToggle,
                                  rowIsOpen: isOpen,
                                  isParentCheckboxIndeterminate: !allSelected && anySelected,
                              }
                            : undefined,
                        metadata: hasChildren ? parentMetadata : transferMetadata[topTransfer.id],
                        setMetadata: hasChildren ? handleParentMetadataChange : metadata => setMetadata(topTransfer.id, metadata),
                        txHash: txHash,
                    }}
                />
                <AnimatePresence>
                    {isOpen &&
                        hasChildren &&
                        tx.allTransfers.map(childTransfer => (
                            <DescribeTxTr
                                {...{
                                    key: childTransfer.id,
                                    transfer: childTransfer,
                                    userAddress,
                                    isChecked: !omittedIds.includes(childTransfer.id),
                                    suggestedTags: tx.detectedType ? [tx.detectedType] : undefined,
                                    setRowChecked: (isChecked: boolean) => handleTransferCheck(isChecked, childTransfer.id),
                                    metadata: deferUndefinedChildMetadataToParent({
                                        parentMetadata,
                                        childMetadata: transferMetadata[childTransfer.id],
                                    }),
                                    setMetadata: metadata => setMetadata(childTransfer.id, metadata),
                                    txHash: txHash,
                                }}
                            />
                        ))}
                </AnimatePresence>
            </>
        );
    },
);

export const DescribeTransactions = ({
    walletAddress,
    externalTxState,
    setExternalTxState,
    filteredTxs,
    visibleTxs,
    toolbarState,
    pageSize,
}: {
    walletAddress: string;
    externalTxState: DescribeTransactionState | ImportCompleteState;
    setExternalTxState: React.Dispatch<React.SetStateAction<ImportTransactionState>>;
    filteredTxs: ImportTransactionState['queried'];
    visibleTxs: ImportTransactionState['queried']['txs'];
    toolbarState: ToolbarState;
    pageSize: number;
}) => {
    const { omittedIds, transferMetadata, step } = externalTxState;
    const setOmittedIds = (callback: (ids: string[]) => string[]) =>
        setExternalTxState(prev => ({ ...prev, omittedIds: callback(prev.omittedIds) }));

    return (
        <>
            <Toolbar
                externalTxState={externalTxState}
                setExternalTxState={setExternalTxState}
                filteredTxs={filteredTxs}
                userAddress={walletAddress}
                toolbarState={toolbarState}
            />
            <Box h="3" />

            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            {DESCRIBE_HEADERS.map((header, i) => (
                                <TableHeader key={i} fontWeight={700} isNumeric={header?.isNumeric}>
                                    {header.label}
                                </TableHeader>
                            ))}
                        </Tr>
                    </Thead>

                    <Tbody>
                        {
                            <>
                                {visibleTxs.map((t, i) => (
                                    <AddMetadataTxTr
                                        key={t.id + i}
                                        tx={t}
                                        userAddress={walletAddress}
                                        setOmittedIds={setOmittedIds}
                                        omittedIds={omittedIds}
                                        transferMetadata={transferMetadata}
                                        setExternalTxState={setExternalTxState}
                                    />
                                ))}
                                {visibleTxs.length < pageSize && getFillerRows(DESCRIBE_HEADERS, pageSize - visibleTxs.length)}
                            </>
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Box h="10" />
        </>
    );
};
