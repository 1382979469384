import { Heading, HStack, VStack, Button, Box, Text, Checkbox, Image } from '@chakra-ui/react';
import { Export, ArrowLeft, ArrowRight } from 'phosphor-react';
import React from 'react';
import { buttonSizeProps, OrangeButton } from '../../inputs/buttons';
import { LedgerWizardState, SelectNameState } from './ledger-wizard-modal';
import { IconBox, LedgerWizardStep } from './progress-steps';
import HandCoins from '../../../assets/coins-hand.svg';
import { getLedgerExportTypeDisplayName } from './ledger-export-success-card';

export const ledgerExportTypes = ['AccountsReceivables', 'AccountsPayables', 'CashDisbursements', 'Transfers'] as const;

export type LedgerExportType = typeof ledgerExportTypes[number];

export type LedgerExportTypeWithIcon = {
    name: LedgerExportType;
    icon: React.ReactNode;
};

export const LedgerExportEntries: Record<string, LedgerExportTypeWithIcon> = {
    AccountsReceivables: {
        name: 'AccountsReceivables',
        icon: <ArrowLeft size={18} weight="bold" color="#E36409" />,
    },
    AccountsPayables: {
        name: 'AccountsPayables',
        icon: <ArrowRight size={18} weight="bold" color="#E36409" />,
    },
    CashDisbursements: {
        name: 'CashDisbursements',
        icon: HandCoins,
    },
};

interface ExportTypeBoxProps {
    type: LedgerExportTypeWithIcon;
    selected: boolean;
    onToggle: () => void;
}

export const ExportTypeBox: React.FC<ExportTypeBoxProps> = ({ type, selected, onToggle }) => {
    const handleBoxClick = (e: React.MouseEvent) => {
        if (!(e.target as HTMLElement).closest('.checkbox-container')) {
            onToggle();
        }
    };

    return (
        <Box
            as="span"
            px={3}
            py={1}
            fontSize="lg"
            fontWeight="semibold"
            backgroundColor={selected ? '#FCEDE2' : 'transparent'}
            color={'#DB4F05'}
            borderColor={'#E36409'}
            borderWidth="3px"
            borderRadius="xl"
            w="100%"
            cursor="pointer"
            onClick={handleBoxClick}
        >
            <HStack mx="2">
                <Box bg="#F8D3B6" borderRadius="full" p="2" borderWidth="5px" borderColor="#f7e5d7">
                    {typeof type.icon === 'string' ? <Image h="21px" w="21px" src={type.icon} /> : type.icon}
                </Box>
                <Text my="6" fontSize="md" ml="3">
                    {getLedgerExportTypeDisplayName(type.name)}
                </Text>
                <Box className="checkbox-container" ml="auto" display="flex" alignItems="center" justifyContent="center">
                    <Checkbox colorScheme="orange" ml="auto" borderColor="#E36409" isChecked={selected} onChange={onToggle} />
                </Box>
            </HStack>
        </Box>
    );
};

interface SelectExportTypeCardProps {
    exportType: LedgerExportType[];
    setExportType: React.Dispatch<React.SetStateAction<LedgerExportType[]>>;
    wizardState: LedgerWizardState;
    setWizardState: (state: LedgerWizardState) => void;
    onSelectedExportType: () => void;
}

export const SelectExportTypeCard: React.FC<SelectExportTypeCardProps> = ({
    exportType,
    setExportType,
    wizardState,
    setWizardState,
    onSelectedExportType,
}) => {
    const toggleExportType = (type: LedgerExportType) => {
        setExportType(prevExportType =>
            prevExportType.includes(type) ? prevExportType.filter(t => t !== type) : [...prevExportType, type],
        );
    };

    return (
        <Box minW="lg" maxW="450px" mx="auto" mt="8" textAlign="left" shadow="xl" p="5" borderRadius="md">
            <IconBox icon={Export} boxShadow="none" w="fit-content" mb="6" />
            <Heading as="h2" size="md" mb="4">
                What do you want to export?
            </Heading>

            <VStack my="6" spacing={'4'}>
                {Object.entries(LedgerExportEntries).map(([key, entry]) => (
                    <ExportTypeBox
                        key={key}
                        type={entry}
                        selected={exportType.includes(entry.name)}
                        onToggle={() => toggleExportType(entry.name)}
                    />
                ))}
            </VStack>

            <HStack justify="space-between" w="100%">
                <Button
                    variant="outline"
                    flex={1}
                    {...buttonSizeProps}
                    onClick={() =>
                        setWizardState({
                            ...wizardState,
                            step: LedgerWizardStep.SelectName,
                        } as SelectNameState)
                    }
                >
                    Cancel
                </Button>
                <OrangeButton flex={1} isDisabled={exportType.length === 0} onClick={onSelectedExportType}>
                    Export
                </OrangeButton>
            </HStack>
        </Box>
    );
};
