import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface TitledPageProps {
    title: string;
    children: React.ReactNode;
}

declare global {
    interface Window {
        gtag: any;
    }
}

export const TitledPage: FC<TitledPageProps> = ({ title, children }) => {
    const location = useLocation();

    useEffect(() => {
        const pageTitle = title ? `Bulla Banker | ${title}` : 'Bulla Banker';
        document.title = pageTitle;

        if (typeof window.gtag !== 'undefined') {
            window.gtag('config', 'G-MVREXLWP87', { page_path: location.pathname + location.search, page_title: pageTitle });
        }
    }, [title, location]);

    return <>{children}</>;
};
