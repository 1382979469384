import React from 'react';
import { AreYouSureModal } from '../are-you-sure-modal';

export type DeleteRecipientModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    onDelete: VoidFunction;
};

export const DeleteRecipientModal = ({ triggerElement, onDelete }: DeleteRecipientModalProps) => (
    <AreYouSureModal
        triggerElement={triggerElement}
        title={'Remove recipient'}
        message={'Are you sure you want to remove this recipient?'}
        onClick={onDelete}
        buttonText={'Remove'}
    />
);
