import { Box, BoxProps, Center, Heading, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import * as Yup from 'yup';
import { ShadowBox } from '../../layout/cards';

export const paymentDateSchema = Yup.date()
    .typeError('Invalid date')
    .test('past-date', 'Date in the past', (date: Date | undefined) => date == undefined || date.getTime() > Date.now());

type Width60PercentProps = Partial<BoxProps>;

const Width60Percent: React.FC<Width60PercentProps> = ({ children, ...props }) => (
    <Box p="0" w={['100%', '80%', '60%']} {...props}>
        {children}
    </Box>
);

export default Width60Percent;

export const BatchFooter = ({ children }: { children: React.ReactNode }) => (
    <Center w="full" bg="white" pos="absolute" left={0} right={0} bottom={0} boxShadow={'0px -4px 4px rgba(0, 0, 0, 0.1)'} zIndex="1">
        <HStack w="container.xl" py={['4', '4', '6']} px={['8', '8', '16']}>
            {children}
        </HStack>
    </Center>
);

type BatchStepCardProps = {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
    underlyingBoxRef?: React.Ref<HTMLDivElement>;
};

export const BatchStepCard: React.FC<BatchStepCardProps & Partial<BoxProps>> = ({
    children,
    subtitle,
    title,
    underlyingBoxRef,
    ...props
}) => (
    <ShadowBox p="4" mb="8" w="full" {...props} underlyingBoxRef={underlyingBoxRef}>
        <Heading color="heading" fontSize={18}>
            {title}
        </Heading>
        {subtitle && (
            <Text color="heading" mt="1" fontSize={'14px'} fontWeight="500">
                {subtitle}
            </Text>
        )}
        {children}
    </ShadowBox>
);
