import { chakra } from '@chakra-ui/system';
import React from 'react';

function textContent(elem: React.ReactElement | React.ReactChild | string): string {
    if (!elem) return '';
    if (typeof elem === 'string') return elem;
    if (typeof elem === 'number') return elem.toString();
    const children = elem.props.children;
    if (children instanceof Array) return children.map(textContent).join('');
    return textContent(children);
}

export const HighlightedText = React.memo(({ children, search = '' }: { children: React.ReactChild | string; search?: string }) => {
    const reg = new RegExp(search, 'gi');
    const splitString = textContent(children)
        .toString()
        .replace(reg, str => '|[' + str + ']|')
        .split('|');

    const result = splitString.map((b, i) => {
        if (b.startsWith('[') && b.endsWith(']')) {
            return (
                <chakra.span color="gray.800" fontWeight="700" key={b + i}>
                    {b.replace(/\||\[|\]/g, '')}
                </chakra.span>
            );
        } else {
            return b;
        }
    });
    return <chakra.span>{result}</chakra.span>;
});
