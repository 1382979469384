import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Heading, Image, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import SignInIllustration from 'url:../../assets/sign_in.svg';
import { OrangeButton } from '../../components/inputs/buttons';
import { hasAuthenticationFailed, useAuth } from '../../state/auth-state';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../tools/common';

const SignInButton = ({ onClick, isLoading }: { onClick: VoidFunction; isLoading: boolean }) => (
    <OrangeButton onClick={onClick} isLoading={isLoading} px="16">
        Sign In
    </OrangeButton>
);

export const SignIn = ({ message, onSignIn }: { message?: string; onSignIn?: VoidFunction }) => {
    const { user, authenticate: _authenticate } = useAuth();
    const isError = hasAuthenticationFailed(user);

    const authenticate = async () => _authenticate().then(onSignIn);

    return (
        <Stack align={'center'} spacing={12}>
            <Box />
            <Heading size={'lg'} color={'heading'} textAlign="center">
                {message ?? 'Sign in to access your settings'}
            </Heading>
            <Image src={SignInIllustration} maxW="320px" />
            <SignInButton onClick={authenticate} isLoading={user == 'authenticating'} />
            {isError && !user.errorMessage.includes('denied message') && (
                <Stack>
                    <Text color={'red'}>Authentication failed</Text>
                    <Text color={'red'}>
                        Please reach out to{' '}
                        <Link href={BULLA_NETWORK_DISCORD_INVITE} isExternal fontSize={'inherit'} fontWeight={600}>
                            Support <ExternalLinkIcon boxSize={4} />
                        </Link>
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};
