import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import checkAnimation from '../../../../assets/check_svg.json';
import { Box, useBoolean, Text, SlideFade } from '@chakra-ui/react';

const COMPLETE_DELAY = 500;

export const TransactionImportComplete = ({ onComplete }: { onComplete: VoidFunction }) => {
    const [complete, setComplete] = useBoolean();

    useEffect(() => {
        if (complete) {
            const timeout = setTimeout(onComplete, COMPLETE_DELAY);
            return () => clearTimeout(timeout);
        }
    }, [complete]);

    return (
        <Box w="full" display="flex" alignItems="center" justifyContent={'center'} flexDir="column" position={'relative'}>
            <Lottie
                loop={false}
                animationData={checkAnimation}
                onComplete={setComplete.on}
                type="svg"
                style={{ transform: 'scale(.4)', position: 'absolute', top: '5vh', height: '320px' }}
            />
            <SlideFade in={complete} style={{ position: 'absolute', top: '27.5vh' }}>
                <Text fontSize="xl" color="#69BA44" fontWeight="600">
                    Transactions successfully uploaded
                </Text>
            </SlideFade>
        </Box>
    );
};
