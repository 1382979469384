import { Row } from '@gregoranders/csv';
import { utils } from 'ethers';
import { CsvParseError, ErrorFields, OverridenFields, ParsedContent } from './components/modals/import-batch-csv-modal';
import { isValidAddress } from './data-lib/ethereum';
import { fillToCount } from './tools/common';
import { Contact, GetContact, ContactValidator, isEmailValid } from './hooks/useExtendedContacts';

type OverridenField = keyof Contact;
type ContactOveriddenFields = OverridenFields<Contact>;
type ContactErrorFields = ErrorFields<Contact>;
type ErrorField = keyof Contact;

type ContactsCsvFileError = 'not-enough-rows' | 'not-enough-columns' | 'no-wallet-address' | 'not-enough-columns-with-email';
export type ContactsParseError = CsvParseError | ContactsCsvFileError;
type CsvColIndices = { contactName: number; walletAddress: number; emailAddress: number | 'none'; groups: number | 'none' };
type CsvFileInfo = { colIndices: CsvColIndices; hasHeaderRow: boolean };

const fieldNames = ['name', 'wallet', 'email', 'groups'];

export type ParsedContacts = ParsedContent<Contact>;

const isStringArray = (x: true | Contact | string[]): x is string[] => Array.isArray(x);

function getOverridenFields(current: Contact, next: Contact): ContactOveriddenFields {
    const overridenFields = [
        current.name !== next.name ? 'name' : undefined,
        current.emailAddress !== next.emailAddress ? 'emailAddress' : undefined,
    ].filter((x): x is OverridenField => x !== undefined);

    return overridenFields.length > 0
        ? {
              fields: overridenFields,
          }
        : {};
}

function tryParseContact(
    getContact: GetContact,
    row: Row,
    { isContactNameValid, canAddWalletAddressToContacts, isEmailValid }: ContactValidator,
    cols: CsvColIndices,
    tempAddedContacts: (Contact & ContactOveriddenFields)[],
): (Contact & ContactOveriddenFields) | (Contact & ContactErrorFields) {
    const name = row[cols.contactName];
    const walletAddress = row[cols.walletAddress];
    const emailAddress = cols.emailAddress === 'none' ? undefined : row[cols.emailAddress];
    const groups = cols.groups === 'none' ? undefined : row[cols.groups];
    const fields = [name, walletAddress, emailAddress, groups];

    const rulesWithoutEmail = [isContactNameValid(name), canAddWalletAddressToContacts(walletAddress, tempAddedContacts)];
    const allRules = emailAddress === undefined ? rulesWithoutEmail : [...rulesWithoutEmail, isEmailValid(emailAddress)];
    const errors = allRules.filter(isStringArray).flat();

    if (errors.length > 0) {
        const values = allRules.map((passOrFail, i) =>
            !isStringArray(passOrFail) ? fields[i] : passOrFail.reduce((acc, err) => `${acc} ${err}`, ''),
        );
        const errorFieldNamesOrUndefined = allRules.map((passOrFail, i) => (passOrFail == true ? undefined : fieldNames[i]));

        const errorFieldNames = fillToCount(errorFieldNamesOrUndefined, 3).filter((x): x is ErrorField => x !== undefined);
        return { name: values[0]!, walletAddress: values[1]!, emailAddress: values[2], groups: [], errorFields: errorFieldNames };
    } else {
        const newContact = {
            name,
            walletAddress: utils.getAddress(walletAddress),
            emailAddress,
            groups: groups ? groups.split(',').map(x => x.trim()) : [],
        };
        const currentContactOrNotFound = getContact(walletAddress);
        const overridenFields = currentContactOrNotFound != 'not-found' ? getOverridenFields(currentContactOrNotFound, newContact) : {};
        return { ...newContact, ...overridenFields };
    }
}

const isParseError = (object: any): object is Contact & ContactErrorFields => {
    const contact = object as Contact & ContactErrorFields;
    return contact?.name !== undefined && contact?.errorFields !== undefined;
};

function _readContactsFromRows(
    getContact: GetContact,
    validator: ContactValidator,
    cols: CsvColIndices,
    currentRowNumber: number,
    remaining: readonly Row[],
    successes: (Contact & ContactOveriddenFields)[],
    failures: (Contact & ContactErrorFields)[],
): ParsedContacts {
    const newRemaining = [...remaining];
    const currentRow = newRemaining.pop();

    if (currentRow === undefined) {
        return { successes, failures };
    }

    const parseResult = tryParseContact(getContact, currentRow, validator, cols, successes);
    console.debug('Parse result', parseResult);

    return _readContactsFromRows(
        getContact,
        validator,
        cols,
        currentRowNumber + 1,
        newRemaining,
        isParseError(parseResult) ? successes : [...successes, parseResult],
        isParseError(parseResult) ? [...failures, parseResult] : failures,
    );
}

function readAllContacts(getContact: GetContact, validator: ContactValidator, cols: CsvColIndices, rows: readonly Row[]) {
    return _readContactsFromRows(getContact, validator, cols, 1, [...rows].reverse(), [], []);
}

function findWalletAddressCol(row: Row) {
    return row.findIndex(str => isValidAddress(str));
}

function areContactsCsvRowsValid(rows: readonly Row[]): CsvFileInfo | ContactsCsvFileError {
    if (rows.length == 0) return 'not-enough-rows';

    const hasHeaderRow = findWalletAddressCol(rows[0]) === -1;
    const firstRow = hasHeaderRow ? 1 : 0;
    if (rows.length < firstRow) return 'not-enough-rows';

    const numOfCols = rows[0].length;
    if (numOfCols < 2) return 'not-enough-columns';

    const walletAddressColIndex = findWalletAddressCol(rows[firstRow]);
    if (walletAddressColIndex == -1) return 'no-wallet-address';

    const emailAddressColIndex = rows[firstRow].findIndex(str => str !== '' && isEmailValid(str) === true);
    if (emailAddressColIndex !== -1 && numOfCols == 2) return 'not-enough-columns-with-email';

    const groupsColIndex = numOfCols > 3 ? 3 : 'none';

    const usedColIndices = [walletAddressColIndex, emailAddressColIndex].filter(x => x >= 0).sort();
    const nextSmallestAvailableColIndexForContactName = [...Array(usedColIndices.length + 1).keys()].findIndex(
        x => !usedColIndices.includes(x),
    );

    const colIndices: CsvColIndices = {
        contactName: nextSmallestAvailableColIndexForContactName,
        walletAddress: walletAddressColIndex,
        emailAddress: emailAddressColIndex == -1 ? 'none' : emailAddressColIndex,
        groups: groupsColIndex,
    };

    return {
        colIndices,
        hasHeaderRow,
    };
}

export function parseContactsFromCsvRows(
    rows: readonly Row[],
    getContact: GetContact,
    contactValidator: ContactValidator,
): ParsedContacts | ContactsCsvFileError {
    const csvInfo = areContactsCsvRowsValid(rows);
    return typeof csvInfo === 'string'
        ? csvInfo
        : readAllContacts(getContact, contactValidator, csvInfo.colIndices, csvInfo.hasHeaderRow ? rows.slice(1) : rows);
}
