{
    "id": "/wholeNumberSchema",
    "anyOf": [
        {
            "type": "string",
            "pattern": "^\\d+$"
        },
        {
            "type": "integer"
        }
    ]
}
