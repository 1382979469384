{
    "id": "/exchangeProxyMetaTransactionSchema",
    "properties": {
        "signer": { "$ref": "/addressSchema" },
        "sender": { "$ref": "/addressSchema" },
        "minGasPrice": { "$ref": "/wholeNumberSchema" },
        "maxGasPrice": { "$ref": "/wholeNumberSchema" },
        "expirationTimeSeconds": { "$ref": "/wholeNumberSchema" },
        "salt": { "$ref": "/wholeNumberSchema" },
        "callData": { "$ref": "/hexSchema" },
        "value": { "$ref": "/wholeNumberSchema" },
        "feeToken": { "$ref": "/addressSchema" },
        "feeAmount": { "$ref": "/wholeNumberSchema" },
        "domain": { "$ref": "/eip712DomainSchema" }
    },
    "required": [
        "signer",
        "sender",
        "minGasPrice",
        "maxGasPrice",
        "expirationTimeSeconds",
        "salt",
        "callData",
        "value",
        "feeToken",
        "feeAmount",
        "domain"
    ],
    "type": "object"
}
