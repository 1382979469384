import { Container, Grid, GridItem, HStack, Box, Spacer, Text } from '@chakra-ui/react';
import { Trash } from 'phosphor-react';
import React from 'react';
import { ContinueButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import { DeleteDraftModal } from './batch-exit-modal';
import { StartFromNewOrDraftState, BatchWizardState } from './batch-state';
import { BatchStepCard, BatchFooter } from './shared-components';

const parseFalseDateThatIsActuallyAString = (dateString: Date) => new Date(dateString as unknown as string);

type StartDraftOrNewCardProps = {
    state: StartFromNewOrDraftState;
    setSelectedDraftOrNew: (selected: 'create-new' | string) => void;
    onDelete: (deleted: string) => void;
    setWizardState: React.Dispatch<React.SetStateAction<BatchWizardState>>;
    initialNewBatchCreationState: BatchWizardState;
};

export const StartDraftOrNewCard = ({
    state,
    setSelectedDraftOrNew,
    onDelete,
    setWizardState,
    initialNewBatchCreationState,
}: StartDraftOrNewCardProps) => (
    <Container maxW="container.xl" p="0">
        <BatchStepCard title={'Start from draft or create new'}>
            <Grid pt={8} templateColumns={['1fr', '1fr', '1fr 1fr']} gap={3}>
                <GridItem key="new">
                    <ShadowBox
                        h="69px"
                        _hover={{ cursor: 'pointer' }}
                        border={state.selectedItem === 'create-new' ? '1px solid orange' : 'inherit'}
                        onClick={() => setSelectedDraftOrNew('create-new')}
                    >
                        <Text fontWeight={700} color="black" w="fit-content" mx="auto" py="3">
                            CREATE NEW
                        </Text>
                    </ShadowBox>
                </GridItem>
                {state.drafts.map((draft, i) => (
                    <GridItem key={i}>
                        <ShadowBox h="69px" border={state.selectedItem === draft.batchId ? '1px solid orange' : 'inherit'}>
                            <HStack>
                                <Grid
                                    templateColumns={'2fr 1fr 0.75fr'}
                                    flex="4"
                                    onClick={() => setSelectedDraftOrNew(draft.batchId)}
                                    _hover={{ cursor: 'pointer' }}
                                >
                                    <GridItem key="descriptionTitle">
                                        <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                            DESCRIPTION
                                        </Text>
                                    </GridItem>
                                    <GridItem key="savedOnTitle">
                                        <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                            Saved On
                                        </Text>
                                    </GridItem>
                                    <GridItem key="recipientsTitle">
                                        <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                            Recipients
                                        </Text>
                                    </GridItem>
                                    <GridItem key="description">
                                        <Text>{draft.defaultValues?.defaultDescription}</Text>
                                    </GridItem>
                                    <GridItem key="savedOn">
                                        <Text>{draft.createdOn}</Text>
                                    </GridItem>
                                    <GridItem key="recipients">
                                        <Text>{draft.claimDetails?.length ?? 0}</Text>
                                    </GridItem>
                                </Grid>
                                <DeleteDraftModal
                                    triggerElement={onClick => (
                                        <Box py="4" color={'red'} _hover={{ cursor: 'pointer' }} onClick={onClick}>
                                            <Trash size="20px" weight="bold" />
                                        </Box>
                                    )}
                                    onDelete={() => onDelete(draft.batchId)}
                                />
                            </HStack>
                        </ShadowBox>
                    </GridItem>
                ))}
            </Grid>
        </BatchStepCard>

        <BatchFooter>
            <Spacer />
            <ContinueButton
                isDisabled={state.selectedItem === undefined}
                onClick={() => {
                    if (state.selectedItem === 'create-new') {
                        setWizardState(initialNewBatchCreationState);
                    } else {
                        const selectedDraft = state.drafts.find(x => x.batchId === state.selectedItem);
                        selectedDraft &&
                            setWizardState({
                                ...selectedDraft,
                                defaultValues: {
                                    ...selectedDraft.defaultValues!,
                                    defaultDueDate: !!selectedDraft.defaultValues!.defaultDueDate
                                        ? (() => {
                                              const parsedDate = parseFalseDateThatIsActuallyAString(
                                                  selectedDraft.defaultValues!.defaultDueDate,
                                              );
                                              return parsedDate < new Date() ? new Date() : parsedDate;
                                          })()
                                        : undefined,
                                    //this below is an upgrade path for old batches that did not have multiple tags
                                    defaultAccountTags: !!selectedDraft.defaultValues?.defaultAccountTags
                                        ? selectedDraft.defaultValues.defaultAccountTags
                                        : [
                                              (selectedDraft.defaultValues as unknown as { defaultAccountTag: string | undefined })
                                                  ?.defaultAccountTag,
                                          ].filter((x): x is string => x !== undefined && x !== ''),
                                },
                                claimDetails: selectedDraft.claimDetails!.map(item => {
                                    const claimWithDate = item as { dueDate?: string };
                                    const oldTag = (item as unknown as { tag: string | undefined }).tag;
                                    const tags = !!item.tags ? item.tags! : !!oldTag ? [oldTag] : undefined;

                                    const itemWithTags = { ...item, tags };
                                    return claimWithDate.dueDate !== undefined
                                        ? { ...itemWithTags, dueDate: new Date(claimWithDate.dueDate!) }
                                        : itemWithTags;
                                }),
                            });
                    }
                }}
            />
        </BatchFooter>
    </Container>
);
