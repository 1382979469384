import { constants } from 'ethers';
import { DetectedExternalTxType, ExternalTransaction } from '../data-lib/dto/external-transactions-dto';
import { EthAddress, addressEquality } from '../data-lib/ethereum';

export function getDetectedType({ functionName, erc20TransferEvents }: ExternalTransaction, userAddress: EthAddress) {
    if (functionName.includes('swap') && erc20TransferEvents.length > 0) return 'Swap'; // 🦄
    if (['stake', 'addLiquidity', 'deposit'].some(s => !!functionName && functionName.includes(s))) return 'Stake'; //🏦
    if (
        erc20TransferEvents.length == 1 &&
        erc20TransferEvents.some(
            ({ transfer: { from, to } }) => addressEquality(from, constants.AddressZero) && addressEquality(to, userAddress),
        )
    )
        return 'Mint'; // 💰
    if (erc20TransferEvents.length > 1 && erc20TransferEvents.every(({ transfer: { from } }) => addressEquality(from, userAddress)))
        return 'Batch Transfer'; // 📋

    // transfers
    if (erc20TransferEvents.length == 1 && addressEquality(erc20TransferEvents[0].transfer.from, userAddress)) return 'Tokens Sent'; // 📤
    if (erc20TransferEvents.length == 1 && addressEquality(erc20TransferEvents[0].transfer.to, userAddress)) return 'Tokens Received'; // 📥

    return 'Transfer'; // 💸
}

export const getEmojiForDetectedType = (detectedType: DetectedExternalTxType | string) => {
    switch (detectedType) {
        case 'Swap':
            return `🦄 ${detectedType}`;
        case 'Stake':
            return `🏦 ${detectedType}`;
        case 'Mint':
            return `💰 ${detectedType}`;
        case 'Batch Transfer':
            return `📋 ${detectedType}`;
        case 'Tokens Sent':
            return `📤 ${detectedType}`;
        case 'Tokens Received':
            return `📥 ${detectedType}`;
        case 'Transfer':
            return `💸 ${detectedType}`;
        default:
            return detectedType;
    }
};
