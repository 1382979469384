import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, HStack, IconButton, Image, Link } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React from 'react';
import UnknownTokenLogo from '../assets/unknownToken.svg';
import { WarningIconWithTooltip } from '../assets/warning';
import { GetTokenPrice } from '../hooks/useChainData';
import { OffchainInvoiceInfo } from '../hooks/useOffchainInvoiceFactory';
import { useTokenSafety } from '../hooks/useTokenSafety';
import { ClaimInfo } from './data-model';
import { addressEquality, EthAddress, weiToDisplayAmt } from './ethereum';
import { ZERO_BIGNUMBER } from './helpers';
import { ChainId, NETWORKS, TokenDto, TokenInfo, TokenVariant } from './networks';

export type KnownTokenVariant = Exclude<TokenVariant, TokenVariant.UNKNOWN>;

export type TokenPricesByVariant = Record<KnownTokenVariant, number | undefined>;
export type TokenPricesBySymbol = Record<string, number | undefined>;

export type TokenPrices = { [tokenSymbol: string]: number | undefined };
export type TokenBalances = { getBalanceForToken: (tokenAddress: string) => number | undefined; nonce: number };

export const TOKEN_ROUNDING: Record<TokenVariant, number> = {
    [TokenVariant.BTC]: 6,
    [TokenVariant.DAI]: 3,
    [TokenVariant.ETH]: 5,
    [TokenVariant.MATIC]: 3,
    [TokenVariant.STABLE]: 3,
    [TokenVariant.BRZ]: 3,
    [TokenVariant.SOV]: 3,
    [TokenVariant.ONE]: 4,
    [TokenVariant.EUR]: 3,
    [TokenVariant.USDC]: 3,
    [TokenVariant.USDT]: 3,
    [TokenVariant.AVAX]: 4,
    [TokenVariant.FUSE]: 3,
    [TokenVariant.GLMR]: 3,
    [TokenVariant.CELO]: 4,
    [TokenVariant.UNKNOWN]: 4,
};

export const unknownToken = (chainId: ChainId) => ({
    variant: TokenVariant.UNKNOWN,
    token: new TokenDto('', 18, 'UNKNOWN'),
    icon: UnknownTokenLogo,
    chainId,
});

export const buildExternalTxToken = (
    chainId: ChainId,
    address: EthAddress,
    decimals: number,
    symbol: string,
    name?: string,
): TokenInfo => ({
    variant: TokenVariant.UNKNOWN,
    token: new TokenDto(address, decimals, symbol, name),
    icon: UnknownTokenLogo,
    chainId,
});

export const ExternalLinkIconButton = ({ token }: { token: TokenInfo }) => (
    <IconButton
        as={Link}
        href={`${NETWORKS[token.chainId].blockExplorer}token/${token.token.address}`}
        isExternal
        icon={<ExternalLinkIcon />}
        aria-label="Open in explorer"
        size="l"
        variant="ghost"
        onClick={e => {
            e.stopPropagation();
        }}
        _hover={{ bg: 'light-gray' }}
    />
);

export const TokenDisplay = ({ token }: { token: TokenInfo }) => {
    const { isTokenInfoSafe } = useTokenSafety();

    return (
        <HStack spacing="2">
            <Image src={token.icon ?? UnknownTokenLogo} w={6} />
            <Box color="black" isTruncated>
                {token.token.symbol}
            </Box>
            {!token.token.isNative && <ExternalLinkIconButton token={token} />}
            {isTokenInfoSafe(token) && (
                <WarningIconWithTooltip
                    label="You have sent/received this token in the past. Beware of using airdropped scam tokens."
                    warningOverrides={{ color: 'brand.bulla_yellow', w: '14px', h: '14px' }}
                />
            )}
        </HStack>
    );
};

/**
 * Takes the token address and uses the USDC/{TOKEN} pool on uniswapV2 and calculates an exchange price.
 * Uses the variant enum to find the equivalent token price on mainnet.
 */

const stablecoinTokenVariants = [TokenVariant.USDC, TokenVariant.DAI, TokenVariant.USDT, TokenVariant.STABLE];

export const isStablecoin = (tokenVariant: TokenVariant) => stablecoinTokenVariants.includes(tokenVariant);

export const getTokenInfoFromTokenList = (address: EthAddress, supportedTokens: TokenInfo[]) =>
    supportedTokens.find(({ token }) => addressEquality(token.address, address));

export const tokenAmountToUSD = ({ amount, tokenInfo }: { amount: BigNumber; tokenInfo: TokenInfo }, getTokenPrice: GetTokenPrice) => {
    if (tokenInfo.variant === TokenVariant.UNKNOWN) return 0;

    const displayAmount = weiToDisplayAmt({ amountWei: amount, token: tokenInfo.token });
    return (getTokenPrice(tokenInfo) ?? 0) * displayAmount;
};

export const pendingClaimAmountUSD = (items: (ClaimInfo | OffchainInvoiceInfo)[], getTokenPrice: GetTokenPrice) =>
    items.reduce((acc, item) => {
        const amtPending = item.claimStatus === 'Pending' ? item.claimAmount.sub(item.paidAmount) : ZERO_BIGNUMBER; // amount off the claim in wei
        return acc + tokenAmountToUSD({ amount: amtPending, tokenInfo: item.tokenInfo }, getTokenPrice);
    }, 0);
