import { ButtonGroup, Button } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { FactoringCashHistory, FactoringPriceHistory } from '../../hooks/useBullaFactoring';
import { CashDataPoint } from './cash-chart';

export type PriceDataPoint = { date: Date; price: number };
export type ChartDataPoint = { date: Date; price: number };
export type TimeRange = '1W' | '1M' | '1Y';

export function mapTimestampAndCashHistory(historicalCash: FactoringCashHistory[], decimals: number): CashDataPoint[] {
    return historicalCash.map(price => ({
        date: new Date(price.timestamp * 1000),
        fundBalance: Number(formatUnits(BigNumber.from(price.fundBalance), decimals)),
        capitalAccount: Number(formatUnits(BigNumber.from(price.capitalAccount), decimals)),
        deployedCapital: Number(formatUnits(BigNumber.from(price.deployedCapital), decimals)),
    }));
}

export function mapTimestampAndPrice(historicalPrice: FactoringPriceHistory[], decimals: number): PriceDataPoint[] {
    return historicalPrice.map(price => ({
        date: new Date(price.timestamp * 1000),
        price: Number(formatUnits(BigNumber.from(price.price), decimals)),
    }));
}

const getCutoffDate = (range: TimeRange): Date => {
    const now = new Date();
    const cutoffDate = new Date(now);
    switch (range) {
        case '1W':
            cutoffDate.setDate(now.getDate() - 7);
            break;
        case '1M':
            if (now.getMonth() === 0) {
                cutoffDate.setMonth(11);
                cutoffDate.setFullYear(now.getFullYear() - 1);
            } else {
                cutoffDate.setMonth(now.getMonth() - 1);
            }
            break;
        case '1Y':
            cutoffDate.setFullYear(now.getFullYear() - 1);
            break;
    }
    return cutoffDate;
};

const filterDataByTimeRange = <T extends { date: Date }>(data: T[], range: TimeRange): T[] => {
    if (data.length === 0) return [];

    const cutoffDate = getCutoffDate(range);
    const filteredData = data.filter(item => item.date >= cutoffDate);

    // If no data points fall within the range, we include the latest available data point
    if (filteredData.length === 0) {
        const latestDataPoint = data[data.length - 1];
        return [latestDataPoint];
    }

    return filteredData;
};

export const filterCashDataByTimeRange = (data: CashDataPoint[], range: TimeRange): CashDataPoint[] => {
    return filterDataByTimeRange(data, range);
};

export const filterPriceDataByTimeRange = (data: PriceDataPoint[], range: TimeRange): PriceDataPoint[] => {
    return filterDataByTimeRange(data, range);
};

export const calculatePriceChange = (data: { date: Date; price: number }[]): number | null => {
    if (data.length < 2) return null;
    const oldestPrice = data[0].price;
    const latestPrice = data[data.length - 1].price;
    return ((latestPrice - oldestPrice) / oldestPrice) * 100;
};

export function calculateTodayReturn(chartData: ChartDataPoint[], currentPrice: number | null): { absolute: number; percentage: number } {
    if (chartData.length < 2 || currentPrice === null) {
        return { absolute: 0, percentage: 0 };
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const yesterdayData = chartData
        .filter(data => data.date >= yesterday && data.date < today)
        .sort((a, b) => b.date.getTime() - a.date.getTime())[0];

    if (!yesterdayData) {
        return { absolute: 0, percentage: 0 };
    }

    const yesterdayPrice = yesterdayData.price;
    const absoluteReturn = currentPrice - yesterdayPrice;
    const percentageReturn = (absoluteReturn / yesterdayPrice) * 100;

    return {
        absolute: absoluteReturn,
        percentage: percentageReturn,
    };
}

export function calculateTotalReturn(
    averageCost: number | null,
    currentPrice: number | null,
    currentTokenBalance: string,
): { absolute: number; percentage: number } {
    if (!averageCost || !currentPrice || !currentTokenBalance) {
        return { absolute: 0, percentage: 0 };
    }

    const totalValue = Number(currentTokenBalance) * currentPrice;
    const totalCost = Number(currentTokenBalance) * averageCost;
    const absoluteReturn = totalValue - totalCost;
    const percentageReturn = (absoluteReturn / totalCost) * 100;

    return {
        absolute: absoluteReturn,
        percentage: percentageReturn,
    };
}

interface TimeRangeSelectorProps {
    timeRange: TimeRange;
    setTimeRange: (range: TimeRange) => void;
}

export const TimeRangeSelector: React.FC<TimeRangeSelectorProps> = ({ timeRange, setTimeRange }) => (
    <ButtonGroup size="md" isAttached variant="outline" mr="4">
        {['1W', '1M', '1Y'].map(range => (
            <Button key={range} onClick={() => setTimeRange(range as TimeRange)} bg={timeRange === range ? 'gray.200' : 'transparent'}>
                {range}
            </Button>
        ))}
    </ButtonGroup>
);

export const getTickFormatter = (range: TimeRange) => {
    switch (range) {
        case '1W':
        case '1M':
            return (date: number) => new Date(date).toLocaleString('en-US', { day: 'numeric', month: 'short' });

        case '1Y':
            return (date: number) => new Date(date).toLocaleString('en-US', { month: 'short', year: 'numeric' });
    }
};
