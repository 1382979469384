import moment from 'moment';
import Excel from 'exceljs';
import { exportCsv } from './csvExport';

interface ExcelSheet {
    title: string;
    columns: {
        header: string;
        key: string;
        width?: number;
    }[];
    rows: (string | number | Date)[][];
}

export const exportExcel = async (label: string, sheets: ExcelSheet[]) => {
    const workbook = new Excel.Workbook();
    sheets.forEach(sheet => {
        const worksheet = workbook.addWorksheet(sheet.title);
        worksheet.columns = sheet.columns;
        if (sheet.rows.length > 0) sheet.rows.map(row => worksheet.addRow(row));
    });
    const data = await workbook.xlsx.writeBuffer();

    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    // Create a link to download it
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Bulla-Export-${label ? label.toUpperCase() + '-' : ''}${moment().format('DD-MMM-YYYY-HH-mm-SS')}.xlsx`);
    link.click();
};

export type ExportFileFormat = 'excel' | 'csv';

export const handleExport = async (method: ExportFileFormat, title: string, headers: string[], dataRows: any[][]) => {
    switch (method) {
        case 'excel':
            await exportExcel(title, [
                {
                    title,
                    columns: headers.map(header => {
                        return {
                            header,
                            key: header,
                            width: 15,
                        };
                    }),
                    rows: dataRows,
                },
            ]);
            break;
        case 'csv':
            await exportCsv(title, headers, dataRows);
            break;
        default:
            console.error(`Unsupported export method: ${method}`);
            break;
    }
};

export function toReportDateTimeString(date: Date): string {
    return date.toISOString().slice(0, 19).replace('T', ' ');
}
