import { Log } from '@ethersproject/abstract-provider';
import { BytesLike } from 'ethers';
import { TransactionDescription } from 'ethers/lib/utils';
import {
    IBullaBanker,
    IBullaClaimERC721,
    IBullaFinance,
    IBullaInstantPay,
    IBullaManagerInterface,
    IERC20,
    IERC721,
    IFrendLend,
    IPoster,
    I_IBullaClaim,
    I_IERC721,
    IBullaFactoring,
} from './contract-interfaces';

const interfaces = [
    IBullaClaimERC721,
    IERC721,
    I_IBullaClaim,
    IBullaBanker,
    IBullaManagerInterface,
    IPoster,
    IERC20,
    I_IERC721,
    IBullaInstantPay,
    IBullaFinance,
    IFrendLend,
    IBullaFactoring,
];

type UnparsedLog = {
    __type: 'log';
    log: Log;
};

type UnparsedTransaction = {
    __type: 'transaction';
    data: string;
};

type UnparsedError = {
    __type: 'error';
    error: BytesLike;
};

export const parseRaw = (unparsed: UnparsedLog | UnparsedTransaction | UnparsedError) => {
    for (let iface of interfaces) {
        try {
            switch (unparsed.__type) {
                case 'log':
                    return iface.parseLog(unparsed.log);
                case 'transaction':
                    return iface.parseTransaction({ data: unparsed.data });
                case 'error':
                    return iface.parseError(unparsed.error);
            }
        } catch (e: any) {}
    }
};

export const parseTxLogs = (logs: Log[]) => logs.map(log => parseRaw({ __type: 'log', log }));

/**
 *
 * @param txData transaction data in raw bytes
 * @returns parse transaction data if parsed against our contract ABIs
 */
export const parseTransactions = (txData: string[]) =>
    txData.map(data => parseRaw({ __type: 'transaction', data })).filter((tx): tx is TransactionDescription => !!tx);

export type EthersError = { error: { data: { originalError: { code: number; data: string; message: string } } } };

export const isEthersError = (ethersError: Partial<EthersError>) => ethersError?.error?.data?.originalError?.data !== undefined;

export const parseError = (ethersError: EthersError) => {
    try {
        return parseRaw({ __type: 'error', error: ethersError.error.data.originalError.data })?.name;
    } catch (e: any) {
        return undefined;
    }
};
