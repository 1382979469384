import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Checkbox, HStack, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React from 'react';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useTokenSafety } from '../../hooks/useTokenSafety';
import { filterButtonProps } from './views/filters/claim-filter';
import { filterMenuItemFontProps, filterMenuProps } from './views/filters/common';

type TokenRowProps = {
    symbol: string;
    isChecked: boolean;
    toggle: VoidFunction;
};

const TokenRow = ({ symbol, isChecked, toggle }: TokenRowProps) => (
    <HStack px="3" spacing={3} key={symbol}>
        <Checkbox isChecked={isChecked} onChange={toggle} size="lg" />
        <Text noOfLines={1} textOverflow="ellipsis">
            {symbol}
        </Text>
    </HStack>
);

type TokenMultiselectProps = {
    toggleTokenSymbol: (tokenSymbol: string) => void;
    selectedTokenSymbols: Set<string>;
    seletectableTokenSymbols?: Set<string>;
};
export const TokenMultiselect = ({ selectedTokenSymbols, toggleTokenSymbol, seletectableTokenSymbols }: TokenMultiselectProps) => {
    const { tokensByChainId, allTokensLength } = useTokenRepo();
    const { isTokenInfoSafe } = useTokenSafety();

    const allTokenSymbols = React.useMemo(() => {
        const tokens = Object.values(tokensByChainId)
            .flatMap(x => x)
            .sort();

        return seletectableTokenSymbols
            ? seletectableTokenSymbols
            : new Set(
                  [...tokens.filter(x => isTokenInfoSafe(x)), ...tokens.filter(x => !isTokenInfoSafe(x))].map(x =>
                      x.token.symbol.toUpperCase(),
                  ),
              );
    }, [allTokensLength, isTokenInfoSafe]);

    return (
        <Menu closeOnSelect={false} isLazy>
            <MenuButton rightIcon={<ChevronDownIcon />} as={Button} {...filterButtonProps}>
                Tokens
            </MenuButton>
            <MenuList {...filterMenuProps} overflowX="hidden">
                {[...allTokenSymbols].map((symbol, i) => {
                    return (
                        <MenuItem
                            key={i}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            {...filterMenuItemFontProps}
                        >
                            <TokenRow
                                isChecked={selectedTokenSymbols.has(symbol)}
                                symbol={symbol}
                                toggle={() => toggleTokenSymbol(symbol)}
                            />
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
};
