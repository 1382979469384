{
    "contractName": "GnosisSafe",
    "abi": [
      { "inputs": [], "payable": false, "stateMutability": "nonpayable", "type": "constructor" },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "address", "name": "owner", "type": "address" }],
        "name": "AddedOwner",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          { "indexed": true, "internalType": "bytes32", "name": "approvedHash", "type": "bytes32" },
          { "indexed": true, "internalType": "address", "name": "owner", "type": "address" }
        ],
        "name": "ApproveHash",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "address", "name": "masterCopy", "type": "address" }],
        "name": "ChangedMasterCopy",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "uint256", "name": "threshold", "type": "uint256" }],
        "name": "ChangedThreshold",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "contract Module", "name": "module", "type": "address" }],
        "name": "DisabledModule",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "contract Module", "name": "module", "type": "address" }],
        "name": "EnabledModule",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          { "indexed": false, "internalType": "bytes32", "name": "txHash", "type": "bytes32" },
          { "indexed": false, "internalType": "uint256", "name": "payment", "type": "uint256" }
        ],
        "name": "ExecutionFailure",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": true, "internalType": "address", "name": "module", "type": "address" }],
        "name": "ExecutionFromModuleFailure",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": true, "internalType": "address", "name": "module", "type": "address" }],
        "name": "ExecutionFromModuleSuccess",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          { "indexed": false, "internalType": "bytes32", "name": "txHash", "type": "bytes32" },
          { "indexed": false, "internalType": "uint256", "name": "payment", "type": "uint256" }
        ],
        "name": "ExecutionSuccess",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": false, "internalType": "address", "name": "owner", "type": "address" }],
        "name": "RemovedOwner",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [{ "indexed": true, "internalType": "bytes32", "name": "msgHash", "type": "bytes32" }],
        "name": "SignMsg",
        "type": "event"
      },
      { "payable": true, "stateMutability": "payable", "type": "fallback" },
      {
        "constant": true,
        "inputs": [],
        "name": "NAME",
        "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "VERSION",
        "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "owner", "type": "address" },
          { "internalType": "uint256", "name": "_threshold", "type": "uint256" }
        ],
        "name": "addOwnerWithThreshold",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          { "internalType": "address", "name": "", "type": "address" },
          { "internalType": "bytes32", "name": "", "type": "bytes32" }
        ],
        "name": "approvedHashes",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "address", "name": "_masterCopy", "type": "address" }],
        "name": "changeMasterCopy",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "uint256", "name": "_threshold", "type": "uint256" }],
        "name": "changeThreshold",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "contract Module", "name": "prevModule", "type": "address" },
          { "internalType": "contract Module", "name": "module", "type": "address" }
        ],
        "name": "disableModule",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "domainSeparator",
        "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "contract Module", "name": "module", "type": "address" }],
        "name": "enableModule",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" }
        ],
        "name": "execTransactionFromModule",
        "outputs": [{ "internalType": "bool", "name": "success", "type": "bool" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" }
        ],
        "name": "execTransactionFromModuleReturnData",
        "outputs": [
          { "internalType": "bool", "name": "success", "type": "bool" },
          { "internalType": "bytes", "name": "returnData", "type": "bytes" }
        ],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "getModules",
        "outputs": [{ "internalType": "address[]", "name": "", "type": "address[]" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          { "internalType": "address", "name": "start", "type": "address" },
          { "internalType": "uint256", "name": "pageSize", "type": "uint256" }
        ],
        "name": "getModulesPaginated",
        "outputs": [
          { "internalType": "address[]", "name": "array", "type": "address[]" },
          { "internalType": "address", "name": "next", "type": "address" }
        ],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "getOwners",
        "outputs": [{ "internalType": "address[]", "name": "", "type": "address[]" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "getThreshold",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }],
        "name": "isOwner",
        "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [],
        "name": "nonce",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "prevOwner", "type": "address" },
          { "internalType": "address", "name": "owner", "type": "address" },
          { "internalType": "uint256", "name": "_threshold", "type": "uint256" }
        ],
        "name": "removeOwner",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "address", "name": "handler", "type": "address" }],
        "name": "setFallbackHandler",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "name": "signedMessages",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "prevOwner", "type": "address" },
          { "internalType": "address", "name": "oldOwner", "type": "address" },
          { "internalType": "address", "name": "newOwner", "type": "address" }
        ],
        "name": "swapOwner",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address[]", "name": "_owners", "type": "address[]" },
          { "internalType": "uint256", "name": "_threshold", "type": "uint256" },
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "address", "name": "fallbackHandler", "type": "address" },
          { "internalType": "address", "name": "paymentToken", "type": "address" },
          { "internalType": "uint256", "name": "payment", "type": "uint256" },
          { "internalType": "address payable", "name": "paymentReceiver", "type": "address" }
        ],
        "name": "setup",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" },
          { "internalType": "uint256", "name": "safeTxGas", "type": "uint256" },
          { "internalType": "uint256", "name": "baseGas", "type": "uint256" },
          { "internalType": "uint256", "name": "gasPrice", "type": "uint256" },
          { "internalType": "address", "name": "gasToken", "type": "address" },
          { "internalType": "address payable", "name": "refundReceiver", "type": "address" },
          { "internalType": "bytes", "name": "signatures", "type": "bytes" }
        ],
        "name": "execTransaction",
        "outputs": [{ "internalType": "bool", "name": "success", "type": "bool" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" }
        ],
        "name": "requiredTxGas",
        "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "bytes32", "name": "hashToApprove", "type": "bytes32" }],
        "name": "approveHash",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [{ "internalType": "bytes", "name": "_data", "type": "bytes" }],
        "name": "signMessage",
        "outputs": [],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": false,
        "inputs": [
          { "internalType": "bytes", "name": "_data", "type": "bytes" },
          { "internalType": "bytes", "name": "_signature", "type": "bytes" }
        ],
        "name": "isValidSignature",
        "outputs": [{ "internalType": "bytes4", "name": "", "type": "bytes4" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [{ "internalType": "bytes", "name": "message", "type": "bytes" }],
        "name": "getMessageHash",
        "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" },
          { "internalType": "uint256", "name": "safeTxGas", "type": "uint256" },
          { "internalType": "uint256", "name": "baseGas", "type": "uint256" },
          { "internalType": "uint256", "name": "gasPrice", "type": "uint256" },
          { "internalType": "address", "name": "gasToken", "type": "address" },
          { "internalType": "address", "name": "refundReceiver", "type": "address" },
          { "internalType": "uint256", "name": "_nonce", "type": "uint256" }
        ],
        "name": "encodeTransactionData",
        "outputs": [{ "internalType": "bytes", "name": "", "type": "bytes" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      },
      {
        "constant": true,
        "inputs": [
          { "internalType": "address", "name": "to", "type": "address" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "bytes", "name": "data", "type": "bytes" },
          { "internalType": "enum Enum.Operation", "name": "operation", "type": "uint8" },
          { "internalType": "uint256", "name": "safeTxGas", "type": "uint256" },
          { "internalType": "uint256", "name": "baseGas", "type": "uint256" },
          { "internalType": "uint256", "name": "gasPrice", "type": "uint256" },
          { "internalType": "address", "name": "gasToken", "type": "address" },
          { "internalType": "address", "name": "refundReceiver", "type": "address" },
          { "internalType": "uint256", "name": "_nonce", "type": "uint256" }
        ],
        "name": "getTransactionHash",
        "outputs": [{ "internalType": "bytes32", "name": "", "type": "bytes32" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
      }
    ],
    "compiler": { "name": "solc", "version": "0.5.16+commit.9c3226ce.Emscripten.clang" },
    "networks": {
      "1": {
        "links": {},
        "events": {},
        "address": "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F",
        "updated_at": 1587391878775
      },
      "4": {
        "links": {},
        "events": {},
        "address": "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F",
        "updated_at": 1587391878875
      },
      "5": {
        "links": {},
        "events": {},
        "address": "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F",
        "updated_at": 1587391879067
      },
      "42": {
        "links": {},
        "events": {},
        "address": "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F",
        "updated_at": 1587391878968
      },
      "30": {
        "links": {},
        "events": {},
        "address": "0xe502b44b0f3981f883e03dfa1dff119753178b40",
        "updated_at": 1587391878968
      },
      "31": {
        "links": {},
        "events": {},
        "address": "0x42e36dd00c87ea15e3176d881d506d4bf6ab3300",
        "updated_at": 1587391878968
      }
    },
    "schemaVersion": "3.1.0",
    "updatedAt": "2020-04-20T14:11:17.641Z",
    "devdoc": {
      "author": "Stefan George - <stefan@gnosis.io>Richard Meissner - <richard@gnosis.io>Ricardo Guilherme Schmidt - (Status Research & Development GmbH) - Gas Token Payment",
      "methods": {
        "addOwnerWithThreshold(address,uint256)": {
          "details": "Allows to add a new owner to the Safe and update the threshold at the same time.      This can only be done via a Safe transaction.",
          "params": { "_threshold": "New threshold.", "owner": "New owner address." }
        },
        "approveHash(bytes32)": {
          "details": "Marks a hash as approved. This can be used to validate a hash that is used by a signature.",
          "params": {
            "hashToApprove": "The hash that should be marked as approved for signatures that are verified by this contract."
          }
        },
        "changeMasterCopy(address)": {
          "details": "Allows to upgrade the contract. This can only be done via a Safe transaction.",
          "params": { "_masterCopy": "New contract address." }
        },
        "changeThreshold(uint256)": {
          "details": "Allows to update the number of required confirmations by Safe owners.      This can only be done via a Safe transaction.",
          "params": { "_threshold": "New threshold." }
        },
        "disableModule(address,address)": {
          "details": "Allows to remove a module from the whitelist.      This can only be done via a Safe transaction.",
          "params": {
            "module": "Module to be removed.",
            "prevModule": "Module that pointed to the module to be removed in the linked list"
          }
        },
        "enableModule(address)": {
          "details": "Allows to add a module to the whitelist.      This can only be done via a Safe transaction.",
          "params": { "module": "Module to be whitelisted." }
        },
        "encodeTransactionData(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,uint256)": {
          "details": "Returns the bytes that are hashed to be signed by owners.",
          "params": {
            "_nonce": "Transaction nonce.",
            "baseGas": "Gas costs for data used to trigger the safe transaction.",
            "data": "Data payload.",
            "gasPrice": "Maximum gas price that should be used for this transaction.",
            "gasToken": "Token address (or 0 if ETH) that is used for the payment.",
            "operation": "Operation type.",
            "refundReceiver": "Address of receiver of gas payment (or 0 if tx.origin).",
            "safeTxGas": "Fas that should be used for the safe transaction.",
            "to": "Destination address.",
            "value": "Ether value."
          },
          "return": "Transaction hash bytes."
        },
        "execTransaction(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,bytes)": {
          "details": "Allows to execute a Safe transaction confirmed by required number of owners and then pays the account that submitted the transaction.      Note: The fees are always transfered, even if the user transaction fails.",
          "params": {
            "baseGas": "Gas costs for that are indipendent of the transaction execution(e.g. base transaction fee, signature check, payment of the refund)",
            "data": "Data payload of Safe transaction.",
            "gasPrice": "Gas price that should be used for the payment calculation.",
            "gasToken": "Token address (or 0 if ETH) that is used for the payment.",
            "operation": "Operation type of Safe transaction.",
            "refundReceiver": "Address of receiver of gas payment (or 0 if tx.origin).",
            "safeTxGas": "Gas that should be used for the Safe transaction.",
            "signatures": "Packed signature data ({bytes32 r}{bytes32 s}{uint8 v})",
            "to": "Destination address of Safe transaction.",
            "value": "Ether value of Safe transaction."
          }
        },
        "execTransactionFromModule(address,uint256,bytes,uint8)": {
          "details": "Allows a Module to execute a Safe transaction without any further confirmations.",
          "params": {
            "data": "Data payload of module transaction.",
            "operation": "Operation type of module transaction.",
            "to": "Destination address of module transaction.",
            "value": "Ether value of module transaction."
          }
        },
        "execTransactionFromModuleReturnData(address,uint256,bytes,uint8)": {
          "details": "Allows a Module to execute a Safe transaction without any further confirmations and return data",
          "params": {
            "data": "Data payload of module transaction.",
            "operation": "Operation type of module transaction.",
            "to": "Destination address of module transaction.",
            "value": "Ether value of module transaction."
          }
        },
        "getMessageHash(bytes)": {
          "details": "Returns hash of a message that can be signed by owners.",
          "params": { "message": "Message that should be hashed" },
          "return": "Message hash."
        },
        "getModules()": { "details": "Returns array of first 10 modules.", "return": "Array of modules." },
        "getModulesPaginated(address,uint256)": {
          "details": "Returns array of modules.",
          "params": { "pageSize": "Maximum number of modules that should be returned.", "start": "Start of the page." },
          "return": "Array of modules."
        },
        "getOwners()": { "details": "Returns array of owners.", "return": "Array of Safe owners." },
        "getTransactionHash(address,uint256,bytes,uint8,uint256,uint256,uint256,address,address,uint256)": {
          "details": "Returns hash to be signed by owners.",
          "params": {
            "_nonce": "Transaction nonce.",
            "baseGas": "Gas costs for data used to trigger the safe transaction.",
            "data": "Data payload.",
            "gasPrice": "Maximum gas price that should be used for this transaction.",
            "gasToken": "Token address (or 0 if ETH) that is used for the payment.",
            "operation": "Operation type.",
            "refundReceiver": "Address of receiver of gas payment (or 0 if tx.origin).",
            "safeTxGas": "Fas that should be used for the safe transaction.",
            "to": "Destination address.",
            "value": "Ether value."
          },
          "return": "Transaction hash."
        },
        "isValidSignature(bytes,bytes)": {
          "details": "Should return whether the signature provided is valid for the provided data.      The save does not implement the interface since `checkSignatures` is not a view method.      The method will not perform any state changes (see parameters of `checkSignatures`)",
          "params": {
            "_data": "Arbitrary length data signed on the behalf of address(this)",
            "_signature": "Signature byte array associated with _data"
          },
          "return": "a bool upon valid or invalid signature with corresponding _data"
        },
        "removeOwner(address,address,uint256)": {
          "details": "Allows to remove an owner from the Safe and update the threshold at the same time.      This can only be done via a Safe transaction.",
          "params": {
            "_threshold": "New threshold.",
            "owner": "Owner address to be removed.",
            "prevOwner": "Owner that pointed to the owner to be removed in the linked list"
          }
        },
        "requiredTxGas(address,uint256,bytes,uint8)": {
          "details": "Allows to estimate a Safe transaction.      This method is only meant for estimation purpose, therefore two different protection mechanism against execution in a transaction have been made:      1.) The method can only be called from the safe itself      2.) The response is returned with a revert      When estimating set `from` to the address of the safe.      Since the `estimateGas` function includes refunds, call this method to get an estimated of the costs that are deducted from the safe with `execTransaction`",
          "params": {
            "data": "Data payload of Safe transaction.",
            "operation": "Operation type of Safe transaction.",
            "to": "Destination address of Safe transaction.",
            "value": "Ether value of Safe transaction."
          },
          "return": "Estimate without refunds and overhead fees (base transaction and payload data gas costs)."
        },
        "setFallbackHandler(address)": {
          "details": "Allows to add a contract to handle fallback calls.      Only fallback calls without value and with data will be forwarded.      This can only be done via a Safe transaction.",
          "params": { "handler": "contract to handle fallbacks calls." }
        },
        "setup(address[],uint256,address,bytes,address,address,uint256,address)": {
          "details": "Setup function sets initial storage of contract.",
          "params": {
            "_owners": "List of Safe owners.",
            "_threshold": "Number of required confirmations for a Safe transaction.",
            "data": "Data payload for optional delegate call.",
            "fallbackHandler": "Handler for fallback calls to this contract",
            "payment": "Value that should be paid",
            "paymentReceiver": "Adddress that should receive the payment (or 0 if tx.origin)",
            "paymentToken": "Token that should be used for the payment (0 is ETH)",
            "to": "Contract address for optional delegate call."
          }
        },
        "signMessage(bytes)": {
          "details": "Marks a message as signed",
          "params": { "_data": "Arbitrary length data that should be marked as signed on the behalf of address(this)" }
        },
        "swapOwner(address,address,address)": {
          "details": "Allows to swap/replace an owner from the Safe with another address.      This can only be done via a Safe transaction.",
          "params": {
            "newOwner": "New owner address.",
            "oldOwner": "Owner address to be replaced.",
            "prevOwner": "Owner that pointed to the owner to be replaced in the linked list"
          }
        }
      },
      "title": "Gnosis Safe - A multisignature wallet with support for confirmations using signed messages based on ERC191."
    },
    "userdoc": {
      "methods": {
        "isValidSignature(bytes,bytes)": {
          "notice": "Implementation of ISignatureValidator (see `interfaces/ISignatureValidator.sol`)"
        }
      }
    }
  }
  