import { CopyIcon, InfoIcon } from '@chakra-ui/icons';
import { Collapse, Divider, Fade, Flex, HStack, Icon, Skeleton, Spacer, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactNameOrAddress } from '../../components/base/address-label';
import CopyElement from '../../components/base/copy-element';
import { ChainSymbol } from '../../components/chain-symbol';
import { TokenAmount } from '../../components/currency/token-display-amount';
import { getEmojiForDetectedType } from '../../components/detected-type';
import { PopoverAction } from '../../components/display/actionPopover';
import { PageSelector } from '../../components/display/claim-table';
import { AccountTagViewer } from '../../components/inputs/account-tag-input';
import { LinkButton, ViewDetailsButton } from '../../components/inputs/buttons';
import { ListItemProps, ListViewCard, TwoLineTextWithLabel } from '../../components/layout/cards';
import { CreateClaimModal } from '../../components/modals/create-claim-modal/create-claim-modal';
import { AccountTagInfo, BullaLineItems, ClaimInfo } from '../../data-lib/data-model';
import { addressEquality } from '../../data-lib/ethereum';
import { getPendingGnosisLineItems } from '../../data-lib/gnosis-tools/gnosis';
import { isFactored, sortDesc } from '../../data-lib/helpers';
import { useTokenPrices } from '../../hooks/useChainData';
import { useOpenBullaItem } from '../../hooks/useClaimDetailDisclosure';
import { useIsMobile } from '../../hooks/useIsMobile';
import { usePagination } from '../../hooks/usePagination';
import { useGlobalUserData } from '../../hooks/useUserData';
import { useUserSummary } from '../../hooks/useUserSummary';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useGnosisSafe } from '../../state/gnosis-state';
import { quickHash, toDateDisplay, toUSD } from '../../tools/common';

export const AccountTags = () => {
    const navigate = useNavigate();
    const tokenPrices = useTokenPrices();
    const { accountTags } = useGlobalUserData('exclude-originating-claims');
    const { accountTagSummary } = useUserSummary();
    const isMobile = useIsMobile();
    const isLoading = isEmpty(tokenPrices);

    const accountTagItems = useMemo(
        () =>
            accountTags.reduce((listItems: ListItemProps[], { name }: AccountTagInfo) => {
                const { payable, receivable, netBalance } = accountTagSummary[name] ?? { payable: 0, receivable: 0, netBalance: 0 };

                const columnValues = [
                    <Flex>
                        <Text textStyle="link" whiteSpace="nowrap" onClick={() => navigate(`/category/${name}`)} as="button">
                            {getEmojiForDetectedType(name)}
                        </Text>
                        <CopyElement valueToCopy={name}>
                            <CopyIcon />
                        </CopyElement>
                    </Flex>,
                    ...(isMobile
                        ? []
                        : [
                              <Skeleton isLoaded={!isLoading}>
                                  <Text>{toUSD(payable)}</Text>
                              </Skeleton>,
                              <Skeleton isLoaded={!isLoading}>
                                  <Text>{toUSD(receivable)}</Text>
                              </Skeleton>,
                          ]),
                    <Skeleton isLoaded={!isLoading}>
                        <Text>{toUSD(netBalance)}</Text>
                    </Skeleton>,
                ];

                const actions = [
                    <CreateClaimModal
                        defaults={{ tags: [name] }}
                        claimType="Invoice"
                        triggerElement={onOpen => <PopoverAction onClick={onOpen}>Create Invoice</PopoverAction>}
                    />,
                    <CreateClaimModal
                        defaults={{ tags: [name] }}
                        claimType="Payment"
                        triggerElement={onOpen => <PopoverAction onClick={onOpen}>Create Payment</PopoverAction>}
                    />,
                    <Divider my="3" />,
                    <PopoverAction onClick={() => navigate(`/category/${name}`)}>View Details</PopoverAction>,
                ];

                return [...listItems, { columnValues, actions }];
            }, []),
        [quickHash(accountTagSummary), isLoading, isMobile],
    );

    return (
        <Fade unmountOnExit in={accountTags.length > 0}>
            <ListViewCard
                titleElement={<Text textStyle="listTitle">Categories</Text>}
                headers={['category', ...(isMobile ? [] : ['payable', 'receivable']), 'net balance']}
                displayedListItems={accountTagItems}
                mt="12"
            />
        </Fade>
    );
};

export const PendingClaims = ({
    items,
    title,
    emptyMessage,
    walletHeader,
    withPagination,
}: {
    items: BullaLineItems[];
    title?: React.ReactNode;
    emptyMessage?: string;
    walletHeader: string;
    withPagination?: boolean;
}) => {
    const userAddress = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const isMobile = useIsMobile();
    const pendingClaims = items
        .filter(item => (item.__type === 'Claim' ? item.claimStatus === 'Pending' : item.__type === 'PendingInstantPayment' ? true : false))
        .sort((a, b) => sortDesc(a.created.getTime(), b.created.getTime()));
    const { shownItems, ...pageSelectorProps } = usePagination(pendingClaims, 5);
    const pendingClaimsSliced = pendingClaims.slice(0, 5);
    const itemsToMemo = withPagination ? shownItems : pendingClaimsSliced;

    const pendingClaimsList = useMemo(
        () =>
            itemsToMemo.reduce<ListItemProps[]>((listItems, item) => {
                const isClaim = item.__type === 'Claim';
                const { creditor, debtor, description, tags, tokenInfo: token, id, chainId } = item;
                const hasTags = tags.length !== 0;
                const walletToShow = addressEquality(userAddress, creditor) ? debtor : creditor;
                const dueDate = isClaim ? toDateDisplay(item.dueBy) : '';
                const amount = isClaim ? item.claimAmount : item.paidAmount;

                const columnValues = [
                    <Text>{dueDate}</Text>,
                    ...(isMobile
                        ? []
                        : [
                              <ChainSymbol chainId={chainId} />,
                              <ContactNameOrAddress chainId={chainId}>{walletToShow}</ContactNameOrAddress>,
                              hasTags ? (
                                  <AccountTagViewer
                                      tags={tags}
                                      removeTag={() => {}}
                                      addTag={() => {}}
                                      creatingExpense={false}
                                      setEditing={{
                                          on: () => {},
                                          off: () => {},
                                      }}
                                      isDisabled={false}
                                      fieldName={'tags'}
                                      editState={'viewing only'}
                                      maxTagsDisplayed={1}
                                  />
                              ) : (
                                  <Text color={'gray.400'}>No category</Text>
                              ),
                              <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                          ]),

                    <HStack w="100%">
                        <TokenAmount amount={amount} tokenInfo={token} />
                        <Spacer />
                        <ViewDetailsButton onClick={() => openItem(item)} />
                    </HStack>,
                ];
                return [...listItems, { columnValues }];
            }, []),
        [JSON.stringify(itemsToMemo), isMobile],
    );

    const dateHeader = isMobile ? 'date' : 'due date';
    return (
        <>
            <ListViewCard
                titleElement={title}
                headers={[dateHeader, ...(isMobile ? [] : ['chain', walletHeader, 'category', 'description']), 'amount']}
                displayedListItems={pendingClaimsList}
                emptyMessage={<Text textStyle="faint">{emptyMessage}</Text>}
                mt="12"
                //{...(isMobile ? { columnsCss: '0.8fr 1fr' } : {})}
            />
            {withPagination && <PageSelector {...pageSelectorProps} mt="5" />}
        </>
    );
};

export const ReceivedPayments = () => {
    const openItem = useOpenBullaItem();
    const { receivedBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const isMobile = useIsMobile();
    const actingWallet = useActingWalletAddress();

    const sortedReceivables = receivedBullaItemsWithPayments.slice(0, 5);

    const paymentsReceivedList = useMemo(
        () =>
            sortedReceivables.reduce<ListItemProps[]>((listItems, receivable) => {
                const { debtor, creditor, description, tags, tokenInfo, chainId, payment, paymentTimestamp } = receivable;
                const hasTags: boolean = tags.length !== 0;
                const receivedDate = toDateDisplay(paymentTimestamp);
                const onClick = () => openItem(receivable);

                const columnValues = [
                    <Text>{receivedDate}</Text>,
                    ...(isMobile
                        ? []
                        : [
                              <ChainSymbol chainId={chainId} />,
                              <ContactNameOrAddress chainId={chainId}>
                                  {isFactored(receivable as ClaimInfo, actingWallet) ? creditor : debtor}
                              </ContactNameOrAddress>,
                              hasTags ? (
                                  <AccountTagViewer
                                      tags={tags}
                                      removeTag={() => {}}
                                      addTag={() => {}}
                                      creatingExpense={false}
                                      setEditing={{
                                          on: () => {},
                                          off: () => {},
                                      }}
                                      isDisabled={false}
                                      fieldName={'tags'}
                                      editState={'viewing only'}
                                      maxTagsDisplayed={1}
                                  />
                              ) : (
                                  <Text color={'gray.400'}>No category</Text>
                              ),
                              <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                          ]),
                    <HStack w="100%">
                        <TokenAmount amount={payment} tokenInfo={tokenInfo} />
                        <Spacer />
                        <ViewDetailsButton onClick={onClick} />
                    </HStack>,
                ];
                return [...listItems, { columnValues }];
            }, []),
        [JSON.stringify(sortedReceivables), isMobile],
    );

    return (
        <ListViewCard
            titleElement={
                <Flex>
                    <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                        Received Payments
                    </Text>
                    <Spacer />
                    <LinkButton to="/payments" color={isMobile ? 'brand.bulla_blue' : 'theme'}>
                        {isMobile ? 'View All' : ' View All Payments Received'}
                    </LinkButton>
                </Flex>
            }
            headers={['received', ...(isMobile ? [] : ['chain', 'from wallet', 'category', 'description']), 'amount']}
            displayedListItems={paymentsReceivedList}
            emptyMessage={<Text textStyle="faint">You have not received any payments</Text>}
            mt="12"
        />
    );
};

export const PaymentsMade = () => {
    const openItem = useOpenBullaItem();
    const { paidBullaItemsWithPayments } = useGlobalUserData('exclude-originating-claims');
    const isMobile = useIsMobile();

    const sortedPayables = paidBullaItemsWithPayments.slice(0, 5);

    const paymentsMadeList = useMemo(
        () =>
            sortedPayables.reduce<ListItemProps[]>((listItems, payable) => {
                const { creditor, description, tags, tokenInfo, chainId, payment, paymentTimestamp } = payable;
                const hasTags = tags.length !== 0;
                const paymentDate = toDateDisplay(paymentTimestamp);
                const onClick = () => openItem(payable);

                const columnValues = [
                    <Text>{paymentDate}</Text>,
                    ...(isMobile
                        ? []
                        : [
                              <ChainSymbol chainId={chainId} />,
                              <ContactNameOrAddress chainId={chainId}>{creditor}</ContactNameOrAddress>,
                              hasTags ? (
                                  <AccountTagViewer
                                      tags={tags}
                                      removeTag={() => {}}
                                      addTag={() => {}}
                                      creatingExpense={false}
                                      setEditing={{
                                          on: () => {},
                                          off: () => {},
                                      }}
                                      isDisabled={false}
                                      fieldName={'tags'}
                                      editState={'viewing only'}
                                      maxTagsDisplayed={1}
                                  />
                              ) : (
                                  <Text color={'gray.400'}>No category</Text>
                              ),
                              <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                          ]),
                    <HStack w="100%">
                        <TokenAmount amount={payment} tokenInfo={tokenInfo} />
                        <Spacer />
                        <ViewDetailsButton onClick={onClick} />
                    </HStack>,
                ];
                return [...listItems, { columnValues }];
            }, []),
        [JSON.stringify(sortedPayables), isMobile],
    );

    return (
        <ListViewCard
            titleElement={
                <Flex>
                    <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                        Payments Made
                    </Text>
                    <Spacer />
                    <LinkButton to="/payments" color={isMobile ? 'brand.bulla_blue' : 'theme'}>
                        {isMobile ? 'View All' : 'View All Payments Made'}
                    </LinkButton>
                </Flex>
            }
            headers={['paid', ...(isMobile ? [] : ['chain', 'to wallet', 'category', 'description']), 'amount']}
            displayedListItems={paymentsMadeList}
            emptyMessage={<Text textStyle="faint">You have not made any payments</Text>}
            mt="12"
        />
    );
};

export const PendingSafePayments = () => {
    const { payables } = useGlobalUserData('exclude-originating-claims');
    const { pendingPayments, isSafeReady } = useGnosisSafe();

    const items = getPendingGnosisLineItems(pendingPayments, payables);
    return (
        <Collapse unmountOnExit in={isSafeReady && items.length > 0}>
            <PendingClaims
                walletHeader="wallet"
                items={items}
                title={
                    <>
                        <HStack>
                            <Icon color="accent_light" as={InfoIcon} />
                            <Text textStyle="listTitle">Gnosis: Pending confirmation</Text>
                        </HStack>
                        <Text>These claims are awaiting payment from your Gnosis Multisig.</Text>
                    </>
                }
            />
        </Collapse>
    );
};
