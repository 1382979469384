import { Box, Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { GnosisModuleInstall } from '../../pages/onboard/gnosis-module-install';
import { GnosisSafeConnect } from '../../pages/onboard/gnosis-safe-connect';
import { ChakraMotion } from '../base/animation';
import { CloseModalButton } from './common';

type GnosisOnboardingState = 1 | 2 | 'closed';
type GnosisModalDisclosure = {
    isOpen: boolean;
    close: () => void;
    connect: () => void;
    install: () => void;
};
export const GnosisOnboardingModal = ({
    triggerElement,
    initialState,
    onClose,
}: {
    triggerElement: (disclosure: GnosisModalDisclosure) => JSX.Element;
    initialState?: GnosisOnboardingState;
    onClose?: VoidFunction;
}) => {
    const [gnosisOnboardingState, setGnosisOnboardingState] = React.useState<GnosisOnboardingState>(initialState ?? 'closed');
    const closeGnosisOnboard = () => setGnosisOnboardingState('closed');
    const disclosure: GnosisModalDisclosure = {
        isOpen: gnosisOnboardingState !== 'closed',
        close: closeGnosisOnboard,
        connect: () => setGnosisOnboardingState(1),
        install: () => setGnosisOnboardingState(2),
    };

    return (
        <>
            {triggerElement(disclosure)}
            <Modal
                isOpen={gnosisOnboardingState !== 'closed'}
                onClose={closeGnosisOnboard}
                closeOnOverlayClick={false}
                isCentered
                size="xl"
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={closeGnosisOnboard} />
                    <ModalBody py="8">
                        <Box position={'relative'}>
                            <AnimatePresence initial={false}>
                                {gnosisOnboardingState === 1 ? (
                                    <ChakraMotion initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                        <GnosisSafeConnect
                                            {...{
                                                completeStep: () => setGnosisOnboardingState(2),
                                                finish: () => {
                                                    closeGnosisOnboard();
                                                    onClose && onClose();
                                                },
                                            }}
                                        />
                                    </ChakraMotion>
                                ) : (
                                    <ChakraMotion initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                                        <GnosisModuleInstall
                                            {...{ completeStep: closeGnosisOnboard, goBack: () => setGnosisOnboardingState(1) }}
                                        />
                                    </ChakraMotion>
                                )}
                            </AnimatePresence>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
