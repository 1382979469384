import jsPDF from 'jspdf';

export const PDF_WINDOW_WIDTH = 800;
export const PDF_WINDOW_HEIGHT = PDF_WINDOW_WIDTH * (11 / 8.5);

export const jsPDFSingleton = (function (): jsPDF {
    let instance = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [PDF_WINDOW_WIDTH, PDF_WINDOW_HEIGHT],
    });
    return instance;
})();

export const importFontsIfNotInitialized = async () => {
    if (jsPDFSingleton.getFontList()['Inter'] === undefined) {
        // lazy import fonts
        const { initFonts } = await import('../assets/inter');
        initFonts();
    }
};
