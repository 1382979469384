import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Center,
    Flex,
    Grid,
    Heading,
    HStack,
    Image,
    Spacer,
    Stack,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    Text,
    Wrap,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CoinsLogo from 'url:../../../assets/coins.svg';
import { FinancedClaimInfo, FrendLendOffer } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { isClaim, isFinancingAccepted } from '../../../data-lib/helpers';
import { useFrendLend } from '../../../hooks/useFrendLend';
import { useDataReadiness, useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { StyledTab } from '../../../pages/settings/settings';
import { fillToCount } from '../../../tools/common';
import { OrangeButton } from '../../inputs/buttons';
import { FrendLendCard, Loan, LoanCard, SkeletonCard } from '../../layout/loan-cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../layout/page-layout';
import { CreateFrendlendModal } from '../../modals/create-frendlend-modal/create-frendlend-modal';

const TABS = ['dashboard', 'payables', 'receivables', 'loan-offers'] as const;
type TAB = typeof TABS[number];

const [DEFAULT_TAB] = TABS;

const tabURLToLabel: Record<string, string | undefined> = {
    dashboard: 'Dashboard',
    payables: 'Payables',
    receivables: 'Receivables',
    'loan-offers': 'Loan Offers',
};

const NoLoansMessage = ({ createLoanButton }: { createLoanButton: JSX.Element }) => {
    return (
        <Center h="100%">
            <Box w="max-content">
                <Box h="24" />
                <Stack spacing="3" alignItems={'center'}>
                    <Image w="50px" h="35px" src={CoinsLogo} />
                    <Text fontWeight={700} fontSize="20px" lineHeight="28px">
                        You have no loans
                    </Text>
                    <Text lineHeight="20px">Create a loan offer to get started </Text>
                    {createLoanButton}
                </Stack>
            </Box>
        </Center>
    );
};

type LoanTableProps = { cards: JSX.Element[]; label: string };
const SkeletonTable = ({ label }: Omit<LoanTableProps, 'cards'>) => (
    <Stack p="0" spacing="12">
        <Text textStyle="listTitle">{label}</Text>
        <Grid templateColumns={'repeat(auto-fit, minmax(350px, 1fr))'} columnGap={'4'} rowGap={'4'}>
            {fillToCount([], 3).map((_, i) => (
                <SkeletonCard key={i} />
            ))}
        </Grid>
    </Stack>
);
const CardTable = ({ cards, label }: LoanTableProps) => (
    <Stack p="0" spacing="12">
        <Text textStyle="listTitle">{label}</Text>
        <Grid templateColumns={'repeat(auto-fill, minmax(350px, 1fr))'} columnGap={'4'} rowGap={'4'}>
            {cards}
        </Grid>
    </Stack>
);

export const BetaTag = () => (
    <Tag bg="brand.bulla_yellow" color="white" size="sm">
        Beta
    </Tag>
);

export const FinancingDashboard = () => {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const currentTab = (params.get('tab') as TAB | undefined) || DEFAULT_TAB;

    const {
        connectedNetworkConfig: { frendlendAddress, label },
        connectedNetwork,
    } = useWeb3();
    const wallet = useActingWalletAddress();
    const { isChainInitialized } = useDataReadiness();
    const isLoading = !isChainInitialized(connectedNetwork);
    const { payables, receivables, frendLends } = useGlobalUserData('exclude-originating-claims');

    const frendLendOffersReceived = useMemo(
        () => frendLends.filter((lend): lend is FrendLendOffer => addressEquality(lend.debtor, wallet) && lend.status === 'Offered'),
        [JSON.stringify(frendLends)],
    );
    const frendLendOffersSent = useMemo(
        () => frendLends.filter((lend): lend is FrendLendOffer => addressEquality(lend.creditor, wallet) && lend.status === 'Offered'),
        [JSON.stringify(frendLends)],
    );
    const loanPayables = useMemo(
        () =>
            payables.filter(
                (item): item is FinancedClaimInfo => item.__type == 'Claim' && item.claimStatus == 'Repaying' && isFinancingAccepted(item),
            ),
        [JSON.stringify(payables)],
    );
    const loanReceivables = useMemo(
        () =>
            receivables.filter(
                (item): item is FinancedClaimInfo => item.__type == 'Claim' && item.claimStatus == 'Repaying' && isFinancingAccepted(item),
            ),
        [JSON.stringify(receivables)],
    );

    const hasItems =
        frendLendOffersReceived.length > 0 || frendLendOffersSent.length > 0 || loanPayables.length > 0 || loanReceivables.length > 0;

    const [showFrendLend, setShowFrendLend] = React.useState(false);
    const [{ frendLendPending }] = useFrendLend();

    const changeTab = (tab: TAB) => navigate({ search: `?tab=${tab}` }, { replace: true });

    const offersReceivedTable = React.useMemo(
        () => (
            <CardTable
                cards={frendLendOffersReceived.map((lend, i) => (
                    <FrendLendCard frendLend={lend} key={i} />
                ))}
                label={'Loan Offers Available'}
            />
        ),
        [JSON.stringify(frendLendOffersReceived)],
    );

    const offersSentTable = React.useMemo(
        () => (
            <CardTable
                cards={frendLendOffersSent.map((lend, i) => (
                    <FrendLendCard frendLend={lend} key={i} />
                ))}
                label={'Loan Offers Sent'}
            />
        ),
        [JSON.stringify(frendLendOffersSent)],
    );

    const loanPayablesTable = React.useMemo(
        () => (
            <CardTable
                cards={loanPayables.map((p, i) => (
                    <LoanCard loan={new Loan(p)} key={i} />
                ))}
                label={'Loan Payables'}
            />
        ),
        [JSON.stringify(loanPayables)],
    );

    const loanReceivablesTable = React.useMemo(
        () => (
            <CardTable
                cards={loanReceivables.map((r, i) => (
                    <LoanCard loan={new Loan(r)} key={i} />
                ))}
                label={'Loan Receivables'}
            />
        ),
        [JSON.stringify(loanReceivables)],
    );

    const offerLoanButton = (
        <OrangeButton
            onClick={() => navigate({ pathname: '/financing/new' }, { replace: true })}
            isDisabled={frendLendPending || !frendlendAddress}
            minW="fit-content"
        >
            {!!frendlendAddress ? 'Offer a loan' : `FrendLend not supported on ${label}`}
        </OrangeButton>
    );

    return (
        <PageLayoutProvider>
            <Flex p={{ sm: '8', md: '12' }} bg={'scheme.accent_light'} direction="column" flex="1">
                <MaxWidthWrapper>
                    <Breadcrumb textColor={'#707EAE'}>
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>Loans Dashboard</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Box h="2" />
                    <Heading color="heading" flex={1}>
                        Loans Dashboard&nbsp; <BetaTag />
                    </Heading>
                    <Box h="8" />
                    <Tabs index={TABS.indexOf(currentTab)} onChange={i => changeTab(TABS[i])}>
                        <Wrap>
                            <HStack w={'min-content'}>
                                {TABS.map(tab => (
                                    <StyledTab key={tab}>{tabURLToLabel[tab]}</StyledTab>
                                ))}
                            </HStack>
                            <Spacer />
                            {hasItems && offerLoanButton}
                        </Wrap>

                        <Box h="6" />
                        <TabPanels>
                            <TabPanel>
                                {isLoading || hasItems ? (
                                    <Stack spacing="8">
                                        {frendLendOffersReceived.length > 0 ? offersReceivedTable : null}
                                        {frendLendOffersSent.length > 0 ? offersSentTable : null}
                                        {isLoading ? <SkeletonTable label="Loan Payables" /> : loanPayablesTable}
                                        {isLoading ? <SkeletonTable label="Loan Receivables" /> : loanReceivablesTable}
                                    </Stack>
                                ) : (
                                    <NoLoansMessage createLoanButton={offerLoanButton} />
                                )}
                            </TabPanel>
                            <TabPanel>{hasItems ? loanPayablesTable : <NoLoansMessage createLoanButton={offerLoanButton} />}</TabPanel>
                            <TabPanel>{hasItems ? loanReceivablesTable : <NoLoansMessage createLoanButton={offerLoanButton} />}</TabPanel>
                            <TabPanel>
                                {hasItems ? (
                                    <Stack spacing="8">
                                        {frendLendOffersReceived.length > 0 ? offersReceivedTable : null}
                                        {frendLendOffersSent.length > 0 ? offersSentTable : null}
                                    </Stack>
                                ) : (
                                    <NoLoansMessage createLoanButton={offerLoanButton} />
                                )}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </MaxWidthWrapper>
            </Flex>
            {!!frendlendAddress && (
                <CreateFrendlendModal
                    isOpen={pathname.includes('/new') && !!frendlendAddress}
                    onClose={() => navigate({ pathname: '/financing' }, { replace: true })}
                ></CreateFrendlendModal>
            )}
        </PageLayoutProvider>
    );
};
