import { BigNumber, BytesLike, ethers } from 'ethers';
import { addressEquality, EthAddress } from './ethereum';
import {
    BullaItemInfo,
    ClaimInfo,
    FinanciableClaimInfo,
    InstantPaymentInfo,
    FinancingOfferedClaimInfo,
    PendingInstantPaymentInfo,
    FinancedClaimInfo,
    FrendLendInfo,
    VendorFinancingAcceptedClaimInfo,
    ImportedExternalTransactionInfo,
    PoolEventInfo,
    PoolDepositInfo,
    PoolRedemptionInfo,
} from './data-model';
import { Direction } from '../components/display/claim-table';
import { MappedEventType } from './dto/mapped-event-types';
import { ClaimPaymentEvent } from './domain/bulla-claim-domain';
import { BullaItemInfoWithPayment } from '../hooks/useUserData';
import { NETWORKS } from './networks';
import { HumaFactorEvent } from './domain/common-domain';
import { GetHistoricalTokenPrice } from '../hooks/useHistoricalPrices';
import { OffchainInvoiceInfo } from '../hooks/useOffchainInvoiceFactory';
import { enableBullaFactoringPool } from '../tools/featureFlags';
import { isFactoringEvent } from './dto/event-filters';
import { hasFactoringConfig } from '../pages/financing/factoring-pools-list';
import { InvoiceUnfactoredEvent } from './domain/factoring-domain';

const secondsPerDay = 86400;
export const intToDate = (int: number | undefined) => (int ? new Date(int * 1000) : new Date(0));
export const daysToSeconds = (days: number) => days * secondsPerDay;
export const secondsToDays = (seconds: BigNumber) => seconds.div(secondsPerDay).toNumber();
export const dateToInt = (date: Date) => Math.ceil(date.getTime() / 1000); //TODO:Test that this works
export const dateLabel = (date: Date) => date.toISOString().replace(/\D/g, '');
export const addDaysToToday = (days: number) => {
    const today = new Date();
    return new Date(today.setDate(today.getDate() + days));
};
export const ZERO_BIGNUMBER = BigNumber.from(0);
export const defaultDate = intToDate(0);

export const getNavigatorLanguage = () =>
    navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || 'en-US';

export const getFactoringAddressesByChainId = (): Record<number, string[]> => {
    return Object.values(NETWORKS)
        .filter(hasFactoringConfig)
        .reduce((acc, network) => {
            const chainId = network.chainId;
            const address = network.factoringConfig.bullaFactoringToken.token.address;
            if (!acc[chainId]) {
                acc[chainId] = [];
            }
            acc[chainId].push(address);
            return acc;
        }, {} as Record<number, string[]>);
};

export const getReceivables = (userAddress: EthAddress, items: BullaItemInfo[]) => {
    const factoringAddresses: { chainId: number; address: string }[] = Object.entries(getFactoringAddressesByChainId()).flatMap(
        ([chainId, addresses]) => addresses.map(address => ({ chainId: parseInt(chainId), address })),
    );

    return items.filter(claim => {
        const isReceivable =
            addressEquality(userAddress, claim.creditor) ||
            (claim.__type === 'Claim' &&
                claim.isTransferred &&
                enableBullaFactoringPool &&
                factoringAddresses.some(({ address }) => addressEquality(claim.creditor, address)) &&
                addressEquality(claim.originalCreditor, userAddress));

        const hasTransferedPayment = claim.__type === 'Claim' && claim.logs && filterTransferedPayment(claim.logs, userAddress).length > 0;

        return isReceivable || hasTransferedPayment;
    });
};

export const getPayables = (userAddress: EthAddress, items: BullaItemInfo[]) =>
    items.filter(claim => addressEquality(userAddress, claim.debtor));

export function isFactored(claim: ClaimInfo, userAddress: string): boolean {
    return (
        claim.isTransferred &&
        !addressEquality(userAddress, claim.debtor) &&
        claim.logs.some(log => log.__typename === 'InvoiceFundedEvent') &&
        !claim.logs.some(log => log.__typename === 'InvoiceUnfactoredEvent')
    );
}

export function filterAndMapHumaFactorEvents(logs: MappedEventType[], direction?: Direction): HumaFactorEvent[] {
    return logs.filter((log: MappedEventType): log is HumaFactorEvent =>
        direction === 'In'
            ? log.__typename === 'DrawdownOnReceivableEvent' || log.__typename === 'ExtraFundsDispersedEvent'
            : log.__typename === 'PaymentMadeEvent',
    );
}

export function getHumaPaymentDetails(item: ClaimInfo, direction: Direction) {
    return filterAndMapHumaFactorEvents(item.logs, direction).map(log => {
        const isExtraFundsDispersedOrPaymentMade = log.__typename === 'ExtraFundsDispersedEvent' || log.__typename === 'PaymentMadeEvent';
        return {
            ...item,
            payment: BigNumber.from(isExtraFundsDispersedOrPaymentMade ? log.amount! : log.netAmountToBorrower!),
            paymentTimestamp: log.blocktime,
        };
    });
}

export function getFactoringPaymentDetails(item: ClaimInfo) {
    return item.logs.filter(isFactoringEvent).map(log => {
        return {
            ...item,
            payment: BigNumber.from(log.__typename === 'InvoiceFundedEvent' ? log.fundedAmount : log.kickbackAmount),
            paymentTimestamp: log.blocktime,
            debtor: log.poolAddress,
            creditor: log.originalCreditor,
        };
    });
}

export function filterTransferedPayment(logs: MappedEventType[], userAddress: EthAddress): ClaimPaymentEvent[] {
    return logs.filter((log: MappedEventType): log is ClaimPaymentEvent => {
        return log.__typename === 'ClaimPaymentEvent' && log.creditor !== undefined && addressEquality(log.creditor, userAddress);
    });
}

export function getTransferedPayments(item: ClaimInfo, userAddress: EthAddress) {
    return filterTransferedPayment(item.logs, userAddress).map(log => {
        return {
            ...item,
            payment: log.paymentAmount,
            paymentTimestamp: log.blocktime,
        };
    });
}

export const addPayments = (
    getHistoricalTokenPrice: GetHistoricalTokenPrice,
    receivables: BullaItemInfo[],
    payables: BullaItemInfo[],
    userAddress: EthAddress,
    direction: Direction,
    poolEvents: PoolEventInfo[],
): BullaItemInfoWithPayment[] => {
    const cashFlows: BullaItemInfo[] =
        direction === 'In'
            ? receivables.concat(
                  payables.filter(payable => {
                      return (payable as ClaimInfo).financingState?.kind === 'accepted';
                  }),
              )
            : payables.concat(
                  receivables.filter(receivable => {
                      const claim = receivable as ClaimInfo;
                      return (
                          claim.financingState?.kind === 'accepted' ||
                          (claim.logs?.some((log: MappedEventType): log is ClaimPaymentEvent => log.eventType === 'PaymentMadeEvent') &&
                              claim.isTransferred)
                      );
                  }),
              );

    const payments: Omit<BullaItemInfoWithPayment, 'USDMark'>[] = cashFlows.flatMap<Omit<BullaItemInfoWithPayment, 'USDMark'>>(item => {
        if (item.__type === 'Claim') {
            const claimPayments: Omit<BullaItemInfoWithPayment, 'USDMark'>[] = item.logs
                .reduce<Omit<BullaItemInfoWithPayment, 'USDMark'>[]>((payments, log) => {
                    switch (log.__typename) {
                        case 'InvoiceFundedEvent':
                            payments.push({
                                ...item,
                                payment: BigNumber.from(log.fundedAmount),
                                paymentTimestamp: log.blocktime,
                                debtor: log.poolAddress,
                                creditor: log.originalCreditor,
                            });
                            break;
                        case 'InvoiceKickbackAmountSentEvent':
                            payments.push({
                                ...item,
                                payment: BigNumber.from(log.kickbackAmount),
                                paymentTimestamp: log.blocktime,
                                debtor: log.poolAddress,
                                creditor: log.originalCreditor,
                            });
                            break;
                        case 'InvoiceUnfactoredEvent':
                            payments.push({
                                ...item,
                                payment: BigNumber.from(log.totalRefundAmount),
                                paymentTimestamp: log.blocktime,
                                debtor: log.originalCreditor,
                                creditor: log.poolAddress,
                            });
                            break;
                        case 'ClaimPaymentEvent':
                            payments.push({
                                ...item,
                                payment: log.paymentAmount,
                                paymentTimestamp: log.blocktime,
                            });
                            break;
                    }

                    return payments;
                }, [])
                .filter(payment => addressEquality(userAddress, payment.creditor) || addressEquality(userAddress, payment.debtor));

            const financingPrincipal =
                item.financingState?.kind === 'accepted'
                    ? [
                          {
                              ...item,
                              payment: item.financingState.terms.principalAmount,
                              paymentTimestamp: item.created,
                              debtor: item.creditor,
                              creditor: item.debtor,
                          },
                      ]
                    : [];

            if (addressEquality(direction === 'In' ? item.debtor : item.creditor, userAddress)) {
                return financingPrincipal;
            } else {
                return claimPayments;
            }
        } else if (item.__type == 'OffchainInvoiceInfo') {
            return item.status.kind == 'Paid'
                ? [
                      {
                          ...item,
                          payment: item.status.instantPayment.paidAmount,
                          paymentTimestamp: item.status.instantPayment.created,
                      },
                  ]
                : [];
        } else {
            return [
                {
                    ...item,
                    payment: item.paidAmount,
                    paymentTimestamp: item.created,
                },
            ];
        }
    });

    const poolPayments: Omit<BullaItemInfoWithPayment, 'USDMark'>[] = poolEvents.flatMap(event => {
        const isDeposit = event.__type === 'PoolDeposit';

        const baseEvent = {
            ...event,
            paymentTimestamp: event.created,

            description: isDeposit ? 'Pool Deposit' : 'Pool Redemption',
            tags: [],
            notes: '',
            __type: event.__type,
        };

        return [
            {
                ...baseEvent,
                creditor: isDeposit ? event.poolAddress : event.redeemer,
                debtor: isDeposit ? event.depositor : event.poolAddress,
                payment: event.paidAmount,
                tokenInfo: event.tokenInfo,
            },
            {
                ...baseEvent,
                creditor: isDeposit ? event.depositor : event.poolAddress,
                debtor: isDeposit ? event.poolAddress : event.redeemer,
                payment: event.shares,
                tokenInfo: event.poolTokenInfo,
            },
        ];
    });

    const allPayments = [...payments, ...poolPayments];

    return allPayments
        .map(
            item =>
                ({
                    ...item,
                    USDMark: getHistoricalTokenPrice({
                        chainId: item.chainId,
                        tokenAddress: item.tokenInfo.token.address,
                        timestamp: item.paymentTimestamp,
                    }),
                } as BullaItemInfoWithPayment), // compiler is acting up
        )
        .sort((a, b) => b.paymentTimestamp.getTime() - a.paymentTimestamp.getTime());
};

export const isUnfactored = (claim: ClaimInfo): boolean => {
    return claim.logs.some((log: MappedEventType) => {
        return log.__typename === 'InvoiceUnfactoredEvent';
    });
};

export const outflowUnfactoredPayment = (claim: BullaItemInfoWithPayment): boolean => {
    return (
        claim.payment &&
        (claim as ClaimInfo).logs?.some(
            (log: MappedEventType) => log.eventType === 'PaymentMadeEvent' && (log as HumaFactorEvent).amount === claim.payment.toString(),
        )
    );
};

export const getItemRelationToUser = (userAddress: string, item: BullaItemInfo) => {
    const receivedPaymentFromTransferredClaim =
        item.__type === 'Claim' && filterTransferedPayment(item.logs, userAddress).length > 0 && item.isTransferred;
    const factoringConfig = NETWORKS[item.chainId]?.factoringConfig;
    const receivedPaymentFromFactoring =
        factoringConfig &&
        isClaim(item) &&
        item.__type == 'Claim' &&
        addressEquality(item.creditor, factoringConfig.bullaFactoringToken.token.address) &&
        !addressEquality(item.debtor, userAddress);

    const direction: Direction = addressEquality(userAddress, item.creditor)
        ? 'In'
        : receivedPaymentFromTransferredClaim || receivedPaymentFromFactoring
        ? 'In'
        : 'Out';

    return { direction };
};

export const isClaim = (item: BullaItemInfo | PendingInstantPaymentInfo | FrendLendInfo): item is ClaimInfo | OffchainInvoiceInfo =>
    item.__type === 'Claim' || item.__type === 'OffchainInvoiceInfo';
export const isOffchainClaim = (item: BullaItemInfo | PendingInstantPaymentInfo | FrendLendInfo): item is OffchainInvoiceInfo =>
    item.__type === 'OffchainInvoiceInfo';
export const hasIPFSHash = (item: BullaItemInfo | FrendLendInfo | any): item is { ipfsHash: string } =>
    (item as { ipfsHash: string })?.ipfsHash !== undefined;
export const isFinancedClaim = (claim: ClaimInfo): claim is FinanciableClaimInfo => claim.financingState.kind !== 'no-financing';
export const isFinancingOffered = (claim: ClaimInfo): claim is FinancingOfferedClaimInfo => claim.financingState.kind === 'offered';
export const isFinancingAccepted = (claim: ClaimInfo): claim is FinancedClaimInfo => claim.financingState.kind === 'accepted';
export const isVendorFinancingAcceptedClaim = (claim: ClaimInfo): claim is VendorFinancingAcceptedClaimInfo =>
    claim.financingState.kind === 'accepted' && claim.financingState.origination.kind == 'vendor-financing';
export const isInstantPayment = (item: BullaItemInfo): item is InstantPaymentInfo => item.__type === 'InstantPayment';
export const isImportedExternalTransaction = (item: BullaItemInfo | PendingInstantPaymentInfo): item is ImportedExternalTransactionInfo =>
    item.__type === 'ImportedExternalTransaction';
export const isPoolTransactions = (item: BullaItemInfo | PendingInstantPaymentInfo | PoolEventInfo): item is PoolEventInfo =>
    item.__type === 'PoolDeposit' || item.__type === 'PoolRedemption';
export const getInstantPayments = (items: BullaItemInfo[]): InstantPaymentInfo[] => items.filter(isInstantPayment);

export const toBytes32 = (stringVal: string) => ethers.utils.formatBytes32String(stringVal);
export const fromBytes32 = (bytesVal: BytesLike) => ethers.utils.parseBytes32String(bytesVal); //these are in wrong place, we need to better organize
export const handleBytes32 = (string?: string) => (string && fromBytes32(string) ? fromBytes32(string) : '');

export const sortBlocknumAsc = (a: MappedEventType, b: MappedEventType) =>
    a.blockNumber === b.blockNumber ? sortAsc(a.logIndex, b.logIndex) : sortAsc(a.blockNumber, b.blockNumber);
export const sortBlocknumDesc = (a: MappedEventType, b: MappedEventType) =>
    a.blockNumber === b.blockNumber ? sortDesc(a.logIndex, b.logIndex) : sortDesc(a.blockNumber, b.blockNumber);

export const sortAsc = (a: number, b: number) => a - b;
export const sortDesc = (a: number, b: number) => b - a;

export const filterDuplicateArrayVals = <T extends string | number>(array: T[]) => {
    const uniqueMap = array.reduce<{ [arrayValue: string]: T }>((acc, val) => ({ ...acc, [val.toString()]: val }), {});
    return Object.values(uniqueMap);
};
