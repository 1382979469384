import React, { PropsWithChildren, useEffect } from 'react';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS } from '../data-lib/networks';
import { tryGetSpecificInstantPayment } from '../state/state-helpers';
import {
    CreateInvoiceRequestParams,
    OffchainInvoiceDto,
    OffchainInvoiceMetadataUpdate,
    useExternalTransactionsApi,
} from './useExternalTransactionsApi';
import { useActingWalletAddress } from './useWalletAddress';

type OffchainInvoicesContext = {
    invoices: OffchainInvoiceDto[] | 'loading';
    createInvoice: (params: CreateInvoiceRequestParams) => Promise<boolean>;
    fetchUnassignedInvoices: (invoiceIdsToFetch: string[], chainId: ChainId) => Promise<void>;
    addNewlyPaidInvoiceToFetchQueue: (invoiceId: string, instantPaymentId: string, chainId: ChainId) => Promise<void>;
    cancelInvoice: (invoiceId: string) => Promise<boolean>;
    editInvoiceMetadata: (metadataByInvoiceId: Record<string, OffchainInvoiceMetadataUpdate>) => Promise<boolean>;
};
const OffchainInvoicesContext = React.createContext<OffchainInvoicesContext>({
    invoices: 'loading',
    createInvoice: _ => Promise.resolve(false),
    fetchUnassignedInvoices: _ => Promise.resolve(),
    addNewlyPaidInvoiceToFetchQueue: _ => Promise.resolve(),
    cancelInvoice: _ => Promise.resolve(false),
    editInvoiceMetadata: _ => Promise.resolve(false),
});

export const OffchainInvoiceProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const actingWallet = useActingWalletAddress();
    const {
        fetchOffchainInvoices,
        createOffchainInvoice,
        fetchUnassignedOffchainInvoices,
        cancelInvoice: _cancelInvoice,
        updateMetadata,
    } = useExternalTransactionsApi();
    const [state, setState] = React.useState<'loading' | OffchainInvoiceDto[]>('loading');
    const [fetchedUnassigned, setFetchedUnassigned] = React.useState<string[]>([]);

    const allFetchedInvoiceIds = React.useMemo(
        () => new Set([...fetchedUnassigned, ...(state == 'loading' ? [] : state.map(x => x.id))]),
        [state, fetchedUnassigned.length],
    );
    const createInvoice = React.useCallback(
        (params: CreateInvoiceRequestParams) =>
            createOffchainInvoice(params).then(invoice => {
                if (invoice === undefined) return false;
                setState(state => (state == 'loading' ? 'loading' : [...state, invoice]));
                return true;
            }),
        [actingWallet],
    );

    const fetchUnassignedInvoices = React.useCallback(
        async (invoiceIdsToFetch: string[], chainId: ChainId) => {
            const idsToFetch = invoiceIdsToFetch.filter(x => !allFetchedInvoiceIds.has(x));
            if (idsToFetch.length == 0) return;
            setFetchedUnassigned(otherIds => [...otherIds, ...idsToFetch]);

            return fetchUnassignedOffchainInvoices(idsToFetch, chainId).then(invoices =>
                setState(state => (state == 'loading' ? 'loading' : [...state, ...invoices])),
            );
        },
        [allFetchedInvoiceIds.size, actingWallet],
    );

    const addNewlyPaidInvoiceToFetchQueue = React.useCallback(
        async (invoiceId: string, instantPaymentId: string, chainId: ChainId) => {
            const instantPayment = await tryGetSpecificInstantPayment(NETWORKS[chainId].connections.graphEndpoint!, instantPaymentId);
            if (!!instantPayment) {
                fetchUnassignedInvoices([invoiceId], chainId);
            } else {
                setTimeout(() => addNewlyPaidInvoiceToFetchQueue(invoiceId, instantPaymentId, chainId), 2000);
            }
        },
        [allFetchedInvoiceIds.size, actingWallet],
    );

    useEffect(() => {
        fetchOffchainInvoices().then(setState);
    }, [actingWallet]);

    const cancelInvoice = React.useCallback(
        (invoiceId: string) =>
            _cancelInvoice(invoiceId).then(invoice => {
                if (invoice === undefined) return false;
                setState(state => (state == 'loading' ? 'loading' : [...state.filter(x => x.id !== invoiceId), invoice]));
                return true;
            }),
        [actingWallet],
    );

    const editInvoiceMetadata = React.useCallback(
        (metadataByInvoiceId: Record<string, OffchainInvoiceMetadataUpdate>) =>
            updateMetadata(metadataByInvoiceId).then(invoices => {
                if (invoices.length == 0) return false;
                const changedInvoiceIds = new Set(invoices.map(x => x.id));
                setState(state => (state == 'loading' ? 'loading' : [...state.filter(x => !changedInvoiceIds.has(x.id)), ...invoices]));
                return true;
            }),
        [actingWallet],
    );

    return (
        <OffchainInvoicesContext.Provider
            value={React.useMemo(
                () => ({
                    invoices: state,
                    createInvoice,
                    fetchUnassignedInvoices,
                    addNewlyPaidInvoiceToFetchQueue,
                    cancelInvoice,
                    editInvoiceMetadata,
                }),
                [state, allFetchedInvoiceIds.size, actingWallet],
            )}
        >
            {children}
        </OffchainInvoicesContext.Provider>
    );
};

export const useOffchainInvoices = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call useOffchainInvoices with the OffchainInvoiceProvider');
    return context.invoices == 'loading'
        ? []
        : context.invoices.filter(x => SUPPORTED_NETWORKS.includes(x.tokenStrategy.chainId as ChainId));
};

export const useCreateOffchainInvoice = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call useCreateOffchainInvoice with the OffchainInvoiceProvider');
    return context.invoices == 'loading' ? 'loading' : context.createInvoice;
};

export const useFetchUnassignedOffchainInvoices = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call useFetchUnassignedOffchainInvoices with the OffchainInvoiceProvider');
    return context.fetchUnassignedInvoices;
};

export const usePaidOffchainInvoiceCallback = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call usePaidOffchainInvoiceCallback with the OffchainInvoiceProvider');
    return context.addNewlyPaidInvoiceToFetchQueue;
};

export const useCancelOffchainInvoice = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call useCancelOffchainInvoice with the OffchainInvoiceProvider');
    return context.cancelInvoice;
};

export const useEditOffchainInvoiceMetadata = () => {
    const context = React.useContext(OffchainInvoicesContext);
    if (!context) throw new Error('Error: you must call useEditOffchainInvoiceMetadata with the OffchainInvoiceProvider');
    return context.editInvoiceMetadata;
};
