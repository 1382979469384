import React from 'react';
import { STORAGE_KEYS } from '../tools/storage';
import { useLocalStorage } from './useStorage';

type GettingStartedContext = 'uninitialized' | { showGettingStarted: boolean; setGettingStartedEnabled: (enabled: boolean) => void };

const GettingStartedContext = React.createContext<GettingStartedContext>('uninitialized');

export const GettingStartedProvider = ({ children }: { children: React.ReactNode }) => {
    const [showGettingStarted, setGettingStartedEnabled] = useLocalStorage<boolean>(STORAGE_KEYS.showGettingStarted, true);

    return (
        <GettingStartedContext.Provider value={{ showGettingStarted, setGettingStartedEnabled }}>{children}</GettingStartedContext.Provider>
    );
};

export const useGettingStarted = () => {
    const context = React.useContext(GettingStartedContext);
    return context === 'uninitialized' ? { showGettingStarted: false, setGettingStartedEnabled: () => {} } : context;
};
