{
    "id": "/zeroExTransactionSchema",
    "properties": {
        "data": { "$ref": "/hexSchema" },
        "signerAddress": { "$ref": "/addressSchema" },
        "salt": { "$ref": "/wholeNumberSchema" },
        "expirationTimeSeconds": { "$ref": "/wholeNumberSchema" },
        "gasPrice": { "$ref": "/wholeNumberSchema" },
        "domain": { "$ref": "/eip712DomainSchema" }
    },
    "required": ["data", "salt", "expirationTimeSeconds", "gasPrice", "signerAddress", "domain"],
    "type": "object"
}
