import { SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Collapse,
    Divider,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BullaItemInfo } from '../../../data-lib/data-model';
import { getItemRelationToUser, isClaim } from '../../../data-lib/helpers';
import { useOpenBullaItem } from '../../../hooks/useClaimDetailDisclosure';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { HighlightedText } from '../../base/highlighted-text';
import { StatusBadge } from '../../base/status-badge';
import { TokenAmount } from '../../currency/token-display-amount';
import { BullaCloseButton } from '../../modals/common';
import { TableFilter } from '../views/filters/common';

export type FilterType = 'type' | 'received' | 'status' | 'amount';
export type SetFilter = (key: keyof FilterValues, value: FilterValues[keyof FilterValues]) => void;
export type TableFilters = TableFilter<BullaItemInfo>[];
export type FilterOption = { label: string; filter: string | number | undefined };
export type FilterValues = {
    search: string | undefined;
    advancedFilters: TableFilters;
};
export type FilterParams = {
    filterName: string;
    name: string;
    options: FilterOption[];
};
export type Result = {
    text: string;
    subText: string;
    actions: React.ReactNode[];
};

const isSubstringOf = (value: string, search: string = '') => String(value).toLowerCase().includes(search.trim().toLowerCase());

const Result = ({
    text,
    search = '',
    actions = [],
    onClick,
}: {
    text: string;
    onClick: () => void;
    search?: string;
    actions?: React.ReactNodeArray;
}) => (
    <Box onClick={onClick} textStyle="faint" zIndex={1000}>
        <HStack
            px="3"
            py="3"
            as="button"
            w="100%"
            h="100%"
            _active={{ bg: 'scheme.accent_light' }}
            _hover={{ bg: 'scheme.accent_light', cursor: 'pointer' }}
        >
            <HighlightedText search={search.trim()}>{text}</HighlightedText>
            <Spacer />
            <HStack>
                {actions.map((action, i) => (
                    <React.Fragment key={i}>{action}</React.Fragment>
                ))}
            </HStack>
        </HStack>
    </Box>
);

const ResultGroup = ({ title, children }: { title: string; children: React.ReactNodeArray }) => {
    return (
        <>
            <Box textStyle="faint" h="6" pl="6" pr="6" mt="4">
                {title}
            </Box>
            {children}
        </>
    );
};

export const Search = ({ triggerElement }: { triggerElement: (onClick: VoidFunction) => React.ReactNode }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [filters, setFilters] = React.useState<FilterValues>({
        search: undefined,
        advancedFilters: [],
    });
    const claimFilterCriteria = ['tags', 'id', 'creditor', 'debtor', 'description', 'notes'] as const;
    const queryAddress = useActingWalletAddress();
    const { accountTags, payables, receivables, instantPayments, importedExternalTxs } = useGlobalUserData('exclude-originating-claims');
    const navigate = useNavigate();

    const openItem = useOpenBullaItem();
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));

    const resultCount = 12;
    const { advancedFilters, search } = filters;

    const resultGroups = React.useMemo(() => {
        const matchingCategories = (
            advancedFilters.length === 0
                ? accountTags
                      .filter(tag => isSubstringOf(tag.name, search))
                      .map(tag => ({
                          text: tag.name,
                          subText: '',
                          onClick: () => navigate(`/payments?category=${tag.name}`),
                          actions: [],
                      }))
                : []
        ).slice(0, resultCount);

        const getMatchingItems = (bullaItems: BullaItemInfo[], count: number) =>
            matchingCategories.length < count
                ? (advancedFilters as TableFilter<BullaItemInfo>[])
                      .reduce((items, filterFunc) => items.filter(filterFunc), bullaItems)
                      .filter((item: BullaItemInfo) =>
                          claimFilterCriteria.some(value =>
                              typeof item[value] == 'string'
                                  ? isSubstringOf(item[value] as string, search)
                                  : (item[value] as string[]).some(tag => isSubstringOf(tag, search)),
                          ),
                      )
                      .map(item => {
                          const { direction } = getItemRelationToUser(queryAddress, item);

                          return {
                              text: `${item.description}`,
                              onClick: () => openItem(item),
                              actions: [
                                  <TokenAmount amount={isClaim(item) ? item.claimAmount : item.paidAmount} tokenInfo={item.tokenInfo} />,
                                  <StatusBadge claimStatus={isClaim(item) ? item.claimStatus : 'Paid'} direction={direction} />,
                              ],
                          };
                      })
                      .slice(0, resultCount - matchingCategories.length)
                : [];

        const matchingPayables = getMatchingItems(payables, resultCount - matchingCategories.length);
        const matchingReceivables = getMatchingItems(receivables, resultCount - matchingPayables.length);
        const matchingPayments = getMatchingItems(instantPayments, resultCount - matchingPayables.length);
        const matchingExternalTransactions = getMatchingItems(importedExternalTxs, resultCount - matchingPayables.length);

        return [
            { category: 'Categories', results: matchingCategories },
            { category: 'Payables', results: matchingPayables },
            { category: 'Receivables', results: matchingReceivables },
            { category: 'Instant Payment', results: matchingPayments },
            { category: 'External Transactions', results: matchingExternalTransactions },
        ];
    }, [search, advancedFilters]);

    const showResults =
        (!!(filters.search && filters.search.trim().length > 0) || !!filters.advancedFilters.length) &&
        resultGroups.flatMap(x => x.results).length !== 0;

    return (
        <>
            {triggerElement(open)}
            <Modal isOpen={isOpen} onClose={close} size="2xl" initialFocusRef={inputRef} closeOnEsc={true} scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent my="28">
                    <InputGroup justifyContent="center" w="100%">
                        <InputLeftElement h="100%" mx="2">
                            <SearchIcon color="scheme.icon_dark" h="100%" />
                        </InputLeftElement>
                        <Input
                            minH="14"
                            color="black"
                            placeholder="Search"
                            value={filters['search'] ?? ''}
                            onChange={e => setFilter('search', e.target.value)}
                            ref={inputRef}
                        />
                        <InputRightElement h="100%" mx="2">
                            <BullaCloseButton onClose={close} />
                        </InputRightElement>
                    </InputGroup>
                    <Spacer />
                    <Collapse in={showResults}>
                        {resultGroups.map(({ category, results }) => (
                            <React.Fragment key={category}>
                                {results.length > 0 && (
                                    <ResultGroup title={category}>
                                        <Divider />
                                        {results.map((res, i) => (
                                            <Result
                                                key={category + i}
                                                search={search}
                                                text={res.text}
                                                actions={res.actions}
                                                onClick={res.onClick}
                                            />
                                        ))}
                                    </ResultGroup>
                                )}
                            </React.Fragment>
                        ))}
                    </Collapse>
                </ModalContent>
            </Modal>
        </>
    );
};
