import { ChevronDownIcon, ChevronUpIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Fade,
    FormControl,
    FormLabel,
    Grid,
    Heading,
    HStack,
    IconButton,
    Image,
    Input,
    InputGroup,
    Menu,
    MenuButton,
    Spacer,
    Stack,
    Text,
    Textarea,
    Tooltip,
} from '@chakra-ui/react';
import React from 'react';
import { QRCodeIcon } from '../../../assets/svgs';
import { CopyableTextLabel } from '../../../components/base/address-label';
import { AlertInfo } from '../../../components/base/alert';
import { NetworkDropdown } from '../../../components/display/wallet-info';
import { OrangeButton, SecondaryButton } from '../../../components/inputs/buttons';
import { ClaimAmountField, disabledInputProps } from '../../../components/modals/create-claim-modal/create-claim-inputs';
import { QRViewerModal } from '../../../components/modals/create-claim-modal/qr-viewer-modal';
import { ItemDesc } from '../../../components/modals/item-details-modal/item-details-components';
import { ClaimType } from '../../../data-lib/data-model';
import { EthAddress } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS, TokenDto, TokenInfo } from '../../../data-lib/networks';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';

type LinkPrefillInfo = {
    network?: ChainId;
    name: string;
    description: string;
    tokenInfo?: TokenInfo;
    amount?: string;
};

export const generateCreateClaimLink = (
    itemType: ClaimType,
    recipient: EthAddress,
    network: ChainId | undefined,
    name: string | undefined,
    tokenInfo: TokenInfo | undefined,
    amount: string | undefined,
    payer?: EthAddress,
    description?: string,
) => {
    return `${window.location.origin}/#/?${new URLSearchParams({
        ...(network ? { network: network.toString() } : {}),
        itemType,
        recipient,
        ...(payer ? { payer } : {}),
        ...(description ? { description } : {}),
        ...(name ? { name } : {}),
        ...(amount ? { amount } : {}),
        ...(!!tokenInfo?.token.address ? { token: tokenInfo.token.address } : {}),
        fromLink: 'true',
    }).toString()}`;
};

export const getHeaderText = (itemType: ClaimType, network?: ChainId, name?: string, amount?: string, token?: TokenDto) =>
    `${itemType === 'Payment' ? 'Pay' : itemType} ${!!name ? name : '--'} ${amount ? `${amount} ` : ''}${
        token ? `${!amount ? 'in ' : ''}${token.symbol} ` : ''
    }${network ? `on ${NETWORKS[network].label}` : ''}`;

export const MyLinksPage = () => {
    const actingWallet = useActingWalletAddress();
    const { safeInfo } = useGnosisSafe();
    const { connectedNetwork } = useWeb3();
    const { tokensByChainId, getTokenByChainIdAndAddress } = useTokenRepo();
    const chains = Object.values(NETWORKS).filter(({ chainId }) => SUPPORTED_NETWORKS.includes(chainId));

    const [prefillInfo, setPrefillInfo] = React.useState<LinkPrefillInfo>(() => ({
        network: safeInfo ? NETWORKS[connectedNetwork].chainId : undefined,
        name: '',
        description: '',
        tokenInfo: undefined,
        amount: undefined,
    }));

    const tokenInfo =
        prefillInfo.tokenInfo === undefined || prefillInfo.network === undefined
            ? undefined
            : prefillInfo.tokenInfo?.chainId !== prefillInfo.network
            ? Object.values(tokensByChainId[prefillInfo.network])[0]
            : prefillInfo.tokenInfo;

    const headerText = getHeaderText('Payment', prefillInfo.network, prefillInfo.name, prefillInfo.amount, tokenInfo?.token);
    const claimLink = generateCreateClaimLink(
        'Payment',
        actingWallet,
        prefillInfo.network,
        prefillInfo.name,
        tokenInfo,
        prefillInfo.amount,
        undefined,
        prefillInfo.description,
    );

    return (
        <Stack spacing="8">
            <Heading size="lg" color="heading" fontWeight={600}>
                My Links
            </Heading>

            <AlertInfo message="Create a shareable link for anyone to send you payment in over 20+ cryptocurrencies." />

            <Grid templateColumns={'repeat(auto-fit, minmax(325px, 1fr))'} columnGap="64px" rowGap={'20px'}>
                <Stack spacing="3">
                    <FormControl>
                        <FormLabel htmlFor="yourName">Your Name</FormLabel>
                        <InputGroup>
                            <Input
                                bg="white"
                                value={prefillInfo.name}
                                onChange={e => setPrefillInfo({ ...prefillInfo, name: e.target.value })}
                                name="yourName"
                                placeholder="This will show up in the user’s payment dialog to indicate who they are sending money to"
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="description">Description</FormLabel>
                        <InputGroup>
                            <Input
                                bg="white"
                                value={prefillInfo.description}
                                onChange={e => setPrefillInfo({ ...prefillInfo, description: e.target.value })}
                                name="description"
                                placeholder="Add a shared description to the payment"
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="network">Network</FormLabel>
                        <HStack>
                            <Menu>
                                {({ isOpen, onClose }) => (
                                    <>
                                        <MenuButton as={Button} colorScheme="brand" isDisabled={!!safeInfo}>
                                            <HStack>
                                                {prefillInfo.network && (
                                                    <Center borderRadius={'full'} p="0.5" w="25px" h="25px" bg="white">
                                                        <Image src={NETWORKS[prefillInfo.network].logoFileName} maxH="23px" />
                                                    </Center>
                                                )}
                                                <Text color="white">
                                                    {prefillInfo.network ? NETWORKS[prefillInfo.network].label : 'Any'}
                                                </Text>
                                                {!isOpen ? <ChevronDownIcon h="6" w="5" /> : <ChevronUpIcon h="6" w="5" />}
                                            </HStack>
                                        </MenuButton>
                                        <NetworkDropdown
                                            noSelectionLabel={'Any'}
                                            chains={chains}
                                            onSelect={chainId => {
                                                setPrefillInfo(prev => ({ ...prev, network: chainId }));
                                                onClose();
                                            }}
                                        />
                                    </>
                                )}
                            </Menu>
                        </HStack>
                    </FormControl>

                    <ClaimAmountField
                        {...{
                            required: false,
                            claimType: 'Invoice',
                            field: {
                                name: 'claimAmount',
                                value: prefillInfo.amount,
                            },
                            networkOverride: prefillInfo.network,
                            isDisabled: false,
                            includeNativeToken: true,
                            touched: false,
                            setAmount: amount => setPrefillInfo({ ...prefillInfo, amount }),
                            setToken: token =>
                                prefillInfo.network &&
                                setPrefillInfo({
                                    ...prefillInfo,
                                    tokenInfo: getTokenByChainIdAndAddress(prefillInfo.network)(token.address),
                                }),
                            amount: prefillInfo.amount ?? '',
                            token: tokenInfo?.token,
                            label: 'Token',
                            disableBalanceLabels: true,
                        }}
                    />
                    <Box h="2" />
                    <Box bg="white" borderRadius={'7px'} boxShadow="md" p="16px" pb="20px">
                        <Text fontSize={'20px'} lineHeight="28px" fontWeight={700}>
                            Your Link
                        </Text>
                        <Box h="6" />
                        <Stack>
                            <ItemDesc title="Pay me" pos={'relative'}>
                                <HStack spacing="1">
                                    <CopyableTextLabel fontWeight={600} color="brand.bulla_blue" displayValue="bulla.network.com/pay?t...">
                                        {claimLink}
                                    </CopyableTextLabel>
                                    <QRViewerModal
                                        headerText={headerText + ' with Bulla'}
                                        triggerElement={onOpen => (
                                            <IconButton
                                                variant="ghost"
                                                borderRadius={'full'}
                                                aria-label="QR code"
                                                icon={<QRCodeIcon fill="color.bulla_blue" />}
                                                onClick={onOpen}
                                            />
                                        )}
                                        url={claimLink}
                                    />
                                </HStack>
                            </ItemDesc>
                        </Stack>
                    </Box>
                </Stack>

                <Stack>
                    <Heading size="lg" color="heading" fontWeight={600}>
                        Preview
                    </Heading>
                    <Box bg="white" borderRadius={'7px'} boxShadow="md" p="16px" pb="20px">
                        <Stack spacing="4">
                            <HStack pos={'relative'}>
                                <Text fontWeight={700} fontSize="24px" lineHeight={'32px'} color="gray.700">
                                    {headerText}
                                    <Tooltip
                                        placement="top"
                                        label="We cannot guarantee this identity relates to this wallet address. Please confirm all wallet addresses before sending payment"
                                    >
                                        <InfoOutlineIcon ml="4px" mt="-18px" w="14px" />
                                    </Tooltip>
                                </Text>
                            </HStack>
                            <AlertInfo hideIcon message="Paying with Bulla allows you to keep track of your crypto transactions." />
                            <FormControl>
                                <FormLabel htmlFor="amount">Recipient</FormLabel>
                                <InputGroup>
                                    <Input
                                        isDisabled
                                        value={!!prefillInfo.name ? `${prefillInfo.name}'s wallet: (${actingWallet})` : actingWallet}
                                        {...disabledInputProps}
                                    />
                                </InputGroup>
                            </FormControl>
                            <ClaimAmountField
                                {...{
                                    claimType: 'Invoice',
                                    field: {
                                        name: 'claimAmount',
                                        value: prefillInfo.amount,
                                    },
                                    networkOverride: prefillInfo.network,
                                    isDisabled: true,
                                    includeNativeToken: true,
                                    touched: false,
                                    setAmount: amount => setPrefillInfo({ ...prefillInfo, amount }),
                                    setToken: token =>
                                        prefillInfo.network &&
                                        setPrefillInfo({
                                            ...prefillInfo,
                                            tokenInfo: getTokenByChainIdAndAddress(prefillInfo.network)(token.address),
                                        }),
                                    amount: prefillInfo.amount ?? '',
                                    token: tokenInfo?.token,
                                    label: 'Token',
                                    disableBalanceLabels: true,
                                }}
                            />
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    placeholder={!!prefillInfo.name ? `Hi ${prefillInfo.name}, paying you for ...` : 'Paying you for...'}
                                    isDisabled={true}
                                />
                            </FormControl>
                            <HStack>
                                <Spacer />
                                <SecondaryButton>Cancel</SecondaryButton>
                                <OrangeButton>Send Payment</OrangeButton>
                            </HStack>
                        </Stack>
                    </Box>
                </Stack>
            </Grid>
        </Stack>
    );
};
