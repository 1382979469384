import React from 'react';
import { Menu, MenuButton, MenuItem, MenuList, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

interface ExportMenuProps {
    handleExport: (method: 'excel' | 'csv') => Promise<void>;
}

const ExportMenu: React.FC<ExportMenuProps> = ({ handleExport }) => (
    <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="outline" borderColor="brand.500" borderWidth="1" py="20.5px">
            Export
        </MenuButton>
        <MenuList>
            <MenuItem onClick={async () => handleExport('excel')}>Export to Excel</MenuItem>
            <MenuItem onClick={async () => handleExport('csv')}>Export to CSV</MenuItem>
        </MenuList>
    </Menu>
);

export default ExportMenu;
