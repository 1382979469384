import { HStack, ModalBody, ModalFooter, ModalHeader, Skeleton, Spacer, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { BullaItemType, ClaimInfo } from '../../../data-lib/data-model';
import { hasSafeOnNetwork } from '../../../data-lib/gnosis-tools/gnosis';
import { changeNetwork, NetworkConfig } from '../../../data-lib/networks';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { tryGetSpecificClaim, tryGetSpecificInstantPayment, tryGetSpecificLoanOfferEvent } from '../../../state/state-helpers';
import { AddressLabel } from '../../base/address-label';
import { ResponsiveStack } from '../../display/responsive-stack';
import { OrangeButton, TextButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';
import { ClaimDetails } from './claim-details';

type ClaimNotFoundState = 'no-network' | 'loading' | 'not-found' | ClaimInfo | { __type: 'not-claim'; creditor: string; debtor: string };

const getLabelForItemType = (type: BullaItemType | 'FrendLend') => {
    switch (type) {
        case 'Claim':
            return 'Claim';
        case 'FrendLend':
            return 'Loan Offer';
        case 'ImportedExternalTransaction':
            return 'Transaction';
        case 'OffchainInvoiceInfo':
            return 'Invoice';
        case 'InstantPayment':
            return 'Payment';
        case 'PoolDeposit':
            return 'Pool Deposit';
        case 'PoolRedemption':
            return 'Pool Redemption';
    }
};
export const ItemNotFound = ({
    handleClose,
    type,
    id,
    networkConfig,
    modalContentRef,
}: {
    handleClose: () => void;
    type: BullaItemType | 'FrendLend';
    id: string | undefined;
    networkConfig: NetworkConfig | undefined;
    modalContentRef?: React.RefObject<HTMLDivElement>;
}) => {
    const { connectedNetwork, userAddress } = useWeb3();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const endpoint = networkConfig?.connections.graphEndpoint;
    const [state, setState] = useState<ClaimNotFoundState>(!!endpoint ? 'loading' : 'no-network');
    const [isSafeOwner, setIsSafeOwner] = useState<'loading' | boolean>('loading');
    const itemLabel = getLabelForItemType(type);
    const queryAddress = useActingWalletAddress();
    const isClaimLoaded = typeof state !== 'string';

    useEffect(() => {
        if (!!endpoint) {
            (async function () {
                const itemOrUndefined = !id
                    ? undefined
                    : type === 'Claim'
                    ? await tryGetSpecificClaim(networkConfig, getTokenByChainIdAndAddress, id)
                    : type === 'InstantPayment'
                    ? await tryGetSpecificInstantPayment(endpoint, id)
                    : type === 'FrendLend'
                    ? await tryGetSpecificLoanOfferEvent(endpoint, id)
                    : undefined;

                if (!!itemOrUndefined) {
                    const maybeClaimInfo = itemOrUndefined as ClaimInfo;
                    setState(maybeClaimInfo.__type == 'Claim' ? maybeClaimInfo : { ...itemOrUndefined, __type: 'not-claim' });
                } else setState('not-found');
            })();
        }
    }, []);

    useEffect(() => {
        (async function () {
            if (isClaimLoaded && !!networkConfig)
                setIsSafeOwner(
                    (await hasSafeOnNetwork(userAddress, state.debtor, networkConfig.chainId)) ||
                        (await hasSafeOnNetwork(userAddress, state.creditor, networkConfig.chainId)),
                );
        })();
    }, [state]);

    return isClaimLoaded && state.__type == 'Claim' ? (
        <ClaimDetails handleClose={handleClose} claim={state} modalContentRef={modalContentRef} />
    ) : (
        <>
            <ModalHeader>
                <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                    {itemLabel} Details
                </Text>
            </ModalHeader>
            <CloseModalButton onClose={handleClose} />
            <ModalBody p={6}>
                <Skeleton isLoaded={state !== 'loading'} w="100%" textStyle={'faint'}>
                    <ResponsiveStack w="fit-content" mx="auto" alignItems={'center'}>
                        <Text>Your wallet </Text>
                        <AddressLabel>{queryAddress}</AddressLabel>
                        <Text> is not associated to this {itemLabel.toLowerCase()}.</Text>
                    </ResponsiveStack>
                    {typeof state !== 'string' && (
                        <ResponsiveStack w="fit-content" mx="auto" alignItems={'center'}>
                            <Text> It is only visible to wallets </Text>
                            <AddressLabel>{state.creditor}</AddressLabel>
                            <Text> and </Text>
                            <AddressLabel>{state.debtor}</AddressLabel>
                        </ResponsiveStack>
                    )}
                </Skeleton>
            </ModalBody>
            <ModalFooter>
                <HStack spacing="4" w="100%" justify="center">
                    {!!networkConfig && networkConfig.chainId !== connectedNetwork && isSafeOwner === true && (
                        <>
                            <Spacer />
                            <Spacer />
                            <HStack w="fit-content">
                                <Text>Switch to </Text>
                                <TextButton onClick={() => changeNetwork(networkConfig.chainId)}>{networkConfig.label}</TextButton>
                            </HStack>
                        </>
                    )}
                    <Spacer />
                    <OrangeButton onClick={handleClose}>Close</OrangeButton>
                </HStack>
            </ModalFooter>
        </>
    );
};
