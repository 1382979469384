import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useState } from 'react';
import { FrendLendOffer } from '../data-lib/data-model';
import { getClaimAmountFromLoanOffer } from '../data-lib/domain/frendlend-domain';
import { termLengthToDays } from '../data-lib/dto/bulla-finance-dto';
import { getERC20Contract, getFrendLendContract } from '../data-lib/dto/contract-interfaces';
import {
    acceptFrendLendOffer,
    buildAcceptLoanMultisendTx,
    buildRejectLoanMultisendTx,
    rejectFrendLendOffer,
} from '../data-lib/dto/frendlend-dto';
import { EthAddress } from '../data-lib/ethereum';
import { addDaysToToday } from '../data-lib/helpers';
import { TXStatus } from '../data-lib/networks';
import { tokenAmountToUSD } from '../data-lib/tokens';
import { useGnosisSafe } from '../state/gnosis-state';
import { wrapTxWithLoadingState } from '../tools/common';
import { pinHashes, uploadMetadataToIpfs } from '../tools/ipfs';
import { useTokenPrices } from './useChainData';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useSendTransaction } from './useSendTransaction';
import { useCurrentChainUserData } from './useUserData';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

const frendLendGuard = (address: EthAddress | undefined) => {
    if (!address) throw new Error('FrendLend is not supported on this network');
    return address;
};

export const useFrendLend = () => {
    const userAddress = useActingWalletAddress();
    const { frendLends } = useCurrentChainUserData();
    const { safeInfo } = useGnosisSafe();
    const { loading: executingViaGnosis, executeTransactions } = useGnosisTransaction();
    const { getTokenPrice } = useTokenPrices();
    const { providersByChainId } = useWeb3();
    const [executing, sendTransaction] = useSendTransaction();
    const [loanState, setLoanState] = useState<'init' | 'under-approved' | 'insufficient-funds' | 'ready'>('init');
    const [rejecting, setRejecting] = useState(false);
    const [accepting, setAccepting] = useState(false);

    const {
        connectedNetworkConfig: { frendlendAddress, label },
    } = useWeb3();

    const validateCreditorState = async (frendlendAddress: string, offer: FrendLendOffer) => {
        const token = getERC20Contract(offer.tokenInfo.token.address).connect(providersByChainId[offer.chainId]);
        const requiredTokens = offer.loanAmount.add(BigNumber.from(1));
        const [allowance, balance] = await Promise.all([
            token.allowance(offer.creditor, frendlendAddress),
            token.balanceOf(offer.creditor),
        ]);

        if (allowance.lt(requiredTokens)) setLoanState('under-approved');
        else if (balance.lt(requiredTokens)) setLoanState('insufficient-funds');
        else setLoanState('ready');
    };

    const acceptLoan = wrapTxWithLoadingState(async (loanId: string) => {
        const [frendLend] = frendLends.filter(x => x.loanId == loanId);
        if (!frendLend) throw new Error("Not found, shouldn't happen. Might be wrong network");

        const claimAmount = getClaimAmountFromLoanOffer(frendLend.loanAmount, frendLend.interestBPS);
        const token = frendLend.tokenInfo.token;
        const usdValue = tokenAmountToUSD({ amount: claimAmount, tokenInfo: frendLend.tokenInfo }, getTokenPrice);

        const tokenURI = await uploadMetadataToIpfs(
            label,
            {
                ...frendLend,
                claimAmount: formatUnits(claimAmount, token.decimals),
                token,
                recipient: frendLend.debtor,
                dueBy: addDaysToToday(termLengthToDays(frendLend.termLength)),
            },
            usdValue,
        );

        return (
            !!safeInfo
                ? executeTransactions(safeInfo, [buildAcceptLoanMultisendTx(frendLendGuard(frendlendAddress), loanId, frendLend)], true)
                : sendTransaction(
                      signer =>
                          acceptFrendLendOffer({
                              contract: getFrendLendContract(frendLendGuard(frendlendAddress)).connect(signer),
                              tokenURI,
                              loanId,
                          }),
                      false,
                  ).then(tx => ({ success: !!tx && tx.status == TXStatus.SUCCESS, transactionResult: tx }))
        ).then(tx => {
            if (tx.success) pinHashes(frendLend.ipfsHash, tokenURI);
            return tx;
        });
    }, setAccepting);

    const rejectLoan = wrapTxWithLoadingState(async (loanId: string) => {
        const [frendLend] = frendLends.filter(x => x.loanId == loanId);
        if (!frendLend) throw new Error("Not found, shouldn't happen. Might be wrong network");

        return !!safeInfo
            ? executeTransactions(
                  safeInfo,
                  [buildRejectLoanMultisendTx(frendLendGuard(frendlendAddress), loanId, frendLend, userAddress)],
                  true,
              )
            : sendTransaction(
                  signer =>
                      rejectFrendLendOffer({
                          contract: getFrendLendContract(frendLendGuard(frendlendAddress)).connect(signer),
                          loanId,
                      }),
                  false,
              ).then(tx => ({ success: tx?.status === TXStatus.SUCCESS, transactionResult: tx }));
    }, setRejecting);

    return [
        { loanState, frendLendPending: executingViaGnosis || executing || accepting || rejecting, accepting, rejecting },
        { acceptLoan, rejectLoan, validateCreditorState },
    ] as const;
};
