import { maxBy } from 'lodash';
import { useMemo } from 'react';
import { AccountTagInfo, BullaItemInfo, ClaimInfo } from '../data-lib/data-model';
import { getPayables, getReceivables, isClaim } from '../data-lib/helpers';
import { pendingClaimAmountUSD, tokenAmountToUSD } from '../data-lib/tokens';
import { useGnosisSafe } from '../state/gnosis-state';
import { useTokenPrices } from './useChainData';
import { useGlobalUserData } from './useUserData';
import { useActingWalletAddress } from './useWalletAddress';

export type AccountTagSummary = {
    payable: number;
    receivable: number;
    items: BullaItemInfo[];
    netBalance: number;
};

export type UserSummary = {
    payable: {
        totalAmount: number;
        invoices: number;
        payments: number;
        total: number;
        overdue: { invoices: ClaimInfo[]; payments: ClaimInfo[] };
    };
    receivable: {
        totalAmount: number;
        invoices: number;
        payments: number;
        total: number;
        overdue: { invoices: ClaimInfo[]; payments: ClaimInfo[] };
    };
    netBalance: { balance: number; lastUpdated?: Date };
    accountTagSummary: { [tagName: string]: AccountTagSummary };
};

export const useUserSummary = () => {
    const { receivables, payables, accountTags } = useGlobalUserData('exclude-originating-claims');
    const userAddress = useActingWalletAddress();
    const {
        pendingPayments: { pendingInstantPaymentInfos },
    } = useGnosisSafe();
    const { getTokenPrice } = useTokenPrices();
    const payableClaims = payables.filter(isClaim);
    const receivableClaims = receivables.filter(isClaim);

    const userSummary = useMemo(() => {
        const currentTime = new Date().getTime();
        const payableTotal =
            pendingClaimAmountUSD(payables.filter(isClaim), getTokenPrice) +
            pendingInstantPaymentInfos.reduce(
                (acc, item) => acc + tokenAmountToUSD({ amount: item.paidAmount, tokenInfo: item.tokenInfo }, getTokenPrice),
                0,
            );
        const receivableTotal = pendingClaimAmountUSD(receivables.filter(isClaim), getTokenPrice);

        const getAccountTagInfo = (tags: AccountTagInfo[]) =>
            tags.reduce<{ [tagName: string]: AccountTagSummary }>((summary, { items, name }) => {
                const tagPayable = pendingClaimAmountUSD(getPayables(userAddress, items).filter(isClaim), getTokenPrice);
                const tagRecievable = pendingClaimAmountUSD(getReceivables(userAddress, items).filter(isClaim), getTokenPrice);
                const netBalance = tagRecievable - tagPayable;
                return {
                    ...summary,
                    [name]: {
                        items,
                        payable: tagPayable,
                        receivable: tagRecievable,
                        netBalance,
                    },
                };
            }, {});

        return {
            payableTotals: {
                invoices: payableClaims.filter(p => p.claimType === 'Invoice'),
                payments: payableClaims.filter(p => p.claimType === 'Payment'),
                totalAmount: payableTotal,
                count: payableClaims.filter(p => p.claimStatus === 'Pending').length,
                overdue: payableClaims.filter(
                    p => (p.dueBy.getTime() < currentTime && p.claimStatus === 'Pending') || p.claimStatus === 'Repaying',
                ),
            },
            receivableTotals: {
                invoices: receivableClaims.filter(r => r.claimType === 'Invoice'),
                payments: receivableClaims.filter(r => r.claimType === 'Payment'),
                totalAmount: receivableTotal,
                count: receivableClaims.filter(r => r.claimStatus === 'Pending').length,
                overdue: receivableClaims.filter(
                    r => (r.dueBy.getTime() < currentTime && r.claimStatus === 'Pending') || r.claimStatus === 'Repaying',
                ),
            },
            netBalance: {
                balance: receivableTotal - payableTotal,
                lastUpdated: maxBy([...payables, ...receivables], claim => claim.created)?.created,
            },
            accountTagSummary: getAccountTagInfo(accountTags),
        };
    }, [getTokenPrice, payables, receivables, accountTags]);

    return userSummary;
};
