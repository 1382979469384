{
    "id": "/orderSchema",
    "properties": {
        "makerAddress": {
            "$ref": "/addressSchema"
        },
        "takerAddress": {
            "$ref": "/addressSchema"
        },
        "makerFee": {
            "$ref": "/wholeNumberSchema"
        },
        "takerFee": {
            "$ref": "/wholeNumberSchema"
        },
        "senderAddress": {
            "$ref": "/addressSchema"
        },
        "makerAssetAmount": {
            "$ref": "/wholeNumberSchema"
        },
        "takerAssetAmount": {
            "$ref": "/wholeNumberSchema"
        },
        "makerAssetData": {
            "$ref": "/hexSchema"
        },
        "takerAssetData": {
            "$ref": "/hexSchema"
        },
        "makerFeeAssetData": {
            "$ref": "/hexSchema"
        },
        "takerFeeAssetData": {
            "$ref": "/hexSchema"
        },
        "salt": {
            "$ref": "/wholeNumberSchema"
        },
        "feeRecipientAddress": {
            "$ref": "/addressSchema"
        },
        "expirationTimeSeconds": {
            "$ref": "/wholeNumberSchema"
        },
        "chainId": {
            "type": "number"
        },
        "exchangeAddress": {
            "$ref": "/addressSchema"
        }
    },
    "required": [
        "makerAddress",
        "takerAddress",
        "makerFee",
        "takerFee",
        "senderAddress",
        "makerAssetAmount",
        "takerAssetAmount",
        "makerAssetData",
        "takerAssetData",
        "makerFeeAssetData",
        "takerFeeAssetData",
        "salt",
        "feeRecipientAddress",
        "expirationTimeSeconds",
        "chainId",
        "exchangeAddress"
    ],
    "type": "object"
}
