import { Log, Provider } from '@ethersproject/abstract-provider';
import { BullaItemEvent, getOnlyBullaItemRelatedLogs } from '../data-lib/data-transforms';
import { InstantPaymentEvent } from '../data-lib/domain/bulla-instant-pay-domain';
import { getERC721TransferEvents, isFrendLendEvent, isInstantPaymentEvent } from '../data-lib/dto/event-filters';
import { formatEventLogs } from '../data-lib/dto/events-dto';
import { EthAddress } from '../data-lib/ethereum';
import { NetworkConfig } from '../data-lib/networks';
import {
    getBullaTagUpdatedRequest,
    getClaimTransfersRequest,
    getBullaInfoItemCreatedRequest,
    getBullaInfoItemUpdatesRequest,
    RequestInterface,
    filterDuplicateEventLogs,
    getFactoringRequest,
} from './state-helpers';

/** Build a request interface - which contains our event listeners - from a set of ClaimEvents and the network config */
export const getEventInterfaces = (networkConfig: NetworkConfig, userAddress: EthAddress, events: BullaItemEvent[]): RequestInterface[] => {
    const claimIds = [...new Set(events.reduce<string[]>((ids, event) => ('tokenId' in event ? [...ids, event.tokenId] : ids), []))];
    const instantPaymentEvents = events.filter((ev): ev is InstantPaymentEvent => ev.__typename === 'InstantPaymentEvent');
    const transferredIds = getERC721TransferEvents(events).map(transfer => transfer.tokenId);
    const claimIdsToFetch: string[] = [...new Set([...claimIds, ...transferredIds])];

    const claimTransferRequest = getClaimTransfersRequest(userAddress, networkConfig.bullaClaimAddress);
    const claimFactoringRequests = getFactoringRequest();
    const infoItemCreatedRequest = getBullaInfoItemCreatedRequest(userAddress, networkConfig, transferredIds);
    const infoItemUpdatedRequest =
        !!claimIdsToFetch.length || !!instantPaymentEvents.length
            ? getBullaInfoItemUpdatesRequest(userAddress, claimIdsToFetch, networkConfig)
            : getBullaTagUpdatedRequest(networkConfig, userAddress); //TODO: huh?
    const eventInterfaces: RequestInterface[] = Object.values({
        ...claimTransferRequest,
        ...infoItemCreatedRequest,
        ...infoItemUpdatedRequest,
        ...claimFactoringRequests,
    });

    return eventInterfaces;
};

export const listenToEvents = (
    provider: Provider,
    eventInterfaces: RequestInterface[],
    onNewEvents: (events: BullaItemEvent[]) => void,
) => {
    eventInterfaces.forEach(({ fromAddress, topics, eventData }) =>
        provider.on({ address: fromAddress, topics }, (log: Log) => {
            const events = getOnlyBullaItemRelatedLogs(formatEventLogs(log, eventData));
            console.debug(
                '💥 subscription fired for',
                events.reduce((str, { __typename }) => `${str} ${__typename}`, ''),
            );

            onNewEvents(events);
        }),
    );
    console.debug(`Provider listening on ${provider.listenerCount()} events 📡`);
};

export const setupEventSynchronization = (
    provider: Provider,
    networkConfig: NetworkConfig,
    userAddress: EthAddress,
    events: BullaItemEvent[],
    setEvents: React.Dispatch<React.SetStateAction<BullaItemEvent[]>>,
    listen: boolean,
) => {
    let eventInterfaces = getEventInterfaces(networkConfig, userAddress, events);
    let eventsSinceLastRefresh: BullaItemEvent[] = [];

    const addNewEventsToAppState = (refreshListeners: (newLogs: BullaItemEvent[]) => void) => (events: BullaItemEvent[]) => {
        if (!!events)
            setEvents(prevLogs => {
                const newLogs = [...prevLogs, ...events];
                const uniqueLogs = filterDuplicateEventLogs(newLogs);
                refreshListeners(uniqueLogs);
                return uniqueLogs;
            });
    };

    const updateEvents = (newLogs: BullaItemEvent[]) => {
        if (newLogs.length === eventsSinceLastRefresh.length && !!eventsSinceLastRefresh.length) {
            eventsSinceLastRefresh = [];
            listen && eventInterfaces.forEach(({ fromAddress, topics }) => provider.off({ address: fromAddress, topics }));

            const newEventInterfaces = getEventInterfaces(networkConfig, userAddress, newLogs);
            listen && listenToEvents(provider, newEventInterfaces, addNewEventsToAppState(updateEvents));
            eventInterfaces = newEventInterfaces;
        } else if (newLogs.length > eventsSinceLastRefresh.length) {
            eventsSinceLastRefresh = newLogs;
            setTimeout(() => updateEvents([...eventsSinceLastRefresh]), 750);
        }
    };

    listen && listenToEvents(provider, eventInterfaces, addNewEventsToAppState(updateEvents));

    return { updateEvents: addNewEventsToAppState(updateEvents) };
};
