import React, { Dispatch, SetStateAction } from 'react';
import { tryParseJson } from '../tools/common';

const createStorageHook = (type: 'session' | 'local') => {
    const storageType: 'sessionStorage' | 'localStorage' = `${type}Storage`;

    return <T,>(storageKey: string, defaultValue?: any): [T, Dispatch<SetStateAction<T>>, VoidFunction] => {
        const storage = window[storageType];
        const [value, setValue] = React.useState<T>(tryParseJson(storage.getItem(storageKey)) ?? defaultValue);
        const clearStorage = () => {
            setValue(defaultValue);
            storage.setItem(storageKey, 'undefined');
        };

        React.useEffect(() => {
            if (value != undefined) storage.setItem(storageKey, JSON.stringify(value));
        }, [storageKey, value]);

        return [value, setValue, clearStorage];
    };
};

/**
 * Hook for synced storage (will have a state value that resolves to local | session storage)
 */
export const useSessionStorage = createStorageHook('session');

/**
 * Hook for synced storage (will have a state value that resolves to local | session storage)
 */
export const useLocalStorage = createStorageHook('local');
