import { Box, HStack, Image, Skeleton, SkeletonCircle, Spacer, Stack, Text, Tooltip, useBoolean } from '@chakra-ui/react';
import { BigNumber, utils } from 'ethers';
import React, { useEffect } from 'react';
import AirplaneLogo from '../../assets/airplane.svg';
import CoinsLogo from '../../assets/coins.svg';
import { FinancedClaimInfo, FrendLendOffer } from '../../data-lib/data-model';
import { getFinancingSummaryLabel } from '../../data-lib/dto/bulla-finance-dto';
import { getLoanOfferAcceptedEvents } from '../../data-lib/dto/event-filters';
import { addressEquality } from '../../data-lib/ethereum';
import { ZERO_BIGNUMBER } from '../../data-lib/helpers';
import { ChainId, NETWORKS, TokenDto, TokenInfo } from '../../data-lib/networks';
import { useOpenBullaItem, useOpenClaimDetails, useOpenLoanOffer } from '../../hooks/useClaimDetailDisclosure';
import { useFrendLend } from '../../hooks/useFrendLend';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { shortAddress } from '../base/address-label';
import { getLoanIcon } from '../base/loan-status-icon';
import { BullaBlueTextButton, SecondaryButton } from '../inputs/buttons';
import { MakePaymentModal } from '../modals/make-payment-modal/make-payment-modal';

export type LoanStatus = 'on-track' | 'due-soon' | 'past-due';

export type LoanStatusInfo = { status: LoanStatus; dueDate: Date };

export class Loan {
    statusInfo: LoanStatusInfo;
    remainingAmount: BigNumber;
    paidAmount: BigNumber;
    tokenInfo: TokenInfo;
    underlyingClaim: FinancedClaimInfo;

    constructor(financedClaim: FinancedClaimInfo) {
        const now = new Date();
        const dueDate = financedClaim.dueBy;
        const dueSoonDate = new Date(dueDate.getTime());
        dueSoonDate.setDate(dueSoonDate.getDate() - 30);
        const status: LoanStatus = +now > +dueDate ? 'past-due' : +now > +dueSoonDate ? 'due-soon' : 'on-track';

        this.statusInfo = { status, dueDate };
        this.paidAmount = financedClaim.paidAmount;
        this.remainingAmount = financedClaim.claimAmount.sub(financedClaim.paidAmount);
        this.tokenInfo = financedClaim.tokenInfo;
        this.underlyingClaim = financedClaim;
    }
}

export const getAmountLabel = (amount: BigNumber, token: TokenDto) => `${utils.formatUnits(amount, token.decimals)} ${token.symbol}`;

export const LoanCardChainLabel = ({ chainId }: { chainId: ChainId }) => {
    const config = NETWORKS[chainId];
    return (
        <Tooltip label={config.label ?? ''} placement="top">
            <Image src={config?.logoFileName ?? ''} maxH="23px" />
        </Tooltip>
    );
};

export const LoanCardTitle = ({ children }: { children: React.ReactNode }) => (
    <Text
        color="gray.900"
        fontWeight={700}
        fontSize="20px"
        lineHeight="28px"
        letterSpacing={'0.01em'}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        whiteSpace={'nowrap'}
    >
        {children}
    </Text>
);

export const RepaymentThermometer = ({ paidAmount, totalAmount }: { paidAmount: BigNumber; totalAmount: BigNumber }) => {
    const percentPaid = `${paidAmount.mul(100).div(totalAmount).toString()}%`;
    return (
        <HStack pos="relative">
            <Box width="100%" h="12px">
                <Box pos="absolute" w="100%" h="12px" bg="gray.200" borderRadius={'34px'} />
                <Box pos="absolute" w={percentPaid} h="12px" bg="brand.bulla_orange" borderRadius={'34px'} />
            </Box>
        </HStack>
    );
};

export const SkeletonCard = () => (
    <Box bg="white" p="6" borderRadius={'8px'}>
        <Stack spacing="8">
            <HStack>
                <SkeletonCircle size="23px" />
                <Skeleton w="8em">
                    <LoanCardTitle>Invisible</LoanCardTitle>
                </Skeleton>
            </HStack>
            <Stack spacing="3.5">
                <HStack>
                    <Skeleton>
                        <Text>Paid Amount Skele</Text>
                    </Skeleton>
                    <Spacer />
                    <Skeleton>
                        <Text>Remaining Amt</Text>
                    </Skeleton>
                </HStack>
                <Skeleton>
                    <RepaymentThermometer paidAmount={ZERO_BIGNUMBER} totalAmount={BigNumber.from(1)} />
                </Skeleton>
            </Stack>
            <HStack spacing="5">
                <Spacer />
                <Skeleton>
                    <SecondaryButton>Primary</SecondaryButton>
                </Skeleton>
            </HStack>
        </Stack>
    </Box>
);

export const LoanCard = ({ loan }: { loan: Loan }) => {
    const [showPaymentModal, setShowPaymentModal] = useBoolean(false);
    const openItem = useOpenBullaItem();
    const userAddress = useActingWalletAddress();
    const isPayable = addressEquality(userAddress, loan.underlyingClaim.debtor);
    const viewLoan = () => openItem(loan.underlyingClaim);

    return (
        <Box bg="white" p="6" borderRadius={'8px'}>
            <Stack spacing="8">
                <HStack>
                    <LoanCardChainLabel chainId={loan.underlyingClaim.chainId} />
                    <LoanCardTitle>{loan.underlyingClaim.description}</LoanCardTitle>
                    <Spacer />
                    {getLoanIcon(loan.statusInfo)}
                </HStack>
                <Stack spacing="3.5">
                    <HStack>
                        <Text color="brand.400">{getAmountLabel(loan.paidAmount, loan.tokenInfo.token)} Paid</Text>
                        <Spacer />
                        <Text color="gray.900">{getAmountLabel(loan.remainingAmount, loan.tokenInfo.token)} Remaining</Text>
                    </HStack>
                    <RepaymentThermometer paidAmount={loan.paidAmount} totalAmount={loan.underlyingClaim.claimAmount} />
                </Stack>
                <HStack spacing="5">
                    <BullaBlueTextButton minW="fit-content" onClick={viewLoan} textDecoration="none" justifyContent={'normal'}>
                        View Details
                    </BullaBlueTextButton>
                    <Spacer />
                    {isPayable && (
                        <SecondaryButton w="100%" onClick={setShowPaymentModal.on} maxW="220px">
                            Make a Payment
                        </SecondaryButton>
                    )}
                </HStack>
            </Stack>
            {<MakePaymentModal isOpen={showPaymentModal} onClose={setShowPaymentModal.off} claim={loan.underlyingClaim} />}
        </Box>
    );
};

export const FrendLendCard = ({ frendLend }: { frendLend: FrendLendOffer }) => {
    const userAddress = useActingWalletAddress();
    const [{ frendLendPending, loanState }, { acceptLoan, rejectLoan, validateCreditorState }] = useFrendLend();
    const openLoanOffer = useOpenLoanOffer();
    const openClaim = useOpenClaimDetails();
    const {
        connectedNetwork,
        connectedNetworkConfig: { frendlendAddress },
    } = useWeb3();
    const isOfferSent = addressEquality(userAddress, frendLend.creditor);
    const otherUserAddress = isOfferSent ? frendLend.debtor : frendLend.creditor;

    const headerText = isOfferSent ? 'FrendLend offer sent' : 'FrendLend offer available!';
    const senderText = isOfferSent ? `Sent to ${shortAddress(otherUserAddress, 3)}` : `Sent from ${shortAddress(otherUserAddress, 3)}`;
    const secondaryButtonText = isOfferSent ? 'Rescind Loan' : 'Accept Loan';

    useEffect(() => {
        if (frendlendAddress && frendLend) validateCreditorState(frendlendAddress, frendLend);
    }, [JSON.stringify(frendLend), frendlendAddress]);

    return (
        <Box bg="white" p="6" borderRadius={'8px'} h="fit-content">
            <Stack h="fit-content">
                <Box h="41px">{isOfferSent ? <Image h="41px" w="41px" src={AirplaneLogo} /> : <Image maxW={'50px'} src={CoinsLogo} />}</Box>
                <Stack spacing="0">
                    <HStack>
                        <LoanCardChainLabel chainId={frendLend.chainId} />
                        <LoanCardTitle>{headerText}</LoanCardTitle>
                    </HStack>
                    <Box h="1" />
                    <Stack spacing="2">
                        <Text lineHeight={'20px'} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            Loan for: <b>{frendLend.description}</b>
                        </Text>
                        <HStack alignItems={'center'}>
                            <Tooltip placement="top" variant="address" label={otherUserAddress}>
                                <Box bg="gray.400" borderRadius={'6px'} px="8px" py="2px">
                                    <Text fontSize={'12px'} lineHeight={'16px'} color={'white'} whiteSpace="nowrap">
                                        {senderText}
                                    </Text>
                                </Box>
                            </Tooltip>
                            <Text fontSize={'12px'} lineHeight="16px" color="gray.400">
                                {getFinancingSummaryLabel(frendLend.tokenInfo.token, {
                                    interestRateBPS: frendLend.interestBPS,
                                    loanValue: frendLend.loanAmount,
                                })}
                            </Text>
                        </HStack>
                    </Stack>
                    <Box h="5" />
                    <HStack spacing="5">
                        <BullaBlueTextButton
                            onClick={() =>
                                isOfferSent
                                    ? rejectLoan(frendLend.loanId)
                                    : acceptLoan(frendLend.loanId).then(({ success, transactionResult }) => {
                                          if (success && transactionResult) {
                                              const [acceptedEvent] = getLoanOfferAcceptedEvents(transactionResult.events);
                                              if (acceptedEvent) openClaim(acceptedEvent.tokenId, frendLend.chainId);
                                          }
                                      })
                            }
                            textDecoration="none"
                            paddingLeft="16px"
                            paddingRight="16px"
                            isDisabled={
                                frendLendPending || frendLend.chainId !== connectedNetwork || (!isOfferSent && loanState !== 'ready')
                            }
                        >
                            {secondaryButtonText}
                        </BullaBlueTextButton>
                        <Spacer />
                        <SecondaryButton
                            w="100%"
                            maxW="220px"
                            onClick={() => openLoanOffer(frendLend.loanId, frendLend.chainId)}
                            isDisabled={frendLendPending}
                        >
                            Review lending terms
                        </SecondaryButton>
                    </HStack>
                </Stack>
            </Stack>
        </Box>
    );
};
