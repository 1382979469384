import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { ContractTransaction } from '@ethersproject/contracts';
import { BigNumber, Contract, ethers } from 'ethers';
import React, { useEffect } from 'react';
import { ClaimInfo } from '../data-lib/data-model';
import {
    BullaClaimDto,
    getPayClaimTransaction,
    getRejectClaimTransaction,
    getRescindClaimTransaction,
    payClaim,
    rejectClaim,
    rescindClaim,
} from '../data-lib/dto/bulla-claim-dto';
import { FinancingTerms } from '../data-lib/dto/bulla-finance-dto';
import { getCreateFinanciableInvoiceTransaction } from '../data-lib/dto/bulla-claim-dto';
import { getBullaBankerModuleContract, getBullaClaimContract } from '../data-lib/dto/contract-interfaces';
import { TransactionResult } from '../data-lib/dto/events-dto';
import { EthAddress, weiToDisplayAmt } from '../data-lib/ethereum';
import { MAX_MUTLI_SEND_TXS } from '../data-lib/gnosis-tools/gnosis';
import { ZERO_BIGNUMBER } from '../data-lib/helpers';
import { NETWORKS, TXStatus } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { useAllowances } from './useAllowances';
import { useTokenBalances } from './useChainData';
import {
    MinimalGnosisItemInfo,
    MultisendPayTransaction,
    MultisendTransaction,
    NonPayingMultisendTransaction,
    useGnosisGlobalMultisend,
} from './useGnosisMultisend';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useSendTransaction } from './useSendTransaction';
import { useWeb3 } from './useWeb3';
import { BullaFinance } from '@bulla-network/contracts/typechain/BullaFinance';

export const useClaimFunction = () => {
    const { connectedNetworkConfig } = useWeb3();
    const { safeInfo, inSafeApp } = useGnosisSafe();
    const [pending, sendTransaction] = useSendTransaction();

    const execute = async (
        claimFunction: (contract: Contract) => Promise<ContractTransaction>,
        payingTransaction: boolean,
    ): Promise<TransactionResult | undefined> =>
        sendTransaction(
            signer =>
                claimFunction(
                    (safeInfo?.module?.moduleAddress && !inSafeApp
                        ? getBullaBankerModuleContract(safeInfo.module.moduleAddress)
                        : getBullaClaimContract(connectedNetworkConfig.bullaClaimAddress)
                    ).connect(signer),
                ),
            payingTransaction,
        );

    const functions = {
        payClaim: (claimInfo: ClaimInfo, amountToPay?: BigNumber) =>
            execute((contract: Contract) => payClaim({ contract, claimInfo, amountToPay }), true),
        rejectClaim: (claimInfo: ClaimInfo) => execute((contract: Contract) => rejectClaim({ contract, claimInfo }), false),
        rescindClaim: (claimInfo: ClaimInfo) => execute((contract: Contract) => rescindClaim({ contract, claimInfo }), false),
    };

    return [pending, functions] as const;
};

export type usePayClaimProps = { claimInfo: ClaimInfo | undefined; customAmountToPay?: BigNumber };
export const usePayClaim = ({ claimInfo, customAmountToPay }: usePayClaimProps) => {
    const {
        userAddress,
        connectedNetwork,
        connectedNetworkConfig: { bullaClaimAddress, batchCreate },
    } = useWeb3();
    const [payingClaim, { payClaim: default_payClaim }] = useClaimFunction();
    const { safeInfo, isSafeReady, pendingPayments } = useGnosisSafe();
    const { loading: gnosis_payingClaim, executeTransactions } = useGnosisTransaction();
    const { addTransaction, removeTransaction, transactions, isTokenIdInBundle } = useGnosisGlobalMultisend();
    const claimChainId = claimInfo?.chainId ?? connectedNetwork;
    const tokenBalances = useTokenBalances({ chainId: claimChainId, poll: true });
    const amountToPay = customAmountToPay ?? claimInfo?.claimAmount.sub(claimInfo.paidAmount) ?? ZERO_BIGNUMBER;
    const [approving, { approveTokens, getAllowancesRequired }] = useAllowances('exact-allowance');
    const [insufficientFunds, setInsufficientFunds] = React.useState<boolean | undefined>(undefined);
    const [needsApproval, setNeedsApproval] = React.useState<'init' | boolean>('init');
    const [performing, setPerforming] = React.useState(false);
    const getAllowancesRequiredForBullaClaim = getAllowancesRequired(bullaClaimAddress, claimChainId);
    const approveTokensForBullaClaim = approveTokens(bullaClaimAddress);

    const performAsync = async <T>(func: () => Promise<T>) => {
        setPerforming(true);
        const result = await func();
        setPerforming(false);
        return result;
    };

    const tokenId = claimInfo?.id;
    const claimInMultisendBundle = !!tokenId && isTokenIdInBundle(tokenId);
    const atMaximumMultisendTxs = Object.values(transactions).length >= (batchCreate?.maxClaims * 2 ?? MAX_MUTLI_SEND_TXS);
    const multisigPendingWithClaim = pendingPayments.payClaimTransactions.some(item => item.tokenId === tokenId);
    // if a gnosis-safe is connected, ignore this flag as approvals are handled by multisend
    useEffect(() => {
        if (claimInfo) {
            performAsync(() =>
                getAllowancesRequiredForBullaClaim([{ token: claimInfo.tokenInfo.token, amount: amountToPay }]).then(x =>
                    setNeedsApproval(x.tokensRequireApproval),
                ),
            );
        }
    }, [amountToPay._hex, claimInfo?.tokenInfo.token.address ?? 'not-loaded']);

    const isClaimPayable =
        claimInfo?.claimStatus === 'Pending' || claimInfo?.claimStatus === 'Repaying' || claimInfo?.claimStatus === 'Factored';
    const isPaying = payingClaim || gnosis_payingClaim;
    const isReady = insufficientFunds !== undefined && !performing && needsApproval !== 'init';
    const isOnConnectedNetwork = claimChainId === connectedNetwork;

    const generalDisabled =
        !isReady || !tokenId || !isClaimPayable || !!insufficientFunds || isPaying || multisigPendingWithClaim || !isOnConnectedNetwork;
    const buttonConfig = {
        payButton: {
            labels: {
                tooltip: !isOnConnectedNetwork
                    ? 'Incorrect network'
                    : !isReady
                    ? 'Fetching token balance'
                    : insufficientFunds
                    ? `Insufficient ${claimInfo?.tokenInfo?.token?.symbol ? claimInfo?.tokenInfo?.token?.symbol + ' ' : ''}Balance`
                    : '',
                button: isReady ? (needsApproval ? 'Pay (Approve Token)' : 'Pay') : '',
            },
            isLoading: !isReady || approving || isPaying,
            isDisabled: generalDisabled || !isReady || approving || isPaying || claimInMultisendBundle || !isOnConnectedNetwork,
        },
        addButton: isSafeReady
            ? {
                  labels: {
                      toolip: atMaximumMultisendTxs
                          ? `Maximum transaction amount`
                          : `${claimInMultisendBundle ? 'Remove from' : 'Add to'} transaction bundle`,
                  },
                  icon: claimInMultisendBundle ? MinusIcon : AddIcon,
                  isDisabled: multisigPendingWithClaim || !isReady || approving || isPaying || insufficientFunds || atMaximumMultisendTxs,
                  isLoading: generalDisabled || needsApproval || approving,
                  action: claimInMultisendBundle ? 'remove' : 'add',
              }
            : undefined,
    };

    const [approveModalOpen, setApproveModalOpen] = React.useState(false);
    const closeApproveModal = () => {
        setApproveModalOpen(false);
    };

    const handleApprove = async () => {
        setApproveModalOpen(true);
        if (claimInfo?.tokenInfo?.token.address) {
            const { success } = await approveTokensForBullaClaim([{ token: claimInfo.tokenInfo.token, amount: amountToPay }]);
            success && setNeedsApproval(false);
        }
        closeApproveModal();
    };

    const handleAdd = () => {
        if (claimInfo && !atMaximumMultisendTxs && amountToPay) {
            const multisendTransaction = buildPayMultisendTransaction(bullaClaimAddress, amountToPay, claimInfo);
            claimInMultisendBundle ? removeTransaction(multisendTransaction) : addTransaction(multisendTransaction);
        }
    };

    const payClaim = async () => {
        if (claimInfo && amountToPay) {
            return performAsync<boolean>(async () =>
                !!safeInfo
                    ? (await executeTransactions(safeInfo, [buildPayMultisendTransaction(bullaClaimAddress, amountToPay, claimInfo)], true))
                          .success
                    : (await default_payClaim(claimInfo, amountToPay))?.status === TXStatus.SUCCESS,
            );
        }
        return false;
    };

    const handlePay = needsApproval ? handleApprove : payClaim;

    const getSufficientFunds = async () => {
        if (tokenBalances && claimInfo?.tokenInfo && amountToPay.gt(ZERO_BIGNUMBER)) {
            const { address } = claimInfo.tokenInfo.token;
            const insufficientFunds =
                (tokenBalances.getBalanceForToken(address) ?? 0) <
                weiToDisplayAmt({ amountWei: amountToPay, token: claimInfo.tokenInfo.token });
            setInsufficientFunds(insufficientFunds);
        }
    };

    React.useEffect(() => {
        performAsync(getSufficientFunds);
    }, [tokenBalances.nonce, claimInfo, userAddress, connectedNetwork, amountToPay.toString()]);

    return {
        isReady,
        handlePay,
        handleAdd,
        buttonConfig,
        performing,
        approveModalOpen,
        closeApproveModal,
    } as const;
};

export function buildRejectMultisendTransaction(bullaClaimAddress: string, claimInfo: ClaimInfo): MultisendTransaction {
    return {
        label: `Reject claim #${claimInfo.id}`,
        transactionInput: getRejectClaimTransaction(bullaClaimAddress, claimInfo.id),
        itemInfo: claimInfo,
        interaction: 'Reject',
    };
}

export function buildPayMultisendTransaction(
    bullaClaimAddress: string,
    paymentAmount: BigNumber,
    claimInfo: ClaimInfo,
): MultisendPayTransaction {
    return {
        label: `Pay claim #${claimInfo.id}`,
        transactionInput: getPayClaimTransaction(bullaClaimAddress, claimInfo.id, paymentAmount),
        itemInfo: claimInfo,
        interaction: 'Pay',
        approvalNeeded: {
            amount: paymentAmount,
            spendingContract: bullaClaimAddress,
        },
        token: claimInfo.tokenInfo.token,
        paymentAmount,
    };
}

export function buildRescindMultisendTransaction(bullaClaimAddress: string, claimInfo: ClaimInfo): MultisendTransaction {
    return {
        label: `Rescind claim #${claimInfo.id}`,
        transactionInput: getRescindClaimTransaction(bullaClaimAddress, claimInfo.id),
        itemInfo: claimInfo,
        interaction: 'Rescind',
    };
}

export const financeableInvoiceToMultisendTxDTO = async ({
    contract,
    claim,
    financingTerms,
}: {
    contract: BullaFinance;
    claim: BullaClaimDto;
    financingTerms: FinancingTerms;
}): Promise<{ transaction: MultisendTransaction }> => {
    return {
        transaction: await buildCreateFinanciableInvoiceMultisendTx(claim, contract, financingTerms),
    };
};

export async function buildCreateFinanciableInvoiceMultisendTx(
    claim: BullaClaimDto,
    contract: BullaFinance,
    financingTerms: FinancingTerms,
): Promise<NonPayingMultisendTransaction> {
    const minimalClaimInfo: MinimalGnosisItemInfo = {
        __type: 'Claim',
        description: claim.description,
        creditor: claim.creditor,
        debtor: claim.debtor,
        id: '',
    };

    return {
        label: `Create Financiable Invoice to ${claim.creditor} `,
        transactionInput: await getCreateFinanciableInvoiceTransaction({
            bullaFinanceContract: contract,
            claim,
            tokenURI: claim.tokenURI,
            attachment: claim.ipfsHash,
            financingTerms,
        }),
        itemInfo: minimalClaimInfo,
        interaction: 'Claim Financing',
    };
}
