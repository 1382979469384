import React from 'react';
import { isUserReady, Membership, useAuth } from '../state/auth-state';
import { useGnosisSafe } from '../state/gnosis-state';
import { overridePremiumStatus } from '../tools/featureFlags';

const MembershipContext = React.createContext<Required<Membership> | null | undefined>(undefined);

export const MembershipProvider = ({ children }: { children: React.ReactNode }) => {
    const { user } = useAuth();
    const { connectedSafeAddress } = useGnosisSafe();

    const context = React.useMemo(() => {
        const now = new Date().getTime() / 1000;
        if (overridePremiumStatus || !!connectedSafeAddress)
            return { exp: now + 60 * 60 * 24 * 365, isFreeTrial: false, membershipIds: [] };
        if (isUserReady(user)) {
            if (user.__DONT_USE_DIRECTLY_memberships !== undefined) {
                const activeMemberships = user.__DONT_USE_DIRECTLY_memberships.filter(x => x.exp > now).sort((a, b) => b.exp - a.exp);

                if (activeMemberships.length > 0)
                    return { exp: activeMemberships[0].exp, isFreeTrial: false, membershipIds: activeMemberships.map(x => x.id) };
            }

            return !!user.__DONT_USE_DIRECTLY_membership && user.__DONT_USE_DIRECTLY_membership.exp > now
                ? {
                      ...user.__DONT_USE_DIRECTLY_membership,
                      isFreeTrial: !!user.__DONT_USE_DIRECTLY_membership.isFreeTrial,
                      membershipIds: [],
                  }
                : null;
        }

        return null;
    }, [user, connectedSafeAddress]);

    return <MembershipContext.Provider value={context}>{children}</MembershipContext.Provider>;
};

export const useMembership = () => {
    const context = React.useContext(MembershipContext);
    if (context === undefined) throw new Error('useMembership must me used within the Membership provider');
    return context;
};

export const useMembershipUnsafe = () => {
    const context = React.useContext(MembershipContext);
    return context === undefined ? 'not-connected' : context;
};
