import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import { ContractTransaction, Signer } from 'ethers';
import { useState } from 'react';
import { getOnlyBullaItemRelatedLogs } from '../data-lib/data-transforms';
import { getEventsFromReceipt, TransactionResult } from '../data-lib/dto/events-dto';
import { getGnosisSafeURL } from '../data-lib/gnosis-tools/gnosis';
import { awaitSafeAppTransaction } from '../data-lib/gnosis-tools/transactions';
import { useAppState } from '../state/app-state';
import { useGnosisSafe } from '../state/gnosis-state';
import { useUIState } from '../state/ui-state';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useWaitForItemEventFromTx } from './useWaitForEvent';
import { useWeb3 } from './useWeb3';

export const useSendTransaction = () => {
    const [executingTx, setExecutingTx] = useState<boolean>(false);
    const { signerProvider, signer, connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { sdk } = useSafeAppsSDK();
    const { safeInfo, inSafeApp } = useGnosisSafe();
    const { addPendingTxn, addErrorMessage, addAlert } = useUIState();
    const { loading: gnosisTxPending } = useGnosisTransaction();
    const [searching, eventFound, waitForLogFromTx] = useWaitForItemEventFromTx();
    const { addEventsToAppState } = useAppState();
    const pending = executingTx || gnosisTxPending || (searching && !eventFound);

    const execute = async (
        launchTransaction: (signer: Signer) => Promise<ContractTransaction>,
        txSentToGnosis: boolean,
    ): Promise<TransactionResult | undefined> => {
        setExecutingTx(true);
        try {
            const tx = await launchTransaction(signer);
            const transactionHash = inSafeApp ? await awaitSafeAppTransaction(sdk, tx.hash) : tx.hash;
            const cleanupAlert =
                safeInfo &&
                txSentToGnosis &&
                addAlert({
                    message: 'Gnosis transaction created. Please confirm with your signatories.',
                    link: getGnosisSafeURL(connectedNetworkConfig, safeInfo.safeAddress, 'transactions'),
                });

            await addPendingTxn(signerProvider, connectedNetwork, transactionHash);
            const receipt = await waitForLogFromTx(transactionHash);
            if (cleanupAlert) cleanupAlert();
            const eventsFromTxReceipt = getEventsFromReceipt(receipt);
            addEventsToAppState(getOnlyBullaItemRelatedLogs(eventsFromTxReceipt));
            return {
                ...receipt,
                events: eventsFromTxReceipt,
            };
        } catch (e: any) {
            console.error(e);
            addErrorMessage(e);
        } finally {
            setExecutingTx(false);
        }
    };

    return [pending, execute] as const;
};
