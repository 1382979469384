import React from 'react';
import { Box, Text, Flex, Divider } from '@chakra-ui/react';
import { TrendDown, TrendUp } from 'phosphor-react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

interface PositionCardProps {
    currentTokenBalance: string;
    currentPrice: number | null;
    todayReturn: { absolute: number; percentage: number };
    totalReturn: { absolute: number; percentage: number };
    underlyingTokenSymbol: string;
}

const formatPositionValue = (balance: number, price: number | null, symbol: string): string => {
    if (!price) return 'N/A';
    const value = balance * price;
    return `${symbol} ${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
};

export const PositionCard: React.FC<PositionCardProps> = ({
    currentTokenBalance,
    currentPrice,
    todayReturn,
    totalReturn,
    underlyingTokenSymbol,
}) => {
    return (
        <Box borderWidth="1px" borderRadius="lg" p="6" shadow="md" w="100%">
            <Text fontSize="xl" fontWeight="bold" mb="2">
                Your Position
            </Text>
            <Text fontSize="3xl" fontWeight="bold" mb="6">
                {formatPositionValue(Number(currentTokenBalance), currentPrice, underlyingTokenSymbol)}
            </Text>
            <Flex justify="space-between" flex="">
                <Text color="gray.600">Today's Return</Text>
                <Flex align="center" gap="2">
                    <Text color="gray.900" mr="1" fontWeight={'500'}>
                        {todayReturn.absolute >= 0 ? '+' : '-'} {underlyingTokenSymbol} {Math.abs(todayReturn.absolute).toFixed(3)}
                    </Text>
                    {todayReturn.percentage == 0 ? null : todayReturn.percentage > 0 ? (
                        <TrendUp size={16} color="#17B26A" weight="bold" />
                    ) : (
                        <TrendDown size={16} color="#E53E3E" weight="bold" />
                    )}
                    <Text color={todayReturn.percentage >= 0 ? '#067647' : '#C53030'} ml="-1">
                        {todayReturn.percentage >= 0 ? '+' : '-'}
                        {Math.abs(todayReturn.percentage).toFixed(2)}%
                    </Text>
                </Flex>
            </Flex>

            <Divider my="3" />

            <Flex justify="space-between">
                <Text color="gray.600">Total Return</Text>
                <Flex align="center" gap="2">
                    <Text color="gray.900" mr="1" fontWeight={'500'}>
                        {totalReturn.absolute >= 0 ? '+' : '-'} {underlyingTokenSymbol} {Math.abs(totalReturn.absolute).toFixed(3)}
                    </Text>
                    {totalReturn.percentage === 0 ? null : totalReturn.percentage > 0 ? (
                        <TrendUp size={16} color="#17B26A" weight="bold" />
                    ) : (
                        <TrendDown size={16} color="#E53E3E" weight="bold" />
                    )}
                    <Text color={totalReturn.percentage >= 0 ? '#067647' : '#C53030'} ml="-1">
                        {totalReturn.percentage >= 0 ? '+' : '-'}
                        {Math.abs(totalReturn.percentage).toFixed(2)}%
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
};

interface AverageCostCardProps {
    averageCost: number | null;
    currentTokenBalance: string;
    tokensAvailableForRedemption: BigNumber | null;
    fundTokenInfo: {
        symbol: string;
        decimals: number;
    };
    underlyingTokenSymbol: string;
}

export const AverageCostCard: React.FC<AverageCostCardProps> = ({
    averageCost,
    currentTokenBalance,
    tokensAvailableForRedemption,
    fundTokenInfo,
    underlyingTokenSymbol,
}) => {
    return (
        <Box borderWidth="1px" borderRadius="lg" p="6" shadow="md" w="100%">
            <Text fontSize="xl" fontWeight="bold" mb="2">
                Your Average Cost
            </Text>
            <Text fontSize="3xl" fontWeight="bold" mb="6">
                {averageCost ? `${underlyingTokenSymbol} ${averageCost.toFixed(2)}` : 'N/A'}
            </Text>
            <Flex justify="space-between">
                <Text color="gray.600">Tokens Owned</Text>
                <Text fontWeight={'500'}>
                    {currentTokenBalance} {fundTokenInfo.symbol}
                </Text>
            </Flex>

            <Divider my="3" />

            <Flex justify="space-between">
                <Text color="gray.600">Tokens Available to Redeem</Text>
                <Text fontWeight={'500'}>
                    {tokensAvailableForRedemption &&
                        `${Number(formatUnits(tokensAvailableForRedemption, fundTokenInfo.decimals)).toLocaleString('en-US', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        })} ${fundTokenInfo.symbol}`}
                </Text>
            </Flex>
        </Box>
    );
};
