import { BigNumber } from 'ethers';
import moment from 'moment';
import { BullaItemInfo } from '../data-lib/data-model';
import { DrawdownOnReceivableEvent, ExtraFundsDispersedEvent, HumaFactorEvent } from '../data-lib/domain/common-domain';
import { EthAddress, weiToDisplayAmt } from '../data-lib/ethereum';
import { getItemRelationToUser, hasIPFSHash, isFactored, ZERO_BIGNUMBER } from '../data-lib/helpers';
import { toDateWithTime } from './common';

const csvHeaders = [
    'Payment Date',
    'Direction',
    'From',
    'To',
    'Payment Amount',
    'Token',
    'Status',
    'Description',
    'Categories',
    'Transaction Hash',
    'Document',
];

const createRow = (item: BullaItemInfo, paymentDate: Date | undefined, direction: string, amount: BigNumber | number) => [
    paymentDate ? toDateWithTime(paymentDate) : '',
    direction,
    item.debtor,
    item.creditor,
    weiToDisplayAmt({ amountWei: amount, token: item.tokenInfo.token }).toString(),
    item.tokenInfo.token.symbol ?? '',
    item.__type === 'Claim' ? item.claimStatus : 'Paid',
    item.description ? item.description : '',
    item.tags.join(';'),
    item.txHash ?? '',
    hasIPFSHash(item) ? `https://bulla.mypinata.cloud/ipfs/${item.ipfsHash}` : '',
];

const itemsToFlatArray = (userAddress: EthAddress, items: BullaItemInfo[]): string[][] =>
    items
        .sort((a, b) => a.created.getTime() - b.created.getTime())
        .flatMap(item => {
            const { direction } = getItemRelationToUser(userAddress, item);
            const paymentDate =
                item.__type === 'Claim'
                    ? item.logs.find(l => l.__typename === 'ClaimPaymentEvent' && l.paymentAmount.eq(item.claimAmount))?.blocktime
                    : item.created;

            if (item.__type === 'Claim' && isFactored(item, userAddress)) {
                const drawdownEvent = item.logs.find(l => l.eventType === 'DrawdownOnReceivableEvent');
                const extraFundsEvent = item.logs.find(l => l.eventType === 'ExtraFundsDispersedEvent');

                return [
                    ...(drawdownEvent
                        ? [
                              createRow(
                                  item,
                                  paymentDate,
                                  direction,
                                  Number((drawdownEvent as DrawdownOnReceivableEvent).netAmountToBorrower!),
                              ),
                          ]
                        : []),
                    ...(extraFundsEvent
                        ? [createRow(item, paymentDate, direction, Number((extraFundsEvent as ExtraFundsDispersedEvent).amount))]
                        : []),
                ];
            }
            const paidAmount =
                item.__type === 'Claim'
                    ? item.logs.reduce((acc, l) => (l.__typename === 'ClaimPaymentEvent' ? acc.add(l.paymentAmount) : acc), ZERO_BIGNUMBER)
                    : item.paidAmount;

            return [createRow(item, paymentDate, direction, paidAmount)];
        });

const arrayToCsv = (data: (string | number | Date)[][]) => {
    // the first row in data is the header row
    const headers = data[0] as string[];

    const indexesToQuote = headers
        .map((header, index) => (['categories', 'description', 'notes'].includes(header) ? index : -1))
        .filter(index => index !== -1);

    const quoteFields = (value: string | number | Date, index: number) => {
        if (indexesToQuote.includes(index)) {
            let v = String(value);
            v = v.replace(/"/g, '""');
            return `"${v}"`;
        } else {
            return String(value);
        }
    };

    return data.map(row => row.map(quoteFields).join(',')).join('\r\n');
};

export const exportCsv = (label: string, columns: string[], rows: (string | number | Date)[][]) => {
    const data = rows.length > 0 ? [columns, ...rows] : [columns];
    const csvData = arrayToCsv(data);

    const blob = new Blob([csvData], { type: 'csv' });
    const url = URL.createObjectURL(blob);

    // Create a link to download it
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Bulla-Export-${label ? label.toUpperCase() + '-' : ''}${moment().format('DD-MMM-YYYY-HH-mm-SS')}.csv`);
    link.click();
};
