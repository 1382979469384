import React from 'react';
import { AreYouSureModal } from './are-you-sure-modal';
import { Contact, useExtendedContacts, isContactsReady } from '../../hooks/useExtendedContacts';
import { useBullaToast } from '../base/toast';

export type DeleteContactsModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    selectedContacts: Contact[];
};

export const DeleteContactModal = ({ triggerElement, selectedContacts }: DeleteContactsModalProps) => {
    const contactsContext = useExtendedContacts();
    const triggerToast = useBullaToast();

    return (
        <AreYouSureModal
            triggerElement={triggerElement}
            title={'Delete contacts'}
            message={'Are you sure? This action cannot be undone.'}
            onClick={async () => {
                if (isContactsReady(contactsContext)) {
                    await contactsContext.deleteContactsAsync(selectedContacts);
                    triggerToast({ title: 'Contact deleted successfully.', position: 'top', variant: 'top-accent' });
                }
            }}
            buttonText={`Delete${selectedContacts.length > 0 ? ` (${selectedContacts.length})` : ''}`}
        />
    );
};
