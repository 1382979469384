import React, { SVGProps } from 'react';

export const GnosisSafeIcon = ({ ...props }: Partial<SVGProps<SVGSVGElement>>) => (
    <svg width="20" height="20" viewBox="0 0 661.62 661.47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M531.98,330.7h-49.42c-14.76,0-26.72,11.96-26.72,26.72v71.73c0,14.76-11.96,26.72-26.72,26.72H232.51c-14.76,0-26.72,11.96-26.72,26.72v49.42c0,14.76,11.96,26.72,26.72,26.72h207.99c14.76,0,26.55-11.96,26.55-26.72v-39.65c0-14.76,11.96-25.23,26.72-25.23h38.2c14.76,0,26.72-11.96,26.72-26.72v-83.3c0-14.76-11.96-26.41-26.72-26.41Z"
            fill="currentColor"
        />
        <path
            d="M205.78,232.52c0-14.76,11.96-26.72,26.72-26.72h196.49c14.76,0,26.72-11.96,26.72-26.72v-49.42c0-14.76-11.96-26.72-26.72-26.72H221.11c-14.76,0-26.72,11.96-26.72,26.72v38.08c0,14.76-11.96,26.72-26.72,26.72h-38.03c-14.76,0-26.72,11.96-26.72,26.72v83.39c0,14.76,12.01,26.12,26.77,26.12h49.42c14.76,0,26.72-11.96,26.72-26.72l-.05-71.44Z"
            fill="currentColor"
        />
        <path
            d="M307.55,278.75h47.47c15.47,0,28.02,12.56,28.02,28.02v47.47c0,15.47-12.56,28.02-28.02,28.02h-47.47c-15.47,0-28.02-12.56-28.02-28.02v-47.47c0-15.47,12.56-28.02,28.02-28.02Z"
            fill="currentColor"
        />
    </svg>
);

export const WalletIcon = ({ ...props }: Partial<SVGProps<SVGSVGElement>>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.75 6V18C3.75 18.3978 3.90804 18.7794 4.18934 19.0607C4.47064 19.342 4.85218 19.5 5.25 19.5H20.25C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75V8.25C21 8.05109 20.921 7.86032 20.7803 7.71967C20.6397 7.57902 20.4489 7.5 20.25 7.5H5.25C4.85218 7.5 4.47064 7.34196 4.18934 7.06066C3.90804 6.77936 3.75 6.39782 3.75 6ZM3.75 6C3.75 5.60218 3.90804 5.22064 4.18934 4.93934C4.47064 4.65804 4.85218 4.5 5.25 4.5H18"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.875 14.4375C17.3928 14.4375 17.8125 14.0178 17.8125 13.5C17.8125 12.9822 17.3928 12.5625 16.875 12.5625C16.3572 12.5625 15.9375 12.9822 15.9375 13.5C15.9375 14.0178 16.3572 14.4375 16.875 14.4375Z"
            fill="currentColor"
        />
    </svg>
);

export const QRCodeIcon = ({ ...props }: Partial<SVGProps<SVGSVGElement>>) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.5 3H3.5C3.22386 3 3 3.22386 3 3.5V6.5C3 6.77614 3.22386 7 3.5 7H6.5C6.77614 7 7 6.77614 7 6.5V3.5C7 3.22386 6.77614 3 6.5 3Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.5 9H3.5C3.22386 9 3 9.22386 3 9.5V12.5C3 12.7761 3.22386 13 3.5 13H6.5C6.77614 13 7 12.7761 7 12.5V9.5C7 9.22386 6.77614 9 6.5 9Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.5 3H9.5C9.22386 3 9 3.22386 9 3.5V6.5C9 6.77614 9.22386 7 9.5 7H12.5C12.7761 7 13 6.77614 13 6.5V3.5C13 3.22386 12.7761 3 12.5 3Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M9 9V11" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 13H11V9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11 10H13" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 12V13" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const NoteSvg = ({ ...props }: Partial<SVGProps<SVGSVGElement>>) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M9 9H15" stroke="#171923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 12H15" stroke="#171923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 15H12" stroke="#171923" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M14.6893 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V14.6893C20.25 14.7878 20.2306 14.8854 20.1929 14.9764C20.1552 15.0673 20.1 15.15 20.0303 15.2197L15.2197 20.0303C15.15 20.1 15.0673 20.1552 14.9764 20.1929C14.8854 20.2306 14.7878 20.25 14.6893 20.25V20.25Z"
            stroke="#171923"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
