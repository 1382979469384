import { Box, Text } from '@chakra-ui/react';
import { Row } from '@gregoranders/csv';
import React from 'react';
import { WarningIconWithTooltip } from '../../assets/warning';
import { ContactsParseError, parseContactsFromCsvRows } from '../../contactParser';
import { useExtendedContacts, useContactValidator, requireContactsReady, isContactsReady } from '../../hooks/useExtendedContacts';
import { AddressLabel, EmailAddressLabel } from '../base/address-label';
import { ImportCsvModal } from './import-batch-csv-modal';

type ImportContactsModalProps = { triggerElement: (onOpen: () => void) => React.ReactNode };

function getLabelForError(error: ContactsParseError): string {
    switch (error) {
        case 'not-enough-rows':
            return 'Not enough rows. Expecting at least 1 excluding an optional header.';

        case 'not-enough-columns':
            return 'Not enough columns. Expecting at least 2.';

        case 'no-wallet-address':
            return 'No columns with wallet addresses.';

        case 'not-enough-columns-with-email':
            return 'Not enough columns. Expecting at least 3 if email address column exists.';

        case 'invalid-format':
            return 'Invalid file format. Expecting a .csv file.';
    }
}

const errorIcon = (value: string) => <WarningIconWithTooltip placement="top" label={value} warningOverrides={{ ml: '1', mt: '1' }} />;

export const ImportContactsModal = ({ triggerElement }: ImportContactsModalProps) => {
    const contactValidator = useContactValidator();
    const contactsContext = useExtendedContacts();

    return (
        <ImportCsvModal
            triggerElement={triggerElement}
            parseFromRowsAsync={async (row: readonly Row[]) => {
                if (!isContactsReady(contactsContext)) requireContactsReady();
                return parseContactsFromCsvRows(row, contactsContext.getContact, contactValidator);
            }}
            getLabelForError={getLabelForError}
            getDisplayListItemsForParsedContent={({ name, walletAddress, emailAddress, groups, fields }) => ({
                columnValues: [
                    <>
                        <Text>{name}</Text>
                        {fields && fields.includes('name') && (
                            <WarningIconWithTooltip
                                placement="top"
                                label="The existing information for this contact will be overriden."
                                warningOverrides={{ ml: '1' }}
                            />
                        )}
                    </>,
                    <AddressLabel>{walletAddress}</AddressLabel>,
                    <>
                        {emailAddress && emailAddress !== '' ? (
                            <EmailAddressLabel>{emailAddress}</EmailAddressLabel>
                        ) : (
                            <Text>No email address</Text>
                        )}
                        {fields && fields.includes('emailAddress') && (
                            <WarningIconWithTooltip
                                placement="top"
                                label="The existing information for this contact will be overriden."
                                warningOverrides={{ ml: '1', mt: '1' }}
                            />
                        )}
                    </>,
                    <>
                        {groups && groups.length > 0 ? <Text>{groups.join(', ')}</Text> : <Text>No groups</Text>}
                        {fields && fields.includes('groups') && (
                            <WarningIconWithTooltip
                                placement="top"
                                label="The existing information for this contact will be overriden."
                                warningOverrides={{ ml: '1', mt: '1' }}
                            />
                        )}
                    </>,
                ],
            })}
            getDisplayListItemsForError={({ name, walletAddress, emailAddress, errorFields }) => ({
                columnValues: [
                    <>
                        <Text color={errorFields.includes('name') ? 'red' : 'inherit'}>{name}</Text>
                        {errorFields.includes('name') && errorIcon(name)}
                    </>,
                    <>
                        <AddressLabel
                            color={errorFields.includes('walletAddress') ? 'red' : 'inherit'}
                            withIcon={!errorFields.includes('walletAddress')}
                        >
                            {walletAddress}
                        </AddressLabel>
                        {errorFields.includes('walletAddress') && errorIcon(walletAddress)}
                    </>,
                    <>
                        {emailAddress && emailAddress !== '' ? (
                            <EmailAddressLabel
                                color={errorFields.includes('emailAddress') ? 'red' : 'inherit'}
                                withIcon={!errorFields.includes('emailAddress')}
                            >
                                {emailAddress}
                            </EmailAddressLabel>
                        ) : (
                            <Text>No email address</Text>
                        )}
                        {errorFields.includes('emailAddress') && errorIcon(emailAddress ?? 'No email address')}
                    </>,
                ],
            })}
            onSubmitAsync={contacts => {
                if (!isContactsReady(contactsContext)) requireContactsReady();
                return contactsContext.addOrUpdateContactsAsync(contacts);
            }}
            preuploadInfoBox={
                <Box>
                    <Box py="10">
                        <Text>
                            After trimming the header row (if present), we will auto-detect the wallet address, email address and groups
                            columns (if applicable). The name column will be the first available column after determining wallet, email and
                            groups. Here is an example:
                        </Text>
                    </Box>
                    <Box background="#EEEEEE" borderRadius="8">
                        <Box p="6">
                            <Text>Name,Wallet address,Email address,Groups</Text>
                            <Text>John Doe,0x1aD4dfAeBA64e9912d0893F4470ce1ce4D19d054,jdoe@email.com,"Marketing,North America"</Text>
                        </Box>
                    </Box>
                </Box>
            }
            headers={['Name', 'Wallet Address', 'Email Address', 'Groups']}
            entityName={'Contact'}
            importTemplate={[
                {
                    Name: 'John Doe',
                    'Wallet address': '0x1aD4dfAeBA64e9912d0893F4470ce1ce4D19d054',
                    'Email address': 'jdoe@email.com',
                    Groups: 'Marketing,North America',
                },
            ]}
            fileName="Bulla Contacts Import Template"
        />
    );
};
