import { Box, HStack, Input, InputGroup, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { CopyIcon } from '../../assets/copy_button';
import { TokenDto } from '../../data-lib/networks';
import { AlertInfo } from '../base/alert';
import CopyElement from '../base/copy-element';
import { CloseModalButton } from './common';
import { disabledInputProps } from './create-claim-modal/create-claim-inputs';

export interface ApprovalInfo {
    approvalAmount: BigNumber;
    token: TokenDto;
}

export const TokenApprovalModal: React.FC<{
    modalOpen: boolean;
    closeModal: () => void;
    approvalInfos: ApprovalInfo[];
    approvalType?: string;
}> = ({ modalOpen, closeModal, approvalInfos, approvalType = 'loan' }) => {
    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                size="2xl"
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={() => closeModal()} />
                    <ModalHeader>
                        <Text color="gray.700" fontWeight={'700'} fontSize="25px" alignSelf="center" maxW={'95%'}>
                            Token Approval
                        </Text>
                    </ModalHeader>
                    <ModalBody fontSize={'14px'} fontWeight="400">
                        <AlertInfo
                            message={
                                <Text pl="">
                                    Your wallet might ask for an approval amount for the {approvalType.toLowerCase()} to process. <br />
                                    Copy the approval amount below.
                                </Text>
                            }
                        />
                        <Box h="6" />
                        {approvalInfos.map((info, index) => (
                            <React.Fragment key={index}>
                                <HStack>
                                    <InputGroup>
                                        <Input
                                            placeholder={`${formatUnits(info.approvalAmount, info.token.decimals)} ${info.token.symbol}`}
                                            isDisabled={true}
                                            variant="popup"
                                            border="1px"
                                            textColor={'gray.300'}
                                            backgroundColor={'transparent'}
                                            {...disabledInputProps}
                                        />
                                    </InputGroup>
                                    <CopyElement valueToCopy={formatUnits(info.approvalAmount, info.token.decimals)}>
                                        <CopyIcon pl="5" pr="9" />
                                    </CopyElement>
                                </HStack>
                                <Box h="6" />
                            </React.Fragment>
                        ))}
                        <Box h="3" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
