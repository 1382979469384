import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonProps,
    Center,
    Divider,
    Flex,
    HStack,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spacer,
    Spinner,
    Stack,
    StackProps,
    Text,
    VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { Variants } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import BullaLogo from 'url:../../../assets/logo_orange.svg';
import { ClaimInfo } from '../../../data-lib/data-model';
import { ChainId, chainIds, FactoringConfig } from '../../../data-lib/networks';
import { useBullaFactoring } from '../../../hooks/useBullaFactoring';
import {
    BullaFactoringEligibilityState,
    BullaFactoringEligibilityTerms,
    useCheckFactoringEligibility,
} from '../../../hooks/useCheckFactoringEligibility';
import { Contact, isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { useUIState } from '../../../state/ui-state';
import { calculateDateDifference, toDateDisplay } from '../../../tools/common';
import { enableBullaFactoringPool } from '../../../tools/featureFlags';
import { ChakraCompose } from '../../../tools/types';
import { AddressLabel, EmailAddressLabel, useAddressNameLabel, useYouBadge } from '../../base/address-label';
import { WithSkeleton } from '../../base/skeleton';
import { BullaBlueTextButton, OrangeButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';

export type WithSkeletonProps = {
    children: React.ReactNode | undefined;
    isLoading: boolean;
    randomW?: boolean;
    fixedWidth?: string;
    height?: string;
};

export const claimDetailVariants: Variants = {
    active: {
        x: 0,
        height: 'fit-content',
    },
    inactive: {
        x: '-100%',
    },
};

export const logVariants: Variants = {
    visible: {
        display: 'inline-block',
    },
    invisible: {
        display: 'none',
        transition: {
            delay: 0.5,
        },
    },
    in: {
        x: '-100%',
    },
    out: {
        x: 0,
    },
};

export const SaveClaimButton = ({ ...overrides }: ButtonProps) => {
    const { transactionPending } = useUIState();
    return (
        <Button isDisabled={transactionPending} colorScheme="accent" size="sm" {...overrides}>
            Save
        </Button>
    );
};

export const InfoLabel = ({ children, ...overrides }: ChakraCompose) => (
    <Box fontWeight="500" lineHeight="20px" fontSize="14px" color="brand.bulla_grey" {...overrides}>
        {children}
    </Box>
);

export const ItemDesc = ({ title, children, ...overrides }: { title: string | React.ReactNode } & ChakraCompose & StackProps) => (
    <Stack spacing="1" {...overrides}>
        <InfoLabel>{typeof title == 'string' ? <Text>{title}</Text> : title}</InfoLabel>
        <Box fontWeight={700} fontSize="14px" lineHeight={'24px'} color="black">
            {children}
        </Box>
    </Stack>
);

export const ReceiptLine = ({
    isLoading,
    isMobile,
    title,
    tokenAmount,
    ...overrides
}: { isLoading: boolean; isMobile: boolean; title: string; tokenAmount?: React.ReactNode } & ChakraCompose) => (
    <>
        {!isMobile && <Box />}
        <Text alignSelf="center" whiteSpace="nowrap" flex="1" {...overrides}>
            {title}
        </Text>
        <WithSkeleton isLoading={isLoading}>
            <HStack justifyContent="end" h="24px" flex="1" fontSize={'16px'} fontWeight="700" {...overrides}>
                {tokenAmount}
            </HStack>
        </WithSkeleton>
    </>
);

function claimContactView(
    walletOrEmailAddress: string,
    chainId: ChainId,
): { contact: 'not-found' | Contact; view: React.ReactNode } | undefined {
    const getContactNameLabel = useAddressNameLabel();
    const youBadge = useYouBadge(walletOrEmailAddress);
    const contactsContext = useExtendedContacts();

    switch (youBadge) {
        case 'not-you':
            switch (getContactNameLabel) {
                case 'fetching':
                    return {
                        contact: 'not-found',
                        view: (
                            <>
                                <InfoLabel>Name</InfoLabel>
                                <Skeleton isLoaded={false} w="8em">
                                    <Text>Loading...</Text>
                                </Skeleton>
                            </>
                        ),
                    };

                default:
                    // Contacts are loaded and ready
                    const contactNameLabel = getContactNameLabel(walletOrEmailAddress, chainId);
                    switch (contactNameLabel) {
                        case 'not-found':
                            return { contact: 'not-found', view: undefined };

                        default:
                            // Contact is found
                            const contact = isContactsReady(contactsContext)
                                ? contactsContext.getContact(walletOrEmailAddress)
                                : 'not-found';
                            return {
                                contact,
                                view: (
                                    <>
                                        <InfoLabel>Name</InfoLabel>
                                        <Stack>
                                            {contactNameLabel}
                                            {contact != 'not-found' && !!contact.emailAddress && !walletOrEmailAddress.includes('@') && (
                                                <EmailAddressLabel>{contact.emailAddress}</EmailAddressLabel>
                                            )}
                                        </Stack>
                                    </>
                                ),
                            };
                    }
            }
    }
}

export const ClaimPartyInfo = ({ walletOrEmailAddress, chainId }: { walletOrEmailAddress: string | 'loading'; chainId: ChainId }) => {
    const isWalletLoading = walletOrEmailAddress == 'loading';
    const contactViewContext = isWalletLoading
        ? { contact: 'not-found' as const, view: undefined }
        : claimContactView(walletOrEmailAddress, chainId);
    const youBadge = useYouBadge(walletOrEmailAddress);

    return (
        <Box ml="2">
            <HStack>
                <InfoLabel>{walletOrEmailAddress.includes('@') ? 'Email' : 'Wallet'}</InfoLabel>
                {youBadge != 'not-you' && youBadge}
            </HStack>
            <WithSkeleton isLoading={isWalletLoading}>
                <AddressLabel charCount={3} fontWeight="700">
                    {walletOrEmailAddress}
                </AddressLabel>
            </WithSkeleton>
            {(!!contactViewContext?.view || isWalletLoading) && (
                <>
                    <Box h="2" />
                    <WithSkeleton fixedWidth="8em" isLoading={isWalletLoading}>
                        {contactViewContext?.view}
                    </WithSkeleton>
                </>
            )}
        </Box>
    );
};

type CreditorDebtorBoxLabels = {
    creditor: string;
    debtor?: string;
    creditorLabel?: string;
    debtorLabel?: string;
    chainId: ChainId;
};

export const CreditorDebtorBox = ({
    creditor,
    debtor,
    creditorLabel,
    debtorLabel,
    chainId,
    ...overrides
}: CreditorDebtorBoxLabels & ChakraCompose) => {
    const isMobile = useIsMobile();

    return (
        <HStack
            borderTopWidth="1px"
            borderBottomWidth="1px"
            borderColor={'brand.bulla_grey'}
            p="4"
            my="2"
            spacing="4"
            alignItems={'start'}
            maxW="min-content"
            justifyContent={'space-between'}
            {...overrides}
        >
            {!!debtor && (
                <>
                    <Stack minW="10em">
                        <Text fontSize={'18px'} fontWeight={700} color="gray.700" lineHeight={'28px'}>
                            {debtorLabel ?? 'Debtor'}
                        </Text>
                        <ClaimPartyInfo walletOrEmailAddress={debtor} chainId={chainId} />
                    </Stack>
                    <Box borderWidth="1px" borderLeftColor="brand.bulla_grey" alignSelf={'stretch'} />
                </>
            )}
            <Stack minW="10em">
                <Text fontSize={'18px'} fontWeight={700} color="gray.700" lineHeight={'28px'}>
                    {creditorLabel ?? 'Creditor'}
                </Text>
                <ClaimPartyInfo walletOrEmailAddress={creditor} chainId={chainId} />
            </Stack>
            {!isMobile && <Box />}
        </HStack>
    );
};

export const BullaFactoringBox = ({
    claim,
    usageLabel,
    onComplete,
    factoringConfig,
}: {
    claim: ClaimInfo;
    usageLabel: string;
    onComplete?: (() => void) | undefined;
    factoringConfig: FactoringConfig;
}) => {
    const [bullaFactorInvoiceModalOpen, setBullaFactorInvoiceModalOpen] = React.useState(false);
    const closeBullaFactorInvoiceModal = () => {
        setBullaFactorInvoiceModalOpen(false);
    };
    const { connectedNetwork } = useWeb3();
    const [bullaFactoringEligibility, setBullaFactoringEligibility] = React.useState<BullaFactoringEligibilityState>(
        (enableBullaFactoringPool && claim.chainId == chainIds.SEPOLIA) || claim.chainId == chainIds.MATIC
            ? { type: 'init' }
            : { type: 'not-supported' },
    );
    const [_, { getTotalAssets }] = useBullaFactoring(factoringConfig);
    const [eligibilityLoading, { checkEligibility: checkEligibilityApi }] = useCheckFactoringEligibility();

    useEffect(() => {
        if (connectedNetwork == claim.chainId) {
            setBullaFactoringEligibility({ type: 'init' });
        }
    }, [connectedNetwork]);

    const checkEligibility = async () => {
        const availablAssetsInPool = await getTotalAssets();

        if (availablAssetsInPool && availablAssetsInPool.lt(claim.claimAmount)) {
            setBullaFactoringEligibility({ type: 'not-eligible', reason: 'Pool at capacity' });
            return;
        }

        checkEligibilityApi(claim, factoringConfig.bullaFactoringToken.token.address).then(setBullaFactoringEligibility);
    };

    function factoringButton(eligibility: BullaFactoringEligibilityState) {
        if (eligibilityLoading)
            return (
                <BullaBlueTextButton>
                    <Spinner />
                </BullaBlueTextButton>
            );

        switch (eligibility.type) {
            case 'init':
                return (
                    <BullaBlueTextButton onClick={checkEligibility} _hover={{ textUnderline: 'none' }}>
                        Check Eligibility
                    </BullaBlueTextButton>
                );
            case 'not-eligible':
                return (
                    <BullaBlueTextButton isDisabled _hover={{ textUnderline: 'none' }}>
                        {eligibility.reason ?? 'Not Eligible'}
                    </BullaBlueTextButton>
                );
            case 'not-supported':
                return (
                    <BullaBlueTextButton isDisabled _hover={{ textUnderline: 'none' }}>
                        Not supported
                    </BullaBlueTextButton>
                );
            case 'eligible':
                return <BullaBlueTextButton onClick={() => setBullaFactorInvoiceModalOpen(true)}>Get Paid Now</BullaBlueTextButton>;
        }
    }

    return (
        <>
            <Flex border="1px solid #E2E8F0" borderRadius={'8px'} flexDir="row" pr="8" pl="5" py="4">
                <Image src={BullaLogo} mr="5" w="40px" />
                <Stack spacing="1" w="90%">
                    <Text fontSize={'18px'} fontWeight="700" color={'black'}>
                        Get paid early with Bulla Finance
                    </Text>
                    <Text fontSize={'12px'} w="320px" pb="3">
                        {' '}
                        Receive up to 80% or more of your {usageLabel.toLowerCase()} today and the remaining when your{' '}
                        {usageLabel.toLowerCase()} gets paid.{' '}
                    </Text>
                </Stack>
                <Spacer />
                {factoringButton(bullaFactoringEligibility)}
            </Flex>

            {bullaFactoringEligibility.type === 'eligible' && (
                <BullaFactorInvoiceModal
                    modalOpen={bullaFactorInvoiceModalOpen}
                    closeModal={closeBullaFactorInvoiceModal}
                    closeInvoice={() => onComplete?.()}
                    claim={claim}
                    terms={bullaFactoringEligibility.terms}
                    factoringConfig={factoringConfig}
                />
            )}
        </>
    );
};

export type BullaFactoringFeesAndAmounts = {
    fundedAmountGross: BigNumber;
    adminFee: BigNumber;
    targetInterest: BigNumber;
    targetProtocolFee: BigNumber;
    netFundedAmount: BigNumber;
};

type BullaFactoringFeesAndAmountsState =
    | { type: 'init' }
    | { type: 'loading' }
    | { type: 'found'; feesAndAmounts: BullaFactoringFeesAndAmounts };

const sumFees = (feesAndAmounts: BullaFactoringFeesAndAmounts) => {
    const totalFees = feesAndAmounts.adminFee.add(feesAndAmounts.targetInterest).add(feesAndAmounts.targetProtocolFee);
    return totalFees;
};

type FeesInBasisPoints = {
    adminFeeBps: number;
    targetInterestBps: number;
    targetProtocolFeeBps: number;
};

const calculateBasisPoints = (feesAndAmounts: BullaFactoringFeesAndAmounts, invoiceAmount: BigNumber): FeesInBasisPoints => {
    const basisPoints = {
        adminFeeBps: feesAndAmounts.adminFee.mul(10000).div(invoiceAmount).toNumber(),
        targetInterestBps: feesAndAmounts.targetInterest.mul(10000).div(invoiceAmount).toNumber(),
        targetProtocolFeeBps: feesAndAmounts.targetProtocolFee.mul(10000).div(invoiceAmount).toNumber(),
    };
    return basisPoints;
};

export const BullaFactorInvoiceModal: React.FC<{
    modalOpen: boolean;
    closeModal: () => void;
    closeInvoice: () => void | undefined;
    claim: ClaimInfo;
    terms: BullaFactoringEligibilityTerms;
    factoringConfig: FactoringConfig;
}> = ({ modalOpen, closeModal, claim, terms, closeInvoice, factoringConfig }) => {
    const { connectedNetwork } = useWeb3();
    const [
        executing,
        { calculateTargetFees, approve, isApproved, fundInvoice, fundInvoiceInputToMultisendTxDTO, approveInvoiceInputToMultisendTxDTO },
    ] = useBullaFactoring(factoringConfig);
    const [feesAndAmounts, setFeesAndAmounts] = React.useState<BullaFactoringFeesAndAmountsState>({ type: 'init' });
    const totalFees = feesAndAmounts.type === 'found' && sumFees(feesAndAmounts.feesAndAmounts);
    const [sliderUpfrontBps, setSliderUpfrontBps] = useState(terms.upfrontBps);
    const [sliderChangeTrigger, setSliderChangeTrigger] = useState(false);
    const invoiceAmount = claim.claimAmount.sub(claim.paidAmount);
    const token = claim.tokenInfo.token;
    const wrongNetwork = connectedNetwork !== claim.chainId;
    const [showFeeDetails, setShowFeeDetails] = useState(false);
    const feesBasisPoints = feesAndAmounts.type === 'found' && terms && calculateBasisPoints(feesAndAmounts.feesAndAmounts, invoiceAmount);
    const { safeInfo } = useGnosisSafe();
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const isLoading = executing || executingSafeTx;
    const bullaFundAddress = factoringConfig.bullaFactoringToken.token.address;
    const { executeTransactions } = useGnosisTransaction();
    const { connectedNetworkConfig } = useWeb3();
    const showSlider = claim.chainId === chainIds.SEPOLIA;

    useEffect(() => {
        const fetchFeesAndAmounts = async () => {
            setFeesAndAmounts({ type: 'loading' });
            const feesAndAmounts_ = await calculateTargetFees(BigNumber.from(claim.id), sliderUpfrontBps);

            if (feesAndAmounts_) {
                setFeesAndAmounts({ type: 'found', feesAndAmounts: feesAndAmounts_ });
            }
        };

        if (feesAndAmounts.type === 'init' || sliderChangeTrigger) {
            fetchFeesAndAmounts();
            setSliderChangeTrigger(false);
        }
    }, [calculateTargetFees, claim.id, sliderUpfrontBps]);

    const [approved, setApproved] = React.useState(false);

    useEffect(() => {
        isApproved(claim).then(setApproved);
    }, []);

    useEffect(() => {
        if (modalOpen) {
            setSliderUpfrontBps(terms.upfrontBps);
        }
    }, [modalOpen]);

    const handleSliderChange = (newSliderValue: number) => {
        if (newSliderValue === 0) {
            setSliderUpfrontBps(1);
        } else {
            setSliderUpfrontBps(newSliderValue);
        }
        setSliderChangeTrigger(prev => !prev);
    };

    const handleFactoring = async () => {
        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction: approveInvoiceTransaction } = await approveInvoiceInputToMultisendTxDTO(
                    connectedNetworkConfig.bullaClaimAddress,
                    BigNumber.from(claim.id),
                    bullaFundAddress,
                );
                const { transaction: fundInvoiceTransaction } = await fundInvoiceInputToMultisendTxDTO(
                    bullaFundAddress,
                    BigNumber.from(claim.id),
                    sliderUpfrontBps,
                );
                const { transactionResult, success } = await executeTransactions(
                    safeInfo,
                    [approveInvoiceTransaction, fundInvoiceTransaction],
                    true,
                );
                if (success && closeModal) closeModal();
                return transactionResult;
            } finally {
                setExecutingSafeTx(false);
                return;
            }
        }

        if (approved) {
            fundInvoice(BigNumber.from(claim.id), sliderUpfrontBps).then(success => {
                if (success) {
                    closeModal();
                    closeInvoice();
                }
            });
        } else {
            approve(claim).then(setApproved);
        }
    };

    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                size="2xl"
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={() => closeModal()} />
                    <ModalHeader>
                        <Text color="gray.700" fontWeight={'700'} fontSize="24px" alignSelf="center" maxW={'95%'}>
                            Bulla Finance Terms
                        </Text>
                    </ModalHeader>
                    {feesAndAmounts.type === 'found' && terms ? (
                        <ModalBody fontSize={'14px'} fontWeight="400" p="6">
                            {!approved ? (
                                <>
                                    <VStack spacing={'1'} align="left">
                                        <Text fontWeight={700} fontSize="18px" color="gray.500">
                                            You receive now:
                                        </Text>
                                        <Text fontWeight={700} as="span" color="gray.700" fontSize="24px">
                                            {`${formatUnits(feesAndAmounts.feesAndAmounts.netFundedAmount, token.decimals)} ${
                                                token.symbol
                                            }`}
                                        </Text>
                                        {showSlider && (
                                            <Text fontWeight={500} fontSize="16px" textAlign="left" color="gray.700">
                                                Factor Ratio:{' '}
                                                <Text as="span" color="gray.700">
                                                    {`${Number(sliderUpfrontBps) / 100}%`}
                                                </Text>
                                            </Text>
                                        )}
                                    </VStack>
                                    {showSlider && (
                                        <>
                                            <Slider
                                                aria-label=""
                                                defaultValue={sliderUpfrontBps}
                                                min={100}
                                                max={terms.upfrontBps}
                                                onChangeEnd={val => handleSliderChange(val)}
                                                mt="5"
                                                focusThumbOnChange={false}
                                                size="lg"
                                                colorScheme="orange"
                                            >
                                                <SliderTrack>
                                                    <SliderFilledTrack />
                                                </SliderTrack>
                                                <SliderThumb boxSize={8} border="1.5px solid #e66c0a" />
                                            </Slider>
                                            <Flex justify="space-between" color="gray.900" fontSize={'13px'} pb="4" fontWeight={'500'}>
                                                <Text>1%</Text>
                                                <Text> Max factor ratio: {`${terms.upfrontBps / 100}%`}</Text>
                                            </Flex>
                                        </>
                                    )}
                                    <Box bg="gray.50" borderRadius={'4px'} px="5" py="4" fontSize={'15px'} fontWeight={'500'} mt="3">
                                        <Stack spacing="4" my="2">
                                            <Flex justify="space-between">
                                                <Text color="gray.600">Total Invoice Value</Text>
                                                <Text color="gray.700">{`${formatUnits(invoiceAmount, token.decimals)} ${
                                                    token.symbol
                                                }`}</Text>
                                            </Flex>
                                            {showSlider && (
                                                <>
                                                    <Divider color={'gray.300'} />
                                                    <Flex justify="space-between">
                                                        <Text color="gray.600">Factor Ratio</Text>
                                                        <Text color="gray.700">{`${Number(sliderUpfrontBps) / 100}%`}</Text>
                                                    </Flex>
                                                </>
                                            )}
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <Text color="gray.600">Gross Upfront Amount</Text>
                                                <Text color="gray.700">
                                                    {`${formatUnits(feesAndAmounts.feesAndAmounts.fundedAmountGross, token.decimals)} ${
                                                        token.symbol
                                                    }`}
                                                </Text>
                                            </Flex>
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <Text color="gray.600">Invoice Due Date</Text>
                                                <Text color="gray.700">
                                                    {`${toDateDisplay(claim.dueBy)} (Due ${calculateDateDifference(claim.dueBy)})`}
                                                </Text>
                                            </Flex>
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <HStack onClick={() => setShowFeeDetails(!showFeeDetails)}>
                                                    <Text color="gray.600">Fees</Text>
                                                    {showFeeDetails ? (
                                                        <ChevronDownIcon boxSize={'6'} color="gray.600" />
                                                    ) : (
                                                        <ChevronRightIcon boxSize={'6'} color="gray.600" />
                                                    )}
                                                </HStack>
                                                <Text color="gray.700">
                                                    {totalFees && `${formatUnits(totalFees, token.decimals)} ${token.symbol}`}
                                                </Text>
                                            </Flex>
                                            {showFeeDetails && (
                                                <>
                                                    <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                                                        <Text>
                                                            {`Pool Fee (${feesBasisPoints && feesBasisPoints.targetInterestBps / 100}%)`}
                                                        </Text>
                                                        <Text>
                                                            {`${formatUnits(
                                                                feesAndAmounts.feesAndAmounts.targetInterest,
                                                                token.decimals,
                                                            )} ${token.symbol}`}
                                                        </Text>
                                                    </Flex>
                                                    <Divider color={'gray.300'} />
                                                    <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                                                        <Text>{`Admin Fee (${
                                                            feesBasisPoints && feesBasisPoints.adminFeeBps / 100
                                                        }%)`}</Text>

                                                        <Text>
                                                            {`${formatUnits(feesAndAmounts.feesAndAmounts.adminFee, token.decimals)} ${
                                                                token.symbol
                                                            }`}
                                                        </Text>
                                                    </Flex>
                                                    <Divider color={'gray.300'} />
                                                    <Flex justify="space-between" color="gray.600" fontSize={'13px'}>
                                                        <Text>
                                                            {`Protocol Fee (${
                                                                feesBasisPoints
                                                                    ? (feesBasisPoints.targetProtocolFeeBps / 100).toFixed(3)
                                                                    : 0
                                                            }%)`}
                                                        </Text>
                                                        <Text>
                                                            {`${formatUnits(
                                                                feesAndAmounts.feesAndAmounts.targetProtocolFee,
                                                                token.decimals,
                                                            )} ${token.symbol}`}
                                                        </Text>
                                                    </Flex>
                                                </>
                                            )}
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <Text color="gray.800" as="b">
                                                    You Receive Now
                                                </Text>
                                                <Text color="gray.700">{`${formatUnits(
                                                    feesAndAmounts.feesAndAmounts.netFundedAmount,
                                                    token.decimals,
                                                )} ${token.symbol}`}</Text>
                                            </Flex>
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <Text color="gray.800" as="b">
                                                    You Receive Later
                                                </Text>
                                                <Text color="gray.700">{`${formatUnits(
                                                    invoiceAmount.sub(feesAndAmounts.feesAndAmounts.fundedAmountGross),
                                                    token.decimals,
                                                )} ${token.symbol}`}</Text>
                                            </Flex>
                                            <Divider color={'gray.300'} />
                                            <Flex justify="space-between">
                                                <Text color="gray.800" as="b">
                                                    You Receive in Total
                                                </Text>
                                                <Text color="gray.700">
                                                    {totalFees &&
                                                        `${formatUnits(invoiceAmount.sub(totalFees), token.decimals)} ${token.symbol}`}
                                                </Text>
                                            </Flex>
                                        </Stack>
                                    </Box>
                                    <Text textAlign="center" fontWeight={'500'} mt="6" mb="3" color={'gray.700'}>
                                        By clicking Get Paid Now, you agree to{' '}
                                        <Link
                                            href=""
                                            fontWeight="bold"
                                            textDecoration="underline"
                                            color="brand.bulla_blue"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Bulla's Terms of Service
                                        </Link>
                                        .
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Text
                                        fontWeight={700}
                                        fontSize="22px"
                                        lineHeight={'28px'}
                                        textAlign="center"
                                        color="gray.500"
                                        mb="5"
                                        mt="-3"
                                    >
                                        Token Approved
                                    </Text>
                                    <Text fontWeight={700} fontSize="22px" lineHeight={'28px'} textAlign="center" color="gray.500" mb="5">
                                        You are ready to receive:{' '}
                                        <Text as="span" color="gray.700">
                                            {`${formatUnits(feesAndAmounts.feesAndAmounts.netFundedAmount, token.decimals)} ${
                                                token.symbol
                                            }`}
                                        </Text>
                                    </Text>
                                </>
                            )}
                            <Box h="4" />
                            <Flex justifyContent={approved ? 'center' : 'flex-end'}>
                                <OrangeButton
                                    isLoading={isLoading}
                                    isDisabled={wrongNetwork || isLoading || sliderUpfrontBps === 0}
                                    onClick={handleFactoring}
                                    w="min-content"
                                >
                                    {approved || safeInfo ? 'Get Paid Now' : 'Get Paid (Approve Token)'}
                                </OrangeButton>
                            </Flex>
                        </ModalBody>
                    ) : (
                        <Center height="200px">
                            <Spinner />
                        </Center>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
