import { Heading, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Stack, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import BullaIcon from 'url:../../../assets/logo_orange.svg';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { CloseModalButton } from '../common';

const QRCodeModal = ({ headerText, url, onClose }: { onClose: VoidFunction; headerText: string; url: string }) => {
    const isMobile = useIsMobile();

    return (
        <Modal isCentered isOpen={true} onClose={onClose} closeOnEsc={false} size={isMobile ? '3xl' : 'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading color="heading" size={'lg'} pr="10">
                        {headerText}
                    </Heading>
                    <CloseModalButton onClose={onClose} color="black" />
                </ModalHeader>
                <ModalBody>
                    <Stack alignItems="center" p="10">
                        <QRCode
                            logoWidth={100}
                            size={340}
                            quietZone={5}
                            value={url}
                            logoImage={BullaIcon}
                            removeQrCodeBehindLogo={true}
                            qrStyle="dots"
                        />
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export const QRViewerModal = ({
    triggerElement,
    url,
    headerText,
}: {
    headerText: string;
    url: string;
    triggerElement: (onOpen: VoidFunction) => React.ReactNode;
}) => {
    const { onClose, isOpen, onOpen } = useDisclosure();

    return (
        <>
            {triggerElement(onOpen)}
            {isOpen && <QRCodeModal {...{ url, headerText, onClose }} />}
        </>
    );
};
