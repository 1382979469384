import { Box, Text } from '@chakra-ui/react';
import { Row } from '@gregoranders/csv';
import React from 'react';
import { isValidAddress } from '../../data-lib/ethereum';
import { TokenInfo } from '../../data-lib/networks';
import { useWeb3 } from '../../hooks/useWeb3';
import { AddressLabel } from '../base/address-label';
import { ErrorFields, ImportCsvModal } from './import-batch-csv-modal';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { isEmailValid } from '../../hooks/useExtendedContacts';

type Recipient<T> = {
    walletAddress: T;
    name?: T;
    emailAddress?: T;
    tokenAddress?: T;
    amount?: T;
    description?: T;
    category?: T;
};

type RecipientCols = Recipient<number>;
type RecipientValues = Recipient<string>;
type RecipientValuesWithFetchedToken = RecipientValues & { token?: TokenInfo };

type ImportRecipientsModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    save: (recipients: RecipientValuesWithFetchedToken[]) => void;
};

type RecipientCsvParseError = 'not-enough-rows' | 'no-wallet-address';

const optionalColumns = ['NAME', 'EMAIL_ADDRESS', 'TOKEN_ADDRESS', 'TOKEN_AMOUNT', 'DESCRIPTION', 'CATEGORY'] as const;

const parseRecipientCols = (rows: readonly Row[]): RecipientCols | RecipientCsvParseError => {
    if (rows.length == 0) return 'not-enough-rows';
    const firstRow = rows[0];

    const walletAddressCol = firstRow.findIndex(x => x === 'WALLET_ADDRESS');
    if (walletAddressCol === -1) return 'no-wallet-address';

    const optionalIndices = Object.fromEntries(
        optionalColumns.map(col => {
            const index = firstRow.findIndex(realCol => realCol === col);
            return [col, index == -1 ? undefined : index];
        }),
    );

    return {
        walletAddress: walletAddressCol,
        name: optionalIndices['NAME'],
        emailAddress: optionalIndices['EMAIL_ADDRESS'],
        tokenAddress: optionalIndices['TOKEN_ADDRESS'],
        amount: optionalIndices['TOKEN_AMOUNT'],
        description: optionalIndices['DESCRIPTION'],
        category: optionalIndices['CATEGORY'],
    };
};

const isAmountValid = (value: string) => {
    const regex = new RegExp(/^[0-9]*.?[0-9]*$/);
    const isValid = regex.test(value) && (!isNaN(+value) || value === '.');
    return isValid || 'Invalid amount';
};

const parseRecipientsFromRows = (cols: RecipientCols, rows: Row): RecipientValues & Partial<ErrorFields<RecipientValues>> => {
    const _colValuesByName = Object.entries(cols)
        .filter(([_, index]) => index !== undefined)
        .map(([colName, index]) => [colName, rows[index]])
        .filter(([_, value]) => ![undefined, ''].includes(value?.trim()));

    const colValuesByName = Object.fromEntries(_colValuesByName);

    const invalidFieldsByName = [
        ['walletAddress', isValidAddress(colValuesByName['walletAddress']) || 'Invalid wallet address'],
        ['name', true],
        ['emailAddress', (!!colValuesByName['emailAddress'] && isEmailValid(colValuesByName['emailAddress'])) || undefined],
        [
            'tokenAddress',
            (!!colValuesByName['tokenAddress'] &&
                (isValidAddress(colValuesByName['tokenAddress'].toLowerCase()) || 'Token address not valid')) ||
                undefined,
        ],
        ['amount', (!!colValuesByName['amount'] && isAmountValid(colValuesByName['amount'])) || undefined],
        ['description', true],
        ['category', true],
    ].filter(
        (validityByFieldName): validityByFieldName is [string, string | string[]] =>
            validityByFieldName[1] !== true && validityByFieldName[1] !== undefined,
    );

    const errorFields = invalidFieldsByName.map(([fieldName]) => fieldName);

    const withOverridenFields = invalidFieldsByName.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), colValuesByName);

    return errorFields.length > 0 ? { ...withOverridenFields, errorFields } : colValuesByName;
};

export const ImportRecipientsModal = ({ triggerElement, save }: ImportRecipientsModalProps) => {
    const { connectedNetwork } = useWeb3();
    const { resolveTokenInfo } = useTokenRepo();

    return (
        <ImportCsvModal
            triggerElement={triggerElement}
            parseFromRowsAsync={async (rows: readonly Row[]) => {
                const cols = parseRecipientCols(rows);
                if (typeof cols === 'string') return cols;
                const allParsed = rows.slice(1).map(row => parseRecipientsFromRows(cols, row));

                const allTokenAddresses = [
                    ...new Set(
                        allParsed
                            .filter(x => !!x.tokenAddress && !x.errorFields?.includes('tokenAddress'))
                            .map(x => x.tokenAddress!.toLowerCase()),
                    ),
                ];

                const allTokens = await Promise.all(
                    allTokenAddresses.map(tokenAddress => resolveTokenInfo(connectedNetwork, tokenAddress)),
                );

                const tokenByTokenAddress: { [tokenAddress: string]: TokenInfo | undefined } = Object.fromEntries(
                    allTokens.map(token => [token.token.address.toLowerCase(), token]),
                );

                const rowsWithToken: (RecipientValuesWithFetchedToken & Partial<ErrorFields<RecipientValues>>)[] = allParsed.map(
                    recipient => {
                        const token: TokenInfo | 'Invalid token' | undefined =
                            !!recipient.tokenAddress && !recipient.errorFields?.includes('tokenAddress')
                                ? tokenByTokenAddress[recipient.tokenAddress!.toLowerCase()] ?? 'Invalid token'
                                : undefined;

                        const tokenAddress = token === 'Invalid token' ? 'Invalid token' : recipient.tokenAddress;
                        const newRecipient =
                            token === 'Invalid token'
                                ? {
                                      ...recipient,
                                      tokenAddress,
                                      errorFields: [...(recipient.errorFields ?? []), 'tokenAddress' as keyof RecipientValues],
                                  }
                                : { ...recipient, token };

                        return newRecipient;
                    },
                );

                return {
                    successes: rowsWithToken.filter(x => x.errorFields === undefined),
                    failures: rowsWithToken.filter(
                        (x): x is RecipientValuesWithFetchedToken & ErrorFields<RecipientValues> => x.errorFields !== undefined,
                    ),
                };
            }}
            getLabelForError={(s: RecipientCsvParseError | 'invalid-format') => ''}
            getDisplayListItemsForParsedContent={(x: RecipientValuesWithFetchedToken) => ({
                columnValues: [
                    <AddressLabel>{x.walletAddress}</AddressLabel>,
                    <Text>{x.name ?? 'No name'}</Text>,
                    <Text>{x.emailAddress ?? 'No email address'}</Text>,
                    !!x.token ? (
                        <Text>{x.token.token.symbol}</Text>
                    ) : !!x.tokenAddress ? (
                        <AddressLabel>{x.tokenAddress}</AddressLabel>
                    ) : (
                        <Text>No token address</Text>
                    ),
                    <Text>{x.amount ?? 'No amount'}</Text>,
                    <Text>{x.description ?? 'No description'}</Text>,
                    <Text>{x.category ?? 'No category'}</Text>,
                ],
            })}
            getDisplayListItemsForError={x => ({
                columnValues: [
                    x.errorFields.includes('walletAddress') ? (
                        <Text color={'red'}>{x.walletAddress}</Text>
                    ) : (
                        <AddressLabel color={x.errorFields.includes('walletAddress') ? 'red' : 'inherit'}>{x.walletAddress}</AddressLabel>
                    ),
                    <Text color={x.errorFields.includes('name') ? 'red' : 'inherit'}>{x.name ?? 'No name'}</Text>,
                    <Text color={x.errorFields.includes('emailAddress') ? 'red' : 'inherit'}>{x.emailAddress ?? 'No email address'}</Text>,
                    !!x.token ? (
                        <Text>{x.token.token.symbol}</Text>
                    ) : !!x.tokenAddress && !x.errorFields.includes('tokenAddress') ? (
                        <AddressLabel>{x.tokenAddress}</AddressLabel>
                    ) : (
                        <Text color={x.errorFields.includes('tokenAddress') ? 'red' : 'inherit'}>
                            {x.tokenAddress ?? 'No token address'}
                        </Text>
                    ),
                    <Text color={x.errorFields.includes('amount') ? 'red' : 'inherit'}>{x.amount ?? 'No amount'}</Text>,
                    <Text color={x.errorFields.includes('description') ? 'red' : 'inherit'}>{x.description ?? 'No description'}</Text>,
                    <Text color={x.errorFields.includes('category') ? 'red' : 'inherit'}>{x.category ?? 'No category'}</Text>,
                ],
            })}
            onSubmitAsync={async recipients => save(recipients)}
            preuploadInfoBox={
                <Box>
                    <Box py="10">
                        <Text>Please follow the template below. Only the WALLET_ADDRESS column is required.</Text>
                    </Box>
                    <Box background="#EEEEEE" borderRadius="8">
                        <Box p="6">
                            <Text>WALLET_ADDRESS,NAME,EMAIL_ADDRESS,TOKEN_ADDRESS,TOKEN_AMOUNT,DESCRIPTION,CATEGORY</Text>
                            <Text>
                                0x1aD4dfAeBA64e9912d0893F4470ce1ce4D19d054,John,jdoe@gmail.com,0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174,1000.001,Paper,Supplies
                            </Text>
                        </Box>
                    </Box>
                </Box>
            }
            headers={['Wallet Address', 'Name', 'Email Address', 'Token', 'Amount', 'Description', 'Category']}
            entityName={'Recipient'}
            importTemplate={[
                {
                    WALLET_ADDRESS: '0x1aD4dfAeBA64e9912d0893F4470ce1ce4D19d054',
                    NAME: 'John Doe',
                    EMAIL_ADDRESS: 'john@doe.com',
                    TOKEN_ADDRESS: '0xf17ff940864351631b1be3ac03702dea085ba51c',
                    TOKEN_AMOUNT: '10000',
                    DESCRIPTION: 'Test description',
                    CATEGORY: 'payroll',
                },
            ]}
            fileName="Batch Payment Import Template"
        />
    );
};
