import {
    Box,
    Icon,
    IconButton,
    IconButtonProps,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverContentProps,
    PopoverTrigger,
    Portal,
    Stack,
} from '@chakra-ui/react';
import React from 'react';

export const PopoverAction = ({
    children,
    onClick,
    isDisabled,
}: {
    children: React.ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
}) => (
    <Box
        as="button"
        px={5}
        py={2}
        w="100%"
        textAlign="start"
        color={isDisabled ? 'gray' : 'black'}
        fontWeight={500}
        fontSize={'1em'}
        whiteSpace={'nowrap'}
        _hover={isDisabled ? undefined : { bg: 'scheme.accent_light', fontWeight: 500 }}
        onClick={isDisabled ? () => {} : onClick}
    >
        {children}
    </Box>
);

export const PopoverExternalAction = ({ children, href }: { children: React.ReactNode; href: string }) => (
    <Box
        as="a"
        px={7}
        py={4}
        w="100%"
        textAlign="start"
        color={'#3376C2'}
        fontWeight={500}
        fontSize={'1em'}
        whiteSpace={'nowrap'}
        _hover={{ bg: 'scheme.accent_light', fontWeight: 500 }}
        href={href}
        target="_blank"
    >
        {children}
    </Box>
);

export const OverflowIcon = ({ color }: { color?: string }) => {
    return (
        <Box
            h="1.5em"
            w="1.5em"
            display="inline-flex"
            verticalAlign="top"
            justifyContent="center"
            alignItems="center"
            as={Icon}
            viewBox="0 0 15 14"
        >
            <path
                style={{ transform: 'translate(35%)' }}
                height="100%"
                width="100%"
                d="M2 0.25C1.64844 0.25 1.34896 0.373698 1.10156 0.621094C0.867188 0.855469 0.75 1.14844 0.75 1.5C0.75 1.85156 0.867188 2.15104 1.10156 2.39844C1.34896 2.63281 1.64844 2.75 2 2.75C2.35156 2.75 2.64453 2.63281 2.87891 2.39844C3.1263 2.15104 3.25 1.85156 3.25 1.5C3.25 1.14844 3.1263 0.855469 2.87891 0.621094C2.64453 0.373698 2.35156 0.25 2 0.25ZM2 5.25C1.64844 5.25 1.34896 5.3737 1.10156 5.62109C0.867188 5.85547 0.75 6.14844 0.75 6.5C0.75 6.85156 0.867188 7.15104 1.10156 7.39844C1.34896 7.63281 1.64844 7.75 2 7.75C2.35156 7.75 2.64453 7.63281 2.87891 7.39844C3.1263 7.15104 3.25 6.85156 3.25 6.5C3.25 6.14844 3.1263 5.85547 2.87891 5.62109C2.64453 5.3737 2.35156 5.25 2 5.25ZM2 10.25C1.64844 10.25 1.34896 10.3737 1.10156 10.6211C0.867188 10.8555 0.75 11.1484 0.75 11.5C0.75 11.8516 0.867188 12.151 1.10156 12.3984C1.34896 12.6328 1.64844 12.75 2 12.75C2.35156 12.75 2.64453 12.6328 2.87891 12.3984C3.1263 12.151 3.25 11.8516 3.25 11.5C3.25 11.1484 3.1263 10.8555 2.87891 10.6211C2.64453 10.3737 2.35156 10.25 2 10.25Z"
                fill={color ?? '#4E393B'}
            />
        </Box>
    );
};

export const ThreeDotsButton = (props: Partial<IconButtonProps>) => (
    <IconButton aria-label="actions" colorScheme={'whiteAlpha'} _hover={{ bg: 'scheme.accent_light' }} icon={<OverflowIcon />} {...props} />
);

export const StyledPopoverContent = ({ actions, ...props }: { actions: React.ReactNode[] } & Partial<PopoverContentProps>) => {
    return (
        <PopoverContent
            maxW={'fit-content'}
            border="0.5px solid #ACACAC63"
            style={{ filter: 'box-shadow: 0px 4px 8px 0px #0000001A' }}
            borderRadius="8px"
            {...props}
        >
            <PopoverBody py="2" px="0">
                <Stack spacing={0} alignItems="flex-start">
                    {actions.map((x, i) => (
                        <React.Fragment key={i}>{x}</React.Fragment>
                    ))}
                </Stack>
            </PopoverBody>
        </PopoverContent>
    );
};

export const ActionPopover = ({ actions, triggerElement }: { actions: React.ReactNode[]; triggerElement: React.ReactNode }) => (
    <Popover placement="bottom" autoFocus>
        {({ isOpen }) => (
            <>
                <PopoverTrigger>{triggerElement}</PopoverTrigger>
                <Portal>
                    <StyledPopoverContent display={isOpen ? 'block' : 'none'} actions={actions} />
                </Portal>
            </>
        )}
    </Popover>
);
