import React from 'react';
import { AreYouSureModal } from '../are-you-sure-modal';

export type DeleteDraftModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    onDelete: VoidFunction;
};

export const DeleteDraftModal = ({ triggerElement, onDelete }: DeleteDraftModalProps) => (
    <AreYouSureModal
        triggerElement={triggerElement}
        title={'Delete Draft'}
        message={'Are you sure you want to delete draft?'}
        onClick={onDelete}
        buttonText={'Delete'}
    />
);
