import { BigNumber } from '@ethersproject/bignumber';
import { ContractTransaction } from '@ethersproject/contracts';
import React from 'react';
import { getERC20Contract } from '../data-lib/dto/contract-interfaces';
import { parseTxLogs } from '../data-lib/dto/parser';
import { EthAddress } from '../data-lib/ethereum';
import { waitForTransaction } from '../data-lib/networks';
import { useUIState } from '../state/ui-state';
import { useWeb3 } from './useWeb3';

export const useERC20 = () => {
    const [doing, setDoing] = React.useState<boolean>(false);
    const { signerProvider, signer, connectedNetwork } = useWeb3();
    const { addPendingTxn, addErrorMessage } = useUIState();

    const execute = async (claimFunction: () => Promise<ContractTransaction>) => {
        setDoing(true);
        try {
            const tx = await claimFunction();
            addPendingTxn(signerProvider, connectedNetwork, tx.hash);
            const receipt = await waitForTransaction(connectedNetwork, signerProvider, tx.hash);
            return {
                ...receipt,
                events: parseTxLogs(receipt.logs),
            };
        } catch (e: any) {
            console.error(e);
            addErrorMessage(e);
        } finally {
            setDoing(false);
        }
    };

    const functions = {
        approve: (spenderAddress: EthAddress, tokenAddress: EthAddress, amount: BigNumber) =>
            execute(() => getERC20Contract(tokenAddress).connect(signer).approve(spenderAddress, amount)),
    };

    return [doing, functions] as const;
};
