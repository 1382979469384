import {
    AddIcon,
    ArrowBackIcon,
    ArrowForwardIcon,
    ChevronDownIcon,
    ExternalLinkIcon,
    InfoOutlineIcon,
    TriangleDownIcon,
} from '@chakra-ui/icons';
import {
    Box,
    BoxProps,
    Button,
    Checkbox,
    Collapse,
    Flex,
    HStack,
    IconButton,
    Image,
    Input,
    InputGroup,
    Kbd,
    Link,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Skeleton,
    Stack,
    StackProps,
    StyleProps,
    TableColumnHeaderProps,
    Tag,
    TagCloseButton,
    TagLabel,
    TagRightIcon,
    Td as ChakraTd,
    Text,
    Th,
    Tooltip,
    Tr,
    useBoolean,
    useOutsideClick,
    Wrap,
    WrapItem,
} from '@chakra-ui/react';
import { BigNumber, constants } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import { ImportTransactionState } from '.';
import { DetectedExternalTxType, ExternalTransactionDTO, TransferDTO } from '../../../../data-lib/dto/external-transactions-dto';
import { addressEquality, EthAddress, weiToDisplayAmt } from '../../../../data-lib/ethereum';
import { getNavigatorLanguage } from '../../../../data-lib/helpers';
import { NETWORKS, TokenInfo, TokenVariant } from '../../../../data-lib/networks';
import { TOKEN_ROUNDING } from '../../../../data-lib/tokens';
import { fillToCount } from '../../../../tools/common';
import { Optional } from '../../../../tools/types';
import { ContactNameOrAddress, TXHashLabel } from '../../../base/address-label';
import { getEmojiForDetectedType } from '../../../detected-type';
import { BullaBlueTextButton } from '../../../inputs/buttons';
import DatePicker from '../../../date-picker';
import UnknownTokenLogo from '../../../../assets/unknownToken.svg';
import { useCombobox } from 'downshift';
import { useUserSummary } from '../../../../hooks/useUserSummary';
import { disabledInputProps } from '../../../modals/create-claim-modal/create-claim-inputs';
export const FILTER_DELIMITER = '-';

export const dateFilterPrefix = 'date';
export const networkSelectionFilterPrefix = 'network';
export const txDirectionFilterPrefix = 'transferDirection';
export const txTypeFilterPrefix = 'txType';
export const currencyFilterPrefix = 'currency';
export const hideImportedTransactionsFilterPrefix = 'hideImported';

export const buildFilterId = (prefix: typeof FILTER_GROUPS[number]['prefix'], value: string): FilterId =>
    `${prefix}${FILTER_DELIMITER}${value}`;

const hideImportedTransactionsFilterId = buildFilterId(hideImportedTransactionsFilterPrefix, '');

export const FILTER_GROUPS = [
    { prefix: currencyFilterPrefix, logicOperation: 'OR' },
    { prefix: txTypeFilterPrefix, logicOperation: 'OR' },
    { prefix: dateFilterPrefix, logicOperation: 'AND' },
    { prefix: networkSelectionFilterPrefix, logicOperation: 'OR' },
    { prefix: txDirectionFilterPrefix, logicOperation: 'OR' },
    { prefix: hideImportedTransactionsFilterPrefix, logicOperation: 'AND' },
] as const;

export const FILTERS_BY_PREFIX = Object.fromEntries(FILTER_GROUPS.map(x => [x.prefix, x]));

export type FilterId = `${typeof FILTER_GROUPS[number]['prefix']}${typeof FILTER_DELIMITER}${string}`;

export type Filter = {
    id: FilterId;
    config: typeof FILTER_GROUPS[number];
    label: string;
    filter: (tx: ExternalTransactionDTO) => boolean;
    date?: Date | null;
};

export const localeDateType = getNavigatorLanguage() === 'en-US' ? 'MM/DD/YY' : 'DD/MM/YY';

export interface DateCustomInputProps {
    value?: string;
    onClick?: React.MouseEventHandler<HTMLInputElement> | undefined;
    isDisabled: boolean;
}

const DateCustomInput = React.forwardRef<HTMLInputElement, DateCustomInputProps>(({ value, onClick, isDisabled }, ref) => {
    return (
        <InputGroup>
            <Input value={value} onClick={onClick} placeholder="dd/mm/yy" isDisabled={isDisabled} ref={ref} {...disabledInputProps} />
        </InputGroup>
    );
});

export const DateRange = ({
    isDisabled,
    setStart,
    setEnd,
    filters,
}: {
    isDisabled: boolean;
    setStart: (start: Date | null) => void;
    setEnd: (end: Date | null) => void;
    filters: Filter[];
}) => {
    const dateStart = filters.find(f => f.id === 'date-start');
    const dateEnd = filters.find(f => f.id === 'date-end');

    return (
        <>
            <HStack w="130px">
                <DatePicker
                    selectedDate={dateStart?.date}
                    onChange={value => {
                        if (value === undefined) {
                            setStart(null);
                        } else {
                            setStart(value);
                        }
                    }}
                    dateFormat="dd MMM yyyy"
                    customInput={<DateCustomInput isDisabled={isDisabled} />}
                />
            </HStack>
            <ArrowForwardIcon color="gray.400" mx="1" />
            <HStack w="130px">
                <DatePicker
                    selectedDate={dateEnd?.date}
                    onChange={value => {
                        if (value === undefined) {
                            setEnd(null);
                        } else {
                            setEnd(value);
                        }
                    }}
                    dateFormat="dd MMM yyyy"
                    customInput={<DateCustomInput isDisabled={isDisabled} />}
                    isClearable
                    minDate={dateStart?.date}
                />
            </HStack>
        </>
    );
};

export const ToolbarSelector = ({
    label,
    isDisabled,
    addOption,
    omittedOptions,
    options,
}: {
    label: string;
    isDisabled?: boolean;
    options: string[];
    omittedOptions: string[] | 'init';
    addOption: (opt: string) => void;
}) => (
    <Menu closeOnSelect={false} isLazy>
        <MenuButton isDisabled={isDisabled} as={Button} rightIcon={<ChevronDownIcon />} variant="outline" bg="white">
            <Text fontWeight={500} fontSize={'12px'}>
                {label}
            </Text>
        </MenuButton>
        <MenuList maxH={'300px'} overflowY="auto">
            {options.map((val, i) => {
                const onSelect = () => addOption(val);

                return (
                    <MenuItem
                        key={i}
                        onClick={e => {
                            onSelect();
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            pointerEvents={'none'}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            isChecked={omittedOptions === 'init' ? false : omittedOptions.includes(val)}
                        >
                            <Text fontWeight={500} fontSize={'12px'}>
                                {val}
                            </Text>
                        </Checkbox>
                    </MenuItem>
                );
            })}
        </MenuList>
    </Menu>
);

export type ToolbarState = {
    networkSelectionDirty: boolean;
    selectNetworkFilter: (chainLabel: string) => void;
    addCurrencyFilter: (currency: string) => void;
    currencyDirty: boolean;
};

export const useToolbarState = (setExternalTxState: React.Dispatch<React.SetStateAction<ImportTransactionState>>): ToolbarState => {
    const [networkSelectionDirty, setNetworkSelectionDirty] = useBoolean();
    const [currencyDirty, setCurrencyDirty] = useBoolean();

    const selectNetworkFilter = React.useCallback((chainLabel: string) => {
        const filter = (tx: ExternalTransactionDTO) => {
            const { label } = NETWORKS[tx.chainId];
            return label === chainLabel;
        };
        const id = buildFilterId(networkSelectionFilterPrefix, chainLabel);
        setExternalTxState(prev => {
            const filters = prev.filters;
            const operation = filters.some(f => f.id === id) ? 'remove' : 'add';
            const [, , , networkFilterConfig] = FILTER_GROUPS;

            if (!networkSelectionDirty) setNetworkSelectionDirty.on();

            return {
                ...prev,
                filters:
                    operation == 'add'
                        ? [
                              ...filters,
                              {
                                  id,
                                  config: networkFilterConfig,
                                  label: chainLabel,
                                  filter,
                              },
                          ]
                        : filters.filter(f => f.id !== id),
            };
        });
    }, []);

    const addCurrencyFilter = React.useCallback((currency: string) => {
        const filter = (tx: ExternalTransactionDTO) =>
            tx.allTransfers.some(tr => tr.transferType.kind == 'fungible' && getCurrencyString(tr.transferType.tokenInfo) === currency);
        const id = buildFilterId(currencyFilterPrefix, currency);

        const [currencyFilterTxConfig] = FILTER_GROUPS;

        if (!currencyDirty) setCurrencyDirty.on();

        setExternalTxState(prev => {
            const filters = prev.filters;
            const operation = filters.some(f => f.id === id) ? 'remove' : 'add';
            const newFilters =
                operation == 'add'
                    ? [
                          ...prev.filters,
                          {
                              id,
                              config: currencyFilterTxConfig,
                              label: currency,
                              filter,
                          },
                      ]
                    : prev.filters.filter(f => f.id !== id);

            return {
                ...prev,
                filters: newFilters,
            };
        });
    }, []);

    return { selectNetworkFilter, networkSelectionDirty, addCurrencyFilter, currencyDirty };
};

export const getCurrencyString = (tokenInfo: TokenInfo) =>
    tokenInfo.variant === TokenVariant.UNKNOWN || tokenInfo.variant === TokenVariant.STABLE ? tokenInfo.token.symbol : tokenInfo.variant;

export const Toolbar = ({
    externalTxState: { queried, omittedIds, step, filters, alreadyImportedTransferIdsByChain },
    filteredTxs,
    setExternalTxState,
    userAddress,
    toolbarState: { networkSelectionDirty, selectNetworkFilter, currencyDirty, addCurrencyFilter },
    ...styleProps
}: {
    externalTxState: Optional<ImportTransactionState, 'fetchedDate' | 'step'>;
    filteredTxs: ImportTransactionState['queried'];
    setExternalTxState: React.Dispatch<React.SetStateAction<ImportTransactionState>>;
    userAddress: EthAddress;
    toolbarState: ToolbarState;
} & StackProps) => {
    const [txTypesDirty, setTxTypesDirty] = useBoolean();
    const [txDirectionDirty, setTxDirectionDirty] = useBoolean();

    const allSelected = omittedIds.length === 0;
    const noneSelected = omittedIds.length === filteredTxs.allTransfers.length;
    const someSelected = !allSelected && !noneSelected;

    const omittedNetworkTypes = networkSelectionDirty
        ? filters.reduce<string[]>((acc, { id }) => {
              if (id.includes(networkSelectionFilterPrefix)) {
                  const [, omittedNetwork] = id.split(FILTER_DELIMITER);
                  return [...acc, omittedNetwork];
              }
              return acc;
          }, [])
        : 'init';

    const omittedTxTypes = txTypesDirty
        ? filters.reduce<string[]>((acc, { id }) => {
              if (id.includes(txTypeFilterPrefix)) {
                  const [, txType] = id.split(FILTER_DELIMITER);
                  return [...acc, txType];
              }
              return acc;
          }, [])
        : 'init';

    const omittedTxDirections = txDirectionDirty
        ? filters.reduce<string[]>((acc, { id }) => {
              if (id.includes(txDirectionFilterPrefix)) {
                  const [, txType] = id.split(FILTER_DELIMITER);
                  return [...acc, txType];
              }
              return acc;
          }, [])
        : 'init';

    const omittedCurrencies = currencyDirty
        ? filters.reduce<string[]>((acc, { id }) => {
              if (id.includes(currencyFilterPrefix)) {
                  const [, currency] = id.split(FILTER_DELIMITER);
                  return [...acc, currency];
              }
              return acc;
          }, [])
        : 'init';

    const addHideImportedTransactionsFilter = (hideImported: boolean) => {
        const filter = (tx: ExternalTransactionDTO) => {
            return !hideImported || !tx.allTransfers.some(x => alreadyImportedTransferIdsByChain[x.chainId].has(x.id));
        };
        const id = hideImportedTransactionsFilterId;
        const isAlreadyAdded = filters.some(f => f.id === id);
        const config = FILTERS_BY_PREFIX[hideImportedTransactionsFilterPrefix];

        setExternalTxState(prev =>
            isAlreadyAdded
                ? hideImported
                    ? prev
                    : { ...prev, filters: prev.filters.filter(x => x.id !== id) }
                : hideImported
                ? { ...prev, filters: [...prev.filters, { id, config, filter, label: 'Hide Imported Transactions' }] }
                : prev,
        );
    };

    const addTxTypeFilter = (txType: string) => {
        const filter = (tx: ExternalTransactionDTO) => tx.detectedType === txType;
        const id = buildFilterId(txTypeFilterPrefix, txType);
        const operation = filters.some(f => f.id === id) ? 'remove' : 'add';
        const [, txTypeFilterConfig] = FILTER_GROUPS;

        if (!txTypesDirty) setTxTypesDirty.on();
        setExternalTxState(prev => ({
            ...prev,
            filters:
                operation == 'add'
                    ? [
                          ...prev.filters,
                          {
                              id,
                              config: txTypeFilterConfig,
                              label: txType,
                              filter,
                          },
                      ]
                    : prev.filters.filter(f => f.id !== id),
        }));
    };

    const addTxDirectionFilter = (transferDirection: string) => {
        const filter = (tx: ExternalTransactionDTO) =>
            tx.allTransfers.every(tr =>
                transferDirection === 'In ⬅️' ? addressEquality(tr.to, userAddress) : addressEquality(tr.from, userAddress),
            );
        const id = buildFilterId(txDirectionFilterPrefix, transferDirection);
        const operation = filters.some(f => f.id === id) ? 'remove' : 'add';
        const [, , , , transferDirectionFilterConfig] = FILTER_GROUPS;

        if (!txDirectionDirty) setTxDirectionDirty.on();
        setExternalTxState(prev => {
            const filters =
                operation == 'add'
                    ? [
                          ...prev.filters,
                          {
                              id,
                              config: transferDirectionFilterConfig,
                              label: transferDirection,
                              filter,
                          },
                      ]
                    : prev.filters.filter(f => f.id !== id);

            return {
                ...prev,
                filters,
            };
        });
    };

    const handleDateChange = (type: 'start' | 'end') => (date: Date | null) => {
        const id = buildFilterId(dateFilterPrefix, type);
        const label = `${_.startCase(type)}: ${moment(date).format(localeDateType)}`;
        const operation = date === null ? 'remove' : filters.some(f => f.id === id) ? 'replace' : 'add';
        const [, , dateFilterConfig] = FILTER_GROUPS;

        if (operation === 'remove') {
            setExternalTxState(prev => {
                const filters = prev.filters.filter(f => f.id !== id);

                return {
                    ...prev,
                    filters,
                };
            });
        } else {
            const filter = (tx: ExternalTransactionDTO) =>
                type === 'start' ? tx.timestamp.getTime() >= date!.getTime() : tx.timestamp.getTime() <= date!.getTime();

            setExternalTxState(prev => {
                const filters =
                    operation === 'replace'
                        ? prev.filters.map(filt => (filt.id === id ? { id, config: dateFilterConfig, label, filter, date } : filt))
                        : [
                              ...prev.filters,
                              {
                                  id,
                                  config: dateFilterConfig,
                                  label,
                                  date,
                                  filter,
                              },
                          ];

                return {
                    ...prev,
                    filters,
                };
            });
        }
    };

    const allSelectableNetworks = useMemo(() => {
        const networks = queried.txs.reduce<string[]>((acc, tx) => {
            const { label } = NETWORKS[tx.chainId];
            return label ? [...acc, label] : acc;
        }, []);
        return [...new Set(networks)];
    }, [filteredTxs.txs.length]);

    const txTypes = useMemo(() => {
        const types = queried.txs.reduce<DetectedExternalTxType[]>((acc, tx) => (tx.detectedType ? [...acc, tx.detectedType] : acc), []);
        return [...new Set(types)];
    }, [filteredTxs.txs.length]);

    const currencies = useMemo(() => {
        const currencies = queried.allTransfers.reduce<Set<string>>(
            (acc, tr) => (tr.transferType.kind == 'fungible' ? acc.add(getCurrencyString(tr.transferType.tokenInfo)) : acc),
            new Set<string>(),
        );
        return [...currencies];
    }, [filteredTxs.allTransfers.length]);

    const selectAll = () =>
        setExternalTxState(prev => ({ ...prev, omittedIds: allSelected ? prev.queried.allTransfers.map(t => t.id) : [] }));

    const hideImportedTransactions = filters.some(x => x.id == hideImportedTransactionsFilterId);

    return (
        <Stack spacing="4" {...styleProps}>
            <HStack spacing="3">
                <ToolbarSelector
                    label="Networks"
                    isDisabled={false}
                    options={allSelectableNetworks}
                    omittedOptions={omittedNetworkTypes}
                    addOption={selectNetworkFilter}
                />

                <ToolbarSelector
                    label="Direction"
                    isDisabled={txTypes.length === 0}
                    options={['In ⬅️', 'Out ➡️']}
                    omittedOptions={omittedTxDirections}
                    addOption={addTxDirectionFilter}
                />

                <ToolbarSelector
                    label="Types"
                    isDisabled={txTypes.length === 0}
                    options={txTypes}
                    omittedOptions={omittedTxTypes}
                    addOption={addTxTypeFilter}
                />

                <ToolbarSelector
                    label={'Tokens'}
                    isDisabled={currencies.length === 0}
                    options={currencies}
                    omittedOptions={omittedCurrencies}
                    addOption={addCurrencyFilter}
                />

                <DateRange
                    isDisabled={step === 'loading'}
                    setStart={handleDateChange('start')}
                    setEnd={handleDateChange('end')}
                    filters={filters}
                />

                <Tag>
                    <Text fontWeight={700} py="2" fontSize={'14px'}>
                        {Math.max(filteredTxs.allTransfers.length - omittedIds.length, 0)}/{queried.allTransfers.length}
                    </Text>
                    <Text ml="1">Transfers Shown</Text>
                </Tag>
            </HStack>
            <Wrap spacing={'3'}>
                {filters.map(filter => {
                    const remove = () => setExternalTxState(prev => ({ ...prev, filters: prev.filters.filter(f => f.id !== filter.id) }));
                    const isDateVariant = filter.config.prefix === 'date';
                    return (
                        <WrapItem key={filter.id}>
                            <Tag variant="solid" w="fit-content" py="1" colorScheme={isDateVariant ? 'brand' : 'gray'}>
                                <TagLabel>
                                    {isDateVariant ? '📆 ' : ''}
                                    {filter.label}
                                </TagLabel>
                                <TagCloseButton onClick={remove} />
                            </Tag>
                        </WrapItem>
                    );
                })}
                {filters.length > 0 && (
                    <WrapItem alignItems={'center'}>
                        <BullaBlueTextButton onClick={() => setExternalTxState(prev => ({ ...prev, filters: [] }))}>
                            Clear Filters
                        </BullaBlueTextButton>
                    </WrapItem>
                )}
            </Wrap>
        </Stack>
    );
};

export const Td = (props: any) => <ChakraTd {...props} maxH="57px" />;

export const InboundOutboundTd = ({
    userAddress,
    transfer: { from, to, chainId },
    isReverse,
    txHash,
    ...overrides
}: {
    userAddress: EthAddress;
    transfer: TransferDTO;
    isReverse?: boolean;
    txHash?: string;
} & BoxProps) => {
    const addressLabels = addressEquality(from, userAddress)
        ? [
              <ContactNameOrAddress chainId={chainId} children={userAddress} hideAddressIfFound />,
              <ContactNameOrAddress chainId={chainId} children={to} link={{ chainId }} hideAddressIfFound />,
          ]
        : [
              addressEquality(from, constants.AddressZero) ? (
                  <Tag colorScheme={'green'} children="💰 Mint" />
              ) : (
                  <ContactNameOrAddress chainId={chainId} children={from} hideAddressIfFound />
              ),
              <ContactNameOrAddress chainId={chainId} link={{ chainId }} children={userAddress} hideAddressIfFound />,
          ];
    const [left, right] = isReverse ? addressLabels.reverse() : addressLabels;
    const { blockExplorer } = NETWORKS[chainId];

    return (
        <Td pl={isReverse ? '12' : '6'} {...overrides}>
            <HStack>
                {left} {isReverse ? <ArrowBackIcon /> : <ArrowForwardIcon />} {right}
                {txHash && (
                    <IconButton
                        as={Link}
                        href={`${blockExplorer}tx/${txHash}`}
                        isExternal
                        icon={<ExternalLinkIcon />}
                        aria-label="Open in explorer"
                        size="l"
                        variant="ghost"
                    />
                )}
            </HStack>
        </Td>
    );
};

export const CumulativeAmountTd = ({
    isOpen,
    toggleOpen,
    cumulativeAmounts,
}: {
    isOpen: boolean;
    toggleOpen: VoidFunction;
    cumulativeAmounts: CumulativeTransferAmountsByTokenAddress;
}) => {
    const cumulativeAmountsArray = Object.values(cumulativeAmounts);
    const displayCompactTokens = cumulativeAmountsArray.length > 1;

    const amounts = useMemo(
        () =>
            cumulativeAmountsArray.reduce<CumulativeAmountWithLabels[]>((acc, cumulation) => {
                if (cumulation.kind == 'non-fungible') {
                    return [...acc, { ...cumulation, label: `${cumulation.amount} ${cumulation.symbol}`, tooltip: undefined }];
                }
                const rounding = TOKEN_ROUNDING[cumulation.tokenInfo.variant];
                const amount = formatUnits(cumulation.amount, cumulation.tokenInfo.token.decimals);

                let label: string;
                let tooltip: string | undefined;

                const [whole, decimal] = amount.split('.');
                const decimals = decimal != '0' && decimal?.length ? decimal?.length : 0;
                const symbolOverflows = cumulation.tokenInfo.token.symbol.length > 12;
                const symbol = symbolOverflows ? cumulation.tokenInfo.token.symbol.slice(0, 15) + '...' : cumulation.tokenInfo.token.symbol;

                if (displayCompactTokens) tooltip = `${amount} ${cumulation.tokenInfo.token.symbol}`;

                if (decimals > rounding || symbolOverflows) {
                    const rounded = decimals > 0 ? Number(amount).toFixed(rounding) : amount;
                    const isLessThanRounding = +rounded === 0;
                    label = `${isLessThanRounding ? '<' : ''}${isLessThanRounding ? rounded.slice(0, -1) + '1' : rounded} ${symbol}`;
                    tooltip = `${amount} ${cumulation.tokenInfo.token.symbol}`;
                } else {
                    label = `${decimals == 0 ? whole : amount} ${symbol}`;
                }

                return [...acc, { ...cumulation, label, tooltip }];
            }, []),
        [JSON.stringify(cumulativeAmountsArray)],
    );

    return (
        <Td isNumeric fontWeight={500} overflowY="hidden">
            <HStack justifyContent={'flex-end'} spacing={displayCompactTokens ? '-1' : '1'}>
                {displayCompactTokens && (
                    <HStack mx="2" onClick={toggleOpen} _hover={{ textDecor: 'underline', cursor: 'pointer' }}>
                        <TriangleDownIcon w="9px" h="9px" transform={!isOpen ? 'rotate(-90deg)' : 'rotate(0deg)'} />
                        <BullaBlueTextButton textDecor={'none'}>+ {amounts.length} txs</BullaBlueTextButton>
                    </HStack>
                )}
                {amounts.map((amount, i) => (
                    <Tooltip label={amount.tooltip} isDisabled={amount.tooltip === undefined} placement="top" key={i}>
                        <HStack key={i} color={amount.amount.gt(constants.Zero) ? 'green.400' : 'red.400'} spacing="1" mr={i * -20 + 'px'}>
                            {displayCompactTokens && (
                                <Text
                                    fontWeight={!!displayCompactTokens ? 700 : 500}
                                    fontSize={!!displayCompactTokens && amount.action == 'Sent' ? '18px' : 'initial'}
                                    mt={'-25px'}
                                    mr={'-5px'}
                                >
                                    {amount.action === 'Received' ? '+' : '-'}
                                </Text>
                            )}
                            <Text display={!!displayCompactTokens ? 'none' : 'initial'}>{amount.label}</Text>
                            <Image src={amount.kind == 'non-fungible' ? UnknownTokenLogo : amount.tokenInfo.icon} maxH="16px" maxW="16px" />
                        </HStack>
                    </Tooltip>
                ))}
            </HStack>
        </Td>
    );
};

const ExternalTxTag = ({
    tag,
    isSelected,
    remove,
    setSelectedTag,
}: {
    tag: string;
    isSelected: boolean;
    remove: VoidFunction;
    setSelectedTag: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
    const tagRef = useRef<HTMLDivElement | null>(null);
    useOutsideClick({
        ref: tagRef,
        handler: () => setSelectedTag(undefined),
    });
    const select = () => setSelectedTag(tag);

    const bg = isSelected ? 'gray.300' : 'gray.200';

    return (
        <Tag bg={bg} onClick={select} ref={tagRef}>
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={remove} _hover={{ cursor: 'pointer' }} />
        </Tag>
    );
};

export const CategoryInput = ({
    setTags,
    tags = [],
    suggestedTags = [],
    ...props
}: {
    tags?: string[];
    setTags: (callback: (prev: string[]) => string[]) => void;
    suggestedTags?: string[];
} & Partial<StyleProps>) => {
    const [value, setValue] = useState('');
    const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
    const [touched, setFieldTouched] = useState(false);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const addTag = (tag: string) => {
        if (tags.includes(tag)) return;
        setTags(prev => [...prev, tag]);
        setValue('');
    };
    const removeTag = (tag: string) => {
        setTags(prev => prev.filter(t => t !== tag));
    };

    const inputRef = React.useRef<HTMLInputElement>(null);
    const displayTags = tags.length > 0 || suggestedTags.length > 0;

    const { accountTagSummary } = useUserSummary();
    const allTags = [...new Set(['Expense', 'Rental', ...Object.keys(accountTagSummary)])];

    const { getMenuProps, highlightedIndex, getItemProps, getInputProps } = useCombobox({
        items: allTags,
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                setValue(selectedItem);
            }
        },
        onInputValueChange: ({ inputValue }) => {
            setValue(inputValue || '');
        },
    });

    return (
        <Box
            minW="200px"
            pr="4"
            display="flex"
            alignItems="center"
            h="36px"
            outline="transparent solid 2px"
            outlineOffset="2px"
            pl="4"
            onClick={() => inputRef.current?.focus()}
            borderWidth="1px"
            transition={'all 0.2s ease'}
            _hover={{
                cursor: 'text',
                borderColor: 'gray.300',
            }}
            _focusWithin={{
                outline: 'transparent solid 2px',
                borderColor: '#3182ce',
                boxShadow: '0 0 0 1px #3182ce',
            }}
            borderRadius="md"
            {...props}
        >
            {displayTags && (
                <HStack>
                    {tags.length === 0 &&
                        !touched &&
                        suggestedTags.map((tag, i) => (
                            <HStack
                                key={i + 'suggested'}
                                _hover={{ cursor: 'pointer' }}
                                onClick={() => setTags(prev => (prev.includes(tag) ? prev : [...prev, tag]))}
                            >
                                <Tag bg="brand.100" key={'suggested' + i} _hover={{ cursor: 'pointer' }}>
                                    <TagLabel opacity={0.9} color="white">
                                        {getEmojiForDetectedType(tag)}
                                    </TagLabel>
                                </Tag>
                                <TagRightIcon boxSize="10px" w="10px" h="10px" as={AddIcon} />
                            </HStack>
                        ))}
                    {tags.map((tag, i) => (
                        <ExternalTxTag
                            key={i}
                            tag={getEmojiForDetectedType(tag)}
                            setSelectedTag={setSelectedTag}
                            isSelected={selectedTag === tag}
                            remove={() => setTags(prev => prev.filter((_, k) => i !== k))}
                        />
                    ))}
                </HStack>
            )}
            <Flex cursor="pointer" fontSize={'14px'} mt="10">
                <div {...getMenuProps()}>
                    <Stack bg="white" border="xl" boxShadow="2xl" zIndex={100} w="fit-content" borderRadius="md" pos="absolute">
                        <Collapse in={isDropdownOpen}>
                            <>
                                {allTags.map((item, index) => (
                                    <Stack
                                        py="2"
                                        px="6"
                                        w="100%"
                                        key={`${item}${index}`}
                                        {...getItemProps({ item, index })}
                                        cursor={highlightedIndex === index ? 'pointer' : 'default'}
                                        bg={highlightedIndex === index ? 'gray.100' : 'white'}
                                    >
                                        <Text color="black"> {item}</Text>
                                    </Stack>
                                ))}
                            </>
                        </Collapse>
                    </Stack>
                </div>
            </Flex>
            <Input
                {...getInputProps()}
                pl={displayTags ? '4' : 'initial'}
                h="32px"
                value={value}
                ref={inputRef}
                variant="unstyled"
                placeholder="Add category..."
                minW={`calc(20px + (.5rem * ${value.length}))`}
                onChange={e => {
                    if (!touched) setFieldTouched(true);
                    if (selectedTag) setSelectedTag(undefined);
                    setValue(e.target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter' && value !== '') {
                        addTag(value);
                        setValue('');
                    } else if (value === '' && e.key === 'Backspace') {
                        if (!selectedTag) setSelectedTag(tags[tags.length - 1]);
                        else {
                            removeTag(selectedTag);
                            setSelectedTag(undefined);
                            setValue('');
                        }
                    }
                }}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                {...disabledInputProps}
            />
        </Box>
    );
};

type CumulativeERC20Amount = {
    kind: 'fungible';
    amount: BigNumber;
    action: 'Sent' | 'Received';
    tokenInfo: TokenInfo;
};
type CumulativeERC721Amount = {
    kind: 'non-fungible';
    action: 'Sent' | 'Received';
    tokenIds: string[];
    amount: BigNumber;
    symbol: string;
};

export type CumulativeTransferAmountsByTokenAddress = Record<EthAddress, CumulativeERC20Amount | CumulativeERC721Amount>;
export type CumulativeAmountWithLabels = { label: string; tooltip: string | undefined } & CumulativeTransferAmountsByTokenAddress[string];

export const getCumulativeAmounts = (transfers: TransferDTO[], userAddress: EthAddress) =>
    transfers.reduce<CumulativeTransferAmountsByTokenAddress>((acc, { transferType, value, to, action }) => {
        if (transferType.kind == 'non-fungible') {
            const previousValue = acc[transferType.contractAddress] as CumulativeERC721Amount;
            const previousAmount = previousValue?.amount ?? BigNumber.from(0);
            return {
                ...acc,
                [transferType.contractAddress]: {
                    action,
                    kind: transferType.kind,
                    tokenIds: [...(previousValue?.tokenIds ?? []), transferType.tokenId],
                    amount: action == 'Sent' ? previousAmount.sub(1) : previousAmount.add(1),
                    symbol: transferType.tokenSymbol,
                },
            };
        }
        const prev = acc[transferType.tokenInfo.token.address] as CumulativeERC20Amount;
        const prevVal = prev?.amount ?? BigNumber.from('0');
        return {
            ...acc,
            [transferType.tokenInfo.token.address]: {
                amount: action == 'Sent' ? prevVal.sub(value) : prevVal.add(value),
                tokenInfo: transferType.tokenInfo,
                action: addressEquality(to, userAddress) ? 'Received' : 'Sent',
                kind: 'fungible',
            },
        };
    }, {});

export const getSkeletonRows = (headers: Readonly<any[]>, count: number) =>
    fillToCount([], count).map((_, i) => (
        <Tr key={'skeleton' + i} h="57px">
            {fillToCount([], headers.length + 1).map((_, j) =>
                j == 0 ? (
                    <TableHeader key={j}>
                        <Checkbox isDisabled />
                    </TableHeader>
                ) : (
                    <TableHeader key={j}>
                        <Skeleton height="20px" />
                    </TableHeader>
                ),
            )}
        </Tr>
    ));

export const getFillerRows = (headers: Readonly<any[]>, count: number) =>
    fillToCount([], count).map((t, i) => (
        <Tr key={i}>
            {fillToCount([], headers.length + 1).map((_, j) => (
                <Tr key={j} />
            ))}
        </Tr>
    ));

export const TableHeader = ({ children, ...trProps }: { children: React.ReactNode } & TableColumnHeaderProps) => (
    <Th fontWeight={700} color="gray.900" fontSize={'12px'} {...trProps}>
        {children}
    </Th>
);

export const TransferTd = ({ transfer: { action, transferType, value } }: { transfer: TransferDTO }) => (
    <Td isNumeric color={action === 'Received' ? 'green.400' : 'red.400'} fontWeight={500}>
        <HStack justifyContent={'flex-end'}>
            {transferType.kind == 'fungible' ? (
                <>
                    <Text>
                        {!!transferType.tokenInfo &&
                            `${action === 'Received' ? '+' : '-'} ${weiToDisplayAmt({
                                amountWei: BigNumber.from(value),
                                decimals: transferType.tokenInfo.token.decimals,
                            })} ${transferType.tokenInfo.token.symbol}`}
                    </Text>
                    <Image src={transferType.tokenInfo.icon} h="16px" w="16px" maxW="16px" />
                </>
            ) : (
                <Text>{`${action === 'Received' ? '+' : '-'} #${transferType.tokenId} ${transferType.tokenSymbol}`}</Text>
            )}
        </HStack>
    </Td>
);

export const getDetectedTxTypeBadge = (detectedType: DetectedExternalTxType, functionName?: string) => (
    <Tag variant={detectedType ? 'brand' : 'solid'}>
        <HStack>
            <Text>{getEmojiForDetectedType(detectedType)}</Text>
            {detectedType && functionName && (
                <Tooltip
                    placement="top-start"
                    label={
                        <Text>
                            We've made a guess on the purpose of this transaction based on the function name{' '}
                            <Kbd color={'black'}>{functionName}</Kbd>. Please confirm on the block explorer
                        </Text>
                    }
                >
                    <InfoOutlineIcon />
                </Tooltip>
            )}
        </HStack>
    </Tag>
);
