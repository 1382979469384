import { BullaFinance } from '@bulla-network/contracts/typechain/BullaFinance';
import { BigNumber, ContractTransaction, utils } from 'ethers';
import moment from 'moment';
import { canConvertToBigNumber } from '../../components/modals/create-claim-modal/create-claim-inputs';
import { ChangeType } from '../../tools/types';
import { FinancingOfferedClaimInfo, TAG_SEPARATOR } from '../data-model';
import { EthAddress, toChecksumAddress } from '../ethereum';
import { dateToInt, toBytes32 } from '../helpers';
import { TokenDto } from '../networks';
import { BullaClaimDto } from './bulla-claim-dto';
import { IBullaFinance } from './contract-interfaces';

export const MAX_BPS = 10_000;
export const MAX_BPS_BN = BigNumber.from(MAX_BPS);

export type TermLength = { years: number; months: number; days: number };

export type TermLengthInputs = ChangeType<TermLength, string>;

export type FinancingTerms = {
    principalAmount: BigNumber;
    downPaymentBPS: number;
    interestRateBPS: number;
    downPayment: BigNumber;
    loanValue: BigNumber;
    interestValue: BigNumber;
    totalAmountDue: BigNumber;
    termLength: TermLength;
};

export type FinancingTermInputs = {
    principalAmount: string;
    downPaymentPercent: string;
    interestRate: string;
    financingDueBy: Date;
};

export const financeInputValidator = (values: FinancingTermInputs) => {
    const { principalAmount, downPaymentPercent, interestRate, financingDueBy } = values;
    if (!canConvertToBigNumber(principalAmount) || !canConvertToBigNumber(downPaymentPercent) || !canConvertToBigNumber(interestRate))
        throw new Error('Unsafe input');
    if (+downPaymentPercent > 100) throw new Error('Down payment percent must be less than 100%');
    if (+interestRate > 100_000) throw new Error('Interest rate must be less than 100,000%');
    if (+downPaymentPercent > 0 && +downPaymentPercent < 0.01) throw new Error('Down payment percent must be greater than 1 BPS');
    if (+interestRate > 0 && +interestRate < 0.01) throw new Error('Interest rate must be greater than 1 BPS');
    if (financingDueBy.getTime() < Date.now()) throw new Error('Financing due date must be in the future');
    return true;
};

export const areFinanceInputsValid = (values: FinancingTermInputs) => {
    try {
        return financeInputValidator(values);
    } catch (e) {
        console.log('invalid finance terms', e);
        return false;
    }
};

function calculateTermLength(financingDueBy: Date): TermLength {
    const now = moment();
    const future = moment(financingDueBy);

    const years = future.diff(now, 'years');
    now.add(years, 'years');

    const months = future.diff(now, 'months');
    now.add(months, 'months');

    const days = Math.ceil(future.diff(now, 'days', true));

    return { years, months, days };
}

/**
 * @dev amounts are returned as the "wei" amount of the underlying token for example: given USDC's 6 decimals. principalAmountString: '1' will yield principalAmount: 1000000
 */
export const processFinancingTermInputs = (
    principalAmountInput: string,
    downPaymentPercent: string,
    interestRatePercent: string,
    decimals: number,
    financingDueBy: Date,
): FinancingTerms | 'invalid' => {
    if (
        !areFinanceInputsValid({
            principalAmount: principalAmountInput,
            downPaymentPercent,
            interestRate: interestRatePercent,
            financingDueBy,
        })
    )
        return 'invalid';

    const termLength = calculateTermLength(financingDueBy);

    const principalAmount = utils.parseUnits(principalAmountInput, decimals);
    const interestRateBPS = Math.floor(+interestRatePercent * 100);
    const downPaymentBPS = Math.floor(+downPaymentPercent * 100);

    return getFinancingTerms(principalAmount, downPaymentBPS, interestRateBPS, termLength);
};

const pluralize = (n: number) => (n > 1 ? 's' : '');

export const transformDaysToTermLengthLabel = (days: number): string => {
    let mutLabel = '';
    let mutDays = days;

    const years = Math.floor(mutDays / 365);
    if (years > 0) {
        mutLabel += `${years} year${pluralize(years)}`;
        mutDays -= years * 365;
    }

    const months = Math.floor(mutDays / 30);
    if (months > 0) {
        mutLabel += ` ${months} month${pluralize(months)}`;
        mutDays -= months * 30;
    }

    if (mutDays > 0) mutLabel += ` ${mutDays} day${pluralize(mutDays)}`;

    return mutLabel;
};

export const termLengthToDays = ({ days, months, years }: TermLength) => days + months * 30 + years * 365;
export const daysToTermLength = (totalDays: number): TermLength => {
    const years = Math.floor(totalDays / 365);
    const months = Math.floor((totalDays - years * 365) / 30);
    const days = totalDays - months * 30;

    return { years, months, days };
};
export const transformTermLengthInputToLabel = (term: TermLength) => transformDaysToTermLengthLabel(termLengthToDays(term));

export const getFinancingSummaryLabel = (token: TokenDto, financingTerms: Pick<FinancingTerms, 'loanValue' | 'interestRateBPS'>) =>
    `${utils.formatUnits(financingTerms.loanValue, token.decimals)} ${token.symbol} loan @ ${
        financingTerms.interestRateBPS / 100
    }% interest.`;

type CreateBullaClaimParams = BullaClaimDto & {
    minDownPaymentBPS: number; // these 3 values can all safely be number since they are all integers less than uint40.max
    interestBPS: number;
    termLength: number;
    contract: BullaFinance;
};

export const createFinanciableInvoice = async ({
    tags,
    claimAmount,
    creditor,
    token,
    debtor,
    description,
    dueBy,
    ipfsHash,
    tokenURI,
    interestBPS,
    minDownPaymentBPS,
    termLength,
    contract,
}: CreateBullaClaimParams): Promise<ContractTransaction> =>
    await contract.createInvoiceWithFinanceOffer(
        {
            claimAmount,
            creditor,
            debtor,
            description,
            dueBy: dateToInt(dueBy),
            claimToken: token.address,
            attachment: ipfsHash,
        },
        tokenURI,
        { interestBPS, minDownPaymentBPS, termLength },
        toBytes32(tags?.join(TAG_SEPARATOR) ?? ''),
        { value: await contract.callStatic.fee() },
    );

export function getFinancingTerms(
    principalAmount: BigNumber,
    downPaymentBPS: number,
    interestRateBPS: number,
    termLength: TermLength,
): FinancingTerms {
    const downPayment = principalAmount.mul(downPaymentBPS).div(MAX_BPS);

    const loanValue = principalAmount.sub(downPayment);
    const interestValue = loanValue.mul(interestRateBPS).div(MAX_BPS);
    const totalAmountDue = principalAmount.add(interestValue);

    return {
        principalAmount,
        downPaymentBPS,
        interestRateBPS,
        downPayment,
        loanValue,
        interestValue,
        totalAmountDue,
        termLength,
    };
}

export const getAcceptFinancingTransaction = (_bullaFinanceAddress: EthAddress, claimInfo: FinancingOfferedClaimInfo) => ({
    to: toChecksumAddress(_bullaFinanceAddress),
    value: '0',
    operation: 0,
    data: IBullaFinance.encodeFunctionData('acceptFinancing', [
        claimInfo.id,
        claimInfo.financingState.terms.downPayment,
        claimInfo.description,
    ]),
});
