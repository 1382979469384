import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Tag, TagLabel, TagRightIcon, TagLeftIcon } from '@chakra-ui/react';
import React from 'react';
import { Direction } from './display/claim-table';

export const DirectionBadge = ({ direction }: { direction: Direction }) => (
    <Tag bg={direction == 'Out' ? 'orange.300' : 'green.400'} color={'scheme.light'}>
        <TagLabel>{direction}</TagLabel>
        <TagRightIcon as={direction == 'Out' ? ArrowForwardIcon : ArrowBackIcon} />
    </Tag>
);

export const NewDirectionBadge = ({ direction }: { direction: Direction }) => (
    <Tag bg={direction == 'Out' ? '#FDECEC' : '#ECFDF3'} color={direction == 'Out' ? '#7A0202' : '#027A48'} borderRadius={'16px'} mr="2"  as="span">
        {direction == 'In' && <TagLeftIcon as={ArrowBackIcon} />}
        <TagLabel>{direction}</TagLabel>
        {direction == 'Out' && <TagRightIcon as={ArrowForwardIcon} />}
    </Tag>
);
