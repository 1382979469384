import { Box, Button, Flex, Image, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spacer, Stack, Text } from '@chakra-ui/react';
import axios from 'axios';
import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { ClaimInfo } from '../../../data-lib/data-model';
import { NETWORKS } from '../../../data-lib/networks';
import { useWeb3 } from '../../../hooks/useWeb3';
import { BullaBlueTextButton, OrangeButton } from '../../inputs/buttons';
import { CloseModalButton, getBullaItemLink } from '../common';
import BullaLogo from 'url:../../../assets/logo_orange.svg';
import { useBullaToast } from '../../base/toast';

type TruckerFinanceOfferModalProps = { claim: ClaimInfo; isOpen: boolean; onClose: VoidFunction };
const TruckerFinanceOfferModal = ({ isOpen, onClose, claim }: TruckerFinanceOfferModalProps) => {
    const {
        chainId,
        id,
        tokenInfo: {
            token: { decimals, symbol },
        },
        claimAmount,
        debtor,
        creditor,
    } = claim;
    const [inProgress, setInProgress] = React.useState(false);
    const triggerToast = useBullaToast();
    const claimDisplayAmount = formatUnits(claimAmount, decimals);
    const onClick = React.useCallback(() => {
        setInProgress(true);
        return axios
            .post(
                'https://discord.com/api/webhooks/1164993280036057108/TCuYp9vQCfbkQk_IS8hGz4oWPzVXeVJc6pMC1n2HhGen-THalKORz0F5T2NZJ9yq6r5P',
                {
                    embeds: [
                        {
                            title: 'You have received a new financing request',
                            description: 'Click the link to view the claim',
                            fields: [
                                { name: 'Network', value: NETWORKS[chainId].label, inline: true },
                                { name: 'Id', value: id, inline: true },
                                { name: 'Claim amount', value: `${claimDisplayAmount} ${symbol}`, inline: true },
                                { name: 'Creditor', value: creditor },
                                { name: 'Debtor', value: debtor },
                                { name: 'Link', value: getBullaItemLink(claim) },
                            ],
                        },
                    ],
                },
            )
            .then(() => {
                triggerToast({ title: 'Financing request successful' });
                onClose();
            })
            .finally(() => {
                setInProgress(false);
            });
    }, []);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="lg" closeOnEsc scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent py="4" px="2" maxH="80%">
                <CloseModalButton onClose={onClose} />
                <ModalBody pb={6} pt={6}>
                    <Text color="gray.700" fontWeight={'700'} fontSize="24px">
                        Request Financing
                    </Text>
                    <Box pt={6}>
                        <Text>
                            Once financing is requested, we will analyze the case potentially send you a loan offer.
                            <br />
                            <br /> Once the loan offer is accepted, you will receive funds and an invoice to pay it back.
                        </Text>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                        Cancel
                    </Button>
                    <Spacer />
                    <OrangeButton type="submit" isLoading={inProgress} onClick={onClick}>
                        Request financing
                    </OrangeButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

type TruckerFinanceOfferBoxProps = { claim: ClaimInfo };
export const TruckerFinanceOfferBox = ({ claim }: TruckerFinanceOfferBoxProps) => {
    const { connectedNetwork } = useWeb3();
    const [isModalOpen, setOpenModal] = React.useState(false);

    return (
        <>
            <Flex border="1px solid #E2E8F0" borderRadius={'8px'} flexDir="row" pr="8" pl="5" py="4">
                <Image src={BullaLogo} mr="5" w="40px" />
                <Stack spacing="1" w="90%">
                    <Text fontSize={'18px'} fontWeight="700" color={'black'}>
                        Get paid early with Bulla Finance
                    </Text>
                    <Text fontSize={'12px'} w="320px" pb="3">
                        {' '}
                        Finance your invoice and get paid 100% of its value upfront for a fee.
                    </Text>
                </Stack>
                <Spacer />
                <BullaBlueTextButton
                    onClick={() => setOpenModal(true)}
                    isDisabled={claim.chainId != connectedNetwork}
                    textDecoration={'none'}
                >
                    Learn More
                </BullaBlueTextButton>
            </Flex>

            {isModalOpen && <TruckerFinanceOfferModal isOpen={isModalOpen} onClose={() => setOpenModal(false)} claim={claim} />}
        </>
    );
};
