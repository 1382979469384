import { Provider } from '@ethersproject/abstract-provider';
import {
    filterClaimTransferEvents,
    getBullaInfoItemCreatedRequest,
    getBullaInfoItemUpdatesRequest,
    getClaimTransfersRequest,
    getUserClaimIds,
    mergeCacheWithNewLogs,
    RequestParams,
} from '../state/state-helpers';
import { cacheLogs, getLogsCache } from '../tools/storage';
import { EventLogs, formatEventLogs } from './dto/events-dto';
import { EthAddress } from './ethereum';
import { NetworkConfig } from './networks';

export const getHistoricalLogs = (provider: Provider, fromBlock?: number) => async (requestParameters: RequestParams) => {
    console.debug(`🔍 querying for logs from block: ${fromBlock}`);
    const requests = Object.entries(requestParameters).map(async ([request, { topics, eventData, fromAddress }]) => {
        console.debug(`📜 Fetching historical logs for ${request}`);
        const logs = await provider.getLogs({
            ...(fromAddress ? { address: fromAddress } : {}),
            topics,
            fromBlock: fromBlock ?? 0,
        });
        return {
            request,
            events: formatEventLogs(logs, eventData),
        };
    });

    return Promise.all(requests).then(logs =>
        logs.reduce<EventLogs>(
            (acc, log) => ({
                ...acc,
                [log.request]: log.events,
            }),
            {},
        ),
    );
};

export const getEventLogs = async ({
    networkConfig,
    provider,
    queryAddress,
}: {
    networkConfig: NetworkConfig;
    provider: Provider;
    queryAddress: EthAddress;
}) => {
    const CACHE_ENABLED = false;

    const logCache = CACHE_ENABLED ? getLogsCache(networkConfig.chainId, queryAddress) : undefined;
    const fromBlock = logCache ? logCache.latestLogBlock + 1 : networkConfig.deployedOnBlock;

    const getLogs = getHistoricalLogs(provider, fromBlock);
    /** get all transfer events related to the user */
    const claimTransferRequest = getClaimTransfersRequest(queryAddress, networkConfig.bullaClaimAddress);
    const claimTransferEvents = await getLogs(claimTransferRequest);
    const { transferredIds, filteredTransferEvents } = filterClaimTransferEvents(claimTransferEvents);

    /** get all entities: the ClaimCreated events + Instant Payment events for this user */
    const bullaItemInfoCreatedRequest = getBullaInfoItemCreatedRequest(queryAddress, networkConfig, transferredIds);
    const bullaItemInfoCreatedEvents = await getLogs(bullaItemInfoCreatedRequest);

    /** get any updates relevant to those claims / payments */
    const tokenIds = getUserClaimIds(bullaItemInfoCreatedEvents);
    const bullaItemInfoUpdatedRequest = getBullaInfoItemUpdatesRequest(queryAddress, tokenIds, networkConfig);
    const bullaItemInfoUpdates = await getLogs(bullaItemInfoUpdatedRequest);

    const newEventLogs = {
        ...bullaItemInfoCreatedEvents,
        ...bullaItemInfoUpdates,
        ...filteredTransferEvents,
    };

    const eventLogs = mergeCacheWithNewLogs(logCache, newEventLogs);
    if (CACHE_ENABLED) cacheLogs(networkConfig.chainId, queryAddress, eventLogs, await provider.getBlockNumber());

    return Object.values(eventLogs).flat();
};
