{
    "defaultAddress": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
    "released": true,
    "contractName": "GnosisSafeProxyFactory",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "3": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "10": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "11": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "12": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "28": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "42": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "5": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "56": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "69": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "100": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "122": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "123": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "137": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "246": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "288": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "588": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1088": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "1284": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1285": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1287": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "4002": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "42161": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "42220": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "43114": "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC",
        "73799": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "80001": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "333999": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1313161554": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2",
        "1313161555": "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2"
    },
    "abi": [
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "singleton",
                    "type": "address"
                }
            ],
            "name": "ProxyCreation",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                }
            ],
            "name": "calculateCreateProxyWithNonceAddress",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                }
            ],
            "name": "createProxy",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                },
                {
                    "internalType": "contract IProxyCreationCallback",
                    "name": "callback",
                    "type": "address"
                }
            ],
            "name": "createProxyWithCallback",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_singleton",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "internalType": "uint256",
                    "name": "saltNonce",
                    "type": "uint256"
                }
            ],
            "name": "createProxyWithNonce",
            "outputs": [
                {
                    "internalType": "contract GnosisSafeProxy",
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "proxyCreationCode",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "proxyRuntimeCode",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        }
    ]
}
