import { ArrowBackIcon, ArrowForwardIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Button, ButtonProps, Center, Flex, HStack, IconButton, Spinner, Text, TextProps } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import React, { useEffect } from 'react';
import { WarningIconWithTooltip } from '../../assets/warning';
import { BullaItemInfo, ClaimInfo, ClaimStatus, lastPaymentDate } from '../../data-lib/data-model';
import { InvoiceFundedEvent } from '../../data-lib/domain/factoring-domain';
import { EthAddress } from '../../data-lib/ethereum';
import { getItemRelationToUser, isClaim } from '../../data-lib/helpers';
import { useIsMobile } from '../../hooks/useIsMobile';
import { PageSelectorProps, usePagination } from '../../hooks/usePagination';
import { useSelection } from '../../hooks/useSelection';
import { useDataReadiness } from '../../hooks/useUserData';
import { toDateDisplay } from '../../tools/common';
import { ChakraCompose } from '../../tools/types';
import { FromAndToWallet } from '../base/address-label';
import { getStatusBadge } from '../base/status-badge';
import { ChainSymbol } from '../chain-symbol';
import { TokenAmount } from '../currency/token-display-amount';
import { OneTagPillOrMore } from '../inputs/account-tag-input';
import { ViewDetailsButton } from '../inputs/buttons';
import {
    AMOUNT_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListItemProps,
    ListViewCard,
    ListViewCardProps,
    shadowProps,
    STATUS_COLUMN_WIDTH,
    TwoLineTextWithLabel,
    VIEW_COLUMN_WIDTH,
} from '../layout/cards';
import { getFundedAndKickbackFactoringPayments, getUnfactoredPayments } from '../modals/item-details-modal/claim-details';

export type Categories = ClaimStatus | 'All';
export type Direction = 'In' | 'Out';

type CategoryProps = ChakraCompose & {
    onClick: () => void;
};

export type TableItem = BullaItemInfo & {
    itemStatus: ClaimStatus;
    onClick: VoidFunction;
    displayAmount: BigNumber;
    direction: Direction;
    isPending: boolean;
    isOverdue: boolean;
    displayDate: Date;
    factoringFees?: number;
    originalCreditor?: string;
};

const calculateFactoringFees = (claim: ClaimInfo): number => {
    const factoringPayments = getFundedAndKickbackFactoringPayments(claim);
    const { interestCharged } = getUnfactoredPayments(claim);
    return claim.claimStatus === 'Paid'
        ? +claim.claimAmount.sub(factoringPayments)
        : claim.claimStatus === 'Unfactored'
        ? +interestCharged
        : 0;
};

export const mapBullaItemInfoToTableItem = (
    items: BullaItemInfo[],
    userAddress: EthAddress,
    openItem: (item: BullaItemInfo) => void,
): TableItem[] =>
    items.map(item => {
        const _isClaim = isClaim(item);
        const itemStatus: ClaimStatus = _isClaim ? item.claimStatus : 'Paid';
        const onClick = () => openItem(item);
        const { direction } = getItemRelationToUser(userAddress, item);
        const isPending = _isClaim ? item.claimStatus === 'Repaying' || item.claimStatus == 'Pending' : false;
        const isOverdue = _isClaim ? item.dueBy.getTime() < new Date().getTime() && direction == 'Out' && isPending : false;
        const displayAmount = _isClaim ? item.claimAmount : item.paidAmount;
        const factoringFees = item.__type === 'Claim' ? calculateFactoringFees(item as ClaimInfo) : 0;

        return {
            ...item,
            itemStatus,
            onClick,
            direction,
            isPending,
            isOverdue,
            displayAmount,
            displayDate: _isClaim ? item.dueBy : item.created,
            factoringFees,
        };
    });

const HEADERS: ListViewCardProps['headers'] = [
    { label: 'debtor / creditor', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'status', relativeColumnWidth: STATUS_COLUMN_WIDTH },
    { label: 'created date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'due date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'description' },
    { label: 'categories' },
    { label: 'amount', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];
export const MOBILE_HEADERS = ['date', 'amount'];

const Category = ({ children, onClick, ...overrides }: CategoryProps & TextProps) => (
    <Box as="button" onClick={onClick} px="4" py="2" borderRadius="full" {...overrides}>
        <Text>{children}</Text>
    </Box>
);

const CircleButton = ({
    isActive,
    onClick,
    children,
    ...overrides
}: { isActive?: boolean; onClick: () => void; children: React.ReactNode } & ButtonProps) => (
    <Button
        isActive={isActive}
        onClick={onClick}
        maxWidth="2em"
        fontSize="12px"
        _active={{ bg: 'brand.400', color: 'white' }}
        borderRadius="5px"
        {...overrides}
    >
        {children}
    </Button>
);

const MAX_PAGE_SELECT = 7;

export const PageSelector = ({
    maxNumberOfPages: pageTotal,
    currentPage,
    goToPage,
    goToFirstPage,
    previousPageButtonDisabled,
    goToPreviousPage,
    goToNextPage,
    goToLastPage,
    nextPageButtonDisabled,
    ...boxProps
}: PageSelectorProps & BoxProps) => {
    const windowI = Math.floor(currentPage / MAX_PAGE_SELECT);
    const windowStart = windowI * MAX_PAGE_SELECT;

    return (
        <>
            {pageTotal > 1 ? (
                <Center {...boxProps}>
                    <HStack spacing={0}>
                        {pageTotal > MAX_PAGE_SELECT && (
                            <IconButton
                                onClick={goToFirstPage}
                                isDisabled={previousPageButtonDisabled}
                                icon={<ArrowLeftIcon boxSize={'10px'} />}
                                borderRadius={'full'}
                                variant="ghost"
                                aria-label="Previous page"
                            />
                        )}
                        <IconButton
                            onClick={goToPreviousPage}
                            isDisabled={previousPageButtonDisabled}
                            icon={<ArrowBackIcon />}
                            borderRadius={'full'}
                            variant="ghost"
                            aria-label="Previous page"
                        />
                        <Box w="2" />

                        <HStack>
                            {pageTotal > MAX_PAGE_SELECT
                                ? [...Array(MAX_PAGE_SELECT)].map((_, i) => {
                                      const pageNumber = (windowI === 0 ? 1 : 0) + windowStart + i;
                                      if (pageNumber > pageTotal) return null;
                                      return (
                                          <React.Fragment key={i}>
                                              <CircleButton isActive={pageNumber === currentPage} onClick={goToPage(pageNumber)}>
                                                  {pageNumber}
                                              </CircleButton>
                                          </React.Fragment>
                                      );
                                  })
                                : [...Array(pageTotal)].map((_, i) => (
                                      <CircleButton isActive={i + 1 === currentPage} onClick={goToPage(i + 1)} key={i}>
                                          {i + 1}
                                      </CircleButton>
                                  ))}
                        </HStack>
                        <Box w="2" />
                        <IconButton
                            onClick={goToNextPage}
                            isDisabled={nextPageButtonDisabled}
                            icon={<ArrowForwardIcon />}
                            borderRadius={'full'}
                            variant="ghost"
                            aria-label="Next page"
                        />
                        {pageTotal > MAX_PAGE_SELECT && (
                            <IconButton
                                onClick={goToLastPage}
                                isDisabled={nextPageButtonDisabled}
                                icon={<ArrowRightIcon boxSize={'10px'} />}
                                borderRadius={'full'}
                                variant="ghost"
                                aria-label="last page"
                            />
                        )}
                    </HStack>
                </Center>
            ) : null}
        </>
    );
};

export const ItemsTable = ({ items, setSelected }: { items: TableItem[]; setSelected?: (selected: string[]) => void }) => {
    const isMobile = useIsMobile();
    const { isInitialized } = useDataReadiness();
    const selectOptions = useSelection(items.map(x => x.id));
    const pageSelectorProps = usePagination(items);
    const visibleItems = pageSelectorProps.shownItems;

    useEffect(() => {
        setSelected && setSelected(selectOptions.selected);
    }, [selectOptions]);

    const displayedListItems = visibleItems.map((item): ListItemProps => {
        const { debtor, creditor, description, tokenInfo, direction, isOverdue, onClick, chainId, tags, displayDate, created, __type } =
            item;

        const paidPaymentDate = toDateDisplay(lastPaymentDate(item));
        const dueDate = <Text textStyle="cell">{toDateDisplay(displayDate)}</Text>;
        const createdDate = <Text textStyle="cell">{toDateDisplay(created)}</Text>;
        const amount = (
            <HStack textStyle="cell">
                <TokenAmount amount={item.displayAmount} tokenInfo={tokenInfo} />
            </HStack>
        );

        return {
            columnValues: isMobile
                ? [dueDate, amount]
                : [
                      <FromAndToWallet chainId={chainId} from={debtor} to={creditor} />,
                      <ChainSymbol chainId={chainId} />,
                      <>
                          {getStatusBadge(item.itemStatus, direction, paidPaymentDate)}
                          {isOverdue && <WarningIconWithTooltip label="Payment Overdue" placement="top" warningOverrides={{ mx: '1' }} />}
                      </>,
                      createdDate,
                      dueDate,
                      <TwoLineTextWithLabel>{description}</TwoLineTextWithLabel>,
                      <OneTagPillOrMore tags={tags} />,
                      amount,
                      <ViewDetailsButton onClick={onClick} />,
                  ],
            selectId: item.id,
        };
    });

    return (
        <>
            <Flex {...shadowProps} direction={'column'} flex="1" p="0">
                {displayedListItems.length > 0 ? (
                    <ListViewCard
                        headers={isMobile ? MOBILE_HEADERS : HEADERS}
                        displayedListItems={displayedListItems}
                        bordered={false}
                        flexHeight={true}
                        selectOptions={setSelected && !isMobile ? selectOptions : undefined}
                        totalItemCount={items.length}
                    />
                ) : (
                    <Center h="60vh">{isInitialized ? <Text textStyle="faint">No items to display.</Text> : <Spinner />}</Center>
                )}
            </Flex>
            <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
        </>
    );
};
