import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

export const CopyIcon = ({ ...props }: BoxProps) => (
    <Box color="#D24747" width={'24px'} height={'21px'} {...props}>
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.5 0H2.5C1.4 0 0.5 0.9 0.5 2V16H2.5V2H14.5V0ZM17.5 4H6.5C5.4 4 4.5 4.9 4.5 6V20C4.5 21.1 5.4 22 6.5 22H17.5C18.6 22 19.5 21.1 19.5 20V6C19.5 4.9 18.6 4 17.5 4ZM6.5 20H17.5V6H6.5V20Z"
                fill="black"
                fill-opacity="0.54"
            />
        </svg>
    </Box>
);
