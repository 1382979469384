import axios from 'axios';
import { parseUnits } from 'ethers/lib/utils';
import { CreateClaimFields } from '../components/modals/create-claim-modal/create-claim-modal';
import { ClaimType } from '../data-lib/data-model';
import { ClaimCreatedEvent } from '../data-lib/domain/bulla-claim-domain';
import { InstantPaymentEvent } from '../data-lib/domain/bulla-instant-pay-domain';
import { getClaimCreatedEvents, getInstantPaymentEvents } from '../data-lib/dto/event-filters';
import { findEventLogs, getUniqueEventId, TransactionResult } from '../data-lib/dto/events-dto';
import { EthAddress } from '../data-lib/ethereum';
import { dateToInt } from '../data-lib/helpers';
import { ChainId } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints, getBase64, toDateDisplay } from '../tools/common';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

const sendOffchainEmail = async (body: string, actingWallet: EthAddress, isGnosisSafe: boolean, connectedNetwork: ChainId) => {
    const gnosisSafeQueryArgs = isGnosisSafe ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    try {
        let res = await axios.post(`${endpoints.emailApi}/sendEmail/${actingWallet}${gnosisSafeQueryArgs}`, body, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return res.status === 202;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const sendEmail = async (body: string, actingWallet: EthAddress, isGnosisSafe: boolean, connectedNetwork: ChainId) => {
    const gnosisSafeQueryArgs = isGnosisSafe ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    try {
        let res = await axios.post(`${endpoints.emailApi}/sendEmail/${actingWallet}${gnosisSafeQueryArgs}`, body, {
            withCredentials: true,
            headers: { 'content-type': 'application/json' },
        });

        return res.status;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const useEmail = () => {
    const {
        connectedNetworkConfig: { name: networkName },
        connectedNetwork,
    } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();

    type OffchainInvoiceEmailPros = {
        emailAddress: string;
        claimType: ClaimType;
        amount: string;
        dueBy: Date;
        tokenSymbol: string;
        payMeLink: string;
        network: number;
        description?: string;
        ccAddresses?: string[];
        file?: File;
    };

    const sendOffchainInvoiceEmail = async ({
        emailAddress,
        claimType,
        amount,
        dueBy,
        tokenSymbol,
        payMeLink,
        description,
        ccAddresses,
        file,
        network,
    }: OffchainInvoiceEmailPros): Promise<boolean> => {
        const webhookPayload = JSON.stringify({
            emailAddress,
            claimType,
            dueBy: dateToInt(dueBy),
            claimAmount: amount,
            currency: tokenSymbol,
            chainId: network,
            description,
            payMeLink,
            ccAddresses,
            file: file ? { name: file.name, base64: await getBase64(file) } : undefined,
        });
        return sendOffchainEmail(webhookPayload, actingWallet, !!connectedSafeAddress, connectedNetwork);
    };

    const sendClaimCreationEmail = ({
        emailAddress,
        claimType,
        amount,
        dueBy,
        tokenSymbol,
        description,
        ccAddresses,
        network,
        recipient,
        id,
        __typename,
    }: {
        id: string;
        emailAddress?: string;
        claimType: ClaimType;
        creditor: EthAddress;
        debtor: EthAddress;
        amount: string;
        dueBy?: Date;
        tokenSymbol: string;
        network: number;
        description: string;
        recipient: string;
        ccAddresses?: string[];
        __typename: (ClaimCreatedEvent | InstantPaymentEvent)['__typename'];
    }) => {
        const idPayload = __typename == 'ClaimCreatedEvent' ? { claimId: id } : { instantPaymentId: id };
        const webhookPayload = JSON.stringify({
            emailAddress,
            claimType,
            dueBy: claimType == 'Invoice' && !!dueBy ? dateToInt(dueBy) : undefined,
            claimAmount: amount,
            currency: tokenSymbol,
            chainId: network,
            description,
            recipient,
            ccAddresses,
            ...idPayload,
        });
        sendEmail(webhookPayload, actingWallet, !!connectedSafeAddress, connectedNetwork);
    };

    return {
        sendClaimCreationEmail,
        sendOffchainInvoiceEmail,
    };
};

export const useSendClaimEmail = () => {
    const { sendClaimCreationEmail } = useEmail();
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    return (
        claimType: ClaimType,
        claims: Omit<CreateClaimFields, 'attachment' | 'tags' | 'instantPayment'>[],
        transactionResult: TransactionResult,
    ) => {
        const claimCreatedEvents = findEventLogs(transactionResult.events, 'ClaimCreatedEvent');
        const instantPaymentEvents = findEventLogs(transactionResult.events, 'InstantPaymentEvent');
        const claimEvents = getClaimCreatedEvents(claimCreatedEvents);
        const paymentEvents = getInstantPaymentEvents(instantPaymentEvents);
        const claimsByCreditorDebtorPair = claims.reduce<Record<string, typeof claims[number]>>((acc, claim) => {
            const [creditor, debtor] = claimType == 'Payment' ? [claim.recipient, queryAddress] : [queryAddress, claim.recipient];
            return {
                ...acc,
                [`${creditor}${debtor}${claim.description}${parseUnits(claim.claimAmount, claim.token.decimals).toString()}`.toLowerCase()]:
                    claim,
            };
        }, {});

        [
            ...claimEvents.map(claimEvent => ({ ...claimEvent, id: claimEvent.tokenId })),
            ...paymentEvents.map(paymentEvent => ({
                ...paymentEvent,
                creditor: paymentEvent.to,
                debtor: paymentEvent.from,
                id: getUniqueEventId({ txHash: paymentEvent.txHash, logIndex: paymentEvent.logIndex }),
                claimAmount: paymentEvent.amount,
            })),
        ].forEach(claimEvent => {
            const key = `${claimEvent.creditor}${claimEvent.debtor}${claimEvent.description}${claimEvent.claimAmount}`.toLowerCase();
            const { emailAddress, token, claimAmount, description, emailCC, recipient } = claimsByCreditorDebtorPair[key];

            if (!!emailAddress || !!emailCC) {
                sendClaimCreationEmail({
                    ...claimEvent,
                    tokenSymbol: token.symbol,
                    amount: claimAmount,
                    claimType,
                    network: connectedNetwork,
                    emailAddress,
                    description,
                    ccAddresses: !!emailCC ? [emailCC] : undefined,
                    recipient,
                });
            }
        });

        return claimEvents;
    };
};
