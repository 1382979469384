import { Box, BoxProps, HStack, Tooltip, TooltipProps } from '@chakra-ui/react';
import React from 'react';
import { Optional } from '../tools/types';

export const WarningIcon = ({ ...props }: BoxProps) => (
    <Box color="#D24747" width={'24px'} height={'21px'} {...props}>
        <svg height={'100%'} width={'100%'} viewBox={`0 0 24 21`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 20.7273L12 0L24 20.7273H0ZM20.2119 18.5454L11.9974 4.35264L3.78281 18.5454H20.2119ZM13.0896 15.2726H10.9078V17.4544H13.0896V15.2726ZM10.9078 8.72725H13.0896V13.0909H10.9078V8.72725Z"
                fill="currentColor"
            />
        </svg>
    </Box>
);

export const WarningIconWithTooltip = ({
    warningOverrides,
    ...tooltipProps
}: Optional<TooltipProps, 'children'> & { warningOverrides?: BoxProps }) => {
    return (
        <Tooltip {...tooltipProps}>
            <HStack>
                <WarningIcon {...warningOverrides} />
            </HStack>
        </Tooltip>
    );
};
