import { BatchCreate } from '@bulla-network/contracts/typechain/BatchCreate';
import { BullaBanker } from '@bulla-network/contracts/typechain/BullaBanker';
import { BullaBankerModule } from '@bulla-network/contracts/typechain/BullaBankerModule';
import { BigNumber, Contract, ContractTransaction } from 'ethers';
import { ClaimInfo, TAG_SEPARATOR } from '../data-model';
import { dateToInt, toBytes32 } from '../helpers';
import { BullaClaimDto } from './bulla-claim-dto';

type CreateBullaClaimParams = {
    contract: BullaBankerModule | BullaBanker;
} & BullaClaimDto;

export const createBullaClaim = async ({
    tags,
    claimAmount,
    creditor,
    token,
    debtor,
    description,
    dueBy,
    ipfsHash,
    tokenURI,
    contract,
}: CreateBullaClaimParams): Promise<ContractTransaction> =>
    await contract.createBullaClaim(
        {
            claimAmount,
            creditor,
            debtor,
            attachment: ipfsHash,
            claimToken: token.address,
            dueBy: dateToInt(dueBy),
            description,
        },
        toBytes32(tags?.join(TAG_SEPARATOR) ?? ''),
        tokenURI,
    );

type CreateClaimsBatchProps = {
    claimParams: BullaClaimDto[];
    contract: BatchCreate | BullaBankerModule;
};

export const createClaimsBatch = async ({ claimParams, contract }: CreateClaimsBatchProps): Promise<ContractTransaction> => {
    const claims = claimParams.map(({ claimAmount, creditor, token, debtor, description, dueBy, ipfsHash, tags, tokenURI }) => ({
        creditor,
        debtor,
        claimAmount,
        attachment: ipfsHash,
        claimToken: token.address,
        dueBy: dateToInt(dueBy),
        description,
        tag: toBytes32(tags?.join(TAG_SEPARATOR) ?? ''),
        tokenURI,
    }));

    return contract.batchCreate(claims);
};

type UpdateBullaTagParams = {
    newTag: string;
    contract: Contract;
    claimInfo: ClaimInfo;
};

export const updateBullaTag = async ({ newTag, contract, claimInfo: { id } }: UpdateBullaTagParams) =>
    await contract.updateBullaTag(BigNumber.from(id), toBytes32(newTag));
