{
    "id": "/txDataSchema",
    "properties": {
        "from": { "$ref": "/addressSchema" },
        "to": { "$ref": "/addressSchema" },
        "value": {
            "oneOf": [{ "$ref": "/numberSchema" }, { "$ref": "/jsNumberSchema" }]
        },
        "gas": {
            "oneOf": [{ "$ref": "/numberSchema" }, { "$ref": "/jsNumberSchema" }]
        },
        "gasPrice": {
            "oneOf": [{ "$ref": "/numberSchema" }, { "$ref": "/jsNumberSchema" }]
        },
        "maxFeePerGas": {
            "oneOf": [{ "$ref": "/numberSchema" }, { "$ref": "/jsNumberSchema" }]
        },
        "maxPriorityFeePerGas": {
            "oneOf": [{ "$ref": "/numberSchema" }, { "$ref": "/jsNumberSchema" }]
        },
        "data": {
            "type": "string",
            "pattern": "^0x[0-9a-f]*$"
        },
        "nonce": {
            "type": "number",
            "minimum": 0
        },
        "accessList": {
            "type": "object",
            "additionalProperties": {
                "type": "array",
                "items": {
                    "type": "string",
                    "pattern": "^0x[0-9a-fA-F]{64}$"
                }
            }
        }
    },
    "required": ["from"],
    "type": "object"
}
