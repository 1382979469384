import React from 'react';
import { getFactoringAddressesByChainId } from '../data-lib/helpers';
import { FactoringConfig, NETWORKS } from '../data-lib/networks';
import { useBullaFactoringPermissions } from './useBullaFactoringPermissions';
import { useActingWalletAddress } from './useWalletAddress';

type Pool = {
    chainId: number;
    address: string;
    factoringConfig: FactoringConfig;
    hasFactoringPermissions: boolean;
    hasDepositPermissions: boolean;
};

type FactoringAndDepositPermissionsContext =
    | 'uninitialized'
    | {
          poolsWithPermissions: Pool[];
          isLoading: boolean;
      };

const FactoringAndDepositPermissionsContext = React.createContext<FactoringAndDepositPermissionsContext>('uninitialized');

export const FactoringAndDepositPermissionsProvider = ({ children }: { children: React.ReactNode }) => {
    const actingWallet = useActingWalletAddress();
    const [poolsWithPermissions, setPoolsWithPermissions] = React.useState<Pool[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [_, { hasFactoringPermissions, hasDepositPermissions }] = useBullaFactoringPermissions();

    React.useEffect(() => {
        const fetchPoolPermissions = async () => {
            setIsLoading(true);
            const factoringAddressesByChainId: Record<number, string[]> = getFactoringAddressesByChainId();
            const accessiblePools: Pool[] = [];

            for (const [chainId, factoringAddresses] of Object.entries(factoringAddressesByChainId)) {
                for (const factoringAddress of factoringAddresses) {
                    const networkConfig = NETWORKS[parseInt(chainId)];

                    if (!networkConfig || !networkConfig.factoringConfig) {
                        throw new Error(`Factoring configuration is missing for chain ID ${chainId}`);
                    }

                    const { bullaFactoringToken, poolUnderlyingToken, depositPermissionsContract, factoringPermissionsContract, poolName } =
                        networkConfig.factoringConfig;

                    if (!bullaFactoringToken || !poolUnderlyingToken || !depositPermissionsContract || !factoringPermissionsContract) {
                        throw new Error(`One or more configuration properties are missing for chain ID ${chainId}`);
                    }

                    const factoringConfig: FactoringConfig = {
                        bullaFactoringToken,
                        poolUnderlyingToken,
                        depositPermissionsContract,
                        factoringPermissionsContract,
                        poolName,
                    };

                    const factoringPermissions = await hasFactoringPermissions(factoringConfig);
                    const depositPermissions = await hasDepositPermissions(factoringConfig);

                    accessiblePools.push({
                        chainId: parseInt(chainId),
                        address: factoringAddress,
                        factoringConfig: networkConfig.factoringConfig,
                        hasFactoringPermissions: factoringPermissions,
                        hasDepositPermissions: depositPermissions,
                    });
                }
            }

            setPoolsWithPermissions(accessiblePools);
            setIsLoading(false);
        };

        fetchPoolPermissions();
    }, [actingWallet]);

    return (
        <FactoringAndDepositPermissionsContext.Provider value={{ poolsWithPermissions, isLoading }}>
            {children}
        </FactoringAndDepositPermissionsContext.Provider>
    );
};

export const useFactoringAndDepositPermissions = () => {
    const context = React.useContext(FactoringAndDepositPermissionsContext);
    return context === 'uninitialized' ? { poolsWithPermissions: [], isLoading: true } : context;
};
