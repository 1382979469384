import { CheckCircleIcon } from '@chakra-ui/icons';
import { Box, Text, HStack, Spacer, CloseButton, useToast, ToastOptions, AlertProps } from '@chakra-ui/react';
import React, { useCallback } from 'react';

type UseBullaToastProps = Partial<ToastOptions> & {
    variant?: AlertProps['variant'];
    title: string;
};

export const BullaAlert = ({ title, status, onClose }: UseBullaToastProps & { onClose: VoidFunction }) => {
    const bgColor = status === 'error' ? 'red.500' : 'brand.bulla_blue';
    return (
        <Box m="6" bg="white" boxShadow={'lg'} borderRadius={'6px'} overflow="clip">
            <HStack spacing="4" borderColor="white" borderTopColor={bgColor} borderTopWidth="4px" p="3">
                <CheckCircleIcon color={bgColor} />
                <Text fontWeight={700} color="gray.700">
                    {title}
                </Text>
                <Spacer />
                <CloseButton size={'sm'} onClick={onClose} />
            </HStack>
        </Box>
    );
};

export const useBullaToast = () => {
    const toast = useToast();

    const trigger = useCallback(
        ({ title, position, duration, status, id, onCloseComplete, variant }: UseBullaToastProps) =>
            toast({
                isClosable: true,
                duration: duration ?? 5000,
                position: position ?? 'bottom-left',
                render: ({ onClose }) => <BullaAlert title={title} onClose={onClose} status={status} />,
                status: status && status !== 'default' ? status : 'success',
                id,
                variant,
                onCloseComplete,
                title,
            }),
        [],
    );

    return trigger;
};
