import { BigNumber } from 'ethers';
import React, { useEffect } from 'react';
import { Direction } from '../components/display/claim-table';
import { AccountTagInfo, BullaItemInfo, InstantPaymentInfo } from '../data-lib/data-model';
import { NonFungibleTransferType, TransferDTO } from '../data-lib/dto/external-transactions-dto';
import { addressEquality } from '../data-lib/ethereum';
import { addPayments, getPayables, getReceivables } from '../data-lib/helpers';
import { AppContext, UserDataReadyState, UserDataState } from '../state/app-state';
import { Replace } from '../tools/types';
import { emptyUserData, UserData } from '../tools/userData';
import { OffchainInvoiceDto } from './useExternalTransactionsApi';
import { GetHistoricalTokenPrice, useHistoricalPrices } from './useHistoricalPrices';
import { OffchainInvoiceFactory, OffchainInvoiceInfo, useOffchainInvoiceFactory } from './useOffchainInvoiceFactory';
import { useOffchainInvoices } from './useOffchainInvoices';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

type UserDataContext = {
    globalUserDataWithFinanceAcceptedOriginatingClaims: ExtendedUserData<GlobalUserData>;
    globalUserDataWithoutFinanceAcceptedOriginatingClaims: ExtendedUserData<GlobalUserData>;
    currentChainUserData: ExtendedUserData<UserData>;
};

const extendEmptyData = <T extends UserData | GlobalUserData>(emptyData: T): ExtendedUserData<T> => ({
    ...emptyData,
    payables: [],
    receivables: [],
    bullaItems: [],
    accountTags: [],
    paidBullaItemsWithPayments: [],
    receivedBullaItemsWithPayments: [],
    nftTransfers: [],
    offchainInvoices: [],
});

const emptyGlobalUserData: GlobalUserData = emptyUserData;
const emptyExtendedGlobalUserData = extendEmptyData(emptyGlobalUserData);
const extendEmptyUserData = extendEmptyData(emptyUserData);

const UserDataContext = React.createContext<UserDataContext>({
    globalUserDataWithFinanceAcceptedOriginatingClaims: emptyExtendedGlobalUserData,
    globalUserDataWithoutFinanceAcceptedOriginatingClaims: emptyExtendedGlobalUserData,
    currentChainUserData: extendEmptyUserData,
});

type GlobalUserData = Omit<
    UserData,
    | 'refetch'
    | 'bullaItemEvents'
    | 'financeAcceptedClaimOriginatingClaimIds'
    | 'bullaTxHashBitmap'
    | 'bullaItemMetadataById'
    | 'fetched'
    | 'importFetched'
    | 'refetchExternalTransactions'
>;

export type BullaItemInfoWithPayment = BullaItemInfo & {
    payment: BigNumber;
    paymentTimestamp: Date;
    USDMark: 'not-found' | 'fetching' | number;
};

export type NftTransfer = Omit<TransferDTO, 'transferType'> &
    Omit<NonFungibleTransferType, 'kind'> & { timestamp: Date; direction: Direction };

type ExtendedUserData<T extends UserData | GlobalUserData> = T & {
    payables: BullaItemInfo[];
    receivables: BullaItemInfo[];
    bullaItems: BullaItemInfo[];
    accountTags: AccountTagInfo[];
    paidBullaItemsWithPayments: BullaItemInfoWithPayment[];
    receivedBullaItemsWithPayments: BullaItemInfoWithPayment[];
    nftTransfers: NftTransfer[];
    offchainInvoices: OffchainInvoiceInfo[];
};

export const useUserData = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) throw new Error('useUserData must me used within the AppState provider');
    return context.userDataByChain;
};

const getExtendedUserData = <T extends UserData | GlobalUserData>(
    userData: T,
    queryAddress: string,
    getHistoricalTokenPrice: GetHistoricalTokenPrice,
    offchainInvoices: OffchainInvoiceDto[],
    createOffchainInvoice: OffchainInvoiceFactory,
): ExtendedUserData<T> => {
    const {
        userClaims,
        instantPayments: allInstantPayments,
        importedExternalTxs,
        nonImportedExternalTxs,
        poolDeposits,
        poolRedemptions,
    } = userData;
    const offchainInvoicesById: Record<string, OffchainInvoiceDto | undefined> = Object.fromEntries(offchainInvoices.map(x => [x.id, x]));

    const { normalInstantPayments: instantPayments, paidOffchainInvoices } = allInstantPayments.reduce<{
        normalInstantPayments: InstantPaymentInfo[];
        paidOffchainInvoices: OffchainInvoiceInfo[];
    }>(
        (acc, item) => {
            const offchainInvoiceMaybe = offchainInvoicesById[item.description];
            const isOffchainInvoicePayment = !!offchainInvoiceMaybe && !acc.paidOffchainInvoices.map(x => x.id).includes(item.description);
            if (isOffchainInvoicePayment) {
                const offchainInvoiceInfo = createOffchainInvoice(offchainInvoiceMaybe, item);
                if (offchainInvoiceInfo) return { ...acc, paidOffchainInvoices: [...acc.paidOffchainInvoices, offchainInvoiceInfo] };
            }
            return { ...acc, normalInstantPayments: [...acc.normalInstantPayments, item] };
        },
        { normalInstantPayments: [], paidOffchainInvoices: [] },
    );

    const paidOffchainInvoiceIds = new Set(paidOffchainInvoices.map(x => x.id));
    const allOffchainInvoices = [
        ...paidOffchainInvoices,
        ...offchainInvoices
            .filter(x => !paidOffchainInvoiceIds.has(x.id))
            .map(x => createOffchainInvoice(x))
            .filter((x): x is OffchainInvoiceInfo => x !== undefined),
    ].sort((a, b) => b.dueBy.getTime() - a.dueBy.getTime());

    const bullaItems = [...userClaims, ...instantPayments, ...importedExternalTxs, ...allOffchainInvoices].sort(
        (a, b) => b.created.getTime() - a.created.getTime(),
    );

    const allUserClaims = [...userClaims, ...allOffchainInvoices].sort((a, b) => b.dueBy.getTime() - a.dueBy.getTime());
    const payables = getPayables(queryAddress, allUserClaims);
    const receivables = getReceivables(queryAddress, allUserClaims);
    const accountTags = getAccountTags(bullaItems);

    const receivedItems = getReceivables(queryAddress, bullaItems);
    const paidItems = getPayables(queryAddress, bullaItems);

    const receivedBullaItemsWithPayments = addPayments(
        getHistoricalTokenPrice,
        receivedItems,
        paidItems,
        queryAddress,
        'In',
        poolRedemptions,
    );
    const paidBullaItemsWithPayments = addPayments(getHistoricalTokenPrice, receivedItems, paidItems, queryAddress, 'Out', poolDeposits);

    const nftTransfers = nonImportedExternalTxs
        .flatMap(x => x.allTransfers.map(y => ({ ...y, timestamp: x.timestamp })))
        .filter(
            (
                x,
            ): x is Replace<T['nonImportedExternalTxs'][number]['allTransfers'][number], 'transferType', NonFungibleTransferType> & {
                timestamp: Date;
            } => x.transferType.kind == 'non-fungible',
        )
        .map(transfer => ({
            ...transfer,
            ...transfer.transferType,
            direction: (addressEquality(transfer.to, queryAddress) ? 'In' : 'Out') as Direction,
        }));

    return {
        ...userData,
        payables,
        receivables,
        bullaItems,
        accountTags,
        paidBullaItemsWithPayments,
        receivedBullaItemsWithPayments,
        nftTransfers,
        offchainInvoices: allOffchainInvoices,
    };
};

const combineUserData = (left: GlobalUserData, right: GlobalUserData): GlobalUserData => ({
    userClaims: [...left.userClaims, ...right.userClaims].sort((a, b) => b.created.getTime() - a.created.getTime()),
    instantPayments: [...left.instantPayments, ...right.instantPayments].sort((a, b) => b.created.getTime() - a.created.getTime()),
    poolDeposits: [...left.poolDeposits, ...right.poolDeposits].sort((a, b) => b.created.getTime() - a.created.getTime()),
    poolRedemptions: [...left.poolRedemptions, ...right.poolRedemptions].sort((a, b) => b.created.getTime() - a.created.getTime()),
    userBatchesByTxHash: { ...left.userBatchesByTxHash, ...right.userBatchesByTxHash },
    frendLends: [...left.frendLends, ...right.frendLends].sort((a, b) => b.offerDate.getTime() - a.offerDate.getTime()),
    importedExternalTxs: [...left.importedExternalTxs, ...right.importedExternalTxs].sort(
        (a, b) => b.created.getTime() - a.created.getTime(),
    ),
    nonImportedExternalTxs: [...left.nonImportedExternalTxs, ...right.nonImportedExternalTxs].sort(
        (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
    ),
});

export const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
    const userDataByChain = useUserData();
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    const offchainInvoices = useOffchainInvoices();
    const { loadTokenPrices, getHistoricalTokenPrice, USDMark } = useHistoricalPrices();
    const { createOffchainInvoiceInfo } = useOffchainInvoiceFactory();

    const [_globalDataWithFinanceAcceptedOriginatingClaims, _globalDataWithoutFinanceAcceptedOriginatingClaims] = React.useMemo(
        () =>
            userDataByChain === 'uninitialized'
                ? [emptyGlobalUserData, emptyGlobalUserData]
                : [aggregateGlobalUserData(userDataByChain, true), aggregateGlobalUserData(userDataByChain, false)],
        [JSON.stringify(userDataByChain), queryAddress],
    );

    const globalUserDataWithFinanceAcceptedOriginatingClaims = React.useMemo(
        () =>
            getExtendedUserData(
                _globalDataWithFinanceAcceptedOriginatingClaims,
                queryAddress,
                getHistoricalTokenPrice,
                offchainInvoices,
                createOffchainInvoiceInfo,
            ),
        [
            JSON.stringify(_globalDataWithFinanceAcceptedOriginatingClaims),
            queryAddress,
            USDMark,
            offchainInvoices,
            createOffchainInvoiceInfo,
        ],
    );

    useEffect(() => {
        const priceQueries = [
            ...globalUserDataWithFinanceAcceptedOriginatingClaims.receivedBullaItemsWithPayments,
            ...globalUserDataWithFinanceAcceptedOriginatingClaims.paidBullaItemsWithPayments,
        ].map(
            ({
                chainId,
                tokenInfo: {
                    token: { address },
                },
                paymentTimestamp,
            }) => ({ chainId, tokenAddress: address, timestamp: paymentTimestamp }),
        );

        loadTokenPrices(priceQueries);
    }, [
        globalUserDataWithFinanceAcceptedOriginatingClaims.receivedBullaItemsWithPayments.length,
        globalUserDataWithFinanceAcceptedOriginatingClaims.paidBullaItemsWithPayments.length,
    ]);

    const globalUserDataWithoutFinanceAcceptedOriginatingClaims = React.useMemo(
        () =>
            getExtendedUserData(
                _globalDataWithoutFinanceAcceptedOriginatingClaims,
                queryAddress,
                getHistoricalTokenPrice,
                offchainInvoices,
                createOffchainInvoiceInfo,
            ),
        [
            JSON.stringify(_globalDataWithoutFinanceAcceptedOriginatingClaims),
            queryAddress,
            USDMark,
            offchainInvoices,
            createOffchainInvoiceInfo,
        ],
    );

    const currentChainUserData = React.useMemo(() => {
        if (userDataByChain === 'uninitialized') return extendEmptyUserData;

        const userDataState = userDataByChain[connectedNetwork];

        if (userDataState.kind !== 'fetched') return extendEmptyUserData;

        return getExtendedUserData(
            applyBullaItemMetadata(userDataState.userData),
            queryAddress,
            getHistoricalTokenPrice,
            offchainInvoices,
            createOffchainInvoiceInfo,
        );
    }, [
        userDataByChain === 'uninitialized' ? 'uninit' : JSON.stringify(userDataByChain[connectedNetwork]),
        connectedNetwork,
        queryAddress,
        USDMark,
        offchainInvoices,
        createOffchainInvoiceInfo,
    ]);

    return (
        <UserDataContext.Provider
            value={{
                globalUserDataWithFinanceAcceptedOriginatingClaims,
                globalUserDataWithoutFinanceAcceptedOriginatingClaims,
                currentChainUserData,
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
};

const applyBullaItemMetadata = (userData: UserData): UserData => ({
    ...userData,
    userClaims: userData.userClaims.map(x => ({ ...x, notes: userData.bullaItemMetadataById[x.id]?.notes ?? '' })),
    instantPayments: userData.instantPayments.map(x => ({ ...x, notes: userData.bullaItemMetadataById[x.id]?.notes ?? '' })),
});

const getAccountTags = (items: BullaItemInfo[]) => {
    const accountTagsWithoutExpense = Object.values(
        items.reduce<{ [tag: string]: AccountTagInfo }>(
            (tags, item) =>
                item.tags
                    .filter(x => x !== '')
                    .reduce<Record<string, AccountTagInfo>>(
                        (acc, tag) => ({
                            ...acc,
                            [tag]: {
                                name: tag,
                                items: [...(tags[tag]?.items ?? []), item],
                            },
                        }),
                        tags,
                    ),
            {},
        ),
    );

    const accountTagsWithExpense = accountTagsWithoutExpense.map(x => x.name.trim().toLowerCase()).includes('expense')
        ? accountTagsWithoutExpense
        : [...accountTagsWithoutExpense, { name: 'Expense', items: [] }];

    return accountTagsWithExpense.sort((a, b) => a.name.localeCompare(b.name));
};

export const useDataReadiness = () => {
    const userDataByChainId = useUserData();
    const isInitialized = userDataByChainId !== 'uninitialized';
    return {
        isInitialized,
        isChainInitialized: (chainId: number) => isInitialized && userDataByChainId[chainId].kind !== 'loading',
    };
};

const aggregateGlobalUserData = (
    userDataByChainId: Record<number, UserDataState>,
    includeFinanceAcceptedOriginatingClaim: boolean,
): GlobalUserData =>
    Object.values(userDataByChainId)
        .filter((state): state is UserDataReadyState => state.kind == 'fetched')
        .reduce<GlobalUserData>((acc, _userData) => {
            const userData = applyBullaItemMetadata(_userData.userData);
            const claimIdsToIgnore = new Set(userData.financeAcceptedClaimOriginatingClaimIds);
            return combineUserData(acc, {
                ...userData,
                userClaims: userData.userClaims.filter(x => includeFinanceAcceptedOriginatingClaim || !claimIdsToIgnore.has(x.id)),
            });
        }, emptyGlobalUserData);

export const useGlobalUserData = (
    includeFinanceAcceptedOriginatingClaim: 'include-originating-claims' | 'exclude-originating-claims',
): ExtendedUserData<GlobalUserData> => {
    const context = React.useContext(UserDataContext);
    if (context === undefined) throw new Error('useGlobalData must me used within the UserData provider');

    return includeFinanceAcceptedOriginatingClaim == 'include-originating-claims'
        ? context.globalUserDataWithFinanceAcceptedOriginatingClaims
        : context.globalUserDataWithoutFinanceAcceptedOriginatingClaims;
};

export const useCurrentChainUserData = (): ExtendedUserData<UserData> => {
    const context = React.useContext(UserDataContext);
    if (context === undefined) throw new Error('useGlobalData must me used within the UserData provider');

    return context.currentChainUserData;
};
