[
  "的",
  "一",
  "是",
  "在",
  "不",
  "了",
  "有",
  "和",
  "人",
  "這",
  "中",
  "大",
  "為",
  "上",
  "個",
  "國",
  "我",
  "以",
  "要",
  "他",
  "時",
  "來",
  "用",
  "們",
  "生",
  "到",
  "作",
  "地",
  "於",
  "出",
  "就",
  "分",
  "對",
  "成",
  "會",
  "可",
  "主",
  "發",
  "年",
  "動",
  "同",
  "工",
  "也",
  "能",
  "下",
  "過",
  "子",
  "說",
  "產",
  "種",
  "面",
  "而",
  "方",
  "後",
  "多",
  "定",
  "行",
  "學",
  "法",
  "所",
  "民",
  "得",
  "經",
  "十",
  "三",
  "之",
  "進",
  "著",
  "等",
  "部",
  "度",
  "家",
  "電",
  "力",
  "裡",
  "如",
  "水",
  "化",
  "高",
  "自",
  "二",
  "理",
  "起",
  "小",
  "物",
  "現",
  "實",
  "加",
  "量",
  "都",
  "兩",
  "體",
  "制",
  "機",
  "當",
  "使",
  "點",
  "從",
  "業",
  "本",
  "去",
  "把",
  "性",
  "好",
  "應",
  "開",
  "它",
  "合",
  "還",
  "因",
  "由",
  "其",
  "些",
  "然",
  "前",
  "外",
  "天",
  "政",
  "四",
  "日",
  "那",
  "社",
  "義",
  "事",
  "平",
  "形",
  "相",
  "全",
  "表",
  "間",
  "樣",
  "與",
  "關",
  "各",
  "重",
  "新",
  "線",
  "內",
  "數",
  "正",
  "心",
  "反",
  "你",
  "明",
  "看",
  "原",
  "又",
  "麼",
  "利",
  "比",
  "或",
  "但",
  "質",
  "氣",
  "第",
  "向",
  "道",
  "命",
  "此",
  "變",
  "條",
  "只",
  "沒",
  "結",
  "解",
  "問",
  "意",
  "建",
  "月",
  "公",
  "無",
  "系",
  "軍",
  "很",
  "情",
  "者",
  "最",
  "立",
  "代",
  "想",
  "已",
  "通",
  "並",
  "提",
  "直",
  "題",
  "黨",
  "程",
  "展",
  "五",
  "果",
  "料",
  "象",
  "員",
  "革",
  "位",
  "入",
  "常",
  "文",
  "總",
  "次",
  "品",
  "式",
  "活",
  "設",
  "及",
  "管",
  "特",
  "件",
  "長",
  "求",
  "老",
  "頭",
  "基",
  "資",
  "邊",
  "流",
  "路",
  "級",
  "少",
  "圖",
  "山",
  "統",
  "接",
  "知",
  "較",
  "將",
  "組",
  "見",
  "計",
  "別",
  "她",
  "手",
  "角",
  "期",
  "根",
  "論",
  "運",
  "農",
  "指",
  "幾",
  "九",
  "區",
  "強",
  "放",
  "決",
  "西",
  "被",
  "幹",
  "做",
  "必",
  "戰",
  "先",
  "回",
  "則",
  "任",
  "取",
  "據",
  "處",
  "隊",
  "南",
  "給",
  "色",
  "光",
  "門",
  "即",
  "保",
  "治",
  "北",
  "造",
  "百",
  "規",
  "熱",
  "領",
  "七",
  "海",
  "口",
  "東",
  "導",
  "器",
  "壓",
  "志",
  "世",
  "金",
  "增",
  "爭",
  "濟",
  "階",
  "油",
  "思",
  "術",
  "極",
  "交",
  "受",
  "聯",
  "什",
  "認",
  "六",
  "共",
  "權",
  "收",
  "證",
  "改",
  "清",
  "美",
  "再",
  "採",
  "轉",
  "更",
  "單",
  "風",
  "切",
  "打",
  "白",
  "教",
  "速",
  "花",
  "帶",
  "安",
  "場",
  "身",
  "車",
  "例",
  "真",
  "務",
  "具",
  "萬",
  "每",
  "目",
  "至",
  "達",
  "走",
  "積",
  "示",
  "議",
  "聲",
  "報",
  "鬥",
  "完",
  "類",
  "八",
  "離",
  "華",
  "名",
  "確",
  "才",
  "科",
  "張",
  "信",
  "馬",
  "節",
  "話",
  "米",
  "整",
  "空",
  "元",
  "況",
  "今",
  "集",
  "溫",
  "傳",
  "土",
  "許",
  "步",
  "群",
  "廣",
  "石",
  "記",
  "需",
  "段",
  "研",
  "界",
  "拉",
  "林",
  "律",
  "叫",
  "且",
  "究",
  "觀",
  "越",
  "織",
  "裝",
  "影",
  "算",
  "低",
  "持",
  "音",
  "眾",
  "書",
  "布",
  "复",
  "容",
  "兒",
  "須",
  "際",
  "商",
  "非",
  "驗",
  "連",
  "斷",
  "深",
  "難",
  "近",
  "礦",
  "千",
  "週",
  "委",
  "素",
  "技",
  "備",
  "半",
  "辦",
  "青",
  "省",
  "列",
  "習",
  "響",
  "約",
  "支",
  "般",
  "史",
  "感",
  "勞",
  "便",
  "團",
  "往",
  "酸",
  "歷",
  "市",
  "克",
  "何",
  "除",
  "消",
  "構",
  "府",
  "稱",
  "太",
  "準",
  "精",
  "值",
  "號",
  "率",
  "族",
  "維",
  "劃",
  "選",
  "標",
  "寫",
  "存",
  "候",
  "毛",
  "親",
  "快",
  "效",
  "斯",
  "院",
  "查",
  "江",
  "型",
  "眼",
  "王",
  "按",
  "格",
  "養",
  "易",
  "置",
  "派",
  "層",
  "片",
  "始",
  "卻",
  "專",
  "狀",
  "育",
  "廠",
  "京",
  "識",
  "適",
  "屬",
  "圓",
  "包",
  "火",
  "住",
  "調",
  "滿",
  "縣",
  "局",
  "照",
  "參",
  "紅",
  "細",
  "引",
  "聽",
  "該",
  "鐵",
  "價",
  "嚴",
  "首",
  "底",
  "液",
  "官",
  "德",
  "隨",
  "病",
  "蘇",
  "失",
  "爾",
  "死",
  "講",
  "配",
  "女",
  "黃",
  "推",
  "顯",
  "談",
  "罪",
  "神",
  "藝",
  "呢",
  "席",
  "含",
  "企",
  "望",
  "密",
  "批",
  "營",
  "項",
  "防",
  "舉",
  "球",
  "英",
  "氧",
  "勢",
  "告",
  "李",
  "台",
  "落",
  "木",
  "幫",
  "輪",
  "破",
  "亞",
  "師",
  "圍",
  "注",
  "遠",
  "字",
  "材",
  "排",
  "供",
  "河",
  "態",
  "封",
  "另",
  "施",
  "減",
  "樹",
  "溶",
  "怎",
  "止",
  "案",
  "言",
  "士",
  "均",
  "武",
  "固",
  "葉",
  "魚",
  "波",
  "視",
  "僅",
  "費",
  "緊",
  "愛",
  "左",
  "章",
  "早",
  "朝",
  "害",
  "續",
  "輕",
  "服",
  "試",
  "食",
  "充",
  "兵",
  "源",
  "判",
  "護",
  "司",
  "足",
  "某",
  "練",
  "差",
  "致",
  "板",
  "田",
  "降",
  "黑",
  "犯",
  "負",
  "擊",
  "范",
  "繼",
  "興",
  "似",
  "餘",
  "堅",
  "曲",
  "輸",
  "修",
  "故",
  "城",
  "夫",
  "夠",
  "送",
  "筆",
  "船",
  "佔",
  "右",
  "財",
  "吃",
  "富",
  "春",
  "職",
  "覺",
  "漢",
  "畫",
  "功",
  "巴",
  "跟",
  "雖",
  "雜",
  "飛",
  "檢",
  "吸",
  "助",
  "昇",
  "陽",
  "互",
  "初",
  "創",
  "抗",
  "考",
  "投",
  "壞",
  "策",
  "古",
  "徑",
  "換",
  "未",
  "跑",
  "留",
  "鋼",
  "曾",
  "端",
  "責",
  "站",
  "簡",
  "述",
  "錢",
  "副",
  "盡",
  "帝",
  "射",
  "草",
  "衝",
  "承",
  "獨",
  "令",
  "限",
  "阿",
  "宣",
  "環",
  "雙",
  "請",
  "超",
  "微",
  "讓",
  "控",
  "州",
  "良",
  "軸",
  "找",
  "否",
  "紀",
  "益",
  "依",
  "優",
  "頂",
  "礎",
  "載",
  "倒",
  "房",
  "突",
  "坐",
  "粉",
  "敵",
  "略",
  "客",
  "袁",
  "冷",
  "勝",
  "絕",
  "析",
  "塊",
  "劑",
  "測",
  "絲",
  "協",
  "訴",
  "念",
  "陳",
  "仍",
  "羅",
  "鹽",
  "友",
  "洋",
  "錯",
  "苦",
  "夜",
  "刑",
  "移",
  "頻",
  "逐",
  "靠",
  "混",
  "母",
  "短",
  "皮",
  "終",
  "聚",
  "汽",
  "村",
  "雲",
  "哪",
  "既",
  "距",
  "衛",
  "停",
  "烈",
  "央",
  "察",
  "燒",
  "迅",
  "境",
  "若",
  "印",
  "洲",
  "刻",
  "括",
  "激",
  "孔",
  "搞",
  "甚",
  "室",
  "待",
  "核",
  "校",
  "散",
  "侵",
  "吧",
  "甲",
  "遊",
  "久",
  "菜",
  "味",
  "舊",
  "模",
  "湖",
  "貨",
  "損",
  "預",
  "阻",
  "毫",
  "普",
  "穩",
  "乙",
  "媽",
  "植",
  "息",
  "擴",
  "銀",
  "語",
  "揮",
  "酒",
  "守",
  "拿",
  "序",
  "紙",
  "醫",
  "缺",
  "雨",
  "嗎",
  "針",
  "劉",
  "啊",
  "急",
  "唱",
  "誤",
  "訓",
  "願",
  "審",
  "附",
  "獲",
  "茶",
  "鮮",
  "糧",
  "斤",
  "孩",
  "脫",
  "硫",
  "肥",
  "善",
  "龍",
  "演",
  "父",
  "漸",
  "血",
  "歡",
  "械",
  "掌",
  "歌",
  "沙",
  "剛",
  "攻",
  "謂",
  "盾",
  "討",
  "晚",
  "粒",
  "亂",
  "燃",
  "矛",
  "乎",
  "殺",
  "藥",
  "寧",
  "魯",
  "貴",
  "鐘",
  "煤",
  "讀",
  "班",
  "伯",
  "香",
  "介",
  "迫",
  "句",
  "豐",
  "培",
  "握",
  "蘭",
  "擔",
  "弦",
  "蛋",
  "沉",
  "假",
  "穿",
  "執",
  "答",
  "樂",
  "誰",
  "順",
  "煙",
  "縮",
  "徵",
  "臉",
  "喜",
  "松",
  "腳",
  "困",
  "異",
  "免",
  "背",
  "星",
  "福",
  "買",
  "染",
  "井",
  "概",
  "慢",
  "怕",
  "磁",
  "倍",
  "祖",
  "皇",
  "促",
  "靜",
  "補",
  "評",
  "翻",
  "肉",
  "踐",
  "尼",
  "衣",
  "寬",
  "揚",
  "棉",
  "希",
  "傷",
  "操",
  "垂",
  "秋",
  "宜",
  "氫",
  "套",
  "督",
  "振",
  "架",
  "亮",
  "末",
  "憲",
  "慶",
  "編",
  "牛",
  "觸",
  "映",
  "雷",
  "銷",
  "詩",
  "座",
  "居",
  "抓",
  "裂",
  "胞",
  "呼",
  "娘",
  "景",
  "威",
  "綠",
  "晶",
  "厚",
  "盟",
  "衡",
  "雞",
  "孫",
  "延",
  "危",
  "膠",
  "屋",
  "鄉",
  "臨",
  "陸",
  "顧",
  "掉",
  "呀",
  "燈",
  "歲",
  "措",
  "束",
  "耐",
  "劇",
  "玉",
  "趙",
  "跳",
  "哥",
  "季",
  "課",
  "凱",
  "胡",
  "額",
  "款",
  "紹",
  "卷",
  "齊",
  "偉",
  "蒸",
  "殖",
  "永",
  "宗",
  "苗",
  "川",
  "爐",
  "岩",
  "弱",
  "零",
  "楊",
  "奏",
  "沿",
  "露",
  "桿",
  "探",
  "滑",
  "鎮",
  "飯",
  "濃",
  "航",
  "懷",
  "趕",
  "庫",
  "奪",
  "伊",
  "靈",
  "稅",
  "途",
  "滅",
  "賽",
  "歸",
  "召",
  "鼓",
  "播",
  "盤",
  "裁",
  "險",
  "康",
  "唯",
  "錄",
  "菌",
  "純",
  "借",
  "糖",
  "蓋",
  "橫",
  "符",
  "私",
  "努",
  "堂",
  "域",
  "槍",
  "潤",
  "幅",
  "哈",
  "竟",
  "熟",
  "蟲",
  "澤",
  "腦",
  "壤",
  "碳",
  "歐",
  "遍",
  "側",
  "寨",
  "敢",
  "徹",
  "慮",
  "斜",
  "薄",
  "庭",
  "納",
  "彈",
  "飼",
  "伸",
  "折",
  "麥",
  "濕",
  "暗",
  "荷",
  "瓦",
  "塞",
  "床",
  "築",
  "惡",
  "戶",
  "訪",
  "塔",
  "奇",
  "透",
  "梁",
  "刀",
  "旋",
  "跡",
  "卡",
  "氯",
  "遇",
  "份",
  "毒",
  "泥",
  "退",
  "洗",
  "擺",
  "灰",
  "彩",
  "賣",
  "耗",
  "夏",
  "擇",
  "忙",
  "銅",
  "獻",
  "硬",
  "予",
  "繁",
  "圈",
  "雪",
  "函",
  "亦",
  "抽",
  "篇",
  "陣",
  "陰",
  "丁",
  "尺",
  "追",
  "堆",
  "雄",
  "迎",
  "泛",
  "爸",
  "樓",
  "避",
  "謀",
  "噸",
  "野",
  "豬",
  "旗",
  "累",
  "偏",
  "典",
  "館",
  "索",
  "秦",
  "脂",
  "潮",
  "爺",
  "豆",
  "忽",
  "托",
  "驚",
  "塑",
  "遺",
  "愈",
  "朱",
  "替",
  "纖",
  "粗",
  "傾",
  "尚",
  "痛",
  "楚",
  "謝",
  "奮",
  "購",
  "磨",
  "君",
  "池",
  "旁",
  "碎",
  "骨",
  "監",
  "捕",
  "弟",
  "暴",
  "割",
  "貫",
  "殊",
  "釋",
  "詞",
  "亡",
  "壁",
  "頓",
  "寶",
  "午",
  "塵",
  "聞",
  "揭",
  "炮",
  "殘",
  "冬",
  "橋",
  "婦",
  "警",
  "綜",
  "招",
  "吳",
  "付",
  "浮",
  "遭",
  "徐",
  "您",
  "搖",
  "谷",
  "贊",
  "箱",
  "隔",
  "訂",
  "男",
  "吹",
  "園",
  "紛",
  "唐",
  "敗",
  "宋",
  "玻",
  "巨",
  "耕",
  "坦",
  "榮",
  "閉",
  "灣",
  "鍵",
  "凡",
  "駐",
  "鍋",
  "救",
  "恩",
  "剝",
  "凝",
  "鹼",
  "齒",
  "截",
  "煉",
  "麻",
  "紡",
  "禁",
  "廢",
  "盛",
  "版",
  "緩",
  "淨",
  "睛",
  "昌",
  "婚",
  "涉",
  "筒",
  "嘴",
  "插",
  "岸",
  "朗",
  "莊",
  "街",
  "藏",
  "姑",
  "貿",
  "腐",
  "奴",
  "啦",
  "慣",
  "乘",
  "夥",
  "恢",
  "勻",
  "紗",
  "扎",
  "辯",
  "耳",
  "彪",
  "臣",
  "億",
  "璃",
  "抵",
  "脈",
  "秀",
  "薩",
  "俄",
  "網",
  "舞",
  "店",
  "噴",
  "縱",
  "寸",
  "汗",
  "掛",
  "洪",
  "賀",
  "閃",
  "柬",
  "爆",
  "烯",
  "津",
  "稻",
  "牆",
  "軟",
  "勇",
  "像",
  "滾",
  "厘",
  "蒙",
  "芳",
  "肯",
  "坡",
  "柱",
  "盪",
  "腿",
  "儀",
  "旅",
  "尾",
  "軋",
  "冰",
  "貢",
  "登",
  "黎",
  "削",
  "鑽",
  "勒",
  "逃",
  "障",
  "氨",
  "郭",
  "峰",
  "幣",
  "港",
  "伏",
  "軌",
  "畝",
  "畢",
  "擦",
  "莫",
  "刺",
  "浪",
  "秘",
  "援",
  "株",
  "健",
  "售",
  "股",
  "島",
  "甘",
  "泡",
  "睡",
  "童",
  "鑄",
  "湯",
  "閥",
  "休",
  "匯",
  "舍",
  "牧",
  "繞",
  "炸",
  "哲",
  "磷",
  "績",
  "朋",
  "淡",
  "尖",
  "啟",
  "陷",
  "柴",
  "呈",
  "徒",
  "顏",
  "淚",
  "稍",
  "忘",
  "泵",
  "藍",
  "拖",
  "洞",
  "授",
  "鏡",
  "辛",
  "壯",
  "鋒",
  "貧",
  "虛",
  "彎",
  "摩",
  "泰",
  "幼",
  "廷",
  "尊",
  "窗",
  "綱",
  "弄",
  "隸",
  "疑",
  "氏",
  "宮",
  "姐",
  "震",
  "瑞",
  "怪",
  "尤",
  "琴",
  "循",
  "描",
  "膜",
  "違",
  "夾",
  "腰",
  "緣",
  "珠",
  "窮",
  "森",
  "枝",
  "竹",
  "溝",
  "催",
  "繩",
  "憶",
  "邦",
  "剩",
  "幸",
  "漿",
  "欄",
  "擁",
  "牙",
  "貯",
  "禮",
  "濾",
  "鈉",
  "紋",
  "罷",
  "拍",
  "咱",
  "喊",
  "袖",
  "埃",
  "勤",
  "罰",
  "焦",
  "潛",
  "伍",
  "墨",
  "欲",
  "縫",
  "姓",
  "刊",
  "飽",
  "仿",
  "獎",
  "鋁",
  "鬼",
  "麗",
  "跨",
  "默",
  "挖",
  "鏈",
  "掃",
  "喝",
  "袋",
  "炭",
  "污",
  "幕",
  "諸",
  "弧",
  "勵",
  "梅",
  "奶",
  "潔",
  "災",
  "舟",
  "鑑",
  "苯",
  "訟",
  "抱",
  "毀",
  "懂",
  "寒",
  "智",
  "埔",
  "寄",
  "屆",
  "躍",
  "渡",
  "挑",
  "丹",
  "艱",
  "貝",
  "碰",
  "拔",
  "爹",
  "戴",
  "碼",
  "夢",
  "芽",
  "熔",
  "赤",
  "漁",
  "哭",
  "敬",
  "顆",
  "奔",
  "鉛",
  "仲",
  "虎",
  "稀",
  "妹",
  "乏",
  "珍",
  "申",
  "桌",
  "遵",
  "允",
  "隆",
  "螺",
  "倉",
  "魏",
  "銳",
  "曉",
  "氮",
  "兼",
  "隱",
  "礙",
  "赫",
  "撥",
  "忠",
  "肅",
  "缸",
  "牽",
  "搶",
  "博",
  "巧",
  "殼",
  "兄",
  "杜",
  "訊",
  "誠",
  "碧",
  "祥",
  "柯",
  "頁",
  "巡",
  "矩",
  "悲",
  "灌",
  "齡",
  "倫",
  "票",
  "尋",
  "桂",
  "鋪",
  "聖",
  "恐",
  "恰",
  "鄭",
  "趣",
  "抬",
  "荒",
  "騰",
  "貼",
  "柔",
  "滴",
  "猛",
  "闊",
  "輛",
  "妻",
  "填",
  "撤",
  "儲",
  "簽",
  "鬧",
  "擾",
  "紫",
  "砂",
  "遞",
  "戲",
  "吊",
  "陶",
  "伐",
  "餵",
  "療",
  "瓶",
  "婆",
  "撫",
  "臂",
  "摸",
  "忍",
  "蝦",
  "蠟",
  "鄰",
  "胸",
  "鞏",
  "擠",
  "偶",
  "棄",
  "槽",
  "勁",
  "乳",
  "鄧",
  "吉",
  "仁",
  "爛",
  "磚",
  "租",
  "烏",
  "艦",
  "伴",
  "瓜",
  "淺",
  "丙",
  "暫",
  "燥",
  "橡",
  "柳",
  "迷",
  "暖",
  "牌",
  "秧",
  "膽",
  "詳",
  "簧",
  "踏",
  "瓷",
  "譜",
  "呆",
  "賓",
  "糊",
  "洛",
  "輝",
  "憤",
  "競",
  "隙",
  "怒",
  "粘",
  "乃",
  "緒",
  "肩",
  "籍",
  "敏",
  "塗",
  "熙",
  "皆",
  "偵",
  "懸",
  "掘",
  "享",
  "糾",
  "醒",
  "狂",
  "鎖",
  "淀",
  "恨",
  "牲",
  "霸",
  "爬",
  "賞",
  "逆",
  "玩",
  "陵",
  "祝",
  "秒",
  "浙",
  "貌",
  "役",
  "彼",
  "悉",
  "鴨",
  "趨",
  "鳳",
  "晨",
  "畜",
  "輩",
  "秩",
  "卵",
  "署",
  "梯",
  "炎",
  "灘",
  "棋",
  "驅",
  "篩",
  "峽",
  "冒",
  "啥",
  "壽",
  "譯",
  "浸",
  "泉",
  "帽",
  "遲",
  "矽",
  "疆",
  "貸",
  "漏",
  "稿",
  "冠",
  "嫩",
  "脅",
  "芯",
  "牢",
  "叛",
  "蝕",
  "奧",
  "鳴",
  "嶺",
  "羊",
  "憑",
  "串",
  "塘",
  "繪",
  "酵",
  "融",
  "盆",
  "錫",
  "廟",
  "籌",
  "凍",
  "輔",
  "攝",
  "襲",
  "筋",
  "拒",
  "僚",
  "旱",
  "鉀",
  "鳥",
  "漆",
  "沈",
  "眉",
  "疏",
  "添",
  "棒",
  "穗",
  "硝",
  "韓",
  "逼",
  "扭",
  "僑",
  "涼",
  "挺",
  "碗",
  "栽",
  "炒",
  "杯",
  "患",
  "餾",
  "勸",
  "豪",
  "遼",
  "勃",
  "鴻",
  "旦",
  "吏",
  "拜",
  "狗",
  "埋",
  "輥",
  "掩",
  "飲",
  "搬",
  "罵",
  "辭",
  "勾",
  "扣",
  "估",
  "蔣",
  "絨",
  "霧",
  "丈",
  "朵",
  "姆",
  "擬",
  "宇",
  "輯",
  "陝",
  "雕",
  "償",
  "蓄",
  "崇",
  "剪",
  "倡",
  "廳",
  "咬",
  "駛",
  "薯",
  "刷",
  "斥",
  "番",
  "賦",
  "奉",
  "佛",
  "澆",
  "漫",
  "曼",
  "扇",
  "鈣",
  "桃",
  "扶",
  "仔",
  "返",
  "俗",
  "虧",
  "腔",
  "鞋",
  "棱",
  "覆",
  "框",
  "悄",
  "叔",
  "撞",
  "騙",
  "勘",
  "旺",
  "沸",
  "孤",
  "吐",
  "孟",
  "渠",
  "屈",
  "疾",
  "妙",
  "惜",
  "仰",
  "狠",
  "脹",
  "諧",
  "拋",
  "黴",
  "桑",
  "崗",
  "嘛",
  "衰",
  "盜",
  "滲",
  "臟",
  "賴",
  "湧",
  "甜",
  "曹",
  "閱",
  "肌",
  "哩",
  "厲",
  "烴",
  "緯",
  "毅",
  "昨",
  "偽",
  "症",
  "煮",
  "嘆",
  "釘",
  "搭",
  "莖",
  "籠",
  "酷",
  "偷",
  "弓",
  "錐",
  "恆",
  "傑",
  "坑",
  "鼻",
  "翼",
  "綸",
  "敘",
  "獄",
  "逮",
  "罐",
  "絡",
  "棚",
  "抑",
  "膨",
  "蔬",
  "寺",
  "驟",
  "穆",
  "冶",
  "枯",
  "冊",
  "屍",
  "凸",
  "紳",
  "坯",
  "犧",
  "焰",
  "轟",
  "欣",
  "晉",
  "瘦",
  "禦",
  "錠",
  "錦",
  "喪",
  "旬",
  "鍛",
  "壟",
  "搜",
  "撲",
  "邀",
  "亭",
  "酯",
  "邁",
  "舒",
  "脆",
  "酶",
  "閒",
  "憂",
  "酚",
  "頑",
  "羽",
  "漲",
  "卸",
  "仗",
  "陪",
  "闢",
  "懲",
  "杭",
  "姚",
  "肚",
  "捉",
  "飄",
  "漂",
  "昆",
  "欺",
  "吾",
  "郎",
  "烷",
  "汁",
  "呵",
  "飾",
  "蕭",
  "雅",
  "郵",
  "遷",
  "燕",
  "撒",
  "姻",
  "赴",
  "宴",
  "煩",
  "債",
  "帳",
  "斑",
  "鈴",
  "旨",
  "醇",
  "董",
  "餅",
  "雛",
  "姿",
  "拌",
  "傅",
  "腹",
  "妥",
  "揉",
  "賢",
  "拆",
  "歪",
  "葡",
  "胺",
  "丟",
  "浩",
  "徽",
  "昂",
  "墊",
  "擋",
  "覽",
  "貪",
  "慰",
  "繳",
  "汪",
  "慌",
  "馮",
  "諾",
  "姜",
  "誼",
  "兇",
  "劣",
  "誣",
  "耀",
  "昏",
  "躺",
  "盈",
  "騎",
  "喬",
  "溪",
  "叢",
  "盧",
  "抹",
  "悶",
  "諮",
  "刮",
  "駕",
  "纜",
  "悟",
  "摘",
  "鉺",
  "擲",
  "頗",
  "幻",
  "柄",
  "惠",
  "慘",
  "佳",
  "仇",
  "臘",
  "窩",
  "滌",
  "劍",
  "瞧",
  "堡",
  "潑",
  "蔥",
  "罩",
  "霍",
  "撈",
  "胎",
  "蒼",
  "濱",
  "倆",
  "捅",
  "湘",
  "砍",
  "霞",
  "邵",
  "萄",
  "瘋",
  "淮",
  "遂",
  "熊",
  "糞",
  "烘",
  "宿",
  "檔",
  "戈",
  "駁",
  "嫂",
  "裕",
  "徙",
  "箭",
  "捐",
  "腸",
  "撐",
  "曬",
  "辨",
  "殿",
  "蓮",
  "攤",
  "攪",
  "醬",
  "屏",
  "疫",
  "哀",
  "蔡",
  "堵",
  "沫",
  "皺",
  "暢",
  "疊",
  "閣",
  "萊",
  "敲",
  "轄",
  "鉤",
  "痕",
  "壩",
  "巷",
  "餓",
  "禍",
  "丘",
  "玄",
  "溜",
  "曰",
  "邏",
  "彭",
  "嘗",
  "卿",
  "妨",
  "艇",
  "吞",
  "韋",
  "怨",
  "矮",
  "歇"
]
