import { Box, Collapse, Container, Fade, Flex, Grid, HStack, Image, Spacer, Spinner, Stack, Text, useBoolean } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
    deferUndefinedChildMetadataToParent,
    ExternalTransactionDTO,
    initTransferMetadataMappingFromAllTransfers,
    TransferDTO,
    TransferMetadata,
} from '../../../../data-lib/dto/external-transactions-dto';
import { ChainId, EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS, NETWORKS } from '../../../../data-lib/networks';
import { useExternalTransactionsApi } from '../../../../hooks/useExternalTransactionsApi';
import { usePagination } from '../../../../hooks/usePagination';
import { useQuery } from '../../../../hooks/useQuery';
import { useSelectedNetworks } from '../../../../hooks/useSelectedNetworks';
import { useLocalStorage } from '../../../../hooks/useStorage';
import { useTokenRepo } from '../../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../../hooks/useWeb3';
import { SignIn } from '../../../../pages/settings/sign-in';
import { allNetworksReady, useAppState, UserDataNotSelectedState, UserDataReadyState, UserDataState } from '../../../../state/app-state';
import { isUserReady, useAuth } from '../../../../state/auth-state';
import { useGnosisSafe } from '../../../../state/gnosis-state';
import { reloadWindow } from '../../../../tools/common';
import { STORAGE_KEYS } from '../../../../tools/storage';
import { Replace } from '../../../../tools/types';
import { UserData } from '../../../../tools/userData';
import { NewTag } from '../../../base/status-badge';
import { OrangeButton } from '../../../inputs/buttons';
import { MaxWidthWrapper, PageLayoutProvider } from '../../../layout/page-layout';
import { BullaCloseButton } from '../../../modals/common';
import { PageSelector } from '../../claim-table';
import { Filter, FILTER_GROUPS, useToolbarState } from './common';
import { DescribeTransactions } from './describe-transactions';
import { blinkAnimation, getFinishedLoadingState, getInitLoadingState, LoadingState } from './select-transactions';
import { TransactionImportComplete } from './transaction-import-complete';

export type BaseState = {
    fetchedDate: string; // ISO string: "2023-02-15T16:48:27.532Z"
    step: 'loading' | 'describe' | 'complete';
    omittedIds: string[];
    queried: {
        txs: ExternalTransactionDTO[];
        allTransfers: TransferDTO[];
    };
    filters: Filter[];
    alreadyImportedTransferIdsByChain: Record<number, Set<string>>;
};

export type FilterGroup = { config: typeof FILTER_GROUPS[number]; filters: Filter[] }[];

const getFilteredTxs = (state: ImportTransactionState) => {
    const filterGroups = FILTER_GROUPS.reduce<FilterGroup>(
        (acc, config) => [
            ...acc,
            {
                config,
                filters: state.filters.filter(filt => filt.config.prefix === config.prefix),
            },
        ],
        [] as FilterGroup,
    );

    const nextTxs = state.queried.txs.filter(tx =>
        filterGroups.every(({ config, filters }) =>
            filters.length === 0
                ? true
                : config.logicOperation === 'OR'
                ? filters.some(filt => filt.filter(tx))
                : config.logicOperation === 'AND'
                ? filters.every(filt => filt.filter(tx))
                : false,
        ),
    );
    const nextAllTransfers = nextTxs.flatMap(tx => tx.allTransfers);

    return {
        txs: nextTxs,
        allTransfers: nextAllTransfers,
    };
};

export type SelectTransactionState = BaseState & {
    step: 'loading';
    transferMetadata: Record<string, TransferMetadata>;
};

export type DescribeTransactionState = BaseState & {
    step: 'describe';
    transferMetadata: Record<string, TransferMetadata>;
};

export type ImportCompleteState = Replace<DescribeTransactionState, 'step', 'complete'>;

export type ImportTransactionState = SelectTransactionState | DescribeTransactionState | ImportCompleteState;

const getInitState = (alreadyImportedTransferIdsByChain: BaseState['alreadyImportedTransferIdsByChain']): SelectTransactionState => ({
    fetchedDate: new Date().toISOString(),
    step: 'loading',
    omittedIds: [],
    queried: { allTransfers: [], txs: [] },
    filters: [],
    alreadyImportedTransferIdsByChain,
    transferMetadata: {},
});

const refetchInterval = 1000 * 60 * 10; // 10 minutes

export const handleSaveTransactions = async (
    { omittedIds, queried: { allTransfers }, transferMetadata }: DescribeTransactionState,
    saveExternalTransactions: (data: Record<string, TransferMetadata>) => Promise<boolean>,
) => {
    const omittedIdMap = omittedIds.reduce<Record<string, boolean>>((acc, id) => ({ ...acc, [id]: true }), {});

    const finalDescribedTransfers = allTransfers.reduce<Record<string, TransferMetadata>>((acc, transfer) => {
        if (omittedIdMap[transfer.parentTxId] || omittedIdMap[transfer.id]) return acc;
        const [parentMetadata, childMetadata] = [transferMetadata[transfer.parentTxId], transferMetadata[transfer.id]];

        const metadata = deferUndefinedChildMetadataToParent({
            parentMetadata: parentMetadata,
            childMetadata: childMetadata,
        });

        const normalizedMetadata: Required<Omit<TransferMetadata, 'chainId'>> = {
            id: transfer.id,
            tags: metadata?.tags ?? [],
            description: metadata?.description ?? '',
            notes: metadata?.notes ?? '',
        };

        return { ...acc, [transfer.id]: normalizedMetadata };
    }, {});
    console.log('finalDescribedTransfers');
    console.log(finalDescribedTransfers);
    await saveExternalTransactions(finalDescribedTransfers);
};

const DISABLE_CACHING = true;

export const ImportExternalTransactions = () => {
    const navigate = useNavigate();
    const [saving, setSaving] = useBoolean();
    const { userDataByChain } = useAppState();
    const { safeInfo } = useGnosisSafe();
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const networksToFetch = (safeInfo ? [connectedNetwork] : EXTERNAL_TRANSACTIONS_SUPPORTED_NETWORKS) as ChainId[];
    const { selectedNetworks, overrideSelectedNetworks } = useSelectedNetworks();
    const requiredNetworksSelected = networksToFetch.every(chain => selectedNetworks.includes(chain));
    const allNetworksLoaded = allNetworksReady(userDataByChain, networksToFetch);
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const userDataReady = requiredNetworksSelected && allNetworksLoaded;

    const metadataByTransferByChainId: Record<number, Set<string>> = React.useMemo(() => {
        const result = Object.fromEntries(
            userDataReady
                ? networksToFetch
                      .map((x): [ChainId, UserDataReadyState | UserDataNotSelectedState] => [x, userDataByChain[x]])
                      .filter((x): x is [ChainId, UserDataReadyState] => x[1].kind !== 'not-selected')
                      .map(([chainId, userDataState]) => [chainId, new Set(userDataState.userData.importedExternalTxs.map(x => x.id))])
                : networksToFetch.map(x => [x, new Set<string>()]),
        );
        return result;
    }, [userDataReady, selectedNetworks.length]);

    useEffect(() => {
        overrideSelectedNetworks(networksToFetch);
        return () => overrideSelectedNetworks(undefined);
    }, [JSON.stringify(selectedNetworks.sort((a, b) => b - a))]);

    const connectedWallet = useActingWalletAddress();
    const queriedWalletAddress = useQuery('wallet');
    const walletAddress = queriedWalletAddress ? queriedWalletAddress : connectedWallet;

    const [cache, setCache, clearCache] = useLocalStorage<ImportTransactionState | undefined>(
        `${STORAGE_KEYS.externalTxs}-${walletAddress}`,
        undefined,
    );

    const initState = React.useMemo(() => getInitState(metadataByTransferByChainId), []);
    const [externalTxState, setExternalTxState] = useState<ImportTransactionState>(() =>
        DISABLE_CACHING
            ? initState
            : cache && new Date().getTime() < new Date(cache?.fetchedDate).getTime() + refetchInterval
            ? cache
            : { ...initState, transferMetadata: cache && 'transferMetadata' in cache ? cache?.transferMetadata : {} },
    );

    const initLoadingState = getInitLoadingState(networksToFetch);
    const [fetchingState, setFetchingState] = useState<LoadingState>(() =>
        externalTxState.queried.txs.length === 0 ? initLoadingState : getFinishedLoadingState(networksToFetch),
    );

    const toolbarState = useToolbarState(setExternalTxState);

    useEffect(() => {
        setExternalTxState(prev => ({ ...prev, alreadyImportedTransferIdsByChain: metadataByTransferByChainId }));
    }, [metadataByTransferByChainId]);

    const LoadingBar = ({
        allLoaded,
        userDataByChain,
    }: {
        allLoaded: boolean;
        userDataByChain: 'uninitialized' | Record<number, UserDataState>;
    }) => {
        const progress =
            (Object.entries(userDataByChain).reduce((acc, [chain, state]) => {
                if (state !== 'loading') {
                    return acc + 1;
                } else {
                    return acc;
                }
            }, 0) /
                Object.entries(userDataByChain).length) *
            100;

        return (
            <HStack pos="relative" height={'32px'} w="100%" alignItems={'center'}>
                {!allLoaded && <Box w="26px" h="32px" />}
                {networksToFetch.map(chainId => {
                    const network = NETWORKS[chainId];
                    const state = userDataByChain[chainId];
                    return (
                        <Collapse
                            key={chainId}
                            in={state === 'loading'}
                            style={{
                                position: 'absolute',
                                backgroundColor: 'white',
                                height: '32px',
                                margin: '2px',
                            }}
                        >
                            <Image
                                src={network.logoFileName}
                                maxH="18px"
                                animation={`${blinkAnimation} 0.8s linear 0s infinite alternate none running`}
                            />
                        </Collapse>
                    );
                })}
                <Fade in={!allLoaded} style={{ position: 'relative', flex: '1', justifySelf: 'center', height: '8px' }} unmountOnExit>
                    <Box pos="absolute" w="full" borderRadius={'3px'} height="8px" bg="rgba(18, 82, 91, 0.23)" />
                    <Box
                        pos="absolute"
                        w={`${progress}%`}
                        borderRadius={'3px'}
                        height="8px"
                        transition={'width 0.4s ease'}
                        bg="brand.400"
                    />
                </Fade>
            </HStack>
        );
    };

    const filtered = useMemo(
        () => getFilteredTxs(externalTxState),
        [JSON.stringify(externalTxState.filters), externalTxState.queried.txs.length],
    );

    const filteredAndOmitted = useMemo(
        () => ({
            txs: filtered.txs.filter(tx => tx.allTransfers.every(transfer => !externalTxState.omittedIds.includes(transfer.id))),
            allTransfers: filtered.allTransfers.filter(tx => !externalTxState.omittedIds.includes(tx.id)),
        }),
        [filtered, externalTxState.omittedIds],
    );

    const step = externalTxState.step;

    const [debouncedExternalTxState] = useDebounce(externalTxState, 1000);

    const pageRef = useRef<HTMLDivElement>(null);
    const pageSelectorProps = usePagination(filtered.txs);

    const handleSave = async () => {
        if (externalTxState.step == 'complete' || externalTxState.step == 'loading') return;

        const state: DescribeTransactionState = externalTxState;

        try {
            setSaving.on();
            await handleSaveTransactions(state, saveExternalTransactions);
            setExternalTxState({
                ...state,
                step: 'complete',
            });
        } catch (e: any) {
            console.error(e);
        } finally {
            setSaving.off();
        }
    };

    useEffect(() => {
        if (!DISABLE_CACHING) setCache(debouncedExternalTxState);
    }, [debouncedExternalTxState]);

    useEffect(() => {
        if (step === 'complete' || step === 'describe') pageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [step]);

    useEffect(() => {
        setExternalTxState(prev => ({
            ...prev,
            queried: { allTransfers: [], txs: [] },
        }));
    }, [walletAddress]);

    useEffect(() => {
        const getExternalTxs = (userDataByChain: Record<number, UserData>) => {
            for (const network of networksToFetch) {
                try {
                    setFetchingState(prev => ({ ...prev, [network]: { loading: true, error: undefined } }));

                    setExternalTxState(prev => {
                        const txs = userDataByChain[network].nonImportedExternalTxs;
                        const externalTxs = [...prev.queried.txs, ...txs].sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
                        const allTransfers = externalTxs.flatMap(tx => tx.allTransfers);

                        return {
                            ...prev,
                            allTransfers,
                            queried: {
                                txs: externalTxs,
                                allTransfers,
                            },
                            transferMetadata: {
                                ...initTransferMetadataMappingFromAllTransfers(allTransfers),
                                ...(prev.transferMetadata ?? {}),
                            },
                        };
                    });

                    setFetchingState(prev => ({ ...prev, [network]: { loading: false, error: undefined } }));
                } catch (e: any) {
                    setFetchingState(prev => ({ ...prev, [network]: { loading: false, error: e.message } }));
                    console.error(e);
                }
            }
            setExternalTxState(prev => ({ ...prev, step: 'describe' }));
        };

        if (externalTxState.queried.txs.length === 0 && userDataReady) {
            setFetchingState(initLoadingState);
            setExternalTxState(prev => ({
                ...prev,
                omittedIds: [],
                transferMetadata: {},
                queried: { allTransfers: [], txs: [] },
            }));

            const loadedUserDataByChain = Object.fromEntries(
                Object.entries(userDataByChain)
                    .filter(
                        (userDataByChain): userDataByChain is [string, UserDataReadyState] => userDataByChain[1].kind !== 'not-selected',
                    )
                    .map(([network, userDataState]) => [+network, userDataState.userData]),
            );

            getExternalTxs(loadedUserDataByChain);
        }
    }, [walletAddress, userDataReady]);

    return (
        <>
            <Flex p={{ sm: '8', md: '12' }} flex="1" ref={pageRef}>
                <MaxWidthWrapper alignSelf="baseline" flex="1">
                    <HStack alignItems={'start'}>
                        <Stack>
                            <Text fontSize={'34px'} lineHeight="42px" fontWeight="700" color="gray.900">
                                Organize Transactions <NewTag />
                            </Text>
                        </Stack>
                        <Spacer />
                        <BullaCloseButton onClose={() => navigate('/')} />
                    </HStack>
                    <Box h="3" />
                    <Box
                        pos="relative"
                        minH={step !== 'complete' ? `${(pageSelectorProps.pageSize + 3) * 60}px` : '0vh'}
                        minW="100%"
                        transition="min-height 0.3s ease-out"
                    >
                        <AnimatePresence mode="wait" initial={false}>
                            {
                                <>
                                    {step === 'complete' ? (
                                        <motion.div initial={{ x: 0, opacity: 0 }} animate={{ x: 0, opacity: 1 }}>
                                            <TransactionImportComplete
                                                onComplete={() => {
                                                    clearCache();
                                                    navigate('/');
                                                    reloadWindow();
                                                }}
                                            />
                                        </motion.div>
                                    ) : step === 'describe' ? (
                                        <motion.div
                                            initial={{ x: 1200, opacity: 0 }}
                                            animate={{ x: 0, opacity: 1 }}
                                            exit={{ x: 1200, opacity: 0 }}
                                            transition={{ bounceStiffness: 100 }}
                                        >
                                            <DescribeTransactions
                                                walletAddress={walletAddress}
                                                setExternalTxState={setExternalTxState}
                                                externalTxState={externalTxState}
                                                filteredTxs={filteredAndOmitted}
                                                toolbarState={toolbarState}
                                                visibleTxs={pageSelectorProps.shownItems}
                                                pageSize={pageSelectorProps.pageSize}
                                            />
                                        </motion.div>
                                    ) : (
                                        <>
                                            <LoadingBar allLoaded={userDataReady} userDataByChain={userDataByChain} />

                                            <Flex w="full" h="50vh" justifyContent={'center'} alignItems={'center'} flexDir="column">
                                                <Stack alignItems={'center'}>
                                                    <Spinner />
                                                    <Text fontWeight={500} fontSize="16px">
                                                        Loading Networks
                                                    </Text>
                                                </Stack>
                                            </Flex>
                                        </>
                                    )}
                                </>
                            }
                        </AnimatePresence>
                    </Box>
                    <Grid templateColumns={'1fr 1fr 1fr'} justifyContent={'space-between'}>
                        <Box />
                        {step !== 'complete' && !pageSelectorProps.dontNeedPages ? (
                            <PageSelector {...pageSelectorProps} justifySelf="center" />
                        ) : (
                            <Box />
                        )}
                        {step !== 'complete' && (
                            <OrangeButton
                                isLoading={saving}
                                isDisabled={!userDataReady || step === 'loading' || externalTxState.queried.txs.length == 0}
                                justifySelf={'end'}
                                onClick={handleSave}
                                w="min-content"
                            >
                                Save
                            </OrangeButton>
                        )}
                    </Grid>
                </MaxWidthWrapper>
            </Flex>
        </>
    );
};

export const AuthenticatedExternalTransactions = () => {
    const { user } = useAuth();

    return (
        <PageLayoutProvider>
            {isUserReady(user) ? (
                <ImportExternalTransactions />
            ) : (
                <Container maxW="495px" flex="1" mt="8">
                    <SignIn message={`Sign to access your transaction journal`} />
                </Container>
            )}
        </PageLayoutProvider>
    );
};
