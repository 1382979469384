import { Box, Heading, chakra } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Fields, fieldsToInvoicePreviewURL } from './common';

type InvoicePreviewProps = {
    fields: Fields;
    isLoading?: boolean;
};

const ChakraiFrame = chakra('iframe');

export const InvoicePreview = ({ fields, isLoading }: InvoicePreviewProps) => {
    const previewURL = useMemo(() => fieldsToInvoicePreviewURL(fields), [JSON.stringify(fields)]);
    const [shouldBlur, setShouldBlur] = useState(true);

    useEffect(() => {
        if (isLoading) setShouldBlur(true);
    }, [isLoading, previewURL]);

    return (
        <>
            <Heading size="lg" color="heading">
                Invoice Preview
            </Heading>
            <Box h="4" />
            <Box height={'100%'} bg="white" filter={shouldBlur ? 'blur(1px)' : 'none'}>
                <ChakraiFrame
                    onLoad={() => setShouldBlur(false)}
                    bg="white"
                    src={previewURL}
                    minH="100%"
                    width={'100%'}
                    scrolling="no"
                    frameBorder={0}
                />
            </Box>
        </>
    );
};

export const DebouncedInvoicePreview = ({ fields, delay }: InvoicePreviewProps & { delay: number }) => {
    const [debouncedFields, { isPending }] = useDebounce(fields, delay);

    return <InvoicePreview fields={debouncedFields} isLoading={isPending()} />;
};
