import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalContentProps,
    ModalHeader,
    Spacer,
    Spinner,
    Stack,
    Text,
} from '@chakra-ui/react';
import { BigNumber, utils } from 'ethers';
import { Field, FieldProps, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import CoinsLogo from 'url:../../../assets/coins.svg';
import { FinanciableClaimInfo, FinancingOfferedClaimInfo } from '../../../data-lib/data-model';
import { FinancingTerms, processFinancingTermInputs } from '../../../data-lib/dto/bulla-finance-dto';
import { getFrendLendContract } from '../../../data-lib/dto/contract-interfaces';
import { addressEquality } from '../../../data-lib/ethereum';
import { isFinancingOffered } from '../../../data-lib/helpers';
import { TokenDto } from '../../../data-lib/networks';
import { useAcceptFinancing } from '../../../hooks/useBullaFinance';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { apply } from '../../../tools/common';
import { AlertInfo } from '../../base/alert';
import { CloseButton, OrangeButton, SecondaryButton } from '../../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow } from '../common';
import { LockedInTokenAmountField, PercentAmountField } from '../create-claim-modal/create-claim-inputs';
import {
    calculateDueDate,
    defaultFinancingTermsLabels,
    FinanceTermsLabel,
    FinancingDueByField,
    financingTermsErrorSchema,
    getFinancingTermLabels,
    TermsSummary,
} from './financing-inputs';

type BaseFinancingTermsModalProps = {
    onClose: VoidFunction;
    isOpen: boolean;
    height: ModalContentProps['h'];
};

type EditFinancingTermsModalProps = BaseFinancingTermsModalProps & {
    token: TokenDto;
    claimAmount: string; // input formatted string '10.00001' or '.000001'
    terms: FinancingTerms | 'none';
    setClaimAmount: (amount: string) => void;
    onSubmit: (terms: FinancingTerms) => void;
};

export const EditFinancingTermsModal = ({
    onClose,
    isOpen,
    height,
    terms,
    claimAmount,
    token,
    setClaimAmount,
    onSubmit,
}: EditFinancingTermsModalProps) => {
    const {
        connectedNetworkConfig: { frendlendAddress, nativeCurrency },
        provider,
    } = useWeb3();
    const isMobile = useIsMobile();
    const [originationFee, setFee] = React.useState<BigNumber | undefined>();
    const initialDownPayment = terms !== 'none' ? (terms.downPaymentBPS / 100).toString() : '20';
    const initialInterestRate = terms !== 'none' ? (terms.interestRateBPS / 100).toString() : '';
    const initialTermLength = terms !== 'none' ? terms.termLength : { years: 0, months: 0, days: 0 };
    const initialdueBy = terms !== 'none' ? calculateDueDate(terms.termLength) : moment().add(7, 'day').toDate();

    const initialValues = {
        totalAmount: claimAmount,
        downPayment: initialDownPayment,
        interestRate: initialInterestRate,
        termLength: initialTermLength,
        dueBy: initialdueBy,
    };

    useEffect(() => {
        if (frendlendAddress) getFrendLendContract(frendlendAddress).connect(provider).callStatic.fee().then(setFee);
    }, [frendlendAddress, provider]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? 'full' : '2xl'}
            closeOnOverlayClick={false}
            isCentered
            closeOnEsc={false}
            scrollBehavior="inside"
        >
            <Formik
                initialValues={initialValues}
                onSubmit={inputs => {
                    const values = processFinancingTermInputs(
                        inputs.totalAmount,
                        inputs.downPayment,
                        inputs.interestRate,
                        token.decimals,
                        inputs.dueBy,
                    );
                    if (values !== 'invalid') onSubmit(values);
                }}
                validationSchema={financingTermsErrorSchema}
                validateOnBlur
            >
                {({ errors, touched, setFieldValue, isValid, values }) => {
                    const terms = processFinancingTermInputs(
                        values.totalAmount,
                        values.downPayment,
                        values.interestRate,
                        token.decimals,
                        values.dueBy,
                    );
                    const termLabels =
                        terms !== 'invalid' ? getFinancingTermLabels(terms, token.symbol, token.decimals) : defaultFinancingTermsLabels;

                    return (
                        <Form placeholder={''}>
                            <ModalContent py="4" px="2" bg={'white'} h={height}>
                                <ModalHeader display="flex">
                                    <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                                        Financing Options
                                    </Text>
                                </ModalHeader>
                                <CloseModalButton onClose={onClose} />
                                <ModalBody>
                                    <Flex flexDir="column" h="100%">
                                        <AlertInfo message="Bulla Finance allows the recipient to pay you over time." mb={4} />
                                        <Stack spacing="3" w={'100%'}>
                                            <Field name="totalAmount">
                                                {({ field }: FieldProps) => (
                                                    <LockedInTokenAmountField
                                                        {...{
                                                            field,
                                                            label: 'Principal Amount',
                                                            symbol: token.symbol,
                                                            setAmount: (amt: string) => {
                                                                apply(setFieldValue, field.name)(amt);
                                                                setClaimAmount(amt);
                                                            },
                                                            editable: true,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="downPayment">
                                                {({ field }: FieldProps) => (
                                                    <PercentAmountField
                                                        {...{
                                                            field,
                                                            label: 'Down Payment (%)',
                                                            setAmount: apply(setFieldValue, field.name),
                                                            touched: touched.downPayment,
                                                            error: errors.downPayment,
                                                            inputRightElement: (
                                                                <FinanceTermsLabel>{termLabels.downPaymentLabel}</FinanceTermsLabel>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="interestRate">
                                                {({ field }: FieldProps) => (
                                                    <PercentAmountField
                                                        {...{
                                                            field,
                                                            label: 'Interest Rate',
                                                            setAmount: apply(setFieldValue, field.name),
                                                            touched: touched.interestRate,
                                                            error: errors.interestRate,
                                                            inputRightElement: (
                                                                <FinanceTermsLabel>{termLabels.financeChargeLabel}</FinanceTermsLabel>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="dueBy">
                                                {({ field }: FieldProps) => (
                                                    <FinancingDueByField
                                                        {...{
                                                            field,
                                                            label: 'Due Date',
                                                            setFinancingDueBy: apply(setFieldValue, field.name),
                                                            touched: touched.dueBy,
                                                            error: errors.dueBy,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Box />
                                            <TermsSummary
                                                terms={termLabels}
                                                feeInfo={
                                                    originationFee
                                                        ? `${utils.formatUnits(originationFee, nativeCurrency.decimals)} ${
                                                              nativeCurrency.symbol
                                                          }`
                                                        : undefined
                                                }
                                            />
                                            <Box h="4" />
                                        </Stack>
                                    </Flex>
                                </ModalBody>
                                <ModalFooterWithShadow>
                                    <Spacer />
                                    <SecondaryButton onClick={onClose} isDisabled={false} mr="2" display={['none', 'inherit', 'inherit']}>
                                        Cancel
                                    </SecondaryButton>
                                    <OrangeButton type="submit" isDisabled={!isValid || terms == 'invalid'}>
                                        Apply Terms
                                    </OrangeButton>
                                </ModalFooterWithShadow>
                            </ModalContent>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export const PayFinancingButton = ({ claim, onComplete }: { claim: FinancingOfferedClaimInfo; onComplete: VoidFunction }) => {
    const [{ isLoading, state, buttonText }, _handleClick] = useAcceptFinancing(claim);

    const isComplete = state == 'complete';
    const isDisabled = isLoading || state == 'insufficient-funds' || state == 'unsupported-network' || isComplete;
    const isPayInteraction = buttonText.includes('Pay');
    const handleClick = isPayInteraction ? () => _handleClick().then(onComplete) : _handleClick;

    return (
        <OrangeButton
            isDisabled={isDisabled}
            onClick={handleClick}
            leftIcon={isPayInteraction ? <Image src={CoinsLogo} /> : undefined}
            rightIcon={isLoading ? <Spinner /> : isComplete ? <CheckIcon /> : undefined}
        >
            {buttonText}
        </OrangeButton>
    );
};

type ViewFinancingTermsModalProps = BaseFinancingTermsModalProps & {
    claim: FinanciableClaimInfo;
};

export const ViewFinancingTermsModal = ({ onClose, isOpen, height, claim }: ViewFinancingTermsModalProps) => {
    const userAddress = useActingWalletAddress();
    const isMobile = useIsMobile();
    const isDebtor = addressEquality(userAddress, claim.debtor);
    const token = claim.tokenInfo.token;

    const terms = claim.financingState.terms;
    const termLabels = getFinancingTermLabels(terms, token.symbol, token.decimals);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={isMobile ? 'full' : '3xl'}
            closeOnOverlayClick={false}
            isCentered
            closeOnEsc={false}
            scrollBehavior="inside"
        >
            <ModalContent py="4" px="2" bg={'white'} h={height}>
                <ModalHeader display="flex">
                    <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                        Financing Options
                    </Text>
                </ModalHeader>
                <CloseModalButton onClose={onClose} />
                <ModalBody>
                    <AlertInfo message="Bulla Finance allows the recipient to pay you over time." mb={4} />
                    <Stack spacing="3" w={'100%'}></Stack>
                    <TermsSummary terms={termLabels} />
                </ModalBody>
                <ModalFooterWithShadow>
                    <Spacer />
                    <CloseButton onClick={onClose} isDisabled={false} mr="2" display={['none', 'inherit', 'inherit']} />
                    {isFinancingOffered(claim) && isDebtor && <PayFinancingButton claim={claim} onComplete={onClose} />}
                </ModalFooterWithShadow>
            </ModalContent>
        </Modal>
    );
};
