{
    "v": "5.5.2",
    "fr": 29.9700012207031,
    "ip": 0,
    "op": 40.0000016292334,
    "w": 800,
    "h": 800,
    "nm": "Comp 1",
    "ddd": 0,
    "assets": [
        {
            "id": "comp_0",
            "layers": [
                {
                    "ddd": 0,
                    "ind": 1,
                    "ty": 4,
                    "nm": "illustrator Outlines",
                    "sr": 1,
                    "ks": {
                        "o": { "a": 0, "k": 100, "ix": 11 },
                        "r": { "a": 0, "k": 0, "ix": 10 },
                        "p": { "a": 0, "k": [400, 400, 0], "ix": 2 },
                        "a": { "a": 0, "k": [25.5, 25, 0], "ix": 1 },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [0.833, 0.833, 0.833],
                                        "y": [0.833, 0.833, 0.833]
                                    },
                                    "o": {
                                        "x": [0.167, 0.167, 0.167],
                                        "y": [0.167, 0.167, 0.167]
                                    },
                                    "t": 0,
                                    "s": [27.344, 27.344, 100],
                                    "e": [259.344, 259.344, 100]
                                },
                                {
                                    "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                                    "o": { "x": [0.167, 0.167, 0.167], "y": [0, 0, 0] },
                                    "t": 16,
                                    "s": [259.344, 259.344, 100],
                                    "e": [259.344, 259.344, 100]
                                },
                                {
                                    "i": {
                                        "x": [0.833, 0.833, 0.833],
                                        "y": [0.833, 0.833, 0.833]
                                    },
                                    "o": {
                                        "x": [0.167, 0.167, 0.167],
                                        "y": [0.167, 0.167, 0.167]
                                    },
                                    "t": 20,
                                    "s": [259.344, 259.344, 100],
                                    "e": [306.344, 306.344, 100]
                                },
                                {
                                    "i": { "x": [0.667, 0.667, 0.667], "y": [1, 1, 1] },
                                    "o": {
                                        "x": [0.167, 0.167, 0.167],
                                        "y": [0.167, 0.167, -2.724]
                                    },
                                    "t": 23,
                                    "s": [306.344, 306.344, 100],
                                    "e": [259.3, 259.3, 100]
                                },
                                { "t": 28.0000011404634 }
                            ],
                            "ix": 6
                        }
                    },
                    "ao": 0,
                    "ef": [
                        {
                            "ty": 21,
                            "nm": "Fill",
                            "np": 9,
                            "mn": "ADBE Fill",
                            "ix": 1,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 10,
                                    "nm": "Fill Mask",
                                    "mn": "ADBE Fill-0001",
                                    "ix": 1,
                                    "v": { "a": 0, "k": 0, "ix": 1 }
                                },
                                {
                                    "ty": 7,
                                    "nm": "All Masks",
                                    "mn": "ADBE Fill-0007",
                                    "ix": 2,
                                    "v": { "a": 0, "k": 0, "ix": 2 }
                                },
                                {
                                    "ty": 2,
                                    "nm": "Color",
                                    "mn": "ADBE Fill-0002",
                                    "ix": 3,
                                    "v": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Invert",
                                    "mn": "ADBE Fill-0006",
                                    "ix": 4,
                                    "v": { "a": 0, "k": 0, "ix": 4 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Horizontal Feather",
                                    "mn": "ADBE Fill-0003",
                                    "ix": 5,
                                    "v": { "a": 0, "k": 0, "ix": 5 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Vertical Feather",
                                    "mn": "ADBE Fill-0004",
                                    "ix": 6,
                                    "v": { "a": 0, "k": 0, "ix": 6 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Opacity",
                                    "mn": "ADBE Fill-0005",
                                    "ix": 7,
                                    "v": { "a": 0, "k": 1, "ix": 7 }
                                }
                            ]
                        },
                        {
                            "ty": 5,
                            "nm": "(Transform)",
                            "np": 14,
                            "mn": "ADBE Geometry2",
                            "ix": 2,
                            "en": 1,
                            "ef": [
                                {
                                    "ty": 3,
                                    "nm": "Anchor Point",
                                    "mn": "ADBE Geometry2-0001",
                                    "ix": 1,
                                    "v": {
                                        "a": 0,
                                        "k": [400, 400],
                                        "ix": 1,
                                        "x": "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];"
                                    }
                                },
                                {
                                    "ty": 3,
                                    "nm": "Position",
                                    "mn": "ADBE Geometry2-0002",
                                    "ix": 2,
                                    "v": {
                                        "a": 0,
                                        "k": [400, 400],
                                        "ix": 2,
                                        "x": "var $bm_rt;\n$bm_rt = [\n    thisLayer.width / 2,\n    thisLayer.height / 2\n];"
                                    }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Uniform Scale",
                                    "mn": "ADBE Geometry2-0011",
                                    "ix": 3,
                                    "v": { "a": 0, "k": 1, "ix": 3 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Scale Height",
                                    "mn": "ADBE Geometry2-0003",
                                    "ix": 4,
                                    "v": { "a": 0, "k": 100, "ix": 4 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Scale Width",
                                    "mn": "ADBE Geometry2-0004",
                                    "ix": 5,
                                    "v": { "a": 0, "k": 100, "ix": 5 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Skew",
                                    "mn": "ADBE Geometry2-0005",
                                    "ix": 6,
                                    "v": { "a": 0, "k": 0, "ix": 6 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Skew Axis",
                                    "mn": "ADBE Geometry2-0006",
                                    "ix": 7,
                                    "v": { "a": 0, "k": 0, "ix": 7 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Rotation",
                                    "mn": "ADBE Geometry2-0007",
                                    "ix": 8,
                                    "v": { "a": 0, "k": 0, "ix": 8 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Opacity",
                                    "mn": "ADBE Geometry2-0008",
                                    "ix": 9,
                                    "v": { "a": 0, "k": 100, "ix": 9 }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Use Composition’s Shutter Angle",
                                    "mn": "ADBE Geometry2-0009",
                                    "ix": 10,
                                    "v": { "a": 0, "k": 1, "ix": 10 }
                                },
                                {
                                    "ty": 0,
                                    "nm": "Shutter Angle",
                                    "mn": "ADBE Geometry2-0010",
                                    "ix": 11,
                                    "v": { "a": 0, "k": 0, "ix": 11 }
                                },
                                {
                                    "ty": 7,
                                    "nm": "Sampling",
                                    "mn": "ADBE Geometry2-0012",
                                    "ix": 12,
                                    "v": { "a": 0, "k": 1, "ix": 12 }
                                }
                            ]
                        }
                    ],
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "ind": 0,
                                    "ty": "sh",
                                    "ix": 1,
                                    "ks": {
                                        "a": 0,
                                        "k": {
                                            "i": [
                                                [0, 0],
                                                [2.071, 2.072],
                                                [0, 0],
                                                [-2.072, 2.071],
                                                [-2.072, -2.072],
                                                [0, 0],
                                                [-0.524, 0.522],
                                                [0, 0],
                                                [-2.072, -2.072],
                                                [0, -1.407],
                                                [0.995, -0.995]
                                            ],
                                            "o": [
                                                [-2.072, 2.072],
                                                [0, 0],
                                                [-2.072, -2.071],
                                                [2.071, -2.072],
                                                [0, 0],
                                                [0.523, 0.522],
                                                [0, 0],
                                                [2.071, -2.072],
                                                [0.995, 0.995],
                                                [0, 1.407],
                                                [0, 0]
                                            ],
                                            "v": [
                                                [-1.904, 15.482],
                                                [-9.41, 15.482],
                                                [-21.038, 3.854],
                                                [-21.038, -3.65],
                                                [-13.533, -3.65],
                                                [-6.605, 3.277],
                                                [-4.708, 3.277],
                                                [14.05, -15.482],
                                                [21.555, -15.482],
                                                [23.109, -11.73],
                                                [21.555, -7.978]
                                            ],
                                            "c": true
                                        },
                                        "ix": 2
                                    },
                                    "nm": "Path 1",
                                    "mn": "ADBE Vector Shape - Group",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 4 },
                                    "o": { "a": 0, "k": 100, "ix": 5 },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": { "a": 0, "k": [24.796, 24.692], "ix": 2 },
                                    "a": { "a": 0, "k": [0, 0], "ix": 1 },
                                    "s": { "a": 0, "k": [100, 100], "ix": 3 },
                                    "r": { "a": 0, "k": 0, "ix": 6 },
                                    "o": { "a": 0, "k": 100, "ix": 7 },
                                    "sk": { "a": 0, "k": 0, "ix": 4 },
                                    "sa": { "a": 0, "k": 0, "ix": 5 },
                                    "nm": "Transform"
                                }
                            ],
                            "nm": "Group 1",
                            "np": 2,
                            "cix": 2,
                            "bm": 0,
                            "ix": 1,
                            "mn": "ADBE Vector Group",
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 40.0000016292334,
                    "st": 0,
                    "bm": 0
                },
                {
                    "ddd": 0,
                    "ind": 2,
                    "ty": 4,
                    "nm": "Shape Layer 2",
                    "sr": 1,
                    "ks": {
                        "o": { "a": 0, "k": 100, "ix": 11 },
                        "r": { "a": 0, "k": 0, "ix": 10 },
                        "p": { "a": 0, "k": [400, 400, 0], "ix": 2 },
                        "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                        "s": {
                            "a": 1,
                            "k": [
                                {
                                    "i": {
                                        "x": [0.833, 0.833, 0.833],
                                        "y": [0.833, 0.833, 0.833]
                                    },
                                    "o": {
                                        "x": [0.167, 0.167, 0.167],
                                        "y": [0.167, 0.167, 0.167]
                                    },
                                    "t": 0,
                                    "s": [0, 0, 100],
                                    "e": [113, 113, 100]
                                },
                                { "t": 16.0000006516934 }
                            ],
                            "ix": 6
                        }
                    },
                    "ao": 0,
                    "shapes": [
                        {
                            "ty": "gr",
                            "it": [
                                {
                                    "d": 1,
                                    "ty": "el",
                                    "s": { "a": 0, "k": [205.703, 205.703], "ix": 2 },
                                    "p": { "a": 0, "k": [0, 0], "ix": 3 },
                                    "nm": "Ellipse Path 1",
                                    "mn": "ADBE Vector Shape - Ellipse",
                                    "hd": false
                                },
                                {
                                    "ty": "fl",
                                    "c": {
                                        "a": 1,
                                        "k": [
                                            {
                                                "i": { "x": [0.833], "y": [0.833] },
                                                "o": { "x": [0.167], "y": [0.167] },
                                                "t": 0,
                                                "s": [1, 1, 1, 1],
                                                "e": [0.281278342009, 0.739185035229, 0.161424964666, 1]
                                            },
                                            { "t": 16.0000006516934 }
                                        ],
                                        "ix": 4
                                    },
                                    "o": { "a": 0, "k": 100, "ix": 5 },
                                    "r": 1,
                                    "bm": 0,
                                    "nm": "Fill 1",
                                    "mn": "ADBE Vector Graphic - Fill",
                                    "hd": false
                                },
                                {
                                    "ty": "tr",
                                    "p": { "a": 0, "k": [-3.156, -1.609], "ix": 2 },
                                    "a": { "a": 0, "k": [0, 0], "ix": 1 },
                                    "s": { "a": 0, "k": [100, 100], "ix": 3 },
                                    "r": { "a": 0, "k": 0, "ix": 6 },
                                    "o": { "a": 0, "k": 100, "ix": 7 },
                                    "sk": { "a": 0, "k": 0, "ix": 4 },
                                    "sa": { "a": 0, "k": 0, "ix": 5 },
                                    "nm": "Transform"
                                }
                            ],
                            "nm": "Ellipse 1",
                            "np": 2,
                            "cix": 2,
                            "bm": 0,
                            "ix": 1,
                            "mn": "ADBE Vector Group",
                            "hd": false
                        }
                    ],
                    "ip": 0,
                    "op": 40.0000016292334,
                    "st": 0,
                    "bm": 0
                }
            ]
        }
    ],
    "layers": [
        {
            "ddd": 0,
            "ind": 1,
            "ty": 0,
            "nm": "Pre-comp 1",
            "refId": "comp_0",
            "sr": 1,
            "ks": {
                "o": { "a": 0, "k": 100, "ix": 11 },
                "r": { "a": 0, "k": 0, "ix": 10 },
                "p": { "a": 0, "k": [400, 400, 0], "ix": 2 },
                "a": { "a": 0, "k": [400, 400, 0], "ix": 1 },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
                            "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
                            "t": 19,
                            "s": [100, 100, 100],
                            "e": [113, 113, 100]
                        },
                        {
                            "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
                            "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
                            "t": 22,
                            "s": [113, 113, 100],
                            "e": [100, 100, 100]
                        },
                        { "t": 25.0000010182709 }
                    ],
                    "ix": 6
                }
            },
            "ao": 0,
            "w": 800,
            "h": 800,
            "ip": 0,
            "op": 40.0000016292334,
            "st": 0,
            "bm": 0
        },
        {
            "ddd": 0,
            "ind": 2,
            "ty": 4,
            "nm": "Shape Layer 3",
            "sr": 1,
            "ks": {
                "o": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.833], "y": [0.833] },
                            "o": { "x": [0.167], "y": [0.167] },
                            "t": 16,
                            "s": [100],
                            "e": [0]
                        },
                        { "t": 36.0000014663101 }
                    ],
                    "ix": 11
                },
                "r": { "a": 0, "k": 0, "ix": 10 },
                "p": { "a": 0, "k": [400, 400, 0], "ix": 2 },
                "a": { "a": 0, "k": [0, 0, 0], "ix": 1 },
                "s": {
                    "a": 1,
                    "k": [
                        {
                            "i": { "x": [0.833, 0.833, 0.833], "y": [0.833, 0.833, 0.833] },
                            "o": { "x": [0.167, 0.167, 0.167], "y": [0.167, 0.167, 0.167] },
                            "t": 16,
                            "s": [113.344, 113.344, 100],
                            "e": [352.792, 352.792, 100]
                        },
                        { "t": 36.0000014663101 }
                    ],
                    "ix": 6
                }
            },
            "ao": 0,
            "shapes": [
                {
                    "ty": "gr",
                    "it": [
                        {
                            "d": 1,
                            "ty": "el",
                            "s": { "a": 0, "k": [205.703, 205.703], "ix": 2 },
                            "p": { "a": 0, "k": [0, 0], "ix": 3 },
                            "nm": "Ellipse Path 1",
                            "mn": "ADBE Vector Shape - Ellipse",
                            "hd": false
                        },
                        {
                            "ty": "st",
                            "c": { "a": 0, "k": [1, 1, 1, 1], "ix": 3 },
                            "o": { "a": 0, "k": 100, "ix": 4 },
                            "w": { "a": 0, "k": 2, "ix": 5 },
                            "lc": 1,
                            "lj": 1,
                            "ml": 4,
                            "bm": 0,
                            "nm": "Stroke 1",
                            "mn": "ADBE Vector Graphic - Stroke",
                            "hd": false
                        },
                        {
                            "ty": "fl",
                            "c": {
                                "a": 0,
                                "k": [0.921568632126, 0.96862745285, 1, 1],
                                "ix": 4
                            },
                            "o": { "a": 0, "k": 100, "ix": 5 },
                            "r": 1,
                            "bm": 0,
                            "nm": "Fill 1",
                            "mn": "ADBE Vector Graphic - Fill",
                            "hd": false
                        },
                        {
                            "ty": "tr",
                            "p": { "a": 0, "k": [-3.156, -1.609], "ix": 2 },
                            "a": { "a": 0, "k": [0, 0], "ix": 1 },
                            "s": { "a": 0, "k": [100, 100], "ix": 3 },
                            "r": { "a": 0, "k": 0, "ix": 6 },
                            "o": { "a": 0, "k": 100, "ix": 7 },
                            "sk": { "a": 0, "k": 0, "ix": 4 },
                            "sa": { "a": 0, "k": 0, "ix": 5 },
                            "nm": "Transform"
                        }
                    ],
                    "nm": "Ellipse 1",
                    "np": 3,
                    "cix": 2,
                    "bm": 0,
                    "ix": 1,
                    "mn": "ADBE Vector Group",
                    "hd": false
                }
            ],
            "ip": 16.0000006516934,
            "op": 36.0000014663101,
            "st": 0,
            "bm": 0
        }
    ],
    "markers": []
}
