import bs58 from 'bs58';

export type Multihash = {
    hash: string;
    hashFunction: number;
    size: number;
};

export const getMultihashStructFromCID = (bytes32: string) => {
    const decoded = bs58.decode(bytes32);

    return {
        hash: `0x${decoded.slice(2).toString('hex')}`,
        hashFunction: decoded[0] ?? 0,
        size: decoded[1] ?? 0,
    };
};

export const getCIDFromMultihashStruct = ({ hash, hashFunction, size }: Multihash) => {
    const hashBytes = Buffer.from(hash.slice(2), 'hex');

    const multihashBytes = Buffer.alloc(2 + hashBytes.length);
    multihashBytes[0] = hashFunction;
    multihashBytes[1] = size;
    multihashBytes.set(hashBytes, 2);

    return size != 0 ? bs58.encode(multihashBytes) : undefined;
};
