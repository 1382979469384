import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Badge, Box, HStack, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { BullaEventSourcedItemInfo, TAG_SEPARATOR } from '../../../data-lib/data-model';
import { ClaimEvent } from '../../../data-lib/data-transforms';
import { InstantPaymentEvent } from '../../../data-lib/domain/bulla-instant-pay-domain';
import { getFinancingSummaryLabel } from '../../../data-lib/dto/bulla-finance-dto';
import { FrendLendEvent, InstantPayContractEvent, MappedEventType } from '../../../data-lib/dto/mapped-event-types';
import {
    isClaim,
    isFinancedClaim,
    isFinancingAccepted,
    isVendorFinancingAcceptedClaim,
    sortBlocknumDesc,
    ZERO_BIGNUMBER,
} from '../../../data-lib/helpers';
import { NETWORKS, transactionUri } from '../../../data-lib/networks';
import { OffchainInvoiceInfo } from '../../../hooks/useOffchainInvoiceFactory';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { toDateWithTime } from '../../../tools/common';
import { AddressLabel } from '../../base/address-label';
import { TokenAmount } from '../../currency/token-display-amount';
import { TextButton } from '../../inputs/buttons';

const Line = () => (
    <Box ml="5px" w="px" pos="absolute" pt="12" pb="10" h="100%">
        <Box bg="scheme.accent_dark" h="100%" />
    </Box>
);

const ClaimEvent = ({ time, description, link }: { time?: Date | number; description: React.ReactNode; link?: string }) => (
    <HStack spacing="5" align="baseline">
        <Box w="3" h="3" bg="scheme.accent_dark" borderRadius="full" />
        <Stack>
            <HStack>
                <Text fontWeight="500">
                    {time instanceof Date ? toDateWithTime(time) : time ? `Block Number: ${time.toLocaleString('en-us')}` : description}
                </Text>

                {link && (
                    <TextButton onClick={() => window.open(link)} rightIcon={<ExternalLinkIcon />}>
                        View
                    </TextButton>
                )}
            </HStack>
            {time && description}
        </Stack>
    </HStack>
);

export const OffchainPaymentOrRescinded = ({ item }: { item: OffchainInvoiceInfo }) => {
    const { status } = item;

    if (status.kind === 'Paid') {
        const instantPaymentLogs = status.instantPayment.logs as InstantPaymentEvent[];
        return (
            <>
                {instantPaymentLogs.map((log, i) => (
                    <ClaimEvent
                        key={i}
                        time={log.blocktime}
                        description={
                            <HStack spacing="1">
                                <AddressLabel fontWeight="semibold">{log.from}</AddressLabel>
                                <Text>paid</Text>
                                <TokenAmount amount={log.amount} tokenInfo={item.tokenInfo} />
                            </HStack>
                        }
                        link={transactionUri(log.txHash, NETWORKS[item.chainId])}
                    />
                ))}
            </>
        );
    }

    if (status.kind === 'Rescinded') {
        return (
            <ClaimEvent
                key={status.toString()}
                time={status.cancelledDate}
                description={
                    <HStack spacing="1">
                        <Text>Claim rescinded by:</Text>
                        <AddressLabel fontWeight="semibold">{item.creditor}</AddressLabel>
                    </HStack>
                }
            />
        );
    }

    return null;
};

export const ViewLogModal = ({ item }: { item: BullaEventSourcedItemInfo }) => {
    const { userClaims } = useGlobalUserData('include-originating-claims');
    const _isClaim = isClaim(item);

    const translateLog = (log: MappedEventType) => {
        switch (log.__typename) {
            case 'BullaTagUpdatedEvent':
            case 'InstantPaymentTagUpdatedEvent':
                return log.tag ? (
                    <Wrap>
                        <Text>Categories updated to:</Text>
                        {log.tag.split(TAG_SEPARATOR).map((tag, i) => (
                            <WrapItem key={i}>
                                <Badge>{tag}</Badge>
                            </WrapItem>
                        ))}
                    </Wrap>
                ) : null;
            case 'ClaimCreatedEvent':
                return <Text>Claim created</Text>;
            case 'ClaimRejectedEvent':
                return (
                    <HStack>
                        <Text>Claim rejected by</Text>
                        <AddressLabel fontWeight="semibold">{item.debtor}</AddressLabel>
                    </HStack>
                );
            case 'ClaimRescindedEvent':
                return (
                    <HStack spacing="1">
                        <Text>Claim rescinded by:</Text>
                        <AddressLabel fontWeight="semibold">{item.creditor}</AddressLabel>
                    </HStack>
                );
            case 'ClaimPaymentEvent':
            case 'InstantPaymentEvent':
                return (
                    <HStack spacing="1">
                        <AddressLabel fontWeight="semibold">{log.__typename === 'ClaimPaymentEvent' ? log.paidBy : log.from}</AddressLabel>
                        <Text>paid</Text>
                        <TokenAmount
                            amount={log.__typename === 'ClaimPaymentEvent' ? log.paymentAmount : log.amount}
                            tokenInfo={item.tokenInfo}
                        />
                    </HStack>
                );
            case 'ClaimCreatedEvent':
                return <Text>Claim Created</Text>;
            case 'ERC721TransferEvent':
                return (
                    <HStack spacing="1">
                        <Text>Claim Transferred from</Text>
                        <AddressLabel fontWeight="semibold">{log.from}</AddressLabel>
                        <Text>to</Text>
                        <AddressLabel fontWeight="semibold">{log.to}</AddressLabel>
                    </HStack>
                );
            case 'FeePaidEvent':
                if (log.transactionFee.eq(ZERO_BIGNUMBER)) return null;
                return <Text>Platform Fee Paid</Text>;
            case 'FinancingOfferedEvent':
                return (
                    _isClaim &&
                    'financingState' in item &&
                    isFinancedClaim(item) && (
                        <Text>Financing offered: {getFinancingSummaryLabel(item.tokenInfo.token, item.financingState.terms)}</Text>
                    )
                );
            case 'FinancingAcceptedEvent':
                return <Text>Financing accepted</Text>;
            case 'LoanOfferedEvent':
                return <Text>Loan Offered</Text>;
            case 'LoanOfferAcceptedEvent':
                return <Text>Loan Accepted</Text>;
            case 'LoanOfferRejectedEvent':
                return (
                    <HStack>
                        <Text>Loan Rejected by: </Text>
                        <AddressLabel fontWeight="semibold">{log.rejectedBy}</AddressLabel>
                    </HStack>
                );
        }
    };

    const displayForEventLogs = (logs: ClaimEvent[] | InstantPayContractEvent[] | FrendLendEvent[]) => (
        <Stack spacing="10" pos="relative">
            <Line />
            {logs.sort(sortBlocknumDesc).map((log, i) => {
                const description = translateLog(log);
                if (description) {
                    return (
                        <ClaimEvent
                            time={log?.blocktime ?? log.blockNumber}
                            description={description}
                            link={transactionUri(log.txHash, NETWORKS[item.chainId])}
                            key={i + log.blockNumber + log.txHash}
                        />
                    );
                }
            })}
        </Stack>
    );

    return (
        <Box>
            <Stack>
                <Text color="gray.700" fontWeight="700" fontSize="24px" noOfLines={1}>
                    {item.__type === 'OffchainInvoiceInfo'
                        ? 'Invoice Logs'
                        : _isClaim && item.claimType
                        ? isFinancingAccepted(item)
                            ? 'Loan Logs'
                            : `${item.claimType} Logs`
                        : 'Payment Logs'}
                </Text>
            </Stack>
            <Box mt="2">
                {item.__type === 'OffchainInvoiceInfo' ? (
                    <Stack spacing="10" pos="relative">
                        <Line />
                        <OffchainPaymentOrRescinded item={item} />
                        <ClaimEvent time={item.created} description={<Text>Offchain Claim Created</Text>} />
                    </Stack>
                ) : (
                    <>
                        {item.logs && displayForEventLogs(item.logs)}
                        {_isClaim &&
                            isVendorFinancingAcceptedClaim(item) &&
                            (() => {
                                const originatingClaim = userClaims.find(
                                    x => x.chainId === item.chainId && item.financingState.origination.originatingClaimId === x.id,
                                );
                                return originatingClaim ? (
                                    <Box my="8">
                                        <Text fontWeight={700}>Logs from original invoice</Text>
                                        {displayForEventLogs(originatingClaim.logs)}
                                    </Box>
                                ) : undefined;
                            })()}
                    </>
                )}
            </Box>
        </Box>
    );
};
