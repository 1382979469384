import React, { PropsWithChildren } from 'react';
import { useSessionStorage } from './useStorage';
import { useActingWalletAddress } from './useWalletAddress';

type TemporaryCategoriesContext = { tempCategories: string[]; setTempCategories: React.Dispatch<React.SetStateAction<string[]>> };
const TemporaryCategoriesContext = React.createContext<TemporaryCategoriesContext>({ tempCategories: [], setTempCategories: () => {} });

export const TemporaryCategoriesProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const actingWallet = useActingWalletAddress();
    const [tempCategories, setTempCategories] = useSessionStorage<string[]>(`${actingWallet}#temporary-categories`, []);

    return (
        <TemporaryCategoriesContext.Provider value={React.useMemo(() => ({ tempCategories, setTempCategories }), [tempCategories.length])}>
            {children}
        </TemporaryCategoriesContext.Provider>
    );
};

export const useTemporaryCategories = () => {
    const context = React.useContext(TemporaryCategoriesContext);
    if (!context) throw new Error('Error: you must call useTemporaryCategories with the TemporaryCategoriesProvider');
    return context;
};
