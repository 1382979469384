import { HStack, Text } from '@chakra-ui/layout';
import { ChakraProps, Image } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import React from 'react';
import { WarningIconWithTooltip } from '../../assets/warning';
import { weiToDisplayAmt } from '../../data-lib/ethereum';
import { TokenInfo } from '../../data-lib/networks';
import { ExternalLinkIconButton, TOKEN_ROUNDING } from '../../data-lib/tokens';
import { useTokenSafety } from '../../hooks/useTokenSafety';
import { toUSD } from '../../tools/common';

export const TokenAmount = ({
    amount,
    tokenInfo,
    withIcon,
    isDisplayAmount,
    withRounding,
    removeSymbol,
    withLink,
    checkSafety,
    ...props
}: {
    amount: BigNumber | number;
    tokenInfo: TokenInfo;
    withIcon?: boolean;
    withRounding?: boolean;
    isDisplayAmount?: boolean;
    removeSymbol?: boolean;
    withLink?: boolean;
    checkSafety?: boolean;
} & ChakraProps) => {
    const amountSafe = isDisplayAmount ? amount : weiToDisplayAmt({ amountWei: amount, token: tokenInfo.token });

    const { isTokenSafe } = useTokenSafety();
    const isSafe = React.useMemo(() => isTokenSafe(tokenInfo.chainId, tokenInfo.token.address), []);

    return (
        <HStack h="24px" {...props}>
            {withIcon && <Image src={tokenInfo.icon ?? ''} h="100%" />}
            <Text>
                {toUSD(amountSafe, !withRounding, (withRounding && TOKEN_ROUNDING[tokenInfo.variant]) || undefined).replace('$', '')}
            </Text>
            {!removeSymbol && (
                <Text textOverflow={'ellipsis'} noOfLines={1}>
                    {tokenInfo.token.symbol}
                </Text>
            )}
            {!tokenInfo.token.isNative && withLink && <ExternalLinkIconButton token={tokenInfo} />}
            {!isSafe && checkSafety && (
                <WarningIconWithTooltip
                    label="You have sent/received this token in the past. Beware of using airdropped scam tokens."
                    warningOverrides={{ color: 'brand.bulla_yellow', w: '14px', h: '14px' }}
                />
            )}
        </HStack>
    );
};
