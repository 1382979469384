import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseModalButton } from './common';

export type AreYouSureModalProps = {
    triggerElement: (onOpen: () => void) => React.ReactNode;
    title: string;
    message: string;
    onClick: () => Promise<void> | void;
    buttonText: string;
};

export const AreYouSureModal = ({ triggerElement, title, message, onClick: _onClick, buttonText }: AreYouSureModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [inProgress, setInProgress] = useState(false);

    const onClick = async () => {
        setInProgress(true);
        try {
            await Promise.resolve(_onClick());
        } finally {
            setInProgress(false);
            onClose();
        }
    };

    return (
        <>
            {triggerElement(onOpen)}
            <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="md" closeOnEsc scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent py="4" px="2" maxH="80%">
                    <CloseModalButton onClose={onClose} />
                    <ModalBody pb={6} pt={6}>
                        <Text color="icon_dark" textStyle="labelLg">
                            {title}
                        </Text>
                        <Box pt={6}>
                            <Text>{message}</Text>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onClose}>
                            Cancel
                        </Button>
                        <Spacer />
                        <Button px="8" py="6" fontWeight="500" colorScheme="red" type="submit" isLoading={inProgress} onClick={onClick}>
                            {buttonText}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
