import { ArrowForwardIcon, CheckIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    chakra,
    Collapse,
    Divider,
    Fade,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Spinner,
    Stack,
    Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { ContactNameOrAddress } from '../../components/base/address-label';
import { TextButton } from '../../components/inputs/buttons';
import { addressEquality, EthAddress } from '../../data-lib/ethereum';
import { cacheSafeAddress, getGnosisSafeURL, isModuleOutdated } from '../../data-lib/gnosis-tools/gnosis';
import { ChainId, changeNetwork, NETWORKS } from '../../data-lib/networks';
import { useCanChangeNetwork } from '../../hooks/useCanChangeNetwork';
import { useWeb3 } from '../../hooks/useWeb3';
import { useGnosisSafe } from '../../state/gnosis-state';
import { toDateDisplay } from '../../tools/common';
import { Require } from '../../tools/types';
import { SlideProps } from './onboard';

export type SortedSafeInfo = {
    safeAddress: string;
    retry: boolean;
    chainId: ChainId;
    lastUsed?: number;
    name?: string;
};

export const GnosisSafeConnect = ({ finish, completeStep }: Require<SlideProps, 'completeStep' | 'finish'>) => {
    const { provider, userAddress, connectedNetworkConfig, connectedNetwork } = useWeb3();
    const canChangeNetwork = useCanChangeNetwork();
    const { fetchSafeAppInfo, loading, error, safeInfo, userSafes, fetchUserSafes } = useGnosisSafe();
    const [selectedSafeAddress, _setSafeAddress] = React.useState<string | undefined>(safeInfo?.safeAddress ?? '');
    const [safeName, setSafeName] = React.useState<string | undefined>();
    const isSafeOwner = safeInfo?.owners?.some(address => addressEquality(address, userAddress));
    const safesLoaded = typeof userSafes === 'object';
    const [retry, setRetry] = React.useState(false);

    const needsConfiguration = !safeInfo?.module?.moduleEnabled || isModuleOutdated(safeInfo.module.moduleVersion);
    const sortedSafeArrayById = safesLoaded
        ? Object.entries(userSafes)
              .map(([chainId, safeCaches]) => ({
                  chainId: +chainId,
                  safes: Object.entries(safeCaches)
                      .map(([safeAddress, otherStuff]) => ({ ...otherStuff, safeAddress, retry: false }))
                      .sort((a_safeInfo, b_safeInfo) => (b_safeInfo?.lastUsed ?? 0) - (a_safeInfo?.lastUsed ?? -1)),
              }))
              .filter(({ safes }) => safes.length !== 0)
              .sort((chainSafesA, chainSafesB) =>
                  chainSafesB.chainId == connectedNetwork
                      ? 999999999999999999999
                      : chainSafesA.chainId == connectedNetwork
                      ? -999999999999999999999
                      : (chainSafesB.safes[0].lastUsed ?? 0) - (chainSafesA.safes[0].lastUsed ?? 0),
              )
        : [];

    const sortedSafeArray: SortedSafeInfo[] = sortedSafeArrayById.flatMap(({ safes }) => safes);

    const handleChangeSafeAddress = (e: ChangeEvent<HTMLInputElement>) => setSafeAddress(e.target.value.trim());
    const handleSafeName = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length < 26) setSafeName(value);
    };
    const setSafeAddress = (safeAddress: EthAddress) => {
        _setSafeAddress(safeAddress);
        fetchSafeAppInfo(safeAddress);
    };
    const handleEnter = () => {
        if (selectedSafeAddress) {
            setRetry(false);
            cacheSafeAddress(connectedNetworkConfig.chainId, userAddress, selectedSafeAddress, safeName);
            if (needsConfiguration) completeStep();
            else finish();
        }
    };

    useEffect(() => {
        if (error && selectedSafeAddress) {
            setRetry(true);
        } else {
            setRetry(false);
        }
    }, [error, selectedSafeAddress]);

    useEffect(() => {
        selectedSafeAddress && fetchSafeAppInfo(selectedSafeAddress);
        fetchUserSafes();
    }, [provider, userAddress]);

    return (
        <Stack align="center">
            <Text textStyle="listTitle">Connect to a Gnosis Safe</Text>
            <Box h="2" />
            <chakra.label fontWeight={600} w="96">
                Safe Address
            </chakra.label>
            <InputGroup w="96">
                <Input onChange={handleChangeSafeAddress} value={selectedSafeAddress} placeholder="0x..." />
                <InputRightElement>{loading ? <Spinner /> : isSafeOwner ? <CheckIcon color={'green'} /> : null}</InputRightElement>
            </InputGroup>
            <Box h="2" />
            <Collapse in={!!selectedSafeAddress && !!isSafeOwner}>
                <Stack align={'center'} w="96" spacing="0">
                    <HStack w="100%">
                        <chakra.label whiteSpace={'nowrap'} textAlign={'left'} fontWeight={600} htmlFor="safeName">
                            {safesLoaded && selectedSafeAddress && !userSafes[connectedNetwork][selectedSafeAddress]?.name
                                ? 'Name this safe (optional):'
                                : 'Safe name:'}
                        </chakra.label>
                        <InputGroup>
                            <Input
                                w="100%"
                                id="safeName"
                                key={selectedSafeAddress}
                                onChange={handleSafeName}
                                value={
                                    safeName ??
                                    (safesLoaded && selectedSafeAddress ? userSafes[connectedNetwork][selectedSafeAddress]?.name ?? '' : '')
                                }
                            />
                            <Fade in={!!safeName?.length} unmountOnExit>
                                <InputRightElement>
                                    <IconButton aria-label="delete" icon={<CloseIcon />} fontSize="xs" onClick={() => setSafeName('')} />
                                </InputRightElement>
                            </Fade>
                        </InputGroup>
                    </HStack>
                    <Box h="6" />
                    <TextButton rightIcon={<ArrowForwardIcon />} onClick={handleEnter} isDisabled={loading} w="fit-content">
                        {!needsConfiguration ? 'Enter App' : 'Next'}
                    </TextButton>
                </Stack>
            </Collapse>
            <Fade in={loading || !!error}>
                {error ? (
                    <Text fontSize="xs" color="red">
                        {error}
                    </Text>
                ) : (
                    <Text fontSize="xs" color="gray.700">
                        {_.isEmpty(userSafes) ? 'Fetching safes...' : 'Fetching safe info...'}
                    </Text>
                )}
            </Fade>
            {userSafes && !_.isEmpty(userSafes) && (
                <Grid templateColumns={'1fr, 1fr, 1fr, fit-content)'} columnGap="8" rowGap="2" p="4">
                    <Text fontWeight={500}>Your safes</Text>
                    <Text fontWeight={500}>{sortedSafeArray.some(safeInfo => !!safeInfo?.name) && 'Name'}</Text>

                    <GridItem colSpan={2}>
                        <Text fontWeight={500}>{sortedSafeArray.some(safeInfo => !!safeInfo?.lastUsed) && 'Last used'}</Text>
                    </GridItem>
                    <GridItem colSpan={4}>
                        <Divider />
                    </GridItem>
                    {sortedSafeArrayById.map(({ chainId, safes }) => (
                        <>
                            {safes.map(({ safeAddress, lastUsed, name }, i) => (
                                <React.Fragment key={`${chainId}-${i}`}>
                                    <HStack>
                                        <ContactNameOrAddress chainId={chainId as ChainId}>{safeAddress}</ContactNameOrAddress>
                                        <Link href={getGnosisSafeURL(NETWORKS[chainId], safeAddress)} isExternal>
                                            <Box color={'scheme.accent_dark'}>
                                                <ExternalLinkIcon mt="-4px" />
                                            </Box>
                                        </Link>
                                    </HStack>
                                    <Text whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
                                        {name ?? ''}
                                    </Text>
                                    <Text whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
                                        {lastUsed ? toDateDisplay(new Date(lastUsed)) : null}
                                    </Text>
                                    <Button
                                        w="fit-content"
                                        fontSize="xs"
                                        onClick={() => {
                                            if (error && safeAddress === selectedSafeAddress && retry) {
                                                setRetry(false);
                                                fetchSafeAppInfo(safeAddress);
                                            } else {
                                                chainId === connectedNetwork
                                                    ? setSafeAddress(safeAddress)
                                                    : changeNetwork(chainId, provider);
                                            }
                                        }}
                                        isDisabled={
                                            (safeAddress === selectedSafeAddress && !retry) ||
                                            (chainId !== connectedNetwork && !canChangeNetwork)
                                        }
                                    >
                                        {error && safeAddress === selectedSafeAddress && retry
                                            ? 'Retry'
                                            : chainId === connectedNetwork
                                            ? 'Select'
                                            : `Connect to ${NETWORKS[chainId].label}`}
                                    </Button>
                                </React.Fragment>
                            ))}
                            <GridItem key={`${chainId} first spacer`} colSpan={4} rowSpan={2} />
                        </>
                    ))}
                </Grid>
            )}
        </Stack>
    );
};
