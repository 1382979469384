import { FrendLend } from '@bulla-network/contracts/typechain/FrendLend';
import { BigNumber, BigNumberish } from 'ethers';
import _ from 'lodash';
import {
    MultisendAcceptLoanTransaction,
    MultisendOfferLoanTransaction,
    NonPayingMultisendTransaction,
} from '../../hooks/useGnosisMultisend';
import { FrendLendOffer } from '../data-model';
import { addressEquality, EthAddress, toChecksumAddress, weiToDisplayAmt } from '../ethereum';
import { daysToSeconds, toBytes32 } from '../helpers';
import { BullaClaimDto } from './bulla-claim-dto';
import { FinancingTerms, getFinancingSummaryLabel, termLengthToDays } from './bulla-finance-dto';
import { IFrendLend } from './contract-interfaces';

type FrendLendOfferParams = {
    terms: FinancingTerms;
    claimParams: Omit<BullaClaimDto, 'tags' | 'tokenURI' | 'dueBy' | 'claimAmount'>;
};

const termsAndParamsToContractInteraction = ({ terms, claimParams }: FrendLendOfferParams) => ({
    interestBPS: terms.interestRateBPS,
    loanAmount: terms.loanValue,
    creditor: claimParams.creditor,
    debtor: claimParams.debtor,
    description: claimParams.description,
    claimToken: claimParams.token.address,
    attachment: claimParams.ipfsHash,
    termLength: daysToSeconds(termLengthToDays(terms.termLength)),
});

export const getOfferLoanTransaction = (_frendLendAddress: EthAddress, originationFee: BigNumber, params: FrendLendOfferParams) => ({
    to: toChecksumAddress(_frendLendAddress),
    value: originationFee.toString(),
    operation: 0,
    data: IFrendLend.encodeFunctionData('offerLoan', [termsAndParamsToContractInteraction(params)]),
});

export const getAcceptLoanOfferTransaction = (_frendLendAddress: EthAddress, offerId: BigNumberish, tokenURI: string, tag?: string) => ({
    to: toChecksumAddress(_frendLendAddress),
    value: '0',
    operation: 0,
    data: IFrendLend.encodeFunctionData('acceptLoan', [offerId, tokenURI, tag ?? toBytes32('')]),
});

export const getRejectLoanOfferTransaction = (_frendLendAddress: EthAddress, offerId: BigNumberish) => ({
    to: toChecksumAddress(_frendLendAddress),
    value: '0',
    operation: 0,
    data: IFrendLend.encodeFunctionData('rejectLoanOffer', [offerId]),
});

export const buildGetLoanOfferMultisendTx = (
    bullaFinanceAddress: string,
    originationFee: BigNumber,
    params: FrendLendOfferParams,
    requiredAllowance: BigNumber,
): MultisendOfferLoanTransaction => ({
    label: `offer ${weiToDisplayAmt({ amountWei: params.terms.principalAmount, token: params.claimParams.token })} ${
        params.claimParams.token.symbol
    } loan to ${params.claimParams.debtor}`,
    transactionInput: getOfferLoanTransaction(bullaFinanceAddress, originationFee, params),
    itemInfo: {
        __type: 'FrendLend',
        id: '-1', // no id yet
        description: params.claimParams.description,
        debtor: params.claimParams.debtor,
        creditor: params.claimParams.creditor,
    },
    token: params.claimParams.token,
    approvalNeeded: {
        amount: requiredAllowance,
        spendingContract: bullaFinanceAddress,
    },
    interaction: 'Offer Loan',
});

export const buildAcceptLoanMultisendTx = (
    bullaFinanceAddress: string,
    loanId: BigNumberish,
    offer: FrendLendOffer,
): MultisendAcceptLoanTransaction => ({
    interaction: 'Accept Loan',
    label: `Accept ${getFinancingSummaryLabel(offer.tokenInfo.token, { loanValue: offer.loanAmount, interestRateBPS: offer.interestBPS })}`,
    transactionInput: getAcceptLoanOfferTransaction(bullaFinanceAddress, loanId, offer.ipfsHash),
    itemInfo: { ...offer, id: offer.loanId },
});

export const buildRejectLoanMultisendTx = (
    bullaFinanceAddress: string,
    loanId: BigNumberish,
    offer: FrendLendOffer,
    rejectingUser: EthAddress,
): NonPayingMultisendTransaction => {
    const interaction = addressEquality(rejectingUser, offer.debtor) ? 'Reject Loan Offer' : 'Rescind Loan Offer';
    return {
        interaction,
        label: _.capitalize(interaction.toLowerCase()) + ' #' + loanId,
        transactionInput: getRejectLoanOfferTransaction(bullaFinanceAddress, loanId),
        itemInfo: { ...offer, id: offer.loanId },
    };
};

export const createFrendLendOffer = ({
    contract,
    terms,
    claimParams,
    originationFee,
}: FrendLendOfferParams & {
    contract: FrendLend;
    originationFee: BigNumber;
}) => contract.offerLoan(termsAndParamsToContractInteraction({ terms, claimParams }), { value: originationFee });

export const acceptFrendLendOffer = ({ tokenURI, loanId, contract }: { tokenURI: string; loanId: BigNumberish; contract: FrendLend }) =>
    contract.acceptLoan(loanId, tokenURI, toBytes32(''));

export const rejectFrendLendOffer = ({ loanId, contract }: { loanId: BigNumberish; contract: FrendLend }) =>
    contract.rejectLoanOffer(loanId);
