import { ArrowForwardIcon, CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Center, HStack, IconButton, Link, Tag, Text, Tooltip, useClipboard, useToast } from '@chakra-ui/react';
import React from 'react';
import { GnosisSafeIcon, WalletIcon } from '../../assets/svgs';
import { addressEquality, EthAddress, isValidAddress } from '../../data-lib/ethereum';
import { addressUri, ChainId, NETWORKS } from '../../data-lib/networks';
import { isContactsReady, useExtendedContacts } from '../../hooks/useExtendedContacts';
import { useNamingService } from '../../hooks/useNamingService';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { useGnosisSafe } from '../../state/gnosis-state';
import { ActionPopover, PopoverAction, ThreeDotsButton } from '../display/actionPopover';
import { CreateContactModal } from '../modals/create-contact-modal';
import CopyElement from './copy-element';
import { WithSkeleton } from './skeleton';

export const shortAddress = (ethAddress: EthAddress, leftAndRightCharCount?: number) => {
    if (isValidAddress(ethAddress)) {
        return truncateString(ethAddress, leftAndRightCharCount);
    } else if (ethAddress.includes('@')) {
        return truncateEmailOrContractName(ethAddress, leftAndRightCharCount);
    } else {
        return ethAddress;
    }
};

export const truncateEmailOrContractName = (email: string, maxLength: number = 9) => {
    return email.length > maxLength ? `${email.slice(0, maxLength)}...` : email;
};

export const truncateString = (string: string, leftAndRightCharCount: number = 4) =>
    `${string.slice(0, leftAndRightCharCount + 1)}...${string.slice(-leftAndRightCharCount)}`;

type CopyableTextProps = BoxProps & {
    children: string;
    displayValue?: string;
    variant?: 'address';
    withIcon?: boolean;
    iconOnly?: Boolean;
    light?: Boolean;
    disableTooltip?: boolean;
    isDisabled?: boolean;
};

export const YouBadge = ({ type }: { type: 'eoa' | 'safe' }) => (
    <Tag colorScheme={'green'}>
        <HStack>
            {type === 'safe' ? <GnosisSafeIcon height={'14px'} width={'14px'} /> : <WalletIcon height="14px" width="14px" />}
            <Text fontWeight={600}>{type === 'safe' ? 'Safe' : 'You'}</Text>
        </HStack>
    </Tag>
);

export const EmailAddressLabel = ({
    children: emailAddress,
    ...props
}: Partial<CopyableTextProps> & { children: React.ReactNode & string }) => <CopyableTextLabel children={emailAddress} {...props} />;

export const CopyableTextLabel = React.memo(
    ({
        children,
        displayValue,
        variant,
        disableTooltip = false,
        iconOnly,
        withIcon = true,
        light,
        isDisabled,
        ...props
    }: CopyableTextProps) => {
        return (
            <HStack {...props} spacing="2px">
                {!iconOnly && (
                    <Tooltip label={children} fontSize="sm" placement="top" variant={variant} isDisabled={disableTooltip}>
                        <Text textStyle="noWrap" color={light ? 'white' : 'accent'}>
                            {!!displayValue ? displayValue : children}
                        </Text>
                    </Tooltip>
                )}
                {!!withIcon && (
                    <CopyElement valueToCopy={children} light={!!light} isDisabled={isDisabled}>
                        <CopyIcon mt="-2px" />
                    </CopyElement>
                )}
            </HStack>
        );
    },
);

export const Bytes32Label = ({ bytes32, ...props }: Partial<CopyableTextProps> & { bytes32: string }) => (
    <CopyableTextLabel displayValue={bytes32 && truncateString(bytes32)} children={bytes32} {...props} />
);

type AddressLabelProps = Partial<CopyableTextProps> & {
    children: React.ReactNode & string;
    charCount?: number;
    link?: { chainId: ChainId };
};

export const AddressLabel = ({ children: walletAddress, charCount, link, ...props }: AddressLabelProps) => (
    <HStack spacing="0">
        <CopyableTextLabel
            displayValue={walletAddress && shortAddress(walletAddress, charCount)}
            children={walletAddress}
            variant="address"
            {...props}
        />
        {link && (
            <IconButton
                aria-label="inspect address"
                as={Link}
                icon={<ExternalLinkIcon />}
                mt="-4px"
                href={addressUri(walletAddress, NETWORKS[link.chainId])}
                isExternal
                variant="ghost"
                size="xs"
            />
        )}
    </HStack>
);

export function useYouBadge(walletAddress: string, excludeYouBadge?: boolean) {
    if (excludeYouBadge) return 'not-you';
    const { userAddress } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();

    if (addressEquality(walletAddress, userAddress)) return <YouBadge type="eoa" />;
    if (connectedSafeAddress && addressEquality(walletAddress, connectedSafeAddress)) return <YouBadge type="safe" />;
    return 'not-you';
}

export function useAddressNameLabel() {
    const contactsContext = useExtendedContacts();
    const { findNameForAddress } = useNamingService();

    if (contactsContext === 'fetching') return 'fetching';

    return (walletAddress: string, chainId: ChainId) => {
        const contactNameOrUndefined = isContactsReady(contactsContext) ? contactsContext.getContact(walletAddress) : 'not-found';

        if (contactNameOrUndefined != 'not-found')
            return (
                <Text noOfLines={1} textOverflow={'ellipsis'} fontSize="14px" fontWeight={700}>
                    {contactNameOrUndefined.name}
                </Text>
            );
        else {
            const maybeSmartContractName = findNameForAddress(walletAddress, chainId);
            return ['not-found', 'fetching'].includes(maybeSmartContractName) ? (
                'not-found'
            ) : (
                <Text noOfLines={1} textOverflow={'ellipsis'} fontSize="14px" fontWeight={700}>
                    {maybeSmartContractName == 'unverified' ? 'Unverified Contract' : truncateEmailOrContractName(maybeSmartContractName)}
                </Text>
            );
        }
    };
}

type ContactNameOrAddressProps = CopyableTextProps & {
    showBoth?: boolean;
    hideAddressIfFound?: boolean;
    link?: { chainId: ChainId };
    chainId: ChainId;
    excludeYouBadge?: boolean;
    removeParenthesis?: boolean;
};

const ConcatContactLabelAndAddressLabel = ({
    contactLabel,
    addressLabel,
}: {
    contactLabel: React.ReactNode;
    addressLabel: React.ReactNode;
}) => (
    <HStack spacing="0">
        {contactLabel}
        <Box w="2" />
        <Text>{'('}</Text>
        {addressLabel}
        <Text m="0">{')'}</Text>
    </HStack>
);

type CopyPopoverActionProps = {
    valueToCopy: string;
    text: string;
};

export const CopyPopoverAction = ({ valueToCopy, text }: CopyPopoverActionProps) => {
    const toast = useToast();
    const { onCopy } = useClipboard(valueToCopy, 1000);

    const handleCopy = () => {
        onCopy();
        setTimeout(
            () =>
                toast({
                    description: (
                        <Center textAlign={'center'}>
                            {`${valueToCopy.slice(0, 30)}...`} <br /> copied to clipboard
                        </Center>
                    ),
                    duration: 5000,
                    status: 'success',
                    position: 'top',
                    isClosable: true,
                }),
            500,
        );
    };

    return (
        <PopoverAction onClick={handleCopy}>
            <Text>{text}</Text>
        </PopoverAction>
    );
};

export const ContactNameOrAddress = ({
    children: walletOrEmailAddress,
    withIcon,
    hideAddressIfFound,
    showBoth,
    link,
    chainId,
    excludeYouBadge,
    removeParenthesis,
    ...props
}: ContactNameOrAddressProps) => {
    const getContactNameLabel = useAddressNameLabel();
    const youBadge = useYouBadge(walletOrEmailAddress, excludeYouBadge);
    const addressLabel = (
        <AddressLabel withIcon={withIcon} {...props}>
            {walletOrEmailAddress}
        </AddressLabel>
    );
    const contactsContext = useExtendedContacts();
    const contactsReady = isContactsReady(contactsContext);
    const contactNameOrUndefined = contactsReady ? contactsContext.getContact(walletOrEmailAddress as string) : 'not-found';

    if (youBadge !== 'not-you') {
        if (hideAddressIfFound) return youBadge;
        return <ConcatContactLabelAndAddressLabel contactLabel={youBadge} addressLabel={addressLabel} />;
    }
    if (getContactNameLabel == 'fetching')
        return (
            <WithSkeleton isLoading={true} fixedWidth="50px">
                <Text>Can't see me</Text>
            </WithSkeleton>
        );
    else {
        const contactLabel = getContactNameLabel(walletOrEmailAddress, chainId);

        const isEmailAddress = walletOrEmailAddress.includes('@');
        const editState =
            contactLabel == 'not-found'
                ? {
                      name: '',
                      groups: [],
                      ...(isEmailAddress
                          ? { emailAddress: walletOrEmailAddress, walletAddress: '' }
                          : { walletAddress: walletOrEmailAddress }),
                  }
                : contactNameOrUndefined != 'not-found'
                ? contactNameOrUndefined
                : undefined;

        const actionText = contactLabel == 'not-found' ? 'Add contact' : 'Edit contact';

        const actions = [
            <CopyPopoverAction valueToCopy={walletOrEmailAddress as string} text={`Copy ${isEmailAddress ? 'email ' : ''}address`} />,
            contactsReady && (
                <CreateContactModal
                    {...(isEmailAddress ? { defaults: editState } : { editState })}
                    triggerElement={onOpen => <PopoverAction onClick={onOpen}>{actionText}</PopoverAction>}
                />
            ),
        ];

        if (!(showBoth ?? true) || hideAddressIfFound) {
            return (
                <HStack spacing="2px">
                    <Tooltip variant={'address'} label={walletOrEmailAddress} placement="top">
                        {contactLabel == 'not-found' ? shortAddress(walletOrEmailAddress) : contactLabel}
                    </Tooltip>
                    <ActionPopover
                        actions={actions}
                        triggerElement={
                            <a>
                                <ThreeDotsButton />
                            </a>
                        }
                    />
                </HStack>
            );
        }
        return contactLabel === 'not-found' && removeParenthesis ? (
            <HStack spacing="2px">
                {contactLabel}
                {addressLabel}
            </HStack>
        ) : (
            <ConcatContactLabelAndAddressLabel contactLabel={contactLabel} addressLabel={addressLabel} />
        );
    }
};

type FromAndToWalletProps = {
    chainId: ChainId;
    from: string;
    to: string;
};
export const FromAndToWallet = ({ chainId, from, to }: FromAndToWalletProps) => {
    return (
        <HStack width="100%" maxWidth="inherit">
            <ContactNameOrAddress chainId={chainId} hideAddressIfFound>
                {from}
            </ContactNameOrAddress>
            <ArrowForwardIcon />
            <ContactNameOrAddress chainId={chainId} hideAddressIfFound>
                {to}
            </ContactNameOrAddress>
        </HStack>
    );
};

type TXHashLabelProps = {
    blockExplorerURL: string;
    txHash: string;
};

export const TXHashLabel = ({ txHash, blockExplorerURL, ...props }: TXHashLabelProps & BoxProps) => (
    <HStack>
        <CopyableTextLabel displayValue={truncateString(txHash)} {...props} children={txHash} />
        <IconButton
            as={Link}
            href={`${blockExplorerURL}tx/${txHash}`}
            isExternal
            icon={<ExternalLinkIcon />}
            aria-label="Open in explorer"
            size="l"
            variant="ghost"
        />
    </HStack>
);
