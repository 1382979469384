import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { SetStateAction } from 'react';
import { addressEquality } from '../../../../data-lib/ethereum';
import { allNetworks, ChainId, TokenInfo } from '../../../../data-lib/networks';
import { TokenDisplay } from '../../../../data-lib/tokens';
import { usePrevious } from '../../../../hooks/usePrevious';
import { useGnosisSafe } from '../../../../state/gnosis-state';
import { ChainSymbol } from '../../../chain-symbol';
import { TableFilters } from '../../search/search';
import { NetworkDropdown } from '../../wallet-info';
import { PaymentTableItem } from '../payments';
import { checkDate, filterButtonProps } from './claim-filter';
import { DateSelect, SearchFilter, SetDate } from './common';

export type NetFlowsFilterValues = {
    search: string | undefined;
    date: { startDate?: Date; endDate?: Date };
    type: 'Invoice' | 'Payment' | 'All' | undefined;
    chain: ChainId;
    tokenInfo: TokenInfo | undefined;
};

export const emptyFilterValues = (connectedNetwork: ChainId): NetFlowsFilterValues => ({
    search: '',
    date: { startDate: undefined, endDate: undefined },
    type: undefined,
    chain: connectedNetwork,
    tokenInfo: undefined,
});

type SetNetFlowsFilter = (key: keyof NetFlowsFilterValues, value: NetFlowsFilterValues[keyof NetFlowsFilterValues]) => void;

export const buildFilters = (filterValues: NetFlowsFilterValues) => {
    const {
        search,
        date: { startDate, endDate },
        chain,
        tokenInfo,
    } = filterValues;
    const filterByText = !!search
        ? (payment: PaymentTableItem) => {
              if (!search) return true;
              const filterParameters = [
                  ...payment.tags,
                  payment.id,
                  payment.creditor,
                  payment.debtor,
                  payment.paidAmount,
                  payment.description,
                  payment.notes,
              ];
              return filterParameters.some(value => String(value).toLowerCase().includes(search.trim().toLowerCase()));
          }
        : undefined;

    const filterByDate =
        !startDate && !endDate ? undefined : ({ paymentTimestamp }: PaymentTableItem) => checkDate(paymentTimestamp, startDate, endDate);

    const filterByChain = !!chain ? ({ chainId }: PaymentTableItem) => chainId === chain : undefined;

    const filterByToken = !!tokenInfo
        ? ({ tokenInfo: claimTokenInfo }: PaymentTableItem) => addressEquality(claimTokenInfo.token.address, tokenInfo.token.address)
        : undefined;
    return [filterByText, filterByDate, filterByChain, filterByToken].filter(Boolean) as TableFilters;
};

type FilterOptionsProps = {
    chains: ChainId[];
    paymentTimestamps: Date[];
    firstPaymentTimestamp: Date;
    lastPaymentTimestamp: Date;
    tokens: TokenInfo[];
    tags: string[];
};

export const NetFlowsFilter = ({
    filtersOptions,
    setFilters,
    filters,
}: {
    filtersOptions: FilterOptionsProps;
    filters: NetFlowsFilterValues;
    setFilters: React.Dispatch<SetStateAction<NetFlowsFilterValues>>;
}) => {
    const previousChain = usePrevious(filters.chain);
    const { safeInfo } = useGnosisSafe();
    const setFilter: SetNetFlowsFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const setToken = (tokenInfo: TokenInfo | undefined) => {
        setFilter('tokenInfo', tokenInfo);
    };
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));
    React.useEffect(() => {
        if (filters.tokenInfo == undefined || filters.chain !== previousChain) {
            setToken(filtersOptions.tokens.length !== 0 ? filtersOptions.tokens[0] : undefined);
        }
    }, [filters.tokenInfo == undefined, filtersOptions.tokens.length, filters.chain]);

    return (
        <Box w="100%" pb="4">
            <HStack>
                <SearchFilter value={filters.search} setValue={str => setFilter('search', str)} />
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={filtersOptions.paymentTimestamps}
                />
                {!safeInfo && (
                    <Menu>
                        <MenuButton as={Button} {...filterButtonProps} rightIcon={<ChevronDownIcon />} isDisabled={safeInfo}>
                            <ChainSymbol chainId={filters.chain} />
                        </MenuButton>
                        <NetworkDropdown chains={allNetworks} onSelect={x => setFilter('chain', x)} />
                    </Menu>
                )}

                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        {...filterButtonProps}
                        isDisabled={filtersOptions.tokens.length == 0}
                    >
                        {filters.tokenInfo ? <TokenDisplay token={filters.tokenInfo} /> : 'Token'}
                    </MenuButton>
                    <MenuList>
                        {filtersOptions.tokens.map((token, key) => (
                            <MenuItem key={key} onClick={() => setToken(token)}>
                                <TokenDisplay token={token} />
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </HStack>
        </Box>
    );
};
