import { Box, Fade, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIcon } from '../../assets/warning';
import { PopoverAction } from '../../components/display/actionPopover';
import { ResponsiveStack } from '../../components/display/responsive-stack';
import { NetworkLoadingSpinner, ViewNetworkSelector } from '../../components/display/view-network-selector';
import { GettingStartedCard } from '../../components/display/views/getting-started';
import { ActionDropdown, LinkButton, TextButton } from '../../components/inputs/buttons';
import { InfoSnippetCard } from '../../components/layout/cards';
import { MaxWidthWrapper, PageLayoutProvider, SummaryPanel } from '../../components/layout/page-layout';
import { CreateClaimModal } from '../../components/modals/create-claim-modal/create-claim-modal';
import { CreateItemLinkHandler } from '../../components/modals/create-claim-modal/create-link-handler';
import { isClaim } from '../../data-lib/helpers';
import { useTokenPrices } from '../../hooks/useChainData';
import { useGettingStarted } from '../../hooks/useGettingStarted';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useDataReadiness, useGlobalUserData } from '../../hooks/useUserData';
import { useUserSummary } from '../../hooks/useUserSummary';
import { useAppState } from '../../state/app-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { inIframe, toDateDisplay } from '../../tools/common';
import { AccountTags, PaymentsMade, PendingClaims, PendingSafePayments, ReceivedPayments } from './home-cards';

const SummaryCards = () => {
    const { payableTotals, receivableTotals, netBalance } = useUserSummary();
    const tokenPrices = useTokenPrices();
    const navigate = useNavigate();
    const isLoading = isEmpty(tokenPrices);
    const overdueCount = {
        payables: {
            invoices: payableTotals.overdue.filter(p => p.claimType === 'Invoice'),
            payments: payableTotals.overdue.filter(p => p.claimType === 'Payment'),
        },
        receivables: {
            invoices: receivableTotals.overdue.filter(p => p.claimType === 'Invoice'),
            payments: receivableTotals.overdue.filter(p => p.claimType === 'Payment'),
        },
    };

    const pendingPayableInvoicesCount = payableTotals.invoices.filter(x => x.claimStatus == 'Pending').length;
    const pendingPayablePaymentsCount = payableTotals.payments.filter(x => x.claimStatus == 'Pending').length;

    const pendingReceivableInvoicesCount = receivableTotals.invoices.filter(x => x.claimStatus == 'Pending').length;
    const pendingReceivablePaymentsCount = receivableTotals.payments.filter(x => x.claimStatus == 'Pending').length;

    return (
        <ResponsiveStack justify="center" spacing="3" py="4">
            <InfoSnippetCard
                isLoading={isLoading}
                isCurrency
                title="Total Payable"
                value={payableTotals.totalAmount}
                sign="negative"
                subtitle={
                    <HStack spacing="0">
                        <TextButton color={'black'} onClick={() => navigate('payables/invoices')} mr="2">
                            {overdueCount.payables.invoices.length > 0 && <WarningIcon mr="1" />}
                            {pendingPayableInvoicesCount} Invoice{pendingPayableInvoicesCount !== 1 && 's'},
                        </TextButton>
                        <TextButton color={'black'} onClick={() => navigate('payables/payments')}>
                            {overdueCount.payables.payments.length > 0 && <WarningIcon mr="1" />}
                            {pendingPayablePaymentsCount} Payment{pendingPayablePaymentsCount !== 1 && 's'}
                        </TextButton>
                    </HStack>
                }
            />
            <InfoSnippetCard
                isLoading={isLoading}
                isCurrency
                title="Total Receivable"
                value={receivableTotals.totalAmount}
                sign="positive"
                subtitle={
                    <HStack spacing="0">
                        <TextButton color={'black'} onClick={() => navigate('receivables/invoices')} mr="2">
                            {overdueCount.receivables.invoices.length > 0 && <WarningIcon mr="1" />}
                            {pendingReceivableInvoicesCount} Invoice{pendingReceivableInvoicesCount !== 1 && 's'},
                        </TextButton>
                        <TextButton color={'black'} onClick={() => navigate('receivables/payments')}>
                            {overdueCount.receivables.payments.length > 0 && <WarningIcon mr="1" />}
                            {pendingReceivablePaymentsCount} Payment{pendingReceivablePaymentsCount !== 1 && 's'}
                        </TextButton>
                    </HStack>
                }
            />
            <InfoSnippetCard
                isLoading={isLoading}
                isCurrency
                title="Bulla Balance"
                value={netBalance.balance}
                subtitle={<Text>Last Updated: {toDateDisplay(netBalance.lastUpdated)}</Text>}
            />
        </ResponsiveStack>
    );
};

export const Home = () => {
    const { isInitialized } = useDataReadiness();
    const { payables, receivables } = useGlobalUserData('exclude-originating-claims');
    const { readyToTransact } = useAppState();
    const { connectedSafeAddress } = useGnosisSafe();
    const isMobile = useIsMobile();
    const { showGettingStarted, setGettingStartedEnabled } = useGettingStarted();
    const navigate = useNavigate();

    const actions = [
        <CreateClaimModal
            claimType="Invoice"
            triggerElement={onOpen => (
                <PopoverAction onClick={onOpen} isDisabled={!readyToTransact}>
                    Invoice
                </PopoverAction>
            )}
        />,
        <CreateClaimModal
            claimType="Payment"
            triggerElement={onOpen => (
                <PopoverAction onClick={onOpen} isDisabled={!readyToTransact}>
                    Payment
                </PopoverAction>
            )}
        />,
        <PopoverAction onClick={() => navigate('/batch-invoice')} isDisabled={!readyToTransact}>
            Batch Invoice
        </PopoverAction>,
        <PopoverAction onClick={() => navigate('/batch-payment')} isDisabled={!readyToTransact}>
            Batch Payment
        </PopoverAction>,
    ];

    return (
        <PageLayoutProvider>
            <GettingStartedCard isOpen={showGettingStarted} onClose={() => setGettingStartedEnabled(false)} />
            <Box p="12">
                <SummaryPanel>
                    <ResponsiveStack>
                        <Heading color="heading" flex={1}>
                            Home
                        </Heading>
                        <Spacer />
                        <HStack {...(isMobile && { w: '100%', pb: 2 })}>
                            <NetworkLoadingSpinner />
                            {!connectedSafeAddress && !inIframe && <ViewNetworkSelector {...(isMobile && { w: '100%' })} />}
                        </HStack>
                        <ActionDropdown />
                    </ResponsiveStack>
                    <SummaryCards />
                </SummaryPanel>
                <MaxWidthWrapper>
                    <Fade in={isInitialized} unmountOnExit>
                        <PendingSafePayments />
                        <PendingClaims
                            walletHeader="to wallet"
                            items={payables.filter(isClaim)}
                            title={
                                <Flex>
                                    <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                                        Pending Payables
                                    </Text>
                                    <Spacer />
                                    <LinkButton to="/payables" color={isMobile ? 'brand.bulla_blue' : 'theme'}>
                                        {isMobile ? 'View All' : 'View All Payables'}
                                    </LinkButton>
                                </Flex>
                            }
                            emptyMessage="You have no pending payables"
                        />
                        <PendingClaims
                            walletHeader="from wallet"
                            items={receivables.filter(isClaim)}
                            title={
                                <Flex>
                                    <Text textStyle="listTitle" fontSize={isMobile ? '19px' : '21px'}>
                                        Pending Receivables
                                    </Text>
                                    <Spacer />
                                    <LinkButton to="/receivables" color={isMobile ? 'brand.bulla_blue' : 'theme'}>
                                        {isMobile ? 'View All' : 'View All Receivables'}
                                    </LinkButton>
                                </Flex>
                            }
                            emptyMessage="You have no pending receivables"
                        />
                        <ReceivedPayments />
                        <PaymentsMade />
                        <AccountTags />
                    </Fade>
                </MaxWidthWrapper>
            </Box>
            <CreateItemLinkHandler />
        </PageLayoutProvider>
    );
};
