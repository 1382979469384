import { MenuItem, Tooltip } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React from 'react';
import { ClaimInfo } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { chainIds, MATIC_FACTORING_CONFIG } from '../../../data-lib/networks';
import { useBullaFactoring } from '../../../hooks/useBullaFactoring';
import { BullaFactoringEligibilityTerms, useCheckFactoringEligibility } from '../../../hooks/useCheckFactoringEligibility';
import { MultisendTransaction, useGnosisGlobalMultisend } from '../../../hooks/useGnosisMultisend';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { filterSelectedClaims, getMultisendTooltipLabel, useTransactionBundle } from '../../../hooks/useTransactionBundle';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { menuItemDisabledProps } from '../../inputs/buttons';
import { ClaimsPage } from './claims-page-template';

export const Receivables = () => {
    const { receivables } = useGlobalUserData('exclude-originating-claims');
    const { connectedNetworkConfig } = useWeb3();
    const { connectedSafeAddress, safeInfo } = useGnosisSafe();
    const { isFull } = useGnosisGlobalMultisend();
    const { executeTransactions } = useGnosisTransaction();
    const { addRescindClaimTransactionsToBundle } = useTransactionBundle();
    const [selected, setSelected] = React.useState<string[]>([]);
    const [, { checkEligibility }] = useCheckFactoringEligibility();
    const [, { approveInvoiceInputToMultisendTxDTO, fundInvoiceInputToMultisendTxDTO }] = useBullaFactoring(MATIC_FACTORING_CONFIG);

    const handleRescindSelected = () =>
        addRescindClaimTransactionsToBundle(
            filterSelectedClaims(
                selected,
                receivables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
            ),
        );

    const handleFactorSelected = async () => {
        if (!safeInfo) {
            console.log('no safe, returning');
            return;
        }
        const tcsPoolAddress = MATIC_FACTORING_CONFIG.bullaFactoringToken.token.address;

        const claims = filterSelectedClaims(
            selected,
            receivables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
        );

        if (claims.some(x => x.chainId !== chainIds.MATIC)) {
            console.log('not all claims are on Polygon');
            return;
        }

        const termsAndClaims = await claims.reduce<Promise<{ terms: BullaFactoringEligibilityTerms; claim: ClaimInfo }[]>>(
            async (_acc, claim) => {
                const acc = await _acc;
                console.log(`getting ${claim.description} approved for factoring`);

                const approvalResult = await checkEligibility(claim, tcsPoolAddress);

                console.log({ approvalResult });

                return approvalResult.type == 'eligible' ? [...acc, { terms: approvalResult.terms, claim }] : acc;
            },
            Promise.resolve([]),
        );

        console.log(`${termsAndClaims.length} out of ${claims.length} were approved for financing, creating gnosis transaction to sign`);

        const multisendTransactions = await termsAndClaims.reduce<Promise<MultisendTransaction[]>>(async (_acc, { terms, claim }) => {
            const acc = await _acc;
            const { transaction: approveInvoiceTransaction } = await approveInvoiceInputToMultisendTxDTO(
                connectedNetworkConfig.bullaClaimAddress,
                BigNumber.from(claim.id),
                tcsPoolAddress,
            );
            const { transaction: fundInvoiceTransaction } = await fundInvoiceInputToMultisendTxDTO(
                tcsPoolAddress,
                BigNumber.from(claim.id),
                10000,
            );

            return [...acc, approveInvoiceTransaction, fundInvoiceTransaction];
        }, Promise.resolve([]));

        console.log(`adding ${multisendTransactions.length} txs to batch`);

        await executeTransactions(safeInfo, multisendTransactions, true);
    };

    const multisendTooltipLabel = getMultisendTooltipLabel(!!connectedSafeAddress, isFull);
    const buttonsDisabled = !connectedSafeAddress || isFull;

    const dropdownActions = [
        <Tooltip label={multisendTooltipLabel} key="rescind">
            <MenuItem onClick={handleRescindSelected} isDisabled={buttonsDisabled} {...(buttonsDisabled && menuItemDisabledProps)}>
                Rescind
            </MenuItem>
        </Tooltip>,
        ...(addressEquality(connectedSafeAddress?.toLowerCase() ?? '', '0xbbd837143cdc4816fcd7301731f6d4a366d0cba2')
            ? [
                  <Tooltip label={multisendTooltipLabel} key="factor">
                      <MenuItem onClick={handleFactorSelected} isDisabled={buttonsDisabled} {...(buttonsDisabled && menuItemDisabledProps)}>
                          Factor TCS invoices
                      </MenuItem>
                  </Tooltip>,
              ]
            : []),
    ];

    return (
        <ClaimsPage
            claims={receivables}
            claimType="Invoice"
            tabName="receivables"
            actionButtonLabel="Request Tokens"
            dropdownActions={dropdownActions}
            selected={selected}
            setSelected={setSelected}
        />
    );
};
