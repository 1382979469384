import { BigNumber, BigNumberish } from 'ethers';
import { BaseEvent } from '../dto/base-event-dto';
import { MAX_BPS_BN } from '../dto/bulla-finance-dto';
import { EthAddress } from '../ethereum';

export const getClaimAmountFromLoanOffer = (loanAmount: BigNumber, interestBPS: BigNumberish) =>
    loanAmount.mul(MAX_BPS_BN.add(interestBPS)).div(MAX_BPS_BN);

export type LoanOfferedEvent = BaseEvent & {
    __typename: 'LoanOfferedEvent';
    interestBPS: number;
    termLength: BigNumber;
    loanAmount: BigNumber;
    creditor: EthAddress;
    debtor: EthAddress;
    description: string;
    token: EthAddress;
    ipfsHash: string;
    loanId: string;
    offeredBy: EthAddress;
};

export type LoanOfferAcceptedEvent = BaseEvent & {
    __typename: 'LoanOfferAcceptedEvent';
    tokenId: string;
    loanId: string;
};

export type LoanOfferRejectedEvent = BaseEvent & {
    __typename: 'LoanOfferRejectedEvent';
    rejectedBy: EthAddress;
    loanId: string;
};
