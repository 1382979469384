import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Spacer, Stack, Text } from '@chakra-ui/react';
import { CheckCircle } from 'phosphor-react';
import React from 'react';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../tools/common';
import { OrangeButton, SecondaryButton } from '../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow } from './common';

export const WelcomePremiumModal: React.FC<{
    modalOpen: boolean;
    closeModal: () => void;
}> = ({ modalOpen, closeModal }) => {
    return (
        <Modal isCentered isOpen={modalOpen} onClose={closeModal} motionPreset="slideInBottom" size="xl" closeOnEsc scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent>
                <CloseModalButton onClose={closeModal} />
                <ModalBody fontSize={'14px'} fontWeight="400">
                    <Stack alignItems={'center'} py="6">
                        <Box borderRadius={'19px'} bg="#ECFDF3" p="2">
                            <Box borderRadius={'14px'} bg="#DCFAE6" p="1">
                                <CheckCircle color="green" size={20} radius="8px" />
                            </Box>
                        </Box>
                        <Text color="gray.700" fontWeight={'700'} fontSize="25px" alignSelf="center" maxW={'95%'}>
                            Welcome to Bulla Basic!
                        </Text>
                        <Text>Your payment was successful and your membership has started.</Text>
                    </Stack>
                </ModalBody>
                <ModalFooterWithShadow>
                    <Flex w="100%">
                        <SecondaryButton as="a" href={BULLA_NETWORK_DISCORD_INVITE} target="_blank">
                            Join Discord
                        </SecondaryButton>
                        <Spacer />
                        <OrangeButton onClick={closeModal}>Get Started</OrangeButton>
                    </Flex>
                </ModalFooterWithShadow>
            </ModalContent>
        </Modal>
    );
};
