import { TimeIcon } from '@chakra-ui/icons';
import { Box, HStack, Stack, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { CheckCircle } from 'phosphor-react';
import React from 'react';
import { WarningIcon, WarningIconWithTooltip } from '../../assets/warning';
import { LoanStatusInfo } from '../layout/loan-cards';
import { Badge } from './status-badge';

const tooltipIconStyles = {
    placement: 'top',
    variant: 'card',
    closeDelay: 500,
} as const;

export const OnTrackIcon = ({ dueDate }: { dueDate: Date }) => (
    <Tooltip
        label={
            <Stack align="center" fontSize={'14px'}>
                <Badge bg={'green.400'} w="100%" fontSize={'14px'} color="gray.900">
                    <HStack padding="0" color={'white'} justify="center" display={'flex'}>
                        <CheckCircle color="white" weight="bold" />
                        <Text>On track</Text>
                    </HStack>
                </Badge>
                <HStack>
                    <Text>Due:</Text>
                    <Text fontWeight={'bold'}>{moment(dueDate).format('DD MMM, YYYY')}</Text>
                </HStack>
            </Stack>
        }
        {...tooltipIconStyles}
    >
        <Box>
            <TimeIcon color="green.400" h="20px" w="20px" />
        </Box>
    </Tooltip>
);
export const LateIcon = ({ dueDate, type }: { dueDate: Date; type: 'due-soon' | 'past-due' }) => (
    <WarningIconWithTooltip
        label={
            <Stack align="center" fontSize={'14px'}>
                <Badge bg={type === 'due-soon' ? 'warning' : 'danger'} w="100%" fontSize={'14px'} color="gray.900">
                    <HStack padding="0" color={'white'} justify="center" display={'flex'}>
                        <WarningIcon color="white" height="11px" width={'9.5px'} />
                        <Text>{type == 'due-soon' ? 'Due soon' : 'Past due'}</Text>
                    </HStack>
                </Badge>
                <HStack>
                    <Text>Due:</Text>
                    <Text fontWeight={'bold'}>{moment(dueDate).format('DD MMM, YYYY')}</Text>
                </HStack>
            </Stack>
        }
        warningOverrides={{ color: type === 'due-soon' ? 'warning' : 'danger' }}
        {...tooltipIconStyles}
    />
);

export const getLoanIcon = ({ status, dueDate }: LoanStatusInfo): React.ReactNode => {
    switch (status) {
        case 'on-track':
            return <OnTrackIcon dueDate={dueDate} />;
        case 'past-due':
        case 'due-soon':
            return <LateIcon dueDate={dueDate} type={status} />;
    }
};
