import { useState } from 'react';

/**
 *
 * @param all
 * @param toSearchable
 * @returns [filteredItems, queryString, setQueryString]
 */
export function useSearchFilter<T>(all: T[], toSearchable: (item: T) => string[]): [T[], string, (newQuery: string) => void] {
    const toSearchableLowerCase = (x: T) => toSearchable(x).map(x => x.toLowerCase());
    const [query, setQuery] = useState<string>('');
    const lowerStringQuery = query.toLowerCase();
    const isMatch = (item: T) => (toSearchableLowerCase(item).find(x => x.includes(lowerStringQuery)) === undefined ? false : true);
    const filteredItems = all.filter(isMatch);
    return [filteredItems, query, setQuery];
}
