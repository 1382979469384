import { Image, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ChainId, NETWORKS } from '../data-lib/networks';

export const ChainSymbol = ({ chainId, maxH }: { chainId: ChainId; maxH?: string }) => {
    const networkConfig = NETWORKS[chainId];
    return (
        <Tooltip label={networkConfig.label}>
            <Image src={networkConfig.logoFileName} maxH={maxH ?? '24px'} h={maxH} />
        </Tooltip>
    );
};
