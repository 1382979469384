import { Divider, Flex, Heading, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { ActionDropdown } from '../../inputs/buttons';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';

import { TabOptions, TabSwitcher } from './account-tag-view';

const ALL_ENTERPRISE_VIEW_TABS = ['payments', 'net-flows', 'nft-transfers', 'payables', 'receivables'] as const;
export type EnterpriseViewTab = typeof ALL_ENTERPRISE_VIEW_TABS[number];

const getTabOptionsForEnterpriseView = (tabs: EnterpriseViewTab[]): TabOptions<EnterpriseViewTab>[] =>
    tabs.map(value => {
        const label = (() => {
            switch (value) {
                case 'payables':
                    return 'Payables';
                case 'receivables':
                    return 'Receivables';
                case 'payments':
                    return 'Payments';
                case 'net-flows':
                    return 'Net Flows';
                case 'nft-transfers':
                    return 'NFT Transfers';
            }
        })();

        return { label, value };
    });

const getPathnameForEnterpriseView = (tab: EnterpriseViewTab) => {
    switch (tab) {
        case 'payables':
            return '/payables';
        case 'receivables':
            return '/receivables';
        case 'payments':
            return '/payments';
        case 'net-flows':
            return `/payments/${tab}`;
        case 'nft-transfers':
            return '/nft-transfers';
    }
};

type EnterpriseViewTemplateProps = {
    tab: EnterpriseViewTab;
    topRightWidget: React.ReactNode;
    mainSection: React.ReactNode;
};

export const EnterpriseViewTemplate = ({ tab, topRightWidget, mainSection }: EnterpriseViewTemplateProps) => {
    const navigate = useNavigate();
    const setTab = (tab: EnterpriseViewTab) => navigate({ pathname: getPathnameForEnterpriseView(tab) }, { replace: true });
    const isMobile = useIsMobile();
    const tabs = ALL_ENTERPRISE_VIEW_TABS.filter(value => !(isMobile && value === 'net-flows'));

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <Flex>
                    <Heading textStyle="bullaViewHeader">Explorer</Heading>
                    <Spacer />
                    {topRightWidget}
                    <ActionDropdown border="3px" />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'}>
                    <TabSwitcher tab={tab} setTab={setTab} options={getTabOptionsForEnterpriseView(tabs)} />
                    <Divider transform="translate(0, -2px)" />
                </Stack>
                <Flex pt="8" direction={'column'} flex="1">
                    {mainSection}
                </Flex>
            </MaxWidthWrapper>
        </Flex>
    );
};
