import { Button, ButtonGroup, ButtonProps, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { BigNumber, constants } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { addressEquality } from '../../data-lib/ethereum';
import { FactoringConfig, NETWORKS, TokenDto } from '../../data-lib/networks';
import { ApprovalsRequired, useAllowances } from '../../hooks/useAllowances';
import { useClaimFunction, usePayClaim } from '../../hooks/useBullaClaim';
import { useTokenBalances } from '../../hooks/useChainData';
import { useInstantPayment } from '../../hooks/useInstantPayment';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { useUIState } from '../../state/ui-state';
import { ChakraCompose } from '../../tools/types';
import { buttonSizeProps, OrangeButton, SecondaryButton } from '../inputs/buttons';
import { TokenApprovalModal } from '../modals/token-approval.modal';
import { ClaimInfo } from '../../data-lib/data-model';
import { isFactored, isUnfactored } from '../../data-lib/helpers';
import { useBullaFactoring } from '../../hooks/useBullaFactoring';
import { truncateToDecimals } from '../../tools/common';
import { useGnosisSafe } from '../../state/gnosis-state';
import { useGnosisTransaction } from '../../hooks/useGnosisTransaction';

type ActionButtonProps = {
    claimInfo?: ClaimInfo;
    onComplete?: () => void;
    amountToPay?: BigNumber;
    parentIsDisabled?: boolean;
};

type ActionButtonBaseProps = ChakraCompose &
    ButtonProps & {
        handleClick: () => void;
        isLoading: boolean;
        isDisabled?: boolean;
    };

export const ActionButton = ({ children, handleClick, isLoading, isDisabled, ...props }: ActionButtonBaseProps) => {
    const { transactionPending } = useUIState();

    return (
        <Button
            isLoading={isLoading}
            w="28"
            h="12"
            {...props}
            isDisabled={transactionPending || isDisabled || !handleClick}
            onClick={handleClick}
        >
            {children}
        </Button>
    );
};

export const PayButton = ({ claimInfo, onComplete, amountToPay, parentIsDisabled, ...props }: ActionButtonProps & Partial<ButtonProps>) => {
    const { connectedNetwork } = useWeb3();
    const { readyToTransact } = useAppState();

    const {
        handlePay,
        handleAdd,
        isReady,
        buttonConfig: { payButton, addButton },
        approveModalOpen,
        closeApproveModal,
    } = usePayClaim({ claimInfo, customAmountToPay: amountToPay });

    const onPayClick = () => handlePay().then(success => success && onComplete && onComplete());

    return (
        <Tooltip placement="top" label={payButton.labels.tooltip} isDisabled={!payButton.labels.tooltip}>
            <>
                <ButtonGroup isAttached>
                    <OrangeButton
                        isLoading={payButton.isLoading || !isReady}
                        isDisabled={payButton.isDisabled || !readyToTransact || !!parentIsDisabled}
                        onClick={onPayClick}
                        minW={'84px'}
                        {...props}
                    >
                        <Text fontWeight="500">{payButton.labels.button}</Text>
                    </OrangeButton>
                    {addButton && (
                        <IconButton
                            borderLeftWidth={'1px'}
                            borderLeftColor={'gray.200'}
                            colorScheme={'accent'}
                            isDisabled={addButton.isDisabled || !readyToTransact || claimInfo?.chainId !== connectedNetwork}
                            h={buttonSizeProps.h}
                            aria-label={addButton.labels.toolip}
                            icon={<addButton.icon />}
                            onClick={handleAdd}
                            {...props}
                        />
                    )}
                </ButtonGroup>
                {claimInfo?.tokenInfo.token.isNative || claimInfo?.claimAmount.eq(constants.Zero) ? null : (
                    <TokenApprovalModal
                        modalOpen={approveModalOpen}
                        closeModal={closeApproveModal}
                        approvalInfos={[
                            {
                                approvalAmount: claimInfo?.claimAmount!,
                                token: claimInfo?.tokenInfo.token!,
                            },
                        ]}
                        approvalType={claimInfo?.claimType}
                    />
                )}
            </>
        </Tooltip>
    );
};

export const UnfactorButton = ({
    claimInfo: selectedClaimInfo,
    onComplete,
    factoringConfig,
}: ActionButtonProps & { factoringConfig: FactoringConfig }) => {
    const userAddress = useActingWalletAddress();
    const { connectedNetwork } = useWeb3();
    const { readyToTransact } = useAppState();
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [approved, setApproved] = React.useState(false);
    const token = selectedClaimInfo?.tokenInfo.token!;
    const [executing, { unfactorInvoice, unfactorInvoiceInputToMultisendTxDTO }] = useBullaFactoring(factoringConfig);
    const fundTokenInfo = factoringConfig.bullaFactoringToken;
    const bullaFundTokenAddress = fundTokenInfo.token.address.toLowerCase();
    const poolUnderlyingTokenInfo = factoringConfig.poolUnderlyingToken;
    const poolUnderlyingTokenAddress = poolUnderlyingTokenInfo.token.address;
    const requiredAllowance = allowance !== 'init' ? allowance.add(selectedClaimInfo!.claimAmount) : BigNumber.from('0');
    const isPending = selectedClaimInfo?.claimStatus === 'Pending' || selectedClaimInfo?.claimStatus === 'Factored';
    const isDisabled = !isPending || !readyToTransact || selectedClaimInfo.chainId !== connectedNetwork;
    const { safeInfo } = useGnosisSafe();
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const isLoading = approvalPending || allowance == 'init' || executing || executingSafeTx;
    const { executeTransactions } = useGnosisTransaction();

    useEffect(() => {
        getAllowanceForTokenAddress(bullaFundTokenAddress, connectedNetwork)(poolUnderlyingTokenAddress).then(setAllowance);
        if (allowance !== 'init' && allowance.gt(selectedClaimInfo!.claimAmount)) {
            setApproved(true);
        }
    }, [token, allowance, selectedClaimInfo?.claimAmount, connectedNetwork]);

    const handleUnfactor = async () => {
        if (selectedClaimInfo && isFactored(selectedClaimInfo, userAddress) && !isUnfactored(selectedClaimInfo)) {
            if (safeInfo) {
                setExecutingSafeTx(true);
                try {
                    const { transaction } = await unfactorInvoiceInputToMultisendTxDTO(
                        fundTokenInfo.token.address,
                        BigNumber.from(selectedClaimInfo.id),
                        selectedClaimInfo.claimAmount,
                        poolUnderlyingTokenInfo.token,
                    );
                    const { transactionResult, success } = await executeTransactions(safeInfo, [transaction], true);
                    if (success && onComplete) onComplete();
                    return transactionResult;
                } finally {
                    setExecutingSafeTx(false);
                }
            }

            if (approved) {
                unfactorInvoice(BigNumber.from(selectedClaimInfo.id)).then(success => {
                    if (onComplete && success) onComplete();
                });
            } else {
                approveTokens(bullaFundTokenAddress)([{ amount: requiredAllowance, token }]).then(({ success }) => {
                    if (success) {
                        setApproved(true);
                        setAllowance(requiredAllowance);
                    }
                });
            }
        }
    };

    return selectedClaimInfo ? (
        <>
            <SecondaryButton isLoading={isLoading} isDisabled={isDisabled || isLoading} onClick={handleUnfactor}>
                {approved || safeInfo ? 'Unfactor' : 'Unfactor (Approve)'}
            </SecondaryButton>
            <TokenApprovalModal
                modalOpen={approveModalOpen}
                closeModal={closeApproveModal}
                approvalInfos={[
                    {
                        approvalAmount: requiredAllowance,
                        token: token,
                    },
                ]}
            />
        </>
    ) : null;
};

export const CancelButton = ({ claimInfo: selectedClaimInfo, onComplete }: ActionButtonProps) => {
    const userAddress = useActingWalletAddress();
    const { connectedNetwork } = useWeb3();
    const [txPending, { rescindClaim, rejectClaim }] = useClaimFunction();
    const { readyToTransact } = useAppState();
    const { creditor, debtor } = selectedClaimInfo ? selectedClaimInfo : { creditor: '', debtor: '' };

    const isPending = selectedClaimInfo?.claimStatus === 'Pending' || selectedClaimInfo?.claimStatus === 'Unfactored';
    const isPayable = addressEquality(userAddress, debtor);
    const isReceivable = addressEquality(userAddress, creditor);
    const isSelfPayment = isPayable && isReceivable;
    const isDisabled = txPending || !isPending || !readyToTransact || selectedClaimInfo.chainId !== connectedNetwork;
    const claimAction = isSelfPayment ? rescindClaim : isPayable ? rejectClaim : rescindClaim;

    const handleCancel = () =>
        selectedClaimInfo &&
        claimAction(selectedClaimInfo).then(success => {
            if (onComplete && success) onComplete();
        });

    return selectedClaimInfo ? (
        <SecondaryButton isLoading={txPending} isDisabled={isDisabled} onClick={handleCancel}>
            {isPayable ? 'Reject' : 'Rescind'}
        </SecondaryButton>
    ) : null;
};

type InstantPaymentForm = {
    token: TokenDto;
    amount: string;
};

type ApprovalInfo = { tokensRequireApproval: boolean; allowancesRequired: ApprovalsRequired; approveTxCount: number };

export const InstantPaymentButton = ({
    paymentInfo,
    isDisabled,
    isLoading,
    withText,
    onClick,
    ...overrides
}: {
    paymentInfo: InstantPaymentForm[];
    isLoading: boolean;
    isDisabled: boolean;
    withText?: (buttonText: string) => string;
    onClick?: () => void;
} & ButtonProps) => {
    const [approving, { getAllowancesRequired, approveTokens }] = useInstantPayment();
    const { connectedNetwork } = useWeb3();
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork });
    const tokenAmountByToken = useMemo(
        () =>
            paymentInfo.reduce<Record<string, number>>(
                (acc, item) => ({
                    ...acc,
                    [item.token.address.toLowerCase()]: (acc[item.token.address.toLowerCase()] ?? 0) + +item.amount,
                }),
                {},
            ),
        [JSON.stringify(paymentInfo)],
    );
    const sufficientFunds = Object.entries(tokenAmountByToken).every(
        ([address, amount]) => (tokenBalances.getBalanceForToken(address) ?? 0) >= amount,
    );

    const [approvals, setApprovals] = React.useState<'init' | 'loading' | ApprovalInfo>('init');
    const approveOnClick = typeof approvals !== 'string' && approvals.tokensRequireApproval;
    const blockButtonInteraction = approving || approvals === 'loading';
    const [approveModalOpen, setApproveModalOpen] = React.useState(false);
    const closeApproveModal = () => {
        setApproveModalOpen(false);
    };

    useEffect(() => {
        if (!isDisabled && !isLoading && paymentInfo.some(({ amount }) => +amount > 0)) checkAllowances(paymentInfo);
    }, [paymentInfo.reduce((acc, { amount, token }) => acc.concat(`${token.address}${amount}`), ''), isDisabled, isLoading]);

    const checkAllowances = useCallback(
        debounce((paymentInfo: InstantPaymentForm[]) => {
            setApprovals('loading');
            getAllowancesRequired(paymentInfo).then(setApprovals);
        }, 300),
        [],
    );

    const approve = () =>
        approveTokens(paymentInfo).then(({ success }) => {
            if (success)
                setApprovals(prevApprovals => {
                    if (typeof prevApprovals === 'object') {
                        return {
                            approveTxCount: 0,
                            allowancesRequired: {},
                            tokensRequireApproval: false,
                        };
                    }
                    return prevApprovals;
                });
        });

    const approveWithModal = () => {
        setApproveModalOpen(true);
        approve().then(() => setApproveModalOpen(false));
    };

    const getText = () => {
        if (approvals == 'init' || approvals === 'loading') return 'Send Payment';
        const { approveTxCount, tokensRequireApproval } = approvals;
        return !sufficientFunds
            ? 'Insufficient funds'
            : tokensRequireApproval
            ? approveTxCount > 1
                ? `Send (Approve ${approveTxCount} Tokens)`
                : 'Send (Approve Token)'
            : `Send Payment${paymentInfo.length > 1 ? 's' : ''}`;
    };

    const aggregatedApprovalInfo = useMemo(() => {
        const result =
            paymentInfo
                ?.filter(({ amount }) => {
                    try {
                        return isFinite(Number(amount)) && amount !== '';
                    } catch {
                        return false;
                    }
                })
                .reduce<Record<string, { approvalAmount: BigNumber; token: TokenDto }>>((acc, { amount, token }) => {
                    const truncatedAmount = truncateToDecimals(amount, token.decimals);
                    const parsedAmount = parseUnits(truncatedAmount, token.decimals);

                    return {
                        ...acc,
                        [token.symbol]: acc[token.symbol]
                            ? {
                                  token,
                                  approvalAmount: acc[token.symbol].approvalAmount.add(parsedAmount),
                              }
                            : {
                                  token,
                                  approvalAmount: parsedAmount,
                              },
                    };
                }, {}) ?? {};

        return Object.values(result).filter(info => !info.token.isNative && !info.approvalAmount.isZero());
    }, [paymentInfo]);

    return (
        <>
            <OrangeButton
                type={approveOnClick ? 'button' : 'submit'}
                onClick={approveOnClick ? approveWithModal : onClick}
                isLoading={isLoading || blockButtonInteraction || tokenBalances.nonce <= 0}
                isDisabled={isDisabled || blockButtonInteraction || approvals == 'init' || !sufficientFunds}
                {...overrides}
            >
                {withText ? withText(getText()) : getText()}
            </OrangeButton>
            <TokenApprovalModal
                modalOpen={approveModalOpen}
                closeModal={closeApproveModal}
                approvalInfos={aggregatedApprovalInfo}
                approvalType={'payment'}
            />
        </>
    );
};
