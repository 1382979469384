import { Contract, ethers, providers } from 'ethers';
import bullaBankerModuleArtifact from '@bulla-network/contracts/artifacts/contracts/libraries/SafeModuleFactory.sol/ModuleProxyFactory.json';
import { ModuleProxyFactory } from '@bulla-network/contracts/typechain/ModuleProxyFactory';
import { EthAddress } from '../ethereum';
import { NetworkConfig } from '../networks';
import { GnosisSettings, GNOSIS_CONFIG } from './gnosis';
import { getBullaBankerModuleContract } from '../dto/contract-interfaces';

const SALT_NONCE = '0xB007aB007a';

export const getDeployAndSetUpModuleTransaction = (
    provider: providers.Provider,
    networkConfig: NetworkConfig,
    safeAddress: EthAddress,
    isRedeploy: boolean = false,
) => {
    // since module deployment is determinstic, if user tries to deploy a module with the same
    // bytecode, the `create2` call will try and deploy it to the same address, resulting in an error
    // if we modify the salt nonce in the case of a redeploy, the deployment address will be different.
    const saltNonce = isRedeploy ? `${SALT_NONCE}${Math.floor(Math.random() * 10000000).toString(16)}` : SALT_NONCE;
    const { factory, module } = getFactoryAndMasterCopy(provider, GNOSIS_CONFIG[networkConfig.chainId]);
    const moduleSetupData = module.interface.encodeFunctionData('setUp', [
        ethers.utils.defaultAbiCoder.encode(
            ['address', 'address', 'address', 'address'],
            [safeAddress, networkConfig.bullaBankerLatest, networkConfig.bullaClaimAddress, networkConfig.batchCreate.address],
        ),
    ]);

    const expectedModuleAddress = calculateProxyAddress(factory, module.address, moduleSetupData, saltNonce);
    const deployData = factory.interface.encodeFunctionData('deployModule', [module.address, moduleSetupData, saltNonce]);
    const transaction = {
        data: deployData,
        to: factory.address,
        value: '0',
    };

    return {
        transaction,
        expectedModuleAddress,
    };
};

export const calculateProxyAddress = (factory: ModuleProxyFactory, masterCopy: EthAddress, initData: string, saltNonce: string) => {
    const masterCopyAddress = masterCopy.toLowerCase().replace(/^0x/, '');
    const byteCode = '0x602d8060093d393df3363d3d373d3d3d363d73' + masterCopyAddress + '5af43d82803e903d91602b57fd5bf3';

    const salt = ethers.utils.solidityKeccak256(['bytes32', 'uint256'], [ethers.utils.solidityKeccak256(['bytes'], [initData]), saltNonce]);

    return ethers.utils.getCreate2Address(factory.address, salt, ethers.utils.keccak256(byteCode));
};

const factoryABI = [
    //TODO - update bulla contracts and use the typechain import
    `function deployModule(
      address masterCopy, 
      bytes memory initializer,
      uint256 saltNonce
    ) public returns (address proxy)`,
];

export const getFactoryAndMasterCopy = (
    provider: providers.Provider,
    { bullaModuleMasterCopyAddress, moduleFactoryAddress }: GnosisSettings,
) => {
    const module = getBullaBankerModuleContract(bullaModuleMasterCopyAddress).connect(provider);
    const factory = new Contract(moduleFactoryAddress, bullaBankerModuleArtifact.abi, provider) as ModuleProxyFactory;

    return {
        factory,
        module,
    };
};
