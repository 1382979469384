import { Heading, Input, Box, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { OrangeButton } from '../../inputs/buttons';
import FileIcon from 'url:../../../assets/file-icon.svg';

interface ReportNameCardProps {
    reportName: string;
    setReportName: (name: string) => void;
    onStartExport: () => void;
}

export const ReportNameCard: React.FC<ReportNameCardProps> = ({ reportName, setReportName, onStartExport }) => {
    return (
        <Box maxW="400px" mx="auto" mt="8" textAlign="center" shadow={'xl'} p="5">
            <Box my="8" display="flex" justifyContent="center">
                <Image src={FileIcon} />
            </Box>
            <Heading as="h2" size="md" mb="2">
                Welcome to Ledger Export
            </Heading>
            <Text mb="6">Export CSVs for receivables, payables, and cash disbursements for import into your general ledger.</Text>
            <Input placeholder="Give your report a name" mb="8" value={reportName} onChange={e => setReportName(e.target.value)} />
            <OrangeButton width="full" onClick={onStartExport} isDisabled={!reportName}>
                Start Export
            </OrangeButton>
        </Box>
    );
};
