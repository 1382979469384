import { HStack, Button, Textarea, Text, useBoolean } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useBullaToast } from '../components/base/toast';
import { BullaBlueTextButton, SmallSecondaryButton } from '../components/inputs/buttons';
import { BullaItemInfo } from '../data-lib/data-model';
import { ChainId } from '../data-lib/networks';

import { getBackendTxIdForItem, useExternalTransactionsApi } from './useExternalTransactionsApi';
import { useMembership } from './useMembership';
import { useEditOffchainInvoiceMetadata, useOffchainInvoices } from './useOffchainInvoices';

type UseEditNotesDisplayProps = {
    isLoading: boolean;
    item:
        | { __type: BullaItemInfo['__type']; id: string; tags: string[]; description: string; initialNotes: string; chainId?: ChainId }
        | undefined;
    onSuccess?: (newNotes: string) => void;
};

export const useEditNotesDisplay = ({ isLoading: _isLoading, item, onSuccess }: UseEditNotesDisplayProps) => {
    const [editNotes, setEditNotes] = useBoolean();
    const [savingNotes, setSavingsNotes] = useBoolean();
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const [notes, setNotes] = React.useState('');
    const triggerToast = useBullaToast();
    const isLoading = _isLoading || item == undefined;
    const [modalOpen, setModalOpen] = useState(false);
    const closeModal = () => setModalOpen(false);
    const premiumMembership = useMembership();
    const userIsPremium = !!premiumMembership;
    const initialNotes = item?.initialNotes ?? '';
    const editInvoiceMetadata = useEditOffchainInvoiceMetadata();

    useEffect(() => {
        if (!!initialNotes) {
            setNotes(initialNotes);
        }
    }, [initialNotes]);

    const triggerErrorToast = () =>
        triggerToast({
            title: 'Error saving notes. Please try again later.',
            status: 'error',
        });

    const labelRow = (
        <HStack mt="4" mb="2">
            <Text>Notes</Text>
            {editNotes ? (
                <HStack>
                    <Button
                        onClick={async () => {
                            if (!item) throw new Error('Should not happen');
                            setSavingsNotes.on();

                            const { id, tags, description, chainId } = item;

                            (item.__type == 'OffchainInvoiceInfo'
                                ? editInvoiceMetadata({ [item.id]: { notes, tags } })
                                : saveExternalTransactions(
                                      {
                                          [getBackendTxIdForItem(id, chainId)]: {
                                              id,
                                              notes,
                                              tags,
                                              description,
                                              chainId,
                                          },
                                      },
                                      undefined,
                                      item.chainId,
                                  )
                            )
                                .then(result => {
                                    if (result) {
                                        triggerToast({
                                            title: 'Notes saves successfully.',
                                        });

                                        onSuccess && onSuccess(notes);
                                    } else {
                                        triggerErrorToast();
                                    }
                                })
                                .catch(e => {
                                    console.error(e);
                                    triggerErrorToast();
                                })
                                .finally(() => {
                                    setSavingsNotes.off();
                                    setEditNotes.off();
                                });
                        }}
                        isDisabled={isLoading || savingNotes}
                        isLoading={savingNotes}
                        colorScheme="accent"
                        size="xs"
                    >
                        Save
                    </Button>
                    <SmallSecondaryButton size="xs" onClick={() => onNotesCancel()}>
                        Cancel
                    </SmallSecondaryButton>
                </HStack>
            ) : (
                !userIsPremium && (
                    <BullaBlueTextButton
                        onClick={() => {
                            setModalOpen(true);
                        }}
                        isLoading={isLoading}
                        isDisabled={isLoading}
                        textDecor="none"
                        fontWeight={700}
                        fontSize="14px"
                        lineHeight={'24px'}
                    >
                        Unlock
                    </BullaBlueTextButton>
                )
            )}
        </HStack>
    );

    const onNotesCancel = () => {
        setNotes(initialNotes);
        setEditNotes.off();
    };

    const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditNotes.on();
        setNotes(e.target.value);
    };
    const notesRow = <Textarea isDisabled={!userIsPremium} onChange={e => onNotesChange(e)} value={notes} />;

    return { labelRow, notesRow, modalOpen, closeModal };
};
