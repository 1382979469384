import React from 'react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { ChainId, NETWORKS } from '../data-lib/networks';
import { PoolEvent } from '../data-lib/dto/mapped-event-types';
import {
    mapClaimLogGraphEventsToClaimEvents,
    mapGraphEntitiesToBullaItemEvents,
    mapGraphEventToEvent,
} from '../data-lib/graphql/graphql-dto';
import {
    BullaItemEvent,
    categorizeBullaItemEvents,
    ClaimLogData,
    getClaimLogData,
    getPoolEventInfo,
    mapToClaimInfo,
} from '../data-lib/data-transforms';
import { useTokenRepo } from './useTokenRepo';
import { CLAIM_FIELDS, FACTORING_EVENTS_QUERY } from '../data-lib/graphql/userQuery';
import {
    Claim__graphql,
    DepositMadeEvent__graphql,
    DepositMadeWithAttachmentEvent__graphql,
    InvoiceFundedEvent__graphql,
    InvoiceKickbackAmountSentEvent__graphql,
    InvoiceUnfactoredEvent__graphql,
    SharesRedeemedEvent__graphql,
    SharesRedeemedWithAttachmentEvent__graphql,
} from '../data-lib/graphql/graph-domain';
import { ClaimInfo, FinancingState, PoolDepositInfo, PoolRedemptionInfo } from '../data-lib/data-model';
import { useWeb3 } from './useWeb3';
import { getFactoringAddressesByChainId, sortBlocknumAsc } from '../data-lib/helpers';
import { getClaimCreatedEvents } from '../data-lib/dto/event-filters';
import { ClaimCreatedEvent } from '../data-lib/domain/bulla-claim-domain';
import { InvoiceFundedEvent, InvoiceKickbackAmountSentEvent, InvoiceUnfactoredEvent } from '../data-lib/domain/factoring-domain';
import { useActingWalletAddress } from './useWalletAddress';

const POOL_ACTIVITY_QUERY = gql`
    query GetPoolDepositsAndRedemptions($poolAddress: String!) {
        depositMadeEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
        depositMadeWithAttachmentEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
        sharesRedeemedEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
        sharesRedeemedWithAttachmentEvents(where: { poolAddress: $poolAddress }) {
            ${FACTORING_EVENTS_QUERY}
        }
        invoiceFundedEvents(where: { poolAddress: $poolAddress }) {
            ... on InvoiceFundedEvent {
                    id
                    invoiceId
                    fundedAmount
                    originalCreditor
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    poolAddress
                    priceAfterTransaction
                    claim {
                        ${CLAIM_FIELDS(true)}
                    
                }
            }
        }
        invoiceKickbackAmountSentEvents(where: { poolAddress: $poolAddress }) {
            ... on InvoiceKickbackAmountSentEvent {
                    id
                    invoiceId
                    kickbackAmount
                    originalCreditor
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    poolAddress
                    priceAfterTransaction
                    claim {
                        ${CLAIM_FIELDS(true)}
                    
                }
            }
        }
        invoiceUnfactoredEvents(where: { poolAddress: $poolAddress }) {
            ... on InvoiceUnfactoredEvent {
                    id
                    invoiceId
                    originalCreditor
                    eventName
                    blockNumber
                    transactionHash
                    logIndex
                    timestamp
                    totalRefundAmount
                    interestToCharge
                    poolAddress
                    priceAfterTransaction
                    claim {
                        ${CLAIM_FIELDS(true)}
                    
                }
            }
        }
    }
`;

interface PoolDetailsContextType {
    poolDeposits: PoolDepositInfo[];
    poolRedemptions: PoolRedemptionInfo[];
    factoredInvoices: ClaimInfo[];
}

const PoolDetailsContext = createContext<PoolDetailsContextType | undefined>(undefined);

interface PoolActivityQueryResult {
    depositMadeEvents: DepositMadeEvent__graphql[];
    depositMadeWithAttachmentEvents: DepositMadeWithAttachmentEvent__graphql[];
    sharesRedeemedEvents: SharesRedeemedEvent__graphql[];
    sharesRedeemedWithAttachmentEvents: SharesRedeemedWithAttachmentEvent__graphql[];
    invoiceFundedEvents: InvoiceFundedEvent__graphql[];
    invoiceKickbackAmountSentEvents: InvoiceKickbackAmountSentEvent__graphql[];
    invoiceUnfactoredEvents: InvoiceUnfactoredEvent__graphql[];
}

export const PoolDetailsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [poolDetails, setPoolDetails] = useState<PoolDetailsContextType>({
        poolDeposits: [],
        poolRedemptions: [],
        factoredInvoices: [],
    });
    const { resolveTokenInfo } = useTokenRepo();
    const { connectedNetwork } = useWeb3();
    const userAddress = useActingWalletAddress();

    useEffect(() => {
        const fetchPoolDetails = async () => {
            const factoringAddressesByChainId: Record<number, string[]> = getFactoringAddressesByChainId();
            let allPoolDeposits: PoolDepositInfo[] = [];
            let allPoolRedemptions: PoolRedemptionInfo[] = [];
            let allFactoredInvoices: ClaimInfo[] = [];

            for (const [chainId, factoringAddresses] of Object.entries(factoringAddressesByChainId)) {
                const networkId = parseInt(chainId);
                const network = NETWORKS[networkId];
                if (!network || !network.factoringConfig) continue;

                const client = new ApolloClient({
                    uri: network.connections.graphEndpoint,
                    cache: new InMemoryCache(),
                });

                for (const poolAddress of factoringAddresses) {
                    let allEvents: (
                        | DepositMadeEvent__graphql
                        | DepositMadeWithAttachmentEvent__graphql
                        | SharesRedeemedEvent__graphql
                        | SharesRedeemedWithAttachmentEvent__graphql
                        | InvoiceFundedEvent__graphql
                        | InvoiceKickbackAmountSentEvent__graphql
                        | InvoiceUnfactoredEvent__graphql
                    )[] = [];

                    const { data } = await client.query<PoolActivityQueryResult>({
                        query: POOL_ACTIVITY_QUERY,
                        variables: { poolAddress: poolAddress.toLowerCase() },
                        fetchPolicy: 'network-only',
                    });

                    allEvents = [
                        ...(data.depositMadeEvents || []),
                        ...(data.depositMadeWithAttachmentEvents || []),
                        ...(data.sharesRedeemedEvents || []),
                        ...(data.sharesRedeemedWithAttachmentEvents || []),
                        ...(data.invoiceFundedEvents || []),
                        ...(data.invoiceKickbackAmountSentEvents || []),
                        ...(data.invoiceUnfactoredEvents || []),
                    ];

                    const mappedPoolDepositAndRedemptionEvents = allEvents
                        .map(mapGraphEventToEvent)
                        .filter((event): event is PoolEvent => event !== null);
                    const { poolDeposits, poolRedemptions } = await getPoolEventInfo(
                        mappedPoolDepositAndRedemptionEvents,
                        resolveTokenInfo,
                        networkId as ChainId,
                    );

                    const factoringEventsAll: (
                        | InvoiceFundedEvent__graphql
                        | InvoiceKickbackAmountSentEvent__graphql
                        | InvoiceUnfactoredEvent__graphql
                    )[] = allEvents.filter(
                        (
                            event,
                        ): event is
                            | InvoiceFundedEvent__graphql
                            | InvoiceKickbackAmountSentEvent__graphql
                            | InvoiceUnfactoredEvent__graphql =>
                            event.__typename === 'InvoiceFundedEvent' ||
                            event.__typename === 'InvoiceKickbackAmountSentEvent' ||
                            event.__typename === 'InvoiceUnfactoredEvent',
                    );

                    const mappedInvoiceFundedEvents = factoringEventsAll
                        .map(event => {
                            const mappedEvent = mapGraphEventToEvent(event);
                            if (
                                mappedEvent.__typename === 'InvoiceFundedEvent' ||
                                mappedEvent.__typename === 'InvoiceKickbackAmountSentEvent' ||
                                mappedEvent.__typename === 'InvoiceUnfactoredEvent'
                            ) {
                                return mappedEvent;
                            }
                            return null;
                        })
                        .filter(
                            (event): event is InvoiceFundedEvent | InvoiceKickbackAmountSentEvent | InvoiceUnfactoredEvent =>
                                event !== null,
                        );

                    const [, claims] = factoringEventsAll.reduce<[string[], Claim__graphql[]]>(
                        (acc, event) =>
                            acc[0].includes(event.claim.tokenId)
                                ? acc
                                : [
                                      [...acc[0], event.claim.tokenId],
                                      [...acc[1], event.claim],
                                  ],
                        [[], []],
                    );

                    const allClaimLogs = claims.flatMap(claim => {
                        const graphClaimLogs = claim.logs || [];
                        return mapClaimLogGraphEventsToClaimEvents(graphClaimLogs);
                    });

                    const allLogs = [...allClaimLogs, ...mappedInvoiceFundedEvents].filter(claimLog => {
                        if (!claimLog) return false;
                        const isNotYourTag = claimLog.__typename === 'BullaTagUpdatedEvent';
                        return !isNotYourTag;
                    });

                    const eventsASC = Object.values(
                        allLogs
                            .sort(sortBlocknumAsc)
                            .reduce<{ [uniqueKey: string]: BullaItemEvent }>(
                                (logs, log) => ({ ...logs, [log.txHash + log.logIndex]: log }),
                                {},
                            ),
                    );

                    const { claimEvents, factoringEvents } = categorizeBullaItemEvents(eventsASC);

                    const claimEventsWithRelatedFactoringEvents = [...claimEvents, ...factoringEvents];

                    // @notice we assume there are no loan events related to factored invoices
                    const claimLogData: ClaimLogData = getClaimLogData(claimEventsWithRelatedFactoringEvents, [], userAddress);

                    const getBullaClaimInfo = (claimEvent: ClaimCreatedEvent) => {
                        const claimId = claimEvent.tokenId;

                        const financingState: FinancingState = (() => {
                            const acceptedFinancing = claimLogData.financingAccepted[claimId];
                            if (acceptedFinancing !== undefined) {
                                return { kind: 'accepted', ...acceptedFinancing };
                            } else {
                                const offeredFinancing = claimLogData.financingOffers[claimId];
                                if (offeredFinancing !== undefined) {
                                    return { kind: 'offered', terms: offeredFinancing };
                                } else return { kind: 'no-financing' };
                            }
                        })();

                        return mapToClaimInfo({
                            chainId: networkId as ChainId,
                            resolveTokenInfo,
                            claimEvent,
                            userAddress: userAddress,
                            claimTransfers: claimLogData.claimTransfers[claimId],
                            bullaTag: claimLogData.bullaTags[claimId],
                            logs: claimLogData.claimEvents[claimId] ?? [],
                            owners: [],
                            financingState,
                        });
                    };

                    const userClaims: ClaimInfo[] = await Promise.all(getClaimCreatedEvents(claimEvents).map(getBullaClaimInfo));

                    allPoolDeposits = [...allPoolDeposits, ...poolDeposits];
                    allPoolRedemptions = [...allPoolRedemptions, ...poolRedemptions];
                    allFactoredInvoices = [...allFactoredInvoices, ...userClaims];
                }
            }

            setPoolDetails({ poolDeposits: allPoolDeposits, poolRedemptions: allPoolRedemptions, factoredInvoices: allFactoredInvoices });
        };

        fetchPoolDetails();
    }, [connectedNetwork, resolveTokenInfo]);

    return <PoolDetailsContext.Provider value={poolDetails}>{children}</PoolDetailsContext.Provider>;
};

export const usePoolDetailsRepo = () => {
    const context = useContext(PoolDetailsContext);
    if (context === undefined) {
        throw new Error('usePoolDetailsRepo must be used within a PoolDetailsProvider');
    }
    return context;
};
