import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BullaItemType, BullaLineItems, FrendLendOffer } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { isImportedExternalTransaction, isPoolTransactions } from '../../../data-lib/helpers';
import { NETWORKS } from '../../../data-lib/networks';
import {
    useCloseBullaItem,
    useOpenClaimDetails,
    useOpenExternalTransactionDetails,
    useOpenInstantPaymentDetails,
    useOpenLoanOffer,
    useOpenOffchainInvoiceDetails,
} from '../../../hooks/useClaimDetailDisclosure';
import { useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { OffchainInvoiceInfo, useOffchainInvoiceFactory } from '../../../hooks/useOffchainInvoiceFactory';
import { usePoolDetailsRepo } from '../../../hooks/usePoolDetailsRepo';
import { useQuery } from '../../../hooks/useQuery';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useDataReadiness, useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { tryGetSpecificClaim, tryGetSpecificInstantPayment, tryGetSpecificLoanOfferEvent } from '../../../state/state-helpers';
import { clearSessionStorage, getSessionStorage, STORAGE_KEYS } from '../../../tools/storage';
import { OffchainInvoiceDetails } from '../offchain-invoice-details';
import { ClaimDetails } from './claim-details';
import { ExternalTransactionDetails } from './external-transaction-details';
import { InstantPaymentDetails } from './instant-payment-details';
import { ItemNotFound } from './item-not-found';
import { OfferDetails } from './loan-offer-details';
import { PoolTransactionDetails } from './pool-transaction-details';

export const ItemDetailsModal = () => {
    const isMobile = useIsMobile();
    const openClaim = useOpenClaimDetails();
    const openInstantPayment = useOpenInstantPaymentDetails();
    const openExternalTransaction = useOpenExternalTransactionDetails();
    const openOffchainInvoice = useOpenOffchainInvoiceDetails();
    const actingWallet = useActingWalletAddress();
    const { connectedNetwork } = useWeb3();
    const { userSafes, fetchSafeAppInfo, connectedSafeAddress, pendingPayments } = useGnosisSafe();
    const { userClaims, instantPayments, frendLends, importedExternalTxs, offchainInvoices } =
        useGlobalUserData('include-originating-claims');
    const { poolDeposits, poolRedemptions } = usePoolDetailsRepo();
    const { isChainInitialized } = useDataReadiness();
    const navigate = useNavigate();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [offchainInvoiceFetched, setOffchainInvoiceFetched] = React.useState<'init' | 'fetching' | 'fetched'>('init');
    const { fetchOffchainInvoiceById } = useExternalTransactionsApi();
    const { createOffchainInvoiceInfo } = useOffchainInvoiceFactory();
    const [offchainInvoice, setOffchainInvoice] = React.useState<OffchainInvoiceInfo | undefined>();

    const modalContentRef = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState(true);

    const openLoanOffer = useOpenLoanOffer();
    const removeItemFromParams = useCloseBullaItem();

    const network_params = useQuery('network');
    const claimId_params = useQuery('claim');
    const instantPaymentId_params = useQuery('instantPayment');
    const externalTransactionId_params = useQuery('externalTransaction');
    const offchainInvoiceId_params = useQuery('invoice');
    const offerId_params = useQuery('loanOffer');
    const poolDepositId_params = useQuery('poolDepositId');
    const poolRedemptionId_params = useQuery('poolRedemptionId');
    const itemId =
        claimId_params ||
        instantPaymentId_params ||
        offerId_params ||
        externalTransactionId_params ||
        offchainInvoiceId_params ||
        poolDepositId_params ||
        poolRedemptionId_params;

    const cache = getSessionStorage<{
        claim?: string;
        instantPayment?: string;
        externalTransaction?: string;
        invoice?: string;
        loanOffer?: string;
        network: string;
        importTransactions?: boolean;
    }>(STORAGE_KEYS.capturedParams);

    useEffect(() => {
        if (cache && !window.location.href.includes('onboard')) {
            const { claim, instantPayment, loanOffer, network, externalTransaction, importTransactions, invoice } = cache;
            if (!!importTransactions) {
                navigate('/import-transactions');
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            }
            if (claim) {
                openClaim(claim, network);
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            } else if (instantPayment) {
                openInstantPayment(instantPayment, network);
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            } else if (externalTransaction) {
                openExternalTransaction(externalTransaction, network);
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            } else if (invoice) {
                openOffchainInvoice(invoice, network);
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            } else if (loanOffer) {
                openLoanOffer(loanOffer, network);
                clearSessionStorage(STORAGE_KEYS.capturedParams);
            }
        }
    }, []);

    const type: BullaItemType | 'FrendLend' = !!claimId_params
        ? 'Claim'
        : !!instantPaymentId_params
        ? 'InstantPayment'
        : !!externalTransactionId_params
        ? 'ImportedExternalTransaction'
        : !!offchainInvoiceId_params
        ? 'OffchainInvoiceInfo'
        : !!poolDepositId_params
        ? 'PoolDeposit'
        : !!poolRedemptionId_params
        ? 'PoolRedemption'
        : 'FrendLend';

    const itemInBulla: BullaLineItems | FrendLendOffer | undefined = [
        ...userClaims,
        ...instantPayments,
        ...pendingPayments.pendingInstantPaymentInfos,
        ...frendLends,
        ...importedExternalTxs,
        ...offchainInvoices,
        ...poolDeposits,
        ...poolRedemptions,
    ].find(
        i =>
            (i.__type == 'FrendLend' ? i.loanId : i.id) === itemId &&
            i.__type.includes(type) &&
            (i.__type == 'OffchainInvoiceInfo' || i.chainId.toString() === network_params),
    );
    const item = offchainInvoice ?? itemInBulla;

    const isOpen = !!itemId && !!actingWallet;
    const networkReady = !!network_params && isChainInitialized(+network_params);
    const networkConfig = !!network_params ? NETWORKS[+network_params] : undefined;

    useEffect(() => {
        setLoading(true);
    }, [connectedNetwork]);

    useEffect(() => {
        if (itemInBulla) {
            setOffchainInvoiceFetched('init');
            setOffchainInvoice(undefined);
        }
    }, [!!itemInBulla]);

    useEffect(() => {
        if (!!offchainInvoiceId_params && !!networkConfig && networkReady && !itemInBulla && offchainInvoiceFetched == 'init') {
            setOffchainInvoiceFetched('fetching');
            const chainId = networkConfig.chainId;

            fetchOffchainInvoiceById(offchainInvoiceId_params, chainId)
                .then(invoice => {
                    const offchainInvoice = invoice ? createOffchainInvoiceInfo(invoice) : undefined;
                    setOffchainInvoice(offchainInvoice);
                })
                .finally(() => setOffchainInvoiceFetched('fetched'));
        }
    }, [networkReady, itemInBulla, offchainInvoiceFetched]);

    useEffect(() => {
        if (!loading) return;
        if (networkConfig?.connections?.graphEndpoint === undefined) setLoading(false);
        else if (userSafes == 'not-supported') setLoading(false);
        else if (isOpen && !connectedSafeAddress && userSafes !== 'init') {
            new Promise<
                | {
                      creditor: string;
                      debtor: string;
                  }
                | undefined
            >(async resolve => {
                const endpoint = networkConfig.connections.graphEndpoint!;
                const info = claimId_params
                    ? await tryGetSpecificClaim(networkConfig, getTokenByChainIdAndAddress, claimId_params)
                    : instantPaymentId_params
                    ? await tryGetSpecificInstantPayment(endpoint, instantPaymentId_params)
                    : offerId_params
                    ? await tryGetSpecificLoanOfferEvent(endpoint, offerId_params)
                    : undefined;
                resolve(info);
            }).then(item => {
                if (item) {
                    const isUserAddressCreditorOrDebtor =
                        addressEquality(item.creditor, actingWallet) || addressEquality(item.debtor, actingWallet);

                    if (!isUserAddressCreditorOrDebtor) {
                        const safesForUserAddress = Object.keys(userSafes);
                        const debtorSafeAddressOrNot = safesForUserAddress.find(safeAddress => addressEquality(safeAddress, item.debtor));
                        const creditorSafeAddressOrNot = safesForUserAddress.find(safeAddress =>
                            addressEquality(safeAddress, item.creditor),
                        );

                        if (!!debtorSafeAddressOrNot) {
                            fetchSafeAppInfo(debtorSafeAddressOrNot).then(_ => setLoading(false));
                        } else if (!!creditorSafeAddressOrNot) {
                            fetchSafeAppInfo(creditorSafeAddressOrNot).then(_ => setLoading(false));
                        } else {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            });
        }
    }, [userSafes, itemId, networkConfig, loading]);

    const handleClose = () => {
        if (isOpen) removeItemFromParams();
    };

    return (
        <>
            <Modal
                isCentered={!isMobile}
                isOpen={isOpen}
                onClose={handleClose}
                size={isMobile ? 'full' : '2xl'}
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent py="4" px="2" overflowX="hidden" bg={'white'} ref={modalContentRef}>
                    {item || loading || !networkReady || offchainInvoiceFetched == 'fetching' ? (
                        item && item.__type == 'Claim' ? (
                            <ClaimDetails claim={item} handleClose={handleClose} modalContentRef={modalContentRef} />
                        ) : item?.__type === 'FrendLend' ? (
                            <OfferDetails offer={item} handleClose={handleClose} />
                        ) : item?.__type === 'OffchainInvoiceInfo' ? (
                            <OffchainInvoiceDetails invoice={item} handleClose={handleClose} />
                        ) : item && isImportedExternalTransaction(item) ? (
                            <ExternalTransactionDetails
                                externalTransaction={item}
                                handleClose={handleClose}
                                modalContentRef={modalContentRef}
                            />
                        ) : item && isPoolTransactions(item) ? (
                            <PoolTransactionDetails poolTransaction={item} handleClose={handleClose} modalContentRef={modalContentRef} />
                        ) : (
                            <InstantPaymentDetails instantPayment={item} handleClose={handleClose} modalContentRef={modalContentRef} />
                        )
                    ) : (
                        <ItemNotFound
                            handleClose={handleClose}
                            type={type}
                            id={claimId_params ?? instantPaymentId_params ?? offerId_params}
                            networkConfig={isNaN(+network_params) ? undefined : NETWORKS[+network_params]}
                            modalContentRef={modalContentRef}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
