import { Box, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import QrScanner from 'qr-scanner';
import React, { useEffect, useRef, useState } from 'react';
import { isValidAddress } from '../../../data-lib/ethereum';
import { CloseModalButton } from '../common';

type QrScannerModalProps = {
    onSuccess: (address: string) => void;
    triggerElement: (onOpen: VoidFunction) => React.ReactNode;
};

type _QrScannerModalProps = {
    onSuccess: (address: string) => void;
    onClose: VoidFunction;
};

export const QrScannerModal = ({ triggerElement, onSuccess }: QrScannerModalProps) => {
    const { onClose, isOpen, onOpen } = useDisclosure();

    return (
        <>
            {triggerElement(onOpen)}
            {isOpen && <_QrScannerModal onClose={onClose} onSuccess={onSuccess} />}
        </>
    );
};

const _QrScannerModal = ({ onClose, onSuccess }: _QrScannerModalProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [errorState, setErrorState] = useState<string | undefined>();
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        if (videoRef.current !== null) {
            const qrCodeScanner = new QrScanner(
                videoRef.current,
                result => {
                    const splitAfterColon = result.data.split(':');
                    const addressAndNetworkId = splitAfterColon[splitAfterColon.length - 1];
                    const address = addressAndNetworkId.split('@')[0];
                    console.log(result.data, splitAfterColon, address);

                    isValidAddress(address)
                        ? (() => {
                              onSuccess(address);
                              onClose();
                          })()
                        : setErrorState('Invalid Address');
                },
                {
                    maxScansPerSecond: 1,
                    highlightScanRegion: true,
                    preferredCamera: 'environment',
                    onDecodeError: error => (typeof error === 'string' ? setErrorState(error) : setErrorState(error.message)),
                },
            );
            qrCodeScanner.start();
            return () => qrCodeScanner.stop();
        } else {
            setUpdate(x => x + 1);
        }
    }, [update]);

    return (
        <Modal isCentered isOpen={true} onClose={onClose} closeOnOverlayClick={false} closeOnEsc={false} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <CloseModalButton onClose={onClose} color="black" />
                </ModalHeader>
                <ModalBody px="4">
                    <Box py="4">
                        <video ref={videoRef} width="100%"></video>
                    </Box>
                    {!!errorState && errorState !== 'No QR code found' && (
                        <Text color="red" my="4">
                            {errorState}
                        </Text>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
