import {
    Box,
    chakra,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react';
import { CheckCircle } from 'phosphor-react';
import React from 'react';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../tools/common';
import { OrangeButton } from '../inputs/buttons';
import { CloseModalButton } from './common';

interface PricingBoxProps {
    price: number;
    isTrial?: boolean;
}

export const PricingBox = ({ price, isTrial }: PricingBoxProps) => {
    return (
        <Box>
            {isTrial && (
                <HStack spacing="2">
                    <Text fontWeight={'bold'} fontSize="24px">
                        FREE
                    </Text>
                    <Text color="#4A5568" pt="6px" fontWeight={500}>
                        first time use and import
                    </Text>
                </HStack>
            )}
            <HStack spacing="2">
                <Text fontWeight={'bold'} fontSize="24px">
                    ${price}
                </Text>
                <Text color="#4A5568" pt="6px" fontWeight={500}>
                    per yearly access to Bulla Premium
                </Text>
            </HStack>
        </Box>
    );
};

export const Feature: React.FC<{ feature: string }> = ({ feature }) => {
    return (
        <HStack>
            <CheckCircle size="20px" color={'green'} />
            <Text>{feature}</Text>
        </HStack>
    );
};

export const ImporterFreePricingModal: React.FC<{ modalOpen: boolean; closeModal: () => void }> = ({ modalOpen, closeModal }) => {
    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                size="2xl"
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={() => closeModal()} />
                    <ModalHeader>
                        <Text color="gray.700" fontWeight={'700'} fontSize="25px" maxW={'95%'} alignSelf="center">
                            Bulla Premium Feature
                        </Text>
                    </ModalHeader>
                    <ModalBody fontSize={'14px'} fontWeight="400">
                        <Stack>
                            <Box pb="2" mt="-3">
                                <Text fontWeight={400} fontSize={'16px'}>
                                    {' '}
                                    Access our premium transaction journal feature to import and document past transactions.
                                </Text>
                            </Box>

                            <PricingBox price={49} isTrial={true} />

                            <Text fontSize={'14px'}>
                                What you get with <Text as="b">Bulla Premium:</Text>
                            </Text>
                            <Stack spacing={1} fontWeight={500}>
                                <Feature feature={'External Transaction imports and categorization'} />
                                <Feature feature={'Private notes only you can see'} />
                                <Feature feature={'PDF Creation for receipts or invoices'} />
                            </Stack>

                            <Box pt="1" color="#A3AED0" fontWeight={500}>
                                <chakra.span>
                                    <chakra.span>Have questions or more support needs? </chakra.span>
                                    <Link
                                        href={BULLA_NETWORK_DISCORD_INVITE}
                                        isExternal
                                        target={'_blank'}
                                        textDecoration={'underline'}
                                        color={'#12525B'}
                                        fontWeight={500}
                                    >
                                        Reach out
                                    </Link>
                                    <chakra.span> to our sales team.</chakra.span>
                                </chakra.span>
                            </Box>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Spacer />
                        <OrangeButton onClick={() => closeModal()}>Continue</OrangeButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
