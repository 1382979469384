import React, { useEffect } from 'react';

export type SelectableOptions<T> = {
    toggleSelectAll: () => void;
    toggleSelected: (item: T) => void;
    isAllSelected: boolean;
    isItemSelected: (item: T) => boolean;
    selected: T[];
    resetSelection: VoidFunction;
};

export function useSelection<T>(all: T[], initiallySelected: T[] = []): SelectableOptions<T> {
    const [selected, setSelected] = React.useState<T[]>(initiallySelected);

    useEffect(() => {
        selected.forEach(x => {
            if (!all.includes(x)) {
                toggleSelected(x);
            }
        });
    }, [all]);

    function toggleSelected(item: T) {
        setSelected(selected => {
            const index = selected.indexOf(item, 0);
            if (index > -1) {
                return selected.filter(x => x != item);
            } else {
                return selected.concat(item);
            }
        });
    }

    const isAllSelected = selected.length == all.length && all.length > 0;

    function toggleSelectAll() {
        setSelected(isAllSelected ? [] : all);
    }

    const isItemSelected = (item: T) => selected.includes(item);
    const resetSelection = () => setSelected([]);

    return { toggleSelected, isItemSelected, toggleSelectAll, isAllSelected, selected, resetSelection };
}
