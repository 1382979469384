import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    ButtonProps,
    Collapse,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Image,
    Slide,
    SlideFade,
    Spacer,
    Stack,
    Text,
    useDisclosure,
    usePrevious,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import AlchemyLogo from 'url:../../assets/alchemy-logo.svg';
import ConnectYourWalletImage from 'url:../../assets/connect-your-wallet.svg';
import GasStationLogo from 'url:../../assets/gas-station.svg';
import OpolisLogo from 'url:../../assets/opolis-logo.png';
import SafeLogo from 'url:../../assets/safe-logo.png';
import SpectralLogo from 'url:../../assets/spectral-logo.svg';
import KeyLogo from 'url:../../assets/vpn-key.svg';
import { useBullaToast } from '../../components/base/toast';
import { CreateOrangeButton, OrangeButton } from '../../components/inputs/buttons';
import { Header } from '../../components/layout/header';
import { useConnectedEOA } from '../../hooks/useConnectedEOA';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useOnboard, useWeb3Unsafe } from '../../hooks/useWeb3';
import { isUserReady, useAuth } from '../../state/auth-state';
import { reloadWindow } from '../../tools/common';
import { ConnectNetworkSlide } from './connect-network-slide';

declare global {
    interface Ethereum {
        enable: () => Promise<void>;
    }

    var ethereum: Ethereum;
}

declare var ethereum: Ethereum;

export type SlideProps = {
    completeStep?: VoidFunction;
    goBack?: VoidFunction;
    finish?: VoidFunction;
};

const StepContainer = ({ slideIn, children }: { slideIn: boolean; children: React.ReactNode }) => {
    return (
        <Slide in={slideIn} style={{ position: 'absolute', minHeight: '100%', width: '100%' }} unmountOnExit>
            <Stack align="center" spacing="0" mt="8" bg="white" pos="relative" w="100%">
                {children}
            </Stack>
        </Slide>
    );
};

export const ConfirmationButton = ({
    onClick,
    validation,
    falseText,
    trueText,
    ...rest
}: {
    onClick: () => void;
    validation?: boolean;
    falseText: string;
    trueText: string;
} & ButtonProps) => {
    return (
        <Button
            onClick={onClick}
            colorScheme="accent"
            w="48"
            h="12"
            isDisabled={validation}
            rightIcon={validation ? <CheckIcon /> : undefined}
            {...rest}
        >
            {!validation && <Text fontWeight="700">{falseText}</Text>}
            <SlideFade in={validation}>
                <Text display={validation ? 'block' : 'none'} fontWeight="700">
                    {trueText}
                </Text>
            </SlideFade>
        </Button>
    );
};

const ConnectButton = CreateOrangeButton('Connect Wallet');

export const SlideContainer = ({ children }: { children: React.ReactNode }) => (
    <Box bg={'white'} borderRadius={'8px'} p="10">
        {children}
    </Box>
);

export const OnboardPage = () => {
    const { connectedNetworkConfigUnsafe: networkConfigUnsafe } = useWeb3Unsafe();
    const isMobile = useIsMobile();

    return (
        <Flex h="100vh" p="0" flexDir={'column'}>
            <Header />
            <Box p="10" bg="#0E3138" flexGrow={1} overflowY={'auto'}>
                <Flex pt={10}>
                    {!isMobile && <Spacer />}
                    <Container maxW={'432px'} marginX={'auto'}>
                        {networkConfigUnsafe === 'unsupported-network' ? (
                            <ConnectNetworkSlide />
                        ) : networkConfigUnsafe === 'not-connected' ? (
                            <ConnectWalletSlide />
                        ) : (
                            <SignInSlide />
                        )}
                    </Container>
                    {!isMobile && (
                        <Box flex={1} marginY={'auto'} h="fit-content">
                            <Container textAlign={'center'} color={'white'}>
                                <Stack gap={4}>
                                    <Text fontWeight={700} fontSize="24px">
                                        Trusted Partners
                                    </Text>
                                    <Grid templateColumns={'1fr 1fr'} rowGap="8">
                                        <GridItem key="1" margin="auto">
                                            <Image src={SafeLogo} w="160px" h="55px" margin={'auto'} />
                                        </GridItem>
                                        <GridItem key="2" margin="auto">
                                            <Image src={OpolisLogo} w="160px" h="35px" margin={'auto'} />
                                        </GridItem>
                                        <GridItem key="3" margin="auto">
                                            <Image src={AlchemyLogo} w="160px" h="40px" margin={'auto'} />
                                        </GridItem>
                                        <GridItem key="4" margin="auto">
                                            <Image src={SpectralLogo} w="190px" h="50px" margin={'auto'} />
                                        </GridItem>
                                    </Grid>
                                </Stack>
                            </Container>
                        </Box>
                    )}
                </Flex>
            </Box>
        </Flex>
    );
};

const ConnectWalletSlide = () => {
    const { walletSelect } = useOnboard();

    return (
        <SlideContainer>
            <Stack spacing={'4'} alignItems={'center'}>
                <Heading color="black" textAlign={'center'} fontSize={'20px'}>
                    Get started with Bulla
                </Heading>
                <Text fontWeight={'400'} fontSize="14px" textAlign="center">
                    In a few easy steps, our Web3 native solution allows you to send invoices, make payments and manage payroll.
                </Text>
                <Container py="2" w="fit-content">
                    <Image src={ConnectYourWalletImage} h={['120px', '250px', '320px']} />
                </Container>
                <ConnectButton isDisabled={false} onClick={walletSelect} w="100%" />
            </Stack>
        </SlideContainer>
    );
};

const SignInSlide = () => {
    const { authenticate, user } = useAuth();
    const eoaSigner = useConnectedEOA();
    const [signing, setSigning] = React.useState(false);
    const { isOpen, onToggle } = useDisclosure();
    const [hasFailed, setHasFailed] = React.useState(false);
    const [retryCount, setRetryCount] = React.useState(0);
    const triggerToast = useBullaToast();
    const { userAddress: userAddressRaw, connectedNetworkConfigUnsafe } = useWeb3Unsafe();
    const userAddress = useMemo(() => userAddressRaw, [userAddressRaw]);
    const previousUserAddress = usePrevious(userAddressRaw);

    useEffect(() => {
        if (previousUserAddress && previousUserAddress !== userAddress) {
            reloadWindow();
        }
    }, [userAddress, connectedNetworkConfigUnsafe]);

    React.useEffect(() => {
        if (eoaSigner !== 'not-connected' && !signing && !isUserReady(user) && user !== 'init') {
            setSigning(true);

            authenticate()
                .then(({ success }) => {
                    if (!success) {
                        setHasFailed(true);
                        triggerToast({
                            title: 'Signature failed. Please try again.',
                            status: 'error',
                        });
                    }
                })
                .finally(() => setSigning(false));
        }
    }, [eoaSigner, retryCount, user == 'init']);

    const borderRadiusProps = (isOpen: boolean) => (isOpen ? { borderTopRadius: '8px' } : { borderBottomRadius: '8px' });

    return (
        <Box>
            <Collapse in={!isOpen}>
                <Box bg={'white'} borderTopRadius={'8px'} p="9">
                    <Stack spacing={'4'} alignItems={'center'}>
                        <Heading color="black" textAlign={'center'} fontSize={'20px'}>
                            Requesting signature
                        </Heading>
                        <Text fontWeight={'400'} fontSize="14px" textAlign="center">
                            We need your signature to confirm you own this wallet. Sign the message in your wallet to log in.
                        </Text>
                        {hasFailed && (
                            <Box pt="2">
                                <OrangeButton onClick={() => setRetryCount(x => x + 1)} isLoading={signing} isDisabled={signing}>
                                    Sign Message To Continue
                                </OrangeButton>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Collapse>
            <Box bg="#12525B" {...borderRadiusProps(isOpen)} color="white" py="4" onClick={onToggle} _hover={{ cursor: 'pointer' }}>
                <Flex>
                    <Box flex="1"></Box>
                    <Text color={'white'} fontWeight={600} fontSize="14px" w="fit-content" textAlign={'center'}>
                        Learn more about signing
                    </Text>
                    <Flex flex="1">
                        <Spacer />
                        <Box pr="4">{isOpen ? <ChevronUpIcon h="7" w="7" /> : <ChevronDownIcon h="7" w="7" />}</Box>
                    </Flex>
                </Flex>
            </Box>
            <Collapse in={isOpen}>
                <Box bg={'white'} {...borderRadiusProps(!isOpen)} p="10" fontSize={'14px'} color="black">
                    <Grid templateColumns={'35px 1fr'} rowGap="3">
                        <GridItem key="first logo" colSpan={1}>
                            <Image src={KeyLogo} color="black" fill="black" mt="-2px" />
                        </GridItem>
                        <GridItem key="first header" colSpan={1}>
                            <Text fontWeight="600">Confirm you own the wallet</Text>
                        </GridItem>
                        <GridItem key="second logo (whitespace)" colSpan={1} />
                        <GridItem key="description" colSpan={1}>
                            <Text>
                                Signing proves you have access to your wallet. It’s a bit like verifying your email by clicking an email
                                confirmation link in your inbox during signup. This does not approve a transaction.
                            </Text>
                        </GridItem>
                        <GridItem key="second logo" colSpan={1}>
                            <Image src={GasStationLogo} color="black" fill="black" mt="-2px" />
                        </GridItem>
                        <GridItem key="second header" colSpan={1}>
                            <Text fontWeight="600">There are no associated gas costs</Text>
                        </GridItem>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
};
