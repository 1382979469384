import axios from 'axios';
import React from 'react';
import { ChainId, chainIds } from '../data-lib/networks';
import { endpoints } from '../tools/common';
import { STORAGE_KEYS } from '../tools/storage';
import { useLocalStorage } from './useStorage';
import { useGlobalUserData } from './useUserData';

type SmartContractNameResolutionRequest = {
    address: string;
    chainId: ChainId;
};
type NamingServiceResult = 'unverified' | 'not-found' | string;
type SmartContractNameResolvedResponse = SmartContractNameResolutionRequest & { name: NamingServiceResult };

const toCacheKey = ({ address, chainId }: SmartContractNameResolutionRequest) => `${address.toLowerCase()}#${chainId}`;
const fromCacheKey = (str: string): SmartContractNameResolutionRequest => {
    const [address, chainId] = str.split('#');
    return { address, chainId: +chainId as ChainId };
};

type NamingServiceContext = {
    findNameForAddress: (address: string, chainId: ChainId) => 'fetching' | NamingServiceResult;
    loadCache: (requests: SmartContractNameResolutionRequest[]) => Promise<void>;
    hash: string;
};

const NamingServiceContext = React.createContext<NamingServiceContext>({
    findNameForAddress: (_, __) => 'fetching',
    loadCache: async _ => {},
    hash: '',
});

export const NamingServiceProvider = ({ children }: { children: React.ReactNode }) => {
    const [_localStorageCache, _setLocalStorageCache] = useLocalStorage<Record<string, NamingServiceResult>>(
        STORAGE_KEYS.namingServiceCache,
        {},
    );
    const [cache, _setCache] = React.useState(_localStorageCache);
    const { bullaItems, nonImportedExternalTxs } = useGlobalUserData('include-originating-claims');

    const addressesAndChainIds = React.useMemo(
        () =>
            [
                ...bullaItems,
                ...nonImportedExternalTxs.flatMap(rootTx =>
                    rootTx.allTransfers.map(({ from, to }) => ({ creditor: from, debtor: to, chainId: rootTx.chainId })),
                ),
            ].flatMap(({ creditor, debtor, chainId }) => [
                { address: creditor, chainId },
                { address: debtor, chainId },
            ]),
        [bullaItems.length, nonImportedExternalTxs.length],
    );

    React.useEffect(() => {
        loadCache(addressesAndChainIds);
    }, [addressesAndChainIds]);

    const setCache = (fCache: (old: Record<string, NamingServiceResult>) => Record<string, NamingServiceResult>) => {
        _setCache(oldCache => {
            const newCache = fCache(oldCache);
            _setLocalStorageCache(newCache);
            return newCache;
        });
    };

    const findNameForAddress = (address: string, chainId: ChainId): 'fetching' | NamingServiceResult =>
        cache[toCacheKey({ address, chainId })] ?? 'fetching';

    const loadCache = async (requests: SmartContractNameResolutionRequest[]) => {
        const { inCache: _, toFetch } = [...new Set(requests.map(toCacheKey))].reduce<{
            inCache: SmartContractNameResolvedResponse[];
            toFetch: SmartContractNameResolutionRequest[];
        }>(
            (acc, cacheKey) => {
                const cacheValue = cache[cacheKey];
                const initialRequest = fromCacheKey(cacheKey);
                if (cacheValue) return { ...acc, inCache: [...acc.inCache, { ...initialRequest, name: cacheValue }] };
                else return { ...acc, toFetch: [...acc.toFetch, initialRequest] };
            },
            { inCache: [], toFetch: [] },
        );

        let notFoundAcc: SmartContractNameResolutionRequest[] = [];
        let smartContractsAcc: SmartContractNameResolvedResponse[] = [];
        let currentFetch = toFetch;

        while (currentFetch.length > 0) {
            try {
                const response = await axios.post<{
                    notFound: SmartContractNameResolutionRequest[];
                    smartContracts: SmartContractNameResolvedResponse[];
                    timedOut: SmartContractNameResolutionRequest[];
                }>(`${endpoints.namingServiceApi}/names`, currentFetch);

                const data = response.data;

                notFoundAcc = [...notFoundAcc, ...data.notFound];
                smartContractsAcc = [...smartContractsAcc, ...data.smartContracts];
                currentFetch = data.timedOut;
            } catch (e) {
                console.error('failed fetching from naming service', e);
                currentFetch = [];
            }
        }

        const newResolvedNamesToSave = [...notFoundAcc.map(x => ({ ...x, name: 'not-found' })), ...smartContractsAcc];

        setCache(oldCache => ({
            ...oldCache,
            ...newResolvedNamesToSave.reduce((acc, item) => ({ ...acc, [toCacheKey(item)]: item.name }), {}),
        }));
    };

    const hash = React.useMemo(() => JSON.stringify(cache), [cache]);

    return <NamingServiceContext.Provider value={{ loadCache, findNameForAddress, hash }}>{children}</NamingServiceContext.Provider>;
};

export const useNamingService = () => {
    const context = React.useContext(NamingServiceContext);
    if (!context) throw new Error('NamingService must be initialized');
    return context;
};
