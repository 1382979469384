import { useEffect, useState } from 'react';
import { TXStatus, waitForTransaction } from '../data-lib/networks';
import { useCurrentChainUserData } from './useUserData';
import { useWeb3 } from './useWeb3';

/**
 *
 * @returns [
 * `searching`: if the search process has begun,
 * `txFound`: if the transaction has been found in the eventLogs yet,
 * `waitForLogFromTx`: setup function that accepts a tx has to listen for]
 */
export const useWaitForItemEventFromTx = () => {
    const { bullaItemEvents, refetch } = useCurrentChainUserData();
    const { signerProvider, connectedNetworkConfig } = useWeb3();
    const [txSuccessful, setTxSuccesful] = useState(false);
    const [searchTx, setSearchTx] = useState<string>();
    const [eventRetryInterval, setEventRetryInterval] = useState<NodeJS.Timeout | undefined>();
    const [eventRetryAmount, setEventRetryAmount] = useState<number>(0);
    const hasTx = bullaItemEvents.some(log => log.txHash.toLowerCase() === searchTx?.toLowerCase() ?? '');

    useEffect(() => {
        const checkInternalStateForLog = !!eventRetryInterval;
        if (searchTx && checkInternalStateForLog) {
            if (!hasTx) console.debug(`anticipating event and checked: ${eventRetryAmount} times`);
            if (hasTx) {
                clearInterval(eventRetryInterval);
                setSearchTx('');
                setEventRetryAmount(0);
            }
            if (!hasTx && eventRetryAmount > 3) {
                console.log("didn't receive event from listeners... refetching!");
                refetch();
            }
        }
    }, [eventRetryAmount, bullaItemEvents.length]);

    useEffect(() => {
        if (txSuccessful) {
            const interval = setInterval(() => setEventRetryAmount(amt => amt + 1), 2000);
            setEventRetryInterval(interval);
        }
    }, [txSuccessful]);

    const waitForLogFromTx = async (txHash: string) => {
        setSearchTx(txHash);
        const tx = await waitForTransaction(connectedNetworkConfig.chainId, signerProvider, txHash);
        if (tx.status === TXStatus.SUCCESS) setTxSuccesful(true);
        return tx;
    };

    return [!!searchTx, txSuccessful && hasTx, waitForLogFromTx] as const;
};
