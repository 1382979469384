{
    "defaultAddress": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
    "released": true,
    "contractName": "CompatibilityFallbackHandler",
    "version": "1.3.0",
    "networkAddresses": {
        "1": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "3": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "10": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "11": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "12": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "28": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "42": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "5": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "56": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "69": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "100": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "122": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "123": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "137": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "246": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "288": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "588": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1088": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "1284": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1285": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1287": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "4002": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "42161": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "42220": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "43114": "0x017062a1dE2FE6b99BE3d9d37841FeD19F573804",
        "73799": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "80001": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "333999": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1313161554": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4",
        "1313161555": "0xf48f2B2d2a534e402487b3ee7C18c33Aec0Fe5e4"
    },
    "abi": [
        {
            "inputs": [],
            "name": "NAME",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "VERSION",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "message",
                    "type": "bytes"
                }
            ],
            "name": "getMessageHash",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "contract GnosisSafe",
                    "name": "safe",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "message",
                    "type": "bytes"
                }
            ],
            "name": "getMessageHashForSafe",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getModules",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "_dataHash",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes",
                    "name": "_signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "_data",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes",
                    "name": "_signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "",
                    "type": "uint256[]"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC1155BatchReceived",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC1155Received",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "onERC721Received",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "targetContract",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "calldataPayload",
                    "type": "bytes"
                }
            ],
            "name": "simulate",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "response",
                    "type": "bytes"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes4",
                    "name": "interfaceId",
                    "type": "bytes4"
                }
            ],
            "name": "supportsInterface",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "name": "tokensReceived",
            "outputs": [],
            "stateMutability": "pure",
            "type": "function"
        }
    ]
}
