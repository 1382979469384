import {
    Box,
    chakra,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckCircle } from 'phosphor-react';
import React from 'react';
import { ClaimType } from '../../../data-lib/data-model';
import { useLocalStorage } from '../../../hooks/useStorage';
import { BULLA_NETWORK_DISCORD_INVITE } from '../../../tools/common';
import { STORAGE_KEYS } from '../../../tools/storage';
import { OrangeButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';

export type BatchPricingModalProps = {
    children: React.ReactNode;
    claimType: ClaimType;
};

export const BatchPricingModal = ({ children, claimType }: BatchPricingModalProps) => {
    const { isOpen: _isOpen, onClose: _onClose } = useDisclosure({ defaultIsOpen: true });

    const [seen, setSeen] = useLocalStorage<boolean>(STORAGE_KEYS.seenBatchPricingModal, false);

    const isOpen = _isOpen && !seen;
    const onClose = () => {
        setSeen(true);
        _onClose();
    };

    return (
        <>
            {children}
            <Modal isCentered isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" size="2xl" closeOnEsc scrollBehavior="inside">
                <ModalOverlay />
                <ModalContent>
                    <CloseModalButton onClose={onClose} />
                    <ModalHeader>
                        <Text color="gray.700" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                            Pricing
                        </Text>
                    </ModalHeader>
                    <ModalBody fontSize={'12px'} fontWeight="400">
                        <Stack spacing={'2'}>
                            <Box pb="2">
                                <Text fontSize={'14px'}>
                                    {claimType == 'Invoice' ? 'Invoice' : 'Pay'} multiple wallets at once as efficiently as possible.
                                </Text>
                            </Box>
                            <Box>
                                <HStack spacing="2">
                                    <Text fontWeight={'bold'} fontSize="24px">
                                        FREE
                                    </Text>
                                    <Text color="#4A5568" pt="6px" fontWeight={500}>
                                        items under $100
                                    </Text>
                                </HStack>
                                <HStack spacing="2">
                                    <Text fontWeight={'bold'} fontSize="24px">
                                        $2
                                    </Text>
                                    <Text color="#4A5568" pt="6px" fontWeight={500}>
                                        items over $100
                                    </Text>
                                </HStack>
                            </Box>

                            <Text fontSize={'14px'}>Perks</Text>
                            <Stack spacing={1} fontWeight={500}>
                                <HStack>
                                    <CheckCircle size="20px" color={'green'} />
                                    <Text>Safe Integration</Text>
                                </HStack>
                                <HStack>
                                    <CheckCircle size="20px" color={'green'} />
                                    <Text>Unlimited Wallets</Text>
                                </HStack>
                                <HStack>
                                    <CheckCircle size="20px" color={'green'} />
                                    <Text>CSV import</Text>
                                </HStack>
                            </Stack>

                            <Box pt="1" color="#A3AED0" fontWeight={500}>
                                <chakra.span>
                                    <chakra.span>Have questions or support needs? </chakra.span>
                                    <Link
                                        href={BULLA_NETWORK_DISCORD_INVITE}
                                        isExternal
                                        target={'_blank'}
                                        textDecoration={'underline'}
                                        color={'#12525B'}
                                        fontWeight={500}
                                    >
                                        Reach out
                                    </Link>
                                    <chakra.span> to our team.</chakra.span>
                                </chakra.span>
                            </Box>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        <Spacer />
                        <OrangeButton onClick={onClose}>Continue</OrangeButton>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};
