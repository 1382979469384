import { getBullaFactoringPermissionsContract } from '../data-lib/dto/contract-interfaces';
import { FactoringConfig } from '../data-lib/networks';
import { useWeb3 } from './useWeb3';
import React from 'react';
import { useActingWalletAddress } from './useWalletAddress';

export const useBullaFactoringPermissions = () => {
    const [pending, setPending] = React.useState(false);
    const { providersByChainId } = useWeb3();
    const actingWallet = useActingWalletAddress();

    const hasFactoringPermissions = async (factoringConfig: FactoringConfig) => {
        setPending(true);
        try {
            const contract = getBullaFactoringPermissionsContract(factoringConfig.factoringPermissionsContract).connect(
                providersByChainId[factoringConfig.bullaFactoringToken.chainId],
            );
            const result = await contract.isAllowed(actingWallet);
            return result;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    const hasDepositPermissions = async (factoringConfig: FactoringConfig) => {
        setPending(true);
        try {
            const contract = getBullaFactoringPermissionsContract(factoringConfig.depositPermissionsContract).connect(
                providersByChainId[factoringConfig.bullaFactoringToken.chainId],
            );
            const result = await contract.isAllowed(actingWallet);
            return result;
        } catch (e) {
            console.error(e);
            return false;
        } finally {
            setPending(false);
        }
    };

    return [
        pending,
        {
            hasFactoringPermissions,
            hasDepositPermissions,
        },
    ] as const;
};
