import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { WithSkeletonProps } from '../modals/item-details-modal/item-details-components';

export const randomWidth = (min: number, max: number, property: string) => `${Math.random() * (max - min) + min}${property}`;

export const WithSkeleton = React.memo(({ children, isLoading, fixedWidth, randomW, height }: WithSkeletonProps) => {
    const width = React.useRef(randomWidth(50, 250, 'px'));
    return (
        <>
            {isLoading ? (
                <Skeleton h={height ?? '1.5em'} maxW="100%" w={randomW ? width.current : fixedWidth ? fixedWidth : '100%'} />
            ) : (
                children
            )}
        </>
    );
});
